/* eslint-disable react/jsx-props-no-spreading */
import { InputHTMLAttributes, useState } from "react";
import { FileInput } from "../Styled";
import { CSVFile } from "../types";

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  onFileUpload: (file: CSVFile, uploadError: string) => string | void;
  renderAs: (fileStatus: {
    isUploading: boolean;
    uploadError: string;
  }) => JSX.Element;
}

const parseTextFile = e => {
  const [file] = e.target.files;
  const [name, ext] = file.name.split(".");
  const reader = new FileReader();

  return new Promise<CSVFile>(resolve => {
    if (!file) {
      resolve(null);
    }
    reader.addEventListener("load", fileEvt => {
      const { result } = fileEvt.target;
      resolve({
        name: name || file.name,
        ext: file.type || ext,
        size: file.size,
        content: result as string,
      });
    });
    reader.readAsText(file);
  });
};

const TextFileInput = ({
  name,
  onFileUpload,
  renderAs,
  ...inputProps
}: FileInputProps) => {
  const [file, setFile] = useState<string>("");
  const [uploadError, setUploadError] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleFileUpload = async e => {
    e.preventDefault();
    setFile(e.target.value);
    setIsUploading(loading => !loading);
    const parsedFile = await parseTextFile(e);
    const uploadResult = onFileUpload(parsedFile, uploadError);
    setUploadError(uploadResult || "");
    setIsUploading(loading => !loading);
    setFile("");
  };

  return (
    <>
      <FileInput
        type="file"
        name={name}
        id={name}
        hidden={!!renderAs}
        disabled={isUploading}
        value={file}
        onChange={handleFileUpload}
        {...inputProps}
      />
      {renderAs && (
        <label htmlFor={name}>{renderAs({ isUploading, uploadError })}</label>
      )}
    </>
  );
};

export default TextFileInput;
