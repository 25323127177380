import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { FilterBy, colors, chartLines } from "./statLinesConfig";
import { EmailsSumData, parseData } from "./parseData";
import FilterOptions from "./FilterOptions";
import ChartHeader from "../ChartHeader";
import takeRange from "../takeRange";
import { Margin } from "recharts/types/util/types";
import { DaysRange } from "pages/UsageReports/dayRange";
import { TimePeriodStat } from "@dashboard-v3/api";
import { LegendData, StatConfig } from "../types";
import { tickFormatter, tooltipLabelFormatter } from "../labels";

dayjs.extend(utc);

type Params = {
  stats: Array<TimePeriodStat>;
  range: DaysRange;
};

export default function ProcessedEmailsChart({ stats, range = 90 }: Params) {
  const { t } = useTranslation(["usageReports", "rules"]);
  const [filter, setFilter] = useState<FilterBy>("action");
  const [data, setData] = useState<EmailsSumData>([]);
  const [statLines, setStatLines] = useState<StatConfig[]>(chartLines[filter]);
  const classes = useStyles();
  const areaChartMargins: Margin = { top: 5, right: 30, left: 0, bottom: 5 };

  useEffect(() => {
    setData(parseData(stats));
  }, [stats]);

  useEffect(() => {
    setStatLines(chartLines[filter]);
  }, [filter]);

  const handleLegendClick = (data: LegendData) => {
    setStatLines(prev => {
      return prev.map(statLine => {
        const { dataKey } = statLine;
        if (dataKey !== "all" && dataKey === data.dataKey) {
          statLine.enabled = !statLine.enabled;
        }
        return statLine;
      });
    });
  };

  const tooltipFormatter = (value, _, props) => {
    if (props.dataKey === "all") {
      return [value.toLocaleString(), t("processedEmails.title")];
    }
    return value.toLocaleString();
  };

  return (
    <Paper className={classes.chartContainer}>
      <ChartHeader>
        <Typography
          className={classes.chartTitle}
          variant="subtitle1"
          display="block"
          gutterBottom
        >
          {t("processedEmails.title")}
        </Typography>
        <div className={classes.filtersContainer}>
          <FilterOptions value={filter} onChange={setFilter} />
        </div>
      </ChartHeader>
      <ResponsiveContainer height={250}>
        <AreaChart data={takeRange(data, range)} margin={areaChartMargins}>
          <defs>
            {statLines.map(({ dataKey: key }, i) => (
              <linearGradient key={key} id="one" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors[i][1]} stopOpacity={0.8} />
                <stop offset="95%" stopColor={colors[i][1]} stopOpacity={0} />
              </linearGradient>
            ))}
          </defs>
          <CartesianGrid strokeDasharray="4" vertical={false} />
          <XAxis tickLine dataKey="date" tickFormatter={tickFormatter} />
          <YAxis tickLine={false} />
          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
          />
          <Legend onClick={handleLegendClick} />
          {statLines.map(stat => (
            <Area
              key={stat.dataKey}
              dataKey={stat.dataKey}
              name={t(stat.name)}
              stroke={stat.stroke}
              strokeWidth={2}
              fill={stat.fill}
              hide={!stat.enabled}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
}

const useStyles = makeStyles({
  chartContainer: {
    padding: "2rem 1rem",
  },
  chartTitle: {
    marginBottom: "20px",
  },
  filtersContainer: {
    marginBottom: "15px",
    textAlign: "right",
  },
});
