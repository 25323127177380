import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import isEmail from "utils/isEmail";
import ButtonWithLoading from "components/ButtonWithLoading";
import useStorageAccountValidation from "utils/useStorageAccountValidation";
import TagsInput from "./TagsInput";
import { AccountToEdit, State } from "./types";
import handleValidationErrors from "./handleValidationErrors";
import { useAccountState } from "../../accountContext";

type Params = {
  accountToEdit?: AccountToEdit;
} & ButtonProps;

export default function AddOrEditAccount({ accountToEdit, ...rest }: Params) {
  const { t } = useTranslation("accounts");
  const { state: accountsState, actions } = useAccountState();
  const [openDialog, setOpenDialog] = useState(false);
  const [initialEmail, setInitialEmail] = useState<string>();
  const [state, setState] = useState<State>(initState);
  const [isSaving, setIsSaving] = useState(false);
  const [aliasesError, setAliasesError] = useState<string>(null);
  const [emailError, setEmailError] = useState<string>(null);
  const [storageError, setStorageError] = useState(initStorageError);
  const validateAccess = useStorageAccountValidation();

  const handleClickOpen = e => {
    e.stopPropagation();

    if (accountToEdit) {
      setState(() => {
        const { storage, ...account } = accountToEdit;
        const accounts = Object.keys(storage).filter(item =>
          Boolean(storage[item])
        );

        return !accounts.length ? { ...account, storage: {} } : accountToEdit;
      });

      setInitialEmail(accountToEdit.email);
    }

    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setState(initState());
    resetErrors();
  };

  function resetErrors() {
    setAliasesError(null);
    setEmailError(null);
    setStorageError(initStorageError());
  }

  const validateAliases = aliases => {
    const wrongEmails = aliases.filter(email => !isEmail(email));

    if (wrongEmails.length > 0) {
      setAliasesError(`${t("error.aliasesAreWrong")} ${wrongEmails}`);
    } else {
      setAliasesError(null);
    }
  };

  const handleDeleteAlias = removedAlias => {
    const newAliases = state.aliases.filter(alias => alias !== removedAlias);
    validateAliases(newAliases);
    setState(current => ({ ...current, aliases: newAliases }));
  };

  const handleAddAlias = newAlias => {
    const newAliases = [...state.aliases, newAlias];
    validateAliases(newAliases);

    setState(current => ({
      ...current,
      aliases: newAliases,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (Boolean(aliasesError)) return;
    setIsSaving(true);
    try {
      const { isValid, errors } = await validateAccess(state.storage);
      if (!isValid) return setStorageError(errors);
      if (accountToEdit) {
        await actions.updateAccount(initialEmail, state);
      } else {
        await actions.createAccount(state);
      }
      handleClose();
    } catch (error) {
      const { type, error: message } = handleValidationErrors(state, error, t);
      if (type === "STORAGE") return setStorageError(message);
      if (type === "EMAIL") return setEmailError(message);
      if (type === "ALIASES") return setAliasesError(message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = event => {
    const { value, name } = event.target;

    if (name === "email") {
      setEmailError(null);
    }
    if (name === "aliases") {
      setAliasesError(null);
    }

    setState(current => ({ ...current, [name]: value }));
  };

  const changeStorageAccount = e => {
    const storage = { ...state.storage };
    const { name, value } = e.target;
    setStorageError(prev => ({ ...prev, [name]: null }));

    if (!value) {
      delete storage[name];
    } else {
      storage[name] = value.trim();
    }

    setState(current => ({
      ...current,
      storage,
    }));
  };

  const handleAddTag = (_, selected) => {
    setState(prev => ({ ...prev, tags: selected }));
  };

  return (
    <>
      <Button
        color={accountToEdit ? "default" : "primary"}
        data-testid={
          accountToEdit
            ? "accounts__edit-account-btn"
            : "accounts__add-account-btn"
        }
        disableElevation
        onClick={handleClickOpen}
        variant="contained"
        {...rest}
      >
        {accountToEdit ? t("common:edit") : t("buttons.addAccount")}
      </Button>
      <Dialog
        open={openDialog}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
      >
        <DialogTitle>
          {accountToEdit ? t("common:edit") : t("common:add")} Account
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": `${
                  accountToEdit ? "edit-email-input" : "account-email-input"
                }`,
              }}
              label={t("email")}
              onChange={handleChange}
              required
              name="email"
              type="email"
              value={state.email}
              variant="outlined"
              disabled={Boolean(accountToEdit)}
              error={Boolean(emailError)}
              helperText={emailError}
            />
            <TagsInput
              options={accountsState.tags}
              values={state.tags}
              onChange={handleAddTag}
            />
            <StyledChipInput
              fullWidth
              label={t("alias_plural")}
              onAdd={handleAddAlias}
              onDelete={handleDeleteAlias}
              variant="outlined"
              value={state.aliases}
              error={Boolean(aliasesError)}
              helperText={aliasesError || t("enter_to_add")}
              newChipKeys={["Enter", ","]}
            />
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": "accounts__account-box-input",
              }}
              label={t("orgStorageAccount.label", { value: "Box" })}
              onChange={changeStorageAccount}
              type="text"
              name="box"
              value={state.storage.box || ""}
              variant="outlined"
              error={Boolean(storageError.box)}
              helperText={storageError.box}
            />
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": "accounts__account-dropbox-input",
              }}
              label={t("buttons.storageAccount", { value: "Dropbox" })}
              onChange={changeStorageAccount}
              type="text"
              name="dropbox"
              value={state.storage.dropbox || ""}
              variant="outlined"
              error={Boolean(storageError.dropbox)}
              helperText={storageError.dropbox}
            />
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": "accounts__account-ondedrive-input",
              }}
              label={t("orgStorageAccount.label", { value: "OneDrive" })}
              onChange={changeStorageAccount}
              type="text"
              name="onedrive"
              value={state.storage.onedrive || ""}
              variant="outlined"
              error={Boolean(storageError.onedrive)}
              helperText={storageError.onedrive}
            />
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": "accounts__account-drive-input",
              }}
              label={t("orgStorageAccount.label", { value: "Google Drive" })}
              onChange={changeStorageAccount}
              type="text"
              name="drive"
              value={state.storage.drive || ""}
              variant="outlined"
              error={Boolean(storageError.drive)}
              helperText={storageError.drive}
            />
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="accounts__account-cancel-edit"
              onClick={handleClose}
              color="primary"
            >
              {t("common:cancel")}
            </Button>
            <ButtonWithLoading
              color="primary"
              data-testid={
                accountToEdit
                  ? "account-confirm-edit-btn"
                  : "account-confirm-create-btn"
              }
              disableElevation
              type="submit"
              variant="contained"
              loading={isSaving}
            >
              {accountToEdit ? t("common:save") : t("common:create")}
            </ButtonWithLoading>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const StyledTextField = styled(TextField)({
  marginBottom: "20px",
});

const StyledChipInput = styled(ChipInput)({
  marginBottom: "20px",
  "& .MuiFormHelperText-root": {
    marginBottom: 0,
  },
});

const initState = (): State => ({
  email: "",
  storage: {},
  tags: [],
  aliases: [],
});

const initStorageError = (): AccountToEdit["storage"] => ({
  box: null,
  onedrive: null,
  egnyte: null,
  dropbox: null,
  drive: null,
});
