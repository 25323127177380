import { clearVariablesFromStorage } from "./usePlatformVariables";
import {
  hoursToRevalidateSensitiveActions,
  hoursUntilNow,
} from "@dashboard-v3/shared";

import { User } from "@dashboard-v3/api";
import { Timestamp } from "@dashboard-v3/api/src/types/commons";

export const MIN_PASSWORD_LENGTH = 8;
export const UPPER_CASE_REGEX = /[A-Z]+/;
export const LOWER_CASE_REGEX = /[a-z]+/;
export const SPECIAL_CHARS_REGEX = /[^A-Za-z0-9]/;

export const getOrganizationId = () => {
  const user = getUser();
  if (user) return user.organizationId;
  return null;
};

export function isAuthenticated() {
  return getUser() !== null;
}

/**
 *
 * @param {number} sessionDuration in hours
 */
export function addUserInSession(user: User, sessionDuration = 3.0) {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("sessionDuration", sessionDuration.toString());
}

export function updateLastLogin(time: Timestamp) {
  const currentUser = JSON.parse(localStorage.getItem("user")) as User;
  currentUser.lastLogin = time;
  localStorage.setItem("user", JSON.stringify(currentUser));
}

export async function removeUserFromSession() {
  localStorage.removeItem("user");
  localStorage.removeItem("sessionDuration");
  localStorage.removeItem("lastApiRequest");
  localStorage.removeItem("originPath");
  clearVariablesFromStorage();
}

export function addOriginPathInSession(pathname: string) {
  localStorage.setItem("originPath", pathname);
}

export function getOriginPathInSession() {
  const pathname = localStorage.getItem("originPath");
  return pathname;
}

export function getUser(): User | null {
  try {
    const userAsString = localStorage.getItem("user");
    return JSON.parse(userAsString) as User;
  } catch (error) {
    return null;
  }
}

export function getSessionDuration() {
  const value = localStorage.getItem("sessionDuration");
  if (!value) return 3.0;
  return parseFloat(value);
}

export function updateLastApiRequestTime() {
  localStorage.setItem("lastApiRequest", Date.now().toString());
}

export function getLastApiRequestTime() {
  return parseInt(localStorage.getItem("lastApiRequest"), 10);
}

export function shouldAuthenticate() {
  const currentUser = getUser();
  const shouldAuthenticate =
    hoursUntilNow(currentUser.lastLogin) >= hoursToRevalidateSensitiveActions;
  return shouldAuthenticate;
}
