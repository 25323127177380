import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import CdnBackend from "i18next-locize-backend";

dayjs.extend(relativeTime);

const dayjsLocales = {
  en: () => import(`dayjs/locale/en`),
  ja: () => import(`dayjs/locale/ja`),
  fr: () => import(`dayjs/locale/fr`),
};

const common = {
  // debug: true,
  detection: {
    order: ["localStorage", "navigator"],
    lookupQuerystring: "lng",
    lookupLocalStorage: "i18nextLng",
    // cache user language
    caches: ["localStorage"],
    excludeCacheFor: ["cimode"],
  },
  ns: ["common", "components"],
  fallbackLng: ["en", "ja", "fr"],
  supportedLngs: ["en", "ja", "fr"],
  nonExplicitSupportedLngs: false,
  defaultNS: "common",
};

function staticFilesConfig() {
  return {
    ...common,
    react: {
      useSuspense: false,
    },
    resources:
      // Double check is required for "webpack" :(
      process.env.NODE_ENV === "production"
        ? require("./locales.resource.json")
        : {},
  };
}

function locizeCdnFiles() {
  return {
    ...common,
    react: {
      useSuspense: true,
    },
    backend: {
      projectId: "513d082b-b815-4b3b-8909-3a07371c2946",
      referenceLng: "en",
      version: "latest",
    },
  };
}

function testConfig() {
  return { ...common, react: { useSuspense: false }, debug: false };
}

i18n.use(initReactI18next).use(LanguageDetector);

let config = {};

if (process.env.NODE_ENV === "test") {
  config = testConfig();
} else {
  if (process.env.NODE_ENV === "production") {
    config = staticFilesConfig();
    document.translations = config.resources;
  } else {
    i18n.use(CdnBackend);
    config = locizeCdnFiles();
  }
}

i18n
  .init(config)
  .then(() => dayjsLocales[i18n.language]())
  .then(() => {
    dayjs.locale(i18n.language);
    document.documentElement.lang = i18n.language;
  });

i18n.on("languageChanged", language => {
  dayjs.locale(language);
  document.documentElement.lang = language;
});

export default i18n;
