export enum FolderType {
  FOLDER_STRUCTURE = "folderStructure",
  PARENT_FOLDER = "parentfolder",
  SOURCE_FOLDER = "{storage_source_folder_id}",
}

export type FolderPath = {
  folderStructure?: string;
  parentName?: string;
  parentId?: string;
};

export type FilepathUpdate = {
  type: FolderType;
  folderStructure?: string;
  parentFolder?: { parentName: string; parentId: string };
};

export type StorageFolder = { parentName: string; parentId: string };
export type WebhookFolder = { id: string; path: string };

export type PickerParams = {
  onChange: (folder?: StorageFolder) => void;
  open: boolean;
};

export type BoxPickerParams = {
  onChange: (folders?: StorageFolder[], account?: string) => void;
  open: boolean;
  multiSelect?: boolean;
};
