import { byLastModified } from "utils/sort";
import { Item, Option } from "./types";
import { typePath } from "../CreateOrEdit/context/helpers";

import {
  Authentication,
  SecurityDefinition,
  SecurityPolicy,
  Storage,
  TemplateDefinition,
  TemplatePolicy,
} from "@dashboard-v3/api";
import { State } from "../CreateOrEdit/context/types";
import { Rule } from "../types";

export enum AccountManagement {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
  BALANCED = "BALANCED",
}

export enum OptionTypes {
  EMPTY = "EMPTY",
  SEARCH = "SEARCH",
  PRESET = "PRESET",
  DEFINITION = "DEFINITION",
  POLICY = "POLICY",
}

export enum CloudStorageStatus {
  OK = "OK",
  NOT_FOUND = "NOT_FOUND",
  OUT_OF_SYNC = "OUT_OF_SYNC",
}

export const isOrganization = (value: Authentication) =>
  value === "ORGANIZATION";

export const isIndividual = (value: Authentication) => value === "INDIVIDUAL";

export const isSecurityOption = (value: OptionTypes) =>
  value === "PRESET" || value === "DEFINITION" || value === "POLICY";

export const isSearchOption = (value: OptionTypes) => value === "SEARCH";

export const isPresetOption = (value: OptionTypes) => value === "PRESET";

export const canConfirm = (state: State) =>
  !state.steps?.cloudStorage?.done && state.steps?.cloudStorage?.valid;

const emptySecurityOption: Option = {
  key: "empty",
  type: OptionTypes.EMPTY,
  text: "cloudStorage.security.empty",
};

const searchOption: Option = {
  key: crypto.randomUUID(),
  type: OptionTypes.SEARCH,
  label: "cloudStorage.security.searchField",
};

type SecurityItems = {
  presets: SecurityDefinition[];
  definitions: SecurityDefinition[];
};

export const toSecurityOptions = (
  definitions: SecurityDefinition[],
  policies: SecurityPolicy[]
): Option[] => {
  if (definitions && policies) {
    const securityItems: SecurityItems = definitions.reduce(
      (acc, item) => {
        if (item.systemDefault) {
          acc.presets.push(item);
        } else {
          acc.definitions.push(item);
        }
        return acc;
      },
      { presets: [], definitions: [] }
    );

    const presetOpts = securityItems.presets.map(({ id, name }) => ({
      key: id,
      type: OptionTypes.PRESET,
      label: `securityDefinitions:presets.label`,
      text: name,
      value: `${OptionTypes.PRESET}:${id}`,
    }));

    const policyOptions = policies.map(
      ({ id, name, storageType, description }) => ({
        key: id,
        type: OptionTypes.POLICY,
        provider: storageType,
        label: "cloudStorage.security.policy.label",
        text: name,
        value: `${OptionTypes.POLICY}:${id}`,
        description,
      })
    );

    const definitionOpts = securityItems.definitions.map(
      ({ id, name, allowedStorages }) => ({
        key: id,
        type: OptionTypes.DEFINITION,
        provider: allowedStorages[0],
        text: name,
        label: "securityDefinitions:definitions.label",
        value: `${OptionTypes.DEFINITION}:${id}`,
      })
    );

    return [
      emptySecurityOption,
      ...presetOpts,
      searchOption,
      ...policyOptions,
      ...definitionOpts,
    ];
  }
  return [];
};

export const toTemplateOptions = (
  definitions: TemplateDefinition[],
  policies: TemplatePolicy[]
): Option[] => {
  const policyOpts = policies
    .sort(byLastModified)
    .map(({ id, name, description }) => ({
      key: id,
      type: OptionTypes.POLICY,
      label: `cloudStorage.templates.optionLabels.${OptionTypes.POLICY}`,
      text: name,
      value: `${OptionTypes.POLICY}:${id}`,
      description,
    }));

  const definitionOpts = definitions
    .sort(byLastModified)
    .map(({ id, name }) => ({
      key: id,
      type: OptionTypes.DEFINITION,
      label: `cloudStorage.templates.optionLabels.${OptionTypes.DEFINITION}`,
      text: name,
      value: `${OptionTypes.DEFINITION}:${id}`,
    }));

  return [...definitionOpts, ...policyOpts];
};

export function getStorage(rule: Rule): Storage | null {
  const path = typePath(rule.actionType, rule);
  if ("storage" in path) return path.storage;
  return null;
}

export function isStorageAccountSynced(
  storageAccounts: Item[],
  selectedAccount: Storage
) {
  const item = storageAccounts?.find(
    ({ storageAccount }) => storageAccount.userId === selectedAccount.userId
  );

  return Boolean(item?.option.enabled);
}

export const isAccountNotFound = (items: Item[], user: string) =>
  !items?.some(({ storageAccount }) => storageAccount.userId === user);

// TODO: Remove and use useCloudStorageStatus
export const getCloudStorageStatus = (
  storage: Storage | null,
  availableAccounts: Item[]
) => {
  if (!storage) return CloudStorageStatus.OK;
  if (!availableAccounts) return CloudStorageStatus.OK;

  const { authentication, provider } = storage;
  const isIndividual =
    authentication === "INDIVIDUAL" || provider === "DROPBOX";

  if (isIndividual) {
    const account = availableAccounts?.find(
      ({ storageAccount }) =>
        storageAccount &&
        storageAccount.userId === storage.userId &&
        storageAccount.provider === storage.provider
    );

    if (!account) return CloudStorageStatus.NOT_FOUND;

    if (account && !account.option.enabled) {
      return CloudStorageStatus.OUT_OF_SYNC;
    }
  }

  return CloudStorageStatus.OK;
};

export const asUserValue = (storage: Storage) => {
  const value =
    storage.provider === "DROPBOX" ? storage.asUser : storage.userId;
  return value || "";
};

export const asUserValueCollection = (storage: Storage) => {
  const value =
    storage.provider !== "DROPBOX" ? storage.userIds : storage?.asUsers;
  return value || [];
};

export const canImpersonate = (storage: Storage) => {
  if (storage?.authentication === "ORGANIZATION") return true;
  return storage?.provider === "DROPBOX";
};

export const shouldResetFolderLocation = (storage: Storage) => {
  const { storeId, location } = storage;
  return Boolean(storeId) && Boolean(location.parentId);
};
