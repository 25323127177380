import { useTranslation } from "react-i18next";
import nextId from "react-id-generator";
import DotIcon from "@material-ui/icons/FiberManualRecord";

import { Group } from "@dashboard-v3/api";

interface EmailAndSourceProps {
  email: string;
  sourceType: Group["sourceType"];
  sourceLabel: Group["sourceLabel"];
}

export default function EmailAndSource(props: EmailAndSourceProps) {
  const { t } = useTranslation("accounts");
  const { email, sourceType, sourceLabel } = props;
  const items = [];

  if (email) {
    items.push(<span key={nextId()}>{email}</span>);
  }

  if (email && sourceType) {
    items.push(
      <DotIcon key={nextId()} style={{ fontSize: ".5rem", margin: "0 10px" }} />
    );
  }

  if (sourceType) {
    items.push(
      <span key={nextId()} style={{ textTransform: "capitalize" }}>
        {t("source")}: {sourceTypes[sourceType]}
        {sourceLabel ? ` - ${sourceLabel}` : ""}
      </span>
    );
  }

  return <>{items}</>;
}

const sourceTypes = {
  MANUAL: "Manual",
  GAPPS: "Google Apps",
  OFFICE365: "Office 365",
  LDAP: "LDAP",
};
