import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Label, OptionGroup } from "components/Styled";
import { useTranslation } from "react-i18next";
import { getStorage } from "pages/Rules/CloudStorage/utils";
import { Rule } from "pages/Rules/types";
import { UpdateMoreActions } from "../../context/reducers/updateMoreActions";
import { State } from "../../context/types";
import { useRuleContext } from "../../context/ruleContext";
import SelectVariable from "./SelectVariable";
import { Storage } from "@dashboard-v3/api";

export default function OnStorageSuccess() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { rule } = state;
  const actionType = getActionType(rule);
  const recipientType = getRecipientType(rule);
  const variableId = getAction(rule)?.variableId;

  return (
    <>
      <OptionGroup>
        <Label wording={t("onStorageSuccess.mainLabel")} />
        <Box display="flex" mt="15px" alignItems="baseline">
          <Select
            variant="outlined"
            value={actionType}
            onChange={event => {
              dispatch({
                type: "updateMoreActions",
                payload: { onStorageSuccess: { type: event.target.value } },
              });
            }}
          >
            <MenuItem value="NOTHING">
              {/* Do nothing */}
              {t("onStorageSuccess.actionType.NOTHING")}
            </MenuItem>
            <MenuItem value="COPY">
              {/* Send a copy */}
              {t("onStorageSuccess.actionType.COPY")}
            </MenuItem>
            <MenuItem value="FORWARD">
              {/* Forward */}
              {t("onStorageSuccess.actionType.FORWARD")}
            </MenuItem>
          </Select>
          {actionType !== "NOTHING" && (
            <>
              <SelectRecipientType />
              {recipientType === "EMAIL" && <InputRecipientAddress />}
            </>
          )}
        </Box>
        {recipientType === "VARIABLE" && (
          <Box mt="15px">
            <SelectVariable
              onChange={variableId => {
                dispatch({
                  type: "updateMoreActions",
                  payload: {
                    onStorageSuccess: { variableId },
                  },
                });
              }}
              value={variableId}
              error={stepError(state) && t("common:is_required")}
            />
          </Box>
        )}
        {actionType !== "NOTHING" && (
          <Box display="flex" mt="15px" gridGap="15px" alignItems="center">
            <Box>
              <Typography>
                {/* With */}
                {t("onStorageSuccess.recipientType.withConnector")}
              </Typography>
            </Box>
            <SelectOriginalOrProccessed />
            {actionType === "FORWARD" && (
              <Box>
                <Typography>
                  {/* as an attachment */}
                  {t("onStorageSuccess.asAttachment")}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </OptionGroup>
    </>
  );
}

export function hasDefaultState(rule: Rule) {
  const storage = getStorage(rule);
  return !Boolean(storage.onStorageSuccess?.emailAction);
}

export function setToDefaults(dispatch) {
  const changes: UpdateMoreActions = {
    type: "updateMoreActions",
    payload: {
      onStorageSuccess: { type: "NOTHING" },
    },
  };

  dispatch(changes);
}

function SelectOriginalOrProccessed() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const originalEmail = getAction(state.rule)?.originalEmail;

  return (
    <Select
      variant="outlined"
      value={originalEmail ? "ORIGINAL" : "PROCESSED"}
      onChange={event => {
        const value = event.target.value;
        dispatch({
          type: "updateMoreActions",
          payload: {
            onStorageSuccess: { originalEmail: value === "ORIGINAL" },
          },
        });
      }}
    >
      <MenuItem value="PROCESSED">
        {/* the resulting email (after the processing) */}
        {t("onStorageSuccess.contentToSend.PROCESSED")}
      </MenuItem>
      <MenuItem value="ORIGINAL">
        {/* the original email (before processing) */}
        {t("onStorageSuccess.contentToSend.ORIGINAL")}
      </MenuItem>
    </Select>
  );
}

function InputRecipientAddress() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();

  const recipientAddress = getAction(state.rule)?.recipientAddress;

  return (
    <TextField
      variant="outlined"
      value={recipientAddress}
      style={{ minWidth: "300px", marginLeft: 15 }}
      placeholder={t("onStorageSuccess.recipientType.emailPlaceholder")}
      onChange={event => {
        const email = event.target.value;
        dispatch({
          type: "updateMoreActions",
          payload: {
            onStorageSuccess: { recipientAddress: email },
          },
        });
      }}
      helperText={stepError(state) && t("common:is_required")}
      error={stepError(state)}
    />
  );
}

function SelectRecipientType() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const recipientType = getRecipientType(state.rule);

  function onChangeRecipientType(event) {
    let state: {
      recipientAddress?: string;
      variableId?: string;
    } = {};

    if (event.target.value === "EMAIL") {
      state.recipientAddress = "";
    } else {
      state.variableId = "";
    }

    dispatch({
      type: "updateMoreActions",
      payload: { onStorageSuccess: state },
    });
  }

  return (
    <Select
      style={{ marginLeft: 15 }}
      variant="outlined"
      value={recipientType}
      onChange={onChangeRecipientType}
    >
      <MenuItem value="EMAIL">
        {/* to the account */}
        {t("onStorageSuccess.recipientType.EMAIL")}
      </MenuItem>
      <MenuItem value="VARIABLE">
        {/* to the account resolved by the variable */}
        {t("onStorageSuccess.recipientType.VARIABLE")}
      </MenuItem>
    </Select>
  );
}

function stepError(state: State) {
  return !state.steps.moreActions.done;
}

function getAction(rule: Rule): Storage["onStorageSuccess"]["emailAction"] {
  const storage = getStorage(rule);
  return storage.onStorageSuccess?.emailAction;
}

function getRecipientType(rule: Rule) {
  const emailAction = getAction(rule);
  if (emailAction) {
    if ("recipientAddress" in emailAction) return "EMAIL";
    if ("variableId" in emailAction) return "VARIABLE";
  }
  return "EMAIL";
}

function getActionType(rule: Rule) {
  return getAction(rule)?.type || "NOTHING";
}
