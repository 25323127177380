/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { CircularProgress, Typography, Tooltip, Box } from "@material-ui/core";
import ButtonWithLoading from "components/ButtonWithLoading";
import useSynchronize from "./useSynchronize";
import ItemTitle from "./ItemTitle";
import EditSource from "./EditSource";
import {
  getRunDates,
  getSourceType,
  hasWarnings,
  isOfficeUsersError,
  tsToDateStr,
  initSourceLoading,
  updateSourceLoading,
} from "./helpers";
import { useSourcesContext } from "../sourcesProvider";
import { useAccountState } from "../accountContext";
import { useGroupsState } from "../groupsContext";
import { initOffice365Auth } from "../Buttons/OfficeSourceAuth";
import { ItemContainer, WarningLink } from "../Styled";
import * as api from "../api";
import { Source } from "@dashboard-v3/api";
import { SourceLoading } from "./types";
import Confirm from "components/Confirm";

interface Params {
  source: Source;
}

export default function SourceListItem({ source }: Params) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<SourceLoading>(initSourceLoading);
  const { synchronization: sync } = source;
  const { t, actions: sourceActions } = useSourcesContext();
  const { actions: accountActions } = useAccountState();
  const { actions: groupActions } = useGroupsState();
  const { startSync, isSyncing } = useSynchronize(
    source,
    sourceActions.updateSource,
    () => {
      accountActions.reloadAccounts();
      groupActions.reloadGroups();
    }
  );

  useEffect(() => {
    if (!sync.lastRun && !isSyncing) {
      refreshSync();
    }
  }, [sync?.lastRun]);

  const deleteSource = async () => {
    setLoading(updateSourceLoading("delete", true));
    await sourceActions.deleteSource(source.id);
    accountActions.reloadAccounts();
    groupActions.reloadGroups();
    setLoading(updateSourceLoading("delete", false));
  };

  const refreshSync = async () => {
    try {
      setLoading(updateSourceLoading("refresh", true));
      await api.refreshSync(source.id);
      const updated = await api.fetchSource(source.id);
      startSync();
      sourceActions.updateSource(updated);
    } catch (error) {
      enqueueSnackbar(t("sources.syncError"), {
        variant: "error",
      });
    } finally {
      setLoading(updateSourceLoading("refresh", false));
    }
  };

  const onOfficeReAuth = async () => {
    setLoading(updateSourceLoading("refresh", true));
    try {
      await initOffice365Auth();
      await refreshSync();
    } catch (error) {
      enqueueSnackbar(t("sources.syncError"), {
        variant: "error",
      });
    } finally {
      setLoading(updateSourceLoading("refresh", false));
    }
  };

  const handleWarnings = e => {
    e.preventDefault();
    sourceActions.showWarnings(sync.warnings);
  };

  if (isSyncing) {
    return (
      <ItemContainer data-testid="accounts__source-list-item">
        <ItemTitle source={source} />
        <Box my={1}>
          <CircularProgress size={15} style={{ marginRight: "8px" }} />
          <Typography display="inline">
            {t([
              `sources.step.${source.synchronization.step}`,
              `sources.step.starting`,
            ])}
          </Typography>
        </Box>
        <Box fontWeight="fontWeightLight">
          {t("sources.processWillTakeTime")}
        </Box>
      </ItemContainer>
    );
  }

  return (
    <ItemContainer data-testid="accounts__source-list-item">
      <ItemTitle source={source} />
      {getRunDates(source).map(({ label, date }, i) => (
        <Box key={date} mt={i === 0 ? 1 : 0}>
          <Typography>
            <Tooltip title={tsToDateStr(date)} placement="top">
              <span>{t(label, { date: dayjs(date).fromNow() })}</span>
            </Tooltip>
          </Typography>
        </Box>
      ))}
      {hasWarnings(source) && (
        <Box mt={1}>
          <Typography>
            <Trans i18nKey="sources.endWithWarnings" t={t}>
              Ends with
              <WarningLink
                component="button"
                data-testid="accounts__source-with-errors-link"
                onClick={handleWarnings}
              >
                warnings
              </WarningLink>
            </Trans>
          </Typography>
        </Box>
      )}
      {sync.errorDetails && (
        <Box mt={1}>
          <Typography style={{ marginBottom: "5px" }}>
            {t("sources.errorLabel")}:
          </Typography>
          <Typography variant="caption" color="error">
            {isOfficeUsersError(source)
              ? t("sources.officeAccountsListError")
              : sync.errorDetails}
          </Typography>
        </Box>
      )}

      <Box my={1} display="flex" justifyContent="space-between">
        <Box display="flex">
          {isOfficeUsersError(source) ? (
            <ButtonWithLoading
              data-testid="accounts__refresh-sync-btn"
              onClick={onOfficeReAuth}
              loading={loading.refresh}
            >
              {t("buttons.officeReAuth")}
            </ButtonWithLoading>
          ) : (
            <>
              <ButtonWithLoading
                data-testid="accounts__refresh-sync-btn"
                onClick={refreshSync}
                loading={loading.refresh}
              >
                {t("sources.refreshUpdate")}
              </ButtonWithLoading>
              <EditSource source={source} />
            </>
          )}
        </Box>
        <Confirm
          message={t("buttons.confirmSourceDelete", {
            type: t(`sourceTypes.${getSourceType(source)}`),
          })}
          onAccept={deleteSource}
          actionable={openConfirm => (
            <ButtonWithLoading
              variant="text"
              color="secondary"
              data-testid="accounts__delete-source-btn"
              loading={loading.delete}
              onClick={openConfirm}
            >
              {t("common:delete")}
            </ButtonWithLoading>
          )}
        />
      </Box>
    </ItemContainer>
  );
}
