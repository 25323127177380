import styled from "styled-components";
import { Divider, Paper, TextField } from "@material-ui/core";

export const StyledLoginPanel = styled(Paper)`
  border-radius: 8px;
  display: grid;
  grid-row-gap: 18px;
  justify-items: center;
  min-width: 390px;
  padding: 50px 65px;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;

export const FullWidthContainer = styled.div`
  width: 100%;
`;

export const StyledForm = styled.form`
  display: grid;
  width: 100%;
`;

export const Field = styled(TextField)({
  marginBottom: "30px",
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "98%",
    fontSize: "11px",
  },
});
