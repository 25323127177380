/**
 * Blank values: null, undefined, "" or any string with only white spaces
 */
// eslint-disable-next-line import/prefer-default-export
export function isBlank(value: unknown): value is null | undefined {
  if (value === 0) return false;
  if (value === false) return false;
  if (value?.toString()?.trim()) return false;

  return true;
}

export function isNumber(value: unknown) {
  if (isBlank(value)) return false;
  return Number.isFinite(+value);
}
