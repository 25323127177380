import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { ListHeaderTitle } from "components/List";
import AddAccountButton from "./AddAccountButton";
import AccountItem from "./Item";
import { ListHeader, Panel, StyledList, TextWithButtonWrapper } from "./Styles";
import openAuthPopup, { isClosedByUser } from "./openAuthPopup";
import { AccountType, MailboxProvider, getErrorMsg } from "./helpers";
import {
  MailboxSingleAccount,
  StorageAccount,
  StorageProviderWithAuth,
} from "@dashboard-v3/api";
import { AuthType } from "utils/useMailboxAuthPopup";
import { MailboxSingleAccountProvider } from "../types";

type AccountsProps = {
  storageAccounts: StorageAccount[];
  mailboxAccounts: MailboxSingleAccount[];
  deleteStorageAccount: (accountId: string) => void;
  enableAccount: (account: StorageAccount | MailboxSingleAccount) => void;
  addStorageAccount: (account: StorageAccount) => void;
  addMailboxAccount: (provider: MailboxProvider, authType: AuthType) => void;
  deleteMailboxAccount: (id: string) => void;
};

export default function Accounts({
  storageAccounts,
  mailboxAccounts,
  enableAccount,
  addStorageAccount,
  deleteStorageAccount,
  addMailboxAccount,
  deleteMailboxAccount,
}: AccountsProps) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const accounts = toIntegrationAccounts(storageAccounts, mailboxAccounts);

  const onClickOnEnableAccount = async (
    user: string,
    type: StorageProviderWithAuth | MailboxSingleAccountProvider
  ) => {
    try {
      const accountData = await openAuthPopup(type, user);
      if (accountData) enableAccount(accountData);
    } catch (error) {
      if (isClosedByUser(error)) return;
      const isErrorClass = error instanceof Error;
      let message = getErrorMsg(error, t);

      if (isErrorClass && error.message === "INIT_WITH_DIFFERENT_ACCOUNTS") {
        message = t("error.trySyncWithDifferentAccount", {
          user,
          type: t(`common:integration.provider.${type}`),
        });
      }

      enqueueSnackbar(message, {
        variant: "error",
        resumeHideDuration: 5000,
      });
    }
  };

  if (!storageAccounts && !mailboxAccounts) {
    return (
      <Panel>
        <Typography>{t("error.fetchAccounts")}</Typography>
      </Panel>
    );
  }

  if (!accounts.length) {
    return (
      <Panel>
        <TextWithButtonWrapper>
          <Typography>{t("accountList.noAccounts")}</Typography>
          <AddAccountButton
            addStorageAccount={addStorageAccount}
            addMailboxAccount={addMailboxAccount}
          />
        </TextWithButtonWrapper>
      </Panel>
    );
  }

  return (
    <StyledList disablePadding data-testid="storage__account-list">
      <ListHeader>
        <ListHeaderTitle>{t("account")}</ListHeaderTitle>
        <ListHeaderTitle>{t("accountList.storageService")}</ListHeaderTitle>
        <ListHeaderTitle>{t("accountList.accountType")}</ListHeaderTitle>
        <ListHeaderTitle>{t("accountList.syncStatus")}</ListHeaderTitle>
      </ListHeader>
      {accounts.map(({ id, user, provider, synced, data, accountType }) => (
        <AccountItem
          id={id}
          key={id}
          synced={synced}
          provider={provider}
          accountType={accountType}
          user={data && data.name ? data.name : user}
          deleteStorageAccount={deleteStorageAccount}
          deleteMailboxAccount={deleteMailboxAccount}
          enableAccount={onClickOnEnableAccount}
        />
      ))}
    </StyledList>
  );
}

type IntegrationAccount = {
  id: string;
  user: string;
  provider: StorageProviderWithAuth | MailboxSingleAccountProvider;
  accountType: AccountType;
  role?: string;
  synced: boolean;
  createdAt: number;
  data?: {
    name: string;
    type: "business";
  };
};

const toIntegrationAccounts = (
  storageAccounts: StorageAccount[],
  mailboxAccounts: MailboxSingleAccount[]
): IntegrationAccount[] => {
  if (!storageAccounts.length && !mailboxAccounts.length) return [];
  const storage = storageAccounts.map(account => ({
    ...account,
    provider: account.type,
    accountType: AccountType.Storage,
  }));
  const mailbox = mailboxAccounts.map(({ type, ...rest }) => ({
    ...rest,
    provider: type,
    user: rest.email,
    accountType: AccountType.Mailbox,
  }));

  return [...storage, ...mailbox].sort((a, b) => b.createdAt - a.createdAt);
};
