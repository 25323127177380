import { useState } from "react";

function useFormState<FormType>(initialValues: FormType) {
  const [formState, setFormState] = useState<FormType>(initialValues);

  const updateValues = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
      checked?: boolean;
      type?: string;
    }>
  ) => {
    const { name, value, checked, type } = event.target;
    const valueByType = type === "checkbox" ? checked : value;
    setFormState(prevState => ({
      ...prevState,
      [name]: valueByType,
    }));
  };

  const setValues = (
    values: Partial<FormType> | ((prevState: FormType) => FormType)
  ) => {
    if (typeof values === "function") {
      setFormState(values);
    } else if (values) {
      setFormState(prevState => ({ ...prevState, ...values }));
    }
  };

  function fieldMatches<T extends keyof FormType>(
    field: T,
    values: FormType[T] | Array<FormType[T]>
  ) {
    if (Array.isArray(values)) return values.includes(formState[field]);
    return values === formState[field];
  }

  // type ResetParam = { [key: string]: boolean };
  type ResetParam = { [Property in keyof Partial<FormType>]: boolean };

  const reset = (fields?: ResetParam | string[]) => {
    const fieldNames = Object.keys(initialValues);
    if (!fields) {
      return setFormState(initialValues);
    }
    if (Array.isArray(fields)) {
      const update = fields.reduce((acc, field) => {
        return { ...acc, [field]: initialValues[field] };
      }, {});
      return setFormState(prev => ({ ...prev, ...update }));
    }
    const fieldsToReset = fieldNames.reduce((acc, key) => {
      if (fields[key]) {
        return {
          ...acc,
          [key]: initialValues[key],
        };
      }
      return acc;
    }, {});
    return setFormState(prevState => ({
      ...prevState,
      ...fieldsToReset,
    }));
  };

  return {
    formState,
    updateValues,
    setValues,
    reset,
    fieldMatches,
  };
}

export default useFormState;
