import styled from "styled-components";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { ImportStatus } from ".";
import { green, orange, red } from "@material-ui/core/colors";

export const DropAndDropAreaContainer = styled.div<{ status: ImportStatus }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 250px;
  margin-top: 10px;
  margin-bottom: 18px;
  box-shadow: none;
  border-radius: 6px;
  border: 2px dashed;
  border-color: ${({ status }) => {
    if (status === ImportStatus.Invalid) return red[500];
    return "#009ed2";
  }};
  background-color: ${({ status }) => {
    if (status === ImportStatus.Valid) return "#f2f7ff";
    if (status === ImportStatus.Invalid) return "#FDDBDC";
    return "transparent";
  }};
`;

export const ImportFileContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

export const ImportIcon = styled(CloudUploadOutlinedIcon)`
  font-size: 45px;
  color: rgba(0, 0, 0, 0.54);
  pointer-events: none;
`;

export const SaveProgressText = styled(Typography)<{ isError?: boolean }>`
  display: flex;
  gap: 3px;
  color: ${({ isError }) => (isError ? "#f44336" : "rgba(0, 0, 0, 0.54)")};
  & a {
    cursor: pointer;
  }
  & input {
    display: none;
  }
`;

export const DraggedFileHelper = styled(FormHelperText)`
  position: absolute;
  bottom: 42px;
  pointer-events: none;
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UnfilledProgress = styled(CircularProgress)`
  position: absolute;
  color: rgba(0, 0, 0, 0.1) !important;
`;

export const Progress = styled(CircularProgress)<{ error: boolean }>`
  color: ${({ value, error }) => {
    if (value < 100) return "#009ed2";
    return error ? orange[300] : green[400];
  }} !important;
`;

export const PercentageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 0;
  right: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Percentage = styled(Typography)`
  font-size: 18px;
  font-weight: 200;
`;
