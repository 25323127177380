import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Button,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
  Link as MUILink,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Accordion } from "components/AccordionForm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dayjs from "dayjs";

import { SearchCriteria } from "@dashboard-v3/api";

const DateInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
  width: 100%;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const notificationTypes: Array<SearchCriteria["notificationType"]> = [
  "MAIL",
  "RULE",
  "PLATFORM",
  "STORAGE",
];

const notificationLevels: Array<SearchCriteria["notificationLevel"]> = [
  "INFO",
  "WARN",
  "ERROR",
];

const dateFormat = "YYYY-MM-DD";

interface Props {
  onSubmit: (event) => void;
}

function now() {
  return dayjs();
}
function lastWeek() {
  return dayjs().subtract(7, "days");
}

const SearchFilter = ({ onSubmit }: Props) => {
  const { t } = useTranslation("notifications");

  const handleSubmit = async e => {
    e.preventDefault();
    const { since, until, notificationLevel, notificationType } = e.target;
    const filterCriteria = {
      notificationLevel: notificationLevel.value,
      notificationType: notificationType.value,
      since: dayjs(since.value).valueOf(),
      until: dayjs(until.value).endOf("day").valueOf(),
    } as SearchCriteria;

    onSubmit(filterCriteria);
  };

  useEffect(() => {
    const startingCriteria = {
      notificationLevel: "",
      notificationType: "",
      since: dayjs(lastWeek().format(dateFormat)).valueOf(),
      until: dayjs(now().format(dateFormat)).endOf("day").valueOf(),
    };
    onSubmit(startingCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t("searchNotifications")}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Typography color="textSecondary" variant="body2">
            {t("dateRange")}:
          </Typography>
          <DateInputContainer>
            <TextField
              name="since"
              label={t("fromDate")}
              type="date"
              defaultValue={lastWeek().format(dateFormat)}
              variant="outlined"
            />

            <TextField
              name="until"
              label={t("common:toDate")}
              type="date"
              defaultValue={now().format(dateFormat)}
              variant="outlined"
            />
          </DateInputContainer>

          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <Typography color="textSecondary" variant="body2">
              {t("notification.level.label")}
            </Typography>
            <RadioGroup defaultValue="" name="notificationLevel" row>
              <FormControlLabel
                control={<Radio size="small" color="primary" />}
                label={t("common:all")}
                value=""
              />
              {notificationLevels.map(level => (
                <FormControlLabel
                  control={<Radio size="small" color="primary" />}
                  label={t(`notification.level.${level}`)}
                  key={level}
                  value={level}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <Typography color="textSecondary" variant="body2">
              {t("notification.type.label")}
            </Typography>
            <RadioGroup name="notificationType" defaultValue={""} row>
              <FormControlLabel
                control={<Radio size="small" color="primary" />}
                label={t("common:all")}
                value=""
              />
              {notificationTypes.map(type => (
                <FormControlLabel
                  control={<Radio size="small" color="primary" />}
                  label={t(`notification.type.${type}`)}
                  key={type}
                  value={type}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <FooterContainer>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
            >
              {t("common:search")}
            </Button>
            <MUILink component={RouterLink} to="/notification-settings">
              {t("settingsLink")}
            </MUILink>
          </FooterContainer>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchFilter;
