import { createTheme } from "@material-ui/core";

const mxHeroTheme = {
  typography: {
    fontFamily: "Rubik, Arial",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#009ed2",
    },
  },
};

export default createTheme(mxHeroTheme);
