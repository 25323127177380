import { Typography } from "@material-ui/core";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

export default function FromToHelp() {
  const { t } = useTranslation("fromToSelector");
  const containerStyles: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  return (
    <div style={containerStyles}>
      <div>
        <Typography variant="subtitle2">{t("sender")}</Typography>
        <Typography variant="caption">{t("senderHelp")}</Typography>
      </div>
      <div>
        <Typography variant="subtitle2">{t("recipient")}</Typography>
        <Typography variant="caption">{t("recipientHelp")}</Typography>
      </div>
    </div>
  );
}
