import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ConfirmButton = ({ onClick, disabled }) => {
  const { t } = useTranslation("rules");

  return (
    <Button
      color="primary"
      data-testid="target__confirm-btn"
      disabled={disabled}
      disableElevation
      fullWidth
      onClick={onClick}
      size="large"
      style={{ marginTop: "24px" }}
      variant="contained"
    >
      {t("confirm")}
    </Button>
  );
};

export default ConfirmButton;
