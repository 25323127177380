/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apiRequest from "utils/apiRequestWithErrorCode";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { PropertyData } from "../../Styled";

import { CopyPolicy, Rule } from "@dashboard-v3/api";

interface Props {
  rule: Rule;
  details: Record<string, any>;
}

export default function NoFolderFound({ rule, details }: Props) {
  const { t } = useTranslation("tracing");
  const [copyPolicy, setCopyPolicy] = useState<CopyPolicy>();
  const classes = useStyles();

  useEffect(() => {
    const policyId = getCopyPolicyId(rule);

    async function fetchCopyPolicy(id: string) {
      try {
        const policy = await apiRequest<CopyPolicy>(
          "GET",
          `/copy-policies/${id}`
        );
        setCopyPolicy(policy);
      } catch (e) {
        console.error(`Copy policy with id ${id} not found.`);
      }
    }

    if (policyId && !copyPolicy) {
      fetchCopyPolicy(policyId);
    }
  }, [rule]);

  return (
    <Grid item xs={12}>
      <PropertyData
        className={classes.message}
        variant="subtitle2"
        color="textSecondary"
      >
        {t("noFolderFoundEvent.message")}
      </PropertyData>
      <Grid item xs={6}>
        <Card variant="outlined">
          <CardContent>
            <Typography
              className={classes.detailsTitle}
              variant="subtitle2"
              color="textPrimary"
              gutterBottom
            >
              {t("noFolderFoundEvent.details.title")}
            </Typography>
            <Typography
              className={classes.detailsBodyText}
              color="textSecondary"
            >
              {JSON.stringify(details)}
            </Typography>
            {rule && (
              <Typography
                className={classes.detailsBodyText}
                color="textSecondary"
              >
                {t("noFolderFoundEvent.details.ruleName", { name: rule.name })}
              </Typography>
            )}
            {copyPolicy && (
              <Typography
                className={classes.detailsBodyText}
                color="textSecondary"
              >
                {t("noFolderFoundEvent.details.copyPolicyName", {
                  name: copyPolicy.name,
                })}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            {rule && (
              <Button
                color="primary"
                size="small"
                href={`/rules/${rule.id}/edit`}
                target="_blank"
              >
                {t("noFolderFoundEvent.details.goToRule")}
              </Button>
            )}
            {copyPolicy && (
              <Button
                color="primary"
                size="small"
                href={`/copy-policies/${copyPolicy.id}/edit`}
                target="_blank"
              >
                {t("noFolderFoundEvent.details.goToCopyPolicy")}
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

const getCopyPolicyId = (rule: Rule) => {
  if (!rule) return "";
  const ruleType = rule.actionType
    .split("_")
    .map((t, i) => {
      const type = t.toLowerCase();
      if (i === 0) return type;
      return `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
    })
    .join("");

  return rule[ruleType]?.storage?.location?.copyPolicy || "";
};

const useStyles = makeStyles({
  message: { marginTop: 10, marginBottom: 12, fontSize: 14 },
  detailsTitle: { fontSize: 14 },
  detailsBodyText: { marginTop: 14 },
});
