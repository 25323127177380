/* eslint-disable no-undef */
/* eslint-disable no-console */
import Script from "@gumgum/react-script-tag";
import { useEffect } from "react";
import { PickerParams } from "../types";

declare var google: any;

// API Documentation: https://developers.google.com/picker/docs
// The Browser API key obtained from the Google API Console.
// Replace with your own Browser API key, or your own key.
const developerKey = process.env.REACT_APP_GOOGLE_PICKER_KEY;

// The Client ID obtained from the Google API Console.
// Replace with your own Client ID.
const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

// Replace with your own project number from console.developers.google.com.
// See "Project number" under "IAM & Admin" > "Settings"
const appId = process.env.REACT_APP_GOOGLE_PICKER_APP_ID;

// Scope to use to access user's Drive items.
const scope = ["https://www.googleapis.com/auth/drive.file"];

let authLoaded = false;
let pickerLoaded = false;
let alreadyOpen = false;

function createPicker(onChange, accessToken) {
  const pickerCallback = data => {
    if (data.action === "cancel") {
      onChange(null);
      return;
    }

    if (data.action === google.picker.Action.PICKED) {
      const { name, id } = data.docs[0];
      onChange({ parentName: `.../${name}`, parentId: id });
    }
  };

  const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS);
  view.setSelectFolderEnabled(true);
  view.setIncludeFolders(true);
  view.setParent("root");

  const picker = new google.picker.PickerBuilder()
    .setAppId(appId)
    .setOAuthToken(accessToken)
    .addView(view)
    .addView(new google.picker.DocsUploadView())
    .setSelectableMimeTypes("application/vnd.google-apps.folder")
    .setDeveloperKey(developerKey)
    .setSize(850, 500)
    .setTitle("Select the location to save your files")
    .setCallback(pickerCallback)
    .build();

  picker.setVisible(true);
  document.querySelector<HTMLElement>("div.picker-dialog-bg").style.zIndex =
    "1250";
  document.querySelectorAll<HTMLElement>(".picker-dialog").forEach(elem => {
    elem.style.zIndex = "1300";
  });
}

const GooglePicker = ({ onChange, open }: PickerParams) => {
  useEffect(() => {
    authAndOpen();

    return () => {
      alreadyOpen = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoaded, pickerLoaded, open]);

  function handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
      const oauthToken = authResult.access_token;
      createPicker((...params) => {
        alreadyOpen = false;
        onChange(...params);
      }, oauthToken);
    } else {
      alreadyOpen = false;
      onChange(null);
    }
  }

  function authAndOpen() {
    if (authLoaded && pickerLoaded && open && !alreadyOpen) {
      alreadyOpen = true;
      window.gapi.auth.authorize(
        {
          client_id: clientId,
          scope,
          immediate: false,
          authuser: -1,
        },
        handleAuthResult
      );
    }
  }

  return (
    <Script
      src="https://apis.google.com/js/api.js"
      onLoad={() => {
        gapi.load("auth", {
          callback: () => {
            authLoaded = true;
          },
        });

        gapi.load("picker", {
          callback: () => {
            pickerLoaded = true;
          },
        });
      }}
      type="text/javascript"
    />
  );
};

export default GooglePicker;
