import useSWR from "swr";
import { getDkimRecords } from "./api";
import { getDomainsList } from "../api";
import { DKIMRecord } from "@dashboard-v3/api";

export type DomainDkimState = {
  domain: string;
  dkimState: "NONE" | "PENDING" | "ENABLE";
};

export default function useRecords() {
  const { data: records, mutate: updateRecords } = useSWR(
    "/dkim",
    getDkimRecords,
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  const { data: domains } = useSWR("/domains", getDomainsList, {
    revalidateOnFocus: false,
    suspense: true,
  });

  const statesByDomain = domains.reduce((all, domain) => {
    const domainDkimState = dkimState(domain.domain, records);
    all[domainDkimState.domain] = domainDkimState;
    domain.aliases.forEach(alias => {
      if (!Boolean(all[alias])) {
        all[alias] = dkimState(alias, records);
      }
    });

    return all;
  }, {} as Record<string, DomainDkimState>);

  const domainDkimState = Object.values(statesByDomain);

  return {
    domainDkimState,
    updateRecords,
    records,
  };
}

function dkimState(domain: string, records: DKIMRecord[]): DomainDkimState {
  // domain could be present
  // + zero times -> DKIM is not enabled,
  // + one time -> DKIM is PEDING or ENABLED
  // + twice -> DKIM is PENDING (because the second one is PENDING, there never could be two in a pending state)
  let dkimState: DomainDkimState["dkimState"] = "NONE";

  const found = records.filter(record => record.domain === domain);
  if (found) {
    dkimState = found.some(record => !record.isVerified) ? "PENDING" : "ENABLE";
  }

  return {
    domain,
    dkimState,
  };
}
