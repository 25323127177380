import { useTranslation } from "react-i18next";
import { List as MuiList, Typography } from "@material-ui/core";
import styled from "styled-components";
import ButtonWithLoading from "../ButtonWithLoading";

export const List = ({ children, ...rest }) => {
  return <StyledList {...rest}>{children}</StyledList>;
};

export const ListHeader = ({ children, ...rest }) => {
  return <StyledDiv {...rest}>{children}</StyledDiv>;
};

export const ListHeaderTitle = ({ children, ...rest }) => {
  return <StyledTypography {...rest}>{children}</StyledTypography>;
};

type Props = {
  loading: boolean;
  onClick: () => void;
};

export const ShowMoreBtn = ({ loading, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonWithLoading
      style={{ marginTop: "10px" }}
      data-testid={`showMoreBtn${loading ? "-loading" : ""}`}
      color="primary"
      disableElevation
      variant="contained"
      loading={loading}
      onClick={onClick}
    >
      {t("common:showMore")}
    </ButtonWithLoading>
  );
};

const StyledDiv = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 20px;
  justify-items: start;
  padding: 0 20px;
`;

const StyledTypography = styled(Typography)`
  color: grey;
  font-size: 0.82rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const StyledList = styled(MuiList)`
  margin-top: 20px;
`;
