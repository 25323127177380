import { AIPredicateData } from "@dashboard-v3/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";

export default function useIaContext() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("variables");

  const { data, error } = useSWR<AIPredicateData>(
    "/platform/ai-context",
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  if (error) {
    enqueueSnackbar(t("errors.variables.aiContext"), { variant: "error" });
  }

  return { iaContext: data };
}
