import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { addUserInSession } from "utils/authentication";
import { checkToken, changePassword } from "./api";
import {
  getErrorCode,
  getErrorDescription,
} from "utils/apiRequestWithErrorCode";
import { Users } from "@dashboard-v3/api";
import PasswordErrorHelper from "components/PaswordInput/PasswordErrorHelper";
import { ValidationKey } from "@dashboard-v3/api/src/schemas/users";

const StyledLoginPanel = styled(Paper)`
  border-radius: 8px;
  display: grid;
  grid-row-gap: 18px;
  justify-items: center;
  width: 390px;
  padding: 50px 65px;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const ConfirmPasswordPanel = () => {
  const [loading, setLoading] = useState(true);
  const [tokenError, setTokenError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [email, setEmail] = useState<string>();
  const [showValidationErrors, setShowValidationErrors] =
    useState<Users.Errors[]>();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation(["confirmPassword", "users"]);

  useEffect(() => {
    async function fetchData() {
      try {
        setEmail(await checkToken(token));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setTokenError(true);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [token]);

  const handleFormSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    if (newPassword !== confirmedPassword) {
      setLoading(false);
      enqueueSnackbar(t("passwordsDontMatch"), {
        variant: "error",
      });
      return;
    }

    try {
      const { user, sessionDuration } = await changePassword(
        token,
        newPassword
      );
      addUserInSession(user, sessionDuration);
      history.push("/");
    } catch (error) {
      const description = getErrorDescription(error);
      const errorCode = getErrorCode(error);

      if (errorCode === "INVALID_PASSWORD") {
        if ("validations" in (description as object)) {
          const validations = (
            description as Users.ValidationError["description"]
          ).validations as ValidationKey[];

          setShowValidationErrors(validations);
        }
      } else {
        const errorMessage = t([
          `error.${description}`,
          "error.SYSTEM_FAILURE",
        ]);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <StyledLoginPanel>
        <CircularProgress />
      </StyledLoginPanel>
    );
  }

  if (tokenError) {
    return (
      <StyledLoginPanel>
        <FullWidthContainer>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "5px" }}
          >
            {t("errorWhileValidating")}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "5px" }}
          >
            {t("validationErrorExplanation")}
          </Typography>
        </FullWidthContainer>
        <Button
          data-testid="landing__switch-signin-btn"
          onClick={() => history.push("/login")}
          size="large"
        >
          {t("switchToSignIn")}
        </Button>
        <Button
          data-testid="login__forgot-pass-btn"
          onClick={() => history.push("/reset-password")}
          size="large"
        >
          {t("forgotPassword")}
        </Button>
      </StyledLoginPanel>
    );
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <StyledLoginPanel style={{ justifyItems: "left" }}>
        <FullWidthContainer>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "20px" }}
          >
            <Trans i18nKey="title" email={email}>
              <strong>{email}</strong> you are about to change your password
            </Trans>
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "5px" }}>
            {t("newPassword")}
          </Typography>
          <Box mb="18px">
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "confirmpass__new-pass-input",
              }}
              onChange={e => {
                setNewPassword(e.target.value);
              }}
              placeholder={t("password")}
              required
              size="small"
              type="password"
              value={newPassword}
              variant="outlined"
            />
            {showValidationErrors && (
              <Box mb={2} color="red">
                <PasswordErrorHelper errors={showValidationErrors} />
              </Box>
            )}
          </Box>
          <Typography variant="body2" style={{ marginBottom: "5px" }}>
            {t("confirmPassword")}
          </Typography>
          <TextField
            fullWidth
            inputProps={{
              "data-testid": "confirmpass__confirm-pass-input",
            }}
            onChange={e => {
              setConfirmedPassword(e.target.value);
            }}
            placeholder={t("password")}
            required
            size="small"
            style={{ marginBottom: "18px" }}
            type="password"
            value={confirmedPassword}
            variant="outlined"
          />
        </FullWidthContainer>
        <Button
          color="primary"
          data-testid="confirmpass__save-change-btn"
          disableElevation
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t("resetBtn")}
        </Button>
      </StyledLoginPanel>
    </form>
  );
};

export default ConfirmPasswordPanel;
