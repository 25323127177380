import { Card, CardContent, Grid, Typography } from "@material-ui/core";

export default function FailDetails({ details }) {
  const validDetails = details && !!Object.keys(details).length;

  if (validDetails)
    return (
      <Grid item xs={6}>
        <Card variant="outlined">
          <CardContent>
            <Typography color="textSecondary">
              {JSON.stringify(details)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );

  return null;
}
