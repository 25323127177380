import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Avatar,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { o365gateway } from "@dashboard-v3/api";

type Params = {
  open: boolean;
  onClose: () => void;
  onSelect: (flowType: o365gateway.Flow, saveAsDisabled: boolean) => void;
};

export default function FlowSelectionDialog({
  open,
  onClose,
  onSelect,
}: Params) {
  const { t } = useTranslation("gateway");
  const [saveAsDisabled, setSaveAsDisabled] = useState(false);

  function handleSelect(flowType: o365gateway.Flow) {
    onSelect(flowType, saveAsDisabled);
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="simple-dialog-title">
      <StyledTitle>
        <Typography variant="h6">{t("flowModal.title")}</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </StyledTitle>
      <List>
        <ListItem button onClick={() => handleSelect("BOTH")}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "black" }}>
              <ImportExportIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("flow.BOTH")} />
        </ListItem>
        <ListItem button onClick={() => handleSelect("OUTBOUND")}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "black" }}>
              <ArrowUpwardIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("flow.OUTBOUND")} />
        </ListItem>
        <ListItem button onClick={() => handleSelect("INBOUND")}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "black" }}>
              <ArrowDownwardIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("flow.INBOUND")} />
        </ListItem>
        <Divider style={{ marginTop: 8 }} />
        <ListItem style={{ paddingTop: "5px", paddingBottom: "0" }}>
          <FormControlLabel
            label={<ListItemText primary={t("flow.disabledCheck")} />}
            control={
              <Checkbox
                name="saveAsDisabled"
                color="primary"
                checked={saveAsDisabled}
                onChange={e => setSaveAsDisabled(e.target.checked)}
              />
            }
          />
        </ListItem>
      </List>
    </Dialog>
  );
}

export const StyledTitle = styled(DialogTitle)`
  padding: 13px 60px 6px 20px;
  button {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    padding: 3px;
    .MuiSvgIcon-root {
      width: 21px;
      height: 21px;
    }
  }
`;

export const SwitchContainer = styled.div`
  padding: 8px 16px 16px 16px;
  max-width: 270px;
`;
