import React, { SetStateAction, useState } from "react";
import produce, { Draft } from "immer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import NameAndDescription from "./sections/NameAndDescription";
import ConditionsSection from "./sections/ConditionsSection";
import PillButton from "components/Forms/PillButton";
import OnNotMatch from "./OnNotMatch";
import { StyledPaper, VerticalLine } from "components/Styled";
import useVariables from "utils/useVariables";
import { TestingState } from ".";
import PredicateTester from "./PredicateTester";
import { defaultAddCondition } from "./state/ConditionsProvider";
import { Variable, KeyedVariable } from "@dashboard-v3/api";
import PageLoading from "components/PageLoading";
import { FormAction } from "./types";

type FormProps = {
  variable: KeyedVariable;
  formAction: FormAction;
  variableTestProps: {
    variableTest: TestingState;
    setVariableTest: React.Dispatch<SetStateAction<TestingState>>;
  };
};

export default function VariablesForm({
  variable: initialValue,
  formAction,
  variableTestProps,
}: FormProps) {
  const { t } = useTranslation("variables");
  const [variable, setVariable] = useState(initialValue);

  const [showDefault, setShowDefault] = useState<boolean>(
    Boolean(variable.defaultValue)
  );
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  const { variableTest } = variableTestProps;
  const { createUserVariable, updateUserVariable } = useVariables();

  const { children: conditionBlocks } = variable.predicate;
  const isReadOnly = Boolean(variable?.platformVariable);

  const updateVariable = (
    updateFn: (draft: Draft<KeyedVariable>) => void
  ): Variable => {
    const newState = produce(variable, updateFn);
    setVariable(newState);
    global.variable = newState;
    return newState;
  };

  const handleDefaultValue = newValue => {
    updateVariable(draft => {
      draft.defaultValue = newValue;
    });
  };

  function handleDefaultBlock(turnOn: boolean) {
    setShowDefault(turnOn);
    if (!turnOn) {
      updateVariable(draft => {
        draft.defaultValue = null;
      });
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isReadOnly) return false;

    setIsSaving(true);

    if (formAction === "edit") {
      const varId = variable.id;
      await updateUserVariable(varId, variable);
    } else {
      await createUserVariable(variable);
    }
    setIsSaving(false);
    history.push("/variables", { tab: "variables" });
  };

  const addConditionsSection: () => void = () => {
    const condition = defaultAddCondition();

    updateVariable(draft => {
      draft.predicate.children.push(condition);
    });
  };

  const cannotSave = () => {
    if (!variable.name) return true;
    if (showDefault) return !variable.defaultValue;
    return variableTest.disabled;
  };

  return (
    <form onSubmit={handleSubmit}>
      <PageLoading loading={isSaving} />
      <Grid container spacing={4}>
        <Grid item xs={7}>
          {conditionBlocks.map((item, index) => (
            <ConditionsSection
              key={`predicate-${item.key}`}
              index={index}
              variable={variable}
              updateVariable={updateVariable}
              variableTestProps={variableTestProps}
            />
          ))}
          {isReadOnly ? (
            <VerticalLine size="sm" />
          ) : (
            <PillButton
              wording={t("form.condition.btn")}
              onClick={addConditionsSection}
              lineSeparator="full"
            />
          )}

          <OnNotMatch
            value={variable.defaultValue}
            checked={showDefault}
            onChange={(type, value) => {
              if (type === "value") handleDefaultValue(value);
              if (type === "checked") handleDefaultBlock(value as boolean);
            }}
            disabled={isReadOnly}
          />
          {!isReadOnly && (
            <>
              <VerticalLine size="sm" />

              <StyledPaper style={{ padding: "25px 24px" }}>
                <Button
                  fullWidth
                  type="submit"
                  wording={t("form.save.btn")}
                  disabled={cannotSave()}
                />
              </StyledPaper>
            </>
          )}
        </Grid>
        <Grid item xs={5}>
          {variableTest.isTesting ? (
            <PredicateTester variable={variable} variableTest={variableTest} />
          ) : (
            <NameAndDescription
              variable={variable}
              onChange={(field, value) => {
                updateVariable(draft => {
                  draft[field] = value;
                });
              }}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
}
