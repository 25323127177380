import useServiceStatus from "./useServiceStatus";
import { StatusColorIcon, StyledButton } from "../Styled";

export default function StatusBtn() {
  const serviceStatus = useServiceStatus();

  if (!serviceStatus) {
    return null;
  }

  return (
    <StyledButton
      href="http://status.mxhero.com/"
      /* @ts-ignore */
      target="_blank"
      data-testid="status"
      rel="noreferrer"
    >
      <StatusColorIcon state={serviceStatus.indicator} />
      {serviceStatus.description}
    </StyledButton>
  );
}
