import { Link as RouterLink, useLocation } from "react-router-dom";
import { StyledListItem, StyledListItemText, useStyles } from "./Styled";

interface Props {
  route: string;
  label: string;
}

export default function SidebarItem({ route, label }: Props) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const selected = pathname.includes(route);
  const ItemTextStyles = {
    color: selected ? "black" : "#5f6061",
    fontWeight: "500",
  };

  return (
    <StyledListItem
      dense
      button
      className={classes.nestedSecond}
      /* @ts-ignore */
      component={RouterLink}
      to={route}
      selected={selected}
      data-testid={`menuItem ${label.replace("/", "")}`}
    >
      <StyledListItemText
        primary={label}
        primaryTypographyProps={{ style: ItemTextStyles }}
      />
    </StyledListItem>
  );
}
