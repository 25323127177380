/**
 *
 * @param {(Array | null)} maybeArray
 * @returns {(null | true | false)}
 */
// eslint-disable-next-line import/prefer-default-export
export const isEmptyArray = maybeArray => {
  if (!Array.isArray(maybeArray || [])) return null;
  return (maybeArray || []).length === 0;
};
