/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { getStorageWebhook } from "pages/Rules/CreateOrEdit/context/helpers";
import { State } from "pages/Rules/CreateOrEdit/context/types";
import BoxPicker from "pages/Rules/StorageFilepath/FilepathSelector/PickerModal/BoxPicker";
import {
  StorageFolder,
  WebhookFolder,
} from "pages/Rules/StorageFilepath/FilepathSelector/types";
import AdvancedOptions from "./AdvancedOptions";
import { WebHookContainer } from "./Styled";
import { handleWebhookCheck, toWebhookFolders } from "./utils";
import FolderPathList from "./FolderPathList";
import ConfirmButton from "../ConfirmButton";

import { WebhookCheckResponse } from "@dashboard-v3/api";

export type FoldersInUse = WebhookCheckResponse["foldersInUse"];
export type MissingFolder = WebhookCheckResponse["missingFolder"];

function StorageWebhookOptions() {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { action, id } = useParams();
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const { state, dispatch } = useRuleContext();
  const webhook = getStorageWebhook(state.rule);

  useEffect(() => {
    if (id && action === "copy") {
      dispatch({
        type: "updateStep",
        payload: {
          step: "target",
          changes: {
            folders: [],
          },
        },
      });
      enqueueSnackbar(t("webhookSection.warning.foldersRemoved"), {
        variant: "info",
      });
    }
  }, []);

  const handleFolderSelection = async (
    folders: WebhookFolder[],
    account: string
  ) => {
    const webhookUpdate = produce(webhook, draft => {
      const update = folders.reduce((acc, { id, path }) => {
        const match = draft.folders.find(f => f.id === id);
        return !match ? [...acc, { id, path }] : acc;
      }, []);

      draft.folders = [...draft.folders, ...update];

      if (!draft.account) draft.account = account;
    });
    const webhookStatus = await handleWebhookCheck(
      webhookUpdate.folders,
      webhookUpdate.account,
      state.rule.id
    );

    dispatch({
      type: "updateStep",
      payload: { step: "target", changes: { ...webhookUpdate, webhookStatus } },
    });
  };

  const handlePicker = async (items: StorageFolder[], account: string) => {
    if (account && webhook.account && account !== webhook.account) {
      enqueueSnackbar(
        t("webhookSection.error.differentAccount", {
          account: webhook.account,
        }),
        {
          variant: "error",
        }
      );
    } else if (items) {
      await handleFolderSelection(toWebhookFolders(items), account);
    }
    setOpenPicker(false);
  };

  return (
    <>
      <WebHookContainer>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          style={{ fontWeight: "500" }}
        >
          {t("webhookSection.title")}
        </Typography>
        <FolderPathList setOpenModal={setOpenPicker} />
        {webhook.account && (
          <>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ paddingLeft: "15px" }}
            >
              {t("webhookSection.webhookAccount")}{" "}
              <Box component="span" fontWeight="500">
                {webhook.account}
              </Box>
            </Typography>
          </>
        )}
        <ConfirmButton
          onClick={() => dispatch({ type: "confirmStep", payload: "target" })}
          disabled={isConfirmDisabled(state)}
        />
        <BoxPicker multiSelect onChange={handlePicker} open={openPicker} />
      </WebHookContainer>
      <AdvancedOptions />
    </>
  );
}

function isConfirmDisabled(state: State) {
  if (state.steps?.target?.done) return true;
  return !state.steps?.target?.valid;
}

export default StorageWebhookOptions;
