import { ChangeEventHandler, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest, { getErrorStatus } from "utils/apiRequestWithErrorCode";

import { TestSources } from "@dashboard-v3/api";
import { uploadFileLimit } from "@dashboard-v3/api";
import { useSnackbar } from "notistack";
import formatBytes from "utils/formatBytes";

interface Props {
  onChange: (name: string, sources: TestSources) => void;
  isReload: boolean;
  disabled: boolean;
}

export default function UploadEmailButton({
  onChange,
  disabled,
  isReload,
}: Props) {
  const { t } = useTranslation("variables");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>();
  const btnTranslation = isReload
    ? "variableTester.uploadBtn.modify"
    : "variableTester.uploadBtn.load";

  const handleUpload: ChangeEventHandler<HTMLInputElement> = async e => {
    const { files } = e.target;
    const file = files[0];
    const form = new FormData();
    if (file.size > uploadFileLimit) {
      enqueueSnackbar(
        t("variableTester.uploadEmailToBig.error", {
          size: formatBytes(uploadFileLimit),
        }),
        { variant: "error" }
      );
      return;
    }
    form.append("file", file);
    setLoading(true);
    try {
      const res = await apiRequest<TestSources>(
        "POST",
        "/platform/extract-sources",
        form
      );
      onChange(file.name, res);
    } catch (error) {
      console.error(error);
      if (getErrorStatus(error) === 413) {
        enqueueSnackbar(
          t("variableTester.uploadEmailToBig.error", {
            size: formatBytes(uploadFileLimit),
          }),
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(t("variableTester.uploadBtn.error"), {
          variant: "error",
        });
      }
    } finally {
      fileInputRef.current.value = "";
      setLoading(false);
    }
  };

  return (
    <>
      <ButtonWithLoading
        size="large"
        variant="text"
        loading={loading}
        disabled={disabled}
        onClick={() => fileInputRef.current.click()}
      >
        {t(btnTranslation)}
      </ButtonWithLoading>
      <input
        type="file"
        ref={fileInputRef}
        accept=".eml, .msg"
        onChange={handleUpload}
        value={fileInputRef.current?.value ?? ""}
        style={{ display: "none" }}
      />
    </>
  );
}
