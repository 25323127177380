import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, List, Button } from "@material-ui/core";
import AccountItem from "./AccountItem";
import { ImportCSVResult } from "../utils";
import { ShowMoreContainer } from "./styled";

type Props = {
  file: ImportCSVResult;
};

export default function LoadedAccounts({ file }: Props) {
  const { t } = useTranslation("accounts");
  const [listItems, setListItems] = useState(20);
  const { name, accounts } = file;

  return (
    <List>
      <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
        {t("importAccountsModal.filename", { name })}
      </Typography>
      {accounts.slice(0, listItems).map(account => (
        <AccountItem account={account} />
      ))}
      {accounts.length > listItems && (
        <ShowMoreContainer>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disableElevation
            onClick={() => setListItems(prev => prev + 10)}
          >
            {t("importAccountsModal.accounts.showMoreBtn")}
          </Button>
        </ShowMoreContainer>
      )}
    </List>
  );
}
