import { StatConfig } from "../types";

const colorAlpha = "0.2";

const color = {
  one: ["rgb(0, 158, 210)", `rgb(0, 158, 210, ${colorAlpha})`],
  two: ["rgb(85, 0, 255)", `rgb(85, 0, 255, ${colorAlpha})`],
  three: ["rgb(0, 106, 255)", `rgb(0, 106, 255, ${colorAlpha})`],
  four: ["rgb(0, 207, 210)", `rgb(0, 207, 210, ${colorAlpha})`],
};

export const chartBars: StatConfig[] = [
  {
    dataKey: "bodies",
    name: "uploadedFiles.bodies",
    fill: color.one[0],
    enabled: true,
  },
  {
    dataKey: "emails",
    name: "uploadedFiles.emails",
    fill: color.two[0],
    enabled: true,
  },
  {
    dataKey: "attachments",
    fill: color.three[0],
    name: "uploadedFiles.attachments",
    enabled: true,
  },
];
