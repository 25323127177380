import apiRequest from "utils/apiRequestWithErrorCode";
import type { User, Users } from "@dashboard-v3/api";

export async function fetchUserList() {
  return apiRequest<Users.List>("GET", "/users");
}

export async function deleteUser(userId) {
  return apiRequest("DELETE", `/users/${userId}`);
}

export async function updateUser(id: string, changes: Users.Update) {
  return apiRequest<User>("PATCH", `/users/${id}`, changes);
}

export type UserCreate = {
  name?: string;
};

export async function createUser(userData: Users.Create) {
  return apiRequest<Users.Item>("POST", "/users", userData);
}
