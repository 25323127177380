import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import { StatusDiv } from "./Styles";
import StatusIcon from "components/StatusIcon";

interface AccountStatusProps {
  synced: boolean;
  enableAccount: () => void;
}

export default function AccountStatus({
  synced,
  enableAccount,
}: AccountStatusProps) {
  const { t } = useTranslation("storage");

  if (synced)
    return (
      <StatusDiv data-testid="storage__synced-label">
        <StatusIcon state="enable" />
        {t("synced")}
      </StatusDiv>
    );

  const handleEnable = e => {
    e.preventDefault();
    enableAccount();
  };

  return (
    <StatusDiv data-testid="storage__not-synced-label">
      <StatusIcon state="disable" />
      {t("notSynced")}{" "}
      <Link
        color="textSecondary"
        data-testid="storage__enable-account-btn"
        href="#"
        onClick={handleEnable}
        style={{ marginLeft: "7px", cursor: "pointer" }}
      >
        {t("enable")}
      </Link>
    </StatusDiv>
  );
}
