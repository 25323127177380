import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest from "utils/apiRequestWithErrorCode";
import ApplicationStatus, { SyncStatus } from "./ApplicationStatus";
import { StorageSettingsUpdate } from "../types";

import { StorageSettings, TestSourceResponse } from "@dashboard-v3/api";

interface Props {
  settings: StorageSettings;
  onAccountSync: (update: StorageSettingsUpdate) => void;
}

export default function OfficeDirectorySync({
  settings,
  onAccountSync,
}: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { office365 } = settings;

  const status = office365.directory
    ? SyncStatus.INSTALLED
    : SyncStatus.NOT_INSTALLED;

  const onSourceAdd = () => {
    history.push({
      pathname: "/accounts",
      state: { action: "ADD", type: "OFFICE365" },
    });
  };

  const onVerify = async () => {
    setLoading(true);
    try {
      const res = await apiRequest<TestSourceResponse>(
        "GET",
        `/sources/OFFICE365/verify`
      );

      onAccountSync({
        ...settings,
        office365: { ...settings.office365, directory: res.sucess },
      });
    } catch (e) {
      enqueueSnackbar(t("officeDirectorySync.verify.unknownError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <ApplicationStatus status={status} />
      {status === SyncStatus.NOT_INSTALLED && (
        <ButtonWithLoading
          variant="text"
          color="default"
          loading={loading}
          onClick={onSourceAdd}
        >
          {t("officeDirectorySync.addSource")}
        </ButtonWithLoading>
      )}
      {status === SyncStatus.INSTALLED && (
        <ButtonWithLoading
          variant="text"
          color="default"
          loading={loading}
          onClick={onVerify}
        >
          {t("verify")}
        </ButtonWithLoading>
      )}
    </div>
  );
}
