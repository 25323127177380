import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as keyGen } from "uuid";
import {
  Badge,
  Box,
  Fab,
  Grow,
  MenuItem,
  MenuProps,
  Paper,
  Popper,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

interface TagFilterProps {
  tags: string[];
  selectedTag: string;
  onFilterChange: (tag: string) => void;
}

export default function TagFilter({
  tags,
  selectedTag,
  onFilterChange,
}: TagFilterProps) {
  const { t } = useTranslation("components");
  const [showFilters, setShowFilters] = useState(false);
  const anchorRef = useRef(null);
  const tagFilterOptions = toTagFilterOptions(tags);
  const tooltipMessage = selectedTag
    ? t("accountSearch.tagFilter.tooltip.selected", { selectedTag })
    : t("accountSearch.tagFilter.tooltip.show");

  const onTagSelect = e => {
    const { value } = e.target;
    onFilterChange(value);
    setShowFilters(false);
  };

  return (
    <>
      <Badge
        color="primary"
        overlap="circular"
        invisible={!Boolean(selectedTag)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Tooltip title={tooltipMessage} placement="top">
          <span>
            <Fab
              size="small"
              style={{ boxShadow: "none" }}
              ref={anchorRef}
              onClick={() => setShowFilters(prev => !prev)}
            >
              <FilterListIcon fontSize="small" />
            </Fab>
          </span>
        </Tooltip>
      </Badge>
      <Popper
        open={showFilters}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <Box
                display="flex"
                gridGap="10px"
                alignItems="center"
                padding="20px"
              >
                <Typography>
                  {t("accountSearch.tagFilter.selectLabel")}
                </Typography>
                <Select
                  variant="outlined"
                  displayEmpty
                  style={{ minWidth: "10rem" }}
                  MenuProps={selectMenuConfig}
                  value={selectedTag}
                  onChange={onTagSelect}
                >
                  <MenuItem value="">
                    <em>{t("common:none")}</em>
                  </MenuItem>
                  {tagFilterOptions.map(option => (
                    <MenuItem key={option.key} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

type TagFilterOption = {
  key: string;
  value: string;
};

const selectMenuConfig: Partial<MenuProps> = {
  disablePortal: false,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const toTagFilterOptions = (tags: string[]): TagFilterOption[] => {
  if (!tags) return [];
  return tags.map(tag => ({
    key: keyGen(),
    value: tag,
  }));
};
