/* eslint-disable react/prop-types */
import React from "react";
import { TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PaperTable from "./PaperTable";

const { Headers, Body, Cell } = PaperTable;

/**
 *
 * @param {object} params
 * @param {import('./types').API.Contact[]} params.contacts
 * @returns
 */
const Contacts = ({ contacts }) => {
  const { t } = useTranslation("billing");

  return (
    <PaperTable title={t("contacts.title")} data-testid="contacts-list">
      <Headers>
        {[
          t("contacts.firstName"),
          t("contacts.lastName"),
          t("contacts.email"),
          t("contacts.creditCard"),
        ]}
      </Headers>
      <Body>
        {contacts.map(contact => (
          <TableRow key={contact.id}>
            <Cell>{contact.firstName}</Cell>
            <Cell>{contact.lastName}</Cell>
            <Cell>{contact.email}</Cell>
            <Cell>
              {contact.stripeCustomer ? t("common:yes") : t("common:no")}
            </Cell>
          </TableRow>
        ))}
      </Body>
    </PaperTable>
  );
};

export default Contacts;
