import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { StorageProvider, TemplateDefinition } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";

type Templates = TemplateDefinition[] | null;
type UseTemplatePolicies = () => {
  fetchDefinitions: () => Promise<void>;
  getByStorageType: (
    storage: Extract<StorageProvider, "BOX" | "EGNYTE"> | ""
  ) => TemplateDefinition[] | [];
  loading: boolean;
  definitions: Templates;
};

const useTemplateDefinitions: UseTemplatePolicies = () => {
  const { t } = useTranslation("templatePolicies");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [definitions, setDefinitions] = useState<Templates>(null);

  const fetchDefinitions: () => Promise<void> = async () => {
    if (!definitions) {
      setLoading(true);
      try {
        const data = await apiRequest<TemplateDefinition[]>(
          "GET",
          "/template-definitions?limit=1000"
        );
        setDefinitions(
          data.sort(
            (a: TemplateDefinition, b: TemplateDefinition) =>
              b.updatedAt - a.updatedAt
          )
        );
      } catch (error: unknown) {
        enqueueSnackbar(t(`errors.fetchError`), {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getByStorageType = (
    storage: Extract<StorageProvider, "BOX" | "EGNYTE"> | ""
  ) => {
    return definitions
      ? definitions.filter(t => t.storageType === storage)
      : [];
  };

  return { fetchDefinitions, getByStorageType, loading, definitions };
};

export default useTemplateDefinitions;
