import { Rule } from "@dashboard-v3/api";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Params = {
  target: Rule["targetType"];
  targetsEnabled: Rule["targetType"][];
  selection?: Rule["targetType"];
  onClick: (newTarget: Rule["targetType"]) => void;
  disabled: boolean;
};

export default function TargetOption({
  target,
  targetsEnabled,
  selection,
  onClick,
  disabled,
}: Params) {
  const { t } = useTranslation("rules");

  if (!targetsEnabled.includes(target)) return null;

  let selected = selection === target;

  return (
    <Grid item xs={4}>
      <Button
        color={selected ? "primary" : "default"}
        disableElevation
        fullWidth
        disabled={disabled && !selected}
        onClick={() => {
          if (disabled) return false;
          onClick(target);
        }}
        data-testid={`target__${target}-btn`}
        size="large"
        style={{ minHeight: "60px" }}
        variant={selected ? "contained" : "outlined"}
      >
        <Typography style={{ fontWeight: "500" }}>{t(target)}</Typography>
      </Button>
    </Grid>
  );
}
