import styled from "styled-components";
import { ListItem, ListItemText, makeStyles, Button } from "@material-ui/core";
import LensIcon from "@material-ui/icons/Lens";
import { green, red, yellow } from "@material-ui/core/colors";

const drawerWidth = 220;

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    boxShadow: "5px 0px 25px 0px rgba(0,0,0,0.1)",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "white",
    border: 0,
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedSecond: {
    paddingLeft: theme.spacing(5),
  },
}));

export const Logo = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
`;

export const StyledToolbar = styled.div`
  align-items: center;
  background-color: white;
  display: grid;
  justify-items: start;
`;

export const StyledListItemText = styled(ListItemText)`
  font-size: 0.5rem;
`;

export const StyledListItem = styled(ListItem)({
  "&.Mui-selected": {
    backgroundColor: "rgb(238, 242, 246)",
    borderLeft: "2px solid #00ade6",
  },
});

export const StatusColorIcon = styled(LensIcon)<{ state: string }>`
  font-size: 0.7rem;
  margin-right: 9px;
  position: relative;
  bottom: 1px;
  color: ${props => {
    switch (props.state) {
      case "minor":
        return yellow.A400;
      case "major":
      case "critical":
        return red.A400;
      default:
        return green.A400;
    }
  }};
`;

export const StyledButton = styled(Button)`
  bottom: 0;
  border-radius: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  color: #5f6061;
  position: absolute;
  text-align: center;
  width: 100%;
`;
