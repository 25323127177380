import ReactDOM from "react-dom";
import "./i18n";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { StrictMode } from "react";

window.DASHBOARD_RELEASE = process.env.REACT_APP_RELEASE || "not-set";

window.onbeforeunload = e => {
  window.isWindowClosed = true;
};

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
