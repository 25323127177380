import { EmailFlow, FromTo } from "@dashboard-v3/api";

type FromToValidationResult = {
  isValid: boolean;
  errors: { [key: number]: string };
};

const isRequiredFromTo = (current: FromTo, fromToArr: FromTo[]) => {
  const { fromDirection, toDirection } = current;
  if (fromToArr.length > 1) {
    return !fromDirection?.type || !toDirection?.type;
  }
  return false;
};

const isUnmanagedFromTo = (current: FromTo) => {
  const { fromDirection, toDirection } = current;
  const fromType = fromDirection?.type || "";
  const toType = toDirection?.type || "";

  if (fromType === "ANYONE" && toType === "ANYONE") {
    return true;
  }
  if (fromType === "ANYONE_ELSE" && toType === "ANYONE_ELSE") {
    return true;
  }

  return false;
};

const isDuplicated = (current: FromTo, update: FromTo) => {
  const { fromDirection: currentFrom, toDirection: currentTo } = current;
  const fromTypeMatch = currentFrom?.type === update.fromDirection?.type;
  const fromValueMatch = currentFrom?.value === update.fromDirection?.value;
  const toTypeMatch = currentTo?.type === update.toDirection?.type;
  const toValueMatch = currentTo?.value === update.toDirection?.value;
  return fromTypeMatch && fromValueMatch && toTypeMatch && toValueMatch;
};

const isDuplicatedInEmailFlowStep = (
  emailFlow: EmailFlow,
  currentFromTo: FromTo
) => {
  if (!emailFlow) return;
  if (!emailFlow.bidirectional) {
    return isDuplicated(emailFlow.fromTo, currentFromTo);
  }

  const reversedFromTo = {
    fromDirection: emailFlow.fromTo.toDirection,
    toDirection: emailFlow.fromTo.fromDirection,
  };

  return (
    isDuplicated(emailFlow.fromTo, currentFromTo) ||
    isDuplicated(reversedFromTo, currentFromTo)
  );
};

const isDuplicatedInFilter = (
  current: FromTo,
  fromToArr: FromTo[],
  currentIdx: number
) => {
  if (current.fromDirection?.type && current.toDirection?.type) {
    const index = fromToArr.findIndex(fromTo => isDuplicated(current, fromTo));
    return { isDuplicated: currentIdx !== index, foundIndex: index };
  }
  return { isDuplicated: false };
};

export default function isValidFromTo(
  filterFromTo: FromTo[],
  emailFlow?: EmailFlow
) {
  const results: FromToValidationResult = { isValid: true, errors: {} };

  if (filterFromTo) {
    filterFromTo.forEach((fromTo, i, fromToArr) => {
      if (isRequiredFromTo(fromTo, fromToArr)) {
        results.isValid = false;
        results.errors[i] = "targetFilter.fromTo.error.required";
        return;
      }

      if (isUnmanagedFromTo(fromTo)) {
        results.isValid = false;
        results.errors[i] = "targetFilter.fromTo.error.unmanaged";
        return;
      }

      if (isDuplicatedInEmailFlowStep(emailFlow, fromTo)) {
        results.isValid = false;
        results.errors[i] = "targetFilter.fromTo.error.duplicatedInEmailFlow";
        return;
      }

      const checkDuplicates = isDuplicatedInFilter(fromTo, fromToArr, i);

      if (checkDuplicates.isDuplicated) {
        const { foundIndex } = checkDuplicates;
        results.isValid = false;
        results.errors[i] = "targetFilter.fromTo.error.duplicated";
        results.errors[foundIndex] = "targetFilter.fromTo.error.duplicated";
        return;
      }
    });
  }
  return results;
}
