import {
  ArchivingAddressFilter,
  EmailFlowFilter,
  MailboxFilter,
} from "@dashboard-v3/api";
import { typePath } from "../context/helpers";

import { Rule } from "pages/Rules/types";
import { State } from "pages/Rules/CreateOrEdit/context/types";

export const actionFilter = (rule: Rule) => {
  const path = typePath(rule.actionType, rule);
  if (rule.actionType === "BLOCK_ATTACHMENTS") {
    return rule.blockAttachments.contentFilter;
  }
  if ("contentToSave" in path) {
    return path.contentToSave.filter;
  }
  return null;
};

export function emailFlowFilter(rule: Rule): EmailFlowFilter | null {
  const ruleTypeSection = typePath(rule.actionType, rule);
  if ("emailFlow" in ruleTypeSection) {
    return ruleTypeSection.emailFlow?.filter;
  }

  return null;
}

export function mailboxFilter(rule: Rule): MailboxFilter | null {
  const ruleTypeSection = typePath(rule.actionType, rule);
  if ("mailbox" in ruleTypeSection) {
    return ruleTypeSection.mailbox?.filter;
  }

  return null;
}

export function archivingAddressFilter(
  rule: Rule
): ArchivingAddressFilter | null {
  const ruleTypeSection = typePath(rule.actionType, rule);
  if ("archivingAddress" in ruleTypeSection) {
    return ruleTypeSection.archivingAddress?.filter;
  }

  return null;
}

const isAttachmentFilter = (state: State, type: "action" | "target") => {
  const { type: ruleType, rule } = state;
  const { targetType } = rule;

  if (type === "target") return false;

  return (
    (ruleType === "COPY_EMAILS" && targetType !== "EMAIL_FLOW") ||
    ruleType === "MOVE_EMAILS" ||
    ruleType === "DRAG_AND_DROP"
  );
};

export const getFilterTranslations = (
  state: State,
  type: "action" | "target"
) => {
  const translations = {
    title: `filters.${type}`,
    tooltip: "filters.actionTooltip",
  };

  if (isAttachmentFilter(state, type)) {
    translations.title = `filters.attachments`;
    translations.tooltip = "filters.attachmentsTooltip";
  }

  return translations;
};
