import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { StyledForm } from "./styled";
import TagFilter from "./TagFilter";

interface AccountSearchProps {
  placeholder: string;
  tags?: string[];
  withTagFilter?: boolean;
  onSearch: (account: string, tag?: string) => void;
  onClear: (account?: string, tag?: string) => void;
}

type SearchCriteria = {
  account: string;
  tag?: string;
};

export default function AccountSearch({
  placeholder,
  tags,
  withTagFilter,
  onSearch,
  onClear,
}: AccountSearchProps) {
  const { t } = useTranslation("components");
  const [criteria, setCriteria] = useState<SearchCriteria>({ account: "" });
  const showClearBtn = criteria.account && onClear;
  const inputProps = {
    "aria-label": "search accounts",
    "data-testid": "accounts__search-input",
  };

  function emitSearch(event) {
    event.preventDefault();
    const { account, tag } = criteria;
    onSearch(account, tag);
    return false;
  }

  function clearSearch() {
    setCriteria(prev => ({ ...prev, account: "" }));
    onClear("", criteria.tag);
  }

  function handleOnChange(e) {
    const { value: account } = e.target;
    setCriteria(prev => ({ ...prev, account }));
  }

  function onTagFilterChange(tag: string) {
    setCriteria(prev => ({ ...prev, tag: tag || "" }));
  }

  return (
    <StyledForm onSubmit={emitSearch}>
      <InputBase
        name="criteria"
        value={criteria.account}
        placeholder={placeholder}
        inputProps={inputProps}
        onChange={handleOnChange}
        endAdornment={
          showClearBtn && (
            <IconButton size="small" onClick={clearSearch}>
              <ClearIcon />
            </IconButton>
          )
        }
      />
      <Box gridGap="5px" display="flex">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          role="button"
          startIcon={withTagFilter && <SearchIcon />}
          disableElevation
        >
          {withTagFilter ? t("accountSearch.submit") : <SearchIcon />}
        </Button>
        {withTagFilter && (
          <TagFilter
            tags={tags}
            selectedTag={criteria.tag || ""}
            onFilterChange={onTagFilterChange}
          />
        )}
      </Box>
    </StyledForm>
  );
}
