import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { addUserInSession, getOriginPathInSession } from "utils/authentication";
import styled from "styled-components";
import { loginWithSamlCode } from "./api";
import Panel from "./Panel";
import * as Sentry from "@sentry/react";
import { getErrorCode } from "utils/apiRequestWithErrorCode";

export default function SamlResponse() {
  const location = useLocation();
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState<string>();
  const { t } = useTranslation("login");
  const pathname = getOriginPathInSession() || "/";

  // Obtain code from search param (/saml-response?code=XXX)
  const code = new URLSearchParams(location.search).get("code");

  useEffect(() => {
    async function login() {
      try {
        const { user, sessionDuration } = await loginWithSamlCode(code);
        addUserInSession(user, sessionDuration);
        history.push(pathname);
      } catch (serviceError) {
        Sentry.captureException(serviceError);
        const code = getErrorCode(serviceError);
        setErrorMsg(t("samlErrorCode.UNKNOWN", { errorCode: code }));
      }
    }

    if (code) {
      login();
    } else {
      const params = new URLSearchParams(window.location.search);
      const errorCode = params.get("errorCode");
      // USER_NOT_FOUND SAML_NOT_SUPPORTED SYSTEM_FAILURE
      Sentry.captureMessage("Error in SAML response", {
        level: "error",
        fingerprint: ["SAML"],
        extra: { errorCode },
      });

      let errorMessage;

      switch (errorCode) {
        case "USER_NOT_FOUND":
          const email = params.get("email");
          errorMessage = t(`samlErrorCode.USER_NOT_FOUND`, { email });
          break;

        case "SAML_NOT_SUPPORTED":
          const organizationId = params.get("organizationId");
          errorMessage = t("samlErrorCode.SAML_NOT_SUPPORTED", {
            organizationId,
          });
          break;
        default:
          errorMessage = t("samlErrorCode.UNKNOWN", { errorCode });
          break;
      }

      setErrorMsg(errorMessage);
    }
    // eslint-disable-next-line
  }, [code]);

  if (errorMsg)
    return (
      <Panel>
        <ErrorMsg>
          <Typography>{errorMsg}</Typography>
        </ErrorMsg>

        <Button size="large" onClick={() => history.push("/login")}>
          {t("backToLogin")}
        </Button>
      </Panel>
    );

  return (
    <LoadingPanel>
      <CircularProgress />
      <div>{t("loadingSamlResults")}</div>
    </LoadingPanel>
  );
}

const ErrorMsg = styled("div")`
  max-width: 600px;
  text-align: center;
`;

const LoadingPanel = styled(Panel)`
  grid-auto-flow: column;
  align-items: center;
`;
