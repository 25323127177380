import styled from "styled-components";
import { MenuItem, Link, Divider } from "@material-ui/core";

export const StyledMenuItem = styled(MenuItem)`
  display: block;
  opacity: 1 !important;
  cursor: ${({ disabled }) => disabled && "default"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  p {
    opacity: ${({ disabled }) => disabled && "0.5"};
  }
  &:hover {
    background: ${({ disabled }) => disabled && "none"};
  }
`;

export const AddAccountLink = styled(Link)`
  margin: 6px 0 0 8px;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
`;

export const OptionsDivider = styled(Divider)`
  margin: 8px 0 8px 0;
`;
