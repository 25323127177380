import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DirectionAutocomplete, {
  AllowedTypes,
} from "../FromToSelector/DirectionAutocomplete";
import optionToDirection from "./optionToDirection";
import type { FromTo } from "@dashboard-v3/api";
import { Option } from "./types";
import { Direction } from "@dashboard-v3/api";
import { TFunction } from "react-i18next";
import { getGroupOption } from "./DirectionAutocomplete/searchInApi";

type Params = {
  onChange: (newSelection: FromTo) => void;
  initialSelectedItem?: FromTo;
  /** Separator label, "to" by default */
  separator?: string;
  allowedTypes?: AllowedTypes;
  onOptionSelect?: (update: { from: Option; to: Option }) => void;
};

export default function FromToFilter({
  separator,
  onChange,
  initialSelectedItem,
  allowedTypes,
  onOptionSelect,
}: Params) {
  const { t } = useTranslation("fromToSelector");
  const [loading, setLoading] = useState(true);
  const [fromOption, setFromOption] = useState<Option>();
  const [toOption, setToOption] = useState<Option>();

  useEffect(() => {
    async function init() {
      if (initialSelectedItem) {
        const { fromDirection, toDirection } = initialSelectedItem;
        const [from, to] = await Promise.all([
          convertToOption(fromDirection, t),
          convertToOption(toDirection, t),
        ]);

        setFromOption(from);
        setToOption(to);
      }
      setLoading(false);
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onOptionSelect) {
      onOptionSelect({ from: fromOption, to: toOption });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromOption, toOption]);

  function changeFrom(selection: Option) {
    setFromOption(selection);
    onChange({
      fromDirection: optionToDirection(selection),
      toDirection: optionToDirection(toOption),
    });
  }

  function changeTo(selection: Option) {
    setToOption(selection);
    onChange({
      fromDirection: optionToDirection(fromOption),
      toDirection: optionToDirection(selection),
    });
  }

  return (
    <Box display="grid" gridTemplateColumns="1fr auto 1fr" gridColumnGap="10px">
      {loading ? (
        <Skeleton height={56} variant="rect" />
      ) : (
        <Box>
          <DirectionAutocomplete
            label={t("from")}
            allowedTypes={allowedTypes}
            initialSelection={fromOption}
            onChange={changeFrom}
          />
        </Box>
      )}
      <Box justifySelf="center" alignSelf="center">
        <Typography>{separator || t("separator")}</Typography>
      </Box>

      {loading ? (
        <Skeleton height={56} variant="rect" />
      ) : (
        <Box>
          <DirectionAutocomplete
            label={t("to")}
            allowedTypes={allowedTypes}
            initialSelection={toOption}
            onChange={changeTo}
          />
        </Box>
      )}
    </Box>
  );
}

async function convertToOption(
  initialValue: Direction | null,
  t: TFunction
): Promise<Option> {
  if (!initialValue) return null;

  const { type, value } = initialValue;

  switch (type) {
    case "ANYONE":
    case "ANYONE_ELSE":
    case "ORGANIZATION":
      return { type, label: t(`option.${type}`), managed: false };

    case "TAG":
    case "DOMAIN":
      return { type, label: value, managed: false };

    case "EMAIL":
      return { type: "ACCOUNT", label: value, managed: false };

    case "GROUP": {
      const option = await getGroupOption(value);
      if (option) return option;
      break;
    }
    default:
      break;
  }
}
