import { TFunction, useTranslation } from "react-i18next";
import { Box, Tooltip } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import ButtonWithLoading from "components/ButtonWithLoading";
import { useRuleContext } from "./context/ruleContext";
import { canRestart } from "./context/helpers";
import { useParams } from "react-router-dom";
import { State } from "./context/types";
import { RuleUrlParams } from ".";
import { Rule } from "../types";

export default function SaveButton() {
  const { state, dispatch } = useRuleContext();
  const { t } = useTranslation("rules");
  const { action }: RuleUrlParams = useParams();

  return (
    <Box display="grid" gridTemplateColumns="3fr auto">
      <ButtonWithLoading
        color="primary"
        data-testid="rules__save-btn"
        disableElevation
        fullWidth
        size="large"
        variant="contained"
        loading={state.asyncStatus === "saving"}
        disabled={cannotSave(state, action)}
        onClick={() => {
          dispatch({ type: "saveRule" });
        }}
      >
        {t("saveRule")}
      </ButtonWithLoading>
      {cannotSave(state, action) && (
        <Tooltip title={errorHelp(state, action, t)} placement="top">
          <Box alignSelf="center" pl={2} pt="5px" color="error.main">
            <ReportProblemOutlinedIcon />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

function cannotSave(state: State, action: RuleUrlParams["action"]) {
  if (!state.canSave) return true;
  return isNotEditable(state.rule, action);
}

function isNotEditable(rule: Rule, action: RuleUrlParams["action"]) {
  if (action !== "copy" && !canRestart(rule)) return true;
  return false;
}

function errorHelp(
  state: State,
  action: RuleUrlParams["action"],
  t: TFunction
) {
  if (isNotEditable(state.rule, action)) {
    return t("saveBtnHelper.notEditable");
  }

  if (state.remaining.length === 2) {
    return t("saveBtnHelper.all");
  }
  if (state.remaining.includes("steps")) {
    return t("saveBtnHelper.steps");
  }

  if (state.remaining.includes("name")) {
    return t("saveBtnHelper.name");
  }

  if (state.filters.targetFilter.errors) {
    return t("saveBtnHelper.targetFilter");
  }

  return null;
}
