/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WithIconHelper from "components/WithIconHelper";
import { OptionGroup, Label } from "components/Styled";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import isEmail from "utils/isEmail";
import BalancedAccountsValues from "./BalancedAccountsValues";
import { StyledChipField, StyledTextField } from "./Styled";
import {
  AccountManagement,
  asUserValueCollection,
  asUserValue,
  getStorage,
} from "./utils";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";

import { Storage } from "@dashboard-v3/api";
import { Rule } from "../types";

/*
1. MAIL_BOX
  can use: AUTO, MANUAL, BALANCED
2. EMAIL_FLOW
  can use: AUTO, MANUAL
3. ARCHIVING_ADDRESS
  can use: MANUAL

# When select MANUAL:
  + DROPBOX: fill "userId" and "asUser" wiht the email filled in the input
  + BOX, DRIVE, ONEDRIVE: fill "userId"

# When select BALANCED:
  + DROPBOX: fill "userId" and the array "asUsers"
  + BOX, DRIVE, ONEDRIVE: fill the array "userIds"
*/
export default function AsUserSelector() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { rule } = state;
  const { targetType } = rule;
  const storage: Storage = getStorage(rule);
  const [asUserError, setAsUserError] = useState<string>();
  const accessErrors = state.checks.storageAccess.errors;

  // TODO: Check if we can remove this useEffect
  useEffect(() => {
    if (!accessErrors) return;
    if (state.storageManagement === AccountManagement.MANUAL) {
      const [error] = accessErrors;

      if (error.reason) {
        const errMsg = t(`cloudStorage.asUser.error.${error.reason}`, {
          provider: storage.provider,
          account: asUserValue(storage),
        });

        setAsUserError(errMsg);
      }
    }

    if (state.storageManagement === AccountManagement.BALANCED) {
      setAsUserError(t("cloudStorage.balanced.accessError"));
    }
  }, [accessErrors]);

  useEffect(() => {
    const { authentication, userId = "", userIds, asUsers, provider } = storage;
    let isAutoManaged = false;

    if (!rule.id) {
      return updateAccountManagement(initAccountManagement(state.rule));
    }

    if (userIds?.length || asUsers?.length) {
      return updateAccountManagement(AccountManagement.BALANCED);
    }

    if (provider === "MXHERO_STORAGE") {
      return updateAccountManagement(AccountManagement.AUTO);
    }

    if (provider === "DROPBOX") {
      isAutoManaged = !Boolean(storage.asUser);
    } else {
      isAutoManaged = authentication === "ORGANIZATION" && !userId;
    }

    updateAccountManagement(
      isAutoManaged ? AccountManagement.AUTO : AccountManagement.MANUAL
    );

    return () => updateAccountManagement(null);
  }, []);

  // TODO: Check if we can remove this useEffect
  useEffect(() => {
    if (rule.id) return;

    const isAutoManaged = state.storageManagement === AccountManagement.AUTO;

    if (isAutoManaged && !isAccountManagedAutoAvailable(rule)) {
      const value = initAccountManagement(state.rule);
      changeAccountManagement(value);
    }

    if (storage.provider === "MXHERO_STORAGE") {
      return updateAccountManagement(AccountManagement.AUTO);
    }

    if (storage.provider === "DROPBOX" && rule.targetType !== "MAIL_BOX") {
      let management = AccountManagement.BALANCED;

      if (storage.asUser) {
        management = AccountManagement.MANUAL;
      }
      if (storage.asUsers?.length) {
        management = AccountManagement.BALANCED;
      }

      updateAccountManagement(management);
    }
  }, [rule.targetType]);

  const updateAccountManagement = (payload: AccountManagement) => {
    dispatch({ type: "updateStorageManagement", payload });
  };

  const changeAccountManagement = (value: AccountManagement) => {
    const managedAccount = value === AccountManagement.AUTO ? null : "";
    updateAccountManagement(value);
    changeManagedAccount(managedAccount);
  };

  const changeManagedAccount = (email: string | null) => {
    const isDropbox = storage.provider === "DROPBOX";
    const update = isDropbox ? { asUser: email } : { userId: email };
    dispatch({
      type: "updateStep",
      payload: {
        step: "cloudStorage",
        changes: { ...update, userIds: [], asUsers: [] },
      },
    });
  };

  const handleAccountManagement = e => {
    const value = e.target.value as AccountManagement;
    setAsUserError("");
    changeAccountManagement(value);
  };

  const handleManualChange = e => {
    const { value } = e.target;
    setAsUserError("");
    changeManagedAccount(value);
  };

  const handleManualBlur = async e => {
    const account = asUserValue(storage);

    if (account && !isEmail(account)) {
      return setAsUserError(t("cloudStorage.asUser.error.invalidEmail"));
    }
  };

  const getManagementLabel = () => {
    if (targetType === "MAIL_BOX" || targetType === "STORAGE_WEBHOOK") {
      return t(`cloudStorage.manage.auto.${targetType}`);
    }
    return t(`cloudStorage.manage.auto.DEFAULT`);
  };

  const validateBalancedAccountsLimit = (changes: Partial<Storage>) => {
    const { provider } = storage;
    const value = provider === "DROPBOX" ? changes?.asUsers : changes?.userIds;

    if (value.length > 100) {
      setAsUserError(t("cloudStorage.balanced.limitError"));
    }
  };

  const handleBalancedChange = (action: "add" | "delete") => {
    return (value: string) => {
      setAsUserError("");

      if (!isEmail(value)) return;

      const changes = {
        userIds: [...storage.userIds],
        asUsers: storage?.asUsers?.length ? [...storage.asUsers] : [],
      };

      if (action === "add") {
        storage.provider === "DROPBOX"
          ? changes.asUsers.push(value)
          : changes.userIds.push(value);
      }

      if (action === "delete") {
        const filterAccount = (account: string) => account !== value;
        storage.provider === "DROPBOX"
          ? (changes.asUsers = changes.asUsers.filter(filterAccount))
          : (changes.userIds = changes.userIds.filter(filterAccount));
      }

      validateBalancedAccountsLimit(changes);

      dispatch({
        type: "updateStep",
        payload: {
          step: "cloudStorage",
          changes,
        },
      });
    };
  };

  function canUseBalance() {
    if (state.rule.targetType === "STORAGE_WEBHOOK") return false;
    if (storage.provider === "DROPBOX") return true;
    if (storage.authentication === "INDIVIDUAL") return false;
    if (storage.provider === "MXHERO_STORAGE") return false;
    return true;
  }

  return (
    <OptionGroup>
      <Label gutterBottom wording={t("cloudStorage.manage.title")} />
      <RadioGroup
        name="management"
        value={state.storageManagement ?? AccountManagement.AUTO}
        onChange={handleAccountManagement}
      >
        <FormControlLabel
          label={getManagementLabel()}
          value={AccountManagement.AUTO}
          disabled={!isAccountManagedAutoAvailable(rule)}
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          label={t("cloudStorage.manage.manual")}
          value={AccountManagement.MANUAL}
          control={<Radio color="primary" size="small" />}
          disabled={storage.provider === "MXHERO_STORAGE"}
        />
        {state.storageManagement === AccountManagement.MANUAL && (
          <StyledTextField
            variant="outlined"
            fullWidth
            style={{ marginBottom: "15px" }}
            name="asUser"
            label={t("cloudStorage.asUser.label")}
            value={asUserValue(storage)}
            onChange={handleManualChange}
            onBlur={handleManualBlur}
            error={!!asUserError}
            helperText={asUserError}
            required
          />
        )}
        {canUseBalance() && (
          <WithIconHelper
            position="after"
            text={t("cloudStorage.balanced.helper")}
          >
            <FormControlLabel
              label={t("cloudStorage.manage.balance")}
              value={AccountManagement.BALANCED}
              control={<Radio color="primary" size="small" />}
            />
          </WithIconHelper>
        )}
      </RadioGroup>
      {canUseBalance() &&
        state.storageManagement === AccountManagement.BALANCED && (
          <WithIconHelper
            position="after"
            text={t("common:chipInputHelper")}
            buttonStyles={{ marginLeft: "10px" }}
            icon={<InfoOutlinedIcon fontSize="small" color="primary" />}
          >
            <StyledChipField
              fullWidth
              variant="outlined"
              label={t("cloudStorage.balanced.label")}
              value={asUserValueCollection(storage)}
              onAdd={handleBalancedChange("add")}
              onDelete={handleBalancedChange("delete")}
              error={!!asUserError}
              helperText={asUserError}
              chipRenderer={(props, key) => (
                <BalancedAccountsValues
                  key={key}
                  accessErrors={accessErrors}
                  {...props}
                />
              )}
              required
            />
          </WithIconHelper>
        )}
    </OptionGroup>
  );
}

function initAccountManagement(rule: Rule) {
  const { userIds, asUsers } = getStorage(rule);
  if (userIds?.length || asUsers?.length) {
    return AccountManagement.BALANCED;
  }
  if (isAccountManagedAutoAvailable(rule)) {
    return AccountManagement.AUTO;
  }

  return AccountManagement.MANUAL;
}

function isAccountManagedAutoAvailable(rule: Rule) {
  if (getStorage(rule).provider === "MXHERO_STORAGE") return false;
  return rule.targetType !== "ARCHIVING_ADDRESS";
}
