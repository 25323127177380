import styled from "styled-components";
import { Box, Dialog, Divider, Paper } from "@material-ui/core";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import { green, red } from "@material-ui/core/colors";

export const TesterContainer = styled(Paper)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  padding: 20px 30px 30px 30px;
`;

export const TestHeader = styled.div`
  margin-bottom: 4px;
  align-self: flex-start;
  h6 {
    font-size: 18px;
  }
`;

export const TestFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 20px;
  width: 100%;
`;

export const TestResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: "center";
  align-items: flex-start;
  h6 {
    font-size: 18px;
  }
`;

export const ResultValuesRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  h6,
  span {
    font-size: 14px;
    line-height: 0;
  }
`;

export const ResultIcon = styled(CheckCircleTwoToneIcon)<{ $success: boolean }>`
  font-size: 18px;
  color: ${({ $success }) => ($success ? green[400] : red[400])};
`;

export const RegexDialogContainer = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 30px;
    min-width: 500px;
  }
`;

export const RegexDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RegexDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RegexDialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RegexTestButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RegexTestResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  :last-child {
    margin-bottom: 8px;
  }
`;

export const RegexTestResultDataWrapper = styled.div`
  margin-bottom: 2px;
  div {
    display: grid;
    grid-template-columns: 20% auto;
  }
`;

export const EmailDetailsContainer = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 22px;
`;

export const EmailFieldsModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  svg {
    position: absolute;
    top: 5px;
    right: -6px;
    cursor: pointer;
    color: grey;
    :hover {
      color: initial;
    }
  }
`;

export const EmailFieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-bottom: 24px;
  border-radius: 6px;
  border: 1px dashed rgb(0, 158, 210);
`;
