import { useState } from "react";
import { useTranslation } from "react-i18next";
import produce from "immer";
import { useSnackbar } from "notistack";
import {
  Box,
  Divider,
  FormHelperText,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ChipInput from "material-ui-chip-input";
import SwitchField from "components/Forms/SwitchField";
import SelectField from "components/Forms/SelectField";
import isEmail from "utils/isEmail";
import apiRequest from "utils/apiRequestWithErrorCode";
import { DigestContainer, FieldWrapper, SelectorsContainer } from "./Styled";
import {
  FormState,
  digestPeriodOptions,
  notificationFields,
  toFormState,
  toNotificationSettings,
} from "./utils";

import { DigestPeriod, NotificationSettings } from "@dashboard-v3/api";
import WithIconHelper from "components/WithIconHelper";

interface Props {
  settings: NotificationSettings;
}

export default function SettingsForm({ settings }: Props) {
  const { t } = useTranslation("notificationSettings");
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<FormState>(toFormState(settings));

  const updateSettings = async (form: FormState) => {
    const update = toNotificationSettings(form);
    try {
      await apiRequest<NotificationSettings>(
        "PUT",
        "/notifications/settings",
        update
      );
    } catch (e) {
      enqueueSnackbar(t("errors.updateSettings"), { variant: "error" });
    }
  };

  const handleMailAddresses = (mail: string, index?: number) => {
    setForm(prev =>
      produce(prev, draft => {
        if (index === undefined && isEmail(mail)) {
          draft.mailAddresses.push(mail);
        } else {
          draft.mailAddresses = draft.mailAddresses.filter(d => d !== mail);
        }
        updateSettings(draft);
      })
    );
  };

  const handleSwitch = e => {
    const { name, checked } = e.target;
    setForm(prev =>
      produce(prev, draft => {
        const { period } = draft[name];
        if (name === "monthlyUsageReport") {
          draft[name] = checked;
        } else {
          draft[name] = {
            checked,
            period: checked ? period : "HOUR",
          };
        }
        updateSettings(draft);
      })
    );
  };

  const handleDigestPeriod = e => {
    const { name, value } = e.target;
    setForm(prev =>
      produce(prev, draft => {
        draft[name] = { ...draft[name], period: value };
        updateSettings(draft);
      })
    );
  };

  return (
    <Paper style={{ maxWidth: "80%" }}>
      <FieldWrapper withPaddingTop style={{ width: "80%" }}>
        <Typography style={{ marginBottom: "15px", fontWeight: 500 }}>
          {t("form.mailAddresses.label")}
        </Typography>
        <WithIconHelper
          position="after"
          text={t("common:chipInputHelper")}
          icon={<InfoOutlinedIcon fontSize="small" color="primary" />}
          buttonStyles={{ marginLeft: "10px" }}
        >
          <ChipInput
            variant="outlined"
            fullWidth
            value={form.mailAddresses}
            onAdd={handleMailAddresses}
            onDelete={handleMailAddresses}
            fullWidthInput
            style={{ minWidth: "30rem" }}
          />
        </WithIconHelper>
      </FieldWrapper>
      <Divider />
      <FieldWrapper withPaddingTop>
        <SwitchField
          name="monthlyUsageReport"
          label={t("form.monthlyUsageReport.label")}
          description={t("form.monthlyUsageReport.description")}
          value={form.monthlyUsageReport}
          onChange={handleSwitch}
          labelProps={{ variant: "subtitle2" }}
        />
      </FieldWrapper>
      <Divider />
      <SelectorsContainer>
        <Box marginLeft="24px" marginBottom="20px">
          <Typography style={{ fontWeight: "500" }}>
            {t("form.notifyWarnings.label")}
          </Typography>
          <FormHelperText>
            {t("form.notifyWarnings.description")}
          </FormHelperText>
        </Box>
        {notificationFields.map(({ name, transKey }) => (
          <FieldWrapper key={name}>
            <SwitchField
              name={name}
              value={form[name].checked}
              onChange={handleSwitch}
              label={t(`form.${transKey}.label`)}
              labelProps={{ variant: "subtitle2" }}
            />
            <DigestContainer>
              {form[name].checked && (
                <>
                  <Typography variant="subtitle2">
                    {t("form.digestPeriod.label")}
                  </Typography>
                  <Box width="10rem">
                    <SelectField
                      name={name}
                      options={digestPeriodOptions}
                      value={form[name].period || "HOUR"}
                      onChange={handleDigestPeriod}
                      renderOption={(opt: DigestPeriod) => (
                        <MenuItem key={opt} value={opt}>
                          {t(`form.digestPeriod.option.${opt}`)}
                        </MenuItem>
                      )}
                    />
                  </Box>
                </>
              )}
            </DigestContainer>
          </FieldWrapper>
        ))}
      </SelectorsContainer>
    </Paper>
  );
}
