import {
  AccordionDetails,
  Button,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import Horizontal from "pages/Rules/RuleStepper/HorizontalStepper";
import { getFormattedTimeStamp } from "utils/dateUtils";
import MenuButton from "./MenuButton";
import RuleStatus from "./RuleStatus";
import RuleStatistics from "../RuleStatistics";
import GoToAccountDetailsBtn from "../RuleStatistics/GoToAccountDetailsBtn";
import {
  AccordionContainer,
  ButtonContainer,
  StepperContainer,
  SummaryContainer,
  SummaryGrid,
} from "./Styled";
import { Rule } from "@dashboard-v3/api";
import ErrorFeedback from "./ErrorFeedback";

export enum ItemAction {
  ENABLE = "enable",
  DISABLE = "disable",
  COPY = "copy",
  DELETE = "delete",
  EDIT = "edit",
}

export type onClick = (action: ItemAction, rule: Rule) => void;

type Params = {
  rule: Rule;
  onClick: onClick;
};

export default function Item({ rule, onClick }: Params) {
  const { t } = useTranslation("rules");

  const [expanded, setExpanded] = useState<boolean>(false);
  const { name, enabled, updatedAt, actionType, targetType } = rule;

  const getMenuOptions = () => {
    return Object.values(ItemAction)
      .filter(action => {
        if (action !== ItemAction.EDIT) {
          return (
            (rule.enabled && action !== ItemAction.ENABLE) ||
            (!rule.enabled && action !== ItemAction.DISABLE)
          );
        }
        return false;
      })
      .map(action => ({
        key: `${action}-rule`,
        text: `list.menuBtn.${action}`,
        value: action,
      }));
  };

  return (
    <ListItem disableGutters>
      <AccordionContainer onChange={() => setExpanded(prev => !prev)}>
        <SummaryContainer>
          <SummaryGrid>
            <RuleStatus enabled={enabled} />
            <Typography
              display="inline"
              variant="subtitle2"
              data-testid="ruleList__ruleName"
            >
              {name}
            </Typography>
            <Typography display="inline" variant="caption">
              {t(`list.actionTypes.${actionType}`)}
            </Typography>
            <Typography display="inline" variant="caption">
              {t(targetType)}
            </Typography>
            <Typography display="inline" variant="body2">
              {getFormattedTimeStamp(updatedAt, true)}
            </Typography>
            <ButtonContainer>
              <Suspense fallback={<></>}>
                <ErrorFeedback rule={rule} />
              </Suspense>
              <Button
                href={`/rules/${rule.id}/edit`}
                size="small"
                variant="outlined"
                color="default"
                onClick={e => e.stopPropagation()}
              >
                {t("common:edit")}
              </Button>
              <MenuButton
                rule={rule}
                options={getMenuOptions()}
                onSelect={val => onClick(val, rule)}
              />
            </ButtonContainer>
          </SummaryGrid>
        </SummaryContainer>
        <AccordionDetails style={{ display: "inline", padding: 0 }}>
          <StepperContainer>
            <Horizontal rule={rule} />
          </StepperContainer>
          {expanded && rule.targetType === "MAIL_BOX" && (
            <StepperContainer>
              <RuleStatistics
                rule={rule}
                renderActionBtn={rule => <GoToAccountDetailsBtn rule={rule} />}
              />
            </StepperContainer>
          )}
        </AccordionDetails>
      </AccordionContainer>
    </ListItem>
  );
}
