/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  Box,
  Divider,
  FormControl,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Alert, Autocomplete } from "@material-ui/lab";
import PhoneInput from "react-phone-input-2";
import ButtonWithLoading from "components/ButtonWithLoading";
import SelectField from "components/Forms/SelectField";
import apiRequest from "utils/apiRequestWithErrorCode";
import useOrganization from "utils/useOrganization";
import { timezones, locales } from "@dashboard-v3/shared";

type Form = {
  name: string;
  contactEmail: string;
  contactNumber: string;
  locale?: Locales;
  timeZone: string;
};

type LoadingState = "idle" | "loading" | "errorOnLoading" | "saving";

export default function Organization() {
  const { t } = useTranslation("orgAndDomains");
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<Form>();
  const [state, setState] = useState<LoadingState>("loading");
  const [timezoneLabel, setTimezoneLabel] = useState(null);
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [orgId, setOrgId] = useState();
  const { organization } = useOrganization({
    onError(error) {
      console.error(error);
      setState("errorOnLoading");
    },
  });

  useEffect(() => {
    if (organization) {
      setStateFromOrganization(organization);
      setState("idle");
    }
  }, [organization]);

  const phoneInputOnBlur = (e, { countryCode }) => {
    const { value } = e.target;

    setIsInvalidPhone(false);
    if (!value.trim()) return;

    const parsedPhoneNumber = parsePhoneNumberFromString(
      e.target.value,
      countryCode
    );

    if (parsedPhoneNumber) {
      setIsInvalidPhone(!parsedPhoneNumber.isValid());
    }
  };

  const timezoneChange = (_, label) => {
    let value = null;
    setTimezoneLabel(label);
    if (label) {
      const found = timezones.find(tz => tz.label === label);
      value = found ? found.value : null;
    }
    setForm(current => ({ ...current, timeZone: value }));
  };

  const inputUpdated = event => {
    const { name, value } = event.target;
    setForm(current => ({
      ...current,
      [name]: value,
    }));
  };

  function setStateFromOrganization(org) {
    const form: Form = {
      ...defaultState,
      name: org.name,
      contactEmail: org.contactEmail,
      contactNumber: org.contactNumber,
      locale: org.locale,
      timeZone: org.timeZone,
    };

    setForm(form);
    setOrgId(org.id);

    let tz;

    if (org.timeZone) {
      tz = timezones.find(item => item.value === org.timeZone);
    }
    setTimezoneLabel(tz.label);
  }

  async function saveChanges(event) {
    event.preventDefault();
    setState("saving");

    try {
      const organization = await apiRequest("PATCH", "/organization", form);
      setStateFromOrganization(organization);
      enqueueSnackbar(t("org.savedOk"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("org.error"), { variant: "error" });
    } finally {
      setState("idle");
    }
  }

  if (state === "loading") {
    return (
      <div data-testid="organization_loading">
        <Paper style={{ padding: "12px 16px" }}>
          <LinearProgress />
        </Paper>
      </div>
    );
  }

  if (state === "errorOnLoading") {
    return (
      <div data-testid="organization_error-on-loading">
        <Alert variant="filled" severity="error">
          {t("org.error")}
        </Alert>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={saveChanges}>
        <Box marginTop="20px" component={Paper} pt={1} px={2}>
          <Box marginY={2}>
            <Typography variant="h6">{form.name}</Typography>
            <Box display="flex">
              <Box color={grey[600]} mr="8px">
                ID
              </Box>
              <Box color={grey[500]}>{orgId}</Box>
            </Box>
          </Box>
          <Divider />
          <Box marginTop={3}>
            <Typography style={{ marginTop: "15px" }}>
              {t("org.contact")}
            </Typography>
            <Box my={2} display="flex" gridColumnGap="15px">
              <TextField
                style={{ minWidth: "300px" }}
                inputProps={{
                  "data-testid": "orgdomains__org-email-input",
                }}
                label={t("org.email")}
                variant="outlined"
                value={form.contactEmail}
                name="contactEmail"
                type="email"
                onChange={inputUpdated}
              />
              <PhoneInput
                inputProps={{
                  "data-testid": "orgdomains__org-number-input",
                }}
                country="us"
                value={form.contactNumber}
                containerStyle={{
                  display: "inline-flex",
                  width: "30%",
                  margin: 0,
                }}
                inputStyle={{ padding: "18px 14px 18px 58px" }}
                onChange={contactNumber =>
                  setForm(prev => ({ ...prev, contactNumber }))
                }
                isValid={() => {
                  if (isInvalidPhone) {
                    return t("org.invalidNumber");
                  }
                  return true;
                }}
                specialLabel={t("org.phone")}
                onBlur={phoneInputOnBlur}
              />
            </Box>
          </Box>

          <Box marginTop={3}>
            <Typography style={{ marginTop: "15px" }}>
              {t("org.locale&timezone")}
            </Typography>
            <Box my={2} display="flex">
              <FormControl
                variant="outlined"
                style={{ width: 300, marginRight: 15 }}
              >
                <SelectField
                  name="locale"
                  label={t("org.locale")}
                  options={locales}
                  value={form.locale}
                  onChange={inputUpdated}
                  data-testid="orgdomains__org-locale-select"
                  inputProps={{
                    "data-testid": "orgdomains__org-locale-input",
                  }}
                  renderOption={locale => (
                    <MenuItem
                      key={locale}
                      value={locale}
                      data-testid={`${locale}-locale-item`}
                    >
                      {t(`org.${locale}`)}
                    </MenuItem>
                  )}
                />
              </FormControl>

              <Box width={400} display="inline">
                <Autocomplete
                  disablePortal
                  data-testid="orgdomains__org-timezone-select"
                  options={timezoneLabels}
                  onChange={timezoneChange}
                  value={timezoneLabel}
                  renderInput={params => (
                    <TextField
                      inputProps={{
                        "data-testid": "orgdomains__org-timezone-input",
                      }}
                      {...params}
                      variant="outlined"
                      label={t("org.timezone")}
                    />
                  )}
                  getOptionLabel={option => {
                    if (option && option.label) return option.label;
                    return option;
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box pb={3} pt={2} marginTop={1}>
            <ButtonWithLoading
              data-testid="orgdomains__save-settings-btn"
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              loading={state === "saving"}
            >
              {t("org.save")}
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </>
  );
}

const timezoneLabels = timezones.map(tz => tz.label);

type Locales = (typeof locales)[number];

const defaultState = {
  contactEmail: null,
  contactNumber: null,
  locale: null,
  timeZone: null,
};
