import { Paper } from "@material-ui/core";
import styled from "styled-components";

export const SelectorsContainer = styled.div`
  display: grid;
  padding-top: 25px;
`;

export const FieldWrapper = styled.div<{ withPaddingTop?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  padding: ${({ withPaddingTop }) =>
    withPaddingTop ? "24px 30px 24px 24px" : "0px 30px 24px 24px"};
  label {
    align-self: baseline;
  }
`;

export const DigestContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  white-space: nowrap;
  margin-left: 48px;
  margin-top: 10px;
  h6 {
    font-weight: 400;
  }
  div {
    max-height: 50px;
  }
`;

export const DisabledContainer = styled(Paper)`
  padding: 35px 25px;
  display: flex;
  gap: 25px;
  align-items: center;
  svg {
    font-size: 5.2rem;
    color: rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  button {
    align-self: flex-start;
  }
`;
