import { getStorage } from "pages/Rules/CloudStorage/utils";
import { Rule } from "pages/Rules/types";

export type EmailActionType =
  | Storage["onStorageError"]["emailAction"]["type"]
  | "DEFAULT";

export default function getEmailActionType(rule: Rule): EmailActionType {
  const storage = getStorage(rule);
  return storage.onStorageError?.emailAction?.type || "DEFAULT";
}
