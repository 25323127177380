import { useEffect, useState } from "react";
import { ButtonGroup, Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { TimePeriodStat } from "@dashboard-v3/api";
import SectionHeader from "components/SectionHeader";
import apiRequest from "utils/apiRequestWithErrorCode";
import ToggleButton from "./ToggleButton";
import AccountsBlock from "./Blocks/Accounts";
import ProcessedEmailsBlock from "./Blocks/ProcessedEmails";
import UploadedMegabytesBlock from "./Blocks/UploadedMegabytes";
import UploadedFilesChart from "./Charts/UploadedFiles";
import ProcessedEmailsChart from "./Charts/ProcessedEmails";
import { DaysRange, daysRanges } from "./dayRange";
import EmailFlow from "./Charts/EmailFlow";
import EmailFlowTotal from "./Blocks/EmailFlowTotal";

export default function UsageReports() {
  const [stats, setStats] = useState<TimePeriodStat[]>([]);
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState<DaysRange>(90);
  const { t } = useTranslation("usageReports");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchStats() {
      try {
        const searchStats = await apiRequest<TimePeriodStat[]>("GET", "/stats");
        setStats(searchStats);
      } catch (error) {
        console.error("UsageReports error:", error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      } finally {
        setLoading(false);
      }
    }

    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SectionHeader title={t("usageReports")} />

      <Box marginBottom="15px">
        <Typography
          display="inline"
          variant="body2"
          style={{ marginRight: ".5rem" }}
        >
          {t("lastMonthTitle")}
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        style={{ marginBottom: "20px" }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs>
          <ProcessedEmailsBlock stats={stats} loading={loading} />
        </Grid>

        <Grid item xs>
          <UploadedMegabytesBlock stats={stats} loading={loading} />
        </Grid>

        <Grid item xs>
          <EmailFlowTotal stats={stats} loading={loading} />
        </Grid>

        <Grid item xs>
          <AccountsBlock />
        </Grid>
      </Grid>

      <Box marginBottom="15px">
        <Typography
          display="inline"
          variant="body2"
          style={{ marginRight: ".5rem" }}
        >
          {t("filterByDays")}
        </Typography>
        <ButtonGroup
          variant="contained"
          color="primary"
          size="small"
          disableElevation
        >
          {daysRanges.map(daysRange => (
            <ToggleButton
              key={daysRange}
              active={range === daysRange}
              onClick={() => setRange(daysRange)}
            >
              {daysRange}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ProcessedEmailsChart stats={stats} range={range} />
        </Grid>
        <Grid item xs={12}>
          <UploadedFilesChart stats={stats} range={range} />
        </Grid>
        <Grid item xs={12}>
          <EmailFlow stats={stats} range={range} />
        </Grid>
      </Grid>
    </>
  );
}
