import produce from "immer";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useTranslation } from "react-i18next";
import { ProviderAccounts, ProviderType } from "@dashboard-v3/api";
import { OptionGroup } from "components/Styled";
import Domains from "./Domains";
import Accounts from "./Accounts";
import Groups from "./Groups";
import Tags from "./Tags";
import { useState } from "react";

interface Params {
  accounts: ProviderAccounts;
  onChange: (changes: ProviderAccounts) => void;
  disabled: boolean;
}

const SelectAccounts = ({ accounts, onChange, disabled }: Params) => {
  const { t } = useTranslation("rules");
  const { type, entities } = accounts;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProviderType = e => {
    const update = produce(accounts, draft => {
      draft.type = e.target.value as ProviderType;
      draft.entities = [];
    });
    onChange(update);
  };

  const handleEntities = (options: string[]) => {
    const update = produce(accounts, draft => {
      draft.entities = options;
    });
    onChange(update);
  };

  return (
    <OptionGroup>
      <Typography
        gutterBottom
        variant="subtitle1"
        style={{ fontWeight: "500" }}
      >
        {t("selectAccounts.title")}
      </Typography>

      <RadioGroup onChange={handleProviderType} value={type}>
        <FormControlLabel
          control={<Radio color="primary" size="small" />}
          label={t("selectAccounts.organization")}
          value="ORGANIZATION"
          disabled={disabled}
        />
        <FormControl>
          <FormControlLabel
            control={<Radio color="primary" size="small" />}
            label={t("selectAccounts.domains.title")}
            value="DOMAIN"
            disabled={disabled}
          />

          {type === "DOMAIN" && (
            <Domains
              value={entities}
              onChange={handleEntities}
              disabled={disabled}
            />
          )}
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={<Radio color="primary" size="small" />}
            label={t("selectAccounts.accounts.title")}
            value="ACCOUNT"
            disabled={disabled}
          />
          {type === "ACCOUNT" && (
            <Accounts value={accounts} onChange={handleEntities} />
          )}
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={<Radio color="primary" size="small" />}
            label={t("selectAccounts.groups.title")}
            value="GROUP"
            disabled={disabled}
          />
          {type === "GROUP" && (
            <Groups
              value={accounts}
              onChange={handleEntities}
              disabled={disabled}
            />
          )}
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={<Radio color="primary" size="small" />}
            label={
              <>
                {t("selectAccounts.tags.title")}
                <IconButton
                  aria-describedby={id}
                  onClick={handleClick}
                  aria-label="delete"
                  color="primary"
                  size="small"
                >
                  <HelpOutlineIcon fontSize="inherit" />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    style: { padding: "13px 17px", maxWidth: "470px" },
                  }}
                >
                  <Typography variant="caption">
                    {t("selectAccounts.tags.description")}{" "}
                    <Link display="block" href="/accounts" target="_blank">
                      {t("selectAccounts.tags.action")}{" "}
                      <OpenInNewIcon
                        fontSize="inherit"
                        style={{ verticalAlign: "middle" }}
                      />
                    </Link>
                  </Typography>
                </Popover>
              </>
            }
            value="TAG"
            disabled={disabled}
          />
          {type === "TAG" && (
            <Tags
              value={accounts}
              onChange={handleEntities}
              disabled={disabled}
            />
          )}
        </FormControl>
      </RadioGroup>
    </OptionGroup>
  );
};

export default SelectAccounts;
