/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Item } from "./types";
import {
  StorageAccount,
  StorageSettings,
  StorageProvider,
} from "@dashboard-v3/api";
import { useFetch } from "utils/api/useFetch";

export default function useStorageAccounts(withMxheroStorage?: boolean) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: accounts,
    isLoading: accountsLoading,
    mutate: refetchAccounts,
    error: accountsError,
  } = useFetch<StorageAccount[]>("/storage-accounts");

  const {
    data: storageSettings,
    isLoading: settingsLoading,
    mutate: refetchSettings,
    error: settingsError,
  } = useFetch<StorageSettings>("/storages/settings");

  async function reFetchOptions() {
    refetchAccounts();
    refetchSettings();
  }

  useEffect(() => {
    if (accountsError || settingsError) {
      enqueueSnackbar(t("cloudStorage.errors.fetchError"), {
        variant: "error",
      });
    }
  }, [accountsError, settingsError]);

  return {
    items:
      settingsLoading || accountsLoading
        ? null
        : mapToItems(storageSettings, accounts, withMxheroStorage),
    reFetchOptions,
    isLoading: accountsLoading || settingsLoading,
  };
}

function mapToItems(
  settings?: StorageSettings,
  storageAccounts?: StorageAccount[],
  withMxheroStorage?: boolean
) {
  const orgItems = mapOrgAccounts(settings, withMxheroStorage);
  const individualItems = mpaIndividualItems(storageAccounts);

  return orgItems.concat(individualItems);
}

function mapOrgAccounts(
  settings?: StorageSettings,
  withMxheroStorage?: boolean
): Array<Item> {
  if (!settings) return [];

  const items = [
    itemOfProvider("BOX", Boolean(settings.box?.enterpriseId)),
    itemOfProvider("GOOGLEDRIVE", Boolean(settings.gapps?.installed)),
    itemOfProvider("ONEDRIVE", Boolean(settings.onedrive?.installed)),
  ];

  if (withMxheroStorage) {
    items.push(
      itemOfProvider("MXHERO_STORAGE", Boolean(settings.mxheroStorage?.enabled))
    );
  }

  return items;
}

function mpaIndividualItems(storageAccounts?: StorageAccount[]) {
  if (!storageAccounts) return [];

  return storageAccounts.map(({ type, user, synced, data }, index) => {
    const isFirstIndividual = index === 0;
    return {
      key: crypto.randomUUID(),
      option: {
        enabled: synced,
        subTitle: `cloudStorage.header.subtitle.${type}`,
        title: type === "DROPBOX" ? data.name : user,
        showSeparator: isFirstIndividual,
      },
      storageAccount: {
        authentication: "INDIVIDUAL",
        provider: type,
        userId: user,
      },
    } as Item;
  });
}

function itemOfProvider(
  provider: StorageProvider | "MXHERO_STORAGE",
  enabled: boolean
): Item {
  return {
    key: crypto.randomUUID(),
    option: {
      enabled,
      title: `cloudStorage.accounts.org.${provider}`,
    },
    storageAccount: {
      authentication: "ORGANIZATION",
      provider,
    },
  };
}
