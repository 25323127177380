import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import ButtonWithLoading from "components/ButtonWithLoading";
import { getUser } from "utils/authentication";
import apiRequest from "utils/apiRequestWithErrorCode";
import isEmail from "utils/isEmail";
import { StyledDialogContent } from "../../Styled";

import {
  ValidateMailboxRequest,
  ValidateMailboxResponse,
} from "@dashboard-v3/api";
import { StyledTextField } from "components/UserFormModal/Styled";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type EmailInput = {
  value: string;
  error: string;
};

export default function VerifyAccountModal(props: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [emailInput, setEmailInput] = useState<EmailInput>(initEmail);
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose } = props;

  const onEmailChange = e => {
    const { value } = e.target;
    const update = { value, error: "" };
    setEmailInput(update);
  };

  const onVerifyConnection = async () => {
    const { value: email } = emailInput;

    if (!isEmail(email)) {
      return setEmailInput(prev => ({
        ...prev,
        error: t("officeMailboxSync.verify.emailFormatError"),
      }));
    }

    setLoading(true);
    try {
      const reqBody: ValidateMailboxRequest = {
        emailAccount: email,
        inboxType: "OFFICE365",
        loginType: "ORGANIZATION",
      };

      const res = await apiRequest<ValidateMailboxResponse>(
        "POST",
        "/mailbox/validate",
        reqBody
      );
      const { key, variant } = getResultMessage(res.isValid);
      enqueueSnackbar(t(key, { email }), { variant });
      onClose();
    } catch (e) {
      enqueueSnackbar(t("officeMailboxSync.verify.unknownError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h6" gutterBottom>
          {t("officeMailboxSync.verify.title")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("officeMailboxSync.verify.description")}
        </Typography>
      </DialogTitle>
      <StyledDialogContent>
        <StyledTextField
          value={emailInput.value}
          error={!!emailInput.error}
          helperText={emailInput.error}
          onChange={onEmailChange}
        />
      </StyledDialogContent>
      <DialogActions style={{ marginTop: "10px" }}>
        <Button
          variant="text"
          size="small"
          wording={t("common:cancel")}
          onClick={onClose}
          disabled={loading}
        />
        <ButtonWithLoading
          variant="contained"
          size="small"
          disableElevation
          disabled={!!emailInput.error}
          loading={loading}
          onClick={onVerifyConnection}
        >
          {t("verify")}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
}

const getResultMessage = (
  isValid: boolean
): { variant: "success" | "error"; key: string } => {
  return {
    variant: isValid ? "success" : "error",
    key: isValid
      ? "officeMailboxSync.verify.valid"
      : "officeMailboxSync.verify.notValid",
  };
};

const initEmail = () => {
  const { email: currentEmail } = getUser();
  return { value: currentEmail, error: "" };
};
