/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import produce from "immer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { OptionGroup, OptionTitle, StyledPaper } from "components/Styled";
import PillButton from "components/Forms/PillButton";
import PageLoading from "components/PageLoading";
import { Button } from "components/Forms/StyledComponents";
import {
  createCopyPolicy,
  fetchCopyPolicy,
  updateCopyPolicy,
} from "utils/api/copyPolicies";
import useFormState from "utils/useFormState";
import apiRequest from "utils/apiRequestWithErrorCode";
import PolicyCondition from "./PolicyCondition";
import DefaultCondition from "./DefaultCondition";
import { toFormState, toPolicyReqBody } from "../policyMapper";
import { CopyPolicyReqBody, SanitizedFilename } from "@dashboard-v3/api";

import {
  DefaultType,
  EditState,
  FilenameErrors,
  FormState,
  ResetErrorData,
  ValidationFields,
} from "../types";

export default function CopyPolicyForm() {
  const { t } = useTranslation("copyPolicy");
  const [loading, setLoading] = useState(false);
  const [pathErrors, setPathErrors] = useState<FilenameErrors["errors"]>({});
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id: paramId } = useParams();
  const { state, pathname }: EditState = useLocation();
  const isEdit = pathname.includes("edit");
  const { formState, updateValues, setValues, reset } = useFormState<FormState>(
    formInit(state?.selectedPolicy)
  );

  useEffect(() => {
    const fetchPolicyById = async (policyId: string) => {
      setLoading(true);
      try {
        const policy = await fetchCopyPolicy(policyId);
        setValues(toFormState(policy));
      } catch (e: unknown) {
        enqueueSnackbar(t("form.errors.fetchByIdError"), {
          variant: "error",
        });
        history.push("/copy-policies");
      } finally {
        setLoading(false);
      }
    };

    async function handleEditData() {
      if (state?.policyId === paramId) return;
      await fetchPolicyById(paramId);
    }

    if (isEdit) {
      handleEditData();
    }
  }, []);

  const savePolicy = async (body: CopyPolicyReqBody, id?: string) => {
    setLoading(true);
    try {
      if (id) {
        await updateCopyPolicy(id, body);
      } else {
        await createCopyPolicy(body);
      }

      enqueueSnackbar(t("form.messages.savedPolicy"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("form.errors.fetchError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
      history.push("/copy-policies");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = await folderPathValidation();

    if (!validation.isValid) {
      return setPathErrors(validation.errors);
    }

    if (state && isEdit) {
      const { policyId } = state;
      savePolicy(toPolicyReqBody(formState), policyId);
    } else {
      savePolicy(toPolicyReqBody(formState));
    }
  };

  const addPredicate = () => {
    const {
      predicates: [predicate],
    } = formInit();
    setValues({
      predicates: [...formState.predicates, predicate],
    });
  };

  const validateFilenames = async (fields: ValidationFields[]) => {
    const errorMsg = t("form.errors.invalidPath");
    const result: FilenameErrors = {
      isValid: true,
      errors: { defaultErrors: {} },
    };

    setLoading(true);

    for (const [i, field] of fields.entries()) {
      const { value, isDefault } = field;

      if (!value) continue;

      try {
        const { formattedList } = await apiRequest<SanitizedFilename>(
          "POST",
          "/platform/check-filename",
          { value }
        );
        const isValid = formattedList.join("/") === value;

        if (isValid) continue;

        result.isValid = false;

        if (isDefault) {
          const { defaultType } = field;
          result.errors.defaultErrors[defaultType] = errorMsg;
        } else {
          result.errors[i] = errorMsg;
        }
      } catch (e) {
        console.error(e);
        break;
      }
    }
    setLoading(false);

    return result;
  };

  const folderPathValidation = async () => {
    const { predicates } = formState;
    const defaultTypes = Object.keys(formState.defaultCopies);
    const fieldsToCheck = predicates.map<ValidationFields>(p => ({
      isDefault: false,
      value: p.folderPath.folderStructure,
    }));

    defaultTypes.forEach(key => {
      const defaultType = key as DefaultType;
      const item = formState.defaultCopies[defaultType];

      if (item.folderStructure) {
        fieldsToCheck.push({
          isDefault: true,
          defaultType,
          value: item.folderStructure,
        });
      }
    });

    const result = await validateFilenames(fieldsToCheck);

    return result;
  };

  function resetPathErrors(data: ResetErrorData) {
    return (defaultType?: DefaultType) => {
      const { index, isDefault } = data;

      const updateFn = (draft: FilenameErrors["errors"]) => {
        if (isDefault && draft.defaultErrors) {
          delete draft.defaultErrors[defaultType];
        } else {
          delete draft[index];
        }
      };

      setPathErrors(prev => produce(prev, updateFn));
    };
  }

  return (
    <form onSubmit={handleSubmit}>
      <PageLoading loading={loading} />
      <Grid container spacing={5}>
        <Grid item lg={8} xs={12}>
          <Paper style={{ padding: "15px 24px 15px 24px" }}>
            <Typography
              gutterBottom
              variant="h6"
              style={{ marginBottom: "20px" }}
            >
              {isEdit ? t("form.editCopyPolicy") : t("form.createANewPolicy")}
            </Typography>
            <OptionGroup>
              <TextField
                name="name"
                label={t("form.copyPolicyName")}
                variant="outlined"
                value={formState.name}
                onChange={updateValues}
                fullWidth
                required
              />
            </OptionGroup>
            <OptionGroup>
              <TextField
                name="description"
                label={t("form.copyPolicyDescription")}
                variant="outlined"
                value={formState.description}
                onChange={updateValues}
                fullWidth
              />
            </OptionGroup>
            <OptionGroup>
              <FormLabel>
                <OptionTitle>
                  {t("form.selectOnWhichConditionMatchesToApply")}
                </OptionTitle>
              </FormLabel>
              <RadioGroup
                name="conditionMatch"
                onChange={updateValues}
                value={formState.conditionMatch}
              >
                <FormControlLabel
                  control={<Radio color="primary" size="small" />}
                  label={t("form.onTheFirstMatch")}
                  value="first"
                />
                <FormControlLabel
                  control={<Radio color="primary" size="small" />}
                  label={t("form.onEveryMatch")}
                  value="all"
                />
              </RadioGroup>
            </OptionGroup>
          </Paper>
          {formState.predicates.map((_, i) => (
            <PolicyCondition
              key={i}
              index={i}
              formData={{ formState, setValues }}
              pathErrors={pathErrors}
              resetPathErrors={resetPathErrors({ index: i })}
            />
          ))}
          <PillButton
            wording={t("form.addCondition")}
            onClick={addPredicate}
            lineSeparator="full"
          />
          <DefaultCondition
            formData={{ formState, setValues, reset }}
            isEdit={isEdit}
            pathErrors={pathErrors.defaultErrors}
            resetPathErrors={resetPathErrors({ isDefault: true })}
          />
          <StyledPaper style={{ padding: "25px 24px" }}>
            <Button
              type="submit"
              size="large"
              fullWidth
              wording={t("form.savePolicy")}
            />
          </StyledPaper>
        </Grid>
      </Grid>
    </form>
  );
}

const formInit = (policy?: FormState): FormState => {
  if (policy) return policy;
  return {
    name: "",
    description: "",
    conditionMatch: "first",
    predicates: [
      {
        variableIdList: [],
        folderPath: {
          parentId: "",
          parentName: "",
          folderStructure: "",
        },
        match: true,
        firstMatch: false,
      },
    ],
    defaultCopies: {},
  };
};
