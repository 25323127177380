import styled from "styled-components";

export const UnstyledList = styled.ul`
  padding-inline-start: 0;
  list-style-type: none;
  margin-top: 0;
`;

export const ItemWithMargin = styled.li`
  padding-left: 10px;
`;

export const HelperAccountsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  li {
    padding: 0;
  }
`;
