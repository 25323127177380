/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import AutocompleteSearchInput from "components/AutocompleteSearchInput";
import PageLoading from "components/PageLoading";
import { OptionGroup, OptionTitle } from "components/Styled";
import useVariables from "utils/useVariables";
import SelectParentType from "./SelectParentType";
import FilepathTextFields from "./FilepathTextFields";
import SelectStore from "./SelectStore";
import StorageProviderSelector from "./SelectStorageProvider";
import FolderPickerModal from "./FolderPickerModal";
import PickFolderBtn from "./PickFolderBtn";
import { SelectProviderWrapper, StyledLabel } from "../../Styled";

import { StorageProvider } from "@dashboard-v3/api";
import { StorageFolder } from "pages/Rules/StorageFilepath/FilepathSelector/types";

export type FolderPath = {
  folderStructure?: string;
  parentName?: string;
  parentId?: string;
  storeId?: string;
};

interface Params {
  folderPath: FolderPath;
  pathError?: string;
  storageProvider?: StorageProvider;
  isProviderDisabled: boolean;
  canResetStorage: boolean;
  onFolderPathChange: (folderPath: FolderPath) => void;
  onStorageProviderChange: (provider: StorageProvider) => void;
}

export enum ParentType {
  Storage = "STORAGE",
  Root = "ROOT",
  Variable = "VARIABLE",
  SharepointSite = "SHAREPOINT_SITE",
}

export default function StorageFilepathSelector({
  folderPath,
  pathError,
  storageProvider,
  isProviderDisabled,
  onFolderPathChange,
  onStorageProviderChange,
  canResetStorage,
}: Params) {
  const { t } = useTranslation("copyPolicy");
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = Boolean(id) && pathname.includes("/edit");
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [parentType, setParentType] = useState<ParentType>(() => {
    if (!isEdit) return ParentType.Root;
    if (folderPath?.storeId) return ParentType.SharepointSite;
    if (!folderPath?.parentId) return ParentType.Root;
    return folderPath.parentName ? ParentType.Storage : ParentType.Variable;
  });

  const { variableOptions, loading, mapVarToValue, mapVarToLabel } =
    useVariables();

  const showPickFolder = storageProvider && parentType === ParentType.Storage;

  const onParentTypeChange = (type: ParentType) => {
    setParentType(prev => {
      if (prev === type) return;

      if (type !== ParentType.Storage && canResetStorage) {
        onStorageProviderChange(null);
      }

      if (type === ParentType.SharepointSite) {
        onStorageProviderChange("ONEDRIVE");
      }

      onFolderPathChange({
        folderStructure: folderPath.folderStructure,
      });

      return type;
    });
  };

  const onVariableChange = (id: string) => {
    onFolderPathChange({
      folderStructure: folderPath.folderStructure,
      parentId: mapVarToValue(id, variableOptions),
    });
  };

  function onSubfolderChange(subfolder: string) {
    let update: FolderPath;

    if (parentType === ParentType.Root) {
      update = {
        folderStructure: mapVarToValue(subfolder, variableOptions),
      };
    } else {
      update = {
        ...folderPath,
        folderStructure: mapVarToValue(subfolder, variableOptions),
      };
    }

    onFolderPathChange(update);
  }

  const onPickFolderSelect = (folder?: StorageFolder) => {
    setIsPickerOpen(false);

    if (folder) {
      onFolderPathChange({
        ...folder,
        folderStructure: folderPath.folderStructure,
      });
    }
  };

  const handleStorageProvider = (provider: StorageProvider) => {
    onStorageProviderChange(provider);
    setIsPickerOpen(true);
  };

  const updateStoreId = (storeId: string) => {
    onFolderPathChange({
      ...folderPath,
      storeId,
    });
  };

  return (
    <>
      <OptionGroup style={{ marginBottom: "15px" }}>
        <PageLoading loading={loading} />
        <OptionTitle>{t("form.saveTheEmailToThisCloudStorage")}</OptionTitle>
        <Box display="flex" mb="1em">
          <SelectParentType
            value={parentType}
            onChange={onParentTypeChange}
            storageProvider={storageProvider}
          />
        </Box>
        {parentType === ParentType.Variable && (
          <Box>
            <StyledLabel>{t("form.selectVariable.label")}</StyledLabel>
            <AutocompleteSearchInput
              placeholder={t("form.selectVariable.placeholder")}
              variables={variableOptions?.filter(e => e.origin === "USER")}
              value={mapVarToLabel(folderPath.parentId, variableOptions)}
              onChange={onVariableChange}
              required
            />
          </Box>
        )}
        {parentType === ParentType.SharepointSite && (
          <Box>
            <StyledLabel>Select Sharepoint Site</StyledLabel>
            <SelectStore
              onChange={updateStoreId}
              storeId={folderPath.storeId}
            />
          </Box>
        )}
        {parentType === ParentType.Storage && (
          <SelectProviderWrapper>
            <StorageProviderSelector
              value={storageProvider}
              onChange={handleStorageProvider}
              disabled={isProviderDisabled}
            />
            {showPickFolder && (
              <PickFolderBtn onClick={() => setIsPickerOpen(true)} />
            )}
          </SelectProviderWrapper>
        )}
        <FilepathTextFields
          parentType={parentType}
          folderPath={folderPath}
          pathError={pathError}
          variableOptions={variableOptions}
          onChange={onSubfolderChange}
        />
        <FolderPickerModal
          open={isPickerOpen}
          provider={storageProvider}
          onChoose={onPickFolderSelect}
        />
      </OptionGroup>
    </>
  );
}
