import styled from "styled-components";
import { AccordionDetails, Avatar, List, Paper } from "@material-ui/core";

export const StyledAvatar = styled(Avatar)`
  align-self: center;
  text-transform: uppercase;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  padding-bottom: 0;
  padding-top: 0;
`;

export const StyledListContainer = styled.div`
  background-color: #eef2f6;
  border-radius: 4px;
  display: block;
  margin-bottom: 20px;
  max-height: 340px;
  overflow: auto;
  padding: 16px;
`;

export const Li = styled.li`
  padding-top: 8px;
`;

export const MainPanel = styled(Paper)`
  align-self: start;
  align-items: center;
  display: grid;
  padding: 16px;
  text-align: center;
`;

export const StyledList = styled(List)`
  li:first-child {
    padding-top: 0;
    padding-bottom: 8px;
  }
  li:last-child {
    padding-bottom: 0;
  }
`;
