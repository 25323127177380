import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from "@material-ui/core";
import PageLoading from "components/PageLoading";
import { OptionGroup } from "components/Styled";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { State } from "pages/Rules/CreateOrEdit/context/types";
import { getMailbox } from "pages/Rules/CreateOrEdit/context/helpers";
import AdvancedOptions from "./AdvancedOptions";
import SelectTimeRange, { TimeRangeAndRepeat } from "./SelectTimeRange";
import ImapServerConfig from "./ImapServerConfig";
import SelectAccounts from "./SelectAccounts/index";
import ChooseProvider, { ServerType } from "./ChooseProvider";
import ReauthenticateSingleAccount from "./ReauthenticateSingleAccount";
import type { SingleAccountProvider } from "./ReauthenticateSingleAccount";
import {
  enableAccountSelector,
  getServerUse,
  isSingleAccount,
  getAccountState,
  getAuthSingleAccount,
  isOfficeIndividual,
} from "./utils";
import ConfirmButton from "../ConfirmButton";
import type { Mailbox } from "@dashboard-v3/api";
import type { RuleUrlParams } from "pages/Rules/CreateOrEdit";

const MailboxOptions = () => {
  const { t } = useTranslation(["rules", "components"]);
  const { action }: RuleUrlParams = useParams();
  const { state, dispatch } = useRuleContext();
  const mailbox = getMailbox(state.rule);
  const server = getServerUse(mailbox);
  const isEdit = Boolean(state.rule.id) && action === "edit";

  const [accountSynced, setAccountSynced] = useState(true);
  const [useOtherMailboxes, setUseOtherMailboxes] = useState(
    handleOtherMailboxes(mailbox)
  );

  useEffect(() => {
    const fetchAccountAuth = async (
      provider: "GOOGLE" | "OFFICE365",
      userId: string
    ) => {
      try {
        const account = await getAccountState(provider, userId);
        setAccountSynced(account.sync);
      } catch (error) {
        setAccountSynced(false);
      }
    };

    if (isSingleAccount(mailbox)) {
      fetchAccountAuth(
        mailbox.provider as "GOOGLE" | "OFFICE365",
        getAuthSingleAccount(mailbox)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailbox]);

  const updateMailbox = (updates: Partial<Mailbox>) => {
    const changes = { ...mailbox, ...updates };
    dispatch({
      type: "updateStep",
      payload: { step: "target", changes },
    });
  };

  const handleServerType = (serverType?: ServerType) => {
    const { provider, authentication, accounts } = serverType || {
      provider: null,
      authentication: null,
    };
    const update: Partial<Mailbox> = {
      provider,
      authentication,
      accounts: accounts || mailbox.accounts,
    };

    if (provider === "OFFICE365" && accounts?.entities?.length > 1) {
      update.loginUser = accounts.entities[0];
    } else {
      update.loginUser = null;
    }

    updateMailbox(update);
  };

  const changeUserOtherMailboxes = event => {
    const { checked } = event.target;
    setUseOtherMailboxes(checked);
    if (!checked) {
      // Reset to single account
      const changes: Partial<Mailbox> = {
        accounts: { type: "ACCOUNT", entities: [mailbox.loginUser] },
      };

      dispatch({
        type: "updateStep",
        payload: { step: "target", changes },
      });
    }
  };

  const handleTimeRange = (value: TimeRangeAndRepeat) => {
    updateMailbox({ timeRange: value.timeRange, repeatable: value.repeatable });
  };

  const isConfirmDisabled = (state: State) => {
    if (state.steps?.target?.done) return true;
    return !state.steps.target.valid;
  };

  return (
    <>
      {state.asyncStatus === "validate_mailbox_imap" && <PageLoading loading />}
      <div style={{ padding: "0 24px 24px" }}>
        <OptionGroup>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontWeight: "500" }}
          >
            {t("mailboxSection.server.title")}
          </Typography>
          <ChooseProvider
            value={server}
            onChange={handleServerType}
            disabled={isEdit}
          />
        </OptionGroup>

        {mailbox.provider === "IMAP" && (
          <OptionGroup>
            <ImapServerConfig
              value={mailbox.imapServer}
              onChange={imapServer => updateMailbox({ imapServer })}
            />
          </OptionGroup>
        )}

        {isSingleAccount(mailbox) && (
          <OptionGroup>
            {!accountSynced && (
              <ReauthenticateSingleAccount
                provider={mailbox.provider as SingleAccountProvider}
                email={getAuthSingleAccount(mailbox)}
                onSuccess={() => setAccountSynced(true)}
              />
            )}
            {isOfficeIndividual(mailbox) && (
              <UseOtherMailboxes
                label={t("mailboxSection.useOtherMailboxes")}
                checked={useOtherMailboxes}
                onChange={changeUserOtherMailboxes}
                disabled={isEdit}
              />
            )}
          </OptionGroup>
        )}

        {enableAccountSelector(mailbox, useOtherMailboxes) && (
          <SelectAccounts
            accounts={mailbox.accounts}
            onChange={accounts => updateMailbox({ accounts })}
            disabled={isEdit}
          />
        )}

        <SelectTimeRange
          value={{
            timeRange: mailbox.timeRange,
            repeatable: mailbox.repeatable,
          }}
          onChange={handleTimeRange}
          disabled={isEdit}
        />

        <ConfirmButton
          onClick={() => dispatch({ type: "confirmStep", payload: "target" })}
          disabled={isConfirmDisabled(state)}
        />
        {isInvalidImap(state, mailbox) && (
          <FormHelperText error margin="dense">
            <Typography variant="body2">{t(mapErrorCheck(state))}</Typography>
          </FormHelperText>
        )}
      </div>
      <AdvancedOptions mailbox={mailbox} updateMailbox={updateMailbox} />
    </>
  );
};

function UseOtherMailboxes({ label, checked, onChange, disabled }) {
  return (
    <Box my={2}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={checked}
            onChange={onChange}
            data-testid="check_useOtherMailboxes"
          />
        }
        label={label}
        disabled={disabled}
      />
    </Box>
  );
}

function mapErrorCheck(state: State) {
  const errorType = state.checks.mailboxImap?.error;
  return `target.MAIL_BOX.checkError.${errorType}`;
}

function isInvalidImap(state: State, mailbox: Mailbox) {
  if (mailbox.provider !== "IMAP") return false;
  return Boolean(state.checks.mailboxImap?.error);
}

function handleOtherMailboxes(mailbox: Mailbox) {
  if (
    isSingleAccount(mailbox) &&
    mailbox.authentication === "INDIVIDUAL" &&
    Boolean(mailbox.loginUser)
  ) {
    if (handleSameAccountAsLogin(mailbox)) return false;
    return true;
  }
}

function handleSameAccountAsLogin(mailbox: Mailbox) {
  return (
    mailbox.accounts.type === "ACCOUNT" &&
    mailbox.accounts.entities.length === 1 &&
    mailbox.accounts.entities[0] === mailbox.loginUser
  );
}

export default MailboxOptions;
