import { ContentFilter } from "@dashboard-v3/api";
import { State } from "../types";
import produce from "immer";

const ruleTypeMap = new Map([
  ["COPY_EMAILS", "copyEmails"],
  ["MOVE_EMAILS", "moveEmails"],
  ["PROTECT_EMAILS", "protectEmails"],
  ["REPLACE_ATTACHMENTS", "replaceAttachments"],
  ["BLOCK_ATTACHMENTS", "blockAttachments"],
  ["DRAG_AND_DROP", "dragAndDrop"],
]);

export function updateFilterAction(
  state: State,
  filter?: ContentFilter
): State {
  if (state.type === "BLOCK_ATTACHMENTS") {
    return produce(state, draft => {
      if (filter) {
        draft.rule.blockAttachments.contentFilter = filter;
      } else {
        delete draft.rule.blockAttachments.contentFilter;
      }
    });
  } else {
    const typeKey = ruleTypeMap.get(state.type);
    return produce(state, draft => {
      draft.rule[typeKey].contentToSave.filter = filter;
    });
  }
}

export type UpdateFilterAction = {
  type: "updateFilterAction";
  payload: ContentFilter | null;
};
