import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import PageLoading from "components/PageLoading";
import requestResetPassword from "./api";
import { getErrorCode } from "utils/apiRequestWithErrorCode";

const StyledLoginPanel = styled(Paper)`
  border-radius: 8px;
  display: grid;
  grid-row-gap: 18px;
  justify-items: center;
  width: 390px;
  padding: 50px 65px;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const ResetPasswordPanel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const { t } = useTranslation("resetPassword");
  const history = useHistory();

  async function handleFormSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      await requestResetPassword(userEmail);
      setResetEmailSent(true);
      setIsLoading(false);
    } catch (error) {
      const errorCode = getErrorCode(error);
      enqueueSnackbar(t(`error.requestResetPassword.${errorCode}`), {
        variant: "error",
      });

      setIsLoading(false);
    }
    return false;
  }

  const handleUserEmailInput = e => {
    setUserEmail(e.target.value);
  };

  if (resetEmailSent) {
    return (
      <StyledLoginPanel>
        <FullWidthContainer>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "8px" }}
          >
            {t("emailResetSentExplanation")}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "8px" }}
          >
            {t("clickLinkChangePassword")} <br />
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "8px" }}
          >
            {t("checkEmailExplanation")}
          </Typography>
        </FullWidthContainer>
        <Button
          data-testid="landing__switch-signin-btn"
          onClick={() => history.push("/login")}
          size="large"
        >
          {t("switchToSignIn")}
        </Button>
      </StyledLoginPanel>
    );
  }

  return (
    <>
      <PageLoading loading={isLoading} />
      <form onSubmit={handleFormSubmit}>
        <StyledLoginPanel>
          <FullWidthContainer>
            <Typography
              variant="body2"
              align="center"
              style={{ width: "100%", marginBottom: "5px" }}
            >
              {t("confirmEmailForReset")}
            </Typography>
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "resetpass__user-email-input",
              }}
              onChange={handleUserEmailInput}
              placeholder="user@domain.com"
              required
              size="small"
              type="email"
              value={userEmail}
              variant="outlined"
            />
          </FullWidthContainer>
          <Button
            color="primary"
            data-testid="resetpass__reset-btn"
            disableElevation
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {t("resetPassword")}
          </Button>
          <Button
            data-testid="landing__switch-signin-btn"
            onClick={() => history.push("/login")}
            size="large"
          >
            {t("switchToSignIn")}
          </Button>
        </StyledLoginPanel>
      </form>
    </>
  );
};

export default ResetPasswordPanel;
