import { useTranslation } from "react-i18next";
import { OptionGroup } from "components/Styled";
import { RuleType } from "pages/Rules/types";
import FilterFromTo from "./FilterFromTo";
import { useRuleContext } from "../../context/ruleContext";
import { emailFlowFilter } from "../helpers";
import { EmailFlowFilter, FromTo } from "@dashboard-v3/api";
import SwitchField from "components/Forms/SwitchField";

export default function EmailFlowOptions() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { actionType } = state.rule;
  const { errors } = state.filters.targetFilter;
  let filter = emailFlowFilter(state.rule);

  function updateFilter(updates: Partial<EmailFlowFilter>) {
    filter = { ...filter, ...updates };
    if (filter.triggerOnShortCode) {
      delete filter.cancelShortCodeEnabled;
    }
    dispatch({ type: "updateFilterTarget", payload: filter });
  }

  return (
    <>
      {showShortCodeOption(state.type) && (
        <>
          <OptionGroup>
            <SwitchField
              name="triggerOnShortCode"
              label={t("triggerOnShortCode")}
              description={t(`triggerOnShortCodeDescription.${actionType}`)}
              value={filter?.triggerOnShortCode}
              onChange={event => {
                updateFilter({ triggerOnShortCode: event.target.checked });
              }}
            />
          </OptionGroup>
          <OptionGroup>
            <SwitchField
              name="cancelShortCodeEnabled"
              label={t("cancelShortCodeEnabled")}
              description={t("cancelShortCodeEnabledDescription")}
              value={filter?.cancelShortCodeEnabled}
              disabled={!!filter?.triggerOnShortCode}
              onChange={event => {
                updateFilter({ cancelShortCodeEnabled: event.target.checked });
              }}
            />
          </OptionGroup>
        </>
      )}
      {showOnlyManagedAccounts(state.type) && (
        <OptionGroup>
          <SwitchField
            name="onlyManagedAccounts"
            label={t("onlyManagedAccounts")}
            description={t("onlyManagedAccountsDescription")}
            value={filter?.onlyManagedAccounts}
            onChange={e =>
              updateFilter({ onlyManagedAccounts: e.target.checked })
            }
          />
        </OptionGroup>
      )}

      <OptionGroup>
        <FilterFromTo
          type="exclude"
          value={filter?.exclude}
          errors={errors.exclusions}
          onChange={(exclude: FromTo[]) => updateFilter({ exclude })}
        />
      </OptionGroup>
    </>
  );
}

function showShortCodeOption(type: RuleType) {
  return type === "REPLACE_ATTACHMENTS" || type === "PROTECT_EMAILS";
}

function showOnlyManagedAccounts(type: RuleType) {
  return [
    "COPY_EMAILS",
    "PROTECT_EMAILS",
    "REPLACE_ATTACHMENTS",
    "BLOCK_ATTACHMENTS",
  ].some(t => t === type);
}
