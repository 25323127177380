import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import UserFormModal, { UserFormProps } from "components/UserFormModal";
import AuthenticationModal from "components/AuthenticationModal";
import { shouldAuthenticate } from "utils/authentication";

interface CreateButtonProps {
  onCreate: UserFormProps["onSave"];
}

const CreateBtn = ({ onCreate }: CreateButtonProps) => {
  const { t } = useTranslation("users");
  const [userFormIsOpen, setUserFormIsOpen] = useState(false);
  const [authIsOpen, setAuthIsOpen] = useState(false);

  const onButtonClick = () => {
    if (shouldAuthenticate()) {
      setAuthIsOpen(true);
    } else {
      setUserFormIsOpen(true);
    }
  };

  const onAuthSuccess = () => {
    setAuthIsOpen(false);
    setUserFormIsOpen(true);
  };

  return (
    <>
      <Button
        data-testid="users__add-user-btn"
        onClick={onButtonClick}
        color="primary"
        variant="contained"
        disableElevation
      >
        {t("addUser")}
      </Button>
      {userFormIsOpen && (
        <UserFormModal
          isOpen={userFormIsOpen}
          onSave={onCreate}
          onClose={() => setUserFormIsOpen(false)}
        />
      )}
      {authIsOpen && (
        <AuthenticationModal
          isOpen={authIsOpen}
          onSuccess={onAuthSuccess}
          onClose={() => setAuthIsOpen(false)}
        />
      )}
    </>
  );
};

export default CreateBtn;
