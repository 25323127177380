import NewItem from "./NewItem";
import Item from "./Item";
import { Button } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ManualDomain } from "@dashboard-v3/api";
import useManualDomains from "./useManualDomains";
import EmptyState from "./EmptyState";

export default function List() {
  const { t } = useTranslation("orgAndDomains");

  const [showForm, setShowForm] = useState(false);
  const { data } = useManualDomains();

  if (data?.length === 0 && !showForm) {
    return <EmptyState onClick={() => setShowForm(true)} />;
  }

  return (
    <>
      {!showForm && (
        <Button
          color="primary"
          disableElevation
          variant="contained"
          onClick={() => {
            setShowForm(true);
          }}
        >
          {t("manualDomains.add")}
        </Button>
      )}

      {showForm && (
        <NewItem
          onCancel={() => setShowForm(false)}
          onDone={() => setShowForm(false)}
        />
      )}

      {data.sort(byCreatedAt).map(domain => (
        <Item key={domain.txtEntry} domain={domain} />
      ))}
    </>
  );
}

function byCreatedAt(one: ManualDomain, other: ManualDomain) {
  return other.createdAt - one.createdAt;
}
