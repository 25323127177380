import { useTranslation } from "react-i18next";
import nextId from "react-id-generator";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { GatewayHeader } from "@dashboard-v3/api";

type HeadersWithId = GatewayHeader & { id: string };

interface HeadersProps {
  headers: HeadersWithId[];
  onChange: (newHeaders: HeadersWithId[]) => void;
}

export default function GatewayHeaders({ headers, onChange }: HeadersProps) {
  const { t } = useTranslation("orgAndDomains");

  const handleAddHeader = () => {
    onChange([...headers, ...initHeaders()]);
  };

  const handleDeleteHeader = (index: number) => {
    onChange(headers.slice(0, index).concat(headers.slice(index + 1)));
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newHeaders = [...headers];

    if (name === "directions") {
      let directions;
      switch (value) {
        case "BOTH":
          directions = ["IN", "OUT"];
          break;
        default:
          directions = [value];
          break;
      }
      newHeaders[index]["directions"] = directions;
    } else {
      newHeaders[index][name] = value;
    }

    onChange(newHeaders);
  };

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography variant="subtitle2" display="inline">
          {t("headers.gatewayHeaders")}
        </Typography>
        {!!headers.length && (
          <>
            <Button
              color="primary"
              disableElevation
              onClick={handleAddHeader}
              size="small"
              startIcon={<AddIcon />}
              style={{ marginLeft: "10px" }}
            >
              {t("headers.addItem")}
            </Button>
          </>
        )}
      </Box>

      {!headers.length ? (
        <Button
          onClick={handleAddHeader}
          color="default"
          disableElevation
          variant="contained"
        >
          {t("headers.addGatewayHeaders")}
        </Button>
      ) : (
        headers.map((header, i) => (
          <Box key={header.id} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel>{t("headers.direction")}</InputLabel>
                  <Select
                    label={t("headers.directions")}
                    name="directions"
                    onChange={e => handleInputChange(i, e)}
                    value={
                      header.directions.length === 1
                        ? header.directions[0]
                        : "BOTH"
                    }
                  >
                    <MenuItem value="IN">{t("headers.in")}</MenuItem>
                    <MenuItem value="OUT">{t("headers.out")}</MenuItem>
                    <MenuItem value="BOTH">{t("headers.both")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <TextField
                  error={prohibitedName(header.name)}
                  helperText={
                    prohibitedName(header.name)
                      ? t("headers.nameProhibited")
                      : ""
                  }
                  fullWidth
                  label={t("headers.headerName")}
                  name="name"
                  onChange={e => handleInputChange(i, e)}
                  required
                  type="text"
                  value={header.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  label={t("headers.headerValue")}
                  name="value"
                  onChange={e => handleInputChange(i, e)}
                  required
                  type="text"
                  value={header.value}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs="auto">
                <IconButton
                  aria-label={t("headers.deleteHeader")}
                  edge="start"
                  onClick={() => handleDeleteHeader(i)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))
      )}
    </Box>
  );
}

export function prohibitedName(name: string): boolean {
  return name.trim().toLowerCase().startsWith("x-mxhero");
}

const initHeaders = () => [
  {
    id: nextId(),
    name: "",
    value: "",
    directions: ["IN"],
  } as HeadersWithId,
];
