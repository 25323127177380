import React from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import {
  Box,
  capitalize,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  OptionGroup,
  StyledPaper,
  VerticalLine,
  menuItemWithDescription,
} from "components/Styled";
import SelectField from "components/Forms/SelectField";
import VariablesSelector from "components/VariablesSelector";
import { PredicateTitle, StyledTitle } from "../Styled";
import { PredicateProps } from "../types";

enum MatchOption {
  AllMatch = "ALL_MATCH",
  OneMatches = "ONE_MATCHES",
  NoMatches = "NO_MATCHES",
}

function Predicate({
  formState,
  setValues,
  predicateIndex,
  definitions,
}: PredicateProps) {
  const { t } = useTranslation("templatePolicies");
  const { predicates } = formState;
  const { variableIdList } = formState.predicates[predicateIndex];

  const handleVariableIds = (variableIds: string[]) => {
    const newState = produce(formState, draft => {
      draft.predicates[predicateIndex].variableIdList = variableIds;
    });

    setValues(newState);
  };

  const handleOnChange = (index: number) => event => {
    const { name, value } = event.target;
    const updated = predicates.map((item, i) => {
      return i === index ? { ...item, [name]: value } : item;
    });
    setValues({ predicates: updated });
  };

  const removePredicate =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (predicates.length > 1) {
        setValues({ predicates: predicates.filter((_, i) => i !== index) });
      }
    };

  const handleMatchChange = event => {
    const value = event.target.value as MatchOption;
    const newState = produce(formState, draft => {
      if (value === MatchOption.AllMatch) {
        draft.predicates[predicateIndex].firstMatch = false;
        draft.predicates[predicateIndex].match = true;
      }

      if (value === MatchOption.NoMatches) {
        draft.predicates[predicateIndex].firstMatch = false;
        draft.predicates[predicateIndex].match = false;
      }

      if (value === MatchOption.OneMatches) {
        draft.predicates[predicateIndex].firstMatch = true;
        draft.predicates[predicateIndex].match = true;
      }
    });

    setValues(newState);
  };

  const getMatchValue = () => {
    const { match, firstMatch } = predicates[predicateIndex];
    if (match && !firstMatch) return MatchOption.AllMatch;
    if (match && firstMatch) return MatchOption.OneMatches;
    if (!match && !firstMatch) return MatchOption.NoMatches;
    return MatchOption.AllMatch;
  };

  return (
    <>
      <VerticalLine size="sm" />
      <StyledPaper>
        <PredicateTitle color="textSecondary">
          {t("form.predicates.title", { number: predicateIndex + 1 })}
          {predicates.length > 1 && (
            <IconButton
              onClick={removePredicate(predicateIndex)}
              size="small"
              aria-label="delete"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </PredicateTitle>
        <OptionGroup>
          <StyledTitle>{t("form.predicates.variableId.title")}</StyledTitle>
          <VariablesSelector
            label={t("form.predicates.variableId.label")}
            value={variableIdList}
            onChange={handleVariableIds}
            required
          />
          <Box display="flex" mt=".5em" mb="1em">
            <RadioGroup
              row
              aria-label="match-or-not"
              name="match-or-not"
              value={getMatchValue()}
              onChange={handleMatchChange}
            >
              <FormControlLabel
                value={MatchOption.AllMatch}
                control={<Radio color="primary" />}
                label={t("form.allMatch")}
              />
              <FormControlLabel
                value={MatchOption.OneMatches}
                control={<Radio color="primary" />}
                label={t("form.oneMatches")}
              />
              <FormControlLabel
                value={MatchOption.NoMatches}
                control={<Radio color="primary" />}
                label={t("form.noMatches")}
              />
            </RadioGroup>
          </Box>
        </OptionGroup>
        <OptionGroup>
          <StyledTitle>{t("form.predicates.definitionId.title")}</StyledTitle>
          <SelectField
            name="definitionId"
            label={t("form.predicates.definitionId.label")}
            options={definitions}
            value={predicates[predicateIndex].definitionId}
            onChange={handleOnChange(predicateIndex)}
            disabled={!formState.cloudStorage}
            menuTitle={t("form.predicates.definitionId.default", {
              storage: capitalize(formState.cloudStorage),
            })}
            required
            renderOption={({ id, name, description }) =>
              menuItemWithDescription(id, name, description)
            }
          />
        </OptionGroup>
      </StyledPaper>
    </>
  );
}

export default Predicate;
