/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ProviderAccounts } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";

export interface Params {
  value: ProviderAccounts;
  onChange: (tags: string[]) => void;
  disabled: boolean;
}

export default function Tags({ value, onChange, disabled }: Params) {
  const { t } = useTranslation("rules");
  const [options, setOptions] = useState<string[]>([]);
  const [error, setError] = useState<string>();
  const selected: string = value.entities[0] || "";
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchAccountTags = async () => {
      try {
        const tags = await apiRequest<string[]>("GET", "/accounts/tags");
        setOptions(tags);
      } catch (e) {
        enqueueSnackbar(t("selectAccounts.errors.fetchTags "), {
          variant: "error",
        });
        console.error(e);
      }
    };

    fetchAccountTags();
  }, []);

  const handleOnBlur = () => {
    setError(() => {
      if (!selected.length) {
        return t("selectAccounts.tags.errors.required");
      }
      return "";
    });
  };

  return (
    <Box mt={1} mb={1}>
      <Autocomplete
        openOnFocus={false}
        disabled={disabled}
        options={options}
        getOptionDisabled={option => selected === option}
        value={selected}
        onBlur={handleOnBlur}
        onChange={(_, selected) => onChange([selected])}
        loading={false}
        loadingText={t("selectAccounts.loadingSearch")}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            error={!!error}
            helperText={error}
          />
        )}
      />
    </Box>
  );
}
