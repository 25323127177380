import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import { OptionGroup, StyledPaper } from "components/Styled";
import SelectField from "components/Forms/SelectField";
import PageLoading from "components/PageLoading";
import apiRequest, { isErrorWithCode } from "utils/apiRequestWithErrorCode";
import useFormState from "utils/useFormState";
import canUseTemplate, { AllowedStorage } from "utils/canUseTemplates";
import Mappings from "./Mappings";
import StorageOptionLabel from "./StorageOptionLabel";
import { StyledMenuItem, StyledTitle } from "../Styled";

import {
  TemplateDefinitionReqBody,
  StorageAccount,
  StorageTemplate,
  TemplateDefinition,
} from "@dashboard-v3/api";
import { FormState } from "../types";

const initValues = (): FormState => ({
  name: "",
  description: "",
  mappings: [{ key: "", value: "" }],
  storageAccount: "",
  metadataKey: "",
});

type NoAccountsWarningType = "NONE" | "BOX" | "EGNYTE";

export default function Form() {
  const { t } = useTranslation(["templateDefinitions", "rules"]);
  const history = useHistory();
  const { state, pathname } = useLocation();
  const { id: paramId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<StorageAccount[]>([]);
  const [templates, setTemplates] = useState<StorageTemplate[]>([]);
  const [noAccountsWarning, setNoAccountsWarning] =
    useState<NoAccountsWarningType>();

  const { formState, updateValues, setValues, reset } = useFormState<FormState>(
    initValues()
  );

  const isEdit: boolean = pathname.includes("/edit");

  useEffect(() => {
    fetchStorageAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefinition = (): Promise<TemplateDefinition> => {
    if (state) {
      const { selectedDefinition, definitionId } = state;
      return definitionId !== paramId
        ? fetchDefinitionById(paramId)
        : selectedDefinition;
    }
    return fetchDefinitionById(paramId);
  };

  const mapFieldValue = (val: string): string | string[] =>
    val.includes(";") ? val.split(";") : val;

  async function fetchStorageAccounts() {
    setLoading(true);
    try {
      const data = await apiRequest<StorageAccount[]>(
        "GET",
        "/storage-accounts"
      );
      const accounts = data.filter(
        account => canUseTemplate(account.type) && account.synced
      );

      setAccounts(accounts);

      if (!accounts.length) {
        setNoAccountsWarning("NONE");
        return;
      }

      if (isEdit) {
        const { storageType, ...definition } = await getDefinition();
        const foundAccount = accounts.find(account =>
          account.id.includes(storageType)
        );

        if (foundAccount) {
          const { user } = foundAccount;
          const storageAccount = `${user}:${storageType}`;
          setValues({
            name: definition.name,
            storageAccount,
            description: definition.description,
            metadataKey: definition.templateId,
            mappings: Object.keys(definition.mappings).map(key => ({
              key,
              value: mapFieldValue(definition.mappings[key]),
            })),
          });
          await fetchStorageTemplates(storageAccount);
        } else {
          setNoAccountsWarning(storageType);
        }
      }
    } catch (error) {
      enqueueSnackbar(t("errors.accounts.getError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function onStorageTemplateFetchError(error: unknown) {
    let refetchAccounts = false;

    switch (true) {
      case isAccountNotSynced(error):
        enqueueSnackbar(t("errors.account.notSynced"), {
          variant: "error",
        });
        refetchAccounts = true;
        break;
      case isAccountNotFound(error):
        enqueueSnackbar(t("errors.account.notFound"), {
          variant: "error",
        });
        refetchAccounts = true;
        break;
      default:
        enqueueSnackbar(t("errors.templates.getError"), {
          variant: "error",
        });
        break;
    }

    reset(["storageAccount", "metadataKey", "mappings"]);

    if (refetchAccounts) {
      await fetchStorageAccounts();
    }
  }

  async function fetchStorageTemplates(storageAccount?: string) {
    if (!storageAccount) return;

    setLoading(true);
    try {
      const [account, storageType] = storageAccount.split(":");
      const query = new URLSearchParams();
      query.append("storage", storageType);
      query.append("account", account);

      const templates = await apiRequest<StorageTemplate[]>(
        "GET",
        `/storage-templates?${query.toString()}`
      );
      setTemplates(templates);
    } catch (error) {
      await onStorageTemplateFetchError(error);
    } finally {
      setLoading(false);
    }
  }

  const fetchDefinitionById = async (id: string) => {
    const path = "/template-definitions";
    setLoading(true);
    try {
      const res = await apiRequest<TemplateDefinition>("GET", `${path}/${id}`);
      setLoading(false);
      return res;
    } catch (e: unknown) {
      setLoading(false);
      enqueueSnackbar(t("errors.definitions.getError"), {
        variant: "error",
      });
      history.push(path);
    }
  };

  const saveDefinition = async (
    body: TemplateDefinitionReqBody,
    id?: string
  ) => {
    setLoading(true);
    try {
      const method = id ? "PUT" : "POST";
      const path = id ? `/template-definitions/${id}` : "/template-definitions";
      const res = await apiRequest<TemplateDefinition>(method, path, body);
      enqueueSnackbar(t("saveSuccess", { name: res.name }), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("errors.definitions.saveError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);

      history.push("/template-definitions");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const templateDefinition = {
      name: formState.name,
      description: formState.description,
      storageType: formState.storageAccount.split(":")[1] as AllowedStorage,
      templateId: formState.metadataKey,
      mappings: formState.mappings.reduce((acc, { key, value }) => {
        const parsedValue = Array.isArray(value) ? value.join(";") : value;
        return { ...acc, [key]: parsedValue };
      }, {}),
    };
    if (state && isEdit) {
      const { definitionId } = state;
      saveDefinition(templateDefinition, definitionId);
    } else {
      saveDefinition(templateDefinition);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageLoading loading={loading} />
        <Grid container spacing={5}>
          <Grid item lg={8} xs={12}>
            <StyledPaper>
              <Typography gutterBottom variant="h6">
                {t("form.title")}
              </Typography>
              <OptionGroup>
                <TextField
                  variant="outlined"
                  name="name"
                  label={t("form.name.label")}
                  required
                  fullWidth
                  value={formState.name}
                  onChange={updateValues}
                />
              </OptionGroup>
              <OptionGroup>
                <TextField
                  variant="outlined"
                  name="description"
                  label={t("form.description.label")}
                  fullWidth
                  value={formState.description}
                  onChange={updateValues}
                />
              </OptionGroup>
              <OptionGroup>
                <StyledTitle>{t("form.storageAccount.title")}</StyledTitle>
                <SelectField<StorageAccount>
                  name="storageAccount"
                  label={t("form.storageAccount.label")}
                  options={accounts}
                  renderValue={val => <>{val.toString().split(":")[0]}</>}
                  value={formState.storageAccount}
                  onChange={e => {
                    reset(["metadataKey", "mappings"]);
                    updateValues(e);
                    fetchStorageTemplates(e.target.value as string);
                  }}
                  renderOption={(account: StorageAccount) => (
                    <StyledMenuItem
                      style={{ display: "block" }}
                      key={account.id}
                      value={account.id}
                      disabled={!account.synced}
                    >
                      <StorageOptionLabel
                        account={account}
                        fetchAccounts={fetchStorageAccounts}
                      />
                      <Typography>{account.user}</Typography>
                    </StyledMenuItem>
                  )}
                />
              </OptionGroup>
              <OptionGroup>
                <StyledTitle>{t("form.metadataKey.title")}</StyledTitle>
                <SelectField
                  name="metadataKey"
                  label={t("form.metadataKey.label")}
                  options={templates}
                  value={formState.metadataKey}
                  onChange={e => {
                    reset(["mappings"]);
                    updateValues(e);
                  }}
                  disabled={templates.length < 1}
                  required
                  renderOption={({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  )}
                />
              </OptionGroup>
              {formState.metadataKey && !!templates.length && (
                <Mappings
                  setValues={setValues}
                  formState={formState}
                  template={templates.find(m => m.id === formState.metadataKey)}
                />
              )}
              <OptionGroup>
                <Button
                  type="submit"
                  size="large"
                  wording={t("form.submitBtn")}
                />
              </OptionGroup>
            </StyledPaper>
          </Grid>
        </Grid>
      </form>
      {noAccountsWarning && (
        <Dialog open>
          <DialogTitle>{t("accountsWarning.title")}</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="textSecondary">
              {noAccountsWarning === "NONE"
                ? t("accountsWarning.content")
                : t("accountsWarning.noAccountByType", {
                    storageType: noAccountsWarning,
                  })}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              wording={t("common:backToList")}
              onClick={() => history.push("/template-definitions")}
            />
            <Button
              size="small"
              wording={t("common:goToStorage")}
              onClick={() => history.push("/integrations")}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

function isAccountNotSynced(error: unknown) {
  return isErrorWithCode(error) && error.code === "NOT_SYNCED";
}

function isAccountNotFound(error: unknown) {
  return isErrorWithCode(error) && error.code === "NOT_FOUND";
}
