import styled from "styled-components";
import { OptionTitle } from "components/Styled";
import { Button, Grid, IconButton, Link, MenuItem } from "@material-ui/core";

export const StyledTitle = styled(OptionTitle)`
  margin-bottom: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

export const StyledGrid = styled(Grid)`
  margin-top: 4px;
  margin-bottom: 30px;
  row-gap: 10px;
  align-items: start;
`;

export const HelperMenuContainer = styled.div`
  padding: 8px 15px 10px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HelperContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    font-size: 20px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  visibility: ${({ hidden }) => (hidden ? "hidden" : "initial")};
`;

export const FixedValuesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  li {
    padding: 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: block;
  opacity: 1 !important;
  cursor: ${({ disabled }) => disabled && "default"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  p {
    opacity: ${({ disabled }) => disabled && "0.5"};
  }
  &:hover {
    background: ${({ disabled }) => disabled && "none"};
  }
`;

export const EnableAccountButton = styled(Button)`
  pointer-events: visible;
  padding: 2px 8px;
  font-size: 0.7rem;
  min-width: 60px;
  margin-left: 10px;
`;

export const StorageAccountsLink = styled(Link)`
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  pointer-events: visible;
`;
