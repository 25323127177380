import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import { AccountWithEnterprise } from "../../Accounts/openAuthPopup";
import { DialogContentWrapper, StyledDialogContent } from "../../Styled";

type Props = {
  isOpen: boolean;
  data: AccountWithEnterprise;
  onCancel: () => void;
  onConfirm: (id: string) => (e: any) => void;
};

export default function ConfirmWarning(props: Props) {
  const { t } = useTranslation("storage");
  const { isOpen, data, onConfirm, onCancel } = props;
  const { user, role, enterprise } = data;

  return (
    <Dialog open={isOpen}>
      <DialogTitle disableTypography>
        <Typography variant="h6" gutterBottom>
          {t("box.confirmWarning.title")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("box.confirmWarning.subtitle")}
        </Typography>
      </DialogTitle>
      <StyledDialogContent>
        <DialogContentWrapper>
          <Typography variant="subtitle2">
            {t("box.confirmWarning.account.title")}
          </Typography>
          <Typography variant="body2">
            {t("box.confirmWarning.account.user")}
            <span>{user}</span>
          </Typography>
          <Typography variant="body2">
            {t("box.confirmWarning.account.role")}
            <span>{role}</span>
          </Typography>
        </DialogContentWrapper>
        <DialogContentWrapper>
          <Typography variant="subtitle2">
            {t("box.confirmWarning.company.title")}
          </Typography>
          <Typography variant="body2">
            {t("box.confirmWarning.company.name")}
            <span>{enterprise.name}</span>
          </Typography>
          <Typography variant="body2">
            {t("box.confirmWarning.company.id")}
            <span>{enterprise.id}</span>
          </Typography>
        </DialogContentWrapper>
      </StyledDialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          wording={t("box.confirmWarning.cancel")}
          onClick={onCancel}
        />
        <Button
          size="small"
          wording={t("box.confirmWarning.confirm")}
          onClick={onConfirm(enterprise.id)}
        />
      </DialogActions>
    </Dialog>
  );
}
