import { useTranslation } from "react-i18next";
import { MenuItem, SelectProps } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { ConditionByType } from "../../common/options";

export default function SelectConditionType({
  onChange,
  value,
  options,
  isReadOnly,
}: {
  onChange: SelectProps["onChange"];
  value: string;
  options: ConditionByType[];
  isReadOnly: boolean;
}) {
  const { t } = useTranslation("variables");

  return (
    <SelectField
      name="type"
      label={t("form.condition.option.default")}
      inputProps={{ "data-testid": "vars_conditions-select" }}
      options={options}
      value={value || ""}
      onChange={onChange}
      required
      renderOption={({ value, translationKey }) => (
        <MenuItem key={value} value={value}>
          {t(`form.condition.option.${translationKey}`)}
        </MenuItem>
      )}
      disabled={isReadOnly}
    />
  );
}
