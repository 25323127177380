import { State } from "../types";

export function saveRule(state: State, payload: null): State {
  if (!state.canSave) return state;
  return { ...state, asyncStatus: "saving" };
}

export type SaveRule = {
  type: "saveRule";
  payload?: null;
};
