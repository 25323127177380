import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { Wrapper } from "components/Forms/StyledComponents";
import { Variable } from "@dashboard-v3/api";

type Params = {
  variable: Variable;
  onChange: (field: "name" | "description", value: string) => void;
};

export default function InfoSection({ variable, onChange }: Params) {
  const { t } = useTranslation("variables");
  const isReadOnly = Boolean(variable?.platformVariable);

  const handleVariableInfo = e => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  return (
    <Wrapper $sticky>
      <TextField
        name="name"
        label={t("form.name.label")}
        variant="outlined"
        fullWidth
        value={variable.name}
        onChange={handleVariableInfo}
        inputProps={{ "data-testid": "vars_var-name-input" }}
        required
        disabled={isReadOnly}
      />
      <TextField
        name="description"
        label={t("form.description.label")}
        placeholder={t("form.description.placeholder")}
        variant="outlined"
        fullWidth
        multiline
        minRows={4}
        value={variable.description || ""}
        onChange={handleVariableInfo}
        inputProps={{ "data-testid": "vars_var-desc-input" }}
        disabled={isReadOnly}
      />
    </Wrapper>
  );
}
