import { useState, createContext, useContext } from "react";
import { useSnackbar } from "notistack";
import { getErrorDescription, isConflict } from "utils/apiRequestWithErrorCode";
import Modal from "./Modal";
import { Dependencies } from "./types";

type ModalContext = {
  errorHandler: (error: unknown, fallbackMsg: string) => void;
};

const Context = createContext<ModalContext | null>(null);

export const DependencyModalProvider = ({ children }) => {
  const [dependencies, setDependencies] = useState<Dependencies>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  /** Checks to see if an error produced by an async request
   * was caused by a dependency conflict and handles the state for the error modal.
   * @param {Error} error - Error response from request.
   * @param {string} fallbackMsg - Error message used in case a
   * dependency conflict is not the caused of the error.
   */
  const errorHandler = (error: unknown, fallbackMsg: string) => {
    if (isConflict(error)) {
      const errorContent: Dependencies = getErrorDescription(error);
      const dependencies = Object.keys(errorContent).reduce((acc, key) => {
        return errorContent[key].length
          ? { ...acc, [key]: errorContent[key] }
          : acc;
      }, {});
      setDependencies(dependencies);
      setShowModal(true);
    } else {
      enqueueSnackbar(fallbackMsg, { variant: "error" });
    }
  };

  return (
    <Context.Provider value={{ errorHandler }}>
      {showModal && (
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          dependencies={dependencies}
        />
      )}
      {children}
    </Context.Provider>
  );
};

/** Exposes the modal provider error handler function to handle api requests
 * that may incur in a dependency conflict error.
 * @example
 * const { errorHandler } = useDependencyModal();
 * 
 * const requestFunction = async () => {
    try {
      const res = await request()
    } catch (error) {
      errorHandler(error, fallbackMessage);
    }
  };
 * */
export const useDependencyModal = () => useContext(Context);
