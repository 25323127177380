import { LoginOptions } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";

export async function fetchSecuritySettings() {
  return apiRequest<LoginOptions>("GET", "/login-options");
}

export async function saveSecuritySettings(
  newSettings: LoginOptions
): Promise<null> {
  return apiRequest("PUT", "/login-options", newSettings);
}
