import styled from "styled-components";
import { MouseEventHandler, PropsWithChildren, useState } from "react";
import {
  IconButton,
  Popover,
  PopoverProps,
  Typography,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface Props {
  position: "before" | "after";
  text: string | React.ReactNode;
  icon?: React.ReactNode | (() => React.ReactNode);
  buttonStyles?: CSSProperties;
  display?: boolean;
}

export default function WithIconHelper(props: PropsWithChildren<Props>) {
  const { display, text, position, icon, buttonStyles, children } = props;
  const [helpAnchor, setHelpAnchor] = useState(null);
  const [helpIsOpen, setHelpIsOpen] = useState<boolean>(false);
  const popoverProps: Partial<PopoverProps> = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  };

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = e => {
    setHelpAnchor(e.currentTarget);
    setHelpIsOpen(prev => !prev);
  };

  if (typeof display !== "undefined" && !display) return <>{children}</>;

  return (
    <Container>
      {position === "after" && children}
      <IconButton
        size="small"
        edge="end"
        style={buttonStyles}
        onClick={onButtonClick}
      >
        <>
          <Popover
            open={helpIsOpen}
            anchorEl={helpAnchor}
            anchorOrigin={popoverProps.anchorOrigin}
            transformOrigin={popoverProps.transformOrigin}
          >
            <Typography
              variant="body2"
              style={{ maxWidth: "450px", padding: "10px" }}
            >
              {text}
            </Typography>
          </Popover>
          {icon ? icon : <HelpOutlineIcon fontSize="small" color="primary" />}
        </>
      </IconButton>
      {position === "before" && children}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  > label {
    margin-right: 5px;
  }
`;
