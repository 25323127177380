import { Box, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

type Params = {
  title: string;
  sideBtn?: React.ReactElement;
  testId?: string;
  children?: React.ReactElement;
};

export default function SectionHeader({
  title,
  sideBtn,
  children,
  testId,
}: Params) {
  return (
    <SectionHeaderContainer>
      <Box gridArea="title">
        <Typography
          variant="h5"
          component="h1"
          data-testid={testId || "page-title"}
          style={{ fontWeight: 500 }}
        >
          {title}
        </Typography>
      </Box>

      <Box gridArea="action" style={{ justifySelf: "end" }}>
        {sideBtn && <Box justifySelf="end">{sideBtn}</Box>}
      </Box>

      <Box gridArea="content">{children}</Box>
    </SectionHeaderContainer>
  );
}

const SectionHeaderContainer = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  padding-bottom: 20px;
  row-gap: 10px;
  grid-template-areas:
    "title action"
    "content content";
`;
