import styled from "styled-components";
import { TextField } from "@material-ui/core";

export const KeyValueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const KeyValueItem = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  align-items: center;
  min-height: 40px;
  padding: 10px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  span {
    font-weight: 600;
    margin-right: 8px;
  }
`;

export const BtnRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

export const DataSetFieldContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  > button {
    margin-bottom: 12px;
  }
`;

export const StyledTextField = styled(TextField)({
  marginBottom: "12px",
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "98%",
    fontSize: "11px",
  },
});

export const FileInput = styled.input`
  display: ${({ hidden }) => (hidden ? "none" : "initial")};
`;

export const TooltipBtnContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    font-size: 20px;
    cursor: pointer;
  }
`;
