/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { RuleUrlParams } from ".";
import { useRuleContext } from "./context/ruleContext";
import { Rule } from "../types";
import { getInitArchivingAddress, getInitEmailFlow } from "./context/helpers";

const TargetWarningModal = ({ rule }: { rule: Rule }) => {
  const { t } = useTranslation("rules");
  const { dispatch } = useRuleContext();
  const [open, setOpen] = useState<boolean>(false);
  const { action }: RuleUrlParams = useParams();
  const history = useHistory();

  useEffect(() => {
    if (action === "copy") {
      dispatch({
        type: "updateRuleLabels",
        payload: {
          type: "name",
          input: t("copyName", {
            name: rule.name,
            interpolation: { escapeValue: false },
          }),
        },
      });

      setOpen(
        rule.targetType === "ARCHIVING_ADDRESS" ||
          rule.targetType === "EMAIL_FLOW"
      );
    }
  }, [rule]);

  const handleEdit = () => {
    const changes =
      rule.targetType === "ARCHIVING_ADDRESS"
        ? getInitArchivingAddress()
        : getInitEmailFlow();

    dispatch({
      type: "updateStep",
      payload: { step: "target", changes },
    });
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="h6" paragraph>
          {t("targetWarning.title")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("targetWarning.body")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle2">
          {t(`targetWarning.section.${rule.targetType}`)}
        </Typography>
      </DialogContent>
      <DialogContent>
        <Typography variant="caption" color="textSecondary">
          {t("targetWarning.info")}
        </Typography>
      </DialogContent>

      <DialogActions style={{ marginTop: "10px" }}>
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          onClick={() => history.push("/rules/list")}
        >
          {t("common:cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleEdit}
        >
          {t("common:continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TargetWarningModal;
