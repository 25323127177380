import Script from "@gumgum/react-script-tag";
import { useEffect } from "react";
import { PickerParams } from "../types";

declare var OneDrive: any;
declare var location: any;

let sdkAlreadyLoaded = false;

function openOneDrive(onChange) {
  OneDrive.open({
    clientId: process.env.REACT_APP_ONEDRIVE_PICKER_CLIENT_ID,
    action: "query",
    viewType: "folder",
    multiSelect: false,
    accountSwitchEnabled: true,
    advanced: {
      queryParameters: "select=id,name,parentReference",
      // List of params here:
      // https://learn.microsoft.com/en-us/onedrive/developer/controls/file-pickers/js-v72/open-file?view=odsp-graph-online
      //
      // This URI has to be included in the picker's
      // Redirect URIs list: https://portal.azure.com/
      redirectUri: `${location.origin}/oneDrivePicker.html`,
    },
    success(files) {
      const { name, id } = files.value[0];
      onChange({ parentName: name, parentId: id });
    },
    cancel() {
      onChange(null);
    },
    error(error: unknown) {
      console.error(error);

      onChange(null);
    },
  });
}

const OneDrivePicker = ({ open, onChange }: PickerParams) => {
  useEffect(() => {
    if (open) {
      openOneDrive(onChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdkAlreadyLoaded]);

  if (open && sdkAlreadyLoaded) {
    openOneDrive(onChange);
  }

  return (
    <Script
      src="https://js.live.net/v7.2/OneDrive.js"
      type="text/javascript"
      onLoad={() => {
        sdkAlreadyLoaded = true;
      }}
    />
  );
};

export default OneDrivePicker;
