import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";
import nextId from "react-id-generator";

// const results = require("./example.json");

/**
 *
 * @returns {import('./types').useBillingResponse}
 */
export default function useBilling() {
  const [contacts, setContacts] = useState([]);
  const { t } = useTranslation("billing");
  const [subscriptions, setSubscriptions] = useState([]);
  const [xeroContact, setXeroContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [withError, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchAndLoading() {
      try {
        const results = await apiRequest("GET", "/billing");
        setContacts(results.contacts);
        setXeroContact(results.account.xeroContact);
        const parsed = results.subscriptions
          .map(subscription => {
            const { contract, orders } = subscription;

            const signer = results.contacts.find(
              el => el.id === contract.customerSignedId
            );

            return {
              id: contract.id,
              status: contract.status,
              startDate: contract.startDate,
              endDate: contract.endDate,
              months: contract.months,
              signer,
              orders: mapOrders(orders),
            };
          })
          .sort(
            (a, b) =>
              new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          );

        setSubscriptions(parsed);
      } catch (error) {
        console.error(error);
        setError(true);
        enqueueSnackbar(t("fetchError"), { variant: "error" });
      } finally {
        setLoading(false);
      }
    }

    fetchAndLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { withError, loading, contacts, subscriptions, xeroContact };
}

function mapOrders(orders) {
  return orders.reduce((items, order) => {
    return items.concat(
      order.orderItems.reduce((recurring, orderItem) => {
        if (orderItem.recurring) {
          recurring.push({
            id: nextId(),
            feature: orderItem.feature,
            name: orderItem.name,
            quantity: orderItem.quantity,
            startDate: order.startDate,
          });
        }
        return recurring;
      }, [])
    );
  }, []);
}

/*
function labelOfProducts(orders, t) {
  return orders.reduce((products, order) => {
    const recurring = order.orderItems.find(item => item.recurring);
    if (recurring) {
      products.push(
        t("licencesOf", { count: recurring.quantity, type: recurring.name })
      );
    }
    return products;
  }, []);
}
*/
