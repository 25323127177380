import {
  Typography,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AdvancedOptions from "./AdvancedOptions";
import { OptionGroup } from "components/Styled";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { ArchivingAddress } from "@dashboard-v3/api";
import ConfirmButton from "../ConfirmButton";
import { State } from "pages/Rules/CreateOrEdit/context/types";
import { archivingAddressDomain } from "./utils";
import PageLoading from "components/PageLoading";

export default function ArchivingAddressOptions() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const archivingAddress = state.rule.copyEmails.archivingAddress;

  const updateTarget = (updates: Partial<ArchivingAddress>) => {
    dispatch({
      type: "updateStep",
      payload: { step: "target", changes: updates },
    });
  };

  const handleEmailAddress = e => {
    const { value } = e.target;
    updateTarget({ emailAddress: `${value}@${archivingAddressDomain}` });
  };

  const isConfirmDisabled = (state: State) => {
    if (state.steps?.target?.done) return true;
    return !state.steps.target.valid;
  };

  return (
    <>
      <div style={{ padding: "0 24px 24px" }}>
        {state.asyncStatus === "validate_archiving_address" && (
          <PageLoading loading />
        )}
        <OptionGroup>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontWeight: "500" }}
          >
            {t("targetAllEmails")}:
          </Typography>
          <FormControl fullWidth size="medium" variant="outlined">
            <OutlinedInput
              error={isInvalidAddress(state) || !archivingAddress.emailAddress}
              value={emailPrefix(archivingAddress.emailAddress)}
              onChange={handleEmailAddress}
              endAdornment={
                <InputAdornment position="end" style={{ marginLeft: "5px" }}>
                  {`@${archivingAddressDomain}`}
                </InputAdornment>
              }
            />
            {!archivingAddress.emailAddress && (
              <FormHelperText error>{t("fieldCantBeEmpty")}</FormHelperText>
            )}
            {isInvalidAddress(state) && (
              <FormHelperText error variant="outlined" margin="dense">
                {t(mapErrorCheck(state))}
              </FormHelperText>
            )}
          </FormControl>
        </OptionGroup>
        <ConfirmButton
          onClick={() => dispatch({ type: "confirmStep", payload: "target" })}
          disabled={isConfirmDisabled(state)}
        />
      </div>
      <AdvancedOptions
        updateTarget={updateTarget}
        archivingAddress={archivingAddress}
      />
    </>
  );
}

function emailPrefix(email: string) {
  if (!email) return "";
  return email.split("@")[0];
}

function isInvalidAddress(state: State) {
  return Boolean(state.checks.archivingAddress?.error);
}

function mapErrorCheck(state: State) {
  const errorType = state.checks.archivingAddress?.error;
  return `target.ARCHIVING_ADDRESS.checkError.${errorType}`;
}
