import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";

import ComponentList from "components/ComponentList";
import VariableItem from "./VariableItem";
import { Variable } from "@dashboard-v3/api";

type ListProps = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<"ALL" | "ITEM">>;
  variables: Variable[];
  isDefault?: boolean;
};

const VariablesList = ({
  loading,
  setLoading,
  variables,
  isDefault,
}: ListProps) => {
  const [loadingItem, setLoadingItem] = useState<string>(null);
  const { t } = useTranslation("variables");

  return (
    <ComponentList
      list={variables}
      emptyMsg={t("emptyList")}
      loading={loading}
      renderItems={variable =>
        loadingItem === variable.id ? (
          <Skeleton
            key={variable.id}
            height="4rem"
            animation="wave"
            variant="rect"
          />
        ) : (
          <VariableItem
            key={variable.id}
            variable={variable}
            setLoading={setLoading}
            setLoadingItem={setLoadingItem}
            isDefault={isDefault}
          />
        )
      }
    />
  );
};

export default VariablesList;
