import {
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AdvancedOptionsContainer, OptionGroup } from "components/Styled";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import {
  getEmailFlow,
  typePath,
} from "pages/Rules/CreateOrEdit/context/helpers";
import { EmailFlow } from "@dashboard-v3/api";
import SwitchField from "components/Forms/SwitchField";

export default function AdvancedOptions() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();

  let emailFlow: EmailFlow = getEmailFlow(state.rule);
  const isCopyEmails = state.rule.actionType === "COPY_EMAILS";

  return (
    <AdvancedOptionsContainer>
      <AccordionSummary
        data-testid="emailflow__advanced-options-expand"
        expandIcon={<ExpandMoreIcon />}
        style={{ padding: "0 24px" }}
      >
        <Typography>{t("advancedOptions")}</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ padding: "8px 24px 24px", flexDirection: "column" }}
      >
        <OptionGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={event => {
                  const checked = event.target.checked;

                  dispatch({
                    type: "updateStep",
                    payload: {
                      step: "target",
                      changes: { evaluateHeaders: checked },
                    },
                  });
                }}
                checked={emailFlow.evaluateHeaders}
                disabled={emailFlow.evaluateFrom}
              />
            }
            label={
              <Typography style={{ fontWeight: "500" }} variant="subtitle1">
                {t("evaluateConditionDescription")}
              </Typography>
            }
          />
        </OptionGroup>
        <OptionGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={event => {
                  const checked = event.target.checked;

                  dispatch({
                    type: "updateStep",
                    payload: {
                      step: "target",
                      changes: { evaluateFrom: checked },
                    },
                  });
                }}
                checked={emailFlow.evaluateFrom}
                disabled={emailFlow.evaluateHeaders}
              />
            }
            label={
              <Typography style={{ fontWeight: "500" }} variant="subtitle1">
                {t("evaluateFromHeaderDescription")}
              </Typography>
            }
          />
        </OptionGroup>
        {isCopyEmails && (
          <OptionGroup>
            <SwitchField
              name="saveForwardedMessages"
              label={t("saveForwardedMessages")}
              description={t("saveForwardedMessagesAsAttachmentsSeparately")}
              value={typePath("COPY_EMAILS", state.rule).saveForwardedMessages}
              onChange={event => {
                dispatch({
                  type: "updateStep",
                  payload: {
                    step: "target",
                    changes: { saveForwardedMessages: event.target.checked },
                  },
                });
              }}
            />
          </OptionGroup>
        )}
      </AccordionDetails>
    </AdvancedOptionsContainer>
  );
}
