import {
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "components/Forms/StyledComponents";
import styled from "styled-components";
import { HeaderProps } from "./types";

const HeaderContainer = styled.header`
  border-bottom: 1px solid #d6dbe0;
  padding: 14px 24px;
`;

export const ContentWrapper = styled.div`
  padding: 24px;
`;

export const ConfirmButton = styled(Button)`
  margin-top: 24px;
`;

export const StyledDetails = styled(AccordionDetails)`
  padding: 8px 24px 24px;
  flex-direction: column;
`;

export const StepHeader = ({ title, subtitle, selected }: HeaderProps) => {
  const selectedStyle = {
    fontWeight: !selected ? "400" : "",
    wordBreak: "break-all",
  } as React.CSSProperties;

  const color = selected ? "primary" : "inherit";
  return (
    <HeaderContainer>
      <Typography variant="h6">{title}</Typography>
      <Typography color={color} variant="h6" style={selectedStyle}>
        {subtitle}
      </Typography>
    </HeaderContainer>
  );
};

export const StyledSummary = ({ children }) => {
  return (
    <AccordionSummary
      data-testid="action__advanced-options-expand"
      expandIcon={<ExpandMoreIcon />}
      style={{ padding: "0 24px" }}
    >
      {children}
    </AccordionSummary>
  );
};
