import {
  Box,
  Checkbox,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useRuleContext } from "../../../context/ruleContext";
import { getMailbox } from "../../../context/helpers";
import { IncludeFolder } from "@dashboard-v3/api";
import { Rule } from "pages/Rules/types";
import { AddItemButton } from "./AddItemButton";
import SelectType from "./SelectType";
import { DeleteRowButton } from "./DeleteRowButton";
import { convertToMapWithKey } from "./convertToMapWithKey";

export default function IncludeFoldersFilter({ disabled }) {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();

  const [items, setItems] = useState<Map<string, IncludeFolder>>(
    mapOfIncludeFolders(state.rule)
  );

  function getError(index: number) {
    const inclusions = state.filters.targetFilter?.errors?.inclusions || [];
    return inclusions[index] ?? null;
  }

  function updateState(items: Map<string, IncludeFolder>) {
    setItems(items);
    const filters = Array.from(items.values());
    dispatch({ type: "updateIncludeFolders", payload: filters });
  }

  function deleteItem(key) {
    items.delete(key);
    updateState(items);
  }

  function changeItem(key, changes: Partial<IncludeFolder>) {
    const updated = { ...items.get(key), ...changes };
    items.set(key, updated);
    updateState(items);
  }

  function addItem() {
    const inclusion: IncludeFolder = {
      folder: "",
      type: "BOTH",
      createFoldersIfNotExists: false,
    };

    items.set(crypto.randomUUID(), inclusion);
    updateState(items);
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
            {t("mailboxFilters.includeFolders.label")}
          </Typography>
          <FormHelperText>
            {t("mailboxFilters.includeFolders.desc")}
          </FormHelperText>
        </Box>
        <AddItemButton onClick={addItem} disabled={disabled}>
          {t("mailboxFilters.includeFolders.addBtnLabel")}
        </AddItemButton>
      </Box>
      {items.size > 0 && (
        <>
          <Header />
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            style={{ paddingBottom: "30px" }}
          >
            {Array.from(items, ([key, item], index) => (
              <Row
                key={key}
                item={item}
                onDelete={() => deleteItem(key)}
                onChange={changes => changeItem(key, changes)}
                error={getError(index)}
                disabled={disabled}
              />
            ))}
          </Grid>
          <Divider />
        </>
      )}
    </>
  );
}

function Header() {
  const { t } = useTranslation("rules");

  return (
    <Grid container spacing={3} style={{ marginTop: "5px" }}>
      <Grid item xs={5}>
        <Typography variant="caption">
          {t("mailboxFilters.folders.folderLabel")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">
          {t("mailboxFilters.includeFolders.includeTypeLabel")}
        </Typography>
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        <Tooltip title={t("mailboxFilters.create.desc")}>
          <HeaderWithTooltip>
            <Typography variant="caption">
              {t("mailboxFilters.includeFolders.createFolder")}
            </Typography>
            <InfoOutlinedIcon color="primary" fontSize="small" />
          </HeaderWithTooltip>
        </Tooltip>
      </Grid>

      <Grid item xs={1}></Grid>
    </Grid>
  );
}

const HeaderWithTooltip = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    flex-grow: 2;
  }
  svg {
    font-size: 16px;
    margin-bottom: 2px;
  }
`;

type Params = {
  item: IncludeFolder;
  onDelete: () => void;
  onChange: (update: Partial<IncludeFolder>) => void;
  disabled: boolean;
  error?: string;
};

function Row({ item, onDelete, onChange, disabled, error }: Params) {
  const { t } = useTranslation(["rules", "common"]);

  return (
    <>
      <Grid item xs={5}>
        <TextField
          type="text"
          variant="outlined"
          fullWidth
          defaultValue={item.folder}
          onBlur={event => {
            onChange({ folder: event.target.value.trim() });
          }}
          error={Boolean(error)}
          helperText={Boolean(error) ? t(error) : ""}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectType
          type={item.type}
          onChange={type => onChange({ type })}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={2} style={{ textAlign: "center" }}>
        <Checkbox
          color="primary"
          checked={item.createFoldersIfNotExists}
          onChange={event => {
            onChange({ createFoldersIfNotExists: event.target.checked });
          }}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={1} style={{ textAlign: "center" }}>
        <DeleteRowButton onClick={onDelete} disabled={disabled} />
      </Grid>
    </>
  );
}

function mapOfIncludeFolders(rule: Rule): Map<string, IncludeFolder> {
  const mailbox = getMailbox(rule);
  const includeFolders = mailbox.filter?.includeFolders || [];

  return convertToMapWithKey(includeFolders);
}
