/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, FormControl, ListSubheader, Typography } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { FieldSkeleton } from "components/SkeletonLoading";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { getMailbox } from "pages/Rules/CreateOrEdit/context/helpers";
import useOrganization from "utils/useOrganization";
import useMailboxAuthPopup from "utils/useMailboxAuthPopup";
import { SingleAccount } from "./singleAccount";
import { getAuthSingleAccount, getMailboxAccounts } from "../utils";

import { Mailbox, MailboxSingleAccount } from "@dashboard-v3/api";
import { AddAccountLink, OptionsDivider, StyledMenuItem } from "./styled";
import AddMailboxAccountModal from "./AddMailboxAccountModal";

type ServerTypeOption = Exclude<
  `${Mailbox["provider"]}_${Mailbox["authentication"]}`,
  "IMAP_INDIVIDUAL"
>;

type ProviderOption = {
  type: ServerTypeOption | "DIVIDER";
  value: string;
};

export type ServerType = {
  provider: Mailbox["provider"];
  authentication: Mailbox["authentication"];
  accounts?: SingleAccount;
};

interface Params {
  value?: ServerType;
  onChange: (value: ServerType | null) => void;
  disabled: boolean;
}

export default function ChooseProvider({ value, onChange, disabled }: Params) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useRuleContext();
  const mailbox = getMailbox(state.rule);
  const { open, isClosedByUser } = useMailboxAuthPopup();
  const [enabled, setEnabled] = useState({ gapps: false, office365: false });
  const { organization } = useOrganization();
  const [options, setOptions] = useState<ProviderOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const getOptions = async () => {
    setLoading(true);
    try {
      const mailboxAccounts = await getMailboxAccounts();
      setOptions(toProviderOptions(mailboxAccounts));
    } catch (error) {
      setOptions(toProviderOptions([]));
      enqueueSnackbar(t("mailboxAccounts.fetchError"), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (organization) {
      setEnabled({
        gapps: Boolean(organization.integrations?.gapps),
        office365: Boolean(organization.integrations?.office365?.inbox),
      });
    }
  }, [organization]);

  const handleGoogleForOrganization = () => {
    if (!enabled.gapps) {
      window.open(process.env.REACT_APP_GOOGLE_MARKETPLACE_APP);
      return false;
    }
    onChange({
      provider: "GOOGLE",
      authentication: "ORGANIZATION",
      accounts: null,
    });
  };

  const handleOfficeForOrganization = async () => {
    if (enabled.office365) {
      return onChange({
        provider: "OFFICE365",
        authentication: "ORGANIZATION",
      });
    }

    try {
      const res = await open("office365/organization");
      if (!res.auth) return;
      onChange({
        provider: "OFFICE365",
        authentication: "ORGANIZATION",
      });
    } catch (error) {
      if (isClosedByUser(error)) return;
      if (error instanceof Error) {
        const { message: code } = error;

        console.error("Fail with error code: ", code);
        enqueueSnackbar(t("chooseProvider.failWithCode", { code }), {
          variant: "error",
        });
      } else {
        console.error("Fail with error: ", error);
        enqueueSnackbar(t("chooseProvider.fail"), {
          variant: "error",
        });
      }
    }
  };

  const onProviderChange = e => {
    if (!enabled) return false;
    const { value } = e.target;
    const option = options.find(o => o.value === value);
    if (!option) return onChange(null);

    switch (option.type) {
      case "IMAP_ORGANIZATION":
        return onChange({ provider: "IMAP", authentication: "ORGANIZATION" });
      case "GOOGLE_ORGANIZATION":
        return handleGoogleForOrganization();
      case "OFFICE365_ORGANIZATION":
        return handleOfficeForOrganization();
      case "GOOGLE_INDIVIDUAL":
        return onChange({
          provider: "GOOGLE",
          authentication: "INDIVIDUAL",
          accounts: {
            type: "ACCOUNT",
            entities: [option.value],
          },
        });
      case "OFFICE365_INDIVIDUAL":
        return onChange({
          provider: "OFFICE365",
          authentication: "INDIVIDUAL",
          accounts: {
            type: "ACCOUNT",
            entities: [option.value],
          },
        });
    }
  };

  const renderValue = value => {
    const option = options.find(o => o.value === value);
    if (option && option.type.includes("INDIVIDUAL")) {
      return option.value;
    }
    return t(`mailBoxServerType.${value}`);
  };

  const onAddAccount = async (value: ServerType) => {
    if (!value) return;
    await getOptions();
    onChange(value);
  };

  function getSelectedValue(serverType?: ServerType) {
    if (!serverType?.provider) return "";
    if (serverType.provider === "IMAP") return "IMAP_ORGANIZATION";
    if (serverType.authentication === "INDIVIDUAL") {
      return getAuthSingleAccount(mailbox);
    }
    return `${serverType.provider}_${serverType.authentication}`;
  }

  if (loading) return <FieldSkeleton />;

  return (
    <FormControl fullWidth variant="outlined">
      <Box mt={1}>
        <SelectField
          name="server"
          data-testid="mailbox__server-type-select"
          label={t("chooseAServer")}
          options={options}
          disabled={disabled}
          value={getSelectedValue(value)}
          renderValue={renderValue}
          onChange={onProviderChange}
          defaultValue=""
          renderOption={({ type, value }) => {
            if (type.includes("ORGANIZATION")) {
              return (
                <StyledMenuItem key={value} value={value}>
                  <Typography variant="caption">
                    {t("mailBoxServerType.label.organization")}
                  </Typography>
                  <Typography>{t(`mailBoxServerType.${type}`)}</Typography>
                </StyledMenuItem>
              );
            }
            if (type === "DIVIDER") {
              return (
                <ListSubheader key={type}>
                  <OptionsDivider />
                </ListSubheader>
              );
            }
            if (type.includes("INDIVIDUAL")) {
              return (
                <StyledMenuItem key={value} value={value}>
                  <Typography variant="caption">
                    {t(`mailBoxServerType.label.individual.${type}`)}
                  </Typography>
                  <Typography>{value}</Typography>
                </StyledMenuItem>
              );
            }
          }}
        />
      </Box>
      <AddAccountLink
        onClick={() => setOpenAddModal(true)}
        hidden={disabled}
        aria-hidden={disabled}
      >
        {t("mailBoxServerType.addAccountLink")}
      </AddAccountLink>
      {openAddModal && (
        <AddMailboxAccountModal
          isOpen={openAddModal}
          onClose={() => setOpenAddModal(false)}
          onChange={onAddAccount}
        />
      )}
    </FormControl>
  );
}

const toProviderOptions = (mailboxAccounts: MailboxSingleAccount[]) => {
  const initialOptions = [
    "GOOGLE_ORGANIZATION",
    "OFFICE365_ORGANIZATION",
    "IMAP_ORGANIZATION",
  ];

  const fixedOptions = initialOptions.map(s => ({
    type: s as ServerTypeOption,
    value: s,
  }));

  if (mailboxAccounts.length) {
    const divider = { type: "DIVIDER" as ServerTypeOption, value: "DIVIDER" };
    const accounts = mailboxAccounts.map(a => ({
      type: `${a.type}_INDIVIDUAL` as ServerTypeOption,
      value: a.email,
    }));

    return [...fixedOptions, divider, ...accounts];
  }

  return fixedOptions;
};
