import { withStyles } from "@material-ui/styles";
import { AccordionProps, Accordion as MuiAccordion } from "@material-ui/core";

export const Accordion = ({ children, ...props }: AccordionProps) => (
  <div style={{ marginBottom: "20px" }}>
    <StyledAccordion {...props}>{children}</StyledAccordion>
  </div>
);

const StyledAccordion = withStyles({
  root: {
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);
