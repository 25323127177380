import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  ListItem,
  Typography,
  Button,
  Box,
  ListItemText,
  Chip,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ConfirmDelete from "components/ConfirmDelete";
import {
  ListItemAccordion,
  StyledSecondaryAction,
  StyledContentList,
  StyledSummary,
  StyledDetails,
} from "components/List/StyledComponents";
import { useDependencyModal } from "components/DependencyModal";
import { getFormattedTimeStamp } from "utils/dateUtils";
import apiRequest from "utils/apiRequestWithErrorCode";
import { SecurityDefinition } from "@dashboard-v3/api";
import storageProviders, { getProviderLabel } from "utils/storageProviders";

const ContentItem = ({ wording }: { wording: string }) => {
  return (
    <li>
      <p>{wording}</p>
    </li>
  );
};

interface ItemProps {
  content: SecurityDefinition;
  refreshList: () => Promise<void>;
  setLoading: Dispatch<SetStateAction<"ALL" | "MORE" | "ITEM">>;
  setLoadingItem: Dispatch<SetStateAction<string>>;
  onClickEdit: (id: string) => void;
}

export default function SecurityDefinitionItem({
  content,
  refreshList,
  setLoading,
  setLoadingItem,
  onClickEdit,
}: ItemProps) {
  const { t } = useTranslation("securityDefinitions");
  const { errorHandler } = useDependencyModal();
  const {
    id,
    name,
    updatedAt,
    expiresInDays,
    access,
    individualPassword,
    systemDefault,
  } = content;

  const getExpirationText = () => {
    return expiresInDays
      ? t("expiresInDays.expiration", { days: expiresInDays })
      : t("expiresInDays.persistent");
  };

  const deleteDefinition = async (id: string) => {
    try {
      setLoading("ITEM");
      setLoadingItem(id);
      await apiRequest<void>("DELETE", `/security-definitions/${id}`);
      await refreshList();
    } catch (e) {
      errorHandler(e, t(`errors.definitionsRequests.deleteError`));
    } finally {
      setLoading(null);
    }
  };

  const renderProviderLabel = () => {
    const { systemDefault, allowedStorages } = content;
    const [provider] = allowedStorages;

    if (systemDefault) {
      const isRestricted = !storageProviders.every(s =>
        allowedStorages.includes(s)
      );

      return isRestricted
        ? allowedStorages
            .map(s => getProviderLabel(s))
            .sort()
            .reduce((acc, provider, i, arr) => {
              return arr.length - 1 === i
                ? acc.concat(" ").concat(t("common:and", { item: provider }))
                : acc.concat(`, ${provider}`);
            })
        : t("allCloudServices");
    }

    return getProviderLabel(provider);
  };

  return (
    <ListItem disableGutters data-testid="security-defs-item">
      <ListItemAccordion>
        <StyledSummary
          data-testid="accounts__account-summary"
          expandIcon={<ExpandMore />}
        >
          <ListItemText
            primary={
              <>
                <Box marginY="5px">
                  <Typography
                    display="inline"
                    style={{ fontWeight: "500", marginRight: "10px" }}
                  >
                    {t(`presets.${id}.name`, name)}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="body2"
                  >
                    {renderProviderLabel()}
                  </Typography>
                </Box>
                <Box marginBottom="5px">
                  {systemDefault ? (
                    <Chip
                      size="small"
                      label="Preset"
                      style={{ marginRight: "10px" }}
                    />
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {t("updatedAt", {
                        date: getFormattedTimeStamp(updatedAt, true),
                      })}
                    </Typography>
                  )}
                </Box>
              </>
            }
          />
          {!systemDefault && (
            <StyledSecondaryAction>
              <Button
                data-testid="security-defs_edit-btn"
                disableElevation
                variant="outlined"
                size="small"
                onClick={event => {
                  event.stopPropagation();
                  onClickEdit(content.id);
                }}
              >
                {t("common:edit")}
              </Button>
              <ConfirmDelete
                onAccept={() => deleteDefinition(id)}
                message={t("confirmDeletion", { name })}
                variant="outlined"
                size="small"
              />
            </StyledSecondaryAction>
          )}
        </StyledSummary>
        <StyledDetails>
          <StyledContentList>
            <ContentItem wording={t(`access.${access}`)} />
            <ContentItem wording={getExpirationText()} />
            {content.individualPassword && (
              <ContentItem
                wording={t(`individualPassword.${individualPassword}`)}
              />
            )}
            {!content.canDownload && <ContentItem wording={t("canDownload")} />}
            {content.removeDownloadLink && (
              <ContentItem wording={t("removeLink")} />
            )}
          </StyledContentList>
        </StyledDetails>
      </ListItemAccordion>
    </ListItem>
  );
}
