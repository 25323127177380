import { State } from "../types";
import produce from "immer";
import { setStepAsValid } from "./confirmStep";
import { getArchivingAddress, getEmailFlow, getMailbox } from "../helpers";
import { CheckAccessResponse } from "@dashboard-v3/api";

export function asyncValidationEnds(
  state: State,
  payload: AsyncValidationEnds["payload"]
): State {
  if (payload.validation === "ARCHIVING_ADDRESS") {
    const { emailAddress } = getArchivingAddress(state.rule);

    return produce(state, draft => {
      draft.asyncStatus = "idle" as State["asyncStatus"];
      draft.checks.archivingAddress.testedEmail = emailAddress;

      if (payload.valid) {
        draft.checks.archivingAddress.isValidEmail = true;
        draft.checks.archivingAddress.error = null;
        setStepAsValid("target", draft);
      } else {
        draft.canSave = false;
        draft.steps.target.done = false;
        draft.steps.target.valid = true;
        draft.checks.archivingAddress.isValidEmail = false;
        draft.checks.archivingAddress.error = payload.error;
        if (payload.error === "FETCH_ERROR") {
          // This allow another check with the same email
          draft.checks.archivingAddress.testedEmail = null;
        }
      }
    });
  }

  if (payload.validation === "EMAIL_FLOW") {
    const emailFlow = getEmailFlow(state.rule);
    return produce(state, draft => {
      draft.asyncStatus = "idle" as State["asyncStatus"];
      draft.checks.emailFlow.tested = {
        bidirectional: emailFlow.bidirectional,
        fromTo: emailFlow.fromTo,
      };

      if (payload.valid) {
        draft.checks.emailFlow.isValid = true;
        draft.checks.emailFlow.error = null;
        setStepAsValid("target", draft);
      } else {
        draft.canSave = false;
        draft.steps.target.done = false;
        draft.steps.target.valid = true;
        draft.checks.emailFlow.isValid = false;
        draft.checks.emailFlow.error = payload.error;
        if (payload.error === "FETCH_ERROR") {
          // This allow another check with the same flow
          draft.checks.emailFlow.tested = null;
        }
      }
    });
  }

  if (payload.validation === "MAIL_BOX") {
    const mailbox = getMailbox(state.rule);
    return produce(state, draft => {
      draft.asyncStatus = "idle" as State["asyncStatus"];
      draft.checks.mailboxImap.tested = mailbox.imapServer;
      if (payload.valid) {
        draft.checks.mailboxImap.isValid = true;
        draft.checks.mailboxImap.error = null;
        setStepAsValid("target", draft);
      } else {
        draft.canSave = false;
        draft.steps.target.done = false;
        draft.steps.target.valid = true;
        draft.checks.mailboxImap.isValid = false;
        draft.checks.mailboxImap.error = payload.error as
          | "INVALID"
          | "FETCH_ERROR";

        if (payload.error === "FETCH_ERROR") {
          // This allow another check with the same flow
          draft.checks.mailboxImap.tested = null;
        }
      }
    });
  }

  if (payload.validation === "STORAGE_ACCOUNTS_ACCESS") {
    return produce(state, draft => {
      draft.asyncStatus = "idle" as State["asyncStatus"];

      if (payload.valid) {
        draft.checks.storageAccess.isValid = payload.valid;
        draft.steps.cloudStorage.done = true;
        draft.steps.cloudStorage.valid = true;

        setStepAsValid("cloudStorage", draft);
      } else {
        draft.canSave = false;
        draft.checks.storageAccess.errors = payload.storageAccessErrors;
        draft.steps.cloudStorage.done = false;
        draft.steps.cloudStorage.valid = false;
      }
    });
  }

  if (payload.validation === "FILENAME_FORMAT") {
    return produce(state, draft => {
      draft.asyncStatus = "idle" as State["asyncStatus"];
      draft.checks.storageFilename.isValid = payload.valid;

      if (payload.valid) {
        draft.steps.storageFilepath.done = true;
        draft.steps.storageFilepath.valid = true;
        draft.checks.storageFilename.fieldsWithError = [];
        delete draft.checks.storageFilename.fieldsToCheck;
        setStepAsValid("storageFilepath", draft);
      } else {
        draft.canSave = false;
        draft.checks.storageFilename.fieldsWithError = payload.fieldsWithError;
        draft.steps.storageFilepath.done = false;
        draft.steps.storageFilepath.valid = false;
      }
    });
  }
}

export type AsyncValidationEnds = {
  type: "asyncValidationEnds";
  payload?: {
    valid: boolean;
    validation:
      | "ARCHIVING_ADDRESS"
      | "EMAIL_FLOW"
      | "MAIL_BOX"
      | "STORAGE_ACCOUNTS_ACCESS"
      | "FILENAME_FORMAT";
    error?: "IN_USE" | "INVALID" | "FETCH_ERROR";
    storageAccessErrors: CheckAccessResponse["errors"];
    fieldsWithError: string[];
  };
};
