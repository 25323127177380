import apiRequest from "utils/apiRequestWithErrorCode";
import { getDomainsList } from "pages/OrgAndDomains/api";
import { Option } from "./types";

export default async function fetchDomainsAndTags(): Promise<Option[]> {
  const [domains, tags] = await Promise.all([fetchDomains(), fetchTags()]);
  return [...domains, ...tags];
}

async function fetchTags(): Promise<Option[]> {
  const tags = await apiRequest<string[]>("GET", "/accounts/tags");
  return tags.map(tag => ({
    label: tag,
    type: "TAG",
    managed: true,
  }));
}

async function fetchDomains(): Promise<Option[]> {
  const domains = await getDomainsList();
  return domains.map(item => ({
    label: item.domain,
    type: "DOMAIN",
    managed: true,
  }));
}
