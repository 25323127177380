/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import {
  ChangeEventHandler,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Link } from "@material-ui/core";
import { BulkSaveResult, useAccountState } from "pages/Accounts/accountContext";
import ProgressIndicator from "./ProgressIndicator";
import {
  DraggedFileHelper,
  DropAndDropAreaContainer,
  ImportFileContent,
  ImportIcon,
  SaveProgressText,
} from "./styled";
import {
  bytesToUnit,
  getAccountsToSave,
  getProgressPercentage,
  importDroppedCSV,
  importSelectedCSV,
  ImportCSVResult,
} from "../utils";

export enum ImportStatus {
  Valid = "VALID",
  Invalid = "INVALID",
  Idle = "IDLE",
  Saving = "SAVING",
}

interface Props {
  files: ImportCSVResult[];
  onImport: (files: ImportCSVResult[]) => void;
  onSaveResults: (result: BulkSaveResult) => void;
  onShowErrors: () => void;
}

type ProgressState = {
  percentage: number;
  processed: number;
  total: number;
  accountsWithError: BulkSaveResult["errors"];
};

export default function ImportFileSection({
  files,
  onImport,
  onShowErrors,
  onSaveResults,
}: Props) {
  const { t } = useTranslation("accounts");
  const { actions } = useAccountState();
  const [status, setStatus] = useState<ImportStatus>(ImportStatus.Idle);
  const [importError, setImportError] = useState("");
  const [progress, setProgress] = useState<ProgressState>(initProgress);
  const fileInputRef = useRef<HTMLInputElement>();
  const completedRef = useRef(false);
  const canSave = files.some(f => !!f.accounts.length);
  const isSaveFinished = completedRef.current || progress.percentage === 100;
  const finishedWithErrors =
    isSaveFinished && !!progress.accountsWithError.length;

  useEffect(() => {
    if (files.length) {
      const accounts = files.reduce((acc, f) => [...acc, ...f.accounts], []);
      setProgress(prev => ({ ...prev, total: accounts.length }));
    }
  }, [files]);

  useEffect(() => {
    if (isSaveFinished) {
      onSaveResults({
        accountsDone: progress.processed,
        errors: progress.accountsWithError,
      });
    }
  }, [isSaveFinished]);

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { items } = e.dataTransfer;
    const csvFiles = Array.from(items).every(i => i.type === "text/csv");
    setStatus(() => {
      if (csvFiles) return ImportStatus.Valid;
      setImportError("importAccountsModal.import.fileTypeError");
      return ImportStatus.Invalid;
    });
  };

  const onDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setStatus(ImportStatus.Idle);
  };

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { items } = e.dataTransfer;

    if (status === ImportStatus.Invalid) return setStatus(ImportStatus.Idle);

    const files = Array.from(items).map(i => i.getAsFile());
    const fileSizeOverLimit = containsFilesOverLimit(files);

    if (fileSizeOverLimit) {
      setImportError("importAccountsModal.import.fileSizeError");
      return setStatus(ImportStatus.Invalid);
    }

    const importResults = await importDroppedCSV(files);
    onImport(importResults);
    setStatus(ImportStatus.Idle);
  };

  const onFileSelect: ChangeEventHandler<HTMLInputElement> = async e => {
    const filesArr = Array.from(e.target.files);
    const fileSizeOverLimit = containsFilesOverLimit(filesArr);

    if (fileSizeOverLimit) {
      setImportError("importAccountsModal.import.fileSizeError");
      return setStatus(ImportStatus.Invalid);
    }

    const importResults = await importSelectedCSV(filesArr);
    fileInputRef.current.value = "";
    onImport(importResults);
    setStatus(ImportStatus.Idle);
  };

  const onSave = async () => {
    setStatus(ImportStatus.Saving);
    completedRef.current = false;
    const accountsToSave = getAccountsToSave(files, 100);

    for (const [index, accounts] of accountsToSave.entries()) {
      if (completedRef.current) break;
      const { accountsDone, errors } = await actions.bulkSave(accounts);
      setProgress(prev => ({
        ...prev,
        processed: prev.processed + accountsDone,
        percentage: getProgressPercentage(index, accountsToSave),
        accountsWithError: [...prev.accountsWithError, ...errors],
      }));
    }

    completedRef.current = true;
    actions.synchronizeAccounts();
    actions.reloadAccounts();
  };

  const handleSaveAction = () => {
    if (!completedRef.current) {
      completedRef.current = true;
    } else {
      setProgress(initProgress());
      setStatus(ImportStatus.Idle);
    }
  };

  const getResultsText = () => {
    const { accountsWithError, processed, total } = progress;
    const { length: errorCount } = accountsWithError;
    const translationProps = { processed, total, errorCount };

    if (!isSaveFinished) {
      return t("importAccountsModal.import.saving", translationProps);
    }

    return !!accountsWithError.length
      ? t("importAccountsModal.import.finishedWithErrors", translationProps)
      : t("importAccountsModal.import.saveFinished", translationProps);
  };

  if (status === ImportStatus.Saving) {
    return (
      <DropAndDropAreaContainer status={status}>
        <ImportFileContent>
          <ProgressIndicator
            value={progress.percentage}
            finishedWithErrors={finishedWithErrors}
            withPercentage
          />
          <SaveProgressText variant="caption" isError={finishedWithErrors}>
            {getResultsText()}
            {finishedWithErrors && (
              <Link onClick={onShowErrors}>
                {t("importAccountsModal.import.resultsLink")}
              </Link>
            )}
          </SaveProgressText>
          <Button variant="outlined" size="small" onClick={handleSaveAction}>
            {isSaveFinished
              ? t("importAccountsModal.import.addMore")
              : t("importAccountsModal.import.abort")}
          </Button>
        </ImportFileContent>
      </DropAndDropAreaContainer>
    );
  }

  return (
    <DropAndDropAreaContainer
      status={status}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <ImportIcon />
      <SaveProgressText variant="caption">
        {t("importAccountsModal.import.dragFiles")}
        <input
          type="file"
          id="csv-file"
          accept=".csv"
          multiple
          ref={fileInputRef}
          onChange={onFileSelect}
        />
        <label htmlFor="csv-file">
          <Link>{t("importAccountsModal.import.selectFileLink")}</Link>
        </label>
      </SaveProgressText>
      {status === ImportStatus.Invalid && (
        <DraggedFileHelper error>{t(importError)}</DraggedFileHelper>
      )}
      {canSave && (
        <Button variant="outlined" size="small" onClick={onSave}>
          {t("importAccountsModal.import.saveBtn")}
        </Button>
      )}
    </DropAndDropAreaContainer>
  );
}

const initProgress = () => ({
  percentage: 0,
  processed: 0,
  total: 0,
  accountsWithError: [],
});

const containsFilesOverLimit = (files: File[]) => {
  return files.some(f => bytesToUnit(f.size, "MB") > 100);
};
