/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { orange } from "@material-ui/core/colors";
import { OptionGroup, OptionTitle } from "components/Styled";
import { Button } from "components/Forms/StyledComponents";
import { FieldSkeleton } from "components/SkeletonLoading";
import WithIconHelper from "components/WithIconHelper";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { AccountManagement, getStorage } from "pages/Rules/CloudStorage/utils";
import useVariables from "utils/useVariables";
import { isBlank } from "utils/string";
import PickerModal, { isPickerAvailable } from "./PickerModal";
import PathFields from "./PathFields";
import { getDefaultFolderStructure } from "../utils";

import { Location } from "@dashboard-v3/api";
import { FolderType, StorageFolder } from "./types";
import { LocationUpdate } from "../types";

export enum PathType {
  ROOT = "ROOT",
  STORAGE = "STORAGE",
  SAME_FOLDER = "SAME_FOLDER",
}

type SubfolderError = {
  isError: boolean;
  errorMsg?: string;
};

const initError = () => ({
  isError: false,
});

export default function FilepathSelector() {
  const { t } = useTranslation(["components", "rules"]);
  const { state, dispatch } = useRuleContext();
  const storage = getStorage(state.rule);
  const { mapVarToValue, variableOptions, loading } = useVariables(state.rule);
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [subfolderError, setSubfolderError] =
    useState<SubfolderError>(initError);
  const { actionType, targetType } = state.rule;
  const { provider, location } = storage;
  const isNewRule = Boolean(!state.rule.id);
  const { fieldsWithError } = state.checks.storageFilename;

  useEffect(() => {
    if (isNewRule) {
      initLocation();
    }
  }, []);

  // TODO: check if we need this useEffect
  useEffect(() => {
    const isError = fieldsWithError?.includes("folderStructure");
    setSubfolderError({
      isError: isError,
      ...(isError && {
        errorMsg: t("rules:storageFilepath.subfolder.error.invalid"),
      }),
    });
  }, [fieldsWithError]);

  function initLocation() {
    let changes: LocationUpdate = {
      folderStructure: getDefaultFolderStructure(actionType, targetType, t),
    };

    if (actionType === "DRAG_AND_DROP") {
      // "Same folder of the email" as initial selection of drag and drop
      changes = {
        folderStructure: "",
        parentId: FolderType.SOURCE_FOLDER,
      };
    }

    updateLocation(changes);
  }

  function updateLocation(changes: LocationUpdate) {
    setSubfolderError(() => {
      const update: SubfolderError = { isError: false };
      if (folderInputIsRequired(changes)) {
        update.isError = true;
        update.errorMsg = t("rules:storageFilepath.location.invalidFilepath");
      }
      return update;
    });

    dispatch({
      type: "updateStep",
      payload: {
        step: "filePath",
        changes: { location: changes },
      },
    });
  }

  const handlePathType = e => {
    const value: PathType = e.target.value;

    switch (value) {
      case PathType.ROOT:
        updateLocation({
          folderStructure:
            location.folderStructure ||
            getDefaultFolderStructure(actionType, targetType, t),
          parentId: null,
          parentName: null,
        });
        break;
      case PathType.SAME_FOLDER:
        updateLocation({
          folderStructure: "",
          parentId: FolderType.SOURCE_FOLDER,
          parentName: null,
        });
        break;
      case PathType.STORAGE:
        break;
      default:
        throw new Error("Unhandled path type");
    }
  };

  function showPickerFolderButton() {
    return (
      Boolean(location.parentId) &&
      location.parentId !== FolderType.SOURCE_FOLDER
    );
  }

  function folderPickerChange(folder?: StorageFolder) {
    if (folder) {
      updateLocation({ ...storage.location, ...folder });
    }
    setOpenPicker(false);
  }

  const handlePathChange = (type: FolderType, value: string) => {
    setSubfolderError({ isError: false });

    if (type === FolderType.PARENT_FOLDER) {
      updateLocation({
        ...storage.location,
        parentId: value,
        parentName: null,
      });
    }

    if (type === FolderType.FOLDER_STRUCTURE) {
      updateLocation({
        ...storage.location,
        folderStructure: mapVarToValue(value, variableOptions),
      });
    }
  };

  if (loading) {
    return <FieldSkeleton withLabel />;
  }

  return (
    <OptionGroup>
      <OptionGroup>
        <WithIconHelper
          position="after"
          text={t("rules:cloudStorage.balanced.helper")}
          buttonStyles={{ marginBottom: "3px", marginLeft: "3px" }}
          display={state.storageManagement === AccountManagement.BALANCED}
          icon={
            <WarningRoundedIcon
              fontSize="medium"
              style={{ color: orange[500] }}
            />
          }
        >
          <OptionTitle>{t("storageFilepath.label")}</OptionTitle>
        </WithIconHelper>
        <RadioGroup
          defaultValue={PathType.SAME_FOLDER}
          row
          value={getPathType(location)}
          onChange={handlePathType}
        >
          {isPickerAvailable(storage) && (
            <>
              <FormControlLabel
                value={PathType.ROOT}
                label={t("storageFilepath.root")}
                control={<Radio color="primary" />}
              />
              {actionType === "DRAG_AND_DROP" && (
                <FormControlLabel
                  value={PathType.SAME_FOLDER}
                  label={t("storageFilepath.pickSameStorage")}
                  control={<Radio color="primary" />}
                />
              )}
              <FormControlLabel
                value={PathType.STORAGE}
                label={t("storageFilepath.pickFromStorage")}
                control={<Radio color="primary" />}
                onChange={() => setOpenPicker(true)}
              />
              {showPickerFolderButton() && (
                <Button
                  variant="outlined"
                  size="small"
                  style={{ alignSelf: "center" }}
                  wording={t("storageFilepath.pickFolder")}
                  onClick={() => setOpenPicker(true)}
                />
              )}
            </>
          )}
        </RadioGroup>
        <PathFields
          provider={provider}
          folderPath={location}
          searchValues={variableOptions}
          onChange={handlePathChange}
          subFolderError={subfolderError}
        />

        <PickerModal
          provider={provider}
          onChange={folderPickerChange}
          open={openPicker}
        />
      </OptionGroup>
    </OptionGroup>
  );
}

function folderInputIsRequired(changes: LocationUpdate) {
  return !changes.parentId && isBlank(changes.folderStructure);
}

function getPathType(location: Location) {
  if (location?.parentId) {
    return location.parentId === FolderType.SOURCE_FOLDER
      ? PathType.SAME_FOLDER
      : PathType.STORAGE;
  }
  return PathType.ROOT;
}
