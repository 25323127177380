import SelectField from "components/Forms/SelectField";
import { Label, OptionGroup } from "components/Styled";
import { FormHelperText, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import getSharePathOptions from "./getSharePathOptions";
import useVariables from "utils/useVariables";

type Params = {
  currentPath: string;
  position?: number;
  onChange: (event) => void;
};

export default function SelectCollaboratePath({
  currentPath,
  position,
  onChange,
}: Params) {
  const { t } = useTranslation("rules");
  const { mapVarToLabel } = useVariables();
  const positionSelected = position >= 0;
  const options = useMemo(
    () => getSharePathOptions(mapVarToLabel(currentPath)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPath]
  );

  return (
    <OptionGroup>
      <Label wording={t("storageFilepath.position.title")} />
      <SelectField
        name="position"
        label={t("storageFilepath.position.label")}
        options={options}
        value={normalizePosition(position)}
        onChange={onChange}
        renderOption={({ key, text, value }) => (
          <MenuItem key={key} value={value}>
            {text}
          </MenuItem>
        )}
      />
      {!positionSelected && (
        <FormHelperText margin="dense" error>
          {t("storageFilepath.position.error")}
        </FormHelperText>
      )}
    </OptionGroup>
  );
}

/**
 *
 * @param position expected a positive number or undefined
 * @returns number value as string or empty string
 */
function normalizePosition(position?: number) {
  return position ?? "";
}
