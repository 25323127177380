import { Paper } from "@material-ui/core";
import ChartHeader from "../ChartHeader";
import takeRange from "../takeRange";
import { useTranslation } from "react-i18next";
import { TimePeriodStat } from "@dashboard-v3/api";
import { DaysRange } from "pages/UsageReports/dayRange";
import { useEffect, useState } from "react";
import { EmailFlowSumData, parseData } from "./parseData";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Margin } from "recharts/types/util/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LegendData, StatConfig } from "../types";
import {
  formatBigNumber,
  tickFormatter,
  tooltipFormatter,
  tooltipLabelFormatter,
} from "../labels";
import ChartTitle from "../ChartTitle";

dayjs.extend(utc);

type Params = {
  stats: Array<TimePeriodStat>;
  range: DaysRange;
};

export default function EmailFlow({ stats, range = 90 }: Params) {
  const { t } = useTranslation("usageReports");
  const [data, setData] = useState<EmailFlowSumData>([]);
  const [statBars, setStatBars] = useState<StatConfig[]>(chartBars);

  const chartMargins: Margin = { top: 5, right: 30, bottom: 5, left: 5 };

  useEffect(() => {
    setData(parseData(stats));
  }, [stats]);

  const handleLegendClick = (data: LegendData) => {
    setStatBars(prev => {
      return prev.map(statBar => {
        const { dataKey } = statBar;
        if (dataKey === data.dataKey) {
          statBar.enabled = !statBar.enabled;
        }
        return statBar;
      });
    });
  };

  return (
    <Paper style={{ padding: "2rem 1rem", width: "100%" }}>
      <ChartHeader>
        <ChartTitle>{t("emailFlow.title")}</ChartTitle>
      </ChartHeader>
      <ResponsiveContainer height={250}>
        <BarChart data={takeRange(data, range)} margin={chartMargins}>
          <CartesianGrid strokeDasharray="4" vertical={false} />
          <XAxis dataKey="date" tickFormatter={tickFormatter} />
          <YAxis
            tickLine={false}
            tickFormatter={formatBigNumber}
            tickCount={10}
          />
          <Tooltip
            labelFormatter={tooltipLabelFormatter}
            formatter={tooltipFormatter}
          />
          <Legend onClick={handleLegendClick} />
          {statBars.map(stat => (
            <Bar
              key={stat.dataKey}
              dataKey={stat.dataKey}
              name={t(stat.name)}
              stackId="a"
              fill={stat.fill}
              fillOpacity={0.8}
              hide={!stat.enabled}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
}

const colorAlpha = "0.2";

const color = {
  one: ["rgb(0, 158, 210)", `rgb(0, 158, 210, ${colorAlpha})`],
  two: ["rgb(85, 0, 255)", `rgb(85, 0, 255, ${colorAlpha})`],
  three: ["rgb(0, 106, 255)", `rgb(0, 106, 255, ${colorAlpha})`],
  four: ["rgb(0, 207, 210)", `rgb(0, 207, 210, ${colorAlpha})`],
};

const chartBars: StatConfig[] = [
  {
    dataKey: "in",
    name: "emailFlow.in",
    fill: color.one[0],
    enabled: true,
  },
  {
    dataKey: "out",
    name: "emailFlow.out",
    fill: color.two[0],
    enabled: true,
  },
  {
    dataKey: "both",
    fill: color.three[0],
    name: "emailFlow.both",
    enabled: true,
  },
];
