import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Chip } from "@material-ui/core";

interface Props {
  value: number;
  oldValue: number;
}

export default function PercentageChip({ value, oldValue }: Props) {
  return (
    <Chip
      label={percentageDiff(value, oldValue) + "%"}
      size="small"
      icon={chipLabelIcon(value, oldValue)}
      style={chipLabelStyle(value, oldValue)}
    />
  );
}

function chipLabelIcon(value: number, oldValue: number) {
  if (value > oldValue) {
    return <TrendingUpIcon style={{ color: green[700] }} />;
  } else {
    return <TrendingDownIcon style={{ color: red[700] }} />;
  }
}

function chipLabelStyle(value: number, oldValue: number) {
  if (value > oldValue) {
    return { backgroundColor: green.A100, color: green[700] };
  } else {
    return { backgroundColor: red.A100, color: red[700] };
  }
}

export function percentageDiff(value: number, oldValue: number): string {
  let diff;
  if (oldValue === 0) {
    diff = value;
  } else {
    diff = ((value - oldValue) / oldValue) * 100;
  }

  return diff <= 100 ? diff.toFixed() : "+100";
}
