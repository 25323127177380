import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

interface PickAgainProps {
  onClick: () => void;
}

export default function PickAgainBtn({ onClick }: PickAgainProps) {
  const { t } = useTranslation("copyPolicy");
  return (
    <Button
      color="primary"
      data-testid="storage-filepath__pick-folder-again-btn"
      disableElevation
      onClick={onClick}
      size="small"
      style={{ alignSelf: "center" }}
      variant="outlined"
    >
      {t("storageFilepath.pickFolder")}
    </Button>
  );
}
