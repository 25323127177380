import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ComponentList from "components/ComponentList";
import PolicyItem from "./PolicyItem";
import apiRequest from "utils/apiRequestWithErrorCode";
import { byLastModified } from "utils/sort";
import { TemplatePolicy } from "@dashboard-v3/api";
import { ShowMoreBtn } from "components/List";
import { Skeleton } from "@material-ui/lab";

const listLimit = 10;

export default function List() {
  const { t } = useTranslation("templatePolicies");
  const [loading, setLoading] = useState<"ALL" | "MORE" | "ITEM">("ALL");
  const [loadingItem, setLoadingItem] = useState<string>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [hasMore, setHasMore] = useState(false);
  const [searchOffset, setSearchOffset] = useState(0);
  const [templatePolicies, setTemplatePolicies] = useState<
    TemplatePolicy[] | null
  >();

  async function fetchPolicies(offset = 0) {
    try {
      const items = await apiRequest<TemplatePolicy[]>(
        "GET",
        `/template-policies?offset=${offset}&limit=${listLimit}`
      );
      setHasMore(items.length === listLimit);
      setTemplatePolicies(prevPolicies => {
        const newPolicies = offset ? [...items, ...prevPolicies] : items;
        return newPolicies.sort(byLastModified);
      });
    } catch (error) {
      enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
    }
  }

  const loadMore = async () => {
    setLoading("MORE");
    const newOffset = searchOffset + listLimit;
    await fetchPolicies(newOffset);
    setSearchOffset(newOffset);
    setLoading(null);
  };

  useEffect(() => {
    async function initialFetch() {
      setLoading("ALL");
      await fetchPolicies();
      setLoading(null);
    }

    if (!templatePolicies) {
      initialFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ComponentList
        list={templatePolicies}
        emptyMsg={t("list.emptyList")}
        loading={loading === "ALL"}
        renderItems={(policy: TemplatePolicy) =>
          loadingItem === policy.id ? (
            <Skeleton
              key={policy.id}
              height="4rem"
              animation="wave"
              variant="rect"
            />
          ) : (
            <PolicyItem
              key={policy.id}
              policy={policy}
              setLoading={setLoading}
              setLoadingItem={setLoadingItem}
              fetchPolicies={fetchPolicies}
            />
          )
        }
      />
      {hasMore && (
        <ShowMoreBtn loading={loading === "MORE"} onClick={loadMore} />
      )}
    </>
  );
}
