import styled, { css } from "styled-components";

export const SharedSelectCss = css`
  align-items: center;
  column-gap: 15px;
  display: grid;
  margin-top: 10px;
`;

export const SizeSelectContainer = styled.div`
  ${SharedSelectCss}
  grid-template-columns: 2fr 1fr;
  align-items: start;
`;

export const TypeSelectContainer = styled.div`
  ${SharedSelectCss}
  grid-template-columns: 1fr 1.3fr;
`;
