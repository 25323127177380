import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Typography, capitalize } from "@material-ui/core";
import openAuthPopup, {
  isClosedByUser,
  isDifferentAccount,
} from "pages/Integrations/Accounts/openAuthPopup";
import { StorageAccount, StorageProviderWithAuth } from "@dashboard-v3/api";
import { EnableAccountButton, StorageAccountsLink } from "../Styled";

interface Props {
  account: StorageAccount;
  fetchAccounts: () => Promise<void>;
}

export default function StorageOptionLabel({ account, fetchAccounts }: Props) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleAccountAuth =
    (provider: StorageProviderWithAuth, user: string) => async e => {
      e.stopPropagation();

      try {
        await openAuthPopup(provider, user);
        fetchAccounts();
        enqueueSnackbar(t("cloudStorage.reAuth.success"), {
          variant: "success",
        });
      } catch (error) {
        if (isClosedByUser(error)) return;
        if (isDifferentAccount(error)) {
          return enqueueSnackbar(
            t("cloudStorage.reAuth.matchError", { user }),
            {
              variant: "error",
            }
          );
        }
        enqueueSnackbar(t("cloudStorage.reAuth.error"), { variant: "error" });
      }
    };

  if (!account.synced) {
    return (
      <>
        <Typography variant="caption">
          {capitalize(account.type.toLowerCase())}
          {t("rules:cloudStorage.accounts.org.notSynced", {
            pre: " - ",
          })}
        </Typography>
        <EnableAccountButton
          size="small"
          color="primary"
          disableElevation
          data-testid="storage__not-synced-btn"
          onClick={handleAccountAuth(account.type, account.user)}
          variant="contained"
        >
          {t("common:enable")}
        </EnableAccountButton>
        <StorageAccountsLink onClick={() => history.push("/integrations")}>
          {t("cloudStorage.accounts.goToStorage")}
        </StorageAccountsLink>
      </>
    );
  }

  return (
    <Typography variant="caption">
      {capitalize(account.type.toLowerCase())}
    </Typography>
  );
}
