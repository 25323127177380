import { ResponseOrganization } from "@dashboard-v3/api";
import { fetchOrganization } from "utils/api/organization";
import request from "utils/apiRequestWithErrorCode";

type SourceAuthResponse = {
  url: string;
};

declare var location: Location;
let authWindow;

function getWindowConfig({ width, height }) {
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  return `width=${width},height=${height},status=false,titlebar=false,toolbar=false,menubar=false,top=${top},left=${left}`;
}

const getOfficeSourceAuthUrl = async () => {
  const redirectUrl = `${location.origin}/storage-auth-response`;
  return request<SourceAuthResponse>(
    "GET",
    `/auth/sources/office365?redirect_url=${redirectUrl}`
  );
};

const fromMxHero = (url: string, event: MessageEvent) => {
  return url.startsWith(event.origin) && event.data.source === "mxhero";
};

export const initOffice365Auth = async (): Promise<boolean> => {
  if (authWindow && !authWindow.closed) return null;
  const { url: authUrl } = await getOfficeSourceAuthUrl();
  const currentUrl = location.origin;
  const windowConfig = getWindowConfig({ width: 450, height: 620 });
  authWindow = window.open(authUrl, "", windowConfig);

  return new Promise((resolve, reject) => {
    function onMessage(event: MessageEvent) {
      if (fromMxHero(currentUrl, event)) {
        const { success, errorCode } = event.data;
        window.removeEventListener("message", onMessage, false);

        if (authWindow && authWindow.close) authWindow.close();
        authWindow = null;

        if (success || errorCode === "ACCESS_DENIED") {
          const isAuthorized = success && !errorCode;
          resolve(isAuthorized);
        } else {
          console.error("Error details: ", errorCode);
          reject(new Error(errorCode));
        }
      }
    }

    window.addEventListener("message", onMessage, false);
  });
};

const isOfficeSourceEnabled = (organization: ResponseOrganization) => {
  return organization.integrations?.office365?.source;
};

export const handleOffice365Auth = async () => {
  const organization = await fetchOrganization();
  if (!isOfficeSourceEnabled(organization)) {
    const isAuthorized = await initOffice365Auth();
    return isAuthorized;
  }
  return true;
};
