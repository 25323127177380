import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import AlertMsg from "components/AlertMsg";
import useMailboxAuthPopup from "utils/useMailboxAuthPopup";

import { Mailbox } from "@dashboard-v3/api";

export type SingleAccountProvider = Exclude<Mailbox["provider"], "IMAP">;

type Params = {
  provider: SingleAccountProvider;
  email: string;
  onSuccess: () => void;
};

export default function ReauthenticateSingleAccount({
  provider,
  email,
  onSuccess,
}: Params) {
  const { t } = useTranslation(["rules", "components"]);
  const { enqueueSnackbar } = useSnackbar();
  const { open, isClosedByUser } = useMailboxAuthPopup();
  const authType =
    provider === "GOOGLE" ? "google/individual" : "office365/individual";

  async function reauthenticate() {
    try {
      const response = await open(authType);

      if (response.auth && response.email) {
        if (response.email !== email) {
          enqueueSnackbar(
            t("mailboxSection.server.differentAccountError", { email }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(t("mailboxSection.server.reAuthenticateSuccess"), {
            variant: "success",
          });
          onSuccess();
        }
      }
    } catch (error) {
      if (isClosedByUser(error)) return;
      enqueueSnackbar(t("chooseProvider.fail"), {
        variant: "error",
      });
    }
  }

  return (
    <>
      <AlertMsg
        actionBtn={t("syncAgain", { ns: "components" })}
        onClick={reauthenticate}
      >
        {t("warnings.mailbox.notSynced", { ns: "components" })}
      </AlertMsg>
    </>
  );
}
