/* eslint-disable react-hooks/exhaustive-deps */
import { InputHTMLAttributes } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Mention, MentionsInput, OnChangeHandlerFunc } from "react-mentions";
import { ErrorHelper, InputContainer, mentionsStyles } from "./Styled";
import VariablesHelper from "../VariablesHelper";

import { DashboardVariable } from "types";
import SuggestionItem from "./SuggestionItem";

type InputProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, "onChange">;

export interface AutocompleteProps extends InputProps {
  variables: DashboardVariable[];
  value: string;
  testid?: string;
  fullWidth?: boolean;
  error?: boolean;
  errorText?: string;
  required?: boolean;
  innerRef?: (e: HTMLTextAreaElement) => void;
  onChange: (value: string, vars?: DashboardVariable[]) => void;
}

const trigger = "{";
const regexTrigger = new RegExp(`(\\${trigger}([^\\${trigger}]*))$`);

export default function AutocompleteSearchInput({
  variables,
  testid,
  fullWidth = false,
  error = false,
  errorText = "",
  value,
  onChange,
  ...rest
}: AutocompleteProps) {
  const { t } = useTranslation("platformVariables");
  const valueWithMentions = toValueWithMentions(value, variables, t);

  const handleOnChange: OnChangeHandlerFunc = (
    _event,
    _valueWithMentions,
    plainValue
  ) => {
    if (value !== plainValue) {
      onChange(plainValue, variables);
    }
  };

  return (
    <InputContainer className={fullWidth ? "MuiInputBase-fullWidth" : ""}>
      <MentionsInput
        style={mentionsStyles}
        inputRef={rest.innerRef}
        placeholder={rest.placeholder}
        value={valueWithMentions}
        onChange={handleOnChange}
        disabled={rest.disabled}
        required={rest.required}
      >
        <Mention
          data={toSuggestionDataItem(variables, t)}
          trigger={regexTrigger}
          renderSuggestion={item => <SuggestionItem suggestion={item} />}
        />
      </MentionsInput>
      <VariablesHelper
        value={value}
        variables={variables}
        autoCompleteDescription
      />
      {error && errorText && (
        <ErrorHelper margin="dense" error>
          {errorText}
        </ErrorHelper>
      )}
    </InputContainer>
  );
}

const toSuggestionDataItem = (variables: DashboardVariable[], t: TFunction) => {
  if (!variables) return [];
  return variables.map(v => ({
    id: v?.id || "",
    display: t(v?.name) || "",
    description: v?.description || "",
    origin: v?.origin || "",
  }));
};

/**
 * @example
 * value "{Regex group 0} test-12 new/one" => ["{Regex group 0}", " test-12 new/one"]
 */
function extractVariables(value: string): string[] {
  return value.split(/(\{.*?\})/g).filter(v => v);
}

const isVariable = (value: string): boolean => value.startsWith("{");

const toValueWithMentions = (
  value: string,
  variables: DashboardVariable[],
  t: TFunction
) => {
  if (!value) return "";
  if (!variables) return value;
  const values = extractVariables(value);
  const valuesWithMentions = values.map(value => {
    if (!isVariable(value)) return value;

    const variableMatch = variables.find(v => t(v.name) === value);
    if (!variableMatch) return value;
    return `@[${t(variableMatch.name)}](${variableMatch.id})`;
  });

  return valuesWithMentions.join("");
};
