import { Box, Paper, Button, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

export default function EmptyState({ onClick }) {
  const { t } = useTranslation("orgAndDomains");
  return (
    <Box
      display="flex"
      justifyContent="center"
      padding="30px"
      alignItems="center"
      component={Paper}
      boxShadow={0}
    >
      <Trans i18nKey="manualDomains.firstAdd" t={t}>
        <Button
          color="primary"
          disableElevation
          variant="contained"
          onClick={onClick}
        >
          Start connecting
        </Button>
        <Typography style={{ marginLeft: "8px" }}>
          more domains with mxHERO
        </Typography>
      </Trans>
    </Box>
  );
}
