import { LoginResponse } from "components/AuthenticationModal/api";
import apiRequest from "utils/apiRequestWithErrorCode";
/**
 *
 * Errors: "SYSTEM_FAILURE" | "TOKEN_NOT_FOUND_OR_EXPIRED"
 *
 * @param {string} token
 * @returns {Promise<string, string>} email
 * @re
 */
export async function checkToken(token) {
  const { email } = await apiRequest<{ email: string }>(
    "GET",
    `/auth/reset-password/${token}`
  );
  return email;
}

/**
 *
 * @param {string} token
 * @param {string} newPassword
 * @returns {Promise<import("types").User>} user
 */
export async function changePassword(token, newPassword) {
  const user = await apiRequest<LoginResponse>(
    "POST",
    `/auth/reset-password/${token}`,
    {
      password: newPassword,
    }
  );
  return user;
}
