import { useEffect, useState } from "react";

const url = "https://v4d25rq7kv9z.statuspage.io/api/v2/status.json";

export default function useServiceStats() {
  const [serviceStatus, setStatus] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      const response = await fetch(url, { signal });

      if (response.ok) {
        const result = await response.json();
        setStatus(result.status);
        return;
      } else {
        const error = await response.text();
        console.error(error);
        setStatus(null);
      }
    })();

    return () => controller.abort();
  }, []);

  return serviceStatus;
}
