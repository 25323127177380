import { useEffect } from "react";
import apiRequest from "utils/apiRequestWithErrorCode";
import useQuery from "utils/useQuery";

export default function PowerShellAuth() {
  const query = useQuery();
  const done = query.get("done");

  useEffect(() => {
    async function initAuth() {
      const response = await apiRequest<{ url: string }>(
        "GET",
        `/auth/powershell/authorize?redirect_url=${window.location.origin}/powershell-auth`
      );

      window.location.replace(response.url);
    }

    if (!done) {
      initAuth();
    }
  }, [done]);

  if (done && done === "1")
    return (
      <>
        <h3>Credential saved!</h3>
        <div>
          (check
          credentials:application:32ca442d51dade303c83efaf5e517dea:user:__yourMail__)
        </div>
      </>
    );

  return null;
}
