import { Direction } from "@dashboard-v3/api";
import { TFunction } from "react-i18next";
import {
  getAccountOption,
  getGroupOption,
} from "./DirectionAutocomplete/searchInApi";
import fetchDomainsAndTags from "./fetchDomainsAndTags";
import { Option } from "./types";

export default async function directionToOption(
  initialValue: Direction | null,
  t: TFunction
): Promise<Option> {
  if (!initialValue) return null;

  const { type, value } = initialValue;

  switch (type) {
    case "ANYONE":
    case "ANYONE_ELSE":
      return { type, label: t(`option.${type}`), managed: false };

    case "ORGANIZATION":
      return { type, label: t(`option.${type}`), managed: true };

    case "TAG":
    case "DOMAIN":
      const domainsAndTags = await fetchDomainsAndTags();
      const existingValue = domainsAndTags.find(
        item => item.type === type && item.label === value
      );
      return existingValue
        ? existingValue
        : { type, label: value, managed: false };

    case "EMAIL": {
      const option = await getAccountOption(value);
      if (option) return option;
      return { type: "ACCOUNT", label: value, managed: false };
    }

    case "GROUP": {
      const option = await getGroupOption(value);
      if (option) return option;
      break;
    }

    default:
      return null;
  }
}
