/**
 * Extracts groups of a given regular expression recursively
 * @param {string} str String representation of a regular expression
 * @returns {string[]} Array of matched regex groups
 */
const matchGroups = str => {
  const GROUP_REGEX = /\((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*\)/g;
  const matchedGroups = str.match(GROUP_REGEX);
  let res = [];
  if (matchedGroups) {
    matchedGroups.forEach(group => {
      const outerGroup = group.slice(1, -1);
      const innerGroups = outerGroup.match(GROUP_REGEX);
      res.push(group);
      if (innerGroups) {
        const innerResults = innerGroups.flatMap(matchGroups);
        if (innerResults) {
          res.push(...innerResults);
        }
      }
    });
  }
  return res;
};

/**
 * Finds and identifies groups of a given regular expression
 * @param {string} str String representation of a regular expression
 * @returns {Array} A list containing a list of objects for every
 * group found. Each object represents a part of the regex
 * specifiying whether it's a group or not
 */
export const getRegexGroups = str => {
  if (typeof str !== "string") {
    throw new Error(`Expected argument of type string but got ${typeof str}`);
  }
  const groups = str !== "" ? [str, ...matchGroups(str)] : matchGroups(str);
  return groups.map(match => {
    const regexParts = str
      .replace(match, "match")
      .split(/(match)/)
      .filter(part => part)
      .map(txt => ({
        text: txt === "match" ? match : txt,
        group: txt === "match",
      }));
    return [...regexParts];
  });
};

export function memoize(fn) {
  let cache = {};
  return (...args) => {
    let n = args[0]; // just taking one argument here
    if (n in cache) {
      return cache[n];
    } else {
      let result = fn(n);
      cache[n] = result;
      return result;
    }
  };
}
