import { useTranslation } from "react-i18next";
import { UntilTimeRange } from "@dashboard-v3/api";
import { TextField } from "@material-ui/core";

export type Props = {
  value: UntilTimeRange;
  disabled?: boolean;
  onChange: (newValue: UntilTimeRange) => void;
};

export default function UpToDate({ value, disabled, onChange }: Props) {
  const { t } = useTranslation("rules");

  return (
    <TextField
      type="date"
      variant="outlined"
      fullWidth
      label={t("upTo")}
      inputProps={{
        "data-testid": "mailbox__from-date_upto-textfield",
      }}
      disabled={disabled}
      value={value.until}
      onChange={event => onChange({ mode: "DATES", until: event.target.value })}
    />
  );
}
