import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { useConditionsCtx } from "../../state/ConditionsProvider";
import { Dataset } from "@dashboard-v3/api";
import { AndIndex } from "pages/Variables/types";
import { andCondition, isReadOnly } from "../read";
import { updateDatasetId } from "../actions";

export default function SelectDataSet({
  options,
  index,
}: {
  options: Dataset[];
  index: AndIndex;
}) {
  const { t } = useTranslation("variables");
  const { updateVariable, variable } = useConditionsCtx();
  const { dataSetId } = andCondition(variable, index);

  const handleDatasetId = e => {
    const dataSetId = e.target.value;
    updateDatasetId(updateVariable, index, dataSetId);
  };

  // const { dataSetId } = selectFromCondition();

  return (
    <SelectField
      name="dataSetId"
      label={t("form.datasetId.label")}
      inputProps={{ "data-testid": "vars_datasets-select" }}
      options={options}
      value={dataSetId || ""}
      onChange={handleDatasetId}
      required
      renderOption={({ id, name }) => (
        <MenuItem key={id} value={id} data-testid="vars_datasets-item">
          {name}
        </MenuItem>
      )}
      disabled={isReadOnly(variable)}
    />
  );
}
