import { useTranslation } from "react-i18next";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SectionHeader from "components/SectionHeader";
import { Button } from "components/Forms/StyledComponents";
import Form from "./Form";
import List from "./List";

const TemplateDefinitions = () => {
  const { t } = useTranslation("templateDefinitions");
  const { pathname } = useLocation();
  const history = useHistory();
  const isOnForm = pathname.includes("/new") || pathname.includes("/edit");

  const handleRouting = () => {
    return history.push(
      isOnForm ? "/template-definitions" : "/template-definitions/new"
    );
  };

  const renderButtonText = () => {
    return isOnForm ? t("common:backToList") : t("common:create");
  };

  return (
    <>
      <SectionHeader
        title={t("title")}
        sideBtn={
          <Button wording={renderButtonText()} onClick={handleRouting} />
        }
      >
        <Typography>{t("subtitle")}</Typography>
      </SectionHeader>
      <Switch>
        <Route path="/template-definitions" exact>
          <List />
        </Route>
        <Route path="/template-definitions/new" exact>
          <Form />
        </Route>
        <Route path="/template-definitions/edit/:id" exact>
          <Form />
        </Route>
      </Switch>
    </>
  );
};

export default TemplateDefinitions;
