import { useState, Dispatch, SetStateAction } from "react";
import useInterval from "utils/useInterval";
import * as api from "../api";

import { Source } from "@dashboard-v3/api";

const useSynchronize = (
  source: Source,
  setSource: Dispatch<SetStateAction<Source>>,
  onFinish: () => void
) => {
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const interval = isSyncing ? 1000 : null;

  const isSyncFinished = (source: Source) => {
    const { synchronization } = source;
    const { status, step } = synchronization;
    return status === "pending" && (step === "error" || step === "success");
  };

  const synchronizeSource = async () => {
    const updated = await api.fetchSource(source.id);
    setSource(updated);

    if (isSyncFinished(updated)) {
      setIsSyncing(false);
      onFinish();
    }
  };

  useInterval(synchronizeSource, interval);

  return {
    startSync: () => setIsSyncing(true),
    isSyncing,
  };
};

export default useSynchronize;
