import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import SectionHeader from "components/SectionHeader";
import LinearLoader from "components/LinearLoader";
import useRuleLoader from "./useRuleLoader";
import RuleForm from "./RuleForm";
import { RuleType } from "../types";
import useCheckBoxEnabled from "./useCheckBoxEnabled";

export type RuleUrlParams = {
  id?: string;
  type?: Lowercase<RuleType>;
  action?: "edit" | "copy";
};

export default function CreateOrEdit() {
  const { t } = useTranslation("rules");
  const { action }: RuleUrlParams = useParams();
  const { isNew, rule, isLoading } = useRuleLoader();

  const isCreate = isNew || action === "copy";

  useCheckBoxEnabled(isNew && rule.actionType === "DRAG_AND_DROP");

  return (
    <>
      <SectionHeader title={isCreate ? t("createRule") : t("editRule")}>
        <Typography>
          {t("editCreateRule", {
            action: isCreate ? t("create") : t("edit"),
          })}
        </Typography>
      </SectionHeader>
      {isLoading || !rule ? <LinearLoader /> : <RuleForm rule={rule} />}
    </>
  );
}
