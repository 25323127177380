import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useSearch from "./useSearch";
import { Account, ProviderAccounts } from "@dashboard-v3/api";

export interface Params {
  value: ProviderAccounts;
  onChange: (accounts: string[]) => void;
}

function Accounts({ value, onChange }: Params) {
  const { t } = useTranslation("rules");
  const [error, setError] = useState<string>();
  const search = useSearch<Account[], string>({
    url: "/accounts",
    searchParam: "partialEmailOrAlias",
    mapper: accounts => accounts.map(a => a.email),
    error: t("selectAccounts.errors.fetchAccounts"),
  });

  const handleSearch: ChangeEventHandler<HTMLInputElement> = async e => {
    const { value } = e.target;
    setError("");
    await search.getResults(value);
  };

  const handleValidation = () => {
    setError(() => {
      if (!value.entities.length) {
        return t("selectAccounts.accounts.errors.required");
      }
      return "";
    });
  };

  return (
    <Box mt={1} mb={1}>
      <Autocomplete
        limitTags={3}
        multiple
        defaultValue={[]}
        options={search.results}
        value={value.entities}
        onBlur={handleValidation}
        onChange={(_, selected) => onChange(selected)}
        loading={search.loading}
        loadingText={t("selectAccounts.loadingSearch")}
        renderInput={params => (
          <TextField
            {...params}
            data-testid="account_selector"
            autoFocus
            variant="outlined"
            label={t("selectAccounts.accounts.label")}
            onChange={handleSearch}
            onBlur={search.resetSearch}
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: search.loading && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}

export default Accounts;
