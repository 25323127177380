import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Params = {
  question: string;
  open: boolean;
  onChoose: (choose: boolean) => void;
};

const DeleteDialog = ({ open, onChoose, question }: Params) => {
  const { t } = useTranslation("rules");

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onChoose(false)} color="primary">
          {t("common:no")}
        </Button>
        <Button onClick={() => onChoose(true)} color="primary" autoFocus>
          {t("common:yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
