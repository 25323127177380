import { CreateAccountBody } from "@dashboard-v3/api";
import { BulkSaveResult } from "pages/Accounts/accountContext";
import isEmail from "utils/isEmail";

export type ImportCSVResult = {
  name: string;
  accounts: CreateAccountBody[];
  importErrors: Array<{ row: number; value: string; error: string }>;
};

export const importDroppedCSV = (files: File[]): Promise<ImportCSVResult[]> => {
  return Promise.all(
    files.map(async file => {
      const content = await file.text();
      const { accounts, importErrors } = parseCSVFile(content);
      return { name: file.name, accounts, importErrors };
    })
  );
};

export const importSelectedCSV = async (files: File[]) => {
  const filePromises = files.map<Promise<ImportCSVResult>>(file => {
    return new Promise(resolve => {
      if (!file) resolve(null);
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = e => {
        const content = e.target.result as string;
        const { accounts, importErrors } = parseCSVFile(content);
        resolve({ name: file.name, accounts, importErrors });
      };
    });
  });

  return Promise.all(filePromises);
};

const parseCSVFile = (content: string) => {
  const rows = content.split("\n");
  const importErrors: ImportCSVResult["importErrors"] = [];
  const accounts = rows.reduce<CreateAccountBody[]>((acc, row, i) => {
    const values = row.split(",");

    if (i === 0) return acc;
    if (values.length === 1) return acc;

    if (values.length !== 7 && i === 1) {
      importErrors.push({
        row: i,
        value: "",
        error: "importAccountsModal.files.error.missingColumn",
      });
    }

    if (!values[0].trim()) {
      importErrors.push({
        row: i,
        value: "",
        error: "importAccountsModal.files.error.emailRequired",
      });
    }

    if (values[0] && !isEmail(values[0].trim())) {
      importErrors.push({
        row: i,
        value: values[0],
        error: "importAccountsModal.files.error.invalidEmail",
      });
    }

    if (values[2] && !isEmail(values[2].trim())) {
      importErrors.push({
        row: i,
        value: values[2],
        error: "importAccountsModal.files.error.invalidBoxAccount",
      });
    }

    if (values[3] && !isEmail(values[3].trim())) {
      importErrors.push({
        row: i,
        value: values[3],
        error: "importAccountsModal.files.error.invalidOnedriveAccount",
      });
    }

    if (values[4] && !isEmail(values[4].trim())) {
      importErrors.push({
        row: i,
        value: values[4],
        error: "importAccountsModal.files.error.invalidDriveAccount",
      });
    }

    if (values[5] && !isEmail(values[5].trim())) {
      importErrors.push({
        row: i,
        value: values[5],
        error: "importAccountsModal.files.error.invalidDropboxAccount",
      });
    }

    if (importErrors.length) {
      return acc;
    }

    const account: CreateAccountBody = {
      email: isEmail(values[0]) ? values[0] : null,
      aliases: stringToArray(values[1]),
      storage: {
        box: isEmail(values[2]) ? values[2] : null,
        onedrive: isEmail(values[3]) ? values[3] : null,
        drive: isEmail(values[4]) ? values[4] : null,
        dropbox: isEmail(values[5]) ? values[5] : null,
      },
      tags: stringToArray(values[6]),
    };

    return [...acc, account];
  }, []);

  return { accounts, importErrors };
};

function stringToArray(str: string) {
  const string = str?.replace(/(\r\n|\n|\r)/gm, "");
  return string ? string.split(" ") : [];
}

export const getProgressPercentage = (index: number, arr: any[]) => {
  const percentage = ((index + 1) / arr.length) * 100;
  return Math.floor(percentage);
};

export const getAccountsToSave = (files: ImportCSVResult[], limit: number) => {
  const accounts = files.reduce<CreateAccountBody[]>((acc, f) => {
    return [...acc, ...f.accounts];
  }, []);

  if (accounts.length < limit) return [accounts];
  return Array.from({ length: Math.ceil(accounts.length / limit) }, (v, i) =>
    accounts.slice(i * limit, i * limit + limit)
  );
};

export const getResultFileHref = (accounts: BulkSaveResult["errors"]) => {
  const formatedAccounts = accounts.map(a => {
    return {
      Email: a.email,
      Aliases: (a.aliases || []).join(" "),
      "Box Account": a.storage?.box || "",
      "OneDrive Account": a.storage?.onedrive || "",
      "Google Drive Account": a.storage?.drive || "",
      "Dropbox Account": a.storage?.dropbox || "",
      Tags: (a.tags || []).join(" "),
    };
  });

  const header = Object.keys(formatedAccounts[0]);
  const rows = formatedAccounts.map(row => {
    return Object.keys(formatedAccounts[0])
      .map(field => JSON.stringify(row[field]))
      .join(",");
  });
  const content = [header.join(","), ...rows].join("\r\n");
  const file = new Blob([content], { type: "text/csv" });
  return URL.createObjectURL(file);
};

export function bytesToUnit(bytes: number, unit: "KB" | "MB" | "GB" | "TB") {
  switch (unit) {
    case "KB":
      return bytes / Math.pow(2, 10);
    case "MB":
      return bytes / Math.pow(2, 20);
    case "GB":
      return bytes / Math.pow(2, 30);
    case "TB":
      return bytes / Math.pow(2, 40);
    default:
      return bytes;
  }
}
