import { useHistory, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Item from "components/List/Item";
import { useDependencyModal } from "components/DependencyModal";
import apiRequest from "utils/apiRequestWithErrorCode";
import { ListItemProps } from "../types";

const ListItem = ({
  template,
  setLoading,
  setLoadingItem,
  fetchDefinitions,
}: ListItemProps) => {
  const { t } = useTranslation("templateDefinitions");
  const { errorHandler } = useDependencyModal();
  const history = useHistory();
  const { id } = template;

  const removeDefinition = async () => {
    setLoading("ITEM");
    setLoadingItem(id);
    try {
      await apiRequest<void>("DELETE", `/template-definitions/${id}`);
      await fetchDefinitions();
    } catch (error) {
      errorHandler(error, t("errors.definitions.deleteError"));
    } finally {
      setLoading(null);
      setLoadingItem(null);
    }
  };

  const goToEdit = () => {
    const editData = {
      pathname: generatePath("/template-definitions/edit/:id", { id }),
      state: {
        definitionId: template.id,
        selectedDefinition: {
          name: template.name,
          description: template.description,
          templateId: template.templateId,
          mappings: template.mappings,
          storageType: template.storageType,
        },
      },
    };
    history.push(editData);
  };

  return <Item item={template} onEdit={goToEdit} onDelete={removeDefinition} />;
};

export default ListItem;
