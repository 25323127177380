import { Option } from "../types";

export default function sameOption(oneOption: Option, otherOption: Option) {
  if (!oneOption && !otherOption) return true;
  if (!oneOption || !otherOption) return false;

  if (oneOption.type === otherOption.type) {
    return oneOption.label === otherOption.label;
  }

  return false;
}
