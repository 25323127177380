import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ButtonWithLoading from "components/ButtonWithLoading";
import { useState } from "react";
import * as api from "./api";
import Form from "./Form";
import { DomainDkimState } from "./useRecords";
import { DKIMRecord } from "@dashboard-v3/api";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { getErrorCode } from "utils/apiRequestWithErrorCode";

type Params = {
  record?: DKIMRecord;
  domainDkimState: DomainDkimState[];
  onCancel: () => void;
  onVerifyResult: (record: DKIMRecord) => void;
};

export default function NewItem({
  record,
  domainDkimState,
  onCancel,
  onVerifyResult,
}: Params) {
  const { t } = useTranslation("orgAndDomains");
  const [selectedDomain, setDomain] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [dkimRecord, setDkimRecord] = useState<DKIMRecord>(record);
  const { enqueueSnackbar } = useSnackbar();

  async function generateDkim() {
    try {
      setIsLoading(true);
      setDkimRecord(await api.generateDkim(selectedDomain));
    } catch (error) {
      const code = getErrorCode<"PENDING_DKIM_FOUND" | "DOMAIN_NOT_FOUND">(
        error
      );

      let errorMessage = t("common:errors.fetchError");

      if (code === "PENDING_DKIM_FOUND") {
        errorMessage = t("dkim.generateError.pendingFound");
      }

      if (code === "DOMAIN_NOT_FOUND") {
        errorMessage = t("dkim.generateError.domainNotFound", {
          domain: selectedDomain,
        });
      }

      enqueueSnackbar(errorMessage, { variant: "error" });
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box component={Paper} padding="20px">
      <Typography variant="h6">{t("dkim.form.title")}</Typography>
      <Box mt={3} mb={3} display="flex" gridGap="15px" alignItems="baseline">
        <Select
          displayEmpty
          variant="outlined"
          style={{ minWidth: "300px" }}
          value={selectedDomain}
          disabled={Boolean(dkimRecord)}
          onChange={event => {
            const domainName = event.target.value as string;
            setDomain(domainName);
          }}
        >
          {domainDkimState.map(({ domain, dkimState }) => (
            <MenuItem
              key={domain}
              value={domain}
              disabled={dkimState === "PENDING"}
            >
              <Box display="flex" flexDirection="column">
                {domain}
              </Box>
            </MenuItem>
          ))}
        </Select>

        {!Boolean(dkimRecord) && (
          <>
            <ButtonWithLoading
              variant="contained"
              loading={isLoading}
              disableElevation
              color="primary"
              onClick={generateDkim}
            >
              {isLoading ? t("dkim.form.loading") : t("dkim.form.continueBtn")}
            </ButtonWithLoading>
            <Button color="secondary" disableElevation onClick={onCancel}>
              {t("manualDomains.cancel")}
            </Button>
          </>
        )}
      </Box>

      {Boolean(dkimRecord) && (
        <Form record={dkimRecord} onVerifyResult={onVerifyResult} />
      )}
    </Box>
  );
}
