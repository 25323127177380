import styled, { css } from "styled-components";
import {
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const EmptyFolderContainer = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

  svg {
    font-size: 7.5rem;
    color: rgba(0, 0, 0, 0.12);
  }
  p {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const FolderItemContainer = styled.div<{ withCheckbox: boolean }>`
  display: flex;
  justify-content: ${({ withCheckbox }) =>
    withCheckbox ? "initial" : "space-between"};
  padding: 10px 24px;
  cursor: pointer;
  button {
    display: none;
    align-self: center;
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
    button {
      display: ${({ withCheckbox }) => (withCheckbox ? "none" : "block")};
    }
    .MuiTypography-button {
      text-decoration: underline;
    }
  }
  .MuiCheckbox-root {
    padding: 0;
    width: 20px;
  }
  .MuiCheckbox-root:hover {
    background: transparent;
  }
  ${({ withCheckbox }) => withCheckbox && "gap: 22px"}
`;

const detailsStyles = css`
  gap: 12px;
  align-items: center;
  div {
    padding-left: 3px;
    height: 20px;
  }
  span:nth-child(1) {
    margin-top: 4px;
  }
  span:nth-child(2) {
    margin-top: 2px;
    font-size: 11px;
  }
  svg {
    height: 16px;
    width: 16px;
  }
`;

export const FolderInfoContainer = styled.div<{ details?: boolean }>`
  display: flex;
  flex-direction: ${({ details }) => (details ? "row" : "column")};
  ${({ details }) => details && detailsStyles}
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 15px;
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    padding-bottom: 3px;
    height: 28px;
    width: 28px;
  }
  span {
    font-weight: 500;
  }
`;

export const ContentLoaderRow = styled.div`
  padding: 10px 24px;
`;

export const BreadCrumbsItem = styled(Typography)<{ $current: boolean }>`
  cursor: ${({ $current }) => ($current ? "initial" : "pointer")};
  :hover {
    text-decoration: ${({ $current }) => ($current ? "initial" : "underline")};
  }
  font-weight: ${({ $current }) => ($current ? "500" : "initial")};
`;

export const StyledDialogActions = styled(DialogActions)`
  justify-content: flex-start;
  padding: 16px 24px;
  height: 68.5px;
`;
