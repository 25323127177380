import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, LinearProgress } from "@material-ui/core";
import Details from "pages/Tracing/Details";
import { PropertyData } from "pages/Tracing/Styled";
import { TracingEventDetails, NotificationEvent } from "@dashboard-v3/api";
import { useFetch } from "utils/api/useFetch";

interface Props {
  onError: () => void;
  notification: NotificationEvent;
}

const EmailDetails = ({ notification, onError }: Props) => {
  const { t } = useTranslation(["notifications", "tracing"]);
  const { enqueueSnackbar } = useSnackbar();
  const { data } = notification;

  const { data: itemDetails, isLoading } = useFetch<TracingEventDetails[]>(
    `/tracing/details/${data.msgUID}`,
    {
      fallbackData: [],
      onError: () => {
        enqueueSnackbar(t("tracing:error.fetchItemEvents"), {
          variant: "error",
        });
        onError();
      },
    }
  );

  if (isLoading) {
    return (
      <Box mt={6}>
        <LinearProgress />
      </Box>
    );
  }

  if (!data.msgUID) {
    return (
      <PropertyData color="textPrimary">
        {t("notifications:emptyMsgUID")}
      </PropertyData>
    );
  }

  return <Details details={itemDetails} includeAll />;
};

export default EmailDetails;
