import { State } from "../types";
import { Draft } from "immer";
import { isBlank } from "utils/string";

export function verifyIfCanSave(draft: Draft<State>) {
  const allStepsDone = Object.keys(draft.steps).every(
    step => draft.steps[step].done
  );

  const allFiltersValid = Object.keys(draft.filters).every(
    filter => draft.filters[filter].valid
  );

  draft.remaining = [];

  if (!allStepsDone) {
    draft.remaining.push("steps");
  }
  if (isBlank(draft.rule.name)) {
    draft.remaining.push("name");
  }

  draft.canSave = draft.remaining.length === 0 && allFiltersValid;
}
