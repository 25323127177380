import { ListItem, ListItemText, Box, Typography } from "@material-ui/core";

export default function EmptyItem({ children }) {
  return (
    <ListItem disableGutters dense data-testid="user-list__item">
      <ListItemText>
        <Box m={3}>
          <Typography>{children}</Typography>
        </Box>
      </ListItemText>
    </ListItem>
  );
}
