import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { MailboxProvider } from "pages/Integrations/Accounts/helpers";
import useMailboxAuthPopup from "utils/useMailboxAuthPopup";

import { ServerType } from ".";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: ServerType | null) => void;
}

export default function AddMailboxAccountModal(props: Props) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen, onClose, onChange } = props;
  const [selected, setSelected] = useState<MailboxProvider>(null);
  const { open, isClosedByUser } = useMailboxAuthPopup();

  useEffect(() => {
    return () => setSelected(null);
  }, []);

  const addGoogleAccount = async () => {
    try {
      const res = await open("google/individual");
      if (!res.auth) return;
      onChange({
        provider: "GOOGLE",
        authentication: "INDIVIDUAL",
        accounts: {
          type: "ACCOUNT",
          entities: [res.email],
        },
      });
    } catch (error) {
      if (isClosedByUser(error)) return onClose();
      console.error("Fail with error: ", error);
      enqueueSnackbar(t("chooseProvider.fail"), {
        variant: "error",
      });
    }
  };

  const addOfficeAccount = async () => {
    try {
      const res = await open("office365/individual");
      if (!res.auth) return;
      onChange({
        provider: "OFFICE365",
        authentication: "INDIVIDUAL",
        accounts: {
          type: "ACCOUNT",
          entities: [res.email],
        },
      });
    } catch (error) {
      if (isClosedByUser(error)) return onClose();
      if (error instanceof Error) {
        const errorToShow = error.message
          ? t("chooseProvider.failWithCode", { code: error.message })
          : t("chooseProvider.fail");

        enqueueSnackbar(errorToShow, { variant: "error" });
      } else {
        console.error("Fail with error: ", error);
        enqueueSnackbar(t("chooseProvider.fail"), {
          variant: "error",
        });
      }
    }
  };

  const handleItemClick = async (provider: MailboxProvider) => {
    if (selected && selected === provider) return;
    setSelected(provider);
    if (provider === MailboxProvider.Google) await addGoogleAccount();
    if (provider === MailboxProvider.Office) await addOfficeAccount();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("addMailboxAccount.title")}</DialogTitle>
      <List style={{ padding: "0 0 20px 0" }}>
        {Object.values(MailboxProvider).map(provider => (
          <ListItem
            key={provider}
            button
            onClick={() => handleItemClick(provider)}
            disabled={selected && selected !== provider}
          >
            <ListItemAvatar>
              <Avatar variant="rounded">
                <SupervisorAccountIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t(
                `mailBoxServerType.label.individual.${`${provider}_INDIVIDUAL`}`
              )}
            />
            {selected === provider && <CircularProgress size={20} />}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
