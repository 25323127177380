export default async function downloadFile(
  data: unknown | string,
  fileName: string,
  fileType: "json" | "csv" = "json"
): Promise<void> {
  const element = document.createElement("a");
  let file;

  if (fileType === "json") {
    file = new Blob([JSON.stringify(data, undefined, 2)], {
      type: "application/json; charset=UTF-8",
    });
  } else {
    file = new Blob([data as string], { type: "text/csv; charset=UTF-8" });
  }

  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.${fileType}`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.parentElement.removeChild(element);
}
