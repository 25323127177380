import * as Sentry from "@sentry/react";
import { getUser } from "./authentication";

export function captureException(
  error: unknown,
  extra?: Record<string, unknown>
) {
  Sentry.captureException(error, { user: user(), extra: extra ?? null });
}

type More = {
  extra?: Record<string, unknown>;
  level?: Sentry.SeverityLevel;
};

export function captureMessage(message: string, more?: More) {
  Sentry.captureMessage(message, {
    level: more?.level || "error",
    user: user(),
    extra: more.extra || null,
  });
}

function user() {
  const currentUser = getUser();
  return currentUser
    ? {
        email: currentUser.email,
        organizationId: currentUser.organizationId,
      }
    : null;
}
