/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormHelperText, MenuItem, Typography } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { FieldSkeleton } from "components/SkeletonLoading";
import { isBlank } from "utils/string";

import { CopyPolicy, StorageProvider } from "@dashboard-v3/api";
import { LocationUpdate } from "./types";

interface SelectCopyPolicyProps {
  options: CopyPolicy[];
  storageProvider: StorageProvider;
  loading: boolean;
  value: string;
  onChange: (changes: LocationUpdate) => void;
}

export default function SelectCopyPolicy({
  options,
  storageProvider,
  loading,
  value,
  onChange,
}: SelectCopyPolicyProps) {
  const { t } = useTranslation("rules");

  useEffect(() => {
    if (!value || !options) return;

    const shouldResetValue = options.some(p => {
      if (!p.storageType) return false;
      return p.id === value && p.storageType !== storageProvider;
    });

    if (shouldResetValue) onChange({ copyPolicy: null });
  }, [storageProvider]);

  const handleOnChange = e => {
    const { value } = e.target;
    onChange({ copyPolicy: value });
  };

  if (loading) return <FieldSkeleton />;

  return (
    <>
      <SelectField<CopyPolicy>
        name="copyPolicy"
        label={t("storageFilepath.copyPolicies.field")}
        options={options}
        value={value || ""}
        onChange={handleOnChange}
        renderValue={v => options.find(o => o.id === v).name}
        renderOption={({ id, name, description, storageType }) => (
          <StyledMenuItem
            key={id}
            value={id}
            dense
            disabled={isDisabledOption(storageProvider, storageType)}
          >
            <span style={{ fontSize: "12px" }}>
              {storageType
                ? t(`common:integration.provider:${storageType}`)
                : t("storageFilepath.copyPoliciesAllProvidersLabel")}
            </span>
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {description || t("storageFilepath.copyPoliciesDefaultDesc")}
            </Typography>
          </StyledMenuItem>
        )}
      />
      {isBlank(value) && (
        <FormHelperText margin="dense" error>
          {t("storageFilepath.copyPolicies.error")}
        </FormHelperText>
      )}
    </>
  );
}

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const isDisabledOption = (
  ruleProvider: StorageProvider,
  policyProvider: StorageProvider
) => {
  if (!policyProvider) return false;
  return policyProvider !== ruleProvider;
};
