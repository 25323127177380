/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";
import { ControlProps, LocationUpdate, SwitchControls } from "./types";
import { StepHeader } from "./Styled";
import FilepathLocation from "./FilepathLocation";
import AdvancedOptions from "./AdvancedOptions";
import {
  controlsInit,
  getCurrentPathFromLocation,
  getDefaultFolderStructure,
  isValidPath,
} from "./utils";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import { getStorage } from "../CloudStorage/utils";
import { Collaboration, Storage } from "@dashboard-v3/api";
import useLocationTitle from "./useLocationTitle";

export default function StorageFilepath() {
  const { t } = useTranslation("rules");
  const [controls, setControls] = useState<SwitchControls>(controlsInit());
  const { state, dispatch } = useRuleContext();
  const [previousLocation, setPreviousLocation] = useState<LocationUpdate>();
  const storage = getStorage(state.rule);
  const currentPath = getCurrentPathFromLocation(storage.location);

  const expanded = state.steps["storageFilepath"].expanded;

  const locationTitle = useLocationTitle(storage.location);

  useEffect(() => {
    const { location, collaboration } = storage;
    setControls(prev =>
      produce(prev, controls => {
        if (location.copyPolicy) {
          controls.enablePolicy = true;
        }
        if (location.emailSubfolder) {
          controls.enableEmailSubfolder = true;
        }
        if (location.bodySubfolder) {
          controls.enableBodySubfolder = true;
        }
        if (location.attachmentsSubfolder) {
          controls.enableAttachmentsSubfolder = true;
        }
        if (collaboration) {
          controls.enableCollaborate = true;
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { enableCollaborate } = controls;
    if (enableCollaborate && !isValidPath(currentPath)) {
      setControls(prev => ({ ...prev, enableCollaborate: false }));
      dispatch({
        type: "updateStep",
        payload: {
          step: "filePath",
          changes: { collaboration: null },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  const handleControls: ControlProps["handleControls"] = (name, enabled) => {
    const updatedControls = { ...controls, [name]: enabled };
    const {
      enableCollaborate,
      enableEmailSubfolder,
      enableBodySubfolder,
      enableAttachmentsSubfolder,
    } = updatedControls;

    setControls(updatedControls);

    const storage = getStorage(state.rule);

    let changes: Partial<Storage> = {
      location: { ...storage.location },
    };

    if (name === "enablePolicy") {
      if (updatedControls.enablePolicy) {
        changes.location = {
          folderStructure: null,
          parentId: null,
          parentName: null,
          copyPolicy: previousLocation ? previousLocation.copyPolicy : null,
        };

        const { folderStructure, parentId, parentName } = storage.location;
        setPreviousLocation({ folderStructure, parentId, parentName });
      } else {
        let restore = previousLocation;

        if (!previousLocation) {
          restore = {
            folderStructure: getDefaultFolderStructure(
              state.rule.actionType,
              state.rule.targetType,
              t
            ),
          };
        }

        changes.location = {
          copyPolicy: null,
          ...restore,
        };

        setPreviousLocation({ copyPolicy: storage.location.copyPolicy });
      }
    }

    if (name === "enableCollaborate") {
      changes.collaboration = enableCollaborate ? ({} as Collaboration) : null;
    }

    if (name === "enableEmailSubfolder") {
      changes.location.emailSubfolder = enableEmailSubfolder ? "" : null;
    }

    if (name === "enableBodySubfolder") {
      changes.location.bodySubfolder = enableBodySubfolder ? "" : null;
    }

    if (name === "enableAttachmentsSubfolder") {
      changes.location.attachmentsSubfolder = enableAttachmentsSubfolder
        ? ""
        : null;
    }

    dispatch({
      type: "updateStep",
      payload: {
        step: "filePath",
        changes,
      },
    });
  };

  return (
    <Paper>
      <StepHeader
        title={`4. ${t("storageFilepath.header.title")}`}
        subtitle={locationTitle}
        selected={expanded && isValidPath(currentPath)}
      />
      {expanded && (
        <>
          <FilepathLocation
            controls={controls}
            handleControls={handleControls}
          />
          {storage.provider !== "MXHERO_STORAGE" && (
            <AdvancedOptions
              controls={controls}
              handleControls={handleControls}
            />
          )}
        </>
      )}
    </Paper>
  );
}
