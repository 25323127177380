import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TimeRangeOption, timeRangeOptions } from "./timeRangeOptions";

type SelectorProps = {
  value: TimeRangeOption;
  disabled?: boolean;
  onChange: (TimeRangeOption) => void;
};

export default function Selector({ value, disabled, onChange }: SelectorProps) {
  const { t } = useTranslation("rules");

  return (
    <>
      <Typography
        gutterBottom
        variant="subtitle1"
        style={{ fontWeight: "500" }}
      >
        {t("selectTimeRange")}
      </Typography>
      <FormControl fullWidth variant="outlined">
        <Select
          data-testid="mailbox__time-range-select"
          defaultValue="none"
          inputProps={{ "data-testid": "mailbox__time-range-select" }}
          disabled={disabled}
          value={value || "ALL"}
          onChange={event => {
            onChange(event.target.value);
          }}
        >
          {timeRangeOptions.map(timeRangeOption => (
            <MenuItem
              key={timeRangeOption}
              data-testid={`mailbox__time-range-${timeRangeOption}`}
              value={timeRangeOption}
            >
              {t(timeRangeOption)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
