import { EmailError } from "@dashboard-v3/api";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ButtonWithLoading from "components/ButtonWithLoading";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetcher } from "utils/apiRequestWithErrorCode";
import random from "utils/random";

type Params = {
  open: boolean;
  ruleId: string;
  account: string;
  onClose: () => void;
};

const LIMIT = 10;

export default function ErrorDetailsModal({
  open,
  ruleId,
  account,
  onClose,
}: Params) {
  const { t } = useTranslation("stats");

  return (
    <Dialog open={open} fullWidth maxWidth="lg" scroll="paper">
      <DialogTitle>{t("accounts.errorDetailsModal.title")}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t("accounts.errorDetailsModal.updatedAt")}
              </TableCell>
              <TableCell align="left">
                {t("accounts.errorDetailsModal.subject")}
              </TableCell>
              <TableCell align="left">
                {t("accounts.errorDetailsModal.error")}
              </TableCell>
              <TableCell align="left">
                {t("accounts.errorDetailsModal.folder")}
              </TableCell>
              <TableCell align="left">
                {t("accounts.errorDetailsModal.messageId")}
              </TableCell>
            </TableRow>
          </TableHead>
          {open && <Rows ruleId={ruleId} account={account} onClose={onClose} />}
        </Table>
      </DialogContent>
    </Dialog>
  );
}

function Rows({ ruleId, account, onClose }) {
  const { t } = useTranslation("stats");

  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState<EmailError[]>([]);
  const [lastLoadLength, setLastLoadLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function load(offset) {
      setIsLoading(true);
      const data = await fetcher<EmailError[]>(
        `/rules/${ruleId}/stats/accounts/${account}/errors?limit=${LIMIT}&offset=${offset}`
      );

      setItems(items => items.concat(data));
      setLastLoadLength(data.length);
      setIsLoading(false);
    }

    load(offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const thereAreMore = lastLoadLength === LIMIT;

  if (isLoading && offset === 0) return <Loading />;

  return (
    <>
      {items.map(item => (
        <TableRow key={random()}>
          <TableCell align="left">
            {new Date(item.updatedat).toLocaleDateString()}
          </TableCell>
          <TableCell align="left">{item.subject}</TableCell>
          <TableCell align="left">{item.error}</TableCell>
          <TableCell>
            {
              item.messageidentification?.messagereference?.label?.labeldata
                ?.name
            }
          </TableCell>
          <TableCell>
            {item.messageidentification?.messagereference?.messageid}
          </TableCell>
        </TableRow>
      ))}

      <Box my={2} display="flex">
        <Button color="primary" onClick={onClose}>
          {t("common:close")}
        </Button>
        {thereAreMore && (
          <ButtonWithLoading
            loading={isLoading}
            onClick={() => {
              setOffset(offset + LIMIT);
            }}
          >
            {t("common:loadMore")}
          </ButtonWithLoading>
        )}
      </Box>
    </>
  );
}

function Loading() {
  return (
    <TableBody>
      <LoadingRow key={1} />
      <LoadingRow key={2} />
      <LoadingRow key={3} />
      <Box minHeight="40px" />
    </TableBody>
  );
}

function LoadingRow() {
  return (
    <TableRow>
      <TableCell align="center" colSpan={5}>
        <Skeleton variant="rect" height={20} />
      </TableCell>
    </TableRow>
  );
}
