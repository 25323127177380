import { Variable } from "@dashboard-v3/api";
import { DashboardVariable } from "types";
import { Draft } from "immer";
import { AndIndex } from "../types";
import { ConditionByType } from "../common/options";
import { isMapCondition } from "../helpers";

/*
IDEA 

newState = action(produce, ...params)

produce ---> immer con el set del state
params ---> los parametros que necesita el action
newState ---> podría usarse o no, da un resultado inmediato del state, aunque la vista no lo refleja hasta el siguinte render

*/

export function setApplicationFields(
  updateVariable: (updateFn: (draft: Draft<Variable>) => void) => void,
  index: AndIndex,
  options: DashboardVariable[]
) {
  const [orIndex, andIndex] = index;

  if (options.length) {
    const [{ type: sourceType }] = options;
    const sources = options.map(({ id }) => removeBraces(id));

    return updateVariable(draft => {
      draft.predicate.children[orIndex].children[andIndex].sourceType =
        sourceType;
      draft.predicate.children[orIndex].children[andIndex].sources = sources;
    });

    // return updateCondition({ sources, sourceType });
  } else {
    return updateVariable(resetApplicationFields(index));
  }
}

export function changeTypeOfAnd(
  updateVariable: (updateFn: (draft: Draft<Variable>) => void) => Variable,
  index: AndIndex,
  newType: "FIELDS" | "VARIABLES" | "AI"
): Variable {
  const [orIndex, andIndex] = index;

  if (newType === "AI") {
    return updateVariable(draft => {
      const andCondition = draft.predicate.children[orIndex].children[andIndex];
      draft.predicate.children[orIndex].children[andIndex] = {
        ...andCondition,
        type: "AI_QUESTION",
        sources: [],
        sourceType: "TEXT",
        parameters: [],
      };

      const { toValue } = draft.predicate.children[orIndex];
      draft.predicate.children[orIndex].toValue = removeVariablesInToValue(
        toValue,
        // TODO: Chequear, tal vez se deba usar orIndex, no es clara la función
        andIndex
      );
    });
  }

  if (newType === "VARIABLES") {
    return updateVariable(draft => {
      const andCondition = draft.predicate.children[orIndex].children[andIndex];
      andCondition.type = null;
      andCondition.sources = [];
      andCondition.variableIdList = [];
    });
  }

  if (newType === "FIELDS") {
    return updateVariable(draft => {
      draft.predicate.children[orIndex].children[andIndex].type = null;
    });
  }
}

export function changeAndCondition(
  updateVariable: (updateFn: (draft: Draft<Variable>) => void) => Variable,
  index: AndIndex,
  newCondition: ConditionByType["value"]
) {
  return updateVariable(draft => {
    const [orIndex, andIndex] = index;
    const orCondition = draft.predicate.children[orIndex];
    const andCondition = orCondition.children[andIndex];
    const { type } = andCondition;

    const shouldToValueVariables =
      type === "REGEX" ||
      newCondition === "REGEX" ||
      isMapCondition(type) ||
      isMapCondition(newCondition);

    if (shouldToValueVariables) {
      orCondition.toValue = removeVariablesInToValue(
        orCondition.toValue,
        andIndex
      );
    }

    delete andCondition.dataSetId;
    andCondition.type = newCondition;
  });
}

export function cancelAiQuestion(
  updateVariable: (updateFn: (draft: Draft<Variable>) => void) => Variable,
  index: AndIndex
) {
  return updateVariable(draft => {
    const [orIndex, andIndex] = index;
    const orCondition = draft.predicate.children[orIndex];
    const andCondition = orCondition.children[andIndex];

    andCondition.type = null;
    andCondition.sources = [];
    andCondition.sourceType = null;
    andCondition.parameters = [];

    orCondition.toValue = removeVariablesInToValue(
      orCondition.toValue,
      andIndex
    );
  });
}

export function updateDatasetId(
  updateVariable: (updateFn: (draft: Draft<Variable>) => void) => Variable,
  index: AndIndex,
  datasetId: string
) {
  return updateVariable(draft => {
    const [orIndex, andIndex] = index;
    draft.predicate.children[orIndex].children[andIndex].dataSetId = datasetId;
  });
}

export function updateConditionValue(
  updateVariable: (updateFn: (draft: Draft<Variable>) => void) => Variable,
  index: AndIndex,
  newValue: string
) {
  return updateVariable(draft => {
    const [orIndex, andIndex] = index;
    const andCondition = draft.predicate.children[orIndex].children[andIndex];

    const isAIQuestion = andCondition.type === "AI_QUESTION";
    const hasOpenAiContext = isAIQuestion && andCondition.parameters.length > 1;

    let updateParameters = [newValue];

    if (hasOpenAiContext) {
      const openIaContext = andCondition.parameters[1];
      updateParameters = [newValue, openIaContext];

      if (andCondition.parameters[2]) {
        updateParameters = [
          newValue,
          openIaContext,
          andCondition.parameters[2],
        ];
      }
    }

    andCondition.parameters = updateParameters;
  });
}

const resetApplicationFields =
  (index: AndIndex) => (draft: Draft<Variable>) => {
    const [orIndex, andIndex] = index;

    draft.predicate.children[orIndex].children[andIndex].sourceType = null;
    draft.predicate.children[orIndex].children[andIndex].sources = [];
  };

function removeBraces(value: string) {
  return value.replace(/[{}]/g, "");
}

// TODO: esta función no es clara en lo que hace, faltan ejemplos y tests
function removeVariablesInToValue(
  value: string,
  andIndex: number,
  multiple = false
) {
  if (!multiple) {
    const optionRegex = new RegExp(
      `{children-${andIndex},\\s(?:group-\\d+|value)}`,
      "g"
    );
    return value.replaceAll(optionRegex, "");
  }

  const optionRegex = /{children-\d,\s(?:group-\d+|value)}/g;
  const groupedOptionRegex = /({children-\d,\s(?:group-\d+|value)})/g;
  const digitRegex = /\d/g;
  const parts = value.split(groupedOptionRegex).filter(part => !!part);

  return parts
    .reduce((acc, part) => {
      if (!part) return acc;
      if (optionRegex.test(part)) {
        const optionIdx = parseInt(part.match(digitRegex)[0]);
        return optionIdx >= andIndex + 1 ? [...acc, ""] : [...acc, part];
      }
      return [...acc, part];
    }, [])
    .join("");
}
