import BoxPicker from "./BoxPicker";
import GooglePicker from "./GooglePicker";
import OneDrivePicker from "./OneDrivePicker";

import { Storage, StorageProvider } from "@dashboard-v3/api";
import { PickerParams } from "../types";

export type Params = PickerParams & {
  provider: StorageProvider;
};

export default function PickerModal({ provider, onChange, open }: Params) {
  if (provider === "BOX") {
    return (
      <BoxPicker
        onChange={items => {
          onChange(items?.at(0));
        }}
        open={open}
      />
    );
  }

  if (provider === "ONEDRIVE") {
    return <OneDrivePicker onChange={onChange} open={open} />;
  }

  if (provider === "GOOGLEDRIVE") {
    return <GooglePicker onChange={onChange} open={open} />;
  }

  return null;
}

const pickersEnabled = ["BOX", "ONEDRIVE", "GOOGLEDRIVE"];

export function isPickerAvailable(storage: Storage) {
  const { storeId, provider } = storage;
  if (!pickersEnabled.includes(provider)) return false;
  if (Boolean(storeId)) return false;
  return true;
}
