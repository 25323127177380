import apiRequest from "utils/apiRequestWithErrorCode";
import { CheckAccessResponse, StorageProvider } from "@dashboard-v3/api";

export default async function checkStorageAccountsAccess(
  provider: StorageProvider,
  accounts: string[]
) {
  return apiRequest<CheckAccessResponse>(
    "POST",
    "/storage-accounts/check-access",
    {
      provider,
      accounts,
    }
  );
}
