import { Button } from "@material-ui/core";

export default function ToggleButton({ children, active, ...more }) {
  return (
    <Button
      style={
        active
          ? {
              backgroundColor: "rgb(0, 110, 147)",
              borderColor: "rgb(0, 110, 147)",
              boxShadow: "none",
            }
          : {}
      }
      {...more}
    >
      {children}
    </Button>
  );
}
