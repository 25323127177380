import { AccountManagement } from "pages/Rules/CloudStorage/utils";
import { isBlank } from "utils/string";
import isEmail from "utils/isEmail";
import { getCloudStorage } from "../../helpers";
import { State } from "../../types";

import { Draft } from "immer";
import { Storage } from "@dashboard-v3/api";

export default function validate(draft: Draft<State>) {
  const storage = getCloudStorage(draft.rule);
  draft.steps.cloudStorage.valid =
    validateProvider(storage) && validateAsUser(draft, storage);
}

const validateProvider = (storage: Storage) =>
  Boolean(storage && storage.authentication && storage.provider);

const validateAsUser = (state: State, storage: Storage) => {
  const isManual = state.storageManagement === AccountManagement.MANUAL;
  const isBalanced = state.storageManagement === AccountManagement.BALANCED;

  if (storage.provider === "DROPBOX") {
    // userId example "dbtid:AADgomg1zTVyWZLGP7qoYE82nKMLtrgqaTk" for DropBox
    if (!storage.userId) return false;

    // Case of "Use a specific storage account"
    if (isManual) {
      const { asUser } = storage;
      return Boolean(asUser) && !isBlank(asUser) && isEmail(asUser);
    }

    // Case of "Use a balanced storage account"
    if (isBalanced) {
      const { asUsers } = storage;
      return (
        Boolean(asUsers) &&
        Boolean(asUsers?.length) &&
        Boolean(asUsers?.length <= 100)
      );
    }

    return true;
  } else if (storage.authentication === "ORGANIZATION" && isManual) {
    return "userId" in storage && isEmail(storage.userId);
  } else if (storage.authentication === "ORGANIZATION" && isBalanced) {
    return storage.userIds?.length !== 0 && storage.userIds.length <= 100;
  }

  return true;
};
