import { useEffect } from "react";
import Script from "@gumgum/react-script-tag";
import jwtDecode from "jwt-decode";

interface Params {
  onLogin: (tokenId: string, email: string, domain: string) => void;
}

type AuthResponse = {
  credential: string;
  select_by: string;
};

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    onAuth: (response: AuthResponse) => void;
  }
}

function lastElement<T>(list: Array<T>) {
  return list.slice(-1)[0];
}

export default function LoginGoogleButton({ onLogin }: Params) {
  useEffect(() => {
    window.onAuth = (response: AuthResponse) => {
      // Check payload here https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
      const payload = jwtDecode<{ email: string; hd?: string }>(
        response.credential
      );
      const email = payload.email;
      const hostedDomain = payload.hd;
      const domain = hostedDomain || lastElement<string>(email.split("@"));
      onLogin(response.credential, email, domain);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Script
        src="https://accounts.google.com/gsi/client"
        type="text/javascript"
        async
        defer
      />

      <div
        id="g_id_onload"
        data-client_id={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
        data-context="signup"
        data-ux_mode="popup"
        data-callback="onAuth"
        data-itp_support="true"
        style={{ display: "none" }}
      ></div>

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="continue_with"
        data-size="large"
        data-logo_alignment="left"
        data-width="300"
      ></div>
    </>
  );
}
