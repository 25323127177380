/* eslint-disable no-template-curly-in-string */
import { typePath } from "../CreateOrEdit/context/helpers";
import { Content, LinkFormat } from "@dashboard-v3/api";
import { ActionState, ParsedContent } from "./types";
import { RuleType } from "../types";
import { State } from "../CreateOrEdit/context/types";

export const isAttachment = (ruleType: RuleType) =>
  ruleType === "PROTECT_EMAILS" || ruleType === "REPLACE_ATTACHMENTS";

export const isMoveEmail = (ruleType: RuleType) => ruleType === "MOVE_EMAILS";

export const isEmailType = (val: Content["type"]) => {
  return val === "EML" || val === "MSG" || val === "ORIGINAL";
};

export const containsEmailType = (contents: Content[]) => {
  return contents.some(content => isEmailType(content.type));
};

export const getParsedContent = (content: Content[]): ParsedContent =>
  content.reduce((acc, item) => {
    if (item.type === "EML" || item.type === "MSG") {
      return { ...acc, email: item };
    }
    if (item.type === "ATTACHMENTS") {
      return { ...acc, attachments: item };
    }
    if (item.type === "PDF") {
      return { ...acc, pdf: item };
    }
    return acc;
  }, {});

export const getContentErrors = (selected: Content["type"][] = []) => {
  return new Map([
    ["EMPTY", !selected.length],
    [
      "PROTECT_EMAILS",
      !selected.includes("ATTACHMENTS") || !selected.includes("PDF"),
    ],
    ["REPLACE_ATTACHMENTS", !selected.includes("ATTACHMENTS")],
  ]);
};

export const nameFormatDefaults = (val: Content["type"]) => {
  return val === "ATTACHMENTS"
    ? "{attachments_name}"
    : "{sent_or_received_date_yyyyMMdd_HHmm}-{subject_normalized}";
};

export const getActionState = (state: State): ActionState => {
  const rule = typePath(state.type, state.rule);
  const action: ActionState = { content: null };

  if (state.type === "BLOCK_ATTACHMENTS") return null;

  if ("contentToSave" in rule) {
    action.content = rule.contentToSave.content;
  }
  if ("trash" in rule) {
    action.trash = rule.trash;
  }
  if ("linksFormat" in rule) {
    action.linksFormat = rule.linksFormat;
  }
  return action;
};

export const mapLinkFormat = (
  linkFormat: LinkFormat[]
): "PDF" | "LINK" | "BOTH" => {
  if ((linkFormat || []).length === 0) return "PDF";
  if (linkFormat.length === 1) return linkFormat[0];
  return "BOTH";
};

export const filtersByContentByType = {
  email: (item: Content) => item.type === "EML" || item.type === "MSG",
  attachments: (item: Content) => item.type === "ATTACHMENTS",
  pdf: (item: Content) => item.type === "PDF",
};
