import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { SelectContainer } from "./index";
import { BetweenTimeRange } from "@dashboard-v3/api";

type Props = {
  value: BetweenTimeRange;
  disabled?: boolean;
  onChange: (newValue: BetweenTimeRange) => void;
};

export default function BetweenDates({ value, disabled, onChange }: Props) {
  const { t } = useTranslation("rules");
  return (
    <SelectContainer>
      <TextField
        type="date"
        variant="outlined"
        label={t("common:from")}
        inputProps={{
          "data-testid": "mailbox__from-date_from-textfield",
        }}
        disabled={disabled}
        value={value.since}
        onChange={event => onChange({ ...value, since: event.target.value })}
      />
      <TextField
        type="date"
        variant="outlined"
        label={t("common:to")}
        inputProps={{
          "data-testid": "mailbox__to-date_to-textfield",
        }}
        disabled={disabled}
        value={value.until}
        onChange={event => onChange({ ...value, until: event.target.value })}
      />
    </SelectContainer>
  );
}
