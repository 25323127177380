import { MenuItem, Select } from "@material-ui/core";
import { IncludeFolder } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";

type Props = {
  type: IncludeFolder["type"];
  onChange: (newSelection: IncludeFolder["type"]) => void;
  disabled: boolean;
};
export default function SelectType({ type, onChange, disabled }: Props) {
  const { t } = useTranslation("rules");

  return (
    <Select
      variant="outlined"
      fullWidth
      value={type}
      onChange={event => {
        onChange(event.target.value as IncludeFolder["type"]);
      }}
      disabled={disabled}
    >
      <MenuItem value="BOTH">{t("mailboxFilters.folders.types.BOTH")}</MenuItem>
      <MenuItem value="FOLDER">
        {t("mailboxFilters.folders.types.FOLDER")}
      </MenuItem>
      <MenuItem value="SUBFOLDERS">
        {t("mailboxFilters.folders.types.SUBFOLDER")}
      </MenuItem>
    </Select>
  );
}
