import { TFunction } from "i18next";
import { Step } from "pages/Rules/CreateOrEdit/context/types";
import { Rule } from "pages/Rules/types";

type StepData = {
  step: Step;
  label: string;
};

export default function steps(rule: Rule, t: TFunction): StepData[] {
  const steps: StepData[] = [
    {
      step: "action",
      label: t("action"),
    },
    {
      step: "target",
      label: t("targetStep.label"),
    },
  ];

  if (rule.actionType === "BLOCK_ATTACHMENTS") return steps;

  return [
    ...steps,
    {
      step: "cloudStorage",
      label: t("cloudStorage.header.title"),
    },
    {
      step: "storageFilepath",
      label: t("storageFilepath.header.title"),
    },
  ];
}
