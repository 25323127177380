import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TextFileInput from "./TextFileInput";
import { DataSetFieldContainer, StyledTextField } from "../Styled";
import { CSVFile } from "../types";
import { useState } from "react";
import { Pagination } from "@material-ui/lab";
import { isBlank } from "utils/string";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import InfoSharpIcon from "@material-ui/icons/InfoSharp";
import ButtonWithLoading from "components/ButtonWithLoading";
import ClearIcon from "@material-ui/icons/Clear";
import random from "utils/random";
import { produce } from "immer";
import formatBytes from "utils/formatBytes";

export type KeyValueItem = { id: string; key: string; value: string };

interface KeyValueProps {
  rows: Record<string, string[]>;
  onChange: (rows: Record<string, string[]>, isValid: boolean) => void;
}

const ITEMS_BY_PAGE = 10;
type Error = Record<
  string,
  { key?: "isBlank" | "alreadyExist"; value?: "isBlank" }
>;

type State = {
  items: KeyValueItem[];
  errors: Error;
  search: string;
  page: number;
};

export default function KeyValueList({ rows, onChange }: KeyValueProps) {
  const { t } = useTranslation("datasets");

  const [state, setState] = useState<State>(() => {
    const noRows = Object.keys(rows).length === 0;

    // New item
    if (noRows) {
      const emptyItem = { id: random(), key: "", value: "" };
      return {
        items: [emptyItem],
        errors: { [emptyItem.id]: { key: "isBlank", value: "isBlank" } },
        search: "",
        page: 1,
      };
    }

    // Edit item
    return {
      items: Object.keys(rows).map(
        key =>
          ({
            id: random(),
            key,
            value: rows[key][0],
          } as KeyValueItem)
      ),
      errors: {},
      search: "",
      page: 1,
    };
  });

  const downcaseSearch = (state.search || "").toLowerCase();
  const paginatedItems = chunks(
    filter(state.items, downcaseSearch),
    ITEMS_BY_PAGE
  );

  function setPage(newPage: number) {
    setState(
      produce(draft => {
        draft.page = newPage;
      })
    );
  }

  function updateSearch(search: string) {
    setState(
      produce(draft => {
        draft.search = search;
        draft.page = 1;
      })
    );
  }

  function addRow() {
    const newId = random();
    const updatedState = produce(state, draft => {
      draft.items.unshift({
        id: newId,
        key: "",
        value: "",
      });
      draft.search = "";
      draft.page = 1;
      draft.errors[newId] = { key: "isBlank", value: "isBlank" };
    });

    setState(updatedState);
    onChange(itemsToRows(updatedState.items), false);
  }

  function removeRow(id: string) {
    const updatedState = produce(state, draft => {
      const index = draft.items.findIndex(item => item.id === id);
      // remove from items
      draft.items.splice(index, 1);

      // remove from errors
      delete draft.errors[id];
    });

    setState(updatedState);
    onChange(itemsToRows(updatedState.items), isValid(updatedState.errors));
  }

  function isValid(errors: Error) {
    return Object.keys(errors).length === 0;
  }

  function updateRow(id: string, fieldName: string, fieldValue: string) {
    const updatedState = produce(state, draft => {
      const index = draft.items.findIndex(item => item.id === id);
      // update item field
      draft.items[index][fieldName] = fieldValue;

      const { key, value } = draft.items[index];

      if (draft.errors[id]) delete draft.errors[id];

      if (isBlank(key)) {
        draft.errors[id] = { key: "isBlank" };
      } else {
        const alreadyExist = state.items.find(
          item => item.id !== id && item.key === key
        );
        if (Boolean(alreadyExist)) {
          draft.errors[id] = { key: "alreadyExist" };
        }
      }

      if (isBlank(value)) {
        draft.errors[id] = { ...(draft.errors[id] || {}), value: "isBlank" };
      }
    });

    setState(updatedState);
    onChange(itemsToRows(updatedState.items), isValid(updatedState.errors));
  }

  function getErrors(itemId: string) {
    return state.errors[itemId] || {};
  }

  const handleFileUpload = (file: CSVFile) => {
    const { content, size } = file;
    const COMMA_SEPARATED = /\w+,\w+/g;
    if (isBlank(content) || !COMMA_SEPARATED.test(content)) {
      return t("form.fields.datasetFile.errors.content");
    }
    if (size >= 2097152) {
      return t("form.fields.datasetFile.errors.size", {
        size: formatBytes(size),
      });
    }

    const updatedState = produce(state, draft => {
      content.split("\n").forEach(set => {
        const [key, value] = set.trim().split(",");

        if (isBlank(key)) return;

        const duplicatedIndex = draft.items.findIndex(item => item.key === key);
        if (duplicatedIndex > -1) {
          draft.items.splice(duplicatedIndex, 1);
          // remove from errors
          delete draft.errors[duplicatedIndex];
        }
        const newId = random();

        // prepend
        draft.items.unshift({ id: newId, key, value });

        if (isBlank(value)) {
          draft.errors[newId] = { value: "isBlank" };
        }
      });
    });

    setState(updatedState);
    onChange(itemsToRows(updatedState.items), isValid(updatedState.errors));
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography style={{ fontWeight: "500" }} component="div">
          {t("form.rowsTitle")}
        </Typography>
        <Box
          display="flex"
          gridGap="15px"
          alignItems="flex-start"
          mb="20px"
          mt="10px"
        >
          <Button
            color="primary"
            disableElevation
            size="small"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={addRow}
          >
            {t("form.buttons.addItem")}
          </Button>

          <Tooltip
            title={
              <List dense disablePadding>
                <ListItem disableGutters>
                  {t("form.tooltip.datasetFile.type")}
                </ListItem>
                <ListItem disableGutters>
                  {t("form.tooltip.datasetFile.size")}
                </ListItem>
                <ListItem disableGutters>
                  {t("form.tooltip.datasetFile.format")}
                </ListItem>
                <ListItem disableGutters>
                  {t("form.tooltip.datasetFile.columns")}
                </ListItem>
              </List>
            }
          >
            <span>
              <TextFileInput
                name="datasetFile"
                accept=".csv"
                onFileUpload={handleFileUpload}
                renderAs={({ isUploading, uploadError }) => (
                  <>
                    <ButtonWithLoading
                      data-testid="datasets_form-upload-file-btn"
                      variant="outlined"
                      color="primary"
                      size="small"
                      endIcon={<InfoSharpIcon />}
                      loading={isUploading}
                      onClick={() => {}}
                      component="span"
                    >
                      {t("datasets:uploadFile")}
                    </ButtonWithLoading>
                    {Boolean(uploadError) && (
                      <FormHelperText error>{uploadError}</FormHelperText>
                    )}
                  </>
                )}
              />
            </span>
          </Tooltip>

          {(state.search || paginatedItems.length > 1) && (
            <Box ml="10px">
              <TextField
                size="small"
                placeholder={t("form.search.placeholder")}
                value={state.search}
                onChange={event => updateSearch(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="disabled" fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: Boolean(state.search) && (
                    <IconButton size="small" onClick={() => updateSearch("")}>
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      {paginatedItems[state.page - 1].map(item => {
        return (
          <Item
            key={item.id}
            item={item}
            onChange={(fieldName, fieldValue) =>
              updateRow(item.id, fieldName, fieldValue)
            }
            onRemove={() => removeRow(item.id)}
            error={getErrors(item.id)}
            canDelete={state.items.length !== 1}
          />
        );
      })}

      {paginatedItems.length > 1 && (
        <Pagination
          count={paginatedItems.length}
          page={state.page}
          onChange={(_, newPage) => setPage(newPage)}
          size="small"
        />
      )}
    </>
  );
}

function itemsToRows(items: KeyValueItem[]): Record<string, string[]> {
  return items.reduce((acc, item) => {
    acc[item.key] = [item.value];
    return acc;
  }, {});
}

type ItemParams = {
  item: KeyValueItem;
  onChange: (fieldName: string, fieldValue: string) => void;
  onRemove: () => void;
  canDelete: boolean;
  error?: Error[string];
};

function Item({
  item,
  onChange: emit,
  onRemove,
  canDelete,
  error,
}: ItemParams) {
  const { t } = useTranslation("datasets");

  const errorKeyHint = () => {
    if (error?.key === "isBlank") return t("form.fields.key.errors.empty");
    if (error?.key === "alreadyExist")
      return t("form.fields.key.errors.duplicatedKey");
    return "";
  };

  const errorValueHint =
    error?.value === "isBlank" ? t("form.fields.value.errors.empty") : "";

  return (
    <DataSetFieldContainer key={`${item.id}_item`}>
      <StyledTextField
        inputProps={{ "data-testid": "datasets_form-key-input" }}
        name="key"
        label={t("form.fields.key.label")}
        variant="outlined"
        fullWidth
        value={item.key}
        error={Boolean(error?.key)}
        helperText={errorKeyHint()}
        onChange={event => emit(event.target.name, event.target.value)}
      />
      <StyledTextField
        inputProps={{ "data-testid": "datasets_form-value-input" }}
        name="value"
        label={t("form.fields.value.label")}
        variant="outlined"
        fullWidth
        value={item.value}
        error={Boolean(error?.value)}
        helperText={errorValueHint}
        onChange={event => {
          emit(event.target.name, event.target.value);
        }}
      />
      <IconButton
        size="small"
        aria-label="delete"
        disabled={!canDelete}
        onClick={onRemove}
      >
        <DeleteIcon />
      </IconButton>
    </DataSetFieldContainer>
  );
}

function chunks(items: KeyValueItem[], perChunk: number) {
  return items.reduce(
    (chunks, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = []; // start a new chunk
      }

      chunks[chunkIndex].push(item);

      return chunks;
    },
    [[]] as KeyValueItem[][]
  );
}

function filter(items: KeyValueItem[], filter: string) {
  if (!filter) return items;
  return items.filter(item => item.key.toLowerCase().startsWith(filter));
}
