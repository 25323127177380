import { TimePeriodStat } from "@dashboard-v3/api";
import dayjs from "dayjs";

type TimestampDate = { date: number };

export type StatCode = keyof TimePeriodStat["statsByCode"];

export default function process90Days<Totals>(
  data: Array<TimePeriodStat>,
  iteratee: (totals: Totals, key: StatCode, value: number) => Totals,
  init: () => Totals
): Array<TimestampDate & Totals> {
  const today = dayjs().utc().startOf("day");
  const ts90DaysAgo = today.subtract(90, "days").startOf("day").valueOf();
  const tsToday = today.valueOf();

  const result: Array<TimestampDate & Totals> = [];

  for (
    let tsCurrent = ts90DaysAgo;
    tsCurrent <= tsToday;
    tsCurrent = dayjs(tsCurrent).utc().add(1, "day").valueOf()
  ) {
    const found = data.find(item => item.date === tsCurrent) || {
      statsByCode: {},
    };

    const statsByCode = found?.statsByCode || {};

    const reducedTotals = Object.keys(found?.statsByCode || {}).reduce(
      (totals, key) => iteratee(totals, key as StatCode, statsByCode[key]),
      init()
    );

    result.push({
      date: tsCurrent,
      ...reducedTotals,
    });
  }

  return result;
}
