/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Grid, Typography } from "@material-ui/core";
import { StyledForm } from "components/List/StyledComponents";
import { StyledPaper } from "components/Styled";
import apiRequest, { fetcher } from "utils/apiRequestWithErrorCode";
import { isBlank } from "utils/string";
import KeyValueList from "./KeyValueList";
import { StyledTextField } from "../Styled";
import { Dataset } from "@dashboard-v3/api";
import { DatasetReqBody } from "../types";
import useSWR from "swr";
import { Suspense, useState } from "react";
import * as Sentry from "@sentry/react";
import { getUser } from "utils/authentication";
import ButtonWithLoading from "components/ButtonWithLoading";
import produce from "immer";
import LinearLoader from "components/LinearLoader";

export default function Loader() {
  return (
    <Suspense fallback={<LinearLoader />}>
      <DataSetsForm />
    </Suspense>
  );
}

function DataSetsForm() {
  const { t } = useTranslation("datasets");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id: paramId } = useParams();

  const { data: dataset } = useSWR<Dataset>(
    paramId ? `/datasets/${paramId}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      suspense: true,
      onError() {
        enqueueSnackbar(t("form.errors.fetchByIdError"), {
          variant: "error",
        });
        history.push("/datasets");
      },
    }
  );

  const [isSaving, setIsSaving] = useState(false);
  const [errorInRows, setErrorInRows] = useState(false);
  const [form, setForm] = useState<DatasetReqBody>(
    toForm(dataset) || { name: "", rows: {}, id: null }
  );

  const isInvalidName = isBlank(form.name);
  const nameErrorHint = isInvalidName ? t("form.fields.name.errors.empty") : "";
  const isValid = !errorInRows && !isInvalidName;

  const saveDataset = async event => {
    event.preventDefault();
    if (!isValid) return false;
    try {
      setIsSaving(true);
      if (!form.id) {
        await apiRequest<Dataset>("POST", "/datasets", form);
      } else {
        await apiRequest<Dataset>("PUT", `/datasets/${form.id}`, form);
      }
      const successMsg = form.id ? "updateSuccess" : "createSuccess";
      enqueueSnackbar(t(successMsg, { name: form.name }), {
        variant: "success",
      });
      history.push("/datasets");
    } catch (e) {
      const errorMsg = form.id ? "updateError" : "createError";
      enqueueSnackbar(t(`errors.${errorMsg}`), {
        variant: "error",
      });

      Sentry.captureException(e, { user: getUser(), extra: { changes: form } });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Grid container spacing={5}>
      <Grid item lg={8} xs={12}>
        <StyledPaper>
          <StyledForm onSubmit={saveDataset}>
            <Typography variant="h6">{t("form.title")}</Typography>

            <StyledTextField
              inputProps={{ "data-testid": "datasets_form-name-input" }}
              name="name"
              variant="outlined"
              fullWidth
              label={t("form.fields.name.label")}
              value={form.name}
              error={isInvalidName}
              helperText={nameErrorHint}
              onChange={event => {
                const newName = event.target.value;
                setForm(
                  produce(draft => {
                    draft.name = newName;
                  })
                );
              }}
            />
            <KeyValueList
              rows={form.rows}
              onChange={(rows, areRowValids) => {
                setForm(
                  produce(draft => {
                    draft.rows = rows;
                  })
                );
                setErrorInRows(!areRowValids);
              }}
            />
            <ButtonWithLoading
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              disableElevation
              fullWidth
              data-testid={`datasets_form-save-btn`}
              style={{ marginTop: "20px" }}
              disabled={!isValid}
              loading={isSaving}
            >
              {t("saveDataset")}
            </ButtonWithLoading>
          </StyledForm>
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

function toForm(dataset?: Dataset): DatasetReqBody {
  if (!dataset) return null;

  return {
    id: dataset.id,
    name: dataset.name,
    rows: dataset.rows || {},
  };
}
