import { Location, Storage } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";
import useVariables from "utils/useVariables";
import { FolderType } from "./FilepathSelector/types";
import { getCurrentPathFromLocation, isValidPath } from "./utils";

export default function useLocationTitle(location?: Storage["location"]) {
  const { t } = useTranslation("rules");
  const { t: componentsI18n } = useTranslation("components");
  const { variableOptions, mapVarToLabel } = useVariables();
  const currentPath = getCurrentPathFromLocation(location);
  const locationType: "PATH" | "COPY_POLICY" = getLocationType(location);
  const { isEgnyteParentId, spaceTranslation } = getEgnyteSpacePath(location);

  if (locationType === "PATH") {
    if (location?.parentId === FolderType.SOURCE_FOLDER) {
      let text = `{${componentsI18n("storageFilepath.pickSameStorage")}}`;
      if (currentPath) {
        const label = mapVarToLabel(currentPath, variableOptions);
        text = `${text}${label.startsWith("/") ? label : `/${label}`}`;
      }
      return text;
    }

    if (!isValidPath(currentPath)) return t("storageFilepath.header.default");

    const label = mapVarToLabel(currentPath, variableOptions);

    if (isEgnyteParentId) return `${t(spaceTranslation)}/${label}`;

    if (location.parentId) return label;

    return t("storageFilepath.header.path", {
      path: label.startsWith("/") ? label : `/${label}`,
      interpolation: { escapeValue: false },
    });
  }

  if (locationType === "COPY_POLICY") {
    return "";
  }

  return t("storageFilepath.header.default");
}

function getLocationType(location?: Location) {
  if (!location) return null;
  if (location.copyPolicy) return "COPY_POLICY";
  return "PATH";
}

function getEgnyteSpacePath(location: Storage["location"]) {
  const isShared = location?.parentId === "shared";
  const isPrivate = location?.parentId === "private/{egnyte_user_name}";

  return {
    isEgnyteParentId: isShared || isPrivate,
    spaceTranslation: isShared
      ? "storageFilepath.egnyteSpace.shared"
      : "storageFilepath.egnyteSpace.private",
  };
}
