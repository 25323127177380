import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  > div:first-child {
    flex-grow: 3;
    padding: 5px 10px 5px 20px;
    border-radius: 4px;
    background-color: rgb(224, 231, 239);
    margin-right: 10px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: initial;
      -webkit-box-shadow: 0 0 0 30px rgb(224, 231, 239) inset !important;
    }
    svg {
      font-size: 18px;
    }
  }
  > button {
    padding: 8px;
  }
`;
