/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectField from "components/Forms/SelectField";
import { Button } from "components/Forms/StyledComponents";
import { OptionTitle } from "components/Styled";
import PageLoading from "components/PageLoading";
import { StyledGrid, StyledIconButton, TitleContainer } from "../../Styled";
import { MappingProps, SelectedKeys } from "../../types";
import useVariables from "utils/useVariables";
import MappingValue from "./MappingValue";

import { TemplateField } from "@dashboard-v3/api";

const Mappings = ({ template, formState, setValues }: MappingProps) => {
  const { t } = useTranslation("templateDefinitions");
  const { loading } = useVariables();
  const [selectedKeys, setSelectedKeys] = useState<SelectedKeys>({});
  const [templateFields, setTemplateFields] = useState<TemplateField[]>([]);
  const { mappings } = formState;

  useEffect(() => {
    setTemplateFields(template.fields);
    setSelectedKeys({});
  }, [template]);

  useEffect(() => {
    setSelectedKeys(() =>
      mappings.reduce((acc, mapping, i) => {
        return { ...acc, [i]: mapping.key };
      }, {})
    );
  }, [mappings]);

  const addMapping = () => {
    setValues({ mappings: [...mappings, { key: "", value: "" }] });
  };

  const removeMapping =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (mappings.length > 1) {
        setValues({ mappings: mappings.filter((_, i) => i !== index) });
      }
    };

  const getKeyOptions = (index: number) => {
    const selected = { ...selectedKeys };
    delete selected[index];
    const removedItemsList = Object.values(selected);
    return templateFields.filter(field => {
      return !removedItemsList.includes(field.key);
    });
  };

  const getCurrentKey = (index: number) => {
    const selected = selectedKeys[index];
    return selected ? selected : "";
  };

  const getCurrentField = (key: string) =>
    templateFields.find(f => f.key === key);

  const handleOnChange = (index: number) => e => {
    const { name, value } = e.target;
    let update = [];
    if (name === "key") {
      update = mappings.map((item, i) => {
        return i === index ? { key: value, value: "" } : item;
      });
    } else {
      update = mappings.map((item, i) => {
        return i === index ? { ...item, [name]: value } : item;
      });
    }

    setValues({ mappings: update });
  };

  return (
    <>
      <PageLoading loading={loading} />
      <TitleContainer>
        <OptionTitle variant="subtitle1" color="textPrimary">
          {t("form.mappings.title")}
        </OptionTitle>
        <Button
          color="primary"
          variant="text"
          size="large"
          wording={t("form.mappings.addBtn")}
          onClick={addMapping}
          disabled={mappings.length === template.fields.length}
        />
      </TitleContainer>
      <StyledGrid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {mappings.map(({ value, key }, i: number) => {
          const currentKey = getCurrentKey(i);
          const currentField = getCurrentField(key);
          return (
            <React.Fragment key={key}>
              <Grid item xs={4}>
                <SelectField
                  name="key"
                  label={t("form.mappings.key.label")}
                  options={getKeyOptions(i)}
                  value={currentKey}
                  onChange={handleOnChange(i)}
                  required
                  renderOption={({ key: fieldKey, name }) => (
                    <MenuItem key={fieldKey} value={fieldKey}>
                      {name}
                    </MenuItem>
                  )}
                />
              </Grid>
              <Grid item xs={7}>
                <MappingValue
                  field={currentField}
                  value={value}
                  onChange={handleOnChange(i)}
                  disabled={!currentKey}
                />
              </Grid>
              <Grid item>
                <StyledIconButton
                  hidden={mappings.length === 1}
                  onClick={removeMapping(i)}
                >
                  <DeleteIcon fontSize="small" />
                </StyledIconButton>
              </Grid>
            </React.Fragment>
          );
        })}
      </StyledGrid>
    </>
  );
};

export default Mappings;
