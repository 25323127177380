import styled from "styled-components";
import { FormHelperText } from "@material-ui/core";

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  div:nth-child(1) {
    flex-grow: 1;
  }
`;

export const ErrorHelper = styled(FormHelperText)`
  position: absolute;
  top: 98%;
  font-size: 11px;
  left: 1%;
  padding: 0;
`;

export const mentionsStyles = {
  control: {
    backgroundColor: "#fff",
    fontSize: "1rem",
    minHeight: "56px",
  },
  highlighter: {
    position: "absolute",
  },

  input: {
    position: "relative",
    padding: "18.5px 14px",
    borderRadius: "4px",
    lineHeight: "1",
    resize: "vertical",
    height: "56px",
    minHeight: "56px",
    borderColor: "lightgrey",
    overflow: "scroll",
    outlineColor: "#009ed2",
  },
  suggestions: {
    top: "87%",
    width: "100%",
    zIndex: "2",
    list: {
      maxHeight: "400px",
      overflow: "scroll",
      borderRadius: "4px",
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      padding: "5px",
    },
    item: {
      padding: "5px 8px",
      margin: "3px",
      borderRadius: "4px",
      "&focused": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
};
