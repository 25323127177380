import React from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import SectionHeader from "components/SectionHeader";
import { Button, Link, Typography } from "@material-ui/core";
import List from "./List";
import Form from "./Form";

export default function SecurityPolicies() {
  const { t } = useTranslation("securityPolicies");
  const { pathname } = useLocation();
  const history = useHistory();
  const isOnForm = pathname.includes("/new") || pathname.includes("/edit");

  const handleRouting = () => {
    return history.push(
      isOnForm ? "/security-policies" : "/security-policies/new"
    );
  };

  const renderButtonText = () => {
    return isOnForm ? t("common:backToList") : t("common:create");
  };

  return (
    <>
      <SectionHeader
        title={t("title")}
        sideBtn={
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={handleRouting}
          >
            {renderButtonText()}
          </Button>
        }
      >
        <Typography>
          <Trans
            i18nKey="pageDescription"
            t={t}
            components={{ lnk: <Link href="/security-definitions" /> }}
          />
        </Typography>
      </SectionHeader>

      <Switch>
        <Route path="/security-policies" exact>
          <List />
        </Route>
        <Route path="/security-policies/new" exact>
          <Form />
        </Route>
        <Route path="/security-policies/edit/:id" exact>
          <Form />
        </Route>
      </Switch>
    </>
  );
}
