import { Draft } from "immer";
import { isValidImapConfig } from "pages/Rules/CreateOrEdit/Steps/Target/Mailbox/utils";
import isEmail from "utils/isEmail";
import { isBlank } from "utils/string";
import validateTargetFilter from "../validateFilters/target";
import {
  getArchivingAddress,
  getEmailFlow,
  getMailbox,
  getStorageWebhook,
} from "../../helpers";
import { State } from "../../types";

export default function validate(draft: Draft<State>) {
  const target = draft.rule.targetType;

  if (target === "ARCHIVING_ADDRESS") return validateArchivingAddress(draft);

  if (target === "EMAIL_FLOW") {
    validateEmailFlow(draft);
    validateTargetFilter(draft);
  }

  if (target === "MAIL_BOX") {
    draft.steps.target.valid = isValidateMailBox(draft);
  }

  if (target === "STORAGE_WEBHOOK") {
    const isValid = isValidWebhook(draft);
    draft.steps.target.valid = isValid;
    draft.steps.cloudStorage.valid = isValid;
  }
}

function validateArchivingAddress(draft: Draft<State>) {
  const { emailAddress } = getArchivingAddress(draft.rule);
  const isValidEmail = isEmail(emailAddress);

  draft.steps.target.valid = isValidEmail;
}

function validateEmailFlow(draft: Draft<State>) {
  const emailFlow = getEmailFlow(draft.rule);
  const { fromDirection, toDirection } = emailFlow.fromTo;
  const isValid = Boolean(fromDirection && toDirection);
  draft.steps.target.valid = isValid;
}

function isValidateMailBox(draft: Draft<State>) {
  const { provider, accounts, notifications, imapServer, postProcessing } =
    getMailbox(draft.rule);

  if (!provider) return false;
  if (!accounts) return false;

  if (accounts.type !== "ORGANIZATION" && !accounts.entities?.length)
    return false;

  if (
    (notifications.onError || notifications.onDone) &&
    !isEmail(notifications.email)
  ) {
    return false;
  }

  if (provider === "IMAP") {
    if (!imapServer) return false;
    return isValidImapConfig(imapServer);
  }

  // Field present in "Advanced options"
  if (postProcessing?.markProcessed) {
    return !isBlank(postProcessing?.markProcessedText);
  }

  return true;
}

function isValidWebhook(draft: Draft<State>) {
  const webhook = getStorageWebhook(draft.rule);
  const { isValid } = draft.webhookStatus;
  return isValid && webhook.account && !!webhook.folders.length;
}
