import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionHeader from "components/SectionHeader";
import ComponentList from "components/ComponentList";
import { Button, Typography } from "@material-ui/core";
import { DefinitionsContainer } from "components/List/StyledComponents";
import SecurityDefinitionForm from "./SecurityDefinitionForm";
import SecurityDefinitionItem from "./SecurityDefinitionItem";
import useSecurityDefinitions from "utils/useSecurityDefinitions";
import { ShowMoreBtn } from "components/List";
import { Skeleton } from "@material-ui/lab";
import { SecurityDefinition } from "@dashboard-v3/api";

const itemsByPage = 10;

export default function SecurityDefinitions() {
  const { t } = useTranslation("securityDefinitions");
  const [loading, setLoading] = useState<"ALL" | "MORE" | "ITEM">();
  const [loadingItem, setLoadingItem] = useState<string>();
  const { securityDefinitions, fetchDefinitions, hasMore, loadMore } =
    useSecurityDefinitions();
  const [selectedDefinition, setSelectedDefinition] =
    useState<SecurityDefinition>();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (id: string) => {
    const item = securityDefinitions.find(item => item.id === id);
    setSelectedDefinition(item);
    setShowForm(true);
  };

  const onLoadMore = async () => {
    setLoading("MORE");
    await loadMore(itemsByPage);
    setLoading(null);
  };

  async function initialFetch() {
    setLoading("ALL");
    await fetchDefinitions(itemsByPage);
    setLoading(null);
  }

  return (
    <DefinitionsContainer data-testid="security-defs-page">
      <SectionHeader
        title={t("sidebar:securityDefinitions")}
        testId="security-defs-header"
        sideBtn={
          <Button
            color="primary"
            data-testid="security-defs-create-btn"
            disableElevation
            variant="contained"
            onClick={() => setShowForm(true)}
          >
            {t("common:create")}
          </Button>
        }
      >
        <Typography>{t("description")}</Typography>
      </SectionHeader>
      <ComponentList
        list={securityDefinitions}
        loading={loading === "ALL"}
        emptyMsg={t("emptySecurityDefinitionsList")}
        testId="security-defs-list"
        renderItems={def =>
          loadingItem === def.id ? (
            <Skeleton
              key={def.id}
              height="4rem"
              animation="wave"
              variant="rect"
            />
          ) : (
            <SecurityDefinitionItem
              key={def.id}
              content={def}
              setLoading={setLoading}
              setLoadingItem={setLoadingItem}
              refreshList={initialFetch}
              onClickEdit={onEdit}
            />
          )
        }
      />
      {loading !== "ALL" && hasMore && (
        <ShowMoreBtn loading={loading === "MORE"} onClick={onLoadMore} />
      )}

      {showForm && (
        <SecurityDefinitionForm
          setLoading={setLoading}
          refreshList={initialFetch}
          securityDefinition={selectedDefinition}
          onClose={() => {
            setSelectedDefinition(null);
            setShowForm(false);
          }}
        />
      )}
    </DefinitionsContainer>
  );
}
