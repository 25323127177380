import { getContentToSave } from "pages/Rules/CreateOrEdit/context/helpers";
import { State } from "pages/Rules/CreateOrEdit/context/types";
import { Rule } from "pages/Rules/types";
import { getStorage } from "../utils";

export default function canLinkToFolder(state: State): boolean {
  const { rule } = state;

  return (
    isEmailFlow(rule) &&
    isExpectedAction(rule) &&
    saveAttachments(rule) &&
    hasLinkSecurity(rule)
  );
}

function isEmailFlow(rule: Rule) {
  return rule.targetType === "EMAIL_FLOW";
}

function isExpectedAction(rule: Rule) {
  if (rule.actionType === "REPLACE_ATTACHMENTS") return true;
  if (rule.actionType === "PROTECT_EMAILS") return true;
  return false;
}

function saveAttachments(rule: Rule) {
  const contentToSave = getContentToSave(rule);
  return contentToSave.content.some(content => content.type === "ATTACHMENTS");
}

function hasLinkSecurity(rule: Rule): boolean {
  const storage = getStorage(rule);
  return Boolean(storage.linkSecurity);
}
