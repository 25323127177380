import { useTranslation } from "react-i18next";
import { StyledListItemText, useStyles } from "./Styled";
import { Link, ListItem } from "@material-ui/core";

export default function CustomersReportLink() {
  const { t } = useTranslation("sidebar");
  const classes = useStyles();
  const label = t("customersReport");
  const href = process.env.REACT_APP_CUSTOMERS_REPORT_URL;
  const ItemTextStyles = {
    color: "#5f6061",
    fontWeight: "500",
  };

  return (
    <ListItem
      dense
      button
      className={classes.nestedSecond}
      component={Link}
      href={href}
      target="_blank"
      data-testid={`menuItem ${label}`}
    >
      <StyledListItemText
        primary={label}
        primaryTypographyProps={{ style: ItemTextStyles }}
      />
    </ListItem>
  );
}
