import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "16px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 10,
  },
  disabledInput: { maxWidth: "35rem" },
  enabledInput: { maxWidth: "25rem", display: "block" },
});
