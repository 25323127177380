import produce from "immer";
import { AccountManagement } from "pages/Rules/CloudStorage/utils";
import { State } from "../types";
import validateStep from "./validateStep";

export type UpdateStorageManagement = {
  type: "updateStorageManagement";
  payload: State["storageManagement"];
};

/*
Fields:

+ userId: string -> Dropbox always set it (dbtid:xxx) because is individual. The rest use it to impersonate
+ asUser: string -> Use by Dropbox to use another user (always with userId)
+ userIds: Array -> Fill by BOX, DRIVE, ONEDRIVE when use account balance
+ asUsers: Array -> Fill by Dropbox when use account balance (always with userId)

# When select AUTO:
  + DROPBOX: fill only "userId"
  + BOX, DRIVE, ONEDRIVE: Nothing

# When select MANUAL:
  + DROPBOX: fill "userId" and "asUser" wiht the email filled in the input
  + BOX, DRIVE, ONEDRIVE: fill "userId"

# When select BALANCED:
  + DROPBOX: fill "userId" and the array "asUsers"
  + BOX, DRIVE, ONEDRIVE: fill the array "userIds"
*/

export const updateStorageManagement = (
  state: State,
  payload: AccountManagement
) => {
  return produce(state, draft => {
    if (payload === null) {
      delete draft.storageManagement;
    } else {
      draft.storageManagement = payload;
    }

    validateStep("cloudStorage", draft);
  });
};
