import {
  CheckAccessResponse,
  FromTo,
  Mailbox,
  WebhookCheckResponse,
} from "@dashboard-v3/api";
import { AccountManagement } from "pages/Rules/CloudStorage/utils";
import { Option } from "pages/Rules/FromToSelector/types";
import { Rule, RuleType } from "pages/Rules/types";

export interface State {
  asyncStatus:
    | "idle"
    | "saving"
    | "validate_archiving_address"
    | "validate_email_flow"
    | "validate_mailbox_imap"
    | "validate_storage_access"
    | "validate_filename_format";

  type: RuleType | null;
  rule: Rule | null;
  currentStep: Step;
  steps: Record<Step, StepState>;
  filters: Record<Filter, FilterState>;
  canSave: boolean;
  storageManagement?: AccountManagement | null;
  webhookStatus: {
    isValid?: boolean;
    foldersInUse?: WebhookCheckResponse["foldersInUse"];
    missingFolder?: string;
  };
  webhookProcessing?: {
    hasChanged: boolean;
    currentValue: boolean;
  };
  /** remaining to enable save */
  remaining: ("steps" | "name")[];
  /** Track async validations */
  checks: {
    archivingAddress?: {
      isValidEmail: boolean;
      testedEmail: string | null;
      error?: "IN_USE" | "INVALID" | "FETCH_ERROR";
      // Store the original email use when edit a rule
      initialAddress?: string;
    };
    emailFlow?: {
      isValid: boolean;
      isUnmanaged: boolean;
      error?: "IN_USE" | "INVALID" | "FETCH_ERROR" | "UNMANAGED_FLOW";
      tested?: {
        bidirectional: boolean;
        fromTo: FromTo;
      };
      initialValue?: {
        bidirectional: boolean;
        fromTo: FromTo;
      };
      // Use in canTrackEmails
      fromOption?: Option;
    };
    mailboxImap?: {
      isValid: boolean;
      tested?: Mailbox["imapServer"];
      error?: "INVALID" | "FETCH_ERROR";
      initialValue?: Mailbox["imapServer"];
    };
    storageAccess?: {
      isValid: boolean;
      errors?: CheckAccessResponse["errors"];
    };
    storageFilename?: {
      isValid: boolean;
      fieldsWithError?: Array<string>;
      fieldsToCheck?: Array<{ field: string; value: string }>;
    };
  };
}

export const stepOrderer = [
  "action",
  "target",
  "cloudStorage",
  "storageFilepath",
  "moreActions",
] as const;

export type Step = (typeof stepOrderer)[number];

type StepState = {
  done: boolean;
  expanded: boolean;
  valid: boolean;
  next: Step | null;
};

export type Filter = "actionFilter" | "targetFilter" | "targetFolders";

type FilterState = {
  valid: boolean;
  errors: {
    exclusions?: { [key: number]: string };
    inclusions?: { [key: number]: string };
  };
};
