import { DKIMRecord, VerifyDKIMResponse } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";

export async function getDkimRecords() {
  return apiRequest<DKIMRecord[]>("GET", "/dkim");
}

export async function generateDkim(domain: string) {
  return apiRequest<DKIMRecord>("POST", "/dkim", { domain });
}

export async function verifyDns(id: string) {
  return apiRequest<VerifyDKIMResponse>("PATCH", `/dkim/${id}/verify`, {});
}

export async function removeDkim(id: string) {
  return apiRequest("DELETE", `/dkim/${id}`);
}
