import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import ImportAccountsModal from "./ImportAccountsModal";

export default function BulkAddButton() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("accounts");

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      <Button
        color="primary"
        data-testid="accounts__add-bulk-btn"
        disableElevation
        onClick={openModal}
        style={{ marginLeft: "12px" }}
        variant="contained"
      >
        {t("bulk.add")}
      </Button>
      {open && <ImportAccountsModal isOpen={open} onClose={closeModal} />}
    </>
  );
}
