/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import produce from "immer";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChipInput from "material-ui-chip-input";
import ButtonWithLoading from "components/ButtonWithLoading";
import SwitchField from "components/Forms/SwitchField";
import WithIconHelper from "components/WithIconHelper";
import { isBlank } from "utils/string";
import { getErrorDescription } from "utils/apiRequestWithErrorCode";
import { useSourcesContext } from "../sourcesProvider";
import { createSource, updateSource } from "../api";
import { AdvancedFiltersAccordion, FiltersHelpLink } from "../Styled";

import { Office365Settings } from "../types";

interface Props {
  isOpen: boolean;
  isAuthorized: boolean;
  id?: string | null;
  settings?: Office365Settings;
  onClose: () => void;
}

enum Status {
  LOADING = "LOADING",
  ERROR = "ERROR",
  ADD_OR_EDIT = "ADD_OR_EDIT",
}

export default function Office365SourceDialog({
  isOpen,
  onClose,
  isAuthorized,
  settings,
  id,
}: Props) {
  const { t, actions } = useSourcesContext();
  const [form, setForm] = useState<Office365Settings>(initForm(settings));
  const [status, setStatus] = useState<Status>();
  const [errorMsg, setErrorMsg] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      setStatus(() => (isAuthorized ? Status.ADD_OR_EDIT : Status.LOADING));
    }
  }, [isOpen, isAuthorized]);

  async function handleSubmit() {
    setErrorMsg(null);
    try {
      setStatus(Status.LOADING);
      if (id) {
        const source = await updateSource("OFFICE365", id, form);
        actions.updateSource(source);
      } else {
        const source = await createSource("OFFICE365", form);
        actions.addSource(source);
      }
      onClose();
    } catch (error) {
      const description = getErrorDescription(error);
      setStatus(Status.ERROR);

      if (isSourceFilterError(description as string)) {
        setErrorMsg(t(`error.testSource.${description}`));
      } else {
        setErrorMsg(t("error.unknownError"));
      }
    }
  }

  const handleOnChange = e => {
    const { name, value } = e.target;
    setForm(prev =>
      produce(prev, draft => {
        if (isBlank(value)) {
          delete draft[name];
        } else {
          draft[name] = value;
        }
      })
    );
  };

  const handleExcludeDomains =
    (action: "add" | "delete") => (domain: string) => {
      setForm(prev => {
        let update = [...prev.excludeDomains];
        if (action === "add") {
          update.push(domain);
        }
        if (action === "delete") {
          update = update.filter(d => d !== domain);
        }
        return {
          ...prev,
          excludeDomains: update,
        };
      });
    };

  const handleFilterByGroup = e => {
    const { checked: filterByGroup } = e.target;
    setForm(prev => ({ ...prev, filterByGroup }));
  };

  const getDialogTitle = () => {
    return id
      ? t("sources.editTypeOfSource", { type: t("sourceTypes.OFFICE365") })
      : t("sources.addTypeOfSource", { type: t("sourceTypes.OFFICE365") });
  };

  return (
    <Dialog
      open={isOpen}
      onClick={e => e.stopPropagation()}
      onClose={onClose}
      fullWidth={status !== Status.LOADING}
    >
      <DialogTitle style={{ paddingBottom: "0" }}>
        {getDialogTitle()}
      </DialogTitle>

      {status === Status.LOADING && (
        <DialogContent style={{ marginBottom: "2rem", textAlign: "center" }}>
          <CircularProgress />
        </DialogContent>
      )}

      {status === Status.ERROR && (
        <>
          <DialogContent style={{ textAlign: "center" }}>
            {t("sources.createOfficeFail", { details: errorMsg })}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t("common:ok")}</Button>
          </DialogActions>
        </>
      )}

      {status === Status.ADD_OR_EDIT && (
        <>
          <DialogContent style={{ marginBottom: "25px", overflowY: "unset" }}>
            <AdvancedFiltersAccordion {...(id && { expanded: true })}>
              <AccordionSummary expandIcon={!id && <ExpandMore />}>
                <Box>
                  <Typography variant="subtitle2">
                    {t("sources.advancedFilters.title")}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {t("sources.advancedFilters.description")}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  name="usersFilterQuery"
                  label={t("sources.usersFilterQuery.label")}
                  margin="dense"
                  fullWidth
                  multiline
                  value={form.usersFilterQuery}
                  onChange={handleOnChange}
                  helperText={
                    <>
                      <Typography variant="caption">
                        Ex. “endswith(mail,'@contoso.com')”{" "}
                      </Typography>
                      <FiltersHelpLink
                        target="_blank"
                        href="https://learn.microsoft.com/en-us/graph/api/user-list?view=graph-rest-1.0&tabs=http"
                      >
                        {t("sources.usersFilterQuery.helper")}
                        <OpenInNewIcon />
                      </FiltersHelpLink>
                    </>
                  }
                />
                <TextField
                  name="groupsFilterQuery"
                  label={t("sources.groupsFilterQuery.label")}
                  margin="dense"
                  fullWidth
                  multiline
                  value={form.groupsFilterQuery}
                  onChange={handleOnChange}
                  helperText={
                    <>
                      <Typography variant="caption">
                        Ex. “startswith(displayName, 'Marketing')“{" "}
                      </Typography>
                      <FiltersHelpLink
                        target="_blank"
                        href="https://learn.microsoft.com/en-us/graph/api/group-list?view=graph-rest-1.0&tabs=http"
                      >
                        {t("sources.groupsFilterQuery.helper")}
                        <OpenInNewIcon />
                      </FiltersHelpLink>
                    </>
                  }
                />
                <SwitchField
                  labelProps={{ variant: "body2" }}
                  switchProps={{ size: "small" }}
                  name="filterByGroup"
                  label={t("sources.filterByGroup.label")}
                  value={form.filterByGroup}
                  onChange={handleFilterByGroup}
                  disabled={isBlank(form.groupsFilterQuery)}
                />
                <WithIconHelper
                  position="after"
                  text={t("common:chipInputHelper")}
                  icon={<InfoOutlinedIcon fontSize="small" color="primary" />}
                  buttonStyles={{ marginLeft: "10px" }}
                >
                  <ChipInput
                    label={t("sources.excludeDomains.label")}
                    fullWidth
                    allowDuplicates={false}
                    value={form.excludeDomains}
                    onAdd={handleExcludeDomains("add")}
                    onDelete={handleExcludeDomains("delete")}
                    helperText={
                      <Typography
                        variant="caption"
                        style={{ position: "relative", top: "4px" }}
                      >
                        {t("sources.excludeDomains.description")}
                      </Typography>
                    }
                  />
                </WithIconHelper>
              </AccordionDetails>
            </AdvancedFiltersAccordion>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              data-testid="accounts__source-cancel-edit"
              onClick={onClose}
            >
              {t("common:cancel")}
            </Button>
            <ButtonWithLoading
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              data-testid="accounts__source-save-edit"
              onClick={handleSubmit}
              loading={false}
            >
              {id ? t("buttons.saveSource") : t("buttons.addSource")}
            </ButtonWithLoading>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

const initForm = (settings: Office365Settings) => () => {
  const {
    excludeDomains = [],
    groupsFilterQuery,
    usersFilterQuery,
    filterByGroup,
  } = settings || {};

  return {
    excludeDomains,
    filterByGroup: filterByGroup || false,
    ...(groupsFilterQuery && { groupsFilterQuery }),
    ...(usersFilterQuery && { usersFilterQuery }),
  };
};

const isSourceFilterError = (errorDescription: string) => {
  return (
    errorDescription === "ERROR_GROUPS_FILTER" ||
    errorDescription === "ERROR_USERS_FILTER"
  );
};
