/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "components/ButtonWithLoading";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { validatePassword } from "@dashboard-v3/shared";
import useValidation from "utils/useValidation";
import { isBlank } from "utils/string";
import isEmail from "utils/isEmail";
import { StyledTextField } from "./Styled";
import PasswordSection from "./PasswordSection";
import { FormFields, getLoginOptions, isPasswordExclusive } from "./utils";

import type { LoginOptionNames, Users } from "@dashboard-v3/api";

export type UserSaveProps = {
  id?: string;
  email: string;
  name?: string;
  password?: string;
};

export type UserFormProps = {
  isOpen: boolean;
  user?: Users.Item;
  onClose: () => void;
  onSave: (user: UserSaveProps) => Promise<void>;
};

export default function UserFormModal({
  isOpen,
  onClose,
  onSave,
  user = null,
}: UserFormProps) {
  const { t } = useTranslation("users");
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState<FormFields>(initialValues(user));
  const [loginOptions, setLoginOptions] = useState<LoginOptionNames[]>([]);
  const [loading, setLoading] = useState(false);
  const { errors, validate, clearErrors, validateAll } = useValidation(fields);
  const isEditing = !!user;

  useEffect(() => {
    const fetchLoginOptions = async () => {
      try {
        const options = await getLoginOptions();
        setLoginOptions(options);
      } catch (e) {
        enqueueSnackbar(t("error.loginOptions.fetch"), { variant: "error" });
        onClose();
      }
    };
    fetchLoginOptions();
  }, []);

  const handleOnChange = e => {
    const { name, value, checked } = e.target;
    clearErrors(name);
    setFields(prev => ({
      ...prev,
      [name]: name === "enablePassword" ? checked : value,
      isSubmitting: false,
    }));
  };

  const handleOnBlur = e => {
    const { name, value } = e.target;

    if (formValidations[name] && !errors[name]) {
      validate(name, formValidations[name]);
    }

    setFields(prev => ({ ...prev, [name]: value.trim() }));
  };

  const formValidations = {
    name: value => {
      const field = t("name");
      if (isBlank(value)) {
        return t("error.common.required", { field });
      }
      if (value.length < 2) {
        return t("error.common.length", { field, length: "2" });
      }
    },
    email: value => {
      if (isBlank(value)) {
        return t("error.common.required", { field: t("common:email") });
      }
      if (!isEmail(value)) {
        return t("error.email.format");
      }
    },
    confirmPassword: value => {
      if (isBlank(value)) {
        return t("error.common.required", { field: t("confirmPassword") });
      }
      if (value !== fields.password) {
        return t("error.confirmPassword.match");
      }
    },
  };

  const saveEdited = async () => {
    setFields(prev => ({ ...prev, isSubmitting: true }));
    const { name, password } = fields;
    const changes: UserSaveProps = {
      id: user.id,
      name,
      email: user?.email,
      ...(password && { password }),
    };
    let isValid = false;

    if (fields.enablePassword) {
      const [isValidPassword] = validatePassword(password);
      const fieldsValidation = validateAll(formValidations, [
        "name",
        "email",
        "confirmPassword",
      ]);
      isValid = isValidPassword && fieldsValidation;
    } else {
      isValid = validateAll(formValidations, ["name"]);
    }

    if (isValid) {
      setLoading(true);
      try {
        await onSave(changes);
        onClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const saveNew = async () => {
    setFields(prev => ({ ...prev, isSubmitting: true }));
    const { name, email, password } = fields;
    let isValid = false;

    if (isPasswordExclusive(loginOptions) || fields.enablePassword) {
      const [isValidPassword] = validatePassword(password);
      const fieldsValidation = validateAll(formValidations, [
        "name",
        "email",
        "confirmPassword",
      ]);

      isValid = isValidPassword && fieldsValidation;
    } else {
      isValid = validateAll(formValidations, ["name", "email"]);
    }

    if (isValid) {
      setLoading(true);
      try {
        await onSave({ name, email, password });
        onClose();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    if (isEditing) {
      await saveEdited();
    } else {
      await saveNew();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onKeyDown={e => e.stopPropagation()}
      fullWidth
    >
      <DialogTitle>{isEditing ? t("editUser") : t("addUser")}</DialogTitle>
      <form onSubmit={handleFormSubmit}>
        <DialogContent style={{ paddingTop: "8px" }}>
          <StyledTextField
            variant="outlined"
            fullWidth
            name="name"
            label={t("name")}
            error={!!errors.name}
            helperText={errors.name}
            value={fields.name}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            inputProps={{
              "data-testid": "user-name-input",
            }}
          />
          <StyledTextField
            variant="outlined"
            fullWidth
            name="email"
            label={t("common:email")}
            error={!!errors.email}
            helperText={errors.email}
            value={fields.email}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={!!isEditing}
            inputProps={{
              "data-testid": "user-email-input",
            }}
          />
          {loginOptions.includes("PASSWORD") && (
            <PasswordSection
              user={user}
              loginOptions={loginOptions}
              fields={fields}
              errors={errors}
              isSubmitting={fields.isSubmitting}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            data-testid="users__cancel-user-add"
            disableElevation
            onClick={onClose}
            disabled={loading}
          >
            {t("common:cancel")}
          </Button>
          <ButtonWithLoading
            data-testid={isEditing ? "save-user-btn" : "add-user-btn"}
            color="primary"
            disableElevation
            type="submit"
            variant="contained"
            loading={loading}
            onClick={() => {}}
          >
            {isEditing ? t("common:save") : t("common:add")}
          </ButtonWithLoading>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const initialValues = (user?: UserFormProps["user"]) => ({
  name: user?.name || "",
  email: user?.email || "",
  password: "",
  confirmPassword: "",
  enablePassword: false,
  isSubmitting: false,
});
