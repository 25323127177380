import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Label, OptionGroup } from "components/Styled";
import { useState } from "react";
import { StyledTextField } from "../Styled";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { useTranslation } from "react-i18next";
import { getCloudStorage } from "pages/Rules/CreateOrEdit/context/helpers";

type Option = "never" | "always" | "number";

export default function LinkToFolder() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const [option, setOption] = useState<Option>(() => {
    const cloudStorage = getCloudStorage(state.rule);
    if (cloudStorage?.folderSharing?.attachments === 1) return "always";
    if (cloudStorage?.folderSharing?.attachments > 1) return "number";
    return "never";
  });

  function updateOption(event) {
    const value = event.target.value as Option;
    setOption(value);

    let changes = { folderSharing: null };

    if (value === "always") {
      changes = { folderSharing: { attachments: 1 } };
    }

    if (value === "number") {
      changes = { folderSharing: { attachments: 2 } };
    }

    dispatch({
      type: "updateStep",
      payload: {
        step: "cloudStorage",
        changes,
      },
    });
  }

  function updateAttachementsNumber(number: number) {
    dispatch({
      type: "updateStep",
      payload: {
        step: "cloudStorage",
        changes: {
          folderSharing: { attachments: number },
        },
      },
    });

    return true;
  }

  return (
    <OptionGroup data-testid="LinkToFolder">
      <Box mb={1}>
        <Label
          wording={t("cloudStorage.linkToFolder.title")}
          gutterBottom={false}
        />
        <Typography variant="caption">
          {t("cloudStorage.linkToFolder.subtitle")}
        </Typography>
      </Box>
      <RadioGroup name="kind" value={option} onChange={updateOption}>
        <FormControlLabel
          value="never"
          label={t("cloudStorage.linkToFolder.optionNever")}
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          value="always"
          label={t("cloudStorage.linkToFolder.optionAlways")}
          control={<Radio color="primary" size="small" />}
        />
        <FormControlLabel
          value="number"
          label={t("cloudStorage.linkToFolder.optionNattachments")}
          control={<Radio color="primary" size="small" />}
        />
      </RadioGroup>
      {option === "number" && (
        <NumberOfAttachments
          rule={state.rule}
          onChange={updateAttachementsNumber}
        />
      )}
    </OptionGroup>
  );
}

function NumberOfAttachments({ rule, onChange }) {
  const { t } = useTranslation("rules");
  const [error, setError] = useState<string>();
  const [amount, setAmount] = useState(() => {
    const cloudStorage = getCloudStorage(rule);
    return cloudStorage?.folderSharing?.attachments ?? 2;
  });

  function updateNumber(event) {
    setError(null);
    const number = event.target.value;
    setAmount(number);

    if (isNaN(number)) {
      setError(t("cloudStorage.linkToFolder.errorNotANumber"));
      return true;
    }

    if (number <= 1) {
      setError(t("cloudStorage.linkToFolder.errorLessThanOne"));
      return true;
    }

    onChange(number);
  }

  return (
    <Box ml={4} mt={1} display="flex" alignItems="center" gridGap=".8rem">
      <StyledTextField
        type="number"
        variant="outlined"
        name="amount"
        style={{ maxWidth: "5rem" }}
        value={amount}
        onChange={updateNumber}
        error={Boolean(error)}
        required
      />
      <Box display="flex" flexDirection="column">
        <span>{t("cloudStorage.linkToFolder.attachments")}</span>
        {error && (
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
