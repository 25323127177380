import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmDelete from "components/ConfirmDelete";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Form from "./Form";
import StatusIcon from "components/StatusIcon";
import * as api from "./api";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { DKIMRecord } from "@dashboard-v3/api";
import * as Sentry from "@sentry/react";

type Params = {
  record: DKIMRecord;
  onVerifyResult: (record: DKIMRecord) => void;
  onDelete: () => void;
};

export default function Item({ record, onVerifyResult, onDelete }: Params) {
  const { t } = useTranslation("orgAndDomains");
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  async function deleteRecord() {
    try {
      setIsDeleting(true);
      await api.removeDkim(record.id);
      onDelete();
    } catch (error) {
      enqueueSnackbar(t("dkim.deleteRecordError"), { variant: "error" });
      Sentry.captureException(error);
    } finally {
      setIsDeleting(false);
    }
  }

  if (isDeleting) {
    return <Skeleton width="100%" height="6rem" variant="rect" />;
  }

  return (
    <ListItem disableGutters>
      <Accordion
        style={{ width: "100%" }}
        expanded={isExpanded}
        onChange={(_, isExpanded) => {
          setIsExpanded(isExpanded);
        }}
      >
        <AccordionSummary
          data-testid="dkim__list-item"
          expandIcon={<ExpandMoreIcon />}
        >
          <Box padding="10px" display="flex" alignItems="center" flexGrow={1}>
            <Box flexGrow="1">
              <Box marginBottom="6px">
                <Typography display="inline" style={{ fontWeight: "500" }}>
                  @{record.domain}
                </Typography>
              </Box>
              {record.isVerified ? (
                <VerifiedState />
              ) : (
                <NotVerifiedState record={record} />
              )}
            </Box>

            <Box justifyContent="flex-end">
              <ConfirmDelete
                message={t("dkim.removeConfirmation", { name: record.domain })}
                label={t("dkim.remove")}
                color="secondary"
                disableElevation
                variant="contained"
                onAccept={deleteRecord}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box alignItems="baseline">
            <Form
              record={record}
              onVerifyResult={result => {
                if (result.isVerified) {
                  setIsExpanded(false);
                }
                onVerifyResult(result);
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}

function VerifiedState() {
  const { t } = useTranslation("orgAndDomains");
  return (
    <Box className="MuiTypography-body2 MuiTypography-colorTextSecondary">
      <StatusIcon state="enable" />
      {t("dkim.enableLabel")}
    </Box>
  );
}

function NotVerifiedState({ record }: { record: DKIMRecord }) {
  const { t } = useTranslation("orgAndDomains");

  return (
    <Box display="flex" alignItems="center">
      <Box className="MuiTypography-body2" style={{ color: red.A200 }}>
        <StatusIcon state="disable" />
        {t("dkim.notVerifiedLabel")}
      </Box>
      <Box ml="10px" className="MuiTypography-colorTextSecondary">
        <Box>
          {t("dkim.verificationLastTry", {
            from: dayjs(record.lastVerification).fromNow(),
          })}
        </Box>
      </Box>
    </Box>
  );
}
