import { Users } from "@dashboard-v3/api";
import { Link, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

interface SubtitleProps {
  user: Users.Item;
  isVisible: boolean;
}

export default function PasswordOptionSubtitle({
  user,
  isVisible,
}: SubtitleProps) {
  const { t } = useTranslation("users");
  const translations = {
    changeSubtitle: user?.isPasswordSet
      ? "passwordOptions.reset.subtitle"
      : "passwordOptions.create.subtitle",
  };

  const translationValues = {
    ...(user?.isPasswordSet && { email: user.email }),
  };

  if (!isVisible) return null;

  return (
    <div style={{ marginBottom: "15px", maxWidth: "90%" }}>
      <Typography variant="caption" color="textSecondary">
        <Trans
          i18nKey={translations.changeSubtitle}
          t={t}
          values={translationValues}
        >
          {user?.isPasswordSet && <b>{user.email}</b>}
          <Link href="/security"></Link>
        </Trans>
      </Typography>
    </div>
  );
}
