import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import { StyledPaper } from "components/Styled";
import { DefaultDefinitionGroup, StyledTitle } from "../Styled";
import { DefaultDefinitionProps } from "../types";
import SelectSecuritytDef from "./SelectSecurityDef";

export default function DefaultDefinition({
  formData,
  definitions,
  onDefault,
}: DefaultDefinitionProps) {
  const { t } = useTranslation("securityPolicies");
  const [enableDefault, setEnableDefault] = useState(false);
  const { pathname } = useLocation();
  const isEdit: boolean = pathname.includes("/edit");
  const {
    formState: { defaultDefinition },
    reset,
  } = formData;

  useEffect(() => {
    if (isEdit && defaultDefinition) {
      setEnableDefault(true);
    }
  }, [isEdit, defaultDefinition]);

  const handleSwitch = () => {
    setEnableDefault(prev => !prev);
    if (!enableDefault) {
      reset(["defaultDefinition"]);
    }
  };

  return (
    <>
      <StyledPaper>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={enableDefault}
              onChange={handleSwitch}
              disabled={!definitions.length}
            />
          }
          label={
            <Typography
              color="textSecondary"
              component="span"
              style={{ fontWeight: "500" }}
            >
              {t("form.defaultDefinition.switch")}
            </Typography>
          }
        />
        {enableDefault && (
          <DefaultDefinitionGroup>
            <StyledTitle>{t("form.defaultDefinition.title")}</StyledTitle>
            <SelectSecuritytDef
              name="defaultDefinition"
              options={definitions}
              onChange={onDefault}
              value={defaultDefinition}
            />
          </DefaultDefinitionGroup>
        )}
      </StyledPaper>
    </>
  );
}
