import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import SelectField from "components/Forms/SelectField";
import PageLoading from "components/PageLoading";
import { OptionGroup, StyledPaper, VerticalLine } from "components/Styled";
import PillButton from "components/Forms/PillButton";
import useSecurityDefinitions from "utils/useSecurityDefinitions";
import apiRequest from "utils/apiRequestWithErrorCode";
import useFormState from "utils/useFormState";
import Predicates from "./Predicates";
import DefaultDefinition from "./DefaultDefinition";
import { StyledTitle } from "../Styled";
import { SecurityPolicy, StorageProvider } from "@dashboard-v3/api";
import { FormState } from "../types";
import storageProviders, { getProviderLabel } from "utils/storageProviders";

const formDefaults = (): FormState => ({
  name: "",
  description: "",
  storageType: "" as StorageProvider,
  predicates: [
    {
      variableIdList: [],
      definitionId: "",
      match: true,
      firstMatch: false,
    },
  ],
  defaultDefinition: "",
});

export default function Form() {
  const { t } = useTranslation("securityPolicies");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { state, pathname } = useLocation();
  const { id: paramId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { formState, updateValues, setValues, reset } = useFormState<FormState>(
    formDefaults()
  );
  const {
    fetchDefinitions,
    loading: defsLoader,
    getByStorageType,
    getPresetsByProvider,
  } = useSecurityDefinitions();
  const { name, description, storageType, predicates } = formState;
  const isEdit: boolean = pathname.includes("/edit");

  useEffect(() => {
    const handleEditData = async () => {
      if (state) {
        const { selectedPolicy, policyId } = state;
        return policyId !== paramId
          ? await fetchPolicyById(paramId)
          : setValues(selectedPolicy);
      }
      await fetchPolicyById(paramId);
    };

    if (isEdit) {
      handleEditData();
      fetchDefinitions(1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateStorageType(event) {
    const newStorageType = event.target.value;
    updateValues(event);
    if (storageType !== newStorageType) {
      await fetchDefinitions(1000);
    }
  }

  const fetchPolicyById = async (id: string) => {
    setLoading(true);
    try {
      const res = await apiRequest<SecurityPolicy>(
        "GET",
        `/security-policies/${id}`
      );
      setValues({
        name: res.name,
        description: res.description,
        storageType: res.storageType,
        predicates: res.predicates,
        defaultDefinition: res.defaultDefinition,
      });
    } catch (e: unknown) {
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
      history.push("/security-policies");
    } finally {
      setLoading(false);
    }
  };

  const getPredicateDefinitions = () => {
    return [
      ...getPresetsByProvider(formState.storageType),
      ...getByStorageType(formState.storageType),
    ];
  };

  const savePolicy = async (body: FormState, id?: string) => {
    setLoading(true);
    try {
      const method = id ? "PUT" : "POST";
      const path: string = id
        ? `/security-policies/${id}`
        : "/security-policies";
      await apiRequest<SecurityPolicy>(method, path, body);
      enqueueSnackbar(t("saveSuccess", { name: body.name }), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("errors.saveError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
      history.push("/security-policies");
    }
  };

  const handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void = e => {
    e.preventDefault();
    if (state && isEdit) {
      const { policyId } = state;
      savePolicy(formState, policyId);
    } else {
      savePolicy(formState);
    }
  };

  const addPredicate = () => {
    const {
      predicates: [predicate],
    } = formDefaults();
    setValues({
      predicates: [...formState.predicates, predicate],
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PageLoading loading={loading || defsLoader} />
      <Grid container spacing={5}>
        <Grid item lg={8} xs={12}>
          <StyledPaper>
            <Typography gutterBottom variant="h6">
              {t("form.title")}
            </Typography>
            <OptionGroup>
              <TextField
                name="name"
                label={t("form.name.label")}
                variant="outlined"
                fullWidth
                value={name}
                onChange={updateValues}
                required
              />
            </OptionGroup>
            <OptionGroup>
              <TextField
                name="description"
                label={t("form.description.label")}
                variant="outlined"
                fullWidth
                value={description}
                onChange={updateValues}
              />
            </OptionGroup>
            <OptionGroup>
              <StyledTitle>{t("form.storageType.title")}</StyledTitle>
              <SelectField
                name="storageType"
                label={t("form.storageType.label")}
                options={storageProviders}
                value={storageType}
                onChange={updateStorageType}
                required
                renderOption={(value: StorageProvider) => (
                  <MenuItem key={value} value={value}>
                    {getProviderLabel(value)}
                  </MenuItem>
                )}
              />
            </OptionGroup>
          </StyledPaper>
          {predicates.map((_, i) => (
            <Predicates
              key={`predicates-${i}`}
              index={i}
              formData={{ formState, setValues }}
              definitions={getPredicateDefinitions()}
            />
          ))}
          <PillButton
            wording={t("form.buttons.predicates")}
            onClick={addPredicate}
            lineSeparator="full"
          />
          <DefaultDefinition
            formData={{ formState, reset }}
            definitions={getPresetsByProvider(formState.storageType).concat(
              getByStorageType(formState.storageType)
            )}
            onDefault={updateValues}
          />
          <VerticalLine size="sm" />
          <StyledPaper style={{ padding: "25px 24px" }}>
            <Button
              type="submit"
              size="large"
              wording={t("form.buttons.savePolicy")}
              fullWidth
            />
          </StyledPaper>
        </Grid>
      </Grid>
    </form>
  );
}
