import { useTranslation } from "react-i18next";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  RuleButtonsContainer,
  RuleCardContainer,
  RuleDetailsContainer,
} from "./styled";
import HorizontalStepper from "../RuleStepper/HorizontalStepper";
import RuleStatistics from "../RuleStatistics";
import { Suspense } from "react";
import useSWR from "swr";
import { Rule } from "@dashboard-v3/api";
import { fetcher } from "utils/apiRequestWithErrorCode";
import LinearLoader from "components/LinearLoader";

export default function Loader({ ruleId }: { ruleId: string }) {
  return (
    <Suspense fallback={<LinearLoader />}>
      <RuleCard ruleId={ruleId} />
    </Suspense>
  );
}

function RuleCard({ ruleId }: { ruleId: string }) {
  const { t } = useTranslation("stats");

  const { data: rule } = useSWR<Rule>(`/rules/${ruleId}`, fetcher, {
    revalidateOnFocus: false,
    suspense: true,
  });

  const renderRuleDescription = () => {
    return rule.description
      ? rule.description
      : t("accounts.ruleCard.emptyDescription");
  };

  return (
    <RuleCardContainer>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <ListItemText
            primary={
              <>
                <Box marginY="5px">
                  <Typography display="inline" style={{ fontWeight: "500" }}>
                    {rule?.name}
                  </Typography>
                </Box>
                <Box marginBottom="5px">
                  <Typography color="textSecondary" variant="body2">
                    {renderRuleDescription()}
                  </Typography>
                </Box>
              </>
            }
          />
          <RuleButtonsContainer>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              href={`/rules/${rule.id}/edit`}
              onClick={e => e.stopPropagation()}
            >
              {t("accounts.ruleCard.edit")}
            </Button>

            <Button
              color="primary"
              size="small"
              variant="outlined"
              href="/rules/list"
              onClick={e => e.stopPropagation()}
            >
              {t("accounts.ruleCard.list")}
            </Button>
          </RuleButtonsContainer>
        </AccordionSummary>
        <RuleDetailsContainer>
          <HorizontalStepper rule={rule} />
          <Divider />
          <div style={{ padding: "20px 30px 0 30px" }}>
            <RuleStatistics rule={rule} />
          </div>
        </RuleDetailsContainer>
      </Accordion>
    </RuleCardContainer>
  );
}
