import { useTranslation } from "react-i18next";
import { useRuleContext } from "../../context/ruleContext";
import { Label, OptionGroup } from "components/Styled";
import { Box, MenuItem, Select, TextField } from "@material-ui/core";
import isEmail from "utils/isEmail";
import getEmailActionType from "./getEmailActionType";
import { Rule } from "pages/Rules/types";
import { getStorage } from "pages/Rules/CloudStorage/utils";

export default function WithForwardErrorHandling() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();

  const type = getEmailActionType(state.rule);

  return (
    <OptionGroup>
      <Label wording={t("moreActions.onError.label")} />
      <Box display="flex" mt="15px" alignItems="baseline">
        <Select
          variant="outlined"
          value={type}
          onChange={event => {
            const newType = event.target.value === "DEFAULT" ? null : "FORWARD";
            dispatch({
              type: "updateMoreActions",
              payload: { onStorageError: { type: newType } },
            });
          }}
        >
          <MenuItem value="DEFAULT">
            {/* Do nothing */}
            {t("onStorageSuccess.actionType.NOTHING")}
          </MenuItem>
          <MenuItem value="FORWARD">
            {/* Forward */}
            {t("onStorageSuccess.actionType.FORWARD")}
          </MenuItem>
        </Select>

        {type === "FORWARD" && (
          <InputRecipientAddress
            value={getRecipientAddress(state.rule)}
            onChange={value => {
              dispatch({
                type: "updateMoreActions",
                payload: { onStorageError: { type, recipientAddress: value } },
              });
            }}
          />
        )}
      </Box>
    </OptionGroup>
  );
}

function InputRecipientAddress({ value, onChange }) {
  const { t } = useTranslation("rules");
  const error = !isEmail(value);

  return (
    <TextField
      variant="outlined"
      value={value}
      style={{ minWidth: "300px", marginLeft: 15 }}
      placeholder={t("onStorageSuccess.recipientType.emailPlaceholder")}
      onChange={event => {
        onChange(event.target.value);
      }}
      helperText={error && "Complete with an email"}
      error={error}
    />
  );
}

function getRecipientAddress(rule: Rule): string {
  const storage = getStorage(rule);
  const emailAction = storage.onStorageError?.emailAction;
  if ("recipientAddress" in emailAction) return emailAction.recipientAddress;
  return "";
}
