import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import ConfirmDelete from "components/ConfirmDelete";
import { ItemContainer } from "./Styles";
import AccountStatus from "./AccountStatus";
import { AccountType } from "./helpers";
import { MailboxSingleAccountProvider } from "../types";
import { StorageProviderWithAuth } from "@dashboard-v3/api";

type Params = {
  id: string;
  provider: StorageProviderWithAuth | MailboxSingleAccountProvider;
  accountType: AccountType;
  user: string;
  synced: boolean;
  enableAccount: (
    user: string,
    provider: StorageProviderWithAuth | MailboxSingleAccountProvider
  ) => void;
  deleteStorageAccount: (id: string) => void;
  deleteMailboxAccount: (id: string) => void;
};

export default function AccountItem({
  id,
  provider,
  accountType,
  user,
  synced,
  enableAccount,
  deleteStorageAccount,
  deleteMailboxAccount,
}: Params) {
  const { t } = useTranslation("storage");

  const onDelete = (id: string) => {
    if (accountType === AccountType.Storage) {
      deleteStorageAccount(id);
    }
    if (accountType === AccountType.Mailbox) {
      deleteMailboxAccount(id);
    }
  };

  return (
    <ItemContainer data-testid="storage__account-list-item">
      <Typography variant="caption" style={{ fontSize: "14px" }}>
        {user}
      </Typography>
      <Typography variant="caption" style={{ fontSize: "14px" }}>
        {t(`accountItem.provider.${provider}`)}
      </Typography>

      <Typography variant="caption" style={{ fontSize: "14px" }}>
        {t(`accountList.item.accountType.${accountType}`)}
      </Typography>
      <AccountStatus
        enableAccount={() => enableAccount(user, provider)}
        synced={synced}
      />
      <div style={{ textAlign: "right" }}>
        <ConfirmDelete
          onAccept={() => onDelete(id)}
          variant="outlined"
          size="small"
          message={t(`confirmDeletion.${accountType}`, {
            type: t(`accountItem.provider.${provider}`),
            user,
          })}
        />
      </div>
    </ItemContainer>
  );
}
