import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function tooltipLabelFormatter(timeStamp: number): string {
  const date = dayjs(timeStamp).utc();
  const month = date.month();
  let formatPattern = "D MMMM";

  if (month === 11 || month === 0) {
    formatPattern += " YYYY";
  }

  return dayjs(timeStamp).utc().format(formatPattern);
}

export const tickFormatter = (timeStamp: number) =>
  dayjs(timeStamp).utc().format("D");

export const tooltipFormatter = value => value.toLocaleString();

export function formatBigNumber(number: number) {
  if (number >= 1000000) {
    return `${roundToTwoDecimals(number).toLocaleString()} M`;
  }
  return number.toLocaleString();
}

function roundToTwoDecimals(number: number) {
  return Math.round((number / 1000000 + Number.EPSILON) * 100) / 100;
}
