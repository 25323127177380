import {
  MailboxSingleAccount,
  StorageAccount,
  StorageProvider,
  StorageProviderWithAuth,
} from "@dashboard-v3/api";
import { getAuthUrl } from "../api";
import * as Sentry from "@sentry/react";
import {
  IntegrationAccountProvider,
  MailboxSingleAccountProvider,
} from "../types";
import { SingleAccountProvider } from "pages/Rules/CreateOrEdit/Steps/Target/Mailbox/ReauthenticateSingleAccount";

let authWindow;
let checkIsClosed;

export interface AccountWithEnterprise extends StorageAccount {
  enterprise: {
    id: string;
    type: string;
    name: string;
  };
}

const canceledErrorMsg = "CANCELED";

export function isClosedByUser(error: unknown) {
  return error instanceof Error && error.message === canceledErrorMsg;
}

export function isDifferentAccount(error: unknown) {
  return (
    error instanceof Error && error.message === "INIT_WITH_DIFFERENT_ACCOUNTS"
  );
}

type IntegrationProvider =
  | StorageProviderWithAuth
  | SingleAccountProvider
  | "ONEDRIVE_ORG";

// prettier-ignore
type IntegrationType<T> = 
  T extends "BOX" ? AccountWithEnterprise :
  T extends "ONEDRIVE_ORG" ? { tenant: string } :
  T extends StorageProvider ? StorageAccount : 
  T extends MailboxSingleAccountProvider ? MailboxSingleAccount :
  never;

export default async function openAuthPopup<T extends IntegrationProvider>(
  type: T,
  user?: string
): Promise<IntegrationType<T>> {
  if (authWindow && !authWindow.closed) return null;

  const currentUrl = window.location.origin;
  const fromMxHero = event => {
    return (
      currentUrl.startsWith(event.origin) && event.data.source === "mxhero"
    );
  };

  const config = configByProvider(type);

  // eslint-disable-next-line no-restricted-globals
  const redirectUrl = `${location.origin}/storage-auth-response`;
  const url = await getAuthUrl(config.path, user, redirectUrl);
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-use-before-define
    authWindow = window.open(url, "", popupConfig(config.windowSize));

    checkIsClosed = setInterval(() => {
      if (authWindow.closed) {
        clearInterval(checkIsClosed);
        authWindow = null;
        reject(new Error(canceledErrorMsg));
      }
    }, 1000);

    function onMessage(event) {
      // Check component CloudStorageAuthResponse
      if (fromMxHero(event)) {
        const { success, errorCode, response } = event.data;
        window.removeEventListener("message", onMessage, false);
        if (success) {
          switch (type) {
            case "GOOGLE":
            case "OFFICE365": {
              const account: MailboxSingleAccount = {
                type,
                id: response.id,
                email: response.email,
                createdAt: Date.now(),
                synced: true,
              };

              resolve(account as IntegrationType<T>);
              break;
            }

            case "ONEDRIVE_ORG":
              resolve({ tenant: response.tenant } as IntegrationType<T>);
              break;

            case "BOX": {
              resolve({
                id: response.id,
                type: response.type,
                user: response.user,
                role: response.role,
                synced: true,
                createdAt: Date.now(),
                enterprise: {
                  id: response.enterpriseId,
                  name: response.enterpriseName,
                  type: response.enterpriseType,
                },
              } as IntegrationType<T>);
              break;
            }

            default: {
              const account: StorageAccount = {
                id: response.id,
                type: response.type,
                user: response.user,
                role: response.role,
                synced: true,
                createdAt: Date.now(),
              };

              resolve(account as IntegrationType<T>);
              break;
            }
          }
        } else {
          Sentry.captureMessage("Fail case in openAuthPopup", {
            extra: event.data,
          });

          reject(new Error(errorCode));
        }

        clearInterval(checkIsClosed);
        if (authWindow?.close) authWindow.close();
        authWindow = null;
      }
    }

    window.addEventListener("message", onMessage, false);
  });
}

function popupConfig({ width, height }) {
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  return `width=${width},height=${height},status=false,titlebar=false,toolbar=false,menubar=false,top=${top},left=${left}`;
}

type AuthPopupConfig = {
  windowSize: { width: number; height: number };
  path: string;
};

export function configByProvider(
  type: IntegrationAccountProvider
): AuthPopupConfig {
  switch (type) {
    case "BOX":
      return {
        windowSize: { width: 450, height: 655 },
        path: "/storage-accounts/box",
      };

    case "EGNYTE":
      return {
        windowSize: { width: 400, height: 500 },
        path: "/storage-accounts/egnyte",
      };

    case "GOOGLEDRIVE":
      return {
        windowSize: { width: 500, height: 680 },
        path: "/storage-accounts/drive",
      };

    case "ONEDRIVE":
      return {
        windowSize: { width: 450, height: 620 },
        path: "/storage-accounts/onedrive/individual",
      };

    case "DROPBOX":
      return {
        windowSize: { width: 450, height: 620 },
        path: "/storage-accounts/dropbox",
      };

    case "ONEDRIVE_ORG":
      return {
        windowSize: { width: 450, height: 620 },
        path: "/storage-accounts/onedrive/organizations",
      };

    case "GOOGLE":
      return {
        windowSize: { width: 500, height: 680 },
        path: "/auth/mailbox/google/individual",
      };

    case "OFFICE365":
      return {
        windowSize: { width: 450, height: 620 },
        path: "/auth/mailbox/office365/individual",
      };

    case "EGNYTE_GOV":
      return {
        windowSize: { width: 400, height: 500 },
        path: "/storage-accounts/egnytegov",
      };

    default:
      assertNever(type);
  }
}

function assertNever(x: never): never {
  throw new Error(`Unhandled case: ${x}`);
}
