import { CopyPolicy, CopyPolicyReqBody } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";

export async function fetchCopyPolicy(id: string) {
  return apiRequest<CopyPolicy>("GET", `/copy-policies/${id}`);
}

export async function updateCopyPolicy(id: string, data: CopyPolicyReqBody) {
  return apiRequest<CopyPolicy>("PUT", `/copy-policies/${id}`, data);
}

export async function createCopyPolicy(data: CopyPolicyReqBody) {
  return apiRequest<CopyPolicy>("POST", "/copy-policies", data);
}

export async function deleteCopyPolicy(id: string) {
  return apiRequest("DELETE", `/copy-policies/${id}`);
}
