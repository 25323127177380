/* eslint-disable react/prop-types */
import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ListHeaderTitle } from "components/List";

const PaperTable = ({ children, title, "data-testid": testId }) => {
  const headers = React.Children.map(children, child =>
    child.type.displayName === "Headers" ? child : null
  );

  const body = React.Children.map(children, child =>
    child.type.displayName === "Body" ? child : null
  );

  return (
    <TableContainer component={Paper}>
      <Typography style={{ padding: "1rem" }} variant="h6">
        {title}
      </Typography>
      <Table data-testid={testId} size="small">
        {headers}
        {body}
      </Table>
    </TableContainer>
  );
};

const Headers = ({ children }) => {
  return (
    <TableHead>
      <TableRow style={{ height: "48px" }}>
        {children.map(label => (
          <TableCell>
            <ListHeaderTitle>{label}</ListHeaderTitle>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
Headers.displayName = "Headers";
PaperTable.Headers = Headers;

const Body = ({ children }) => <TableBody>{children}</TableBody>;
Body.displayName = "Body";
PaperTable.Body = Body;

const Cell = ({ children }) => (
  <TableCell>
    <Typography>{children}</Typography>
  </TableCell>
);
Cell.displayName = "Cell";
PaperTable.Cell = Cell;

export default PaperTable;
