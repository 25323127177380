import styled from "styled-components";
import LensIcon from "@material-ui/icons/Lens";
import { Tooltip } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

export default function RuleStatus({ enabled }) {
  const { t } = useTranslation("rules");
  return (
    <Tooltip
      title={enabled ? t("list.enable") : t("list.disable")}
      aria-label={enabled ? t("list.enable") : t("list.disable")}
    >
      <StatusIconContainer>
        <StatusIcon htmlColor={enabled ? green.A400 : grey.A200} />
      </StatusIconContainer>
    </Tooltip>
  );
}

const StatusIcon = styled(LensIcon)`
  margin-left: 10px;
  font-size: 0.8rem;
  margin-right: 6px;
`;

const StatusIconContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`;
