import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface TabItem {
  id: string;
  label: string;
}

export type Props = {
  tabs: TabItem[];
  onClick: (id: TabItem["id"]) => void;
  showTab: TabItem["id"];
};

export default function SectionTabs({ tabs, onClick, showTab }: Props) {
  return (
    <StyledTabs
      value={showTab}
      onChange={(_, newValue) => onClick(newValue)}
      indicatorColor="primary"
    >
      {tabs.map((tab, i) => {
        return (
          <Tab
            data-testid={`section-tabs__tab-${tab.id}`}
            label={tab.label}
            key={tab.id}
            value={tab.id}
          />
        );
      })}
    </StyledTabs>
  );
}

const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #d6dbe0",
  },
})(Tabs);
