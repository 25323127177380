import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionHeader from "components/SectionHeader";
import SectionTabs from "components/SectionTabs";
import { Box, LinearProgress, Paper } from "@material-ui/core";
import DomainList from "./List";
import Organization from "./Organization";
import ManualDomainsList from "./ManualDomains/List";
import DkimItemList from "./Dkim/List";

export default function OrgAndDomains() {
  const [activeTab, setActiveTab] = useState("organization");
  const { t } = useTranslation("orgAndDomains");

  return (
    <>
      <SectionHeader title={t("domain_plural")} />
      <SectionTabs
        tabs={[
          { id: "organization", label: t("organization") },
          { id: "domains", label: t("domains") },
          { id: "manual_domains", label: t("manualDomains.title") },
          { id: "dkim", label: t("dkim.tabTitle") },
        ]}
        onClick={setActiveTab}
        showTab={activeTab}
      />
      <Box mt={2}>
        <Box display={activeTab === "organization" ? "inline" : "none"}>
          <Organization />
        </Box>

        <Box display={activeTab === "domains" ? "inline" : "none"}>
          <Suspense fallback={<Loading testId="domains__list_loading" />}>
            <DomainList />
          </Suspense>
        </Box>

        <Box display={activeTab === "manual_domains" ? "inline" : "none"}>
          <Suspense
            fallback={<Loading testId="manual-domains__list_loading" />}
          >
            <ManualDomainsList />
          </Suspense>
        </Box>

        <Box display={activeTab === "dkim" ? "inline" : "none"}>
          <Suspense
            fallback={<Loading testId="manual-domains__list_loading" />}
          >
            <DkimItemList />
          </Suspense>
        </Box>
      </Box>
    </>
  );
}

function Loading({ testId }: { testId: string }) {
  return (
    <Paper style={{ padding: "12px 16px" }} data-testid={testId}>
      <LinearProgress />
    </Paper>
  );
}
