/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, Typography } from "@material-ui/core";
import ComponentList from "components/ComponentList";
import SectionHeader from "components/SectionHeader";
import apiRequest from "utils/apiRequestWithErrorCode";
import Item from "./Item";

import { ReprocessEmailsSummary } from "@dashboard-v3/api";

export default function ReprocessEmails() {
  const { t } = useTranslation("reprocessEmails");
  const { enqueueSnackbar } = useSnackbar();
  const [emailSummary, setEmailSummary] = useState<ReprocessEmailsSummary>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchEmailSummary() {
      setLoading(true);
      try {
        const summary = await apiRequest<ReprocessEmailsSummary>(
          "GET",
          "/storage-accounts/reprocess-emails"
        );
        setEmailSummary(summary);
      } catch (e) {
        enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
    if (!emailSummary.length) {
      fetchEmailSummary();
    }
  }, []);

  const getEmptyMessage = () => {
    return (
      !emailSummary.length && (
        <Box my={2}>
          <Box mb={2}>{t("list.emptyList")}</Box>
        </Box>
      )
    );
  };

  return (
    <>
      <SectionHeader title={t("header.title")}>
        <Typography>{t("header.description")}</Typography>
      </SectionHeader>
      <ComponentList
        list={emailSummary}
        loading={loading}
        emptyMsg={getEmptyMessage()}
        renderItems={summary => (
          <Item key={summary.storageAccountsKey} summary={summary} />
        )}
      />
    </>
  );
}
