import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import FloatingButton from "../FloatingButton";
import styled from "styled-components";
import DeleteDialog from "../Filters/DeleteDialog";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useState } from "react";
import { useRuleContext } from "../context/ruleContext";
import { useTranslation } from "react-i18next";
import OnStorageSuccess, {
  hasDefaultState as hasOnSuccessDefaults,
  setToDefaults as setOnSuccessToDefaults,
} from "./OnStorageSuccess";
import OnStorageError, {
  hasDefaultState as hasOnErrorDefaults,
  setToDefaults as setOnErrorToDefaults,
} from "./OnStorageError";

export default function AddAction() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();

  const [showContent, setShowContent] = useState(
    !hasOnErrorDefaults(state.rule) || !hasOnSuccessDefaults(state.rule)
  );

  function setActionToDefaults() {
    setOnErrorToDefaults(dispatch);
    setOnSuccessToDefaults(dispatch);
  }

  if (showContent)
    return (
      <Content
        onRemove={() => {
          setShowContent(false);
          setActionToDefaults();
        }}
      />
    );

  return (
    <FloatingButton
      label={t("moreActions.btnLabel")}
      onClick={() => setShowContent(true)}
      disabled={!state.steps.target.done}
    />
  );
}

function Content({ onRemove }) {
  const [expanded, setExpanded] = useState(true);
  const [isConfirmationOpened, openConfirmation] = useState(false);

  const { t } = useTranslation("rules");

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Paper>
          <StepHeader>
            <Typography variant="h6">{t("moreActions.title")}</Typography>
            <div style={{ textAlign: "right" }}>
              <IconButton onClick={() => openConfirmation(true)}>
                <DeleteIcon />
              </IconButton>
              <DeleteDialog
                question={t("moreActions.doYouWantToDelete")}
                open={isConfirmationOpened}
                onChoose={confirm => {
                  if (confirm) onRemove();
                  openConfirmation(false);
                }}
              />
              <IconButton
                onClick={() => setExpanded(prevState => !prevState)}
                edge="end"
              >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </StepHeader>
          {expanded && (
            <StepContent>
              <OnStorageError />
              <OnStorageSuccess />
            </StepContent>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const StepHeader = styled.header`
  align-items: center;
  border-bottom: 1px solid #d6dbe0;
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr;
  padding: 5px 24px;
`;

const StepContent = styled.div`
  padding: 24px;
`;
