/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  SetStateAction,
} from "react";
import { Draft } from "immer";
import {
  getOrConditionsToValue,
  isMapAction,
  isMapCondition,
} from "../helpers";
import {
  OperatorType,
  SourceType,
  KeyedAndCondition,
  KeyedAndChild,
  KeyedVariable,
} from "@dashboard-v3/api";
import { TestingState } from "..";
import { ActionControl, ActionType } from "../types";
import random from "utils/random";

const ConditionsContext = createContext(undefined);

export const useConditionsCtx = () => useContext(ConditionsContext);

type AndFormSection = {
  type: "FIELD" | "VARIABLE" | "IA";
  primaryAction?: "AND" | "CONVERT" | "MAP_WITH";
  secondaryAction?: "AND" | "CONVERT";
};

type Form = {
  // test state maybe?
  orSections: AndFormSection[][];
};

export function defaultOfAndChildren(): KeyedAndChild {
  return {
    sources: [],
    variableIdList: [],
    type: null as OperatorType,
    parameters: [],
    sourceType: null as SourceType,
    key: random(),
  };
}

export function defaultAddCondition(): KeyedAndCondition {
  return {
    type: "AND",
    children: [defaultOfAndChildren()],
    toValue: "",
    key: random(),
  };
}

type Params = {
  children: React.ReactNode;
  position: { blockIndex: number; conditionIndex: number };
  variable: KeyedVariable;
  updateVariable: (updateFn: (draft: Draft<KeyedVariable>) => void) => void;
  variableTestProps: {
    variableTest: TestingState;
    setVariableTest: React.Dispatch<SetStateAction<TestingState>>;
  };
};

// interface ConditionProviderType {
//   variable: KeyedVariable;
//   selectFromBlock<T extends keyof KeyedAndChild>(
//     property?: T
//   ): KeyedAndChild[] | KeyedAndChild[T][];
//
//   selectFromCondition(): KeyedAndChild & { toValue: string };
//   position: Params["position"];
//   actions: ActionControl;
//   setActions: React.Dispatch<React.SetStateAction<ActionControl>>;
//
//   handleDatasetId(
//     e: React.ChangeEvent<{ name?: string; value: unknown }>
//   ): void;
// }

export function ConditionsProvider({
  children,
  position,
  variable,
  updateVariable,
  variableTestProps,
}: Params) {
  const [actions, setActions] = useState<ActionControl>(actionDefaults);
  const { setVariableTest } = variableTestProps;
  const [form, setForm] = useState<Form>();

  const isLastCondition = () => {
    const { blockIndex, conditionIndex } = position;
    const { children: conditions } = variable.predicate.children[blockIndex];
    return conditions.length === conditionIndex + 1;
  };

  useEffect(() => {
    if (variable.id) {
      setVariableTest({ isTesting: false, disabled: true });
    }
  }, []);

  useEffect(() => {
    const orConditions = variable.predicate.children[position.blockIndex];

    const convertValues = getOrConditionsToValue(variable);
    const condition = orConditions.children[position.conditionIndex];

    let conditionValidations = [!!condition.type];

    if (condition?.type !== "AI_QUESTION") {
      const { sources, variableIdList } = condition;
      conditionValidations.push(!!variableIdList.length || !!sources.length);
    }

    if (!isMapCondition(condition.type)) {
      const [primary, secondary] = condition.parameters;
      conditionValidations.push(
        isMapAction(actions.primary) ? !!secondary : !!primary
      );
    }

    if (isMapAction(actions.primary) || isMapCondition(condition.type)) {
      conditionValidations.push(!!condition.dataSetId);
    }

    const isValidVariable = [...conditionValidations, ...convertValues].every(
      validation => !!validation
    );

    setVariableTest(prev => ({
      ...prev,
      disabled: !isValidVariable,
    }));
  }, [variable, actions]);

  function updateCondition(
    update: Partial<KeyedAndChild> | ((condition: Draft<KeyedAndChild>) => void)
  ): void {
    updateVariable(draft => {
      const { blockIndex, conditionIndex } = position;
      const block = draft.predicate.children[blockIndex];
      const { children: conditions } = block;

      if (typeof update === "function") {
        // conditions[conditionIndex] = update(conditions[conditionIndex]);
        update(conditions[conditionIndex]);
        return;
      }

      if ("type" in update) {
        const previousType = conditions[conditionIndex].type;
        const newType = update.type;
        if (
          previousType === "EQUALS_TO_DATASET_KEY" &&
          newType !== "EQUALS_TO_DATASET_KEY"
        ) {
          delete conditions[conditionIndex].dataSetId;
        }
      }

      Object.keys(update).forEach(key => {
        if (key === "toValue") {
          block[key] = update[key];
        } else {
          conditions[conditionIndex][key] = update[key];
        }
      });
    });
  }

  function selectFromBlock<T extends keyof KeyedAndChild>(
    property?: T
  ): KeyedAndChild[] | KeyedAndChild[T][] {
    const { blockIndex } = position;
    const { children } = variable.predicate.children[blockIndex];
    if (property) {
      return children.map(block => block[property]);
    }
    return children;
  }

  const selectFromCondition = (): KeyedAndChild & { toValue: string } => {
    const { blockIndex, conditionIndex } = position;
    const { children } = variable.predicate;
    const conditions = children[blockIndex].children[conditionIndex];
    const { toValue } = children[blockIndex];
    return { toValue, ...conditions };
  };

  const addCondition = () => {
    updateVariable(draft => {
      const { blockIndex } = position;
      if (isLastCondition()) {
        draft.predicate.children[blockIndex].children.push(
          defaultOfAndChildren()
        );
      }
    });
  };

  const removeCondition = (newPrimaryAction: ActionType) => {
    updateVariable(draft => {
      const { blockIndex, conditionIndex } = position;
      const currentConditions = draft.predicate.children[blockIndex].children;
      if (currentConditions.length > 1) {
        draft.predicate.children[blockIndex].children.splice(
          conditionIndex + 1
        );
      }

      const condition = currentConditions[conditionIndex];
      if (
        newPrimaryAction === "and" ||
        (newPrimaryAction === "convert" && !isMapCondition(condition.type))
      ) {
        delete currentConditions[conditionIndex].dataSetId;
      }
    });
  };

  const handleDatasetId = e => {
    const value = e.target.value as string;
    return updateCondition({ dataSetId: value });
  };

  // const handleParameter: ConditionProviderType["handleParameter"] = e => {
  //   const { value } = e.target;
  //   updateCondition({ parameters: [value] });
  // };

  return (
    <ConditionsContext.Provider
      value={{
        variable,
        selectFromBlock,
        selectFromCondition,
        position,
        actions,
        setActions,
        updateCondition,
        handleDatasetId,
        // handleParameter,
        addCondition,
        removeCondition,
        isLastCondition,
        isReadOnly: Boolean(variable?.platformVariable),
        updateVariable,
        form,
        setForm,
      }}
    >
      {children}
    </ConditionsContext.Provider>
  );
}

const actionDefaults = (): ActionControl => ({
  primary: "" as ActionType,
  secondary: "" as ActionType,
});
