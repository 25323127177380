import {
  Typography,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OptionGroup } from "components/Styled";
import ArchivingAddressOptions from "./ArchivingAddressOptions";
import EmailFlowOptions from "./EmailFlowOptions";
import MailboxOptions from "./MailboxOptions";
import { useRuleContext } from "../../context/ruleContext";

const TargetFilters = () => {
  const { state } = useRuleContext();
  const { t } = useTranslation("rules");

  const { targetType } = state.rule;

  if (targetType === "ARCHIVING_ADDRESS") {
    return <ArchivingAddressOptions />;
  }

  if (targetType === "EMAIL_FLOW") {
    return <EmailFlowOptions />;
  }

  if (targetType === "MAIL_BOX") {
    return <MailboxOptions />;
  }

  return (
    <>
      <OptionGroup>
        <FormControlLabel
          control={<Switch color="primary" />}
          label={
            <Typography style={{ fontWeight: "500" }} variant="subtitle1">
              {t("targetFilter.selectorTitle", {
                type: t("targetFilter.filter"),
              })}
            </Typography>
          }
        />
        <FormHelperText>
          {t("targetFilter.selectorDescription", {
            type: t("targetFilter.filter"),
          })}
        </FormHelperText>
      </OptionGroup>

      <OptionGroup>
        <FormControlLabel
          control={<Switch color="primary" />}
          label={
            <Typography style={{ fontWeight: "500" }} variant="subtitle1">
              {t("targetFilter.selectorTitle", {
                type: t("targetFilter.exclude"),
              })}
            </Typography>
          }
        />
        <FormHelperText>
          {t("targetFilter.selectorDescription", {
            type: t("targetFilter.exclude"),
          })}
        </FormHelperText>
      </OptionGroup>

      <OptionGroup>
        <FormControlLabel
          control={<Switch color="primary" />}
          label={
            <Typography style={{ fontWeight: "500" }} variant="subtitle1">
              {t("filterByFolderPath")}
            </Typography>
          }
        />
        <FormHelperText>{t("filterByFolderPathDescription")}</FormHelperText>
      </OptionGroup>

      <OptionGroup style={{ marginBottom: "0" }}>
        <FormControlLabel
          control={<Switch color="primary" />}
          label={
            <Typography style={{ fontWeight: "500" }} variant="subtitle1">
              {t("excludeByFolderPath")}
            </Typography>
          }
        />
        <FormHelperText>{t("excludeByFolderPathDescription")}</FormHelperText>
      </OptionGroup>
    </>
  );
};

export default TargetFilters;
