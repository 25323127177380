import { DigestPeriod, NotificationSettings } from "@dashboard-v3/api";

export type FormState = {
  mailAddresses: string[];
  monthlyUsageReport: boolean;

  MAIL: {
    checked: boolean;
    period: DigestPeriod;
  };
  PLATFORM: {
    checked: boolean;
    period: DigestPeriod;
  };
  STORAGE: {
    checked: boolean;
    period: DigestPeriod;
  };
  RULE: {
    checked: boolean;
    period: DigestPeriod;
  };
};

export const digestPeriodOptions: DigestPeriod[] = ["HOUR", "DAY", "WEEK"];
export const notificationFields = [
  { name: "MAIL", transKey: "mailWarnings" },
  { name: "STORAGE", transKey: "storageWarnings" },
  { name: "PLATFORM", transKey: "platformWarnings" },
  { name: "RULE", transKey: "ruleWarnings" },
];

export const defaultSettings = (user: string): NotificationSettings => ({
  legacy: false,
  mailAddresses: [user],
  monthlyUsageReport: false,
  selectors: [],
});

export const initFormSelectors = () => ({
  MAIL: { checked: false, period: "HOUR" as DigestPeriod },
  STORAGE: { checked: false, period: "HOUR" as DigestPeriod },
  PLATFORM: { checked: false, period: "HOUR" as DigestPeriod },
  RULE: { checked: false, period: "HOUR" as DigestPeriod },
});

export const toFormState =
  (settings: NotificationSettings) => (): FormState => {
    const { mailAddresses, monthlyUsageReport, selectors } = settings;
    const selectorFields = selectors.reduce((acc, item) => {
      const { notificationLevel, notificationType } = item;
      if (notificationLevel === "WARN") {
        return {
          ...acc,
          [notificationType]: {
            checked: true,
            period: item.digestPeriod || "HOUR",
          },
        };
      }
      return acc;
    }, initFormSelectors());

    return { mailAddresses, monthlyUsageReport, ...selectorFields };
  };

export const toNotificationSettings = (form: FormState) => {
  const { mailAddresses, monthlyUsageReport, ...rest } = form;
  const selectors = Object.keys(rest).reduce((acc, key) => {
    const { checked, period } = rest[key];
    if (checked) {
      acc.push({
        notificationType: key,
        notificationLevel: "WARN",
        digestPeriod: period || "HOUR",
      });
    }
    return acc;
  }, []);

  return {
    mailAddresses,
    monthlyUsageReport,
    selectors,
    legacy: false,
  };
};
