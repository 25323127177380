import {
  ArchivingAddress,
  EmailFlow,
  Mailbox,
  Storage,
} from "@dashboard-v3/api";
import { Rule, RuleType } from "pages/Rules/types";
import { State, Step } from "./types";
import { Draft } from "immer";
import { randomArchivingAddress } from "../Steps/Target/ArchivingAddress/utils";
import { getUser } from "utils/authentication";
import { StorageWebhookUpdate } from "./reducers/updateStep";

export function needToConfirm(state: State, step: Step): boolean {
  return !state.steps[step].done;
}

// prettier-ignore
type ObjectType<T> = T extends "BLOCK_ATTACHMENTS"
  ? Rule["blockAttachments"]
  : T extends "COPY_EMAILS"
  ? Rule["copyEmails"]
  : T extends "MOVE_EMAILS"
  ? Rule["moveEmails"]
  : T extends "REPLACE_ATTACHMENTS"
  ? Rule["replaceAttachments"]
  : T extends "PROTECT_EMAILS"
  ? Rule["protectEmails"]
  : T extends "DRAG_AND_DROP"
  ? Rule["dragAndDrop"]
  : never;

export function typePath<T extends RuleType>(
  ruleType: T,
  rule: Rule
): ObjectType<T> {
  switch (ruleType) {
    case "BLOCK_ATTACHMENTS":
      return rule?.blockAttachments as ObjectType<T>;
    case "COPY_EMAILS":
      return rule?.copyEmails as ObjectType<T>;
    case "MOVE_EMAILS":
      return rule?.moveEmails as ObjectType<T>;
    case "REPLACE_ATTACHMENTS":
      return rule?.replaceAttachments as ObjectType<T>;
    case "PROTECT_EMAILS":
      return rule?.protectEmails as ObjectType<T>;
    case "DRAG_AND_DROP":
      return rule?.dragAndDrop as ObjectType<T>;
    default: {
      const exhaustiveCheck: never = ruleType;
      throw new Error(exhaustiveCheck);
    }
  }
}

export const getContentToSave = (rule: Rule) => {
  const path = typePath(rule.actionType, rule);
  if ("contentToSave" in path) return path.contentToSave;
  return null;
};

export const getCloudStorage = (rule: Rule) => {
  const path = typePath(rule.actionType, rule);
  if ("storage" in path) return path.storage;
  return null;
};

export const getArchivingAddress = (rule: Rule) => {
  const path = typePath(rule.actionType, rule);
  if ("archivingAddress" in path) return path.archivingAddress;
  return null;
};

export function getEmailFlow(rule: Rule) {
  const path = typePath(rule.actionType, rule);
  if ("emailFlow" in path) return path.emailFlow;
  return null;
}

export const getMailbox = (rule: Rule) => {
  const path = typePath(rule.actionType, rule);
  if ("mailbox" in path) return path.mailbox;
  return null;
};

export const getStorageWebhook = (rule: Rule) => {
  const path = typePath(rule.actionType, rule);
  if ("storageWebhook" in path) return path.storageWebhook;
  return null;
};

export const updateCloudStorage = (
  rule: Draft<Rule>,
  changes: Partial<Storage>
): Storage => {
  const path = typePath(rule.actionType, rule);
  path["storage"] = {
    ...getCloudStorage(rule),
    ...changes,
  };

  return path["storage"];
};

export const updateArchivingAddress = (
  rule: Draft<Rule>,
  changes: Partial<ArchivingAddress>
): Draft<ArchivingAddress> => {
  const path = typePath(rule.actionType, rule);
  path["archivingAddress"] = {
    ...getArchivingAddress(rule),
    ...changes,
  };

  return path["archivingAddress"];
};

export const updateEmailFlow = (
  rule: Draft<Rule>,
  changes: Partial<EmailFlow> | { saveForwardedMessages: boolean }
) => {
  const path = typePath(rule.actionType, rule);

  // https://mxhero.atlassian.net/browse/MXH-4513
  if ("saveForwardedMessages" in changes) {
    rule.copyEmails.saveForwardedMessages = changes.saveForwardedMessages;
    return path["emailFlow"];
  }

  path["emailFlow"] = {
    ...getEmailFlow(rule),
    ...changes,
  };

  return path["emailFlow"];
};

export const updateMailbox = (
  rule: Draft<Rule>,
  changes: Partial<Mailbox>
): Draft<Mailbox> => {
  const path = typePath(rule.actionType, rule);
  path["mailbox"] = {
    ...getMailbox(rule),
    ...changes,
  };

  return path["mailbox"];
};

export const updateWebhook = (
  state: Draft<State>,
  changes: Partial<StorageWebhookUpdate>
) => {
  const path = typePath(state.rule.actionType, state.rule);
  const { webhookStatus, ...webhookChanges } = changes;

  state.webhookStatus = {
    isValid: webhookStatus?.isValid || false,
    foldersInUse: webhookStatus?.foldersInUse || [],
    missingFolder: webhookStatus?.missingFolder || "",
  };

  path["storageWebhook"] = {
    ...getStorageWebhook(state.rule),
    ...webhookChanges,
  };

  if (!path["storageWebhook"].folders.length) {
    path["storageWebhook"].account = "";
  }

  return path["storageWebhook"];
};

export const getInitArchivingAddress = (): Partial<ArchivingAddress> => {
  const { email: redirectAddress } = getUser();

  return {
    emailAddress: randomArchivingAddress(),
    redirectOnErrorEmailAddress: redirectAddress,
    saveForwardedMessages: false,
  };
};

export const getInitEmailFlow = (): Partial<EmailFlow> => {
  return {
    fromTo: {
      fromDirection: { type: "ORGANIZATION" },
      toDirection: { type: "ANYONE" },
    },
    evaluateHeaders: false,
    bidirectional: true,
  };
};

export function canRestart(rule: Rule) {
  if (rule.targetType === "MAIL_BOX") {
    const mailbox = getMailbox(rule);
    return mailbox?.canRestart ?? true;
  }
  return true;
}

export const isWebhookProcessingUpdate = (state: State) => {
  if (state.webhookProcessing) {
    const { hasChanged } = state.webhookProcessing;
    return hasChanged;
  }
  return false;
};
