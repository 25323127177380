import apiRequest from "utils/apiRequestWithErrorCode";
import {
  TracingEvent,
  TracingEventDetails,
  TracingSearchRequest,
  TracingReport,
  TracingReportsResponse,
} from "@dashboard-v3/api";

export async function search(criteria: TracingSearchRequest) {
  return apiRequest<TracingEvent[]>("POST", "/tracing/search", criteria);
}

export async function fetchEventDetails(msgUID: string) {
  return apiRequest<TracingEventDetails[]>("GET", `/tracing/details/${msgUID}`);
}

export async function createTracingReport(criteria: TracingSearchRequest) {
  return apiRequest<TracingReport>("POST", "/tracing/reports", criteria);
}

export async function getAllTracingReports(rowsPerPage: number, page: number) {
  return apiRequest<TracingReportsResponse>(
    "GET",
    `/tracing/reports?pageSize=${rowsPerPage}&page=${page}`
  );
}

export async function getTracingReportById(id: string) {
  return apiRequest<TracingReport>("GET", `/tracing/reports/${id}`);
}

export async function cancelReport(id: string) {
  return apiRequest<TracingReport>("PATCH", `/tracing/reports/${id}/cancel`);
}
