import { ExcludeFolder } from "@dashboard-v3/api";
import { State } from "../types";
import produce from "immer";
import { getMailbox } from "../helpers";
import validateFilter from "./validateFilters";

export function updateExcludeFolders(
  state: State,
  changes: ExcludeFolder[]
): State {
  return produce(state, draft => {
    const mailbox = getMailbox(draft.rule);
    if (!mailbox.filter) {
      mailbox.filter = {
        includeFromTo: [],
        excludeFromTo: [],
        excludeFolders: [],
      };
    }

    mailbox.filter.excludeFolders = changes;
    validateFilter("targetFilter", draft);
  });
}

export type UpdateExcludeFolders = {
  type: "updateExcludeFolders";
  payload: ExcludeFolder[];
};
