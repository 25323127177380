import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Button, Typography } from "@material-ui/core";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { DisabledContainer, InfoContainer } from "./Styled";
import apiRequest from "utils/apiRequestWithErrorCode";
import { getUser } from "utils/authentication";
import { defaultSettings } from "./utils";

import { NotificationSettings } from "@dashboard-v3/api";

interface Props {
  setSettings: Dispatch<SetStateAction<NotificationSettings>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function DisabledNotice({ setSettings, setLoading }: Props) {
  const { t } = useTranslation("notificationSettings");
  const { enqueueSnackbar } = useSnackbar();

  const handleEnableSettings = async () => {
    const { email } = getUser();
    setLoading(true);
    try {
      const settings = await apiRequest<NotificationSettings>(
        "POST",
        "/notification/settings",
        defaultSettings(email)
      );
      setSettings(settings);
    } catch (e) {
      enqueueSnackbar(t("errors.fetchSettings"), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DisabledContainer>
      <NotificationsOffIcon />
      <InfoContainer>
        <div>
          <Typography variant="subtitle2">
            {t("disabled.content.notice")}
          </Typography>
          <Typography variant="subtitle2">
            {t("disabled.content.action")}
          </Typography>
        </div>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          onClick={handleEnableSettings}
        >
          {t("disabled.content.button")}
        </Button>
      </InfoContainer>
    </DisabledContainer>
  );
}
