let cache = {};

export default function throttle(callback, time) {
  if (cache[callback]) {
    const timeoutId = cache[callback];
    clearTimeout(timeoutId);
  }

  cache[callback] = setTimeout(() => {
    callback();
    delete cache[callback];
  }, time);
}
