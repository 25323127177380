/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SectionHeader from "components/SectionHeader";
import SectionTabs from "components/SectionTabs";
import SourceList from "./SourceList";
import GroupList from "./GroupList";
import AccountList from "./AccountList";
import AddAccountBtn from "./Buttons/AddOrEditAccount";
import { AccountProvider } from "./accountContext";
import { GroupsProvider } from "./groupsContext";
import { SourcesProvider } from "./sourcesProvider";
import BulkAddButton from "./BulkAdd";
import ExportAccountsBtn from "./Buttons/ExportAccounts";
import { Box } from "@material-ui/core";
import useSWR from "swr";
import { AccountsTotal } from "@dashboard-v3/api";
import { fetcher } from "utils/apiRequestWithErrorCode";

const ContentContainer = styled.div`
  column-gap: 30px;
  display: grid;
  grid-template-columns: 4fr 2fr;
  margin-top: 15px;
`;

export default function AccountsAndGroups() {
  const { t } = useTranslation("accounts");
  const [activeTab, setActiveTab] = useState("accounts");

  const { data: totals } = useSWR<AccountsTotal>("/accounts/total", fetcher, {
    revalidateOnFocus: false,
  });

  const tabs = [
    {
      id: "accounts",
      label: ` ${totals?.member?.toLocaleString() ?? ""} ${t("emailAccount", {
        count: totals?.member ?? 0,
      })}`,
    },
    {
      id: "groups",
      label: t("groupAccount", { count: 0 }),
    },
    /*
      label: `${totals?.list?.toLocaleString() ?? ""} ${t("groupAccount", {
        count: totals?.list ?? 0,
      })}`,
      */
  ];

  return (
    <GroupsProvider>
      <AccountProvider>
        <SourcesProvider>
          <Header />
          <SectionTabs tabs={tabs} onClick={setActiveTab} showTab={activeTab} />
          <ContentContainer>
            <Box display={activeTab === "accounts" ? "inline" : "none"}>
              <AccountList />
            </Box>
            <Box display={activeTab === "groups" ? "inline" : "none"}>
              <GroupList />
            </Box>
            <SourceList />
          </ContentContainer>
        </SourcesProvider>
      </AccountProvider>
    </GroupsProvider>
  );
}

const Header = () => {
  const { t } = useTranslation("accounts");

  return (
    <SectionHeader
      title={t("accountsAndGroups")}
      sideBtn={
        <>
          <ExportAccountsBtn />
          <AddAccountBtn />
          <BulkAddButton />
        </>
      }
    />
  );
};
