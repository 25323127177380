/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Paper } from "@material-ui/core";
import apiRequest from "utils/apiRequestWithErrorCode";
import useSecurityDefinitions from "utils/useSecurityDefinitions";
import { getStorage, isOrganization, toSecurityOptions } from "./utils";
import StorageAccount from "./StorageAccount";
import AdvancedOptions from "./AdvancedOptions";
import { StepHeader } from "./Styled";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";

import { SecurityPolicy } from "@dashboard-v3/api";
import { Option } from "./types";

function CloudStorage() {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useRuleContext();
  const storage = getStorage(state.rule);
  const expanded = state.steps["cloudStorage"].expanded;
  const { fetchDefinitions, securityDefinitions } = useSecurityDefinitions();
  const [securityOptions, setSecurityOptions] = useState<Option[]>([]);
  const [securityPolicies, setSecurityPolicies] = useState<SecurityPolicy[]>();

  useEffect(() => {
    fetchDefinitions(1000);
  }, []);

  useEffect(() => {
    async function fetchSecurityItems() {
      try {
        const policies = await apiRequest<SecurityPolicy[]>(
          "GET",
          "/security-policies?limit=1000"
        );
        setSecurityPolicies(policies);
      } catch (error) {
        enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
      }
    }
    if (securityDefinitions) {
      fetchSecurityItems();
    }
  }, [securityDefinitions]);

  useEffect(() => {
    if (securityDefinitions && securityPolicies) {
      const definitions = securityDefinitions.filter(definition =>
        definition.systemDefault
          ? definition.allowedStorages.includes(storage.provider)
          : true
      );
      setSecurityOptions(toSecurityOptions(definitions, securityPolicies));
    }
  }, [storage.provider, securityDefinitions, securityPolicies]);

  const getSubtitle = () => {
    if (!storage) return null;
    const { provider } = storage;
    if (provider) {
      return isOrganization(storage?.authentication)
        ? t(`cloudStorage.accounts.org.${provider}`)
        : t(`cloudStorage.header.subtitle.${provider}`);
    }
    return t("cloudStorage.header.subtitle.default");
  };

  return (
    <Paper>
      <StepHeader
        title={`3. ${t("cloudStorage.header.title")}`}
        subtitle={getSubtitle()}
        selected={Boolean(storage && storage.provider)}
      />
      {expanded && (
        <>
          <StorageAccount securityOptions={securityOptions} />
          <AdvancedOptions securityOptions={securityOptions} />
        </>
      )}
    </Paper>
  );
}

export default CloudStorage;
