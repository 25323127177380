import { useTranslation } from "react-i18next";
import { Button, ListItem, ListItemText, Typography } from "@material-ui/core";
import ConfirmDelete from "components/ConfirmDelete";
import { ListItemAccordion } from "components/List/StyledComponents";
import styled from "styled-components";
import { getFormattedTimeStamp } from "utils/dateUtils";

const ActionButtons = styled.div`
  align-self: center;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 15px;
`;

interface ItemProps {
  item: any;
  onEdit?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
}

const Item = ({ item, onEdit, onDelete, onCopy }: ItemProps) => {
  const { id, name, description, updatedAt } = item;
  const { t } = useTranslation();

  return (
    <ListItem key={id} disableGutters>
      <ListItemAccordion style={{ padding: "12px 16px" }}>
        <ContentContainer>
          <ListItemText
            primary={
              <>
                <Typography
                  display="inline"
                  style={{ marginRight: "10px", fontWeight: "500" }}
                >
                  {name}
                </Typography>
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                >
                  {description}
                </Typography>
              </>
            }
            secondary={
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ marginTop: "7px" }}
              >
                {t("common:updatedAt", {
                  date: getFormattedTimeStamp(updatedAt, true),
                })}
              </Typography>
            }
          />
          <ActionButtons>
            {onEdit && (
              <Button
                color="default"
                size="small"
                variant="outlined"
                onClick={onEdit}
              >
                {t("common:edit")}
              </Button>
            )}
            {onCopy && (
              <Button
                variant="outlined"
                size="small"
                color="default"
                style={{ marginLeft: "10px" }}
                onClick={onCopy}
              >
                {t("common:copy")}
              </Button>
            )}
            {onDelete && (
              <ConfirmDelete
                variant="outlined"
                size="small"
                message={t("common:confirmDeletion", { name })}
                style={{ marginLeft: "10px" }}
                onAccept={onDelete}
              />
            )}
          </ActionButtons>
        </ContentContainer>
      </ListItemAccordion>
    </ListItem>
  );
};

export default Item;
