import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { sourceLabels } from "./helpers";

import { Source } from "@dashboard-v3/api";

const ItemTitle = ({ source }: { source: Source }) => {
  const { t } = useTranslation("accounts");
  const { type } = source.configuration;
  let label: string;

  if (type === "LDAP") {
    label = source.configuration.ldapSettings.label;
  }

  return (
    <>
      <Typography style={{ fontWeight: "500" }}>
        {sourceLabels[type]}
      </Typography>
      {type === "LDAP" && label && (
        <Box mt={1} fontSize={14} color={blueGrey[500]}>
          {t("ldap.label")}: {label}
        </Box>
      )}
    </>
  );
};

export default ItemTitle;
