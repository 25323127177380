import { MouseEventHandler, useState } from "react";
import { TracingReport } from "@dashboard-v3/api";
import {
  Link,
  List,
  ListItem,
  Popover,
  PopoverProps,
  Typography,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

interface FiltersHelperProps {
  filters: TracingReport["filters"];
}

export function FiltersHelper({ filters }: FiltersHelperProps) {
  const { t } = useTranslation("tracing");
  const [openFilters, setOpenFilters] = useState(false);
  const [filtersAnchor, setFiltersAnchor] = useState(null);
  const popoverProps: Partial<PopoverProps> = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  };

  const onFilterOpen: MouseEventHandler<HTMLAnchorElement> = e => {
    setFiltersAnchor(e.currentTarget);
    setOpenFilters(prev => !prev);
  };

  return (
    <Link style={{ cursor: "pointer" }} onClick={onFilterOpen}>
      <Popover
        open={openFilters}
        anchorEl={filtersAnchor}
        anchorOrigin={popoverProps.anchorOrigin}
        transformOrigin={popoverProps.transformOrigin}
      >
        <List>
          {Object.entries(filters).map(item => {
            const [filterName, value] = item;
            const filter = filterName.includes("date")
              ? new Date(value as number).toLocaleString()
              : value;

            return (
              <ListItem key={`${filterName}-${value}`}>
                <Trans
                  t={t}
                  i18nKey={`report.filters.${filterName}`}
                  values={{ filter, interpolation: { escapeValue: false } }}
                >
                  <Typography variant="subtitle2"></Typography>
                </Trans>
              </ListItem>
            );
          })}
        </List>
      </Popover>
      {t("report.filters.helper", { count: Object.keys(filters).length })}
    </Link>
  );
}
