import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  Box,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmDelete from "components/ConfirmDelete";
import EmailAndSource from "./EmailAndSource";
import ItemDetails from "./ItemDetails";
import { StyledAccordionDetails, StyledAvatar } from "./Styled";
import { Group } from "@dashboard-v3/api";
import EditButton from "./EditButton";
import { Alert } from "@material-ui/lab";
import { fetchGroup } from "utils/api/groups";
import useSWR from "swr";

interface ItemProps {
  group: Group;
  deleting: boolean;
  onDelete: (id: string) => void;
}

export default function Item(props: ItemProps) {
  const { t } = useTranslation("accounts");
  const { group, onDelete } = props;
  const { id, name, email, sourceType, sourceId, sourceLabel, members } = group;

  const { data: groupDetails } = useSWR(`/groups/${id}`, () => fetchGroup(id), {
    suspense: true,
  });

  const canEditStorageAccounts = Boolean(groupDetails?.storage);

  return (
    <ListItem disableGutters data-testid="accounts__group-list-item">
      <Accordion
        style={{ width: "100%" }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          data-testid="accounts__group-list-item-summary"
          expandIcon={<ExpandMoreIcon />}
        >
          <ListItemIcon>
            <StyledAvatar>{name.charAt(0)}</StyledAvatar>
          </ListItemIcon>
          <ListItemText
            primary={name}
            secondary={
              <>
                <EmailAndSource
                  email={email}
                  sourceType={sourceType}
                  sourceLabel={sourceLabel}
                />
                {!sourceId && (
                  <Box maxWidth="70%">
                    <Alert
                      severity="warning"
                      variant="standard"
                      style={{
                        padding: "0 16px",
                        marginTop: "5px",
                      }}
                    >
                      {t("noSourceId")}
                    </Alert>
                  </Box>
                )}
              </>
            }
          />
          <ListItemSecondaryAction style={{ marginRight: "35px" }}>
            {!sourceId && (
              <ConfirmDelete
                onAccept={() => onDelete(id)}
                message={t("buttons.confirmGroupDelete", { name })}
                variant="outlined"
              />
            )}

            {canEditStorageAccounts && (
              <Box component="span" ml="10px">
                <EditButton id={id} />
              </Box>
            )}
          </ListItemSecondaryAction>
        </AccordionSummary>
        <StyledAccordionDetails style={{ display: "block" }}>
          <ItemDetails accounts={members} id={id} />
        </StyledAccordionDetails>
      </Accordion>
    </ListItem>
  );
}
