const validRegex =
  /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
export default function isDomain(value) {
  return value.match(validRegex);
}
