/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { Description, Label, OptionGroup } from "components/Styled";
import { FieldSkeleton } from "components/SkeletonLoading";
import { StyledMenuItem } from "../Styled";
import { getStorage, toTemplateOptions } from "../utils";
import { useRuleContext } from "../../CreateOrEdit/context/ruleContext";
import { Option } from "../types";
import allowTemplates from "utils/canUseTemplates";
import { Storage, TemplateDefinition, TemplatePolicy } from "@dashboard-v3/api";
import { useEffect, useState } from "react";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";

export default function SelectTemplateOptions() {
  const [options, setOptions] = useState<Option[]>();
  const [templateLabel, setTemplateLabel] = useState("");

  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useRuleContext();

  const storage = getStorage(state.rule);
  const loading = Boolean(!options);

  useEffect(() => {
    async function fetchTemplateItems() {
      try {
        const [definitions, policies] = await getTemplateItems();
        setOptions(toTemplateOptions(definitions, policies));
      } catch (error) {
        enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
      }
    }

    fetchTemplateItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage.userId]);

  useEffect(() => {
    let label = "";
    if (options && storage.metadata) {
      const { id } = storage.metadata;
      const match = options.find(o => o.key === id);
      label = t(match?.label) || "";
    }
    setTemplateLabel(label);
  }, [options, storage.metadata]);

  const renderTemplateValue = (val: unknown) => {
    if (!val) return "";
    const found = options.find(opt => opt.value.includes(val as string));
    if (!found) return "";
    return found.text;
  };

  const handleTemplates = e => {
    const { value } = e.target;
    let metadata = null;

    if (value) {
      const [type, id] = value.split(":");
      metadata = { id, type };
    }

    dispatch({
      type: "updateStep",
      payload: {
        step: "cloudStorage",
        changes: {
          metadata,
        },
      },
    });
  };

  if (loading) {
    return (
      <FieldSkeleton withLabel testId="SelectTemplateOption-Section__loading" />
    );
  }

  return (
    <OptionGroup data-testid="SelectTemplateOption-Section">
      <Label wording={t("cloudStorage.templates.title")} />
      <Box mt={2}>
        <SelectField<Option>
          name="templatePolicy"
          label={templateLabel}
          allowEmpty
          options={options}
          value={storage.metadata?.id || ""}
          onChange={handleTemplates}
          renderValue={renderTemplateValue}
          renderOption={opt => (
            <StyledMenuItem key={opt.key} value={opt.value}>
              <Typography variant="caption">{t(opt.label)}</Typography>
              <Typography>{t(opt.text)}</Typography>
              {opt.description && <Description>{opt.description}</Description>}
            </StyledMenuItem>
          )}
        />
      </Box>
    </OptionGroup>
  );
}

async function getTemplateItems() {
  return Promise.all([
    apiRequest<TemplateDefinition[]>("GET", "/template-definitions?limit=1000"),
    apiRequest<TemplatePolicy[]>("GET", "/template-policies?limit=1000"),
  ]);
}

export const canUseTemplate = (storage: Storage): boolean => {
  const provider = storage?.provider;
  // The ticket (https://mxhero.atlassian.net/browse/MXH-3682) change this. Chatted with Marcelo.
  // return isIndividual(storage?.authentication) && allowTemplates(provider);
  return allowTemplates(provider);
};
