import { Box, FormControl, MenuItem, TextField } from "@material-ui/core";
import { timezones, locales } from "@dashboard-v3/shared";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";
import SelectField from "components/Forms/SelectField";
import { Label } from "components/Styled";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { getStorage } from "../utils";
import { grey } from "@material-ui/core/colors";

type Timezone = typeof timezones[number];

export default function Locale() {
  const { t } = useTranslation("orgAndDomains");
  const { state, dispatch } = useRuleContext();
  const storage = getStorage(state.rule);

  let tzValue = storage.timeZone
    ? timezones.find(tz => tz.value === storage.timeZone)
    : null;

  return (
    <Box data-testid="Locale">
      <Label wording={t("org.locale&timezone")} />
      <Box my={2} display="flex">
        <FormControl variant="outlined" style={{ width: 300, marginRight: 15 }}>
          <SelectField
            displayEmpty
            name="locale"
            value={storage.locale ?? ""}
            label={t("org.locale")}
            options={["", ...locales]}
            onChange={event => {
              const option = event.target.value;
              dispatch({
                type: "updateStep",
                payload: { step: "cloudStorage", changes: { locale: option } },
              });
            }}
            renderOption={locale => (
              <MenuItem key={locale || "empty-locale"} value={locale || null}>
                {locale && t(`org.${locale}`)}
                {!locale && (
                  <i style={{ color: grey[400] }}>{t("common:none")}</i>
                )}
              </MenuItem>
            )}
          />
        </FormControl>

        <Box width={400} display="inline">
          <Autocomplete
            value={tzValue}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={t("org.timezone")}
              />
            )}
            getOptionLabel={option => {
              if (option && option.label) return option.label;
            }}
            getOptionSelected={compare}
            options={timezones}
            onChange={(_, option) => {
              console.log("option: ", option);
              let selection = null;
              if (typeof option === "string") {
                console.log("option: ", option);
                selection = null;
              } else {
                selection = option?.value ?? null;
              }

              dispatch({
                type: "updateStep",
                payload: {
                  step: "cloudStorage",
                  changes: { timeZone: selection },
                },
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function compare(one: Timezone, other: Timezone) {
  if (one && other) {
    return one.value === other.value;
  }
}
