import { useTranslation } from "react-i18next";
import { Typography, Grid, FormHelperText } from "@material-ui/core";
import AdvancedOptions from "./AdvancedOptions";
import { EmailFlow } from "@dashboard-v3/api";
import PageLoading from "components/PageLoading";
import FromToSelector from "pages/Rules/FromToSelector";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { getEmailFlow } from "pages/Rules/CreateOrEdit/context/helpers";
import SelectDirection from "./SelectDirection";
import ConfirmButton from "../ConfirmButton";

import { State } from "pages/Rules/CreateOrEdit/context/types";
import { Option } from "pages/Rules/FromToSelector/types";
import WithIconHelper from "components/WithIconHelper";
import FromToHelp from "./FromToHelp";

const EmailFlowOptions = () => {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();

  let emailFlow: EmailFlow = getEmailFlow(state.rule);

  function update(updates: Partial<EmailFlow>) {
    dispatch({
      type: "updateStep",
      payload: { step: "target", changes: updates },
    });
  }

  function validateFromTo(flow: { from: Option; to: Option }) {
    dispatch({
      type: "checkUnmanagedFlow",
      payload: flow,
    });
  }

  return (
    <>
      {state.asyncStatus === "validate_email_flow" && <PageLoading loading />}
      <div style={{ padding: "0 24px 24px" }}>
        <Typography
          gutterBottom
          variant="subtitle1"
          style={{ fontWeight: "500" }}
        >
          {t("targetAllEmailsSent")}
        </Typography>
        <WithIconHelper
          position="after"
          text={<FromToHelp />}
          buttonStyles={{ marginLeft: "5px" }}
        >
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item xs={3}>
              <SelectDirection
                value={emailFlow.bidirectional}
                onChange={bidirectional => update({ bidirectional })}
              />
            </Grid>
            <Grid item xs={9}>
              <FromToSelector
                initialSelectedItem={emailFlow.fromTo}
                onChange={fromTo => update({ fromTo })}
                onOptionSelect={validateFromTo}
                separator={
                  emailFlow.bidirectional
                    ? t("emailFlowSection.and")
                    : t("emailFlowSection.to")
                }
              />
            </Grid>
            {isInvalidFlow(state) && (
              <FormHelperText error variant="outlined" margin="dense">
                {t(mapErrorCheck(state))}
              </FormHelperText>
            )}
          </Grid>
        </WithIconHelper>

        <ConfirmButton
          onClick={() => dispatch({ type: "confirmStep", payload: "target" })}
          disabled={isConfirmDisabled(state)}
        />
      </div>
      <AdvancedOptions />
    </>
  );
};

const isConfirmDisabled = (state: State) => {
  if (state.steps?.target?.done) return true;
  return !state.steps.target.valid;
};

function isInvalidFlow(state: State) {
  return Boolean(state.checks.emailFlow?.error);
}
function mapErrorCheck(state: State) {
  const errorType = state.checks.emailFlow?.error;
  return `target.EMAIL_FLOW.checkError.${errorType}`;
}

export default EmailFlowOptions;
