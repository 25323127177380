import dayjs from "dayjs";

import { MxheroShare, MxheroShareParams } from "@dashboard-v3/api";

export function getParsedDate(date: number) {
  if (!date) return "";
  return dayjs(date).format("YYYY-MM-DD");
}

export function getFilename(share: MxheroShare) {
  if (!share) return "";
  const { itemId, type } = share;
  const pathArray = itemId.split("/").filter(e => e);
  const itemNumber = type === "FOLDER" ? 2 : 3;
  let res = "..";

  for (let i = itemNumber; i > 0; i--) {
    const index = pathArray.length - i;
    res += `/${pathArray[index]}`;
  }

  return `${res}${type === "FOLDER" ? "/" : ""}`;
}

export function getUrlParams(filters: MxheroShareParams) {
  const urlParams = new URLSearchParams();
  const filterEntries = Object.entries(filters);

  if (filterEntries.length) {
    Object.entries(filters).forEach(entry => {
      urlParams.set(entry[0], entry[1] as string);
    });
  }

  return `?${urlParams.toString()}`;
}

export function byUpdatedDate(a: MxheroShare, b: MxheroShare) {
  return b.updatedAt - a.updatedAt;
}

export function isExpired(expirationDate: number) {
  return dayjs(expirationDate).isBefore(dayjs());
}
