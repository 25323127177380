/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OptionDescription } from "components/Forms/StyledComponents";
import {
  isAIType,
  isMapAction,
  isMapCondition,
  isRegexType,
} from "pages/Variables/helpers";
import * as regex from "utils/regExp";
import { memoize } from "utils/regExp";
import useVariables from "utils/useVariables";
import { ActionControl, ConvertFields, MapFields } from "./ActionFields";
import ConvertHelper from "./ConvertHelper";
import { useConditionsCtx } from "../../state/ConditionsProvider";
import { ActionType, Parameters, VariableType } from "pages/Variables/types";
import { Dataset } from "@dashboard-v3/api";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const getRegexGroups = memoize(regex.getRegexGroups);

export default function Actions() {
  const { t } = useTranslation("variables");
  const {
    actions,
    setActions,
    isLastCondition,
    selectFromCondition,
    selectFromBlock,
    addCondition,
    removeCondition,
  } = useConditionsCtx();

  const { type, sourceType, dataSetId, toValue } = selectFromCondition();
  const enableMapFields = isRegexType(type) && isMapAction(actions.primary);
  const { platformVariables: platformVars } = useVariables();

  const { data: datasets, isLoading } = useSWR<Dataset[]>(
    isMapAction(actions.primary) ? "/datasets?limit=1000" : null,
    fetcher
  );

  useEffect(() => {
    const primaryValues = (): ActionType | "" => {
      if (isRegexType(type) && dataSetId) return "map";
      if (isRegexType(type) && datasets) return "map";
      if (!isLastCondition() && toValue) return "and";
      if (isLastCondition() && toValue) return "convert";
      return "";
    };
    const secondaryValues = (): ActionType | "" => {
      if (isLastCondition() && toValue) return "convert";
      if (!isLastCondition()) return "and";
      return "";
    };
    setActions(prev => {
      return {
        ...prev,
        primary: primaryValues(),
        secondary: secondaryValues(),
      };
    });
  }, []);

  useEffect(() => {
    if (!isRegexType(type)) {
      setActions(prev => {
        const { primary, secondary } = prev;
        return {
          ...prev,
          primary: isMapAction(primary) ? secondary : primary,
        };
      });
    }
  }, [type]);

  if (isLoading) return <Loading />;

  const enableDatasets = datasets && enableMapFields;

  /*
  useEffect(() => {
    if (enableMapFields) {
      fetchDatasets();
    }
  }, [actions]);
  */

  const handleActions = e => {
    const { name, value } = e.target;
    const newPrimary = name === "primary" ? value : actions.primary;
    let newSecondary = null;

    if (name === "secondary") {
      newSecondary = value;
    } else {
      newSecondary = newPrimary === "map" ? "convert" : null;
    }

    if (value === "and") {
      addCondition();
    } else {
      removeCondition(newPrimary);
    }

    setActions({
      primary: newPrimary,
      secondary: newSecondary,
    });
  };

  const getRegexVars = (
    parameter = "",
    conditionNumber: number,
    types: VariableType[]
  ) => {
    const groups = getRegexGroups(parameter);

    if (groups.length) {
      return groups.map((group, groupNumber) => {
        return {
          id: `{children-${conditionNumber - 1}, group-${groupNumber}}`,
          origin: "USER",
          name: t(`form.search.regex.text`, {
            conditionNumber,
            groupNumber,
            count: types.length,
          }),
          description: group.map((val, i) => (
            <OptionDescription key={`${i}`} highlight={val.group}>
              {val.text}
            </OptionDescription>
          )),
        };
      });
    }
    return [];
  };

  const getRegexOptions = () => {
    const types: VariableType[] = selectFromBlock("type");
    const parameters: Parameters = selectFromBlock("parameters");

    return parameters.reduce((acc, params, i) => {
      return isRegexType(types[i])
        ? [...acc, ...getRegexVars(params[0], i + 1, types)]
        : acc;
    }, []);
  };

  const getMatchedValueOptions = () => {
    const types: VariableType[] = selectFromBlock("type");

    return types.reduce((acc, type, i) => {
      const isMatchedValue = type && !isAIType(type) && !isRegexType(type);

      if (isMatchedValue) {
        const matchedValueOption = {
          id: `{children-${i}, group-0}`,
          origin: "USER",
          name: t(`form.search.matchedValue.text`, {
            conditionNumber: i + 1,
            count: types.length,
          }),
          description: t("form.search.matchedValue.tooltip"),
        };
        acc.push(matchedValueOption);
      }

      const isMapToDataset =
        type === "REGEX" && actions.secondary === "convert";

      if (isMapToDataset) {
        acc.push({
          id: `{children-${i}, value}`,
          origin: "USER",
          name: t(`form.search.matchedDatasetResult.text`, {
            conditionNumber: i + 1,
            count: types.length,
          }),
          description: t("form.search.matchedDatasetResult.tooltip"),
        });
      }

      return acc;
    }, []);
  };

  const getAIOptions = () => {
    const types: VariableType[] = selectFromBlock("type");

    return types.reduce((acc, type, i) => {
      const option = {
        id: `{children-${i}, group-0}`,
        origin: "USER",
        name: t("form.search.aiOption.text", {
          conditionNumber: i + 1,
          count: types.length,
        }),
        description: t("form.search.aiOption.tooltip"),
      };

      return isAIType(type) ? [...acc, option] : acc;
    }, []);
  };

  const getDatasetResultOption = () => {
    const types: VariableType[] = selectFromBlock("type");

    return types.reduce((acc, type, i) => {
      const option = {
        id: `{children-${i}, value}`,
        origin: "USER",
        name: t("form.search.matchedDatasetResult.text", {
          conditionNumber: i + 1,
          count: types.length,
        }),
        description: t("form.search.matchedDatasetResult.tooltip"),
      };
      return isMapCondition(type) ? [...acc, option] : acc;
    }, []);
  };

  const getAutocompleteOptions = () => {
    const matchedValueOptions = getMatchedValueOptions();
    const regexOptions = getRegexOptions();
    const datasetResultOption = getDatasetResultOption();
    const AIOptions = getAIOptions();
    const sortedOptions = [
      ...datasetResultOption,
      ...matchedValueOptions,
      ...regexOptions,
      ...AIOptions,
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    return [...sortedOptions, ...platformVars];
  };

  const getActionOpts = (): Array<"convert" | "map" | "and"> => {
    return isRegexType(type) ? ["and", "map", "convert"] : ["and", "convert"];
  };

  return (
    <>
      <ActionControl condition={sourceType && !isMapAction(actions.primary)}>
        <ConvertFields
          actionType="primary"
          actionOpts={getActionOpts()}
          searchValues={getAutocompleteOptions}
          onAction={handleActions}
        />
      </ActionControl>
      <ActionControl condition={sourceType && enableDatasets}>
        <MapFields
          actionType="primary"
          actionOpts={getActionOpts()}
          datasets={datasets}
          onAction={handleActions}
        />
        <ConvertFields
          actionType="secondary"
          actionOpts={["and", "convert"]}
          searchValues={getAutocompleteOptions}
          onAction={handleActions}
        />
      </ActionControl>
      <ConvertHelper values={getAutocompleteOptions()} />
    </>
  );
}

function Loading() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Skeleton height="55px" style={{ transform: "none" }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height="55px" style={{ transform: "none" }} />
      </Grid>
    </Grid>
  );
}
