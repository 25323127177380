import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import { FileItemSubtitleContainer } from "./styled";

import { ImportCSVResult } from "../utils";

interface FileItemSubtilteProps {
  file: ImportCSVResult;
  onFileSelect: () => void;
}

export default function FileItemSubtitle({
  file,
  onFileSelect,
}: FileItemSubtilteProps) {
  const { t } = useTranslation("accounts");

  return (
    <FileItemSubtitleContainer>
      <span>
        {`${file.accounts.length} accounts loaded.`}
        {!!file.accounts.length && (
          <Link onClick={onFileSelect}>
            {t("importAccountsModal.files.showAccountsLink")}
          </Link>
        )}
      </span>
    </FileItemSubtitleContainer>
  );
}
