/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import ComponentList from "components/ComponentList";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import ListFilters from "./ListFilters";
import MxheroShareItem from "./MxheroShareItem";
import { EmptyListMessage } from "./EmptyListMessage";
import { byUpdatedDate, getUrlParams } from "../utils";

import {
  MxheroShare,
  MxheroShareParams,
  MxheroShareUpdate,
} from "@dashboard-v3/api";

enum LoadingState {
  All = "ALL",
  FetchMore = "FETCH_MORE",
  Idle = "IDLE",
  Edit = "EDIT",
}

const PAGE_LIMIT = 10;

export default function MxheroSharesList() {
  const { t } = useTranslation("storageShares");
  const { enqueueSnackbar } = useSnackbar();
  const [sharedItems, setSharedItems] = useState<MxheroShare[]>([]);
  const [filters, setFilters] = useState<MxheroShareParams>({});
  const [loading, setLoading] = useState<LoadingState>(LoadingState.Idle);
  const [loadingItem, setLoadingItem] = useState("");
  const [fetchMore, setFetchMore] = useState(false);
  const [disabledForOrg, setDisabledForOrg] = useState(false);
  const classes = useStyles();

  async function fetchStorageShares(params?: MxheroShareParams) {
    const newFilters = {
      ...filters,
      ...(params && { ...params }),
    };

    try {
      const route = `/mxhero-shares${getUrlParams(newFilters)}`;
      const shares = await apiRequest<MxheroShare[]>("GET", route);
      setFetchMore(shares.length === PAGE_LIMIT);
      setFilters(newFilters);
      return shares.sort(byUpdatedDate);
    } catch (e) {
      const code = getErrorCode(e);
      if (code === "DISABLED_FOR_ORGANIZATION") {
        setDisabledForOrg(true);
      } else {
        enqueueSnackbar(t("list.fetchError"), { variant: "error" });
      }
    }
  }

  useEffect(() => {
    async function initializeList() {
      const params = { limit: PAGE_LIMIT, offset: 0 };
      setLoading(LoadingState.All);
      const shares = await fetchStorageShares(params);
      if (shares) setSharedItems(shares);
      setLoading(LoadingState.Idle);
    }

    if (!sharedItems.length) {
      initializeList();
    }
  }, []);

  async function fetchMoreShares() {
    const params = { ...filters, offset: filters.offset + PAGE_LIMIT };
    setLoading(LoadingState.FetchMore);
    const shares = await fetchStorageShares(params);
    setSharedItems(prev => [...prev, ...shares]);
    setLoading(LoadingState.Idle);
  }

  async function updateFilters(e) {
    const { name, value } = e.target;
    setFilters(prev => {
      if (name in prev && !value) {
        const update = { ...prev };
        delete update[name];
        return update;
      }
      return { ...prev, [name]: value };
    });
  }

  async function onApplyFilters() {
    const params = { ...filters, offset: 0 };
    setLoading(LoadingState.All);
    const shares = await fetchStorageShares(params);
    setSharedItems(shares);
    setLoading(LoadingState.Idle);
  }

  async function onClearFilters() {
    setFilters({});
  }

  async function onRevoke(id: string) {
    setLoadingItem(id);
    try {
      const update: MxheroShareUpdate = {
        expiresAt: dayjs().subtract(1, "day").utc().valueOf(),
      };
      const updateShare = await apiRequest<MxheroShare>(
        "PATCH",
        `/mxhero-shares/${id}`,
        update
      );
      setSharedItems(prev => {
        return prev.map(share => {
          return share.shareId === updateShare.shareId ? updateShare : share;
        });
      });
    } catch (e) {
      enqueueSnackbar(t("list.fetchError"), { variant: "error" });
    } finally {
      setLoadingItem("");
    }
  }

  return (
    <>
      <ListFilters
        filters={filters}
        onChange={updateFilters}
        onApply={onApplyFilters}
        onClearFilters={onClearFilters}
        disabled={disabledForOrg || !sharedItems.length}
      />
      <ComponentList<MxheroShare>
        list={sharedItems}
        loading={loading === LoadingState.All}
        emptyMsg={<EmptyListMessage isDisabled={disabledForOrg} />}
        renderItems={item =>
          loadingItem === item.shareId ? (
            <Skeleton
              key={item.shareId}
              className={classes.itemLoader}
              variant="rect"
              animation="wave"
              height={68.8}
            />
          ) : (
            <MxheroShareItem
              key={item.shareId}
              item={item}
              loading={!!loadingItem}
              onRevoke={onRevoke}
            />
          )
        }
      />
      {fetchMore && loading !== LoadingState.All && (
        <div className={classes.fetchMoreContainer}>
          <ButtonWithLoading
            className={classes.fetchMoreBtn}
            size="small"
            color="primary"
            disableElevation
            variant="contained"
            loading={loading === LoadingState.FetchMore}
            onClick={fetchMoreShares}
          >
            {t("common:showMore")}
          </ButtonWithLoading>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles({
  itemLoader: { margin: "8px 0 8px 0", borderRadius: "4px" },
  fetchMoreContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  fetchMoreBtn: { marginTop: "10px" },
});
