import { TextField } from "@material-ui/core";
import { Autocomplete, UseAutocompleteProps } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

interface TagsAutocompleteProps {
  options: string[];
  values: string[];
  onChange: UseAutocompleteProps<string, true, true, true>["onChange"];
}

export default function TagsInput(props: TagsAutocompleteProps) {
  const { t } = useTranslation("accounts");
  const { options, values, onChange } = props;

  return (
    <Autocomplete
      disableClearable
      freeSolo
      limitTags={3}
      style={{ marginBottom: "20px" }}
      multiple
      defaultValue={[]}
      getOptionDisabled={o => values.some(tag => tag === o)}
      options={options}
      value={values}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={t("tag_plural")}
          helperText={t("select_or_enter_to_add")}
        />
      )}
    />
  );
}
