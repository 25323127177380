import { Draft } from "immer";
import { getActionState } from "pages/Rules/RuleActions/utils";
import { State } from "../../types";

export default function validate(draft: Draft<State>) {
  draft.steps.action.valid = isValidByContentType(draft);
}

function isValidByContentType(draft: Draft<State>) {
  if (draft.rule.actionType === "BLOCK_ATTACHMENTS") {
    return true;
  }

  const { actionType } = draft.rule;
  const { content } = getActionState(draft);
  const types = content.map(c => c.type);

  if (!types.length) return false;

  if (
    actionType === "PROTECT_EMAILS" &&
    !(types.includes("ATTACHMENTS") && types.includes("PDF"))
  ) {
    return false;
  }

  if (actionType === "REPLACE_ATTACHMENTS" && !types.includes("ATTACHMENTS")) {
    return false;
  }

  return true;
}
