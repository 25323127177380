/* eslint-disable react-hooks/exhaustive-deps */
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import apiRequest from "./apiRequestWithErrorCode";
import useSWR, { SWRConfiguration } from "swr";
import { ResponseOrganization, OrganizationUpdates } from "@dashboard-v3/api";
import { fetchOrganization } from "./api/organization";

export default function useOrganization(options?: SWRConfiguration) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: organization,
    error,
    isLoading,
    mutate: revalidateOrganization,
  } = useSWR<ResponseOrganization>("/organization", fetchOrganization, {
    onError(error) {
      console.error(error);
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    },
    revalidateOnFocus: false,
    ...(options || {}),
  });

  const updateOrganization = async (
    updates: Partial<OrganizationUpdates>,
    shouldThrowError?: boolean
  ) => {
    try {
      await mutate("/organization", async () => {
        /* TODO: Tenemos que rever esto ya que hay varios incovenientes:
          + Si el request es PUT la organización (o lo que se expone) debe viajar completo.
            Y en este caso no lo hace: Si el objeto update es nested podría desconfigurar otras opciones.
            Ej:
            Si el update es { storagesPolicy: { preventReProcessingDragAndDrop: true } } no funciona correctamente

          Hay 2 opciones, o convertirlo en un PATCH o hacer un deep merge.  Hay que chequear el PUT /orgnization en el 
          resto del proyecto para saberl.  Creo que lo mejor es convertirlo en un PATCH
        */

        return await apiRequest<ResponseOrganization>(
          "PATCH",
          "/organization",
          updates
        );
      });
    } catch (error) {
      if (shouldThrowError) {
        throw error;
      }
      enqueueSnackbar(t("errors.updateError"), {
        variant: "error",
      });
    }
  };

  return {
    organization,
    error,
    isLoading,
    updateOrganization,
    revalidateOrganization,
  };
}
