/* eslint-disable react-hooks/exhaustive-deps */
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SectionHeader from "components/SectionHeader";
import { Button } from "components/Forms/StyledComponents";
import DataSetsForm from "./Form";
import DataSetsList from "./List";
import { Typography } from "@material-ui/core";

export default function Dataset() {
  const { t } = useTranslation("datasets");
  const history = useHistory();
  const { pathname } = useLocation();
  const isOnForm = pathname.includes("/new") || pathname.includes("/edit");
  const headerBtnText = isOnForm ? t("common:backToList") : t("common:create");

  const handleRouting = () => {
    return history.push(isOnForm ? "/datasets" : "/datasets/new");
  };

  return (
    <>
      <SectionHeader
        title={t("sidebar:datasets")}
        sideBtn={
          <Button
            wording={headerBtnText}
            onClick={handleRouting}
            data-testid="datasets_create-new-btn"
          />
        }
      >
        <Typography>{t("description")}</Typography>
      </SectionHeader>
      <Switch>
        <Route path="/datasets" exact>
          <DataSetsList />
        </Route>
        <Route path="/datasets/new" exact>
          <DataSetsForm />
        </Route>
        <Route path="/datasets/edit/:id" exact>
          <DataSetsForm />
        </Route>
      </Switch>
    </>
  );
}
