import React from "react";
import BoxPicker from "pages/Rules/StorageFilepath/FilepathSelector/PickerModal/BoxPicker";
import { StorageFolder } from "pages/Rules/StorageFilepath/FilepathSelector/types";
import GooglePicker from "pages/Rules/StorageFilepath/FilepathSelector/PickerModal/GooglePicker";
import OneDrivePicker from "pages/Rules/StorageFilepath/FilepathSelector/PickerModal/OneDrivePicker";
import { StorageProvider } from "@dashboard-v3/api";

type Params = {
  open: boolean;
  onChoose: (folder?: StorageFolder) => void;
  provider: StorageProvider;
};

export default function FolderPickerModal({
  onChoose,
  open,
  provider,
}: Params) {
  const onBoxChange = (folders: StorageFolder[]) => {
    if (!folders) return onChoose(null);
    onChoose(folders[0]);
  };

  return (
    <>
      {provider === "BOX" && (
        <BoxPicker open={open} multiSelect={false} onChange={onBoxChange} />
      )}
      {provider === "GOOGLEDRIVE" && (
        <GooglePicker open={open} onChange={onChoose} />
      )}
      {provider === "ONEDRIVE" && (
        <OneDrivePicker open={open} onChange={onChoose} />
      )}
    </>
  );
}
