import { useEffect, useState } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import ButtonWithLoading from "components/ButtonWithLoading";
import { addUserInSession, getUser } from "utils/authentication";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import { isBlank } from "utils/string";
import type { WritableDraft } from "immer/dist/internal";
import Search from "./Search";
import { grey } from "@material-ui/core/colors";
import * as Sentry from "@sentry/react";

type SelectorState = {
  isOpen: boolean;
  newOrgId: string;
  error: string;
  loading: boolean;
};

export default function OrganizationSelector() {
  const { t } = useTranslation("components");
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId, organizationName, ...user } = getUser();
  const [state, setState] = useState<SelectorState>(initState);

  useEffect(() => {
    if (!state.isOpen) {
      updateState(draft => {
        draft.newOrgId = "";
        draft.error = "";
      });
    }
  }, [state.isOpen]);

  const updateState = (cb: (draft: WritableDraft<SelectorState>) => void) => {
    setState(prev => produce(prev, cb));
  };

  const handleStatus = (type: "IS_OPEN" | "LOADING", status: boolean) => {
    updateState(draft => {
      if (type === "IS_OPEN") {
        draft.isOpen = status;
      }
      if (type === "LOADING") {
        draft.loading = status;
      }
    });
  };

  const handleOnSave = async () => {
    const { newOrgId } = state;

    if (!!state.error || isBlank(newOrgId)) return;

    handleStatus("LOADING", true);
    try {
      const { name: organizationName } = await apiRequest<{
        name: string;
        id: string;
      }>("POST", `/auth/organizations/${newOrgId}`);
      addUserInSession({ ...user, organizationId: newOrgId, organizationName });
      handleStatus("IS_OPEN", false);
      window.location.reload();
    } catch (e) {
      const errorCode = getErrorCode(e);
      updateState(draft => {
        if (errorCode === "ORGANIZATION_NOT_FOUND") {
          draft.error = t("organizationModal.errors.orgNotFound");
        } else if (errorCode === "SAME_ORGANIZATION") {
          draft.error = t("organizationModal.errors.sameOrg", { id: newOrgId });
        } else {
          Sentry.captureException(e, {
            user: getUser(),
            fingerprint: ["organizationSelector", "save"],
            extra: { newOrgId },
          });

          enqueueSnackbar(t("organizationModal.errors.unknown"), {
            variant: "error",
          });
        }
      });
    } finally {
      handleStatus("LOADING", false);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        {organizationId !== user.originalOrganizationId && (
          <>
            <Box fontWeight={500} color={grey[500]}>
              {organizationName}
            </Box>
            <Box
              fontWeight={500}
              color={grey[500]}
              marginLeft="10px"
              marginRight="5px"
            >
              {">"}
            </Box>
          </>
        )}
        <Button
          variant="text"
          color="default"
          style={{ marginRight: "20px", color: "grey" }}
          wording={t("organizationModal.headerBtn")}
          onClick={() => handleStatus("IS_OPEN", true)}
        />
      </Box>

      <Dialog
        open={state.isOpen}
        onClose={() => handleStatus("IS_OPEN", false)}
      >
        <DialogTitle>{t("organizationModal.title")}</DialogTitle>
        <StyledDialogContent>
          <Search
            onChange={orgId => {
              updateState(draft => {
                draft.newOrgId = orgId;
              });
            }}
          />

          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            gridGap="6px"
            mt="20px"
          >
            <Typography variant="caption" color="textSecondary">
              {t("organizationModal.currentOrg")}
            </Typography>
            <Box>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                <Box color={grey["900"]} fontWeight={500} fontSize=".9rem">
                  {organizationName}
                </Box>
                <Box display="flex">
                  <Box mr="8px">ID</Box>
                  <Box color={grey["500"]}>{organizationId}</Box>
                </Box>
              </Typography>
            </Box>
          </Box>
        </StyledDialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="text"
            wording={t("common:close")}
            onClick={() => handleStatus("IS_OPEN", false)}
          />
          <ButtonWithLoading
            variant="contained"
            size="small"
            disableElevation
            loading={state.loading}
            onClick={handleOnSave}
            disabled={!state.newOrgId}
          >
            {t("organizationModal.headerBtn")}
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </>
  );
}

const initState = (): SelectorState => ({
  error: "",
  newOrgId: "",
  loading: false,
  isOpen: false,
});

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  min-width: 400px;
  span {
    margin-left: 3px;
    span {
      font-weight: 500;
    }
  }
`;
