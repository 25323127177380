import { useTranslation } from "react-i18next";
import { typePath as getTypePath } from "pages/Rules/CreateOrEdit/context/helpers";
import { Rule } from "pages/Rules/types";
import useStorageAccounts from "../CloudStorage/useStorageAccounts";
import { Skeleton } from "@material-ui/lab";
import {
  Link,
  ListItem,
  Popover,
  PopoverOrigin,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { HelperAccountsList } from "./Styles";
import { Storage } from "@dashboard-v3/api";

export default function CloudStorageDetails({ rule }: { rule: Rule }) {
  const { t } = useTranslation("rules");

  const typeRulePath = getTypePath(rule.actionType, rule);

  if (!("storage" in typeRulePath)) return null;

  if (!typeRulePath.storage) return null;

  const storage = typeRulePath.storage;

  if (storage.provider === "DROPBOX") {
    return storage.asUsers?.length ? (
      <div>
        {t("managedByMultipleAccounts")}
        <SelectedAccountsHelper storage={typeRulePath.storage} />
      </div>
    ) : (
      <DropboxAccount userId={storage.userId} asUser={storage.asUser} />
    );
  }

  if (storage.authentication === "ORGANIZATION") {
    if (storage.userIds?.length) {
      return (
        <div>
          {t("managedByMultipleAccounts")}
          <SelectedAccountsHelper storage={typeRulePath.storage} />
        </div>
      );
    }

    if (storage.asUser || storage.userId) {
      return (
        <div>
          {t("organizationAccount")} {storage.asUser || storage.userId}
        </div>
      );
    }

    return <div>{t("managedAutomaticallyByMxhero")}</div>;
  }

  if (storage.userId) {
    const [userEmail] = storage.userId.split(":");
    return <div>{userEmail}</div>;
  }

  return null;
}

function DropboxAccount({
  userId,
  asUser,
}: {
  userId: string;
  asUser?: string;
}) {
  const { t } = useTranslation("rules");
  const { items, isLoading } = useStorageAccounts();
  if (isLoading || !items) return <Skeleton />;

  const item = items.find(item => item.storageAccount?.userId === userId);

  if (!item) return null;

  return (
    <>
      <div>
        {t(`cloudStorageDetails.dropbox.team`, { team: item?.option?.title })}
      </div>

      {asUser && (
        <div>{t(`cloudStorageDetails.dropbox.asUser`, { email: asUser })}</div>
      )}
    </>
  );
}

const SelectedAccountsHelper = ({ storage }: { storage: Storage }) => {
  const { t } = useTranslation("rules");
  const [showAccounts, setShowAccounts] = useState<boolean>(false);
  const [helperAnchor, setHelperAnchor] = useState(null);
  const accounts =
    storage.provider === "DROPBOX" ? storage.asUsers : storage.userIds;

  const popoverProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    } as PopoverOrigin,
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    } as PopoverOrigin,
  };

  const handleShowAccounts = e => {
    setHelperAnchor(e.currentTarget);
    setShowAccounts(prev => !prev);
  };

  return (
    <div>
      <Link onClick={handleShowAccounts} style={{ cursor: "pointer" }}>
        {t("cloudStorage.balanced.accountHelper.link")}
        <Popover
          open={showAccounts}
          anchorEl={helperAnchor}
          anchorOrigin={popoverProps.anchorOrigin}
          transformOrigin={popoverProps.transformOrigin}
        >
          <HelperAccountsList>
            <Typography variant="subtitle2">
              {t("cloudStorage.balanced.accountHelper.title")}
            </Typography>
            {accounts.map(account => (
              <ListItem key={account} disableGutters>
                <Typography variant="body2">{account}</Typography>
              </ListItem>
            ))}
          </HelperAccountsList>
        </Popover>
      </Link>
    </div>
  );
};
