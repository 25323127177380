import { FormState } from "pages/CopyPolicies/types";

const uuidRegex =
  /^\{[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\}$/;

export default function isVariableId(value: string) {
  return uuidRegex.test(value);
}

export const isDefaultCondition = (index: number) => {
  return typeof index === "undefined";
};

export const canResetStorageProvider = (formState: FormState) => {
  const { storageProvider, predicates, defaultCopies } = formState;

  const predicatesWithPicker = predicates.some(p =>
    Boolean(p.folderPath.parentName)
  );

  return (
    storageProvider &&
    !predicatesWithPicker &&
    Object.keys(defaultCopies).some(type => {
      return !defaultCopies[type]?.folderPath?.parentName;
    })
  );
};
