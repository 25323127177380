/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import { Button } from "components/Forms/StyledComponents";
import { getEmailFieldLabel } from "../helpers";
import { LoadedEmail } from "../types";
import { EmailFieldContainer, EmailFieldsModalHeader } from "../Styled";

import { TestSources } from "@dashboard-v3/api";

interface Props {
  email: LoadedEmail;
  isOpen: boolean;
  onClose: () => void;
}

export default function EmailFieldsModal({ isOpen, onClose, email }: Props) {
  const { t } = useTranslation(["variables", "platformVariables"]);
  const [fields, setFields] = useState<TestSources>(email.sources);
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    setFields(() => {
      const { sources } = email;
      if (filters.length) {
        return sources.filter(({ id }) =>
          filters.includes(getEmailFieldLabel(id, t))
        );
      }
      return sources;
    });
  }, [filters.length]);

  const handleOnChange = (values: TestSources) => {
    setFilters(values.map(({ id }) => getEmailFieldLabel(id, t)));
  };

  const getEmailFieldValue = (values: string[]) => {
    if (values.length) {
      return values.map(value => (
        <Typography
          variant="subtitle2"
          style={{ wordWrap: "break-word", marginTop: "8px" }}
        >
          {value}
        </Typography>
      ));
    }
    return (
      <Typography variant="subtitle2">
        {t("variableTest.detailsModal.emptyValue")}
      </Typography>
    );
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle disableTypography>
        <EmailFieldsModalHeader>
          <div>
            <Typography variant="h6">
              {t("variableTest.detailsModal.title")}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {email.name}
            </Typography>
          </div>
          <CloseIcon fontSize="medium" onClick={onClose} />
        </EmailFieldsModalHeader>
        <Divider />
        <div style={{ marginTop: "24px" }}>
          <Autocomplete
            multiple
            filterSelectedOptions
            noOptionsText={t("variableTest.detailsModal.noResult")}
            limitTags={3}
            options={email.sources}
            onChange={(_, values) => handleOnChange(values as TestSources)}
            getOptionLabel={({ id }) => getEmailFieldLabel(id, t)}
            renderInput={params => (
              <TextField
                variant="outlined"
                name="fieldSearch"
                label={t("variableTest.detailsModal.filterLabel")}
                {...params}
              />
            )}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        {fields.map(({ id, values }) => (
          <EmailFieldContainer key={id}>
            <Typography variant="caption" color="textSecondary">
              {getEmailFieldLabel(id, t)}
            </Typography>
            {getEmailFieldValue(values)}
          </EmailFieldContainer>
        ))}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" size="large" wording="Close" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
}
