import { Draft } from "immer";
import { getStorage } from "pages/Rules/CloudStorage/utils";
import { FolderType } from "pages/Rules/StorageFilepath/FilepathSelector/types";
import {
  getCurrentPathFromLocation,
  isValidCollaboration,
  isValidSubfolder,
  isValidPath,
} from "pages/Rules/StorageFilepath/utils";
import { isBlank } from "utils/string";
import { State } from "../../types";

export default function validate(draft: Draft<State>) {
  const storage = getStorage(draft.rule);
  const currentPath = getCurrentPathFromLocation(storage.location);

  if (storage.location.copyPolicy) {
    draft.steps.storageFilepath.valid =
      !isBlank(storage.location.copyPolicy) && isValidSubfolder(storage);
  } else {
    const validPath =
      storage.location?.parentId !== FolderType.SOURCE_FOLDER
        ? isValidPath(currentPath)
        : true;
    draft.steps.storageFilepath.valid =
      validPath && isValidSubfolder(storage) && isValidCollaboration(storage);
  }
}
