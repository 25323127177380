/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { MenuItem, TextField } from "@material-ui/core";
import AutocompleteSearchInput from "components/AutocompleteSearchInput";
import { SelectContainer, StyledLabel } from "./Styled";
import useVariables from "utils/useVariables";
import { DashboardVariable } from "types";
import { FolderPath, FolderType } from "./types";

import SelectField from "components/Forms/SelectField";
import { StorageProvider } from "@dashboard-v3/api";
import { useEffect } from "react";
import { getMxheroStorageFolderStructure } from "../utils";

interface Params {
  provider: StorageProvider;
  folderPath: FolderPath;
  onChange: (type: FolderType, value: string) => void;
  searchValues: DashboardVariable[];
  subFolderError: { isError: boolean; errorMsg?: string };
}

export default function PathFields({
  provider,
  folderPath,
  searchValues,
  onChange,
  subFolderError,
}: Params) {
  const { t } = useTranslation("rules");
  const { parentName, folderStructure } = folderPath;
  const { mapVarToLabel } = useVariables();
  const enableParentFolder = isParentFolder(folderPath);
  const isEgnyte = provider === "EGNYTE" || provider === "EGNYTE_GOV";

  useEffect(() => {
    if (provider === "MXHERO_STORAGE") {
      const path = getMxheroStorageFolderStructure("REPLACE_ATTACHMENTS", t);
      onChange(FolderType.FOLDER_STRUCTURE, path);
    }
  }, [provider]);

  return (
    <SelectContainer twoColumns={enableParentFolder || isEgnyte}>
      {enableParentFolder && !isEgnyte && (
        <div>
          <StyledLabel>{t("parentFolder")}</StyledLabel>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={t("pickAFolderFromYourCloudStorage")}
            data-testid="rules-filepath_parentfolder-input"
            value={parentName}
            required
          />
        </div>
      )}
      {isEgnyte && (
        <div>
          <StyledLabel>{t("storageFilepath.egnyteSpace.label")}</StyledLabel>
          <SelectField
            name="egnyteSpace"
            options={egnyteSpaceOptions}
            value={folderPath.parentId || ""}
            onChange={e =>
              onChange(FolderType.PARENT_FOLDER, e.target.value as string)
            }
            renderOption={({ label, value }) => (
              <MenuItem key={value} value={value}>
                {t(label)}
              </MenuItem>
            )}
          />
        </div>
      )}
      <div>
        <StyledLabel>{t("subfolder")}</StyledLabel>
        <AutocompleteSearchInput
          type="text"
          testid="rules-filepath_subfolder-input"
          variables={searchValues}
          value={mapVarToLabel(folderStructure, searchValues)}
          onChange={val => onChange(FolderType.FOLDER_STRUCTURE, val)}
          required={!folderPath.parentId}
          error={subFolderError.isError}
          errorText={subFolderError?.errorMsg}
          disabled={provider === "MXHERO_STORAGE"}
        />
      </div>
    </SelectContainer>
  );
}

const egnyteSpaceOptions = [
  { label: "storageFilepath.egnyteSpace.shared", value: "shared" },
  {
    label: "storageFilepath.egnyteSpace.private",
    value: "private/{egnyte_user_name}",
  },
];

const isParentFolder = (folderPath: FolderPath) => {
  if (folderPath.parentId) {
    return folderPath.parentId !== FolderType.SOURCE_FOLDER;
  }
  return false;
};
