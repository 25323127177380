import { Group } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";

export async function fetchGroups(limit = 10, offset = 0, searchCriteria = "") {
  const query = new URLSearchParams();
  query.append("limit", limit.toString());
  query.append("offset", offset.toString());
  if (searchCriteria) {
    query.append("partialEmailOrName", searchCriteria);
  }

  return apiRequest<Group[]>("GET", `/groups?${query.toString()}`);
}

// TODO: MXH-3296
export async function fetchGroup(groupId: string) {
  return apiRequest<Group>("GET", `/groups/${encodeURIComponent(groupId)}`);
}
