import { Box, Paper, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function EmptyState({ onClick }) {
  const { t } = useTranslation("orgAndDomains");
  return (
    <Box
      display="flex"
      justifyContent="center"
      padding="30px"
      alignItems="center"
      component={Paper}
      boxShadow={0}
    >
      <Button
        color="primary"
        disableElevation
        variant="contained"
        onClick={onClick}
      >
        {t("dkim.setupBtn")}
      </Button>
    </Box>
  );
}
