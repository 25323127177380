import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AccountsTotal } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";

export default function Accounts() {
  const [totals, setTotals] = useState<AccountsTotal>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation("usageReports");

  useEffect(() => {
    async function fetchAccountsTotal() {
      try {
        setTotals(await apiRequest<AccountsTotal>("GET", "/accounts/total"));
      } catch (error) {
        console.error("fetchAccountsTotal -> error:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    fetchAccountsTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <Grid container spacing={2}>
        <Grid item xs>
          <Skeleton
            variant="rect"
            height="123px"
            style={{ borderRadius: "4px" }}
          />
        </Grid>
        <Grid item xs>
          <Skeleton
            variant="rect"
            height="123px"
            style={{ borderRadius: "4px" }}
          />
        </Grid>
      </Grid>
    );

  if (error)
    return (
      <Paper style={{ padding: "41px 15px" }}>
        <Typography color="error" variant="body2">
          {t("errors.fetchAccountsTotal")}
        </Typography>
      </Paper>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Paper style={{ padding: "15px 15px" }}>
          <Box mb="10px">
            <Typography variant="caption" style={{ color: grey[600] }}>
              {t("emailLabel")}
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: "1.2" }}>
              {t("accounts")}
            </Typography>
          </Box>

          <Typography variant="h4">{totals.member.toLocaleString()}</Typography>
        </Paper>
      </Grid>
      <Grid item xs>
        <Paper style={{ padding: "15px 15px" }}>
          <Box mb="10px">
            <Typography variant="caption" style={{ color: grey[600] }}>
              {t("emailLabel")}
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: "1.2" }}>
              {t("groups")}
            </Typography>
          </Box>
          <Typography variant="h4">{totals.list.toLocaleString()}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
