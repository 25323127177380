import styled from "styled-components";
import { DialogTitle, IconButton, Link } from "@material-ui/core";

export const FileExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  > span {
    font-style: italic;
    text-align: center;
  }
`;

export const ExampleLink = styled(Link)`
  cursor: pointer;
  width: fit-content;
`;

export const DialogHeader = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
