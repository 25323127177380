import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import isDomain from "utils/isDomain";
import VerifySection from "./VerificationExplainSection";
import { useTranslation } from "react-i18next";
import { CanRegisterDomainErrorType, ManualDomain } from "@dashboard-v3/api";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import useManualDomains from "./useManualDomains";

type Props = {
  onCancel: () => void;
  onDone: () => void;
};

type Step =
  | "LOADING_NAME"
  | "VALIDATING_NAME"
  | "INVALID_NAME"
  | "SHOWING_HELP";

type InvalidNameCause = CanRegisterDomainErrorType | "SYSTEM_FAILURE";

export default function NewItem({ onCancel, onDone }: Props) {
  const { t } = useTranslation("orgAndDomains");
  const { create } = useManualDomains();

  const [step, setStep] = useState<Step>("LOADING_NAME");
  const [invalidName, setInvalidName] = useState<{
    name: string;
    cause: InvalidNameCause;
  }>();

  const [domain, setDomain] = useState<ManualDomain>({
    name: "",
    txtEntry: "",
    lastStatus: "NOT_VERIFIED",
    createdAt: new Date().getTime(),
  });

  async function validateName() {
    setStep("VALIDATING_NAME");
    setInvalidName(null);
    try {
      await apiRequest(
        "GET",
        `/manual-domains/${encodeURIComponent(domain.name)}/verify-name`
      );

      setDomain(prev => ({
        ...prev,
        txtEntry: `mxhero-domain-check=${crypto.randomUUID()}`,
      }));

      setStep("SHOWING_HELP");
    } catch (error) {
      const code = getErrorCode<CanRegisterDomainErrorType>(error);
      let cause: InvalidNameCause = "SYSTEM_FAILURE";
      if (code === "DOMAIN_ALREADY_EXIST" || code === "DOMAIN_NOT_ALLOWED") {
        cause = code;
      }
      setInvalidName({ name: domain.name, cause });
      setStep("INVALID_NAME");
    }
  }

  async function saveAndVerify() {
    try {
      const savedDomain = await create(domain);
      return savedDomain;
    } catch (error) {
      console.error(error);
    } finally {
      onDone();
    }
  }

  return (
    <Box marginTop="20px" component={Paper} padding="20px">
      <Typography variant="h6">{t("manualDomains.connectLabel")}</Typography>
      <Box mt={3} display="flex" gridGap="15px" alignItems="baseline">
        <TextField
          label="Domain"
          variant="outlined"
          onChange={event => {
            const name = event.target.value;
            setDomain(prev => ({ ...prev, name }));
          }}
          style={{ minWidth: "210px" }}
          disabled={disabledInput(step)}
          value={domain?.name}
          error={Boolean(invalidName)}
          helperText={
            invalidName ? t(`manualDomains.${invalidName.cause}`) : ""
          }
        />
        {showAddButton(step) && (
          <ButtonWithLoading
            variant="contained"
            loading={step === "VALIDATING_NAME"}
            disableElevation
            color="primary"
            disabled={!isDomain(domain?.name || "")}
            onClick={() => {
              validateName();
            }}
          >
            {(step === "LOADING_NAME" || step === "INVALID_NAME") && "Add..."}
            {step === "VALIDATING_NAME" && "Validating..."}
          </ButtonWithLoading>
        )}
        <Button color="secondary" disableElevation onClick={onCancel}>
          {t("manualDomains.cancel")}
        </Button>
      </Box>

      <Box mt={4} display={step === "SHOWING_HELP" ? "block" : "none"}>
        <VerifySection domain={domain} onVerify={saveAndVerify} />
      </Box>
    </Box>
  );
}

function showAddButton(step: Step) {
  return step !== "SHOWING_HELP";
}

function disabledInput(step: Step) {
  return step === "VALIDATING_NAME" || step === "SHOWING_HELP";
}
