import { InputLabel, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const ProgressContainer = styled.div`
  margin-bottom: 50px;
  margin-top: 40px;
  text-align: center;
  height: 70%;
`;

export const StyledLabel = styled(InputLabel)`
  font-size: 0.8em;
  line-height: 1em;
  margin-bottom: 4px;
`;

export const SelectContainer = styled.div<{ twoColumns: boolean }>`
  grid-template-columns: ${({ twoColumns }) =>
    twoColumns ? "1fr 2fr" : "1fr"};
  align-items: center;
  column-gap: 15px;
  display: grid;
  margin-top: 10px;
`;

export const getDialogClasses = makeStyles(() => ({
  dialogPaper: {
    height: "560px",
  },
}));
