import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useState } from "react";
import styled from "styled-components";
import TransportAgentWarning, { WarningAction } from "./TransportAgentWarning";
import apiRequest from "utils/apiRequestWithErrorCode";
import { ResponseOrganization } from "@dashboard-v3/api";
import useClipboard from "utils/useClipboard";

type ModalState = {
  isOpen: boolean;
  type: WarningAction;
};

export interface Props {
  gateway: ResponseOrganization["gateway"];
  onChange: (organization: ResponseOrganization) => void;
}

export default function TransportAgents({ gateway, onChange }: Props) {
  const { t } = useTranslation("orgAndDomains");
  const { enqueueSnackbar } = useSnackbar();
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    type: null,
  });
  const [saving, setSaving] = useState(false);
  const copyToClipboard = useClipboard();

  const { allowedTransportAgent, previousAllowedTransportAgent } = gateway;

  const handleAction = (status: boolean, action: WarningAction) => e => {
    if (action === WarningAction.GENERATE && previousAllowedTransportAgent) {
      enqueueSnackbar(t("org.transportAgent.generate.warning"), {
        variant: "info",
      });
    } else {
      setModalState({ isOpen: status, type: action });
    }
  };

  async function changeTransportAgent() {
    const { type } = modalState;
    setSaving(true);
    try {
      const method = type === WarningAction.GENERATE ? "POST" : "DELETE";
      const organization = await apiRequest<ResponseOrganization>(
        method,
        "/organization/transport-agent"
      );
      console.log("Organization: ", organization);
      onChange(organization);
    } catch (e) {
      const key =
        type === WarningAction.GENERATE ? "generate.error" : "remove.error";

      enqueueSnackbar(t(`org.transportAgent.${key}`), { variant: "error" });
    } finally {
      setSaving(false);
      setModalState({ isOpen: false, type: null });
    }
  }

  return (
    <>
      <FieldWithButtonContainer>
        <TextField
          variant="outlined"
          fullWidth
          name="allowedTransportAgent"
          label={t("org.transportAgent.field.label")}
          value={allowedTransportAgent}
          InputProps={{
            disabled: true,
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ gap: "5px", marginRight: "5px" }}
              >
                <Tooltip title={t("org.transportAgent.copy.tooltip")}>
                  <IconButton
                    onClick={() => copyToClipboard(allowedTransportAgent)}
                    edge="end"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("org.transportAgent.generate.tooltip")}>
                  <IconButton
                    onClick={handleAction(true, WarningAction.GENERATE)}
                    edge="end"
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            inputProps: {
              "data-testid": "orgdomains__transport-agent-input",
            },
          }}
        />
      </FieldWithButtonContainer>
      {previousAllowedTransportAgent && (
        <FieldWithButtonContainer>
          <TextField
            variant="outlined"
            name="previousAllowedTransportAgent"
            label={t("org.transportAgent.previous.label")}
            value={previousAllowedTransportAgent}
            disabled
          />
          <Button
            variant="text"
            color="secondary"
            onClick={handleAction(true, WarningAction.REMOVE)}
          >
            {t("org.transportAgent.remove.btn")}
          </Button>
        </FieldWithButtonContainer>
      )}
      <TransportAgentWarning
        type={modalState.type}
        isOpen={modalState.isOpen}
        loading={saving}
        onClose={() => setModalState(prev => ({ ...prev, isOpen: false }))}
        onConfirm={changeTransportAgent}
      />
    </>
  );
}

const FieldWithButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;

  .MuiTextField-root {
    width: 50%;
  }
`;
