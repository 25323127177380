import { useTranslation } from "react-i18next";
import { List, ListItem, Tooltip } from "@material-ui/core";
import InfoSharpIcon from "@material-ui/icons/InfoSharp";
import { HelperContainer } from "./styled";
import { ValidationKey } from "@dashboard-v3/api/src/schemas/users";

type PasswordHelperProps = {
  errors: ValidationKey[];
};

export default function PasswordErrorHelper({ errors }: PasswordHelperProps) {
  const { t } = useTranslation("users");

  if (errors.length > 1) {
    return (
      <HelperContainer>
        {t("error.password.tooltip")}
        <Tooltip
          title={
            <List dense disablePadding>
              {errors.map(key => (
                <ListItem key={key} disableGutters>
                  {t(`error.password.${key}`)}
                </ListItem>
              ))}
            </List>
          }
        >
          <InfoSharpIcon />
        </Tooltip>
      </HelperContainer>
    );
  }

  return <HelperContainer>{t(`error.password.${errors[0]}`)}</HelperContainer>;
}
