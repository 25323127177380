import { TimePeriodStat } from "@dashboard-v3/api";
import reduce90Days, { StatCode } from "../reduce90Days";

type Totals = ReturnType<typeof emptyResults>;

export type EmailsSumData = Array<{ date: number } & Totals>;

export function parseData(data: Array<TimePeriodStat>): EmailsSumData {
  return reduce90Days<Totals>(
    data,
    (totals, key, valueOfKey) => {
      if (key.startsWith("rule_mail_executed_")) {
        const action = getAction(key);
        if (action) {
          totals[action] += valueOfKey;
        }

        const target = getTarget(key);
        if (target) {
          totals[target] += valueOfKey;
        }

        totals["all"] += valueOfKey;
      }
      return totals;
    },
    emptyResults
  );
}

function emptyResults() {
  return {
    drag_and_drop: 0,
    replace_attachments: 0,
    protect_emails: 0,
    move_emails: 0,
    copy_emails: 0,
    block_attachments: 0,
    email_flow: 0,
    archiving_address: 0,
    mail_box: 0,
    storage_webhook: 0,
    all: 0,
  };
}

const actions = [
  "drag_and_drop",
  "replace_attachments",
  "protect_emails",
  "move_emails",
  "copy_emails",
  "block_attachments",
] as const;

type Action = typeof actions[number];

function getAction(key: StatCode): Action | undefined {
  return actions.find(action => key.includes(action));
}

const targets = [
  "email_flow",
  "archiving_address",
  "mail_box",
  "storage_webhook",
];

type Target = typeof targets[number];

function getTarget(key: StatCode): Target | undefined {
  return targets.find(target => key.includes(target));
}
