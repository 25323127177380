/**
 *
 * @param text Text with sources. The sources are text between characters { and }
 * @returns the list of sources (without the {})
 */
export default function extractSources(text: string) {
  const resultsIterator = text.matchAll(/{(\w+)}/g);
  const result = [];
  for (let match of resultsIterator) {
    result.push(match[1]);
  }
  return result;
}
