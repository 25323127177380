/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { Box, LinearProgress, Tooltip, Typography } from "@material-ui/core";
import apiRequest from "utils/apiRequestWithErrorCode";
import formatNumber from "utils/formatNumber";
import getStatusGrouped from "../getStatusGrouped";
import { Rule } from "../types";
import { MailboxRule, MailboxRuleStats } from "@dashboard-v3/api";
import RuleStatsItemHeader from "./RuleStatsItemHeader";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { StyledError, StyledFab } from "../List/Styled";

type RuleStats = {
  ruleStatus: MailboxRule["executionStatus"];
  emailsProcessed: number;
  emailsUploaded: number;
  filesUploaded: number;
  accountsTotal: number;
  messagesFiltered: number;
  emailsDiscarded: number;
};

interface Props {
  rule: Rule;
  renderActionBtn?: (rule: Rule) => JSX.Element;
}

export default function RuleStatistics({ rule, renderActionBtn }: Props) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<RuleStats>(initStats());

  useEffect(() => {
    const fetchStats = async () => {
      const { id } = rule;
      setLoading(true);
      try {
        const stats = await apiRequest<MailboxRuleStats>(
          "GET",
          `/rules/${id}/stats`
        );

        setStats(toRuleStats(stats));
      } catch (error: unknown) {
        enqueueSnackbar(t("mailboxStats.error.fetchStats"), {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, []);

  if (loading) {
    return (
      <div style={{ padding: "10px 45px 27px 45px" }}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <StatsWrapper>
      <TooltipOnError stats={stats}>
        <Typography variant="subtitle1">
          {t(`mailboxStats.statusTypes.${getStatusGrouped(stats.ruleStatus)}`)}
        </Typography>
      </TooltipOnError>

      {Object.keys(stats).map(
        statKey =>
          statKey !== "ruleStatus" && (
            <StatsItem key={statKey}>
              <RuleStatsItemHeader titleKey={statKey} />
              <DrawNumber>{stats[statKey]}</DrawNumber>
            </StatsItem>
          )
      )}
      {renderActionBtn && renderActionBtn(rule)}
    </StatsWrapper>
  );
}

function TooltipOnError({ stats, children }) {
  const { t } = useTranslation("rules");

  const status = getStatusGrouped(stats.ruleStatus);

  if (status !== "ACCOUNTS_WITH_ERRORS") return children;

  return (
    <Tooltip
      arrow
      style={{ maxWidth: "none" }}
      placement="top"
      title={
        <StyledError display="block" variant="caption">
          {t("mailboxStats.statusTypes.accountWithErrorsDetails")}
        </StyledError>
      }
    >
      <Box display="flex">
        {children}
        <Box ml={1}>
          <StyledFab size="small">
            <ErrorOutlineIcon fontSize="small" />
          </StyledFab>
        </Box>
      </Box>
    </Tooltip>
  );
}

function DrawNumber({ children }: { children: number }) {
  if (children < 1000000)
    return <Typography variant="h6">{formatNumber(children)}</Typography>;

  return (
    <Tooltip title={formatNumber(children)} placement="bottom">
      <Typography variant="h6">
        {formatNumber(children, {
          notation: "compact",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
      </Typography>
    </Tooltip>
  );
}

const initStats = (): RuleStats => ({
  ruleStatus: null,
  accountsTotal: 0,
  messagesFiltered: 0,
  emailsProcessed: 0,
  emailsUploaded: 0,
  filesUploaded: 0,
  emailsDiscarded: 0,
});

const toRuleStats = (stats: MailboxRuleStats): RuleStats => {
  if (stats) {
    const { accounts, emails } = stats;
    return {
      ruleStatus: stats.executionStatus,
      accountsTotal: accounts.amountAccountsTotal,
      messagesFiltered: emails.amountMessagesFiltered,
      emailsDiscarded: emails.amountEmailsDiscarded || 0,
      emailsProcessed: emails.amountEmailsProcessed,
      emailsUploaded: emails.amountEmailsUploaded,
      filesUploaded: emails.amountFilesUploaded,
    };
  }
};

const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2% 27px 2%;
`;

const StatsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
