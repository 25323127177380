import styled from "styled-components";

const ChartHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 30px;
  padding-left: 30px;
  justify-items: stretch;
  align-items: baseline;
  justify-content: space-between;
`;

export default ChartHeader;
