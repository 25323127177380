import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  Button,
  capitalize,
  Grid,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import LensIcon from "@material-ui/icons/Lens";
import { TextWithElipisis } from "./styled";
import { getFilename, getParsedDate, isExpired } from "../utils";

import { MxheroShare } from "@dashboard-v3/api";

interface StorageShareItemProps {
  item: MxheroShare;
  loading: boolean;
  onRevoke: (id: string) => void;
}

export default function MxheroShareItem(props: StorageShareItemProps) {
  const { t } = useTranslation("storageShares");
  const history = useHistory();
  const { item, onRevoke, loading } = props;
  const isAvailable = item.expiresAt ? !isExpired(item.expiresAt) : true;
  const classes = useStyles({ isAvailable });
  const statusText = isAvailable
    ? t("list.item.status.enabled")
    : t("list.item.status.expired");

  const onEdit = () => {
    history.push({
      pathname: `/mxhero-shares/${item.shareId}`,
      state: { selectedShare: item, shareId: item.shareId },
    });
  };

  return (
    <ListItem disableGutters>
      <Accordion className={classes.accordion} expanded={false}>
        <AccordionSummary style={{ cursor: "default" }}>
          <Tooltip className={classes.statusContainer} title={statusText}>
            <LensIcon htmlColor={isAvailable ? green.A400 : orange.A200} />
          </Tooltip>
          <Grid container style={{ rowGap: 4 }}>
            <Grid item lg={12} className={classes.gridItem} alignItems="center">
              <Typography
                className={classes.gridItemTxt}
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {capitalize(item.type.toLowerCase())}:
              </Typography>
              <Tooltip title={item.itemId}>
                <TextWithElipisis display="inline" variant="subtitle2">
                  {getFilename(item)}
                </TextWithElipisis>
              </Tooltip>
            </Grid>
            <Grid item lg={4} className={classes.gridItem} alignItems="center">
              <Typography
                className={classes.gridItemTxt}
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {t("list.item.owner")}
              </Typography>
              <Tooltip title={item.owner}>
                <TextWithElipisis display="inline" variant="subtitle2">
                  {item.owner}
                </TextWithElipisis>
              </Tooltip>
            </Grid>

            <Grid item lg={4} className={classes.gridItem} alignItems="center">
              <Typography
                className={classes.gridItemTxt}
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {item.canDownload
                  ? t("list.item.isDownloadable")
                  : t("list.item.isNotDownloadable")}
              </Typography>
            </Grid>
            <Grid item lg={4} className={classes.gridItem} alignItems="center">
              <Typography
                className={classes.gridItemTxt}
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {!item.expiresAt
                  ? t("list.item.noExpiration")
                  : t("list.item.expiresAt", {
                      date: getParsedDate(item.expiresAt),
                    })}
              </Typography>
            </Grid>
          </Grid>
          <Button
            className={classes.revokeBtn}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => onRevoke(item.shareId)}
            disabled={loading || isExpired(item.expiresAt)}
          >
            {t("list.item.expireBtn")}
          </Button>
          <Button
            className={classes.editBtn}
            size="small"
            variant="outlined"
            color="default"
            onClick={() => onEdit()}
          >
            {t("common:edit")}
          </Button>
        </AccordionSummary>
      </Accordion>
    </ListItem>
  );
}

const useStyles = makeStyles<
  Theme,
  { isAvailable: boolean },
  | "accordion"
  | "gridItem"
  | "gridItemTxt"
  | "statusContainer"
  | "revokeBtn"
  | "editBtn"
  | "overrides"
>({
  accordion: { width: "100%" },
  gridItem: { display: "flex", gap: "5px" },
  gridItemTxt: { fontSize: "13px" },
  statusContainer: {
    fontSize: "0.7rem",
    justifySelf: "start",
    alignSelf: "center",
    marginRight: 20,
    cursor: "pointer",
  },
  revokeBtn: { alignSelf: "center" },
  editBtn: { alignSelf: "center", marginLeft: "10px" },
  overrides: {
    MuiExpansionPanelSummary: {
      root: {
        "&:hover:not(.Mui-disabled)": {
          cursor: "default",
        },
      },
    },
  },
});
