import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import {
  Paper,
  CircularProgress,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { addUserInSession } from "utils/authentication";
import useProgress from "./useProgress";
import { endOnPremiseRegistration } from "./api";
import { isErrorWithCode } from "utils/apiRequestWithErrorCode";

const StyledPanel = styled(Paper)`
  border-radius: 8px;
  display: grid;
  grid-row-gap: 18px;
  justify-items: center;
  width: 390px;
  padding: 50px 65px;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const WaitingProgress = styled(LinearProgress)`
  margin-top: 10px;
`;

const EndRegistationPanel = () => {
  const { id } = useParams();
  const history = useHistory();

  const [loading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState();
  const { progress, start } = useProgress(() => {
    history.push("/");
  });
  const { t } = useTranslation("endRegistration");

  useEffect(() => {
    async function fetchData() {
      try {
        const { user } = await endOnPremiseRegistration(id);
        addUserInSession(user);
        setIsLoading(false);
        start();
      } catch (error) {
        let message;
        if (isErrorWithCode(error)) {
          message = t([`error.${error.code}`, "error.SYSTEM_FAILURE"]);
        }
        setErrorMsg(message);
        setIsLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return (
      <StyledPanel>
        <CircularProgress />
      </StyledPanel>
    );
  }

  if (errorMsg) {
    return (
      <StyledPanel>
        <FullWidthContainer>
          <Typography
            variant="body2"
            align="center"
            style={{ marginBottom: "5px" }}
          >
            {errorMsg}
          </Typography>
        </FullWidthContainer>
        <Button
          data-testid="login__switch-register"
          onClick={() => history.push("/register")}
          size="large"
        >
          {t("switchToRegister")}
        </Button>
      </StyledPanel>
    );
  }

  return (
    <>
      <StyledPanel style={{ textAlign: "center" }}>
        <Trans
          t={t}
          i18nKey="success"
          components={{ div: <div />, b: <strong /> }}
        />
        <FullWidthContainer>
          <WaitingProgress variant="determinate" value={progress} />
        </FullWidthContainer>
      </StyledPanel>
    </>
  );
};

export default EndRegistationPanel;
