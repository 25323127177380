import SwitchField from "components/Forms/SwitchField";
import { OptionGroup } from "components/Styled";
import { ChangeEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRuleContext } from "../../context/ruleContext";

const ArchivingAddressOptions = () => {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const filter = state.rule.copyEmails.archivingAddress.filter;

  useEffect(() => {
    return () =>
      dispatch({
        type: "updateFilterTarget",
        payload: null,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterBySender: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;
    dispatch({
      type: "updateFilterTarget",
      payload: checked ? { filterBySender: [{ type: "ORGANIZATION" }] } : null,
    });
  };

  return (
    <OptionGroup>
      <SwitchField
        name="filterBySender"
        label={t("archivingFilter.filterBySender.label")}
        description={t("archivingFilter.filterBySender.desc")}
        value={!!filter?.filterBySender}
        onChange={handleFilterBySender}
      />
    </OptionGroup>
  );
};

export default ArchivingAddressOptions;
