import EmptyState from "./EmptyState";
import { useState } from "react";
import Item from "./Item";
import NewItem from "./NewItem";
import { Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useRecords from "./useRecords";
import { DKIMRecord } from "@dashboard-v3/api";

type State = "EMPTY" | "NEW_ITEM" | "LIST";

export default function List() {
  const { t } = useTranslation("orgAndDomains");

  const { domainDkimState, updateRecords, records } = useRecords();

  const [state, setState] = useState<State>(records.length ? "LIST" : "EMPTY");

  function newVerification(record: DKIMRecord) {
    const updated = [...records];
    const index = updated.findIndex(item => item.domain === record.domain);
    if (index >= 0) {
      updated[index] = record;
    } else {
      updated.push(record);
    }

    updateRecords(updated);
    setState("LIST");
  }

  function recordDeleted(record: DKIMRecord) {
    const updated = [...records];
    const index = updated.findIndex(item => item.domain === record.domain);
    updated.splice(index, 1);
    updateRecords(updated);
    setState(updated.length ? "LIST" : "EMPTY");
  }

  function cancelNewItem() {
    setState(records.length ? "LIST" : "EMPTY");
  }

  return (
    <>
      {state === "EMPTY" && <EmptyState onClick={() => setState("NEW_ITEM")} />}
      {state === "NEW_ITEM" && (
        <NewItem
          domainDkimState={domainDkimState}
          onCancel={cancelNewItem}
          onVerifyResult={newVerification}
        />
      )}
      {state === "LIST" && (
        <Box mb={1}>
          <Button
            color="primary"
            disableElevation
            variant="contained"
            onClick={() => setState("NEW_ITEM")}
          >
            {t("dkim.setupBtn")}
          </Button>
        </Box>
      )}

      {records.map(record => (
        <Item
          record={record}
          onVerifyResult={newVerification}
          onDelete={() => recordDeleted(record)}
        />
      ))}
    </>
  );
}
