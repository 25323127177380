import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SwitchField from "components/Forms/SwitchField";
import useOrganization from "utils/useOrganization";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import { OrganizationUpdates } from "@dashboard-v3/api";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { chatGptDefaultModel, chatGptModels } from "@dashboard-v3/shared";

type State = {
  enabled: boolean;
  token: string;
  model: OrganizationUpdates["openAI"]["model"];
  tokenError: boolean;
  loading: boolean;
  isSaveDisabled: boolean;
};

export default function OpenAIOption() {
  const { t } = useTranslation("orgAndDomains");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [state, setState] = useState<State>(initState);
  const { organization, updateOrganization } = useOrganization();

  const updateState = (update: Partial<State>) => {
    setState(prev => ({ ...prev, ...update }));
  };

  useEffect(() => {
    if (organization) {
      updateState({
        enabled: !!organization?.openAI?.token,
        token: organization?.openAI?.token || "",
        model: organization?.openAI?.model || chatGptDefaultModel,
      });
    }
  }, [organization]);

  const saveOpenAISettings = async () => {
    updateState({ loading: true, isSaveDisabled: true });
    try {
      const update: OrganizationUpdates = {
        openAI: state.enabled
          ? { token: state.token, model: state.model }
          : null,
      };

      await updateOrganization(update, true);
    } catch (error) {
      if (getErrorCode(error) === "INVALID_OPEN_AI_TOKEN") {
        updateState({ tokenError: true });
      } else {
        enqueueSnackbar(t("org.error"), { variant: "error" });
      }
    } finally {
      updateState({ loading: false });
    }
  };

  const handleEnableOpenAI: ChangeEventHandler<HTMLInputElement> = async e => {
    const { checked: enabled } = e.target;
    const savedToken = organization?.openAI?.token;
    const savedModel = organization?.openAI?.model;
    const update: Partial<State> = { enabled, tokenError: false };

    update.enabled = enabled;

    if (enabled) {
      update.token = savedToken || "";
      update.model = savedModel || chatGptDefaultModel;
      update.isSaveDisabled = true;
    } else {
      const savedEnabled = Boolean(organization?.openAI);
      update.isSaveDisabled = !savedEnabled;
    }

    updateState(update);
  };

  const handleOpenAIToken: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    updateState({
      tokenError: false,
      token: value,
      isSaveDisabled: !value || value === organization?.openAI?.token,
    });
  };

  const handleOpenAIModel = (_, value) => {
    updateState({
      model: value,
      isSaveDisabled: !state.token || organization?.openAI?.model === value,
    });
  };

  return (
    <Paper className={classes.container}>
      <SwitchField
        name="enableOpenAI"
        label={t("openAI.enable.label")}
        value={state.enabled}
        onChange={handleEnableOpenAI}
        disabled={state.loading}
      />

      {state.enabled && (
        <>
          <div className={classes.tokenWrapper}>
            <div className={classes.modelSelector}>
              <Typography
                variant="caption"
                style={{ fontWeight: 500, fontSize: "13px" }}
              >
                {t("openAI.model.label")}
              </Typography>
              <ToggleButtonGroup
                value={state.model}
                onChange={handleOpenAIModel}
                exclusive
              >
                {chatGptModels.map(version => (
                  <ToggleButton
                    key={version}
                    size="small"
                    value={version}
                    className={classes.modelButton}
                  >
                    {t(`openAI.model.options.${version}`)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            <TextField
              name="openAIToken"
              label={t("openAI.token.label")}
              variant="outlined"
              fullWidth
              className={classes.tokenInput}
              value={state.token}
              onChange={handleOpenAIToken}
              error={state.tokenError}
              helperText={state.tokenError && t("openAI.token.invalid")}
            />
          </div>
        </>
      )}
      {showSaveButton(state) && (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={state.isSaveDisabled}
            onClick={saveOpenAISettings}
          >
            {t("openAI.token.saveBtn")}
          </Button>
        </Box>
      )}
    </Paper>
  );
}

/** Not show when openIA is disabled and already saved */
function showSaveButton(state: State) {
  if (!state.isSaveDisabled) return true;
  if (state.enabled) return true;
  return false;
}

const initState = () => ({
  enabled: false,
  loading: false,
  tokenError: false,
  token: "",
  model: null,
  isSaveDisabled: true,
});

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "16px",
  },
  modelSelector: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginBottom: "10px",
  },
  modelButton: { minWidth: "8rem", padding: "0 10px", fontSize: "12px" },
  tokenWrapper: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    marginTop: "16px",
  },
  tokenInput: { maxWidth: "70%", display: "block" },
});
