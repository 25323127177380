import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

export type Props = {
  message: string;
  onAccept: () => void;
  actionable: (openConfirm: (event: any) => void) => ReactNode;
};

export default function Confirm({ message, onAccept, actionable }: Props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");

  const accept = e => {
    e.stopPropagation();
    setOpen(false);
    onAccept();
  };

  const closeDialog = e => {
    e.stopPropagation();
    setOpen(false);
  };

  const openDialog = event => {
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      {actionable(openDialog)}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={closeDialog}
        onClick={e => e.stopPropagation()}
      >
        <DialogContent data-testid="confirm-delete-dialog">
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} data-testid="confirm-dialog--no">
            {t("no")}
          </Button>
          <Button
            onClick={accept}
            color="primary"
            variant="contained"
            disableElevation
            autoFocus
            data-testid="confirm-dialog--yes"
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
