import { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import HeaderMenu from "./Menu";
import LanguageSelector from "components/LanguageSelector";
import { getUser } from "utils/authentication";
import OrganizationSelector from "./OrganizationSelector";

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledToolbar = styled(Toolbar)`
  border-bottom: 1px solid #d6dbe0;
  display: grid;
  grid-template-columns: 240px 1fr;
  justify-items: start;
  padding-left: 30px;
  padding-right: 40px;
`;

const Logo = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 600;
`;

const Header = () => {
  const [user, setUser] = useState(getUser());
  const classes = useStyles();

  return (
    <AppBar
      className={classes.appBar}
      color="inherit"
      position="fixed"
      elevation={0}
    >
      <StyledToolbar>
        <Logo>mxHERO</Logo>
        <div style={{ justifySelf: "end", display: "flex" }}>
          {user?.superUser && <OrganizationSelector />}
          <LanguageSelector />
          <HeaderMenu user={user} onUserChange={setUser} />
        </div>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;
