export const timeRangeOptions = [
  "ALL",
  "REPEAT_ALL",
  "SINCE_A_DATE",
  "REPEAT_SINCE",
  "UP_TO_A_DATE",
  "REPEAT_UP_TO",
  "BETWEEN_DATES",
] as const;

export type TimeRangeOption = typeof timeRangeOptions[number];

const repeateble = ["REPEAT_ALL", "REPEAT_SINCE", "REPEAT_UP_TO"];

export function isRepeatable(timeRangeOption: TimeRangeOption) {
  return repeateble.includes(timeRangeOption);
}
