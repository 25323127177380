import { Collaboration, Content, Location, Storage } from "@dashboard-v3/api";
import { Filepath } from "./types";
import { Rule } from "../types";
import { getStorage } from "../CloudStorage/utils";
import { isBlank } from "utils/string";
import { ParsedContent } from "../RuleActions/types";
import { TFunction } from "react-i18next";

export const controlsInit = () => ({
  enablePolicy: false,
  enableEmailSubfolder: false,
  enableBodySubfolder: false,
  enableAttachmentsSubfolder: false,
  enableCollaborate: false,
});

export const emptyLocation = (): Location => ({
  copyPolicy: null,
  emailSubfolder: null,
  folderStructure: null,
  parentId: null,
  parentName: null,
});

export function canCollaborate(rule: Rule) {
  const { targetType } = rule;
  const { provider, allManagedUserIds } = getStorage(rule);

  if (allManagedUserIds) return false;

  return (
    (targetType === "MAIL_BOX" || targetType === "EMAIL_FLOW") &&
    provider !== "EGNYTE"
  );
}

export const getCurrentPath = (value: Filepath) => {
  return getCurrentPathFromLocation(value?.location);
};

export const getCurrentPathFromLocation = (location: Location) => {
  if (!location) return "";
  const { parentName, folderStructure } = location;
  if (parentName) {
    return folderStructure ? `${parentName}/${folderStructure}` : parentName;
  }
  return folderStructure;
};

export const isValidPath = (val: string) => {
  return !isBlank(val);
};

export const isValidSubfolder = (storage: Storage) => {
  const { location } = storage;
  if ("emailSubfolder" in location) {
    return !isBlank(location.emailSubfolder);
  }
  if ("bodySubfolder" in location) {
    return !isBlank(location.bodySubfolder);
  }
  if ("attachmentsSubfolder" in location) {
    return !isBlank(location.attachmentsSubfolder);
  }
  return true;
};

export const isValidCollaboration = (storage: Storage) => {
  if ("collaboration" in storage) {
    return (
      !!storage.collaboration.position?.toString() &&
      !!storage.collaboration.role
    );
  }
  return true;
};

export function resetCollaboration(): Collaboration {
  const collaboration: Collaboration = {
    position: null,
    role: null,
  };

  return collaboration;
}

export const toParsedContent = (content: Content[]): ParsedContent =>
  content.reduce((acc, item) => {
    if (item.type === "EML" || item.type === "MSG") {
      return { ...acc, email: item };
    }
    if (item.type === "ATTACHMENTS") {
      return { ...acc, attachments: item };
    }
    if (item.type === "PDF") {
      return { ...acc, pdf: item };
    }
    return acc;
  }, {});

export const actionsByContentType = {
  email: (item: Content) => item.type === "EML" || item.type === "MSG",
  attachments: (item: Content) => item.type === "ATTACHMENTS",
  pdf: (item: Content) => item.type === "PDF",
};

export function getDefaultFolderStructure(
  actionType: Rule["actionType"],
  targetType: Rule["targetType"],
  t: TFunction<"rules">
) {
  const actionName = t(`actions.${actionType}.title`, { ns: "rules" });

  if (actionType === "DRAG_AND_DROP") {
    return `${actionName}/{from_address}/{subject_normalized}`;
  }

  let folderStructure = "{subject_normalized}/{sent_or_received_date_yyyyMMdd}";

  switch (targetType) {
    case "MAIL_BOX":
      folderStructure = `/{inbox_folder_name}/{from_address}/${folderStructure}`;
      break;

    case "EMAIL_FLOW":
      folderStructure = `/{email_address}/{email_peer_address}/${folderStructure}`;
      break;

    case "ARCHIVING_ADDRESS":
      folderStructure = `/{from_address}/${folderStructure}`;
      break;

    default:
      break;
  }

  return actionName + folderStructure;
}

export function getMxheroStorageFolderStructure(
  actionType: Rule["actionType"],
  t: TFunction
) {
  const actionName = t(`actions.${actionType}.title`, { ns: "rules" });
  const folderStructure = `{email_address}/{email_peer_address}/{sent_date_yyyyMMdd_HHmm}/{subject_normalized}`;
  return `${actionName}/${folderStructure}`;
}

export const formatTypeToField = (type: string) => {
  switch (type) {
    case "EML":
      return "email";
    case "ATTACHMENTS":
    case "PDF":
      return type.toLocaleLowerCase();
    default:
      return "";
  }
};
