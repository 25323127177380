/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
import { List, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import Item from "./Item";
import { TracingEvent } from "@dashboard-v3/api";

const StyledPaper = styled(Paper)`
  min-height: 100px;
  align-self: start;
  align-items: center;
  display: grid;
  padding: 16px;
  text-align: center;
`;

const StyledList = styled(List)`
  li:first-child {
    padding-top: 0;
  }
  li:last-child {
    padding-bottom: 0;
  }
`;

type Params = {
  results: TracingEvent[] | null;
};

export default function TracingList({ results }: Params) {
  const { t } = useTranslation("tracing");

  if (!results) return null;

  if (results.length === 0) {
    return (
      <StyledPaper>
        <Typography>{t("noEmailEventsFound")}</Typography>
      </StyledPaper>
    );
  }

  return (
    <StyledList data-testid="tracing__result-list" disablePadding>
      {results.map(emailEvent => (
        <Item emailEvent={emailEvent} key={emailEvent.eventId} />
      ))}
    </StyledList>
  );
}
