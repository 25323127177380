import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  AccordionDetails,
  AccordionSummary,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StepHeader } from "components/Styled";
import { Button } from "components/Forms/StyledComponents";

export const ContentWrapper = styled.div`
  padding: 24px;
`;

export const StyledDetails = styled(AccordionDetails)`
  padding: 8px 24px 24px;
  flex-direction: column;
`;

export const ConfirmButton = styled(Button)`
  margin-top: 24px;
`;

export const StyledSummary = ({ children }) => {
  return (
    <AccordionSummary
      data-testid="action__advanced-options-expand"
      expandIcon={<ExpandMoreIcon />}
      style={{ padding: "0 24px" }}
    >
      {children}
    </AccordionSummary>
  );
};

export const ErrorMessage = ({ isError, wording, ...rest }) => {
  return (
    isError && (
      <FormHelperText margin="dense" error {...rest}>
        {wording}
      </FormHelperText>
    )
  );
};

export const SectionTitle = ({ type }) => {
  const { t } = useTranslation("rules");

  return (
    <StepHeader>
      <Typography variant="h6">1. {t("action")}</Typography>
      <Typography color="primary" variant="h6">
        {t(`actions.${type}.title`)}
      </Typography>
    </StepHeader>
  );
};
