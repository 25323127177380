import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useQuery from "utils/useQuery";
import { Button, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { SignInButton } from "components/Landing/Buttons";
import PageLoading from "components/PageLoading";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import LoginSignInOptions from "./SignInOptions";
import {
  fetchSignInOptions,
  LoginOptionNames,
  LoginOptionsErrors,
} from "./api";
import Panel from "./Panel";
import AuthCodeForm from "./AuthCodeForm";
import dayjs from "dayjs";

const LoginPanel = () => {
  const { t } = useTranslation("login");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [requires2FA, setRequires2FA] = useState(false);
  const [signInOptions, setSignInOptions] = useState<LoginOptionNames[] | null>(
    null
  );

  const handleInputChange = e => {
    setUserEmail(e.target.value);
  };

  useEffect(() => {
    const code = query.get("msg");
    if (code === "SESSION_EXPIRED") {
      enqueueSnackbar(t("sessionExpired"), { variant: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async e => {
    e.preventDefault();
    closeSnackbar();
    try {
      setIsLoading(true);
      const result = await fetchSignInOptions(userEmail);
      setSignInOptions(result);
      setIsLoading(false);
    } catch (error: unknown) {
      console.error(error);
      const message = t([
        `error.${getErrorCode<LoginOptionsErrors>(error)}`,
        "error.SYSTEM_FAILURE",
      ]);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    setUserEmail("");
    setSignInOptions(null);
  };

  const handleLoginError = (error: unknown) => {
    const errorCode = getErrorCode(error);
    if (errorCode === "AUTH_CODE_REQUIRED") {
      const codeExpiration = dayjs().unix().toString();
      setRequires2FA(true);
      window.sessionStorage.setItem("2FA-EXPIRATION", codeExpiration);
    } else {
      const message = t([`error.${errorCode}`, "error.SYSTEM_FAILURE"]);
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  if (requires2FA) {
    return (
      <Panel>
        <AuthCodeForm email={userEmail} />
      </Panel>
    );
  }

  if (signInOptions) {
    return (
      <Panel>
        <LoginSignInOptions
          handleGoBack={handleGoBack}
          userEmail={userEmail}
          availableOptions={signInOptions}
          onLoginError={handleLoginError}
        />
      </Panel>
    );
  }

  return (
    <>
      <PageLoading loading={isLoading} />

      <form data-testid="loginForm">
        <Panel>
          <div>
            <Typography
              variant="body2"
              align="center"
              style={{ width: "100%", marginBottom: "5px" }}
            >
              {t("enterYourEmail")}
            </Typography>
            <TextField
              fullWidth
              inputProps={{ "data-testid": "login__signin-email" }}
              onChange={handleInputChange}
              placeholder="user@domain.com"
              required
              size="small"
              type="email"
              value={userEmail}
              variant="outlined"
            />
          </div>

          <SignInButton onClick={handleFormSubmit} />
          <Button
            data-testid="login__switch-register"
            onClick={() => history.push("/register")}
            size="large"
          >
            {t("switchToRegister")}
          </Button>
        </Panel>
      </form>
    </>
  );
};

export default LoginPanel;
