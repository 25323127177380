import { MouseEventHandler } from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface VisibilityButtonProps {
  isVisible: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function VisibilityButton({
  isVisible,
  onClick,
}: VisibilityButtonProps) {
  return (
    <InputAdornment position="end">
      <IconButton
        size="small"
        onClick={onClick}
        onMouseDown={e => e.preventDefault()}
      >
        {isVisible ? (
          <VisibilityOff fontSize="small" />
        ) : (
          <Visibility fontSize="small" />
        )}
      </IconButton>
    </InputAdornment>
  );
}
