import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import request, { getErrorCode } from "utils/apiRequestWithErrorCode";
import { Rule, RuleType } from "../types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import initRule from "./context/initRule";
import { RuleUrlParams } from ".";

export default function useRuleLoader() {
  const { id, type }: RuleUrlParams = useParams();

  const isNew = Boolean(type);
  const [isLoading, setLoading] = useState(!isNew);
  const [rule, setRule] = useState<Rule>(
    type ? initRule(type.toUpperCase() as RuleType) : null
  );

  const { t } = useTranslation("rules");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchRuleById(ruleId: string) {
      setLoading(true);
      try {
        const rule = await request<Rule>("GET", `/rules/${ruleId}`);
        setRule(rule);
        setLoading(false);
      } catch (error) {
        const errorCode = getErrorCode(error);
        console.error("errorCode: ", errorCode);
        if (errorCode === "NOT_FOUND") {
          enqueueSnackbar(t("ruleNotFound"), { variant: "error" });
        } else {
          enqueueSnackbar(t("unknowErrorOnGetRule"), { variant: "error" });
        }
        history.push("/rules");
      }
    }

    if (isNew) {
      if (!rule) {
        // wrong or invalid type
        history.push("/rules/create");
      }
    } else {
      fetchRuleById(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  return { isNew, rule, isLoading };
}
