import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import AutocompleteSearchInput, {
  AutocompleteProps,
} from "components/AutocompleteSearchInput";
import useVariables from "utils/useVariables";
import isVariableId from "./isVariableId";
import { SelectContainer, StyledLabel } from "../../Styled";

import { DashboardVariable } from "types";
import { ParentType } from ".";

type Params = {
  onChange: AutocompleteProps["onChange"];
  folderPath: {
    parentName?: string;
    folderStructure?: string;
    parentId?: string;
  };
  variableOptions: DashboardVariable[];
  parentType: ParentType;
  pathError: string;
};

export default function FilepathTextFields({
  onChange,
  folderPath,
  variableOptions,
  parentType,
  pathError,
}: Params) {
  const { t } = useTranslation("rules");
  const { parentName, folderStructure } = folderPath;
  const { mapVarToLabel } = useVariables();

  const showParentFolder =
    parentType === ParentType.Storage &&
    Boolean(folderPath.parentId) &&
    !isVariableId(folderPath.parentId);

  return (
    <SelectContainer>
      {showParentFolder && (
        <div>
          <StyledLabel>{t("parentFolder")}</StyledLabel>
          <TextField
            fullWidth
            placeholder={t("pickAFolderFromYourCloudStorage")}
            InputProps={{ readOnly: true, required: true }}
            inputProps={{ "data-testid": "rules-filepath_parentfolder-input" }}
            type="text"
            value={parentName}
            variant="outlined"
          />
        </div>
      )}
      <div>
        <StyledLabel>{t("subfolder")}</StyledLabel>
        <AutocompleteSearchInput
          placeholder={t("subfolderPlaceholder")}
          onChange={onChange}
          testid="rules-filepath_subfolder-input"
          type="text"
          value={mapVarToLabel(folderStructure, variableOptions)}
          variables={variableOptions}
          required={parentType === ParentType.Root}
          error={!!pathError}
          errorText={pathError}
        />
      </div>
    </SelectContainer>
  );
}
