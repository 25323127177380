/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import {
  DialogTitle,
  InputBaseComponentProps,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { isNumber } from "utils/string";

import { AUTH_CODE_EXPIRATION_MINUTES } from "@dashboard-v3/shared";
import { getErrorCode } from "utils/apiRequestWithErrorCode";

import { updateLastLogin } from "utils/authentication";
import { validate2FACode } from "./api";

interface Props {
  email: string;
  onSuccess: () => void;
  onClose: () => void;
}

export default function TwoFactorAuthentication({
  email,
  onSuccess,
  onClose,
}: Props) {
  const { t } = useTranslation("login");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [digits, setDigits] = useState<string[]>(initDigits);
  const codeInputs = useRef([]);
  const classes = useStyles();
  const codeInputProps: InputBaseComponentProps = {
    style: { textAlign: "center" },
    maxLength: 1,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const expiration = window.sessionStorage.getItem("2FA-EXPIRATION");
      const diff = dayjs().diff(dayjs.unix(Number(expiration)), "minutes");
      if (diff > AUTH_CODE_EXPIRATION_MINUTES) {
        clearInterval(interval);
        enqueueSnackbar("2FAuth.failedVerification.subtitle", {
          variant: "error",
        });
        onClose();
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const validateCode = async () => {
      setLoading(true);
      try {
        const lastLogin = await validate2FACode({
          code: digits.join(""),
          email,
          persistAccess: false,
        });
        updateLastLogin(lastLogin);
        onSuccess();
      } catch (e) {
        handleValidationErrors(e);
      } finally {
        setLoading(false);
      }
    };

    const shouldValidate = digits.every(d => !!d);

    if (shouldValidate) validateCode();
  }, [digits]);

  const handleValidationErrors = (error: unknown) => {
    const code = getErrorCode(error);

    switch (code) {
      case "INVALID_CODE":
        enqueueSnackbar(t("2FAuth.error.invalid"), { variant: "error" });
        break;
      case "RETRIES_EXHAUSTED":
        enqueueSnackbar(t("2FAuth.failedVerification.subtitle"), {
          variant: "error",
        });
        onClose();
        break;
      default:
        enqueueSnackbar(t("2FAuth.error.unknown"), { variant: "error" });
        onClose();
    }
  };

  const onCodeChange = (i: number) => e => {
    closeSnackbar();
    const { value } = e.target;
    if (value && !isNumber(value)) return;
    const code = [...digits];
    code[i] = value;
    setDigits(code);
    if (i !== 5 && !!value) codeInputs.current[i + 1].focus();
  };

  const onCodeKeyUp = (i: number) => e => {
    if (isBackspace(e.keyCode) && !digits[i] && i !== 0) {
      const code = [...digits];
      code[i] = "";
      setDigits(code);
      const currentInput = codeInputs.current[i - 1];
      currentInput.focus();
      currentInput.select();
    }
  };

  const onCodePaste = e => {
    closeSnackbar();
    e.preventDefault();
    const { clipboardData } = e;
    const pastedData = clipboardData.getData("text/plain");
    const code = pastedData.split("");
    if (!isNumber(pastedData) || code.length < 6) return;
    setDigits(code);
    codeInputs.current[5].focus();
  };

  return (
    <>
      <Paper className={classes.container}>
        <DialogTitle disableTypography className={classes.formTitle}>
          <Typography variant="h6">{t("requireAuthModal.title")}</Typography>
          <Typography variant="caption" color="textSecondary">
            {t("2FAuth.subtitle", { email })}
          </Typography>
        </DialogTitle>
        <div className={classes.codeInputContainer}>
          {digits.map((digit, i) => (
            <TextField
              key={i}
              variant="outlined"
              name={`digit-${i}`}
              size="small"
              className={classes.codeInput}
              inputRef={ref => codeInputs.current.push(ref)}
              inputProps={codeInputProps}
              autoFocus={!digits[0].length && i === 0}
              value={digit}
              onChange={onCodeChange(i)}
              onKeyUp={onCodeKeyUp(i)}
              onPaste={onCodePaste}
              disabled={loading}
            />
          ))}
        </div>
      </Paper>
    </>
  );
}

const initDigits = () => [...Array(6)].map(() => "");
const isBackspace = keyCode => keyCode === 8;

const useStyles = makeStyles({
  formTitle: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "center",
  },
  container: {
    padding: "0 20px 10px 20px",
    maxWidth: "450px",
  },
  codeInputContainer: {
    width: "100%",
    display: "flex",
    gap: "20px",
    justifyContent: "center",
  },

  codeInput: {
    marginBottom: "18px",
    width: "50px",
  },
});
