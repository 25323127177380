import React, { useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { grey, green } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

type Props = {
  header?: string;
  footer?: string;
  onSave: (header: string, footer: string) => Promise<unknown>;
};

export default function HeaderAndFooter({
  onSave,
  header: initHeader,
  footer: initFooter,
}: Props) {
  const [isEditing, setIsEditing] = useState(true);
  const [header, setHeader] = useState(initHeader || "");
  const [footer, setFooter] = useState(initFooter || "");
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveFeedback, setShowSaveFeedback] = useState(false);

  const { t } = useTranslation("storage");

  const saveChanges = async () => {
    setIsSaving(true);
    try {
      await onSave(header, footer);
    } finally {
      setIsSaving(false);
      setShowSaveFeedback(true);
    }
  };

  const toggleEditing = () => {
    setIsEditing(current => !current);
  };

  return (
    <ListItem style={{ flexDirection: "column", alignItems: "stretch" }}>
      <ListItemText
        primary={t("headerFooter.title")}
        secondary={t("headerFooter.description")}
        primaryTypographyProps={{ variant: "subtitle2" }}
        secondaryTypographyProps={{ variant: "caption" }}
      />
      <div style={{ marginTop: "15px" }}>
        <Typography style={{ fontSize: ".9rem" }}>
          {t("headerFooter.header")}
        </Typography>
        <ContentOrPreview
          testid="storage__header-input"
          editing={isEditing}
          onChange={e => setHeader(e.target.value)}
          text={header}
        />

        <Typography style={{ marginTop: "20px", fontSize: ".9rem" }}>
          {t("headerFooter.footer")}
        </Typography>
        <ContentOrPreview
          testid="storage__footer-input"
          editing={isEditing}
          onChange={e => setFooter(e.target.value)}
          text={footer}
        />

        <div style={{ marginTop: "15px", textAlign: "left", width: "100%" }}>
          <Button
            color="default"
            data-testid={`storage__header-footer-${
              isEditing ? "preview" : "edit"
            }`}
            variant="text"
            type="link"
            disabled={isSaving}
            onClick={toggleEditing}
            href=""
          >
            {isEditing ? t("headerFooter.preview") : t("headerFooter.edit")}
          </Button>
          <Button
            color="primary"
            data-testid="storage__header-footer-save"
            variant="contained"
            disableElevation
            disabled={isSaving}
            style={{ marginLeft: "1rem" }}
            onClick={saveChanges}
          >
            {t("headerFooter.save")}
          </Button>
          {isSaving ? (
            <CircularProgress size={16} style={{ marginLeft: "10px" }} />
          ) : null}
          <SavedFeedback
            show={showSaveFeedback}
            onHide={() => setShowSaveFeedback(false)}
          >
            {t("headerFooter.saved")}
          </SavedFeedback>
        </div>
      </div>
    </ListItem>
  );
}

const feedbackStyle = {
  display: "inline",
  color: green[400],
  fontSize: "0.9rem",
  fontWeight: 500,
  marginTop: ".6rem",
  marginLeft: "10px",
  opacity: 1,
  transition: "all 250ms linear",
};

function SavedFeedback({
  show,
  onHide,
  children,
}: {
  show: boolean;
  onHide: () => unknown;
  children: React.ReactNode;
}) {
  const hideStyle = {
    ...feedbackStyle,
    opacity: 0,
    transition: "all 250ms linear 2s",
  };

  return (
    <Typography
      style={show ? feedbackStyle : hideStyle}
      onTransitionEnd={onHide}
      component="div"
    >
      {children}
    </Typography>
  );
}

function ContentOrPreview(params: {
  editing: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => unknown;
  text: string;
  testid: string;
}) {
  const { editing, onChange, text, testid } = params;
  const container = useRef();
  const { t } = useTranslation("storage");

  const offsetSize = (type: "Height" | "Width") => {
    if (container && container.current) {
      const size = container.current[`offset${type}`];
      return `${size}px`;
    }
    return "0px";
  };

  if (editing) {
    return (
      <TextField
        inputProps={{
          "data-testid": testid,
        }}
        ref={container}
        style={{ width: "100%", display: "block", paddingTop: "8px" }}
        size="medium"
        type="string"
        variant="outlined"
        multiline
        fullWidth
        minRows={4}
        onChange={onChange}
        value={text}
      />
    );
  }

  return (
    <div
      ref={container}
      style={{
        width: "100%",
        marginTop: "8px",
        border: `1px dotted ${grey[400]}`,
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <PreviewLabel>{t("headerFooter.preview")}</PreviewLabel>

      <iframe
        title="preview"
        srcDoc={text}
        frameBorder="0"
        allowFullScreen
        width={offsetSize("Width")}
        height={`${(text || "").length > 0 ? "auto" : offsetSize("Height")}`}
      />
    </div>
  );
}

const PreviewLabel = styled.div`
  font-size: 0.8em;
  background-color: #e8e8e8;
  display: inline;
  padding: 4px 15px;
  border-radius: 4px;
  position: absolute;
  right: 8px;
  top: 8px;
`;
