/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { TextField, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Account } from "@dashboard-v3/api";
import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import useStorageAccountValidation from "utils/useStorageAccountValidation";

interface Props {
  providers: Account["storage"];
  validateStorage?: boolean;
}

export default function StorageProperties({
  providers,
  validateStorage,
}: Props) {
  const { t } = useTranslation("accounts");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const fields = Object.keys(cloudStorageLabels);
  const [storageErrors, setStorageErrors] = useState<Account["storage"]>({});
  const [loading, setLoading] = useState(false);

  const validateAccess = useStorageAccountValidation();

  useEffect(() => {
    const checkStorageAccess = async () => {
      setLoading(true);
      try {
        const { isValid, errors } = await validateAccess(providers);
        if (!isValid) return setStorageErrors(errors);
      } catch (e) {
        enqueueSnackbar(t("list.storageAccounts.errors.checkAccess"), {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    if (validateStorage) {
      checkStorageAccess();
    }
  }, []);

  const getFieldInputProps = (key: string, value: Props["providers"]) => ({
    "data-testid": `accounts__${key}-storage-account`,
    readOnly: true,
    classes: value ? null : classes.root,
  });

  if (loading) {
    return (
      <div className={classes.propertiesContainer}>
        {fields.map(key => (
          <Skeleton
            key={key}
            variant="rect"
            animation="wave"
            width={"auto"}
            height={40}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={classes.propertiesContainer}>
      {fields.map(key => {
        const value = providers[key];
        const tooltipTitle = storageErrors[key] ? storageErrors[key] : "";
        return (
          <Tooltip key={key} title={tooltipTitle}>
            <TextField
              variant="outlined"
              size="small"
              style={{ cursor: "help" }}
              label={cloudStorageLabels[key]}
              inputProps={getFieldInputProps(key, value)}
              value={value || t("common:notSet")}
              error={!!storageErrors[key]}
              helperText={
                storageErrors[key] && t("list.storageAccounts.errors.hover")
              }
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}

const useStyles = makeStyles({
  root: { color: "rgba(0, 0, 0, 0.38)" },
  propertiesContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridColumnGap: "20px",
    gridRowGap: "33px",
    marginTop: "15px",
    marginBottom: "30px",
  },
  helperText: {
    position: "absolute",
    top: "39px",
    fontSize: "11px",
    marginLeft: "11px",
  },
});

const cloudStorageLabels = {
  box: "Box",
  drive: "Google Drive",
  dropbox: "Dropbox",
  onedrive: "Microsoft OneDrive",
};
