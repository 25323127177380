/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { Box, Typography, LinearProgress, Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import SectionHeader from "components/SectionHeader";
import SectionTabs from "components/SectionTabs";
import useMailboxAuthPopup, { AuthType } from "utils/useMailboxAuthPopup";
import Applications from "./Applications";
import Accounts from "./Accounts";
import Settings from "./StorageSettings";
import AddAccountButton from "./Accounts/AddAccountButton";
import { MailboxProvider } from "./Accounts/helpers";
import {
  deleteStorageAccount,
  deleteMailboxAccount,
  fetchMailboxAccountById,
  fetchMailboxAccounts,
  fetchStorageAccounts,
  fetchStorageSettings,
} from "./api";

import {
  MailboxSingleAccount,
  StorageAccount,
  StorageSettings,
} from "@dashboard-v3/api";

enum Tab {
  Applications = "APPLICATIONS",
  StorageSettings = "STORAGE_SETTINGS",
  Accounts = "ACCOUNTS",
}

export default function Integrations() {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const { open, isClosedByUser } = useMailboxAuthPopup();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.Applications);
  const [settings, setSettings] = useState<StorageSettings>(null);
  const [storageAccounts, setStorageAccounts] = useState<StorageAccount[]>();
  const [mailboxAccounts, setMailboxAccounts] =
    useState<MailboxSingleAccount[]>();

  useEffect(() => {
    async function getSettings() {
      try {
        setSettings(await fetchStorageSettings());
      } catch (error) {
        enqueueSnackbar(t("error.fetchStorageSettings"), {
          variant: "error",
        });
      }
    }

    getSettings();
    getAccounts();
  }, []);

  const getAccounts = async () => {
    try {
      const storageAccounts = await fetchStorageAccounts();
      const mailbox = await fetchMailboxAccounts();
      setStorageAccounts(storageAccounts);
      setMailboxAccounts(mailbox);
    } catch (error) {
      enqueueSnackbar(t("error.fetchAccounts"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onStorageAccountDelete = async (accountId: string) => {
    setLoading(true);
    try {
      await deleteStorageAccount(accountId);
      await getAccounts();
    } catch (error) {
      enqueueSnackbar(t("error.deleteAccount"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  function showSuccessResyncMessage(resyncedAccount: StorageAccount) {
    const message = t("resynced", { account: resyncedAccount.user });
    enqueueSnackbar(message, { variant: "success" });
  }

  const onStorageAccountAdd = async (addedAccount: StorageAccount) => {
    setLoading(true);
    try {
      const alreadyExist = storageAccounts.some(isSameAccount(addedAccount));

      if (alreadyExist) {
        showSuccessResyncMessage(addedAccount);
      } else {
        setStorageAccounts(items => [addedAccount, ...items]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("error.addAccount"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onMailboxAccountAdd = async (
    mailboxProvider: MailboxProvider,
    authType: AuthType
  ) => {
    try {
      const res = await open(authType);
      if (!res.auth) return;
      const alreadyExists = mailboxAccounts.find(a => a.email === res.email);

      if (!alreadyExists) {
        const id = `${res.email.toLowerCase()}:${mailboxProvider}`;
        const newAccount = await fetchMailboxAccountById(id);
        setMailboxAccounts(prev => [newAccount, ...prev]);
      }
    } catch (err) {
      if (isClosedByUser(err)) return;
      enqueueSnackbar(t("error.addAccount"), {
        variant: "error",
      });
    }
  };

  const onMailboxAccountDelete = async (id: string) => {
    setLoading(true);
    try {
      await deleteMailboxAccount(id);
      await getAccounts();
    } catch (error) {
      enqueueSnackbar(t("error.deleteAccount"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const enableAccountAndReload = async (
    account: StorageAccount | MailboxSingleAccount
  ) => {
    setLoading(true);
    try {
      if (account.type === "GOOGLE" || account.type === "OFFICE365") {
        setMailboxAccounts(items =>
          updateAccount(items, account as MailboxSingleAccount)
        );
      } else {
        setStorageAccounts(items =>
          updateAccount(items, account as StorageAccount)
        );
      }
    } catch (error) {
      enqueueSnackbar(t("error.enableAccountError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  function updateSettings(updates: StorageSettings) {
    setSettings(updates);
  }

  return (
    <>
      <SectionHeader
        title={t("sectionTitle")}
        sideBtn={
          activeTab === Tab.Accounts && (
            <AddAccountButton
              addStorageAccount={onStorageAccountAdd}
              addMailboxAccount={onMailboxAccountAdd}
            />
          )
        }
      >
        <Typography>{t("sectionExplanation")}</Typography>
      </SectionHeader>

      <SectionTabs
        tabs={getSectionTabs(t)}
        showTab={activeTab}
        onClick={value => setActiveTab(value as Tab)}
      />
      {loading && (
        <Paper
          data-testid={`storage__${activeTab}_loading`}
          style={{ padding: "12px 16px", margin: "30px 0" }}
        >
          <LinearProgress />
        </Paper>
      )}
      {!loading && (
        <Box mt="20px">
          {activeTab === Tab.Applications && (
            <Applications settings={settings} onChange={updateSettings} />
          )}
          {activeTab === Tab.StorageSettings && (
            <Settings settings={settings} onChange={updateSettings} />
          )}
          {activeTab === Tab.Accounts && (
            <Accounts
              storageAccounts={storageAccounts}
              mailboxAccounts={mailboxAccounts}
              enableAccount={enableAccountAndReload}
              deleteStorageAccount={onStorageAccountDelete}
              deleteMailboxAccount={onMailboxAccountDelete}
              addStorageAccount={onStorageAccountAdd}
              addMailboxAccount={onMailboxAccountAdd}
            />
          )}
        </Box>
      )}
    </>
  );
}

function isSameAccount<T extends StorageAccount | MailboxSingleAccount>(
  account: T
) {
  return (other: T) => account.id === other.id;
}

function updateAccount<T extends StorageAccount | MailboxSingleAccount>(
  items: T[],
  account: T
) {
  const index = items.findIndex(isSameAccount(account));
  if (index < 0) return items;
  const newList = items.slice(0);
  newList[index] = { ...newList[index], ...account };
  return newList;
}

const getSectionTabs = (t: TFunction) => {
  return Object.values(Tab).map(tab => ({ id: tab, label: t(`tabs.${tab}`) }));
};
