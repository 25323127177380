import {
  Percentage,
  PercentageContainer,
  Progress,
  ProgressContainer,
  UnfilledProgress,
} from "./styled";

interface ProgressProps {
  value: number;
  withPercentage: boolean;
  finishedWithErrors: boolean;
}

export default function ProgressIndicator(props: ProgressProps) {
  const { value, withPercentage, finishedWithErrors } = props;

  return (
    <ProgressContainer>
      <UnfilledProgress variant="static" size={120} thickness={2} value={100} />
      <Progress
        variant="determinate"
        size={120}
        thickness={2}
        value={value}
        error={finishedWithErrors}
      />

      {withPercentage && (
        <PercentageContainer>
          <Percentage
            variant="caption"
            color="textSecondary"
          >{`${value}%`}</Percentage>
        </PercentageContainer>
      )}
    </ProgressContainer>
  );
}
