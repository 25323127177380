import { SetStateAction } from "react";
import { Draft } from "immer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Sources from "./conditions/Sources";
import Conditions from "./conditions/Conditions";
import Actions from "./conditions/Actions";
import { ConditionsProvider } from "../state/ConditionsProvider";
import {
  Wrapper,
  ConnectorWrapper,
  ConnectorTrail,
  ConnectorPill,
  RemoveButton,
} from "components/Forms/StyledComponents";
import { TestingState } from "..";
import { KeyedVariable } from "@dashboard-v3/api";

const ConditionCounter = styled(Typography)`
  align-self: flex-start;
  font-size: 0.85em;
  font-weight: 500;
`;

interface ConditionSectionProps {
  variable: KeyedVariable;
  updateVariable: (updateFn: (draft: Draft<KeyedVariable>) => void) => void;
  index: number;
  variableTestProps: {
    variableTest: TestingState;
    setVariableTest: React.Dispatch<SetStateAction<TestingState>>;
  };
}

export default function ConditionsSection({
  variable,
  updateVariable,
  index: orIndex,
  variableTestProps,
}: ConditionSectionProps) {
  const { t } = useTranslation("variables");

  const { children: conditionBlocks } = variable.predicate;
  const { children: conditionSections, key } =
    variable.predicate.children[orIndex];

  const isReadOnly = Boolean(variable?.platformVariable);

  const displayConnector = () => {
    return conditionBlocks.length > 1 && conditionBlocks.length !== orIndex + 1;
  };

  const displayFields = (
    type: "actions" | "conditions",
    currentIndex: number
  ) => {
    const { sourceType, type: conditionType } = conditionSections[currentIndex];
    if (type === "actions" && conditionSections.length > 1) {
      return true;
    }

    if (type === "actions" && conditionType === "AI_QUESTION") return true;

    return !!sourceType;
  };

  const removeConditionBlock = () => {
    updateVariable(draft => {
      draft.predicate.children = draft.predicate.children.filter(
        item => item.key !== key
      );
    });
  };

  return (
    <>
      <Wrapper $relative>
        {!isReadOnly && (
          <RemoveButton
            edge="end"
            size="small"
            $enabled={conditionBlocks.length > 1}
            onClick={removeConditionBlock}
          >
            <CancelOutlinedIcon fontSize="inherit" />
          </RemoveButton>
        )}
        {conditionSections.map((item, andIndex, conditions) => (
          <ConditionsProvider
            key={item.key}
            position={{ blockIndex: orIndex, conditionIndex: andIndex }}
            variable={variable}
            updateVariable={updateVariable}
            variableTestProps={variableTestProps}
          >
            {conditions.length > 1 && (
              <ConditionCounter color="textSecondary">
                {t("form.condition.count", { count: andIndex + 1 })}
              </ConditionCounter>
            )}
            {/**  First line of condition: When... + "application fields" */}
            <Sources index={[orIndex, andIndex]} />

            {displayFields("conditions", andIndex) && (
              <Conditions index={[orIndex, andIndex]} />
            )}

            {displayFields("actions", andIndex) && <Actions />}
          </ConditionsProvider>
        ))}
      </Wrapper>
      {displayConnector() && (
        <ConnectorWrapper>
          <ConnectorTrail />
          <ConnectorPill size="large" variant="extended">
            <Typography color="primary" variant="button">
              {t("form.condition.pill")}
            </Typography>
          </ConnectorPill>
          <ConnectorTrail />
        </ConnectorWrapper>
      )}
    </>
  );
}
