import { useTranslation } from "react-i18next";
import { useHistory, generatePath } from "react-router-dom";
import Item from "components/List/Item";
import { useDependencyModal } from "components/DependencyModal";
import apiRequest from "utils/apiRequestWithErrorCode";
import { ListItemProps } from "../types";

const PolicyItem = ({
  policy,
  setLoading,
  setLoadingItem,
  fetchPolicies,
}: ListItemProps) => {
  const { t } = useTranslation("templatePolicies");
  const { errorHandler } = useDependencyModal();
  const history = useHistory();
  const { id } = policy;

  const removePolicy = async (id: string) => {
    setLoading("ITEM");
    setLoadingItem(id);
    try {
      await apiRequest<void>("DELETE", `/template-policies/${id}`);
      await fetchPolicies();
    } catch (error) {
      errorHandler(error, t("errors.deleteError"));
    } finally {
      setLoading(null);
      setLoadingItem(null);
    }
  };

  const goToEdit = () => {
    history.push({
      pathname: generatePath("template-policies/edit/:id", { id: policy.id }),
      state: {
        selectedPolicy: {
          name: policy.name,
          description: policy.description || "",
          cloudStorage: policy.storageType,
          predicates: policy.predicates,
          defaultDefinition: policy.defaultDefinition || "",
        },
        policyId: policy.id,
      },
    });
  };

  return (
    <Item item={policy} onEdit={goToEdit} onDelete={() => removePolicy(id)} />
  );
};

export default PolicyItem;
