import { getStorage } from "pages/Rules/CloudStorage/utils";
import { State } from "../types";
import produce, { Draft } from "immer";
import { Storage } from "@dashboard-v3/api";
import { isBlank } from "utils/string";
import isEmail from "utils/isEmail";
import { verifyIfCanSave } from "./verifyIfCanSave";

export function updateMoreActions(
  state: State,
  payload: UpdateMoreActions["payload"]
): State {
  return produce(state, draft => {
    if ("onStorageError" in payload) {
      updateOnStorageError(payload, draft);
    }

    if ("onStorageSuccess" in payload) {
      updateOnStorageSuccess(payload, draft);
    }
  });
}

function updateOnStorageError(
  payload: UpdateMoreActions["payload"],
  draft: Draft<State>
) {
  const storage = getStorage(draft.rule);

  if (payload.onStorageError.type === null) {
    delete storage.onStorageError;
  }

  if (payload.onStorageError.type === "REJECT") {
    storage.onStorageError = {
      emailAction: {
        type: "REJECT",
      },
    };
  }

  if (payload.onStorageError.type === "FORWARD") {
    storage.onStorageError = {
      emailAction: {
        type: "FORWARD",
        recipientAddress: payload.onStorageError.recipientAddress,
      },
    };

    validateMoreActions(draft);
  }
}

function updateOnStorageSuccess(
  payload: UpdateMoreActions["payload"],
  draft: Draft<State>
) {
  const storage = getStorage(draft.rule);

  if (payload.onStorageSuccess.type === "NOTHING") {
    delete storage.onStorageSuccess;
    draft.steps.moreActions.done = true;
    verifyIfCanSave(draft);
    return;
  }

  let newState: Storage["onStorageSuccess"]["emailAction"];

  if (storage.onStorageSuccess?.emailAction) {
    newState = { ...storage.onStorageSuccess?.emailAction };
  } else {
    newState = {
      type: payload.onStorageSuccess.type,
      originalEmail: false,
      recipientAddress: "",
    };

    storage.onStorageSuccess = { emailAction: newState };
  }

  if ("type" in payload.onStorageSuccess) {
    newState.type = payload.onStorageSuccess.type;
  }

  if ("originalEmail" in payload.onStorageSuccess) {
    newState.originalEmail = payload.onStorageSuccess.originalEmail;
  }

  if ("recipientAddress" in payload.onStorageSuccess) {
    delete newState.variableId;
    newState.recipientAddress = payload.onStorageSuccess.recipientAddress;
  }

  if ("variableId" in payload.onStorageSuccess) {
    delete newState.recipientAddress;
    newState.variableId = payload.onStorageSuccess.variableId;
  }

  storage.onStorageSuccess.emailAction = newState;

  validateMoreActions(draft);
}

function validateMoreActions(draft: Draft<State>) {
  const storage = getStorage(draft.rule);
  const emailAction = storage.onStorageSuccess?.emailAction;

  const stepsDone: boolean[] = [true];

  const recipientAddress = emailAction?.recipientAddress;
  const variableId = emailAction?.variableId;

  if (recipientAddress !== undefined) {
    stepsDone.push(isEmail(recipientAddress));
  }

  if (variableId !== undefined) {
    stepsDone.push(!isBlank(variableId));
  }

  if (storage.onStorageError) {
    const emailAction = storage.onStorageError.emailAction;
    if (emailAction?.type === "FORWARD") {
      stepsDone.push(isEmail(emailAction?.recipientAddress));
    }
  }

  draft.steps.moreActions.done = stepsDone.every(isValid => isValid);
  verifyIfCanSave(draft);
}

export type UpdateMoreActions = {
  type: "updateMoreActions";
  payload: {
    onStorageError?: Storage["onStorageError"]["emailAction"] | null;
    onStorageSuccess?:
      | { type: "NOTHING" }
      | {
          type: "COPY" | "FORWARD";
          recipientType: "EMAIL" | "VARIABLE";
          variableId?: string;
          recipientAddress?: string;
          originalEmail: boolean;
        };
  };
};
