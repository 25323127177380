import styled from "styled-components";
import { List, Paper } from "@material-ui/core";

export const Panel = styled(Paper)`
  min-height: 147px;
  align-self: start;
  align-items: center;
  display: grid;
  padding: 16px;
  text-align: center;
`;

export const ListHeader = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 0.75fr;
  padding: 0 16px;
`;

export const StyledList = styled(List)`
  li:first-child {
    padding-top: 0;
  }
  li:last-child {
    padding-bottom: 0;
  }
`;

export const TextWithButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ItemContainer = styled(Paper)`
  align-items: center;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 0.75fr;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 12px 16px;

  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

export const StatusDiv = styled.span`
  margin: 5px 0;
`;
