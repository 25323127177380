import React from "react";
import { LinearProgress, Paper } from "@material-ui/core";

const LinearLoader = () => {
  return (
    <Paper style={{ padding: "12px 16px" }} data-testid="linear-loader">
      <LinearProgress />
    </Paper>
  );
};

export default LinearLoader;
