import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SectionHeader from "components/SectionHeader";
import CopyPolicyList from "./List";
import CopyPolicyForm from "./Form";
import { useTranslation } from "react-i18next";
import { Button } from "components/Forms/StyledComponents";

const CopyPolicies = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isOnForm = pathname.includes("/new") || pathname.includes("/edit");
  const { t } = useTranslation("copyPolicy");

  const handleRouting = () => {
    return history.push(isOnForm ? "/copy-policies" : "/copy-policies/new");
  };

  const sideBtnText = isOnForm ? t("common:backToList") : t("common:create");

  return (
    <>
      <SectionHeader
        title={t("copyPolicies")}
        sideBtn={<Button wording={sideBtnText} onClick={handleRouting} />}
      >
        <Typography>{t("headerDescription")}</Typography>
      </SectionHeader>
      <Switch>
        <Route path="/copy-policies" exact>
          <CopyPolicyList />
        </Route>
        <Route path="/copy-policies/new" exact>
          <CopyPolicyForm />
        </Route>
        <Route path="/copy-policies/:id/edit" exact>
          <CopyPolicyForm />
        </Route>
      </Switch>
    </>
  );
};

export default CopyPolicies;
