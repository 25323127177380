import React, { CSSProperties } from "react";
import { Typography } from "@material-ui/core";
import { green, red, orange } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export enum SyncStatus {
  INSTALLED = "INSTALLED",
  NOT_INSTALLED = "NOT_INSTALLED",
  NOT_VERIFIED = "NOT_VERIFIED",
}

interface AppStatusProps {
  status: SyncStatus;
}

export default function ApplicationStatus({ status }: AppStatusProps) {
  const { t } = useTranslation("storage");
  const { color, label, Icon } = getStatus(status, t);

  return (
    <Typography
      data-testid="storage__install-status"
      style={{
        display: "flex",
        gap: "5px",
        color,
        fontSize: ".9rem",
        marginBottom: ".09rem",
        fontWeight: 500,
      }}
      component="div"
    >
      <Icon style={iconStyle} /> {label}
    </Typography>
  );
}

const getStatus = (status: SyncStatus, t: TFunction) => {
  if (status === SyncStatus.NOT_VERIFIED) {
    return {
      label: t("notVerified"),
      color: orange[400],
      Icon: HelpOutlineIcon,
    };
  }

  if (status === SyncStatus.INSTALLED) {
    return {
      label: t("installed"),
      color: green[400],
      Icon: CheckIcon,
    };
  }
  if (status === SyncStatus.NOT_INSTALLED) {
    return {
      label: t("notInstalled"),
      color: red[400],
      Icon: ErrorOutlineIcon,
    };
  }
};

const iconStyle: CSSProperties = {
  fontSize: "17",
  position: "relative",
  top: "2px",
};
