import {
  getErrorCode,
  getErrorDescription,
} from "utils/apiRequestWithErrorCode";
import { AccountToEdit, State } from "./types";
import { TFunction } from "react-i18next";
import {
  AliasError,
  StorageAccountError,
  CreateEmailError,
} from "@dashboard-v3/api";

export default function handleValidationErrors(
  state: State,
  error: unknown,
  t: TFunction
):
  | { type: "EMAIL" | "ALIASES"; error: string }
  | { type: "STORAGE"; error: AccountToEdit["storage"] } {
  const code = getErrorCode<
    CreateEmailError | AliasError | StorageAccountError
  >(error);

  const description = getErrorDescription(error);

  if (code === "SYSTEM_FAILURE") {
    return { type: "EMAIL", error: t("accountError.UNKNOWN_ERROR") };
  }

  if (code === "DOMAIN_ALIAS_NOT_MANAGED_BY_ORGANIZATION") {
    const domain = getErrorDescription(error) as string;
    const accounts = state.aliases
      .filter(alias => alias.endsWith(domain))
      .join(", ");

    return {
      type: "ALIASES",
      error: `${t("error.aliasNotManaged")} ${accounts}`,
    };
  }

  if (code === "EMAIL_ALIAS_INVALID") {
    const email = getErrorDescription(error) as string;

    return {
      type: "ALIASES",
      error: t("error.aliasIsWrong", { email }),
    };
  }

  if (code === "EMAIL_ALIAS_IS_AN_ACCOUNT") {
    return {
      type: "ALIASES",
      error: t("error.aliasIsAnAccount", { email: description }),
    };
  }

  if (code === "STORAGE_ACCOUNT_INVALID") {
    const errorText = t(`accountError.${code}`);
    const invalidAccount = (
      getErrorDescription(error) as string
    )?.toLowerCase();

    const isInvalidAccount = (storageAccount: string) =>
      storageAccount?.toLowerCase() === invalidAccount;

    return {
      type: "STORAGE",
      error: fillStorageAccountError(isInvalidAccount, errorText, state),
    };
  }

  if (code === "STORAGE_ACCOUNT_DOMAIN_NOT_MANAGED_BY_ORGANIZATION") {
    const errorText = t(`accountError.${code}`);

    const wrongDomain = `@${getErrorDescription(error) as string}`;

    const isInvalidAccount = (storageAccount: string) =>
      storageAccount?.includes(wrongDomain);

    return {
      type: "STORAGE",
      error: fillStorageAccountError(isInvalidAccount, errorText, state),
    };
  }

  return {
    type: "EMAIL",
    error: t([`accountError.${code}`, "accountError.UNKNOWN_ERROR"]),
  };
}

function fillStorageAccountError(
  isInvalidAccount: (storageAccount: string) => boolean,
  errorText: string,
  state: State
) {
  return Object.keys(state.storage).reduce((errors, storageType) => {
    const storageAccount = state.storage[storageType];

    if (isInvalidAccount(storageAccount)) {
      errors[storageType] = errorText;
    }

    return errors;
  }, {} as AccountToEdit["storage"]);
}
