import { Box, CircularProgress } from "@material-ui/core";

type Params = {
  message?: string;
};

export default function ProgressMsg({ message }: Params) {
  return (
    <Box display="flex" alignItems="center" gridGap="15px">
      <CircularProgress />
      <Box>{message || "Processing..."}</Box>
    </Box>
  );
}
