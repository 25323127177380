import { AndChild } from "../types";

export type ConditionByType = {
  value: AndChild["type"];
  translationKey: string;
};

type SourceType = AndChild["sourceType"];

export const conditionsByType = {
  TEXT: [
    { value: "CONTAINS", translationKey: "contains" },
    { value: "START_WITH", translationKey: "starts" },
    { value: "END_WITH", translationKey: "ends" },
    { value: "REGEX", translationKey: "regex" },
    { value: "EQUALS", translationKey: "equalTo" },
    { value: "NOT_EQUALS", translationKey: "diff" },
    // { value: "REPLACE_ALL", translationKey: "replaceAll" },
    // { value: "SPLIT", translationKey: "split" },
    { value: "EQUALS_TO_DATASET_KEY", translationKey: "equalsMap" },
    { value: "CONTAINS_DATASET_KEY", translationKey: "containsMap" },
  ],
  DATE_TIME: [
    { value: "GREATER", translationKey: "after" },
    { value: "GREATER_OR_EQUALS", translationKey: "greaterOr" },
    { value: "LESS", translationKey: "before" },
    { value: "LESS_OR_EQUALS", translationKey: "lessOr" },
    { value: "REGEX", translationKey: "regex" },
    { value: "EQUALS", translationKey: "equalTo" },
    { value: "NOT_EQUALS", translationKey: "diff" },
    // { value: "BETWEEN", translationKey: "between" },
  ],
  NUMBER: [
    { value: "GREATER", translationKey: "greater" },
    { value: "GREATER_OR_EQUALS", translationKey: "greaterOr" },
    { value: "LESS", translationKey: "less" },
    { value: "LESS_OR_EQUALS", translationKey: "lessOr" },
    { value: "EQUALS", translationKey: "equalTo" },
    { value: "NOT_EQUALS", translationKey: "diff" },
    // { value: "BETWEEN", translationKey: "between" },
  ],
} as Record<SourceType, ConditionByType[]>;
