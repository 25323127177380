import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectProps,
  MenuProps,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface Params<T> extends SelectProps {
  options: Array<T>;
  renderOption: (option: T, index: number, array: Array<T>) => ReactNode;
  allowEmpty?: boolean;
  emptyOption?: string;
  renderEndAdornment?: () => ReactElement;
  error?: boolean;
  menuTitle?: string;
  helperText?: string;
}

export default function SelectField<T>({
  label,
  options,
  menuTitle = "",
  helperText = "",
  allowEmpty = false,
  emptyOption,
  renderOption,
  renderValue,
  renderEndAdornment,
  fullWidth = true,
  ...rest
}: Params<T>) {
  const { t } = useTranslation("common");
  const classes = useStyles();

  const handleRenderValue: SelectProps["renderValue"] = value => {
    if (!value && allowEmpty) {
      return <>{emptyOption || t("none")}</>;
    }
    return renderValue(value);
  };

  return (
    <FormControl variant="outlined" fullWidth={fullWidth}>
      {label && (
        <InputLabel htmlFor={rest.name} {...(allowEmpty && { shrink: true })}>
          {label}
        </InputLabel>
      )}
      <Select
        MenuProps={menuProps}
        endAdornment={renderEndAdornment && renderEndAdornment()}
        renderValue={renderValue && handleRenderValue}
        displayEmpty={allowEmpty}
        input={
          <OutlinedInput
            {...(label && { label })}
            {...(allowEmpty && { notched: true })}
          />
        }
        {...rest}
      >
        {!!menuTitle && (
          <MenuItem value="" disabled>
            <em>{menuTitle}</em>
          </MenuItem>
        )}
        {allowEmpty && <MenuItem value="">{emptyOption || t("none")}</MenuItem>}
        {!!options?.length &&
          options.map((opt, index, arr) => renderOption(opt, index, arr))}
      </Select>
      {helperText && (
        <FormHelperText className={classes.helperText} error={rest.error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

const useStyles = makeStyles({
  helperText: {
    fontSize: "11px",
    marginLeft: "5px",
    position: "absolute",
    top: 55,
    left: 3,
  },
});

const menuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};
