import { Paper } from "@material-ui/core";
import styled from "styled-components";

export default styled(Paper)`
  display: grid;
  padding: 50px 65px;
  min-width: 390px;
  grid-row-gap: 18px;
  border-radius: 8px;
`;
