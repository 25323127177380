import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import UserFormModal, { UserFormProps } from "components/UserFormModal";
import AuthenticationModal from "components/AuthenticationModal";
import { shouldAuthenticate } from "utils/authentication";

import { Users } from "@dashboard-v3/api";

interface EditButtonProps {
  user: Users.Item;
  onSave: UserFormProps["onSave"];
}

export default function EditBtn({ onSave, user }: EditButtonProps) {
  const { t } = useTranslation("users");
  const [userFormIsOpen, setUserFormIsOpen] = useState(false);
  const [authIsOpen, setAuthIsOpen] = useState(false);

  const onButtonClick = () => {
    if (shouldAuthenticate()) {
      setAuthIsOpen(true);
    } else {
      setUserFormIsOpen(true);
    }
  };

  const onAuthSuccess = () => {
    setAuthIsOpen(false);
    setUserFormIsOpen(true);
  };

  return (
    <>
      <Button
        data-testid="users__edit-user-btn"
        onClick={onButtonClick}
        style={{ marginLeft: "10px" }}
        variant="outlined"
      >
        {t("common:edit")}
      </Button>

      {userFormIsOpen && (
        <UserFormModal
          isOpen={userFormIsOpen}
          onClose={() => setUserFormIsOpen(false)}
          onSave={onSave}
          user={user}
        />
      )}

      {authIsOpen && (
        <AuthenticationModal
          isOpen={authIsOpen}
          onSuccess={onAuthSuccess}
          onClose={() => setAuthIsOpen(false)}
        />
      )}
    </>
  );
}
