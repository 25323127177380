import type { Option } from "../types";

export default function optionLabel(optionOrText: string | Option) {
  if (isAnOption(optionOrText)) return optionOrText.label;

  return optionOrText;
}

function isAnOption(value): value is Option {
  return value && typeof value !== "string" && "type" in value;
}
