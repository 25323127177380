import { TemplateField } from "@dashboard-v3/api";
import { ListItem, Typography, capitalize, Link } from "@material-ui/core";
import PopupHelper from "./PopupHelper";
import { useTranslation } from "react-i18next";

type Props = {
  field: TemplateField;
};

export default function AvailableOptionsHelp({ field }: Props) {
  const { t } = useTranslation("templateDefinitions");
  return (
    <PopupHelper
      label={
        <Link
          component="button"
          style={{ fontSize: ".8em", marginLeft: "10px" }}
          onClick={e => e.preventDefault()}
        >
          {t("form.mappings.value.helper")}
        </Link>
      }
    >
      <>
        {field.options.map(option => (
          <ListItem key={option} disableGutters>
            <div>
              <Typography variant="body2">{capitalize(option)}</Typography>
            </div>
          </ListItem>
        ))}
      </>
    </PopupHelper>
  );
}
