import apiRequest from "utils/apiRequestWithErrorCode";

import {
  Account,
  BulkCreateResponse,
  CreateAccountBody,
  UpdateAccountBody,
} from "@dashboard-v3/api";

type FetchAccountsParams = {
  limit?: number;
  offset?: number;
  partialEmailOrAlias?: string;
  tag?: string;
  type?: "member" | "list" | "all";
};

export async function fetchAccounts(params: FetchAccountsParams) {
  const {
    limit = 10,
    offset = 0,
    partialEmailOrAlias = "",
    tag = "",
    type = "member",
  } = params;
  const query = new URLSearchParams();
  query.append("limit", limit.toString());
  query.append("offset", offset.toString());

  if (type !== "all") {
    query.append("type", type);
  }

  if (partialEmailOrAlias) {
    query.append("partialEmailOrAlias", partialEmailOrAlias);
  }

  if (tag) {
    query.append("tag", tag);
  }

  return apiRequest<Account[]>("GET", `/accounts?${query.toString()}`);
}

export async function createAccount(account: CreateAccountBody) {
  return apiRequest<Account>("POST", `/accounts`, account);
}

export async function createAccountsInBulk(accounts: CreateAccountBody[]) {
  return apiRequest<BulkCreateResponse>(
    "POST",
    `/accounts/bulk-create`,
    accounts
  );
}

export async function deleteAccount(account: string) {
  return apiRequest("DELETE", `/accounts/${encodeURIComponent(account)}`);
}

export async function updateAccount(email: string, updates: UpdateAccountBody) {
  return apiRequest<Account>(
    "PATCH",
    `/accounts/${encodeURIComponent(email)}`,
    updates
  );
}

export async function fetchAccount(email: string) {
  return apiRequest<Account>("GET", `/accounts/${encodeURIComponent(email)}`);
}

export async function fetchTags() {
  return apiRequest<string[]>("GET", "/accounts/tags");
}

export async function synchronizeAccounts() {
  return apiRequest<void>("POST", "/postfix/accounts-sync");
}
