import { passwordValidationKeys } from "@dashboard-v3/shared";
import {
  IconButton,
  InputAdornment,
  Popover,
  Typography,
} from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidationErrorList, ValidationHelperContainer } from "./styled";

export default function ValidationHelper() {
  const { t } = useTranslation("components");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
    setIsOpen(prev => !prev);
  };

  return (
    <InputAdornment position="end">
      <IconButton
        color="primary"
        size="small"
        onClick={handleOpen}
        onMouseDown={e => e.preventDefault()}
      >
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ValidationHelperContainer>
            <Typography variant="subtitle2">
              {t("passwordInput.validationHelper.title")}
            </Typography>
            <ValidationErrorList>
              {passwordValidationKeys.map(key => (
                <li key={key}>
                  <Typography variant="caption">
                    {t(`passwordInput.validationHelper.rule.${key}`)}
                  </Typography>
                </li>
              ))}
            </ValidationErrorList>
          </ValidationHelperContainer>
        </Popover>
        <HelpOutlineOutlinedIcon fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
}
