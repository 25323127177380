import { Dispatch, useEffect } from "react";
import { Action } from "./context/action";
import { State } from "./context/types";
import apiRequest from "utils/apiRequestWithErrorCode";
import {
  getArchivingAddress,
  getEmailFlow,
  getMailbox,
} from "./context/helpers";
import {
  AccountManagement,
  asUserValue,
  asUserValueCollection,
  getStorage,
} from "../CloudStorage/utils";

import {
  CheckAccessRequest,
  CheckAccessResponse,
  CheckArchivingAddressResponse,
  EmailFlowCheckBody,
  EmailFlowCheckReponse,
  SanitizedFilename,
  ValidateMailboxRequest,
  ValidateMailboxResponse,
} from "@dashboard-v3/api";

export default function useAsyncValidations(
  state: State,
  dispatch: Dispatch<Action>
) {
  useEffect(() => {
    if (state.asyncStatus === "validate_archiving_address") {
      validateArchivingAddress(state, dispatch);
    }

    if (state.asyncStatus === "validate_email_flow") {
      validateEmailFlow(state, dispatch);
    }

    if (state.asyncStatus === "validate_mailbox_imap") {
      validateMailboxImap(state, dispatch);
    }

    if (state.asyncStatus === "validate_storage_access") {
      validateStorageAccess(state, dispatch);
    }

    if (state.asyncStatus === "validate_filename_format") {
      validateFolderPath(state, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.asyncStatus]);
}

async function validateMailboxImap(state: State, dispatch: Dispatch<Action>) {
  const mailbox = getMailbox(state.rule);
  const body: ValidateMailboxRequest = {
    inboxType: "IMAP",
    imapServer: mailbox.imapServer,
  };

  try {
    const { isValid } = await apiRequest<ValidateMailboxResponse>(
      "POST",
      "/mailbox/validate",
      body
    );

    return dispatch({
      type: "asyncValidationEnds",
      payload: {
        valid: isValid,
        validation: "MAIL_BOX",
        error: isValid ? null : "INVALID",
      },
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: "asyncValidationEnds",
      payload: {
        valid: false,
        validation: "MAIL_BOX",
        error: "FETCH_ERROR",
      },
    });
  }
}

async function validateEmailFlow(state: State, dispatch: Dispatch<Action>) {
  const emailFlow = getEmailFlow(state.rule);
  let response: EmailFlowCheckReponse;
  try {
    const request: EmailFlowCheckBody = {
      actionType: state.rule.actionType,
      bidirectional: emailFlow.bidirectional,
      fromTo: emailFlow.fromTo,
    };

    response = await apiRequest<EmailFlowCheckReponse>(
      "POST",
      `/rules/email-flow/check`,
      request
    );
  } catch (error) {
    return dispatch({
      type: "asyncValidationEnds",
      payload: {
        valid: false,
        validation: "EMAIL_FLOW",
        error: "FETCH_ERROR",
      },
    });
  }

  if (response.code === "VALID") {
    return dispatch({
      type: "asyncValidationEnds",
      payload: { valid: true, validation: "EMAIL_FLOW" },
    });
  }

  return dispatch({
    type: "asyncValidationEnds",
    payload: {
      valid: false,
      validation: "EMAIL_FLOW",
      error: response.code === "IN_USE" ? "IN_USE" : "INVALID",
    },
  });
}

async function validateArchivingAddress(
  state: State,
  dispatch: Dispatch<Action>
) {
  const archivingAddress = getArchivingAddress(state.rule);
  let response;
  try {
    response = await apiRequest<CheckArchivingAddressResponse>(
      "GET",
      `/rules/archiving-address/${encodeURIComponent(
        archivingAddress.emailAddress
      )}/check`
    );
  } catch (error) {
    return dispatch({
      type: "asyncValidationEnds",
      payload: {
        valid: false,
        validation: "ARCHIVING_ADDRESS",
        error: "FETCH_ERROR",
      },
    });
  }

  if (response.code === "VALID") {
    return dispatch({
      type: "asyncValidationEnds",
      payload: { valid: true, validation: "ARCHIVING_ADDRESS" },
    });
  }

  return dispatch({
    type: "asyncValidationEnds",
    payload: {
      valid: false,
      validation: "ARCHIVING_ADDRESS",
      error: response.code === "IN_USE" ? "IN_USE" : "INVALID",
    },
  });
}

async function validateStorageAccess(state: State, dispatch: Dispatch<Action>) {
  const storage = getStorage(state.rule);
  let accounts: string[];

  if (state.storageManagement === AccountManagement.MANUAL) {
    accounts = [asUserValue(storage)];
  }

  if (state.storageManagement === AccountManagement.BALANCED) {
    accounts = asUserValueCollection(storage);
  }

  const requestBody: CheckAccessRequest = {
    provider: storage.provider,
    accounts,
  };

  if (storage.provider === "DROPBOX") {
    requestBody.dropboxId = storage.userId;
  }

  try {
    const access = await apiRequest<CheckAccessResponse>(
      "POST",
      "/storage-accounts/check-access",
      requestBody
    );

    return dispatch({
      type: "asyncValidationEnds",
      payload: {
        validation: "STORAGE_ACCOUNTS_ACCESS",
        valid: access.isValid,
        ...(access.errors && { storageAccessErrors: access.errors }),
      },
    });
  } catch (error) {
    return dispatch({
      type: "asyncValidationEnds",
      payload: {
        valid: false,
        validation: "STORAGE_ACCOUNTS_ACCESS",
        error: "FETCH_ERROR",
      },
    });
  }
}

async function validateFolderPath(state: State, dispatch: Dispatch<Action>) {
  const fieldsWithError = [];
  const fields = state.checks.storageFilename.fieldsToCheck;

  for (const { field, value } of fields) {
    const isPathField =
      field === "folderStructure" ||
      field === "emailSubfolder" ||
      field === "attachmentsSubfolder" ||
      field === "bodySubfolder";

    try {
      const { formatted, formattedList } = await apiRequest<SanitizedFilename>(
        "POST",
        "/platform/check-filename",
        { value }
      );

      if (isPathField) {
        const isInValid = formattedList.join("/") !== value;
        isInValid && fieldsWithError.push(field);
        continue;
      }

      if (formatted !== value) {
        fieldsWithError.push(field);
      }
    } catch (e) {
      dispatch({
        type: "asyncValidationEnds",
        payload: {
          valid: false,
          validation: "FILENAME_FORMAT",
          error: "FETCH_ERROR",
        },
      });
      break;
    }
  }

  return dispatch({
    type: "asyncValidationEnds",
    payload: {
      validation: "FILENAME_FORMAT",
      valid: !fieldsWithError.length,
      fieldsWithError,
    },
  });
}
