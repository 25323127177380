import { TimePeriodStat } from "@dashboard-v3/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function reduceStatsProp(stats: TimePeriodStat[], prop: string): number {
  return stats
    .flatMap(({ statsByCode }) =>
      Object.keys(statsByCode)
        .filter(key => key.startsWith(prop))
        .map(key => statsByCode[key])
    )
    .reduce((acc, val) => acc + val, 0);
}

export function monthlyStats(stats: TimePeriodStat[], property: string) {
  const now = dayjs.utc();
  const thirtyDaysAgo = now.subtract(30, "days");
  const sixtyDaysAgo = now.subtract(60, "days");

  const lastMonth = { stats: [] as TimePeriodStat[], amount: 0 };
  const previousToLastMonth = {
    stats: [] as TimePeriodStat[],
    amount: 0,
  };

  lastMonth.stats = stats.filter(stat =>
    dayjs(stat.date).isAfter(thirtyDaysAgo)
  );
  lastMonth.amount = reduceStatsProp(lastMonth.stats, property);

  previousToLastMonth.stats = stats.filter(
    stat =>
      dayjs(stat.date).isBefore(thirtyDaysAgo) &&
      dayjs(stat.date).isAfter(sixtyDaysAgo)
  );
  previousToLastMonth.amount = reduceStatsProp(
    previousToLastMonth.stats,
    property
  );

  return { lastMonth, previousToLastMonth };
}
