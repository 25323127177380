import { useTranslation } from "react-i18next";
import {
  AccordionDetails,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import DraftsRoundedIcon from "@material-ui/icons/DraftsRounded";
import {
  AccordionContainer,
  ButtonContainer,
  DataItemWrapper,
  ListWrapper,
  StyledListAvatar,
  SummaryContainer,
  SummaryGrid,
} from "./styled";
import { ReprocessEmailsSummary } from "@dashboard-v3/api";
import { useState } from "react";
import { useSnackbar } from "notistack";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import ButtonWithLoading from "components/ButtonWithLoading";
import { getProviderLabel } from "utils/storageProviders";

interface ItemProps {
  summary: ReprocessEmailsSummary[number];
}

export default function Item({ summary }: ItemProps) {
  const { t } = useTranslation("reprocessEmails");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleReprocess = async e => {
    e.stopPropagation();
    const { storageAccountsKey, storageAccounts } = summary;
    const requestBody = storageAccounts.map(storageAccount => ({
      storageAccount,
    }));

    setLoading(true);

    try {
      await apiRequest(
        "PUT",
        `/storage-accounts/reprocess-emails/${storageAccountsKey}`,
        requestBody
      );
    } catch (e) {
      const errorCode = getErrorCode(e);
      const msg = errorCode === "PROCESS_LIMIT_REACHED" ? "limit" : "unknown";

      enqueueSnackbar(`list.item.processError.${msg}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ListItem disableGutters>
      <AccordionContainer>
        <SummaryContainer expandIcon={<ExpandMore />}>
          <SummaryGrid>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.provider")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {getProviderLabel(summary.storageType)}
              </Typography>
            </DataItemWrapper>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.emails")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {summary.amount}
              </Typography>
            </DataItemWrapper>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.authentication")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {summary.storageAuthentication}
              </Typography>
            </DataItemWrapper>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.accounts")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {summary.storageAccounts.length}
              </Typography>
            </DataItemWrapper>

            <ButtonContainer>
              <ButtonWithLoading
                size="small"
                variant="outlined"
                loading={loading}
                onClick={handleReprocess}
              >
                {t("list.item.button")}
              </ButtonWithLoading>
            </ButtonContainer>
          </SummaryGrid>
        </SummaryContainer>
        <Divider />
        <AccordionDetails style={{ display: "inline", padding: 0 }}>
          <ListWrapper>
            <Typography variant="subtitle2">
              {t("list.item.storageAccounts")}
            </Typography>
            <List>
              {summary.storageAccounts.map(account => (
                <ListItem key={account}>
                  <StyledListAvatar>
                    <Avatar>
                      <DraftsRoundedIcon fontSize="small" />
                    </Avatar>
                  </StyledListAvatar>
                  <ListItemText
                    primary={account}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              ))}
            </List>
          </ListWrapper>
        </AccordionDetails>
      </AccordionContainer>
    </ListItem>
  );
}
