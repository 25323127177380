import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  isOpen: boolean;
  details: { [x: string]: string };
  onClose: (update: { isOpen: boolean; details: {} }) => void;
}

export default function DetailsModal({ isOpen, details, onClose }: Props) {
  const { t } = useTranslation("tracing");

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose({ isOpen: false, details: {} })}
    >
      <DialogTitle>{t("detailsModal.title")}</DialogTitle>
      <DialogContent dividers>
        <JsonContainer>{JSON.stringify(details, null, 2)}</JsonContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          onClick={() => onClose({ isOpen: false, details: {} })}
        >
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const JsonContainer = styled.pre`
  font-family: monospace;
  font-size: 12px;
`;
