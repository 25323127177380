import { ExternalApiKey } from "@dashboard-v3/api";
import {
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import useSWR from "swr";
import request, { fetcher, getErrorCode } from "utils/apiRequestWithErrorCode";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useState } from "react";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "components/ButtonWithLoading";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { red } from "@material-ui/core/colors";
import useClipboard from "utils/useClipboard";
import Confirm from "components/Confirm";

export default function MxHeroApi() {
  const { t } = useTranslation("mxheroApi");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const copyToClipboard = useClipboard();

  const [status, setStatus] = useState<
    "TOGGLING" | "DELETING" | "CREATING" | null
  >();

  const { data, isLoading, mutate, error } = useSWR<ExternalApiKey>(
    "/external-api-key",
    fetcher,
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );

  async function toggleKeyState() {
    setStatus("TOGGLING");
    try {
      const newState = await request<ExternalApiKey>(
        "PATCH",
        "/external-api-key",
        {
          enable: !data.enabled,
        }
      );

      mutate(newState);

      const message = data.enabled ? "keyWasDisabled" : "keyWasEnabled";
      enqueueSnackbar(message, { variant: "success" });
    } catch (error) {
      const message = data.enabled ? "errorOnDisable" : "errorOnEnable";
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setStatus(null);
    }
  }

  async function deleteKey() {
    setStatus("DELETING");
    try {
      await request<ExternalApiKey>("DELETE", "/external-api-key");
      mutate(null);

      enqueueSnackbar(t("keyDeleted"), { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("errorOnDelete"), { variant: "error" });
    } finally {
      setStatus(null);
    }
  }

  async function createKey() {
    setStatus("CREATING");
    try {
      const newApiKey = await request<ExternalApiKey>(
        "POST",
        "/external-api-key",
        {}
      );
      mutate(newApiKey);

      enqueueSnackbar(t("createdKey"), { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("errorOnCreate"), { variant: "error" });
    } finally {
      setStatus(null);
    }
  }

  return (
    <Paper className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography>{t("mainTitle")}</Typography>

          <Typography variant="body2" color="textSecondary">
            <Trans t={t} i18nKey="subTitle">
              Manage your
              <DatasetLink />
              through an API.
            </Trans>
          </Typography>
        </Box>
        {/*
        <Button size="small" color="primary">
          How to use it
        </Button>
      */}
      </Box>

      {isLoading && !status && (
        <Box mt={4}>
          <LinearProgress />
        </Box>
      )}

      {data && (
        <>
          <Box mt={4} display="flex" gridGap="1rem" alignItems="center">
            <TextField
              name="openAIToken"
              label="API Key"
              variant="outlined"
              fullWidth
              className={classes.apiInput}
              value={data.value}
              InputProps={{
                disabled: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={t("common:copyToClipboard")}>
                      <IconButton
                        onClick={() => copyToClipboard(data.value)}
                        edge="end"
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />

            {!data.enabled && (
              <ButtonWithLoading
                onClick={toggleKeyState}
                loading={status === "TOGGLING"}
              >
                {t("enableApiKey")}
              </ButtonWithLoading>
            )}

            {data.enabled && (
              <Tooltip title={t("disableApiKeyTooltip")}>
                <Confirm
                  message={t("disableApiKeyConfirmation")}
                  onAccept={toggleKeyState}
                  actionable={openConfirm => (
                    <ButtonWithLoading
                      loading={status === "TOGGLING"}
                      onClick={openConfirm}
                    >
                      {t("disableApiKey")}
                    </ButtonWithLoading>
                  )}
                />
              </Tooltip>
            )}

            <Confirm
              message={t("deleteApiKeyConfirmation")}
              onAccept={deleteKey}
              actionable={openConfirm => (
                <ButtonWithLoading
                  color="secondary"
                  onClick={openConfirm}
                  loading={status === "DELETING"}
                >
                  {t("common:delete")}
                </ButtonWithLoading>
              )}
            />
          </Box>
          {data && !data.enabled && (
            <Box display="flex" gridGap=".5rem" mt={2} alignItems="center">
              <Box display="flex" color={red[200]} alignItems="stretch">
                <RemoveCircleIcon />
              </Box>
              <Typography variant="body2">{t("isDisabled")}</Typography>
            </Box>
          )}
        </>
      )}

      {error && getErrorCode(error) === "NOT_FOUND" && (
        <Box mt={3}>
          <ButtonWithLoading
            loading={status === "CREATING"}
            variant="contained"
            disableElevation
            onClick={createKey}
          >
            {t("create")}
          </ButtonWithLoading>
        </Box>
      )}
    </Paper>
  );
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "16px",
  },
  apiInput: { maxWidth: "50%", display: "block" },
});

function DatasetLink() {
  const { t } = useTranslation(["sidebar", "datasets"]);
  return (
    <Tooltip
      title={t("datasets:description")}
      aria-label={t("datasets:description")}
    >
      <Link href="/datasets" target="_blank">
        {t("datasets")}
      </Link>
    </Tooltip>
  );
}
