import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import ButtonWithLoading from "components/ButtonWithLoading";
import request, { getErrorCode } from "utils/apiRequestWithErrorCode";
import { getUser } from "utils/authentication";
import useOrganization from "utils/useOrganization";
import { useStyles } from "./styles";
import SubdomainInput from "./SubdomainInput";
import RetentionOptions from "./RetentionOptions";

import { MxheroShareConfig } from "@dashboard-v3/api";

export enum Status {
  Loading = "LOADING",
  Disabled = "DISABLED",
  Form = "FORM",
  Saving = "SAVING",
  Enabled = "ENABLED",
}

export default function MxheroShareLinks() {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const { organization, revalidateOrganization } = useOrganization();
  const [formError, setFormError] = useState<string>();
  const [form, setForm] = useState<MxheroShareConfig>(initForm);
  const [status, setStatus] = useState<Status>(Status.Loading);
  const classes = useStyles();
  const isEdit = !!organization?.mxheroStorage?.hostname;

  useEffect(() => {
    if (organization?.mxheroStorage) {
      setForm(() => {
        const { hostname, retention } = organization.mxheroStorage;
        if (!hostname) return initForm();
        return { retention, subdomain: hostname };
      });
      setStatus(() => {
        const { hostname } = organization.mxheroStorage;
        if (hostname) return Status.Enabled;
        return Status.Disabled;
      });
    }
  }, [organization?.mxheroStorage]);

  async function onSubmit() {
    setStatus(Status.Saving);
    try {
      const { retention, subdomain } = form;
      const method = isEdit ? "PATCH" : "POST";
      const body = { retention, ...(!isEdit && { subdomain }) };

      await request(method, "/mxhero-shares-config", body);
      await revalidateOrganization();

      setStatus(Status.Enabled);
    } catch (error) {
      const errorCode = getErrorCode(error);

      switch (errorCode) {
        case "INVALID_CHARACTERS":
          setFormError(t("mxheroShares.invalidCharacters"));
          break;
        case "HOSTNAME_ALREADY_EXISTS":
          setFormError(t("mxheroShares.hostnameInUse"));
          break;
        default:
          enqueueSnackbar(t("common:errors.fetchError"), {
            variant: "error",
          });
          break;
      }

      setStatus(Status.Form);
    }
  }

  const updateForm = e => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Paper className={classes.container}>
      <Box>
        <Typography>
          {t("mxheroShares.title")}
          {"  "}
          <Typography variant="caption" color="textSecondary">
            <i>(beta)</i>
          </Typography>
        </Typography>
        {status !== Status.Disabled && (
          <Box>
            <Typography variant="caption" color="textSecondary">
              {t("mxheroShares.subTitle")}
            </Typography>
          </Box>
        )}
      </Box>

      {status === Status.Loading && (
        <Box mt={4}>
          <LinearProgress />
        </Box>
      )}

      <Box mt={1} display="flex" flexDirection="column" gridGap="1rem">
        {status === Status.Disabled ? (
          <Button
            style={{ alignSelf: "flex-start" }}
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => setStatus(Status.Form)}
          >
            {t("mxheroShares.setup")}
          </Button>
        ) : (
          <>
            <SubdomainInput
              isReadOnly={isEdit}
              error={formError}
              disabled={status === Status.Saving}
              value={form.subdomain}
              onChange={updateForm}
            />
            <RetentionOptions
              disabled={status === Status.Saving}
              value={form.retention}
              onChange={updateForm}
            />
            <ButtonWithLoading
              loading={status === Status.Saving}
              variant="contained"
              disableElevation
              onClick={onSubmit}
            >
              {isEdit
                ? t("mxheroShares.submitBtn.edit")
                : t("mxheroShares.inputAction")}
            </ButtonWithLoading>
          </>
        )}
      </Box>
    </Paper>
  );
}

function initForm() {
  const { email } = getUser();
  const emailDomain = email.split("@")[1];
  return {
    retention: "ONE_YEAR" as MxheroShareConfig["retention"],
    subdomain: emailDomain.split(".")[0],
  };
}
