import React, { useEffect, useState } from "react";
import {
  Paper,
  LinearProgress,
  Typography,
  TableRow,
  Button,
} from "@material-ui/core";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ButtonWithLoading from "components/ButtonWithLoading";
import PaperTable from "./PaperTable";

const { Headers, Body, Cell } = PaperTable;

async function downloadInvoice(id) {
  const url = `${process.env.REACT_APP_API_URL}/invoices/${id}`;
  window.open(url);
}

// eslint-disable-next-line react/prop-types
const Invoices = ({ xeroContact }) => {
  const [invoices, setInvoices] = useState(null);
  const [hadErrors, setHadErros] = useState(false);
  const [isLoadingPayingNow, setIsLoadingPayingNow] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("billing");

  useEffect(() => {
    async function fetchInvoices() {
      try {
        const results = await apiRequest(
          "GET",
          `/invoices?xeroContact=${xeroContact}`
        );

        setInvoices(results);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("invoices.fetchError"), { variant: "error" });
        setHadErros(true);
      }
    }

    if (xeroContact) fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xeroContact]);

  async function payInvoice(invoice) {
    try {
      setIsLoadingPayingNow(true);
      const { checkoutUrl } = await apiRequest(
        "POST",
        `/payments/${invoice.number}`
      );
      setIsLoadingPayingNow(false);
      window.location.href = checkoutUrl;
    } catch (exception) {
      setIsLoadingPayingNow(false);
      console.error(`Error when try to pay invoice`, exception);
      enqueueSnackbar(t("invoices.preparePaymentError"), { variant: "error" });
    }
  }

  if (!xeroContact) return "";

  if (hadErrors)
    return (
      <Paper
        style={{ padding: "12px 16px" }}
        data-testid="invoices_loading-error"
      >
        <Typography>{t("invoices.noInformation")}</Typography>
      </Paper>
    );

  if (!invoices) {
    return (
      <Paper style={{ padding: "12px 16px" }} data-testid="invoices_loading">
        <LinearProgress />
      </Paper>
    );
  }

  return (
    <PaperTable title={t("invoices.title")} data-testid="invoices-list">
      <Headers>
        {[
          t("invoices.number"),
          t("invoices.dueDate"),
          t("invoices.total"),
          t("invoices.paid"),
          "",
        ]}
      </Headers>
      <Body>
        {invoices.map(invoice => (
          <TableRow key={invoice.id}>
            <Cell>{invoice.number}</Cell>
            <Cell>{new Date(invoice.dueDate).toLocaleDateString()}</Cell>
            <Cell>
              {invoice.currency} {(invoice.total || 0.0).toLocaleString()}
            </Cell>
            <Cell>
              {invoice.amountDue > 0 ? (
                <ButtonWithLoading
                  size="small"
                  variant="contained"
                  disableElevation
                  color="primary"
                  loading={isLoadingPayingNow}
                  onClick={() => payInvoice(invoice)}
                >
                  {t("invoices.payNow")}
                </ButtonWithLoading>
              ) : (
                t("common:yes")
              )}
            </Cell>
            <Cell>
              <Button
                size="small"
                onClick={() => downloadInvoice(invoice.id)}
                color="primary"
              >
                {t("invoices.download")}
              </Button>
            </Cell>
          </TableRow>
        ))}
      </Body>
    </PaperTable>
  );
};

export default Invoices;
