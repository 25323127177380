import styled from "styled-components";
import {
  Accordion,
  AccordionSummary,
  ListItemAvatar,
  withStyles,
} from "@material-ui/core";

export const ButtonContainer = styled.div`
  justify-self: end;
  display: flex;
  gap: 2px;
`;

export const SummaryContainer = withStyles({
  root: {
    padding: 0,
    paddingRight: "10px",
  },
  content: {
    margin: 0,
  },
})(AccordionSummary);

export const AccordionContainer = styled(Accordion)`
  width: 100%;
  padding: 8px 5px;
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

export const SummaryGrid = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 2fr 3fr 1fr 1fr;
  align-items: center;
  padding: 0;
`;

export const DataItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledListAvatar = styled(ListItemAvatar)`
  > .MuiAvatar-root {
    width: 30px !important;
    height: 30px !important;
  }
`;

export const ListWrapper = styled.div`
  padding: 15px 15px 0 15px;
`;
