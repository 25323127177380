import { TimePeriodStat } from "@dashboard-v3/api";
import reduce90Days, { StatCode } from "../reduce90Days";

type Totals = ReturnType<typeof emptyResults>;

export type EmailFlowSumData = Array<{ date: number } & Totals>;

export function parseData(data: Array<TimePeriodStat>): EmailFlowSumData {
  return reduce90Days<Totals>(
    data,
    (totals, key, valueOfKey) => {
      const mailFlowType = getMailFlowType(key);
      if (mailFlowType) {
        totals[mailFlowType] += valueOfKey;
      }
      return totals;
    },
    emptyResults
  );
}

function emptyResults() {
  return {
    in: 0,
    out: 0,
    both: 0,
  };
}

type EmailFlowType = keyof Totals;

function getMailFlowType(key: StatCode): EmailFlowType | null {
  if (key === "engine_mail_in") return "in";
  if (key === "engine_mail_out") return "out";
  if (key === "engine_mail_both") return "both";

  return null;
}
