import { Box, Grid, Paper, Typography } from "@material-ui/core";
import PercentageChip from "./PercentageChip";

export default function Block({
  label,
  value,
  previousAmount,
  currentAmount,
}: {
  label: string;
  value: string;
  previousAmount: number;
  currentAmount: number;
}) {
  return (
    <Paper style={{ padding: "18px 20px" }}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs>
          <Typography variant="subtitle1">{label}</Typography>
        </Grid>
        <Grid item>
          {currentAmount !== previousAmount && (
            <PercentageChip value={currentAmount} oldValue={previousAmount} />
          )}
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant="h4">{value}</Typography>
      </Box>
    </Paper>
  );
}
