import { getUser } from "utils/authentication";
import random from "utils/random";

export const archivingAddressDomain = "go.mail2cloud.io";

export function randomArchivingAddress() {
  const user = getUser();
  if (user) {
    return `${user.organizationId}_${random()}@${archivingAddressDomain}`;
  }
  return "";
}
