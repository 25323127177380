import { useState } from "react";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ListFromTo from "./ListFromTo";
import { FromTo } from "@dashboard-v3/api";
import { Option } from "pages/Rules/FromToSelector/types";

type Props = {
  type: "exclude" | "filter";
  value?: FromTo[];
  disabled?: boolean;
  onChange: (changes?: FromTo[]) => void;
  allowedTypes?: Array<Option["type"]>;
  errors: { [key: number]: string };
};

export default function FilterFromTo({
  type,
  value,
  disabled,
  onChange,
  allowedTypes,
  errors,
}: Props) {
  const { t } = useTranslation("rules");
  const [isOn, setIsOn] = useState(Boolean(value && value?.length));

  function toggle(event) {
    const { checked } = event.target;
    setIsOn(checked);
    if (!checked) {
      onChange(null);
    }
  }

  return (
    <>
      <FormControlLabel
        control={<Switch checked={isOn} color="primary" onChange={toggle} />}
        disabled={disabled}
        label={
          <Typography style={{ fontWeight: "500" }} variant="subtitle1">
            {t(`targetFilter.selectorTitle`, {
              type: t(`targetFilter.${type}_plural`),
            })}
          </Typography>
        }
      />
      <FormHelperText>
        {t(`targetFilter.selectorDescription`, {
          type: t(`targetFilter.${type}`),
        })}
      </FormHelperText>

      {isOn && (
        <Box mt={3}>
          <ListFromTo
            type={type}
            allowedTypes={allowedTypes}
            errors={errors}
            value={value}
            onChange={onChange}
          />
        </Box>
      )}
    </>
  );
}
