import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { AdvancedOptionsContainer } from "components/Styled";
import SelectTemplateOptions, { canUseTemplate } from "./SelectTemplateOptions";
import SelectStores, { canUseStores } from "./SelectStores";
import TrackingEmails, { canTrackEmails } from "./TrackingEmails";
import Locale from "./Locale";
import LinkToFolder from "./LinkToFolder";
import UseStoreByUserAccount, {
  canUseStoreByUserAccount,
} from "./UseStoreByUserAccount";
import canLinkToFolder from "./canLinkToFolder";
import { StyledDetails, StyledSummary } from "../Styled";
import { getStorage } from "../utils";
import { useRuleContext } from "../../CreateOrEdit/context/ruleContext";
import SelectSecurityOptions, {
  showSecurityOptionsIn,
} from "../SelectSecurityOptions";

import { State } from "pages/Rules/CreateOrEdit/context/types";
import { Option } from "../types";

type Params = { securityOptions: Option[] };

/* 

# Selects to show:

+ Stores: when use ONEDRIVE or GOOGLEDRIVE for ORGANIZATIONS
+ Security options: When is not PROTECT_EMAILS or REPLACE_ATTACHMENTS
+ Templates: When use BOX or EGNYTE
+ Tracking: When is REPLACE_ATTACHMENTS (only in EMAIL FLOW) or PROTECT_EMAILS 
  AND select bidirectional (Between option) or the From Side of email flow is managed.
  (Check ticket https://mxhero.atlassian.net/browse/MXH-3791)

A case without advanced options: rule of REPLACE_ATTACHMENTS with MAILBOX and using a 
Google Drive individual account

*/

export default function AdvancedOptions({ securityOptions }: Params) {
  const { t } = useTranslation("rules");
  const { state } = useRuleContext();
  const { provider, authentication } = getStorage(state.rule);
  const options = enabledOptions(state);

  return (
    <AdvancedOptionsContainer data-testid="CloudStorage_AdvancedOptions">
      <StyledSummary>
        <Typography data-testid="cloud-storage_advanced-options_label">
          {t("cloudStorage.advancedOptions")}
        </Typography>
      </StyledSummary>
      <StyledDetails>
        {options.storeByUserAccount && <UseStoreByUserAccount />}

        {options.stores && (
          <SelectStores key={`${provider}-${authentication}`} />
        )}

        {options.security && (
          <SelectSecurityOptions options={securityOptions} />
        )}

        {options.templates && <SelectTemplateOptions />}

        {options.trackEmails && <TrackingEmails />}

        {options.canLinkToFolder && <LinkToFolder />}
        <Locale />
      </StyledDetails>
    </AdvancedOptionsContainer>
  );
}

function enabledOptions(state: State) {
  const { rule } = state;
  const storage = getStorage(rule);

  if (!storage?.provider) return {};

  return {
    stores: canUseStores(storage),
    security: showSecurityOptionsIn("ADVANCED_OPTIONS", rule),
    templates: canUseTemplate(storage),
    trackEmails: canTrackEmails(state),
    storeByUserAccount: canUseStoreByUserAccount(state),
    canLinkToFolder: canLinkToFolder(state),
  };
}
