import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import useSWR from "swr";
import { SpfCheckResponse } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";
import StatusIcon from "components/StatusIcon";

type Params = { domainName: string };

export default function SpfProperty({ domainName }: Params) {
  const { t } = useTranslation("orgAndDomains");

  const { data, isLoading, error } = useSWR(
    `/domains/${domainName}/spf`,
    path => apiRequest<SpfCheckResponse>("GET", path),
    {
      revalidateOnFocus: false,
    }
  );

  if (error)
    return (
      <>
        {t([
          `error.getSpfStatus.${getErrorCode<"DOMAIN_NOT_FOUND">(error)}`,
          "error.getSpfStatus.SYSTEM_FAILURE",
        ])}
      </>
    );

  if (!data || isLoading) return <CircularProgress size={12} />;

  if (data.enable) {
    return (
      <>
        <StatusIcon state="enable" data-testid="spfEnabledIndicator" />
        {t("list.spfIsSet")}
        <SpfLink />
      </>
    );
  }

  return (
    <>
      <StatusIcon state="disable" />
      {t("list.spfNeedsConfig")}
      <SpfLink />
    </>
  );
}

const SpfLink = () => {
  const { t } = useTranslation("orgAndDomains");

  return (
    <Tooltip title={t("list.howToSetupSpf")}>
      <IconButton
        href="https://support.mxhero.com/hc/en-us/articles/201093935-Improving-Delivery-with-SPF"
        size="small"
        style={{ marginLeft: "7px" }}
        target="_blank"
        onClick={event => event.stopPropagation()}
      >
        <OpenInNewIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};
