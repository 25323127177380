/* eslint-disable react-hooks/exhaustive-deps*/
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import AlertMsg from "components/AlertMsg";
import { FieldSkeleton } from "components/SkeletonLoading";
import openAuthPopup, {
  isClosedByUser,
  isDifferentAccount,
} from "pages/Integrations/Accounts/openAuthPopup";
import AccountOptionLabel from "./AccountOptionLabel";
import AccountSyncModal from "./AccountSyncModal";
import AddAccountModal from "./AddAccountModal";
import { StyledDivider, StyledMenuItem } from "./Styled";
import useStorageAccounts from "./useStorageAccounts";
import { RuleUrlParams } from "../CreateOrEdit";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import {
  CloudStorageStatus,
  getCloudStorageStatus,
  isIndividual,
} from "./utils";
import { TFunction } from "i18next";
import { Storage, StorageProviderWithAuth } from "@dashboard-v3/api";
import { Item } from "./types";
import { Rule } from "../types";
import { getActionState, nameFormatDefaults } from "../RuleActions/utils";
import { ActionState } from "../RuleActions/types";
import { remove } from "utils/array";

export type Change = Item["storageAccount"];

type Props = {
  storage: Storage;
  onChange: (change: Change) => void;
};

function supportMxHeroStorage(rule: Rule) {
  if (rule.targetType === "EMAIL_FLOW") {
    if (rule.actionType === "PROTECT_EMAILS") return true;
    if (rule.actionType === "REPLACE_ATTACHMENTS") return true;
  }
  return false;
}

export default function SelectStorageAccount({ storage, onChange }: Props) {
  const { t } = useTranslation(["rules", "components"]);
  const [openSyncModal, setOpenSyncModal] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Item>();
  const [requireChangesInAction, setRequireChangesInAction] =
    useState<boolean>(false);
  const { action }: RuleUrlParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useRuleContext();

  const { reFetchOptions, items, isLoading } = useStorageAccounts(
    supportMxHeroStorage(state.rule)
  );

  const accountStatus = getCloudStorageStatus(storage, items);
  const warning = getWarningMessage(accountStatus, storage, t);
  const isEdit = Boolean(state.rule.id) && action === "edit";
  const showWarning = isEdit && accountStatus !== CloudStorageStatus.OK;
  const isDragAndDrop = state.rule.actionType === "DRAG_AND_DROP";

  const renderValue = (key: unknown): string => {
    if (accountStatus === CloudStorageStatus.NOT_FOUND) return storage.userId;
    if (!key) return "";

    const item = items.find(sameKey(key as string));
    return t(item?.option?.title);
  };

  function storageAccountToKey(storage: Storage): string {
    if (!storage) return "";
    if (accountStatus === CloudStorageStatus.NOT_FOUND) return storage?.userId;
    const item = items?.find(sameStorage(storage));
    return item?.key || "";
  }

  const handleStorageAccount = e => {
    if (e.target.value === "addAccount") {
      return setOpenAddModal(true);
    }
    const itemKey = e.target.value as string;
    const item = items.find(sameKey(itemKey));
    if (item.storageAccount.provider === "MXHERO_STORAGE") {
      let wrongContentToSave = false;
      // check actions
      if (state.rule.actionType === "REPLACE_ATTACHMENTS") {
        const actionState = getActionState(state);
        wrongContentToSave = actionState.content.some(content =>
          ["PDF", "EML", "MSG"].includes(content.type)
        );
      }

      if (state.rule.actionType === "PROTECT_EMAILS") {
        const actionState = getActionState(state);
        wrongContentToSave = actionState.content.some(content =>
          ["EML", "MSG"].includes(content.type)
        );
      }

      if (wrongContentToSave) return setRequireChangesInAction(true);
    }

    onChange(item.storageAccount);
  };

  const handleAccountAuth = async (
    provider: StorageProviderWithAuth,
    user: string
  ) => {
    try {
      await openAuthPopup(provider, user);
      reFetchOptions();
      enqueueSnackbar(t("cloudStorage.reAuth.success"), {
        variant: "success",
      });
    } catch (error) {
      if (isClosedByUser(error)) return;
      if (isDifferentAccount(error)) {
        return enqueueSnackbar(t("cloudStorage.reAuth.matchError", { user }), {
          variant: "error",
        });
      }
      enqueueSnackbar(t("cloudStorage.reAuth.error"), { variant: "error" });
    }
  };

  const handleWarningAction = async () => {
    const { provider, userId } = storage;
    await handleAccountAuth(provider as StorageProviderWithAuth, userId);
  };

  const handleOnLabelClick = async (item: Item) => {
    const { storageAccount } = item;
    const { authentication, provider, userId } = storageAccount;

    if (isIndividual(authentication)) {
      await handleAccountAuth(provider as StorageProviderWithAuth, userId);
    } else {
      setSelectedItem(item);
      setOpenSyncModal(true);
    }
  };

  const handleAccountChange = () => {
    reFetchOptions();
    setOpenSyncModal(false);
    setSelectedItem(null);
  };

  const disableWrongActions = () => {
    let update: Partial<ActionState> = {};

    if (state.rule.actionType === "REPLACE_ATTACHMENTS") {
      const actionState = getActionState(state);

      update = {
        ...actionState,
        ...{
          content: [
            {
              type: "ATTACHMENTS",
              nameFormat: nameFormatDefaults("ATTACHMENTS"),
            },
          ],
        },
      };
    }

    if (state.rule.actionType === "PROTECT_EMAILS") {
      const actionState = getActionState(state);

      let updatedContent = remove(
        content => ["EML", "MSG"].includes(content.type),
        actionState.content
      );

      if (updatedContent.length === 0) {
        updatedContent.push({
          type: "ATTACHMENTS",
          nameFormat: nameFormatDefaults("ATTACHMENTS"),
        });
      }

      update = {
        ...actionState,
        ...{
          content: updatedContent,
        },
      };
    }

    dispatch({
      type: "updateStep",
      payload: { step: "action", changes: update },
    });
  };

  if (isLoading) {
    return <FieldSkeleton />;
  }

  return (
    <>
      <SelectField
        name="storageAccount"
        label={t("cloudStorage.accounts.label")}
        options={items}
        renderValue={renderValue}
        value={storageAccountToKey(storage)}
        onChange={handleStorageAccount}
        disabled={isDragAndDrop}
        renderOption={(value: unknown) => {
          const item = value as Item;
          const { option, key } = item;

          const renderedItem = (
            <StyledMenuItem key={key} value={key} disabled={!option.enabled}>
              {item.storageAccount && (
                <AccountOptionLabel
                  item={item}
                  onLabelClick={handleOnLabelClick}
                />
              )}
              <Typography>{t(option.title)}</Typography>
            </StyledMenuItem>
          );

          if (option.showSeparator) {
            return [
              <ListSubheader key="SEPARATOR-TOP">
                <StyledDivider />
              </ListSubheader>,

              <StyledMenuItem key="addAccount" value="addAccount">
                <Typography>{t("components:addAccount")}</Typography>
              </StyledMenuItem>,

              <ListSubheader key="SEPARATOR_BOTTOM">
                <StyledDivider />
              </ListSubheader>,
              renderedItem,
            ];
          }

          return renderedItem;
        }}
      />
      {showWarning && (
        <AlertMsg actionBtn={warning.buttonText} onClick={handleWarningAction}>
          {warning.message}
        </AlertMsg>
      )}
      {selectedItem && (
        <AccountSyncModal
          isOpen={openSyncModal}
          selectedAccount={selectedItem}
          onChange={handleAccountChange}
          onClose={() => setOpenSyncModal(false)}
        />
      )}
      <AddAccountModal
        isOpen={openAddModal}
        onAccountAdd={async change => {
          await reFetchOptions();
          onChange(change);
        }}
        onClose={() => setOpenAddModal(false)}
      />
      <ConfirmChangesInAction
        isOpen={requireChangesInAction}
        actionType={state.rule.actionType}
        onClose={accept => {
          if (accept) {
            disableWrongActions();
            onChange({
              authentication: "ORGANIZATION",
              provider: "MXHERO_STORAGE",
            });
            setRequireChangesInAction(false);
          }
          setRequireChangesInAction(false);
        }}
      />
    </>
  );
}

const sameKey = (key: string) => (item: Item) => item.key === key;

const sameStorage = (storage: Storage) => (item: Item) => {
  const sameProvider =
    item.storageAccount &&
    item.storageAccount.authentication === storage.authentication &&
    item.storageAccount.provider === storage.provider;

  if (storage.authentication === "ORGANIZATION") return sameProvider;

  return sameProvider && item.storageAccount.userId === storage.userId;
};

const getWarningMessage = (
  status: CloudStorageStatus,
  storage: Storage,
  t: TFunction
) => {
  if (status === CloudStorageStatus.OUT_OF_SYNC) {
    return {
      message: t(`warnings.cloudStorage.${status}`, { ns: "components" }),
      buttonText: t("syncAgain", { ns: "components" }),
    };
  }

  if (status === CloudStorageStatus.NOT_FOUND) {
    return {
      message: t(`warnings.cloudStorage.${status}`, {
        ns: "components",
        account: storage.userId,
        provider: t(`cloudStorage.accounts.individual.${storage.provider}`),
      }),
      buttonText: t("addAccount", {
        ns: "components",
      }),
    };
  }
};

function ConfirmChangesInAction({
  isOpen,
  actionType,
  onClose,
}: {
  isOpen: boolean;
  actionType: Rule["actionType"];
  onClose: (accept: boolean) => void;
}) {
  return (
    <Dialog open={isOpen} onClose={() => onClose(false)}>
      <DialogTitle>The content to save must be changed</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {actionType === "REPLACE_ATTACHMENTS" &&
            "Only the attachements of the processed emails could be saved. Accept to disable the wrong options."}
          {actionType === "PROTECT_EMAILS" &&
            "The email (in EML or MSG) could no be saved for this cloud storage. Accept to disable the wrong options."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Select another cloud storage
        </Button>
        <Button
          onClick={() => onClose(true)}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
