import styled from "styled-components";
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditAccountBtn from "../Buttons/AddOrEditAccount";
import { useAccountState } from "../accountContext";
import StorageProperties from "./StorageProperties";
import AccountTags from "./AccountTags";
import { Account } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";
import ConfirmDelete from "components/ConfirmDelete";

export default function Item({ account }: { account: Account }) {
  const { t } = useTranslation("accounts");
  const { actions } = useAccountState();
  const { deleteAccount } = actions;
  const { email, aliases, sourceType, sourceLabel, groups, storage, tags } =
    account;

  return (
    <ListItem disableGutters>
      <Accordion
        style={{ width: "100%" }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          data-testid="accounts__account-summary"
          expandIcon={<ExpandMoreIcon />}
        >
          <ListItemIcon>
            <StyledAvatar>{email.charAt(0)}</StyledAvatar>
          </ListItemIcon>
          <ListItemText
            primary={email}
            secondary={
              !isManualSource(sourceType) && (
                <span style={{ textTransform: "capitalize" }}>
                  {t("source")}: {t(`sourceTypes.${sourceType}`)}{" "}
                  {sourceLabel ? ` - ${sourceLabel}` : ""}
                </span>
              )
            }
          />
          <ListItemSecondaryAction style={{ marginRight: "35px" }}>
            {canDelete(sourceType) && (
              <ConfirmDelete
                onAccept={() => deleteAccount(email)}
                message={t("buttons.confirmAccountDelete", { name: email })}
                variant="outlined"
              />
            )}

            <EditAccountBtn
              accountToEdit={{ email, storage, tags, aliases }}
              style={{ marginLeft: "10px" }}
              variant="outlined"
            />
          </ListItemSecondaryAction>
        </AccordionSummary>
        <StyledAccordionDetails style={{ display: "block" }}>
          {!isManualSource(sourceType) && (
            <ItemPropertyContainer>
              <PropertyLabel>{t("group")}</PropertyLabel>
              <PropertyValue
                style={{ color: groups.length ? "inherit" : grey[400] }}
              >
                {groups.length ? groups.join(", ") : t("list.noGroups")}
              </PropertyValue>
            </ItemPropertyContainer>
          )}

          <ItemPropertyContainer>
            <PropertyLabel>{t("alias_plural")}</PropertyLabel>
            <PropertyValue>{(aliases || []).join(", ")}</PropertyValue>
          </ItemPropertyContainer>

          <ItemPropertyContainer>
            <PropertyLabel>{t("tag_plural")}</PropertyLabel>
            <AccountTags tags={tags} />
          </ItemPropertyContainer>

          <ItemPropertyContainer>
            <PropertyLabel>{t("list.storageAccounts")}</PropertyLabel>
            <StorageProperties providers={storage} validateStorage />
          </ItemPropertyContainer>
        </StyledAccordionDetails>
      </Accordion>
    </ListItem>
  );
}

const isManualSource = (sourceType: Account["sourceType"]) =>
  sourceType === "MANUAL";

const canDelete = (sourceType: Account["sourceType"]) =>
  !sourceType || isManualSource(sourceType);

const ItemPropertyContainer = styled.div`
  margin-bottom: 20px;
`;

const PropertyLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.82rem;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const PropertyValue = styled(Typography)`
  min-height: 1.5rem;
`;

const StyledAvatar = styled(Avatar)`
  align-self: center;
  text-transform: uppercase;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  padding-bottom: 0;
  padding-top: 0;
`;
