import { useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import apiRequest from "utils/apiRequestWithErrorCode";
import ProcessState from "./ProgressMessage";
import { ResponseOrganization, o365gateway } from "@dashboard-v3/api";
import useInterval from "utils/useInterval";
import { Box } from "@material-ui/core";
import { InstalledStatus } from "./InstalledStatus";
import { useSnackbar } from "notistack";
import useAuthPopup, {
  PopupClosed,
  ResponseFail,
  ResponseSuccess,
  wasClosedByUser,
} from "components/useAuthPopup";
import FlowSelectionDialog from "./FlowSelectionDialog";
import { captureException, captureMessage } from "utils/traceError";
import { Office365Gateway } from "..";

type Progress = o365gateway.Progress;

enum Step {
  Idle = "IDLE",
  SelectFlow = "SELECT_FLOW",
  Auth = "AUTH",
  Progress = "PROGRESS",
  SavingChanges = "SAVING_CHANGES",
  End = "END",
  Fail = "FAIL",
}

type State = {
  step: Step;
  flow?: Office365Gateway;
  saveAsDisabled?: boolean;
  progress?: Progress;
  waitAuthCounter?: number;
  enabled?: boolean;
  runId?: string;
};

interface O365GatewayProps {
  gateway?: Office365Gateway;
}

export default function O365Gateway({ gateway }: O365GatewayProps) {
  const { t } = useTranslation("gateway");
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useAuthPopup();
  const [state, setState] = useState<State>(newClearState(gateway));

  let showAddButton = state.step === Step.Idle && !state.flow;

  let showInstalled =
    state.step === "END" ||
    (state.flow &&
      state.step !== Step.Progress &&
      state.step !== Step.SavingChanges);

  useInterval(listenProgress, state.step === Step.Progress ? 3000 : null);

  async function openDialog(flow: o365gateway.Flow, saveAsDisabled: boolean) {
    try {
      const response = await open({ url: "/auth/powershell/authorize" });
      if (response.success) {
        const { code } = response.payload;

        const createdProcess = await apiRequest<{ runId }>(
          "POST",
          `/o365gateway`,
          {
            code,
            flow,
            disable: saveAsDisabled,
          }
        );

        setState(prev => ({
          ...prev,
          step: Step.Progress,
          flow: {
            enabled: !saveAsDisabled,
            type: flow,
          },
          runId: createdProcess.runId,
        }));
      } else {
        if (wasClosedByUser(response) || userRejectsAuth(response)) return;

        enqueueSnackbar(t("authError"), { variant: "error" });
        captureMessage("An error ocurred in the authorization process", {
          extra: { response },
        });
      }
    } catch (error) {
      captureException(error);
      enqueueSnackbar(t("authError"), { variant: "error" });
    }
  }

  async function updateOrganization() {
    try {
      await apiRequest<ResponseOrganization>("PATCH", "/organization", {
        office365Gateway: state.flow,
      });

      setState(prev => ({ ...prev, step: Step.End }));
    } catch (error) {
      captureException(error);
      enqueueSnackbar(t("savedFail"), { variant: "error" });
    }
  }

  async function listenProgress() {
    const progress = await apiRequest<Progress>(
      "GET",
      `/o365gateway/${state.runId}`
    );

    if (!progress) return;

    if (progressEnded(progress)) {
      if (progress.exitCode !== 200) {
        return setState(prev => ({
          ...prev,
          progress,
          step: Step.End,
        }));
      }

      setState(prev => ({
        ...prev,
        progress: { ...progress, message: t("saving") },
        step: Step.SavingChanges,
        flow: prev.flow,
      }));

      return await updateOrganization();
    }

    setState(prev => ({ ...prev, progress }));
  }

  function initConfig() {
    setState(prev => ({ ...prev, step: Step.SelectFlow }));
  }

  function onFlowSelect(flow: o365gateway.Flow, saveAsDisabled: boolean) {
    setState(prev => ({ ...prev, step: Step.Idle }));
    openDialog(flow, saveAsDisabled);
  }

  return (
    <>
      {state.step === "SELECT_FLOW" && (
        <FlowSelectionDialog
          open={state.step === "SELECT_FLOW"}
          onClose={() => setState(newClearState(gateway))}
          onSelect={onFlowSelect}
        />
      )}
      <Box mt={2}>
        {showAddButton && (
          <Button
            color="default"
            disableElevation
            onClick={initConfig}
            variant="contained"
          >
            {t("O365Gateway.addConnector")}
          </Button>
        )}
        {(state.step === Step.Progress ||
          state.step === Step.SavingChanges) && (
          <ProcessState message={state.progress?.message} />
        )}

        {showInstalled && (
          <Box display="flex" gridColumnGap="20px" alignItems="center">
            <InstalledStatus flow={state.flow} />
            <Button variant="outlined" color="default" onClick={initConfig}>
              {t("O365Gateway.configureAgain")}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

function userRejectsAuth(
  response: ResponseSuccess | ResponseFail | PopupClosed
): boolean {
  return "errorCode" in response && response.errorCode === "ACCESS_DENIED";
}

const newClearState = (flow?: Office365Gateway): State => ({
  step: flow?.enabled ? Step.End : Step.Idle,
  enabled: flow?.enabled,
  flow: flow,
});

const isWaitingAuth = (progress: Progress) => progress?.exitCode === 102;
const isWaitingProgress = (progress: Progress) => progress?.exitCode === 206;

const progressEnded = (progress: Progress) =>
  progress.exitCode && !isWaitingAuth(progress) && !isWaitingProgress(progress);
