import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";

const StyledButton = styled(Button)`
  min-height: 60px;
`;

type Props = {
  wording: string;
  onClick: (event: unknown) => void;
  selected: boolean;
  testid: string;
  disabled?: boolean;
};

export default function ActionButton({
  wording,
  onClick,
  selected,
  testid,
  disabled,
}: Props) {
  return (
    <StyledButton
      color={selected ? "primary" : "default"}
      disableElevation
      fullWidth
      onClick={onClick}
      size="large"
      variant={selected ? "contained" : "outlined"}
      data-testid={testid}
      disabled={disabled}
    >
      <Typography style={{ fontWeight: "500" }}>{wording}</Typography>
    </StyledButton>
  );
}
