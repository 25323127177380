import { MailboxRule } from "@dashboard-v3/api";

export default function getStatusGrouped(
  status: MailboxRule["executionStatus"]
) {
  switch (status) {
    case "SETTING_UP":
    case "FRESH":
    case "QUEUED":
    case "RUNNING":
    case "WAITING_NEXT_EXECUTION":
    case "WAITING_TO_RESUME":
      return "RUNNING";
    default:
      return status;
  }
}

export type StatusGruped = ReturnType<typeof getStatusGrouped>;

export function taskIsEnded(status: MailboxRule["executionStatus"]) {
  if (!status) return false;
  return status === "STOPPED" || status === "FINISHED" || status === "DELETED";
}
