import { useState, MouseEvent } from "react";
import { Button, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import downloadFile from "utils/downloadFile";
import { fetchAccounts } from "utils/api/accounts";
import { Account } from "@dashboard-v3/api";

type FilteredAccount = Omit<
  Account,
  "additionalProperties" | "groups" | "sourceId" | "sourceType"
>;

export default function ExportAccountsBtn() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("accounts");

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportClick = async (type: "json" | "csv") => {
    setLoading(true);
    handleMenuClose();

    try {
      const accounts = await fetchAccounts({ limit: 1000000, type: "all" });
      let formattedAccounts = accounts.map(
        ({ additionalProperties, groups, sourceId, sourceType, ...rest }) =>
          rest
      );

      if (type === "json") {
        downloadFile(formattedAccounts, "accounts", "json");
      } else {
        const csv = formatToCsv(formattedAccounts);
        downloadFile(csv, "accounts", "csv");
      }
    } catch (error) {
      console.error("handleExportClick -> error:", error);
      enqueueSnackbar(t("error.fetchAccounts"), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        color="primary"
        disabled={loading}
        disableElevation
        onClick={handleMenuClick}
        style={{ marginRight: "12px" }}
        variant="contained"
      >
        {t("buttons.exportAccounts")}
        {loading && (
          <CircularProgress
            color="inherit"
            size="1rem"
            style={{ marginLeft: "10px" }}
          />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleExportClick("json")}>
          {t("exportAccounts.toJson")}
        </MenuItem>
        <MenuItem onClick={() => handleExportClick("csv")}>
          {t("exportAccounts.toCsv")}
        </MenuItem>
      </Menu>
    </>
  );
}

const csvHeaders = [
  "email",
  "aliases",
  "tags",
  "storageBox",
  "storageDrive",
  "storageDropbox",
  "storageOnedrive",
  "type",
  "createdAt",
  "updatedAt",
].join(",");

function formatToCsv(accounts: FilteredAccount[]): string {
  const rows = accounts.map(account => {
    const row = [];
    row.push(account.email);
    row.push(`"${account.aliases.join(",")}"`);
    row.push(`"${account.tags.join(",")}"`);
    row.push(account.storage?.box || "");
    row.push(account.storage?.drive || "");
    row.push(account.storage?.dropbox || "");
    row.push(account.storage?.onedrive || "");
    row.push(account.type);
    row.push(account.createdAt);
    row.push(account.updatedAt);

    return row.join(",");
  });
  const csvBody = rows.join("\n");

  return `${csvHeaders}\n${csvBody}`;
}
