import * as Sentry from "@sentry/react";
import { getUser } from "utils/authentication";
import {
  AuthResponse,
  AuthResponseFail,
  AuthResponseSuccess,
} from "pages/CloudStorageAuthResponse";
import request from "utils/apiRequestWithErrorCode";

let authWindow: Window | null;
let checkIfClosed;

export default function useAuthPopup() {
  async function openAuthPopup(
    params: Params
  ): Promise<ResponseSuccess | ResponseFail | PopupClosed> {
    if (authWindow && !authWindow.closed) return null;

    // eslint-disable-next-line no-restricted-globals
    const currentUrl = location.origin;

    let windowSize = params.windowSize ?? { width: 450, height: 620 };

    try {
      return new Promise(async resolve => {
        // eslint-disable-next-line no-use-before-define
        authWindow = window.open("/loading", "", popupConfig(windowSize));

        try {
          const authUrl = await requestAnAuthUrl(addRedirectUrl(params.url));
          authWindow.location.replace(authUrl);
        } catch (error) {
          authWindow?.close();
          authWindow = null;
          return resolve({
            success: false,
            errorCode: "FAIL_FETCHING_URL",
          });
        }

        checkIfClosed = setInterval(() => {
          if (authWindow?.closed) {
            clearInterval(checkIfClosed);
            authWindow = null;
            checkIfClosed = null;
            resolve({
              success: false,
              errorCode: "CLOSE_BY_USER",
            } as PopupClosed);
          }
        }, 1000);

        function onMessage(event: MessageEvent) {
          // Check component CloudStorageAuthResponse
          if (fromMxHero(currentUrl, event)) {
            const response = event.data as AuthResponse;
            window.removeEventListener("message", onMessage, false);
            if (authWindow?.close) authWindow.close();
            authWindow = null;

            if (response.success) {
              return resolve({
                success: true,
                payload: (response as AuthResponseSuccess).response,
              });
            } else {
              const failure = response as AuthResponseFail;
              const { errorCode, errorDetails } = failure;

              console.error("Error details: ", {
                errorCode,
                errorDetails,
              });

              Sentry.captureMessage("Auth popoup error", {
                level: "warning",
                user: getUser(),
                extra: {
                  url: params.url,
                  errorDetails,
                  errorCode,
                },
              });

              return resolve({
                success: false,
                errorCode,
                errorDetails,
              } as ResponseFail);
            }
          }
        }

        window.addEventListener("message", onMessage, false);
      });
    } catch (error) {
      Sentry.captureException(error, {
        extra: { url: params.url },
      });
      throw error;
    }
  }

  return { open: (params: Params) => openAuthPopup(params) };
}

export function wasClosedByUser(
  response: ResponseSuccess | ResponseFail | PopupClosed
): response is PopupClosed {
  return response.success === false && response.errorCode === "CLOSE_BY_USER";
}

type Params = {
  url: string;
  windowSize?: { width: number; height: number };
};

export type ResponseSuccess = {
  success: true;
  payload: Record<string, string>;
};

export type ResponseFail = {
  success: false;
  errorCode?: string;
  errorDetails?: string;
};

export type PopupClosed = {
  success: false;
  errorCode: "CLOSE_BY_USER";
};

function fromMxHero(currentUrl: string, event: MessageEvent) {
  return currentUrl.startsWith(event.origin) && event.data.source === "mxhero";
}

function popupConfig({ width, height }) {
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  return `width=${width},height=${height},status=false,titlebar=false,toolbar=false,menubar=false,top=${top},left=${left}`;
}

function addRedirectUrl(url: string) {
  const redirectUrl = `${window.location.origin}/storage-auth-response`;
  const connector = url.includes("?") ? "&" : "?";
  return `${url}${connector}redirect_url=${redirectUrl}`;
}

async function requestAnAuthUrl(url: string) {
  const response = await request<{ url: string }>("GET", url);
  return response.url;
}
