import styled from "styled-components";
import { grey, red } from "@material-ui/core/colors";

export const FormatErrorItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  box-shadow: none;
  padding: 0px 16px;
  background: ${grey[100]};

  & span {
    line-height: 0;
  }
`;

export const FileItemSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  & a {
    cursor: pointer;
    margin-left: 3px;
  }
`;

export const FormatErrorMessage = styled.span`
  color: ${red[500]};
  margin-left: 8px;
  font-weight: 400;
  & a {
    cursor: pointer;
    margin-left: 3px;
  }
`;

export const ExampleTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 12px;
  background: white;
  margin: 0 auto;

  table,
  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.54);
  }

  th,
  td {
    padding: 5px 10px;
  }
`;

export const ExampleCell = styled.th`
  font-weight: 500;
`;
