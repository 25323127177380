import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import EditDomainBtn from "./EditDomainBtn";
import { StringProperty, BooleanProperty } from "./ItemProperty";
import SpfProperty from "./SpfProperty";
import { Domain } from "@dashboard-v3/api";
import ConfirmDelete from "components/ConfirmDelete";
import apiRequest from "utils/apiRequestWithErrorCode";
import { Skeleton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { mutate } from "swr";

export type Params = { domain: Domain };

export default function DomainListItem({ domain }: Params) {
  const {
    domain: name,
    type,
    relayServer,
    server,
    aliases,
    rcptLookup,
    mxLookup,
  } = domain;
  const [deleting, setDeleting] = useState(false);
  const { t } = useTranslation("orgAndDomains");
  const { enqueueSnackbar } = useSnackbar();

  const typeLabel = typePresent => {
    const typeName = typeLabels[typePresent];
    if (!typeName) return "";
    return `${t("list.synchronizedOn")} ${typeName}`;
  };

  async function disconnectDomain() {
    try {
      setDeleting(true);
      await apiRequest("DELETE", `/domains/${name}`);
      mutate("/manual-domains");
      mutate("/domains");
    } catch (error) {
      Sentry.captureException(error, { extra: { domainName: name } });
      enqueueSnackbar(t("error.removeDomain"), { variant: "error" });
      setDeleting(false);
    }
  }

  if (deleting)
    return (
      <Skeleton
        height={85}
        style={{ transform: "none", margin: "8px 0" }}
        component="li"
      />
    );

  return (
    <ListItem disableGutters>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          data-testid="domains__list-item"
          expandIcon={<ExpandMoreIcon />}
        >
          <ListItemText
            primary={
              <>
                <Box marginY="5px">
                  <Typography display="inline" style={{ fontWeight: "500" }}>
                    @{name}{" "}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    style={{ marginLeft: "5px" }}
                    variant="body2"
                  >
                    {typeLabel(type)}
                  </Typography>
                </Box>
                <Box
                  marginBottom="5px"
                  className="MuiTypography-body2 MuiTypography-colorTextSecondary"
                >
                  <SpfProperty domainName={name} />
                </Box>
              </>
            }
          />

          <ListItemSecondaryAction style={{ marginRight: "35px" }}>
            <Box display="flex">
              <Box pr={2}>
                <ConfirmDelete
                  size="small"
                  color="secondary"
                  disableElevation
                  label={t("manualDomains.remove")}
                  message={t("removeDomainWarning")}
                  onAccept={disconnectDomain}
                />
              </Box>
              <EditDomainBtn domain={domain} />
            </Box>
          </ListItemSecondaryAction>
        </AccordionSummary>
        <StyledAccordionDetails style={{ display: "block" }}>
          <StringProperty value={server} label={t("list.server")} />
          <StringProperty value={relayServer} label={t("list.relayServer")} />

          <Grid container spacing={5}>
            <Grid item>
              <BooleanProperty
                label={t("list.rcptLookup")}
                value={rcptLookup}
              />
            </Grid>

            <Grid item>
              <BooleanProperty label={t("list.mxLookup")} value={mxLookup} />
            </Grid>
          </Grid>
          <StringProperty
            value={aliases?.join(", ")}
            label={t("list.aliases")}
          />
        </StyledAccordionDetails>
      </Accordion>
    </ListItem>
  );
}

const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  padding-bottom: 0;
  padding-top: 0;
`;

const typeLabels = {
  GAPPS: "Google™ Apps",
  OFFICE365: "Office365™",
  ON_PREMISE: "Premise",
};
