import { Dispatch, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import produce from "immer";
import apiRequest, {
  getErrorCode,
  getErrorDescription,
} from "utils/apiRequestWithErrorCode";
import { Rule } from "../types";
import { State } from "./context/types";
import { Action } from "./context/action";
import { RuleUrlParams } from ".";
import {
  isWebhookFolderError,
  isWebhookForbiddenError,
} from "./Steps/Target/StorageWebhook/utils";
import useOrganization from "utils/useOrganization";
import { isWebhookProcessingUpdate, typePath } from "./context/helpers";
import { Button } from "@material-ui/core";
import { WebhookCheckResponse } from "@dashboard-v3/api";
import * as Sentry from "@sentry/react";
import { getUser } from "utils/authentication";

export default function useSaveRule(state: State, dispatch: Dispatch<Action>) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { action }: RuleUrlParams = useParams();
  const history = useHistory();
  const { updateOrganization } = useOrganization();
  const isEdit = Boolean(state.rule.id) && action === "edit";
  const isCopy = Boolean(state.rule.id) && action === "copy";

  const handleWebhookFolderError = (error: unknown) => {
    const details = getErrorDescription(error) as WebhookCheckResponse;
    const webhookStatus: State["webhookStatus"] = { isValid: false };

    if ("foldersInUse" in details) {
      webhookStatus.foldersInUse = details.foldersInUse;
    }

    if ("missingFolder" in details) {
      webhookStatus.missingFolder = details.missingFolder;
    }

    enqueueSnackbar(t("webhookFoldersError"), { variant: "error" });
    dispatch({
      type: "updateStep",
      payload: { step: "target", changes: { webhookStatus } },
    });
  };

  const handleWebhookForbiddenError = () => {
    enqueueSnackbar(t("webhookForbiddenError"), {
      action: () => (
        <Button
          color="inherit"
          target="_blank"
          href="https://support.mxhero.com/hc/en-us/articles/115002669183-Setting-up-a-BOX-Enterprise-or-Business-account"
        >
          {t("helpLinkLabel")}
        </Button>
      ),
      variant: "error",
    });
  };

  useEffect(() => {
    async function saveRule() {
      try {
        let method: "POST" | "PUT" = "POST";
        let path = "/rules";
        let successMsg = t("createSuccess");

        if (isEdit) {
          method = "PUT";
          path = `/rules/${state.rule.id}`;
          successMsg = t("updateSuccess");
        }

        const body = produce(state.rule, draft => {
          const ruleByPath = typePath(state.type, draft);
          draft.enabled = isCopy ? true : draft.enabled;

          if (isCopy && "mailbox" in ruleByPath) {
            ruleByPath.mailbox.canRestart = true;
          }
        });

        await apiRequest<Rule>(method, path, body);

        if (isWebhookProcessingUpdate(state)) {
          const { currentValue } = state.webhookProcessing;
          await updateOrganization({
            storagesPolicy: { preventReProcessingDragAndDrop: currentValue },
          });
        }

        enqueueSnackbar(successMsg, { variant: "success" });
        history.push("/rules");
      } catch (error) {
        const errorCode = getErrorCode(error) as string;

        if (isWebhookFolderError(state, error)) handleWebhookFolderError(error);
        else if (isWebhookForbiddenError(error)) handleWebhookForbiddenError();
        else {
          Sentry.captureException(error, {
            extra: { rule: state.rule },
            user: getUser(),
          });

          enqueueSnackbar(t(isEdit ? "updateFail" : "createFail"), {
            variant: "error",
          });
        }

        dispatch({
          type: "saveRuleFail",
          payload: { errorCode },
        });
      }
    }

    if (state.asyncStatus === "saving") {
      saveRule();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.asyncStatus]);
}
