/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionHeader from "components/SectionHeader";
import LinearLoader from "components/LinearLoader";
import SettingsForm from "./SettingsForm";
import DisabledNotice from "./DisabledNotice";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";

import { NotificationSettings } from "@dashboard-v3/api";
import { useSnackbar } from "notistack";

export default function MailNotifications() {
  const { t } = useTranslation("notificationSettings");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<NotificationSettings>();

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const settings = await apiRequest<NotificationSettings>(
          "GET",
          "/notifications/settings"
        );
        setSettings(settings);
      } catch (e) {
        const code = getErrorCode(e);
        if (code !== "NOT_FOUND") {
          enqueueSnackbar(t("errors.fetchSettings"), { variant: "error" });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, []);

  if (loading) {
    return (
      <>
        <SectionHeader title={t("title")} />
        <LinearLoader />
      </>
    );
  }

  return (
    <>
      <SectionHeader title={t("title")} />
      {settings && <SettingsForm settings={settings} />}
      {!settings && (
        <DisabledNotice setLoading={setLoading} setSettings={setSettings} />
      )}
    </>
  );
}
