/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { validatePassword } from "@dashboard-v3/shared";
import VisibilityButton from "components/VisibilityButton";
import ValidationHelper from "./ValidationHelper";
import PasswordErrorHelper from "./PasswordErrorHelper";
import { StyledTextField } from "./styled";

import { ValidationKey } from "@dashboard-v3/api/src/schemas/users";

interface PasswordInputProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  triggerValidation?: boolean;
}

export default function PasswordInput({
  value,
  onChange,
  triggerValidation,
}: PasswordInputProps) {
  const { t } = useTranslation();
  const [state, setState] = useState<PasswordState>(initState);

  useEffect(() => {
    if (!triggerValidation) return;
    const [isValid, errors] = validatePassword(value);
    if (!isValid) {
      setState(prev => ({ ...prev, errors: errors as ValidationKey[] }));
    }
  }, [triggerValidation]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setState(prev => ({ ...prev, errors: [] }));
    return onChange(e);
  };

  const onBlur = e => {
    const { value } = e.target;
    const [isValid, errors] = validatePassword(value);

    if (!isValid) {
      setState(prev => ({ ...prev, errors: errors as ValidationKey[] }));
    }
  };

  const handleValueVisibility = () => {
    setState(prev => ({
      ...prev,
      showValue: !prev.showValue,
      type: !prev.showValue ? "text" : "password",
    }));
  };

  const getAdornment = () => ({
    endAdornment: (
      <>
        <ValidationHelper />
        <VisibilityButton
          isVisible={state.showValue}
          onClick={handleValueVisibility}
        />
      </>
    ),
  });

  return (
    <StyledTextField
      variant="outlined"
      fullWidth
      type={state.type}
      name="password"
      label={t("common:password")}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={!!state.errors.length}
      inputProps={{ "data-testid": "user-pass-input" }}
      InputProps={getAdornment()}
      helperText={
        !!state.errors.length && <PasswordErrorHelper errors={state.errors} />
      }
    />
  );
}

type PasswordState = {
  type: "text" | "password";
  showValue: boolean;
  errors: ValidationKey[];
};

const initState = (): PasswordState => ({
  type: "password",
  showValue: false,
  errors: [],
});
