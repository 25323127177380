import { useState } from "react";

type ErrorI18nKey = string;

export type Error = {
  [fieldKey: string]: ErrorI18nKey;
};

type Validator = (value?: unknown) => ErrorI18nKey | null;

type Validators = {
  [key: string]: Validator;
};

const useValidation = (formState: { [keyField: string]: unknown }) => {
  const [errors, setErrors] = useState<Error>({});

  const addError = (key: string, error: ErrorI18nKey) => {
    return setErrors(prev => ({ ...prev, [key]: error }));
  };

  const removeError = (key: string) => {
    return setErrors(prev => {
      const { [key]: _, ...rest } = prev;
      return { ...rest };
    });
  };

  const validate = (name: string, validator: Validator) => {
    const errorMsg = validator(formState[name]);
    const isValid = !errorMsg;
    if (!isValid) {
      addError(name, errorMsg);
    } else {
      removeError(name);
    }
    return isValid;
  };

  const clearErrors = (name?: string) => {
    setErrors(prev => {
      const { [name]: _, ...rest } = prev;
      if (!name) {
        return {};
      }
      return { ...rest };
    });
  };

  const validateAll = (validators: Validators, fields?: string[]) => {
    const fieldsToValidate =
      fields && fields.length ? fields : Object.keys(formState);

    return fieldsToValidate
      .map(field => validate(field, validators[field]))
      .every(val => val);
  };

  return { errors, validate, clearErrors, validateAll, addError };
};

export default useValidation;
