import { StorageProvider, StorageProviderWithAuth } from "@dashboard-v3/api";
import { capitalize } from "@material-ui/core";

const storageProviders: StorageProviderWithAuth[] = [
  "BOX",
  "GOOGLEDRIVE",
  "ONEDRIVE",
  "EGNYTE",
  "DROPBOX",
  "EGNYTE_GOV",
];

export default storageProviders;

export const getProviderLabel = (value: StorageProvider | "ALL") => {
  if (value === "GOOGLEDRIVE") return "Google Drive";
  if (value === "EGNYTE_GOV") return "Egnyte Gov";
  if (value === "MXHERO_STORAGE") return "mxHERO Shares";
  return capitalize(value.toLowerCase());
};
