/* eslint-disable no-use-before-define */
import {
  MailboxSingleAccount,
  StorageAccount,
  StorageSettings,
  VerifyGappsResponse,
} from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";

export async function fetchStorageSettings(): Promise<StorageSettings> {
  return apiRequest("GET", "/storages/settings");
}

export async function fetchStorageAccounts() {
  return apiRequest<StorageAccount[]>("GET", "/storage-accounts");
}

export async function deleteStorageAccount(accountId: string) {
  return apiRequest(
    "DELETE",
    `/storage-accounts/${encodeURIComponent(accountId)}`
  );
}

export async function fetchMailboxAccounts() {
  return apiRequest<MailboxSingleAccount[]>("GET", "/mailbox-accounts");
}

export async function fetchMailboxAccountById(id: string) {
  return apiRequest<MailboxSingleAccount>(
    "GET",
    `/mailbox-accounts/${encodeURIComponent(id)}`
  );
}

export async function deleteMailboxAccount(id: string) {
  return apiRequest<void>(
    "DELETE",
    `/mailbox-accounts/${encodeURIComponent(id)}`
  );
}

type Installed = { valid?: boolean; installed?: boolean };

export async function verifyBox(enterpriseId: string) {
  return apiRequest<Installed>("POST", "/storages/box/verify", {
    enterpriseId,
  });
}

export async function verifyGapps(email: string) {
  return apiRequest<VerifyGappsResponse>("POST", "/storages/gapps/verify", {
    email,
  });
}

export async function updateGapps(customerId: string) {
  return apiRequest<void>("PUT", "/storages/gapps/update", {
    customerId,
  });
}

export async function updateReadableText(
  newValue: boolean
): Promise<StorageSettings> {
  return apiRequest("PATCH", "/storages/settings", {
    readableText: newValue,
  });
}

export async function removeIntegration(
  provider: "gapps" | "onedrive" | "box"
) {
  return apiRequest<StorageSettings>("PATCH", "/storages/settings", {
    ...(provider === "box" && { box: null }),
    ...(provider === "gapps" && { gapps: null }),
    ...(provider === "onedrive" && { office365: null }),
  });
}

export async function updateHeaderAndFooter(
  header: string,
  footer: string
): Promise<StorageSettings> {
  return apiRequest("PATCH", "/storages/settings", {
    attachmentsHeader: header,
    attachmentsFooter: footer,
  });
}

export async function getAuthUrl(
  authorizePath: string,
  user: string | null,
  redirectUrl: string
) {
  const query = new URLSearchParams();
  query.append("redirect_url", redirectUrl);
  if (user) {
    query.append("account", user);
  }

  const url = `${authorizePath}/authorize?${query.toString()}`;

  const response = (await apiRequest("GET", url)) as { url: string };
  return response.url;
}
