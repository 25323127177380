import { useTranslation } from "react-i18next";
import {
  AccordionSummary,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { grey } from "@material-ui/core/colors";
import AccountTags from "pages/Accounts/AccountList/AccountTags";
import StorageProperties from "pages/Accounts/AccountList/StorageProperties";
import {
  AccountAvatar,
  AccountDetails,
  AccountIcon,
  AccountItemContainer,
  AccountPropertyContainer,
  AccountPropertyLabel,
  AccountPropertyValue,
} from "./styled";

import { ImportCSVResult } from "../utils";

interface ItemProps {
  account: Omit<ImportCSVResult["accounts"][number], "storage"> & {
    error?: string;
    storage?: ImportCSVResult["accounts"][number]["storage"];
  };
}

export default function AccountItem({ account }: ItemProps) {
  const { t } = useTranslation("accounts");
  const { email, storage, tags, aliases } = account;
  const error = "error" in account ? account.error : "";

  return (
    <ListItem disableGutters>
      <AccountItemContainer>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccountIcon>
            <AccountAvatar>{email.charAt(0)}</AccountAvatar>
          </AccountIcon>
          <ListItemText
            primary={email}
            secondary={error}
            primaryTypographyProps={{
              variant: "caption",
              style: { fontSize: "14px" },
            }}
            secondaryTypographyProps={{
              variant: "caption",
              color: "error",
              style: { lineHeight: 1 },
            }}
          />
        </AccordionSummary>
        <AccountDetails>
          <AccountPropertyContainer>
            <AccountPropertyLabel>{t("tag_plural")}</AccountPropertyLabel>
            <AccountTags tags={tags} />
          </AccountPropertyContainer>
          <AccountPropertyContainer>
            <AccountPropertyLabel>{t("alias_plural")}</AccountPropertyLabel>
            {aliases.length > 0 ? (
              <AccountPropertyValue>{aliases.join(", ")}</AccountPropertyValue>
            ) : (
              <Typography style={{ color: grey[400] }}>
                {t("list.noAliases")}
              </Typography>
            )}
          </AccountPropertyContainer>
          <AccountPropertyContainer>
            <AccountPropertyLabel>
              {t("list.storageAccounts")}
            </AccountPropertyLabel>
            <StorageProperties providers={storage} />
          </AccountPropertyContainer>
        </AccountDetails>
      </AccountItemContainer>
    </ListItem>
  );
}
