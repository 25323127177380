import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldSkeleton } from "components/SkeletonLoading";
import useVariables from "utils/useVariables";
import { Variable } from "@dashboard-v3/api";

type VariableId = string;

type Params = {
  value: VariableId[];
  onChange: (id: VariableId[]) => void;
  label?: string;
  required?: boolean;
};

export default function VariablesSelector({
  value,
  onChange,
  label,
  required = false,
}: Params) {
  const { t } = useTranslation("components");
  const { loading, userVariables } = useVariables();
  const isLoadingOrEmpty = loading || userVariables?.length === 0;
  const isRequired = required && value.length === 0;

  const handleOnChange = (_, value) => {
    const selectedVariables = value as Variable[];
    onChange(selectedVariables.map(v => v.id));
  };

  if (loading || !userVariables) return <FieldSkeleton />;

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      limitTags={3}
      aria-required={isRequired}
      disabled={isLoadingOrEmpty}
      options={userVariables}
      getOptionLabel={v => v.name}
      getOptionSelected={(opt, selected) => opt.id === selected.id}
      value={value.map(id =>
        userVariables.find(userVariable => userVariable.id === id)
      )}
      onChange={handleOnChange}
      renderOption={({ id, name, description, platformVariable }) => (
        <OptionContainer key={id}>
          <span>
            {platformVariable
              ? t("variableSelector.option.label.default")
              : t("variableSelector.option.label.user")}
          </span>
          {name}
          <span>{description || t("variableSelector.option.defaultDesc")}</span>
        </OptionContainer>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          label={label || t("variableSelector.label")}
          required={isRequired}
        />
      )}
    />
  );
}

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
  }
  span:last-child {
    color: grey;
  }
`;
