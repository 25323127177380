import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type StringPropertyParams = {
  value: string;
  label: string;
};

export const StringProperty = ({ value, label }: StringPropertyParams) => {
  const { t } = useTranslation("orgAndDomains");

  return (
    <ItemPropertyContainer>
      <PropertyLabel>{label}</PropertyLabel>
      <Typography color={`${value ? "textPrimary" : "textSecondary"}`}>
        {value || t("common:notSet")}
      </Typography>
    </ItemPropertyContainer>
  );
};

type BooleanPropertyParams = {
  value: boolean;
  label: string;
};

export const BooleanProperty = ({ value, label }: BooleanPropertyParams) => {
  const { t } = useTranslation("orgAndDomains");

  return (
    <ItemPropertyContainer>
      <PropertyLabel>{label}</PropertyLabel>
      <Typography color="textPrimary">
        {value ? t("common:yes") : t("common:no")}
      </Typography>
    </ItemPropertyContainer>
  );
};

const ItemPropertyContainer = styled.div`
  margin-bottom: 20px;
`;

const PropertyLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.82rem;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
`;
