import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VerificationExplainSection from "./VerificationExplainSection";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ManualDomain } from "@dashboard-v3/api";
import ConfirmDelete from "components/ConfirmDelete";
import useManualDomains from "./useManualDomains";
import StatusIcon from "components/StatusIcon";

type Params = {
  domain: ManualDomain;
};

export default function Item({ domain }: Params) {
  const { verify } = useManualDomains();

  const isVerified = domain.lastStatus === "VERIFIED";

  return (
    <Box marginTop="20px">
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          data-testid="domains__list-item"
          expandIcon={<ExpandMoreIcon />}
        >
          <Box padding="10px" display="flex" alignItems="center" flexGrow={1}>
            <Box flexGrow="1">
              <Box fontWeight="500" marginBottom="6px">
                <Typography display="inline" style={{ fontWeight: "500" }}>
                  @{domain.name}
                </Typography>
              </Box>
              {isVerified ? (
                <VerifiedState domain={domain} />
              ) : (
                <NotVerifiedState domain={domain} />
              )}
            </Box>
            {!domain.connected && (
              <Box justifyContent="flex-end">
                <RemoveBtn domain={domain} />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isVerified && (
            <Box>
              <Box className="MuiTypography-colorTextSecondary">TXT Record</Box>
              <Box>{domain.txtEntry}</Box>
            </Box>
          )}
          {!isVerified && (
            <VerificationExplainSection
              domain={domain}
              onVerify={() => verify(domain)}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function RemoveBtn({ domain }) {
  const { t } = useTranslation("orgAndDomains");
  const { remove } = useManualDomains();

  return (
    <ConfirmDelete
      message={t("common:confirmDeletion", { name: domain.name })}
      label={t("manualDomains.remove")}
      color="secondary"
      disableElevation
      variant="contained"
      onAccept={() => remove(domain)}
    />
  );
}

function VerifiedState({ domain }: { domain: ManualDomain }) {
  const { t } = useTranslation("orgAndDomains");
  return (
    <Box className="MuiTypography-body2 MuiTypography-colorTextSecondary">
      <StatusIcon state="enable" />
      {t("manualDomains.verifiedState")}{" "}
      {domain.connected?.as === "DOMAIN" &&
        t("manualDomains.connectedAsDomain")}
      {domain.connected?.as === "ALIAS" &&
        t("manualDomains.connectedAsAlias", { domain: domain.connected.of })}
    </Box>
  );
}

function NotVerifiedState({ domain }) {
  const { t } = useTranslation("orgAndDomains");

  return (
    <Box display="flex" alignItems="center">
      <Box className="MuiTypography-body2">
        <StatusIcon state="disable" />
        {t("manualDomains.notVerifiedState")}
      </Box>
      <Box ml="10px" className="MuiTypography-colorTextSecondary">
        <Box>
          {t("manualDomains.lastTry", {
            from: dayjs(domain.lastVerification).fromNow(),
          })}
        </Box>
      </Box>
    </Box>
  );
}
