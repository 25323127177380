/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Divider, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Office365Button,
  SignInButton,
  SamlButton,
} from "components/Landing/Buttons";
import LoginGoogleButton from "components/Landing/Buttons/LoginGoogleButton";
import { addUserInSession, getOriginPathInSession } from "utils/authentication";
import { useSnackbar } from "notistack";
import PageLoading from "components/PageLoading";
import { loginWithEmail, loginWithGoogle, loginWithOfficeInApi } from "./api";

export default function LoginSignInOptions({
  handleGoBack,
  userEmail,
  availableOptions,
  onLoginError,
}) {
  const { t } = useTranslation("login");
  const { closeSnackbar } = useSnackbar();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const pathname = getOriginPathInSession() || "/";

  const loginWithPassword = async event => {
    event.preventDefault();
    closeSnackbar();
    const password = event.target.elements.password.value;
    if (!password) return;
    try {
      setIsLoading(true);
      const { user, sessionDuration } = await loginWithEmail(
        userEmail,
        password
      );
      addUserInSession(user, sessionDuration);
      setIsLoading(false);
      history.push(pathname);
    } catch (error) {
      onLoginError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithGoogleTokenId = async tokenId => {
    try {
      setIsLoading(true);
      const { user, sessionDuration } = await loginWithGoogle(tokenId);
      addUserInSession(user, sessionDuration);
      setIsLoading(false);
      history.push(pathname);
    } catch (error) {
      onLoginError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithOffice365 = async () => {
    try {
      setIsLoading(true);
      const { user, sessionDuration } = await loginWithOfficeInApi();
      setIsLoading(false);
      if (user) {
        addUserInSession(user, sessionDuration);
        history.push(pathname);
      }
    } catch (error) {
      onLoginError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isEnabled = type => (availableOptions || []).includes(type);

  if (onlySaml(availableOptions)) {
    loginWithSaml(userEmail);
    return null;
  }

  return (
    <>
      <PageLoading loading={isLoading} />

      <Button
        color="primary"
        data-testid="login__user-email-btn"
        onClick={handleGoBack}
        size="large"
        startIcon={
          <ArrowBackIcon style={{ marginRight: "5px", fontSize: "20px" }} />
        }
      >
        {userEmail}
      </Button>

      {isEnabled("GOOGLE") && (
        <LoginGoogleButton key="GOOGLE" onLogin={loginWithGoogleTokenId} />
      )}

      {isEnabled("OFFICE") && (
        <Office365Button key="OFFICE" onClick={loginWithOffice365}>
          {t("signWithOffice")}
        </Office365Button>
      )}

      {isEnabled("SAML") && (
        <SamlButton onClick={() => loginWithSaml(userEmail)} />
      )}

      {isEnabled("PASSWORD") && (
        <>
          <StyledDivider />
          <StyledForm onSubmit={loginWithPassword}>
            <Typography
              variant="body2"
              align="center"
              style={{ marginBottom: "5px" }}
            >
              {t("signWithMxhero")}
            </Typography>
            <TextField
              fullWidth
              inputProps={{ "data-testid": "login__mxhero-account-input" }}
              placeholder={t("password")}
              required
              size="small"
              style={{ marginBottom: "18px" }}
              type="password"
              variant="outlined"
              name="password"
            />
            <SignInButton />
          </StyledForm>
          <Button
            data-testid="login__forgot-pass-btn"
            size="large"
            onClick={() => history.push("/reset-password")}
          >
            {t("forgotPassword")}
          </Button>
        </>
      )}
    </>
  );
}

function onlySaml(availableOptions) {
  return availableOptions.length === 1 && availableOptions.includes("SAML");
}

function loginWithSaml(email) {
  const returnUrl = encodeURIComponent(
    `${window.location.origin}/saml-response/`
  );
  window.location.replace(
    `${process.env.REACT_APP_API_URL}/auth/saml/${email}?returnUrl=${returnUrl}`
  );
}

const StyledForm = styled.form`
  display: grid;
  width: 100%;
`;

const StyledDivider = styled(Divider)`
  width: 100%;
`;
