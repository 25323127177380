/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem } from "@material-ui/core";
import AddGappsSourceDialog from "./GappsSourceDialog";
import LdapSourceDialog from "./LdapSourceDialog";
import Office365SourceDialog from "./Office365SourceDialog";
import { handleOffice365Auth } from "./OfficeSourceAuth";
import { SourceType } from "../types";

interface AddSourceProps {
  omitOptions: SourceType[];
}

export default function AddSource({ omitOptions }: AddSourceProps) {
  const { t } = useTranslation("accounts");
  const { state: redirectState } = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(null);
  const [isOfficeAuth, setIsOfficeAuth] = useState(false);

  useEffect(() => {
    if (!redirectState) return;
    const { action, type } = redirectState;
    if (action === "ADD" && !omitOptions.includes(type)) {
      handleDialogOpen(type);
      history.replace({ state: null });
    }
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isDisabled = type => (omitOptions || []).includes(type);

  const closeDialog = () => setDialogOpen(null);

  const handleDialogOpen = async sourceType => {
    setDialogOpen(sourceType);
    handleMenuClose();
    if (sourceType === "OFFICE365") {
      const isAuthorized = await handleOffice365Auth();
      setIsOfficeAuth(isAuthorized);
    }
  };

  const handleButtonClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        data-testid="add-source-btn"
        color="primary"
        size="small"
        variant="contained"
        disableElevation
        onClick={handleButtonClick}
      >
        {t("buttons.addSource")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          data-testid="accounts__add-gapps-source"
          onClick={() => handleDialogOpen("GAPPS")}
          disabled={isDisabled("GAPPS")}
        >
          {t("sourceTypes.GAPPS")}
        </MenuItem>

        <MenuItem
          data-testid="accounts__add-office365-source"
          onClick={() => handleDialogOpen("OFFICE365")}
          disabled={isDisabled("OFFICE365")}
        >
          {t("sourceTypes.OFFICE365")}
        </MenuItem>

        <MenuItem
          onClick={() => handleDialogOpen("LDAP")}
          data-testid="accounts__add-ldap-source-btn"
        >
          {t("sourceTypes.LDAP")}
        </MenuItem>
      </Menu>

      {dialogOpen === "GAPPS" && (
        <AddGappsSourceDialog
          isOpen={dialogOpen === "GAPPS"}
          onClose={closeDialog}
        />
      )}

      {dialogOpen === "OFFICE365" && (
        <Office365SourceDialog
          isOpen={dialogOpen === "OFFICE365"}
          onClose={closeDialog}
          isAuthorized={isOfficeAuth}
        />
      )}

      <LdapSourceDialog isOpen={dialogOpen === "LDAP"} onClose={closeDialog} />
    </>
  );
}
