import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AccountSearch from "components/AccountSearch";
import { fetcher } from "utils/apiRequestWithErrorCode";
import { SearchContainer, TooltipHeaderTitle } from "./styled";
import getStatusGrouped, { taskIsEnded } from "../getStatusGrouped";
import {
  MailboxAccountListItem,
  MailboxAccountList,
  Rule,
  MailboxRuleStats,
} from "@dashboard-v3/api";
import useSWR from "swr";
import formatNumber from "utils/formatNumber";
import AccountErrorDetails from "./AccountErrorDetails";
import ErrorDetailsModal from "./ErrorDetailsModal";
import { Skeleton } from "@material-ui/lab";
import refreshInterval from "./refreshInterval";
import LinearLoader from "components/LinearLoader";

const PAGE_LIMIT = 10;

export default function Loader({ ruleId }: { ruleId: string }) {
  return (
    <Suspense fallback={<LinearLoader />}>
      <AccountsTable ruleId={ruleId} />
    </Suspense>
  );
}

function AccountsTable({ ruleId }: { ruleId: string }) {
  const { t } = useTranslation("stats");

  const [searchCriteria, setSearchCriteria] = useState<string>("");

  const { data: stats } = useSWR<MailboxRuleStats>(
    `/rules/${ruleId}/stats`,
    fetcher,
    {
      revalidateOnFocus: false,
      suspense: true,
      refreshInterval: latestData =>
        taskIsEnded(latestData?.executionStatus) ? 0 : refreshInterval,
    }
  );

  const { data: rule } = useSWR<Rule>(`/rules/${ruleId}`, fetcher, {
    revalidateOnFocus: false,
    suspense: true,
  });

  const columns =
    rule?.actionType === "REPLACE_ATTACHMENTS" ||
    rule?.actionType === "MOVE_EMAILS"
      ? 10
      : 9;

  return (
    <>
      <SearchContainer>
        <AccountSearch
          placeholder={t("accounts.accountsTable.searchLabel")}
          onSearch={criteria => setSearchCriteria(criteria)}
          onClear={() => setSearchCriteria("")}
        />
      </SearchContainer>

      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <Header item="email" align="left" />
                <Header item="status" align="left" />
                <Header item="messagesFiltered" withTooltip />
                <Header item="emailsDiscarded" withTooltip />
                <Header item="emailsProcessed" />
                <Header item="emailsUploaded" />
                <Header item="amountEmailsWithErrorFatal" />
                <Header item="amountEmailsToReprocess" />
                {rule?.actionType === "REPLACE_ATTACHMENTS" && (
                  <Header item="emailsProtected" />
                )}
                {rule?.actionType === "MOVE_EMAILS" && (
                  <Header item="emailsMoved" />
                )}
                <Header item="filesUploaded" />
              </TableRow>
            </TableHead>

            <Suspense fallback={<Loading colSpan={columns} />}>
              <TableContent
                actionType={rule?.actionType}
                executionStatus={stats?.executionStatus}
                ruleId={ruleId}
                searchCriteria={searchCriteria}
              />
            </Suspense>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

interface TableProps {
  actionType: Rule["actionType"];
  executionStatus: MailboxRuleStats["executionStatus"];
  ruleId: string;
  searchCriteria?: string;
}
function TableContent({
  actionType,
  executionStatus,
  ruleId,
  searchCriteria,
}: TableProps) {
  const { t } = useTranslation("stats");

  const [showErrorForAccount, setShowErrorForAccount] = useState<string>();

  const { accounts, setPage, accountsTotal, currentPage } =
    useFetchMailboxAccounts(ruleId, executionStatus, searchCriteria);

  const isEmpty = accounts && accounts.length === 0;

  const columns =
    actionType === "REPLACE_ATTACHMENTS" || actionType === "MOVE_EMAILS"
      ? 10
      : 9;

  const getPaginationLabel = context => {
    const { count, page } = context;
    const total = Math.ceil(count / PAGE_LIMIT);
    return t("accounts.accountsTable.pagination.labels", {
      page: page + 1,
      total,
    });
  };

  return (
    <>
      <TableBody>
        {isEmpty && (
          <TableRow>
            <TableCell align="center" colSpan={columns}>
              <Typography>
                {t("rules:mailboxStats.thereAreNoAccountsToList")}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {accounts?.map(item => {
          const { stats } = item;
          const emailsWithErrorFatal = stats?.emailsWithErrorFatal ?? 0;
          const movedEmails =
            (stats?.emailsDeleted ?? 0) + (stats?.emailsMovedToTrash ?? 0);

          return (
            <TableRow key={item.emailAddress} data-testid="user-list__item">
              <TableCell key="email" align="left">
                {item.emailAddress}
              </TableCell>

              <StatusCell item={item} executionStatus={executionStatus} />

              <NumberCell
                key="messagesFiltered"
                number={stats?.messagesFiltered}
              />
              <NumberCell
                key="emailsDiscarded"
                number={stats?.emailsDiscarded}
              />
              <NumberCell
                key="emailsProcessed"
                number={stats?.emailsProcessed}
              />

              <NumberCell key="emailsUploaded" number={stats?.emailsUploaded} />

              {emailsWithErrorFatal > 0 ? (
                <TableCell align="center">
                  <>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setShowErrorForAccount(item.emailAddress)}
                    >
                      <Typography variant="body2">
                        {formatNumber(stats?.emailsWithErrorFatal)}
                      </Typography>
                      <Box ml={1} fontStyle="italic">
                        <Typography variant="caption">
                          {t("stats:accounts.accountsTable.showError")}
                        </Typography>
                      </Box>
                    </Button>
                  </>
                </TableCell>
              ) : (
                <NumberCell
                  key="emailsWithErrorFatal"
                  number={emailsWithErrorFatal}
                />
              )}

              <NumberCell
                key="emailsToReprocess"
                number={stats?.emailsToReprocess}
              />

              {actionType === "REPLACE_ATTACHMENTS" && (
                <NumberCell key="emailsUpdated" number={stats?.emailsUpdated} />
              )}
              {actionType === "MOVE_EMAILS" && (
                <NumberCell key="emailsUpdated" number={movedEmails} />
              )}
              <NumberCell key="filesUploaded" number={stats?.filesUploaded} />
            </TableRow>
          );
        })}
      </TableBody>
      <ErrorDetailsModal
        open={Boolean(showErrorForAccount)}
        ruleId={ruleId}
        account={showErrorForAccount}
        onClose={() => setShowErrorForAccount(null)}
      />
      <TableFooter>
        <TableRow>
          <TablePagination
            page={currentPage}
            count={accountsTotal}
            rowsPerPageOptions={[]}
            rowsPerPage={PAGE_LIMIT}
            labelDisplayedRows={getPaginationLabel}
            onPageChange={(_, page) => setPage(page)}
            colSpan={columns}
          />
        </TableRow>
      </TableFooter>
    </>
  );
}

function StatusCell({
  executionStatus,
  item,
}: {
  item: MailboxAccountList["accounts"][number];
  executionStatus: MailboxRuleStats["executionStatus"];
}) {
  const { t } = useTranslation(["stats", "rules"]);

  return (
    <TableCell key="status" align="left">
      {item.error ? (
        <AccountErrorDetails
          status={t(
            `rules:mailboxStats.accountStatus.${status(
              item.executionStatus,
              executionStatus
            )}`
          )}
          error={item.error}
        />
      ) : (
        t(
          `rules:mailboxStats.accountStatus.${status(
            item.executionStatus,
            executionStatus
          )}`
        )
      )}
    </TableCell>
  );
}

function NumberCell({
  number,
  align = "center",
}: {
  key: string;
  number?: number;
  align?: TableCellProps["align"];
}) {
  return (
    <TableCell align={align}>
      <Typography variant="body2">{formatNumber(number ?? 0)}</Typography>
    </TableCell>
  );
}

function Header({
  item,
  align = "center",
  withTooltip = false,
}: {
  item: string;
  align?: TableCellProps["align"];
  withTooltip?: boolean;
}) {
  const { t } = useTranslation(["stats", "rules"]);

  if (withTooltip)
    return (
      <TableCell key={item} align={align}>
        <Tooltip title={t(`rules:mailboxStats.headerTooltip.${item}`)}>
          <TooltipHeaderTitle color="primary">
            {t(`accounts.accountsTable.header.${item}`)}
          </TooltipHeaderTitle>
        </Tooltip>
      </TableCell>
    );

  return (
    <TableCell key={item} align={align}>
      {t(`accounts.accountsTable.header.${item}`)}
    </TableCell>
  );
}

function Loading({ colSpan }: { colSpan: number }) {
  return (
    <TableBody>
      {[...Array(PAGE_LIMIT).keys()].map(id => (
        <TableRow key={`loading_${id}`}>
          <TableCell align="center" colSpan={colSpan}>
            <Skeleton variant="rect" height={20} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

function useFetchMailboxAccounts(
  ruleId: string,
  executionStatus: MailboxRuleStats["executionStatus"],
  searchCriteria?: string
) {
  const [page, setPage] = useState<number>(0);

  const query = new URLSearchParams();
  query.append("limit", PAGE_LIMIT.toString());
  query.append("page", page.toString());

  if (searchCriteria) {
    query.append("search", searchCriteria);
  }

  const { data } = useSWR<MailboxAccountList>(
    `/rules/${ruleId}/stats/accounts?${query.toString()}`,
    fetcher,
    {
      revalidateOnFocus: false,
      suspense: true,
      refreshInterval: () =>
        taskIsEnded(executionStatus) ? 0 : refreshInterval,
    }
  );

  return {
    accounts: data?.accounts,
    setPage,
    currentPage: data?.currentPage || 0,
    accountsTotal: data?.accountsTotal || 0,
  };
}

function status(
  accountStatus: MailboxAccountListItem["executionStatus"],
  ruleStatus: MailboxRuleStats["executionStatus"]
) {
  const ruleGroupedStatus = getStatusGrouped(ruleStatus);

  if (ruleGroupedStatus === "STOPPED") {
    if (accountStatus === "FINISHED_WITH_ERROR") return accountStatus;
    return ruleGroupedStatus;
  }

  if (ruleGroupedStatus === "FINISHED") return "FINISHED";

  if (ruleGroupedStatus === "ACCOUNTS_WITH_ERRORS") return "RUNNING_WITH_ERROR";

  return accountStatus;
}
