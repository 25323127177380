import { Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

export function AddItemButton({ onClick, children, disabled }) {
  return (
    <Box ml={2} flexGrow={2} textAlign="right">
      <Button
        color="primary"
        disableElevation
        size="small"
        startIcon={<AddIcon />}
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </Box>
  );
}
