import { Typography } from "@material-ui/core";
import { OptionGroup, OptionTitle } from "components/Styled";
import styled from "styled-components";

export const StyledTitle = styled(OptionTitle)`
  margin-bottom: 8px;
`;

export const PredicateTitle = styled(Typography)`
  align-items: start;
  border-bottom: 1px solid #d6dbe0;
  display: grid;
  font-weight: 500;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
`;

export const DefaultDefinitionGroup = styled(OptionGroup)`
  border-top: 1px solid #d6dbe0;
  margin-top: 1rem;
  padding-top: 1rem;
`;
