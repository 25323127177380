/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel, Switch } from "@material-ui/core";

const SwitchFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const DescriptionContainer = styled.span`
  font-size: 14px;
  color: grey;
  width: 80%;
`;

const SwitchField = ({ label, description, ...switchProps }) => {
  return (
    <SwitchFieldContainer>
      <FormControlLabel control={<Switch {...switchProps} />} label={label} />
      <DescriptionContainer>{description}</DescriptionContainer>
    </SwitchFieldContainer>
  );
};

SwitchField.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SwitchField;
