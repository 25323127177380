import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { ExampleCell, ExampleTable } from "./styled";

function Example() {
  const { t } = useTranslation("accounts");

  return (
    <ExampleTable>
      <thead>
        <tr>
          <ExampleCell>{t("email")}</ExampleCell>
          <ExampleCell>
            {t("alias_plural")}
            <Box fontSize=".8rem" fontWeight="300">
              ({t("bulk.spaceSeparated")})
            </Box>
          </ExampleCell>
          <ExampleCell>
            {t("buttons.storageAccount", { value: "Box" })}
          </ExampleCell>
          <ExampleCell>
            {t("buttons.storageAccount", { value: "OneDrive" })}
          </ExampleCell>
          <ExampleCell>
            {t("buttons.storageAccount", { value: "Google Drive" })}
          </ExampleCell>
          <ExampleCell>
            {t("buttons.storageAccount", { value: "Dropbox" })}
          </ExampleCell>
          <ExampleCell>
            {t("tag_plural")}{" "}
            <Box fontSize=".8rem" fontWeight="300">
              ({t("bulk.spaceSeparated")})
            </Box>
          </ExampleCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>john@test.com</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
      </tbody>
    </ExampleTable>
  );
}

export default React.memo(Example);
