import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import SectionHeader from "components/SectionHeader";
import apiRequest from "utils/apiRequestWithErrorCode";
import { ShowMoreBtn } from "components/List";
import downloadJson from "utils/downloadFile";
import ResultList from "./ResultList";
import FilterPanel from "./FiltersPanel";
import { EventItem, Filter } from "@dashboard-v3/api";

const limitByRequest = 10;

export default function UserActivity() {
  const { t } = useTranslation("userActivity");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [activityList, setActivityList] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchActivity(criteria) {
    try {
      const events = await apiRequest<EventItem[]>(
        "POST",
        "/events/search",
        criteria
      );

      setHasMore(events.length === limitByRequest);
      return events;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("error.fetchActivity"), {
        variant: "error",
      });
      return null;
    }
  }

  async function loadMore() {
    setIsLoadingMore(true);
    const criteria = {
      ...searchCriteria,
      offset: searchCriteria.offset + limitByRequest,
    };
    setSearchCriteria(criteria);
    try {
      const items = await fetchActivity(criteria);
      setActivityList(previous => [...previous, ...items]);
    } finally {
      setIsLoadingMore(false);
    }
  }

  async function search(criteria?: Filter) {
    setLoading(true);
    const criteriaAndLimit: Filter = {
      ...(criteria || { actions: [] }),
      offset: 0,
      limit: limitByRequest,
    };

    setSearchCriteria(criteriaAndLimit);
    try {
      const items = await fetchActivity(criteriaAndLimit);
      setActivityList(items);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <SectionHeader
        title="User Activity"
        sideBtn={
          <Button
            color="primary"
            data-testid="user-activity__download-btn"
            disableElevation
            disabled={
              !activityList || (activityList && activityList.length === 0)
            }
            variant="contained"
            style={{ marginLeft: "10px" }}
            onClick={() => downloadJson(activityList, "activityList")}
          >
            {t("common:download")}
          </Button>
        }
      >
        <Typography>{t("sectionExplanation")}</Typography>
      </SectionHeader>
      <FilterPanel onSearch={search} />
      <ResultList activityList={activityList} loading={loading} />
      {hasMore && (
        <Box marginTop="15px">
          <ShowMoreBtn loading={isLoadingMore} onClick={loadMore} />
        </Box>
      )}
    </>
  );
}
