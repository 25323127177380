import { useTranslation } from "react-i18next";
import { Box, List, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import DomainListItem from "./DomainListItem";
import ConnectDomainBtn from "./ConnectDomainBtn";
import useManualDomains from "../ManualDomains/useManualDomains";
import useSWR from "swr";
import { anyfreeManualDomain } from "./freeManualDomains";
import { getDomainsList } from "../api";

export default function DomainList() {
  const { t } = useTranslation("orgAndDomains");
  const { data: manualDomains } = useManualDomains();
  const { data: domains } = useSWR("/domains", getDomainsList, {
    revalidateOnFocus: false,
    suspense: true,
  });

  if (domains?.length === 0) {
    return (
      <StyledPaper data-testid="domains__emptyList">
        <div>
          <Typography>{t("emptyDomainList")}</Typography>
        </div>
      </StyledPaper>
    );
  }

  return (
    <StyledList disablePadding data-testid="domains__list">
      {anyfreeManualDomain(manualDomains) && (
        <Box mb="10px" component="li">
          <ConnectDomainBtn domains={domains} />
        </Box>
      )}
      {domains?.map(item => (
        <DomainListItem
          key={`${item.domain}-${item.updatedAt}`}
          domain={item}
        />
      ))}
    </StyledList>
  );
}

const StyledPaper = styled(Paper)`
  min-height: 100px;
  align-self: start;
  align-items: center;
  display: grid;
  padding: 16px;
  text-align: center;
`;

const StyledList = styled(List)`
  li:first-child {
    padding-top: 0;
  }
  li:last-child {
    padding-bottom: 0;
  }
`;
