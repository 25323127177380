import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import AddGappsSourceDialog from "../Buttons/GappsSourceDialog";
import EditLdapSourceDialog from "../Buttons/LdapSourceDialog";
import Office365SourceDialog from "../Buttons/Office365SourceDialog";

import { Source } from "@dashboard-v3/api";

type Params = {
  source: Source;
};

export default function EditSource({ source }: Params) {
  const { t } = useTranslation("accounts");
  const [dialogOpen, setDialogOpen] = useState(false);

  const type = source.configuration.type;

  const closeDialog = () => setDialogOpen(false);

  return (
    <>
      <Button
        data-testid="accounts__edit-source-btn"
        color="primary"
        size="medium"
        variant="text"
        disableElevation
        onClick={() => setDialogOpen(true)}
      >
        {t("common:edit")}
      </Button>

      {type === "OFFICE365" && dialogOpen && (
        <Office365SourceDialog
          isOpen={dialogOpen}
          onClose={closeDialog}
          isAuthorized={true}
          settings={source.configuration.office365Settings}
          id={source.id}
        />
      )}

      {type === "GAPPS" && dialogOpen && (
        <AddGappsSourceDialog
          isOpen={dialogOpen}
          onClose={closeDialog}
          gappsToEdit={source.configuration.gappsSettings}
          id={source.id}
        />
      )}

      {type === "LDAP" && (
        <EditLdapSourceDialog
          isOpen={dialogOpen}
          onClose={closeDialog}
          ldapToEdit={source.configuration.ldapSettings}
          id={source.id}
        />
      )}
    </>
  );
}
