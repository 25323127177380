/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Group, ProviderAccounts } from "@dashboard-v3/api";
import useSearch from "./useSearch";
import { fetchGroup } from "utils/api/groups";

type GroupOption = {
  value: string;
  text: string;
};

export interface Params {
  value: ProviderAccounts;
  onChange: (groups: string[]) => void;
  disabled: boolean;
}

const initGroup = () => ({ value: "", text: "" });

function Groups({ value, onChange, disabled }: Params) {
  const { t } = useTranslation("rules");
  const [savedOption, setSavedOption] = useState<GroupOption>(initGroup());
  const [error, setError] = useState<string>();
  const search = useSearch<Group[], GroupOption>({
    url: "/groups",
    searchParam: "partialEmailOrName",
    mapper: groups => groups.map(g => ({ value: g.id, text: g.name })),
    error: t("selectAccounts.errors.fetchGroups"),
  });

  useEffect(() => {
    const fetchSavedOptions = async () => {
      const [groupId] = value.entities;
      try {
        const group = await fetchGroup(groupId);
        setSavedOption({ value: group.id, text: group.name });
      } catch (err) {
        onChange([]);
      }
    };

    if (value.entities.length) {
      fetchSavedOptions();
    }
  }, []);

  useEffect(() => {
    if (!value.entities.length) {
      setSavedOption(initGroup());
      search.resetSearch();
    }
  }, [value.entities]);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = async e => {
    const { value } = e.target;
    const searchValue = (value || "").trim();
    setError("");

    if (!searchValue) {
      search.resetSearch();
    } else {
      await search.getResults(searchValue);
    }
  };

  const handleLabel = (option: GroupOption | string) => {
    if (typeof option === "object") return option.text;
    return option;
  };

  const handleOnChange = (_, selected: GroupOption | string) => {
    if (selected && typeof selected === "object") {
      setSavedOption(selected);
      onChange([selected.value]);
    } else {
      onChange([]);
    }
  };

  const getFilteredResults = () => {
    const { results } = search;
    return results.filter(({ value }) => savedOption.value !== value);
  };

  const handleOnBlur = () => {
    setError(() => {
      if (!value.entities.length) {
        return t("selectAccounts.groups.errors.required");
      }
      return "";
    });
    search.resetSearch();
  };

  return (
    <Box mt={1} mb={1}>
      <Autocomplete
        openOnFocus={false}
        disabled={disabled}
        options={getFilteredResults()}
        value={savedOption}
        getOptionLabel={handleLabel}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        loading={search.loading}
        loadingText={t("selectAccounts.loadingSearch")}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus
            label={t("selectAccounts.groups.label")}
            variant="outlined"
            onChange={handleSearch}
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: search.loading && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}

export default Groups;
