/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getDomainsList } from "pages/OrgAndDomains/api";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import SelectField from "components/Forms/SelectField";
import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

interface Params {
  value: string[];
  onChange: (domains: string[]) => void;
  disabled: boolean;
}

function Domains({ value, onChange, disabled }: Params) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const [domainOptions, setDomainOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchDomains() {
      setLoading(true);
      try {
        const domains = await getDomainsList();
        setDomainOptions(domains.map(({ domain }) => domain));
      } catch (error) {
        enqueueSnackbar(t("selectAccounts.errors.fetchDomains"), {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
    fetchDomains();
  }, []);

  return (
    <Box mt={1} mb={1}>
      <SelectField
        name="domains"
        label={t("selectAccounts.domains.label")}
        options={domainOptions}
        onChange={e => onChange([e.target.value as string])}
        value={value}
        disabled={isDisabled(domainOptions, disabled)}
        renderEndAdornment={() =>
          loading && (
            <InputAdornment position="end" style={{ marginRight: "4%" }}>
              <CircularProgress size={20} />
            </InputAdornment>
          )
        }
        renderOption={(val: string) => (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        )}
      />
    </Box>
  );
}

function isDisabled(domainOptions: string[], disabled: boolean) {
  if (disabled) return true;
  return !Boolean(domainOptions?.length);
}

export default Domains;
