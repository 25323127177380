import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  MenuItem,
  DialogActions,
} from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Domain } from "@dashboard-v3/api";
import useManualDomains from "../ManualDomains/useManualDomains";
import apiRequest from "utils/apiRequestWithErrorCode";
import { mutate } from "swr";
import { freeManualDomains } from "./freeManualDomains";
import ButtonWithLoading from "components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";

type DomainName = string;
type State = {
  domain: DomainName;
  asAlias: boolean;
  aliasFor: DomainName;
  loading: boolean;
};

export default function ConnectDomainBtn({ domains }: { domains: Domain[] }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [state, change] = useState<State>(reset());
  const { data: manualDomains } = useManualDomains();

  const { t } = useTranslation("orgAndDomains");
  const { enqueueSnackbar } = useSnackbar();

  if (!manualDomains) return null;

  function close() {
    setDialogOpen(false);
    change(reset());
  }

  async function connectAsDomain() {
    change(state => ({ ...state, loading: true }));
    const domainName = state.domain;

    try {
      await apiRequest("POST", "/domains", {
        domain: domainName,
      });
    } catch (error) {
      Sentry.captureException(error, { extra: { domainName: domainName } });
      enqueueSnackbar(t("common:errors.fetchError"));
      return;
    }

    mutate("/domains");
    mutate("/manual-domains");
    close();
  }

  async function connectAsAlias() {
    change(state => ({ ...state, loading: true }));

    const domain = domains.find(({ domain }) => domain === state.aliasFor);
    const updates: Domain = {
      ...domain,
      aliases: [...domain.aliases, state.domain],
    };
    try {
      await apiRequest("PUT", `/domains/${state.aliasFor}`, updates);
    } catch (error) {
      Sentry.captureException(error, {
        extra: { alias: state.domain, domain: state.aliasFor },
      });

      enqueueSnackbar(t("common:errors.fetchError"));
      return;
    }

    mutate("/domains");
    mutate("/manual-domains");
    close();
  }

  return (
    <>
      <Button
        color="primary"
        disableElevation
        variant="contained"
        onClick={() => setDialogOpen(true)}
      >
        {t("domainList.add")}
      </Button>
      <Dialog open={dialogOpen} onClose={close}>
        <DialogTitle>{t("connectDialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box mt={2} mb={1}>
              <SelectField
                name="manualOption"
                options={freeManualDomains(manualDomains)}
                renderOption={domain => (
                  <MenuItem key={domain.name} value={domain.name}>
                    {domain.name}
                  </MenuItem>
                )}
                label={t("connectDialog.selectLabel")}
                value={state.domain}
                onChange={event => {
                  change(prev => ({
                    ...prev,
                    domain: event.target.value as string,
                  }));
                }}
              />
            </Box>
            {state.asAlias && (
              <Box mt={2} mb={1}>
                <SelectField
                  name="aliasOf"
                  options={domains}
                  renderOption={domain => (
                    <MenuItem key={domain.domain} value={domain.domain}>
                      {domain.domain}
                    </MenuItem>
                  )}
                  label="As an alias of the domain"
                  value={state.aliasFor}
                  onChange={event => {
                    change(prev => ({
                      ...prev,
                      aliasFor: event.target.value as string,
                    }));
                  }}
                />
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingBottom: "20px" }}>
          {state.domain && !state.asAlias && (
            <>
              <ButtonWithLoading
                color="primary"
                variant="contained"
                disableElevation
                onClick={connectAsDomain}
                loading={state.loading}
              >
                {t("connectDialog.asDomain")}
              </ButtonWithLoading>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => change(prev => ({ ...prev, asAlias: true }))}
              >
                {t("connectDialog.asAlias")}
              </Button>
            </>
          )}
          {state.asAlias && (
            <ButtonWithLoading
              color="primary"
              variant="contained"
              disableElevation
              loading={state.loading}
              onClick={connectAsAlias}
            >
              Save the alias
            </ButtonWithLoading>
          )}
          <Button onClick={close}>{t("common:cancel")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function reset() {
  return {
    domain: null,
    asAlias: false,
    aliasFor: null,
    loading: false,
  } as State;
}
