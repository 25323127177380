import { Box, Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import downloadJson from "utils/downloadFile";
import DetailItem from "./DetailItem";

import { TracingEventDetails } from "@dashboard-v3/api";

interface DetailsProps {
  details: TracingEventDetails[];
  includeAll?: boolean;
}

export default function Details({ details, includeAll }: DetailsProps) {
  const { t } = useTranslation("tracing");

  return (
    <Grid container spacing={2}>
      {details.map(event => (
        <DetailItem key={event.eventId} event={event} includeAll={includeAll} />
      ))}

      <Grid item xs={12}>
        <Box mt={2}>
          <Button
            onClick={() => downloadJson(details, t("eventDetails"))}
            color="primary"
            variant="outlined"
          >
            {t("common:download")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
