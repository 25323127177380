import { Account, StorageProvider } from "@dashboard-v3/api";
import checkStorageAccountsAccess from "./api/checkStorageAccountsAccess";
import { useTranslation } from "react-i18next";

export type ValidateAccessResult = {
  isValid: boolean;
  errors: { [key in keyof Account["storage"]]: string };
};

export default function useStorageAccountValidation() {
  const { t } = useTranslation("rules");

  return async function validateAccess(storageAccounts: Account["storage"]) {
    const result: ValidateAccessResult = { isValid: true, errors: {} };

    for (const key in storageAccounts) {
      if (!storageAccounts[key]) continue;
      const account = [storageAccounts[key]];
      const { isValid, errors } = await checkStorageAccountsAccess(
        toStorageProvider(key),
        account
      );

      if (!isValid) {
        const { reason } = errors[0];
        result.errors[key] = t(`cloudStorage.asUser.error.${reason}`, {
          account: storageAccounts[key],
        });
      }
    }
    result.isValid = !Object.keys(result.errors).length;
    return result;
  };
}

const toStorageProvider = (input: string) => {
  const provider = input === "drive" ? "GOOGLEDRIVE" : input.toUpperCase();
  return provider as StorageProvider;
};
