import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Backdrop,
  CircularProgress,
  LinearProgress,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Items from "./Items";
import * as api from "./utils";
import CreateBtn from "./CreateBtn";
import TitleAndTable from "./TitleAndTable";
import { Users } from "@dashboard-v3/api";
import { getUser } from "utils/authentication";
import {
  getErrorCode,
  getErrorDescription,
} from "utils/apiRequestWithErrorCode";

export default function OrganizationUsers() {
  const { t } = useTranslation("users");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [userList, setUserList] = useState<Users.List>([]);
  const [loadFailed, setLoadFailed] = useState(false);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  function handleError(
    action: "deleteUser" | "patchUser" | "postUser",
    error: unknown
  ) {
    const code = getErrorCode(error);
    const message = getErrorDescription(error);
    if (code === "INVALID_PASSWORD") {
      throw error;
    }
    const errorMessage = t([
      `error.${action}.${code}`,
      `error.${action}.${message}`,
      `error.${action}.SYSTEM_FAILURE`,
    ]);
    console.error(error);
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  }

  async function fetchUsers() {
    setLoading(true);
    try {
      const currentUser = getUser();
      const res = await api.fetchUserList();
      const usersList = res
        .sort((u1, u2) => u2.updatedAt - u1.updatedAt)
        .filter(u => currentUser.id !== u.id);
      setUserList(usersList);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setLoadFailed(true);
    } finally {
      setLoading(false);
    }
  }

  async function deleteUser(userId: string) {
    setOpenBackdrop(true);
    try {
      await api.deleteUser(userId);
      setOpenBackdrop(false);
      fetchUsers();
    } catch (error) {
      handleError("deleteUser", error);
      setOpenBackdrop(false);
    }
  }

  async function updateUser(id: string, changes: Users.Update) {
    try {
      await api.updateUser(id, changes);
      await fetchUsers();
    } catch (error) {
      handleError("patchUser", error);
    }
  }

  async function createUser(userData) {
    try {
      const newUser = await api.createUser(userData);
      setUserList(users => [newUser, ...users]);
    } catch (error) {
      console.error(error);
      handleError("postUser", error);
    }
  }

  if (loading) {
    return (
      <TitleAndTable testId="user-list_loading">
        <TableBody>
          <TableRow>
            <TableCell colSpan={3} style={{ padding: "24px" }}>
              <TaskListProgress />
            </TableCell>
          </TableRow>
        </TableBody>
      </TitleAndTable>
    );
  }

  if (loadFailed) {
    return (
      <TitleAndTable testId="user-list_error">
        <TableBody>
          <TableRow>
            <TableCell colSpan={3} style={{ padding: "15px 16px" }}>
              {t("error.fetchUserList.SYSTEM_FAILURE")}
            </TableCell>
          </TableRow>
        </TableBody>
      </TitleAndTable>
    );
  }

  return (
    <>
      <TitleAndTable
        addBtn={<CreateBtn onCreate={createUser} />}
        testId="user-list"
      >
        <StyledTableBody>
          <Items users={userList} onDelete={deleteUser} onChange={updateUser} />
        </StyledTableBody>
      </TitleAndTable>
      <Backdrop
        data-testid="user-list__backdrop"
        className={classes.backdrop}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

const TaskListProgress = styled(LinearProgress)`
  margin-top: 10px;
`;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const StyledTableBody = styled(TableBody)`
  tr {
    background-color: white;
    :hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }

  tr:first-child td:last-child {
    border-radius: 0 4px 0 0;
  }

  tr:last-child td:first-child {
    border: 0;
    border-radius: 0 0 0 4px;
  }

  tr:last-child td:last-child {
    border: 0;
    border-radius: 0 0 4px 0;
  }
`;
