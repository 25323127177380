import produce from "immer";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VariablesSelector from "components/VariablesSelector";
import { StyledPaper, VerticalLine, OptionGroup } from "components/Styled";
import { PredicateTitle, StyledTitle } from "../Styled";
import { SecurityDefinition } from "@dashboard-v3/api";
import { FormState } from "../types";
import SelectDefaultDef from "./SelectSecurityDef";

interface Props {
  formData: {
    formState: FormState;
    setValues: (values: Partial<FormState>) => void;
  };
  index: number;
  definitions: SecurityDefinition[];
}

enum MatchOption {
  AllMatch = "ALL_MATCH",
  OneMatches = "ONE_MATCHES",
  NoMatches = "NO_MATCHES",
}

export default function Predicates({ formData, index, definitions }: Props) {
  const { t } = useTranslation("securityPolicies");
  const { formState, setValues } = formData;
  const { predicates } = formState;
  const { variableIdList, definitionId } = formState.predicates[index];

  const handleVariableIds = (variableIds: string[]) => {
    const newState = produce(formState, draft => {
      draft.predicates[index].variableIdList = variableIds;
    });

    setValues(newState);
  };

  const removePredicate =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (predicates.length > 1) {
        setValues({ predicates: predicates.filter((_, i) => i !== index) });
      }
    };

  const handleMatchChange = event => {
    const value = event.target.value as MatchOption;
    const newState = produce(formState, draft => {
      if (value === MatchOption.AllMatch) {
        draft.predicates[index].firstMatch = false;
        draft.predicates[index].match = true;
      }

      if (value === MatchOption.NoMatches) {
        draft.predicates[index].firstMatch = false;
        draft.predicates[index].match = false;
      }

      if (value === MatchOption.OneMatches) {
        draft.predicates[index].firstMatch = true;
        draft.predicates[index].match = true;
      }
    });

    setValues(newState);
  };

  const handleDefinitionId = e => {
    const { name, value } = e.target;
    const updated = predicates.map((item, i) => {
      let update = value;
      if (value === "DO_NOT_UPLOAD") update = null;
      return i === index ? { ...item, [name]: update } : item;
    });
    setValues({ predicates: updated });
  };

  const getMatchValue = () => {
    const { match, firstMatch } = predicates[index];
    if (match && !firstMatch) return MatchOption.AllMatch;
    if (match && firstMatch) return MatchOption.OneMatches;
    if (!match && !firstMatch) return MatchOption.NoMatches;
    return MatchOption.AllMatch;
  };

  return (
    <>
      <VerticalLine size="sm" />
      <StyledPaper>
        <PredicateTitle color="textSecondary">
          {t("form.predicates.title", { number: index + 1 })}
          {predicates.length > 1 && (
            <IconButton
              onClick={removePredicate(index)}
              size="small"
              aria-label="delete"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </PredicateTitle>
        <OptionGroup>
          <StyledTitle>{t("form.predicates.variableId.title")}</StyledTitle>
          <VariablesSelector
            label={t("form.predicates.variableId.label")}
            value={variableIdList}
            onChange={handleVariableIds}
            required
          />
          <Box display="flex" mt=".5em" mb="1em">
            <RadioGroup
              row
              aria-label="match-or-not"
              name="match-or-not"
              value={getMatchValue()}
              onChange={handleMatchChange}
            >
              <FormControlLabel
                value={MatchOption.AllMatch}
                control={<Radio color="primary" />}
                label={t("form.allMatch")}
              />
              <FormControlLabel
                value={MatchOption.OneMatches}
                control={<Radio color="primary" />}
                label={t("form.oneMatches")}
              />
              <FormControlLabel
                value={MatchOption.NoMatches}
                control={<Radio color="primary" />}
                label={t("form.noMatches")}
              />
            </RadioGroup>
          </Box>
        </OptionGroup>
        <OptionGroup>
          <StyledTitle>{t("form.predicates.definitionId.title")}</StyledTitle>
          <SelectDefaultDef
            options={definitions}
            value={definitionId}
            name="definitionId"
            onChange={handleDefinitionId}
          />
        </OptionGroup>
      </StyledPaper>
    </>
  );
}
