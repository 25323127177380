import { InputLabel, Typography } from "@material-ui/core";
import { OptionGroup, OptionTitle } from "components/Styled";
import styled from "styled-components";

export const ConditionTitle = styled(Typography)`
  align-items: start;
  border-bottom: 1px solid #d6dbe0;
  display: grid;
  font-weight: 500;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
`;

export const DefaultConditionGroup = styled(OptionGroup)`
  border-top: 1px solid #d6dbe0;
  margin-top: 1rem;
  padding-top: 1rem;
  margin-bottom: 10px;
`;

export const StyledTitle = styled(OptionTitle)`
  margin-bottom: 8px;
`;

export const StyledLabel = styled(InputLabel)`
  font-size: 0.8em;
  line-height: 1em;
  margin-bottom: 4px;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 15px;
`;

export const SelectProviderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
`;
