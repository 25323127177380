import { Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  RegexTestResultDataWrapper,
  RegexTestResultsContainer,
} from "../Styled";

interface TestButtonProps {
  matches: { group: string[]; matchCount: number };
}

export default function TestResults({ matches }: TestButtonProps) {
  const { t } = useTranslation("variables");
  const { matchCount, group } = matches;

  return (
    <>
      <Divider />
      <RegexTestResultsContainer>
        <Typography variant="subtitle2">
          {t("regexTester.result.matchCounter", { count: matchCount + 1 })}
        </Typography>
        <div>
          {group.map((match, i) => (
            <RegexTestResultDataWrapper key={match}>
              <div>
                <Typography variant="caption">
                  {t("regexTester.result.resultCounter", { count: i })}
                </Typography>
                <Typography variant="caption">{match}</Typography>
              </div>
            </RegexTestResultDataWrapper>
          ))}
        </div>
      </RegexTestResultsContainer>
    </>
  );
}
