/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DescriptionBlock from "components/DescriptionBlock";
import { useConditionsCtx } from "pages/Variables/state/ConditionsProvider";

import { DashboardVariable } from "types";

const HelpItemList = styled.ul`
  margin: 5px 0 5px 0;
  padding: 0 0 0 15px;
  overflow-wrap: anywhere;
`;
const HelpItem = styled.li`
  > span:first-of-type {
    font-weight: 500;
  }
`;

type Props = {
  values: DashboardVariable[];
};

export default function ConvertHelper({ values }: Props) {
  const [items, setItems] = useState<DashboardVariable[]>([]);
  const { t } = useTranslation("variables");
  const { selectFromCondition, isLastCondition } = useConditionsCtx();
  const { toValue } = selectFromCondition();
  const cssResets = { marginTop: "0", alignSelf: "normal" };

  useEffect(() => {
    const searchItems = values.filter(({ id }) => toValue.includes(id));
    setItems(searchItems);
  }, [toValue]);

  const getItemDescription = (desc: string) => {
    return Array.isArray(desc) ? desc : t(`platformVariables:${desc}`);
  };

  return (
    !!items.length &&
    isLastCondition() && (
      <DescriptionBlock
        title={t("toValueHelper.title")}
        titleStyles={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "0.85em" }}
        styleResets={cssResets}
        renderDescription={() => (
          <HelpItemList>
            {items.map(({ id, name, description }, i) => (
              <HelpItem key={`${id}-${i}`}>
                <span>{t(`platformVariables:${name}`)}: </span>
                <span>{getItemDescription(description)}</span>
              </HelpItem>
            ))}
          </HelpItemList>
        )}
      />
    )
  );
}
