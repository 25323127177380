import React from "react";
import { CssBaseline, StylesProvider, ThemeProvider } from "@material-ui/core";
import PropTypes from "prop-types";
import mxHeroTheme from "./mxHeroTheme";

const MxHeroThemeProvider = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={mxHeroTheme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

MxHeroThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MxHeroThemeProvider;
