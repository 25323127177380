/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Skeleton } from "@material-ui/lab";
import { Button } from "components/Forms/StyledComponents";
import SectionHeader from "components/SectionHeader";
import ComponentList from "components/ComponentList";
import { ListHeaderTitle, ShowMoreBtn } from "components/List";
import SectionTabs from "components/SectionTabs";
import apiRequest from "utils/apiRequestWithErrorCode";
import useQuery from "utils/useQuery";
import { byLastModified } from "utils/sort";
import { isBlank } from "utils/string";
import { ListHeader } from "./Styled";
import Item, { onClick } from "./Item";
import ListSearch from "./Search";
import EmptyListMessage from "./EmptyListMessage";

import { TFunction } from "i18next";
import { Rule } from "@dashboard-v3/api";

const itemsByPage = 10;

export type RuleSearchCriteria = {
  partialText?: string;
  actionType?: Rule["actionType"];
  targetType?: Rule["targetType"];
  enabled?: "true" | "false";
  withCriteria: boolean;
};

export type SearchCriteria = RuleSearchCriteria & {
  offset: number;
  limit: number;
};

export enum ListTab {
  Executing = "EXECUTING",
  All = "ALL",
}

export default function RulesList() {
  const { t } = useTranslation("rules");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const searchParams = useQuery();
  const [loading, setLoading] = useState<"ALL" | "MORE" | "ITEM">("ALL");
  const [loadingItem, setLoadingItem] = useState<string>(null);
  const [currentTab, setCurrentTab] = useState<ListTab>(ListTab.Executing);
  const [ruleList, setRuleList] = useState<Rule[]>([]);
  const [moreRules, setMoreRules] = useState(false);
  const isEmptyList = ruleList?.length === 0;
  const [searchCriteria, setSearchCriteria] =
    useState<SearchCriteria>(initCriteria);

  useEffect(() => {
    async function initialFetch() {
      setLoading("ALL");
      const params = criteriaFromUrl(searchParams);

      if (!!params.enabled) setCurrentTab(ListTab.All);

      await fetchList({
        ...params,
        limit: itemsByPage,
        offset: 0,
        ...(!params.enabled && { enabled: "true" }),
      });
      setLoading(null);
    }

    initialFetch();
  }, []);

  async function fetchList(criteria?: SearchCriteria) {
    const newCriteria = {
      limit: itemsByPage,
      offset: 0,
      ...searchCriteria,
      ...(criteria || {}),
    };

    try {
      const newRules = await apiRequest<Rule[]>(
        "GET",
        `/rules?${toQueryParams(newCriteria)}`
      );
      setSearchCriteria(newCriteria);
      setRuleList(rules => [...rules, ...newRules].sort(byLastModified));
      setMoreRules(newRules.length === itemsByPage);
    } catch (error) {
      enqueueSnackbar(t("list.errors.fetchError"), { variant: "error" });
    }
  }

  const deleteRule = async (rule: Rule) => {
    const { id, name } = rule;
    setLoading("ITEM");
    setLoadingItem(id);
    try {
      await apiRequest<void>("DELETE", `/rules/${id}`);
      const newList = ruleList.filter(rule => rule.id !== id);
      setRuleList(newList);
    } catch (err) {
      enqueueSnackbar(t("list.errors.deleteError", { name }), {
        variant: "error",
      });
    } finally {
      setLoading(null);
      setLoadingItem(null);
    }
  };

  const enableDisableRule = async (rule: Rule, enable: boolean) => {
    const { id, name } = rule;
    setLoading("ITEM");
    setLoadingItem(id);

    try {
      const updatedRule = await apiRequest<Rule>("PATCH", `/rules/${id}`, {
        enabled: enable,
        name,
      });

      const newList = ruleList.map(rule =>
        rule.id === updatedRule.id ? updatedRule : rule
      );
      setRuleList(() => {
        if (currentTab === ListTab.Executing) {
          return newList.filter(r => r.enabled);
        }
        return newList;
      });
    } catch (err: unknown) {
      const { description } = err as { description: string };
      const defaultMsg = t("list.errors.updateError", { name });
      const errorMsg = t(`list.errors.${description}`, defaultMsg);

      enqueueSnackbar(errorMsg, {
        variant: "error",
      });
    } finally {
      setLoading(null);
      setLoadingItem(null);
    }
  };

  async function fetchMoreRules() {
    setLoading("MORE");
    await fetchList({
      limit: itemsByPage,
      offset: searchCriteria.offset + itemsByPage,
      withCriteria: false,
    });
    setLoading(null);
  }

  async function search(criteria: RuleSearchCriteria) {
    const searchParams = { ...criteria };
    const urlParams = { ...criteria };
    setMoreRules(false);
    setLoading("ALL");
    setRuleList([]);

    if (currentTab === ListTab.Executing) {
      searchParams.enabled = "true";
    }

    if (urlParams.enabled && currentTab === ListTab.Executing) {
      delete urlParams.enabled;
    }

    history.replace({
      pathname: "/rules/list",
      search: criteriaToUrlSearchParams(urlParams),
    });

    await fetchList({
      ...searchParams,
      limit: itemsByPage,
      offset: 0,
      withCriteria: true,
    });
    setLoading(null);
  }

  const onItemClick: onClick = (action, rule) => {
    if (action === "edit") {
      history.push(`/rules/${rule.id}/edit`);
    }
    if (action === "copy") {
      history.push(`/rules/${rule.id}/copy`);
    }
    if (action === "delete") {
      deleteRule(rule);
    }
    if (action === "enable" || action === "disable") {
      enableDisableRule(rule, action === "enable");
    }
  };

  const onTabChange = async (value: ListTab) => {
    if (currentTab === value) return;
    setMoreRules(false);
    setCurrentTab(value);

    setLoading("ALL");
    try {
      const params = {
        limit: itemsByPage,
        offset: 0,
        ...(value === ListTab.Executing && { enabled: "true" }),
      };
      const rules = await apiRequest<Rule[]>(
        "GET",
        `/rules?${toQueryParams(params)}`
      );

      history.replace({ pathname: "/rules/list" });
      setRuleList(rules.sort(byLastModified));
      setMoreRules(rules.length === itemsByPage);
      setSearchCriteria(initCriteria);
    } catch (error) {
      enqueueSnackbar(t("list.errors.fetchError"), { variant: "error" });
    } finally {
      setLoading(null);
    }
  };

  return (
    <>
      <SectionHeader
        title={t("rulesList")}
        sideBtn={
          <Button
            wording={t("list.createBtn")}
            onClick={() => history.push("/rules/create")}
          />
        }
      />
      <SectionTabs
        tabs={getSectionTabs(t)}
        showTab={currentTab}
        onClick={val => onTabChange(val as ListTab)}
      />
      <ListSearch
        onChange={search}
        init={criteriaFromUrl(searchParams)}
        showStatusFilter={currentTab === ListTab.All}
      />
      {loading !== "ALL" && !isEmptyList && (
        <ListHeader>
          <ListHeaderTitle>{t("list.header.state")}</ListHeaderTitle>
          <ListHeaderTitle>{t("list.header.name")}</ListHeaderTitle>
          <ListHeaderTitle>{t("list.header.action")}</ListHeaderTitle>
          <ListHeaderTitle>{t("list.header.target")}</ListHeaderTitle>
          <ListHeaderTitle>{t("list.header.modified")}</ListHeaderTitle>
          <ListHeaderTitle> </ListHeaderTitle>
        </ListHeader>
      )}
      <ComponentList
        list={ruleList}
        loading={loading === "ALL"}
        emptyMsg={
          <EmptyListMessage
            rules={ruleList}
            currentTab={currentTab}
            criteria={searchCriteria}
            onTabChange={onTabChange}
          />
        }
        renderItems={(rule: Rule) =>
          loading === "ITEM" && loadingItem === rule.id ? (
            <Skeleton
              key={rule.id}
              height={80}
              data-testid="rules__loadingItem"
            />
          ) : (
            <Item key={rule.id} rule={rule} onClick={onItemClick} />
          )
        }
      />
      {moreRules && (
        <ShowMoreBtn loading={loading === "MORE"} onClick={fetchMoreRules} />
      )}
    </>
  );
}

function getSectionTabs(t: TFunction) {
  return Object.values(ListTab).map(tabId => ({
    id: tabId,
    label: t(`list.tabs.${tabId}`),
  }));
}

function toQueryParams(urlParams: Record<string, unknown>) {
  const params = new URLSearchParams();
  Object.keys(urlParams || {}).forEach(key => {
    if (urlParams[key]) params.append(key, urlParams[key].toString());
  });
  return params.toString();
}

function criteriaToUrlSearchParams(criteria: RuleSearchCriteria) {
  const params = Object.keys(criteria).reduce((params, key) => {
    if (!isBlank(criteria[key])) {
      params[key] = criteria[key];
    }
    return params;
  }, {});

  return `?${new URLSearchParams(params)}`;
}

const initCriteria = {
  offset: 0,
  limit: itemsByPage,
  withCriteria: false,
};

const validQueryParams = [
  "partialText",
  "actionType",
  "targetType",
  "enabled",
] as Array<keyof RuleSearchCriteria>;

function criteriaFromUrl(searchParams: URLSearchParams): RuleSearchCriteria {
  return validQueryParams.reduce((filter, key) => {
    if (searchParams.has(key)) {
      filter[key] = searchParams.get(key);
      filter["withCriteria"] = true;
    }
    return filter;
  }, {}) as RuleSearchCriteria;
}
