import { verifyIfCanSave } from "../verifyIfCanSave";

import { Draft } from "immer";
import { State, Filter } from "../../types";
import validateTargetFilter from "./target";

export default function validateFilter(filter: Filter, draft: Draft<State>) {
  switch (filter) {
    case "targetFilter":
      validateTargetFilter(draft);
      verifyIfCanSave(draft);
      break;
  }
}
