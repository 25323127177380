import { useRef } from "react";
import { searchGroups, searchAccounts } from "./searchInApi";
import { useTranslation } from "react-i18next";
import isEmail from "utils/isEmail";
import isDomain from "utils/isDomain";
import fetchDomainsAndTags from "../fetchDomainsAndTags";

export default function useSearchOptions() {
  const { t } = useTranslation("fromToSelector");
  const domainsAndTags = useRef(null);

  const organizationOption = {
    type: "ORGANIZATION",
    label: t(`option.ORGANIZATION`),
    managed: true,
  };

  const anyoneOptions = ["ANYONE", "ANYONE_ELSE"].map(type => ({
    type: type as "ANYONE" | "ANYONE_ELSE",
    label: t(`option.${type}`),
    managed: false,
  }));

  async function getDomainsAndTags() {
    if (!domainsAndTags.current) {
      domainsAndTags.current = await fetchDomainsAndTags();
    }
    return domainsAndTags.current;
  }

  async function searchDomainsAndTags(textToSearch: string) {
    const domainsAndTags = await getDomainsAndTags();
    return domainsAndTags.filter(item =>
      item.label.toUpperCase().startsWith(textToSearch)
    );
  }

  async function search(text: string) {
    const textToSearch = text?.trim()?.toUpperCase();
    if (!textToSearch) return [];

    const [domainsAndTagsFound, accountsFound, groupsFound] = await Promise.all(
      [
        searchDomainsAndTags(textToSearch),
        searchAccounts(textToSearch),
        searchGroups(textToSearch),
      ]
    );

    const options = [...domainsAndTagsFound, ...accountsFound, ...groupsFound];

    if (options.length === 0) {
      if (isEmail(text)) {
        options.push({ type: "ACCOUNT", managed: false, label: text });
      }

      if (isDomain(text)) {
        options.push({ type: "DOMAIN", managed: false, label: text });
      }
    }

    options.push(organizationOption);

    return [...options, ...anyoneOptions];
  }

  return search;
}
