import apiRequest from "./apiRequestWithErrorCode";
import { removeUserFromSession } from "./authentication";

export const logout = async () => {
  try {
    await apiRequest("POST", "/auth/logout");
  } catch (error) {
    console.error("api logout fail, continue anyway", error);
  }
  removeUserFromSession();
};

export default logout;
