import { useState, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { Alert } from "@material-ui/lab";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ButtonWithLoading from "components/ButtonWithLoading";
import WithIconHelper from "components/WithIconHelper";
import { createSource, updateSource } from "../api";
import { useSourcesContext } from "../sourcesProvider";

const StyledTextField = styled(TextField)`
  margin-bottom: 16px;
`;

// eslint-disable-next-line react/prop-types
const Item = ({ children }) => <Box mb="16px">{children}</Box>;

const defaultLdapData = {
  label: "",
  hostAddress: "",
  portNumber: 389,
  secure: false,
  userDn: "",
  password: "",
  usersFilter:
    "(&(|(objectclass=user)(objectclass=person)(objectclass=inetOrgPerson) (objectclass=organizationalPerson))(!(objectclass=computer))(|(mail=*)(userPrincipalName=*)))",
  groupsFilter:
    "(|(objectclass=group)(objectclass=groupofnames)(objectclass=groupofuniquenames))",
  base: "",
  additionalMailAttributes: [
    "mailAlternateAddress",
    "zimbraMailAlias",
    "proxyAddresses",
  ],
};

/**
 *
 * @param {object} param
 * @param {boolean} param.isOpen
 * @param {function} param.onClose
 * @param {(import("../api").LdapSettings | null)} param.ldapToEdit
 * @param {(string|null)} param.id
 * @returns
 */
const LdapSourceDialog = ({ isOpen, onClose, ldapToEdit, id }) => {
  const { t, actions } = useSourcesContext();

  const form = useRef();
  const [ldap, setLdap] = useState(ldapToEdit || defaultLdapData);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const handleDialogClose = () => {
    onClose();
    setErrorMsg(null);
  };

  const isEditing = () => !!id;

  const updateField = event => {
    const { name, value } = event.target;
    setLdap(old => ({ ...old, [name]: value }));
  };

  const updateSwitch = event => {
    const { name, checked } = event.target;
    setLdap(old => ({
      ...old,
      [name]: checked,
    }));
  };

  const updateLdap = async () => {
    try {
      const source = await updateSource("LDAP", id, ldap);
      setIsSaving(false);
      actions.updateSource(source);
      onClose();
    } catch (error) {
      if (error.statusCode === 504) {
        setErrorMsg(t("error.updateSourceTimeout"));
      } else {
        setErrorMsg(t("error.createSource"));
      }
      setIsSaving(false);
    }
  };

  const createLdap = async () => {
    try {
      const source = await createSource("LDAP", ldap);
      setIsSaving(false);
      actions.addSource(source);
      onClose();
    } catch (error) {
      console.error(`Error Code ${error.code} with status ${error.status}`);

      if (error.code === "TIMEOUT" || error.status === 504) {
        setErrorMsg(t("error.createSourceTimeout"));
      } else {
        setErrorMsg(t("error.createSource"));
      }
      setIsSaving(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMsg(null);
    setIsSaving(true);
    return isEditing() ? updateLdap() : createLdap();
  };

  const title = () => {
    const key = isEditing()
      ? "sources.editTypeOfSource"
      : "sources.addTypeOfSource";
    return t(key, { type: "LDAP" });
  };

  return (
    <Dialog
      open={isOpen}
      onClick={e => e.stopPropagation()}
      onClose={handleDialogClose}
      scroll="paper"
    >
      <DialogTitle>
        {title()}
        {errorMsg && (
          <Alert elevation={0} variant="filled" severity="error">
            {errorMsg}
          </Alert>
        )}
      </DialogTitle>

      <DialogContent dividers>
        <form onSubmit={handleSubmit} ref={form}>
          <StyledTextField
            fullWidth
            inputProps={{
              "data-testid": "accounts__source-ldap-label",
            }}
            label="Label"
            name="label"
            onChange={updateField}
            variant="outlined"
            value={ldap.label}
          />
          <Item>
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "accounts__source-address",
              }}
              label={t("ldap.hostAddress")}
              onChange={updateField}
              required
              variant="outlined"
              name="hostAddress"
              value={ldap.hostAddress}
            />
          </Item>

          <StyledTextField
            fullWidth
            variant="outlined"
            name="portNumber"
            value={ldap.portNumber}
            inputProps={{
              "data-testid": "accounts__source-port",
            }}
            label={t("ldap.portNumber")}
            required
            type="number"
            onChange={updateField}
          />
          <FormControlLabel
            control={
              <Switch
                inputProps={{
                  "data-testid": `accounts__use-ssl-switch_${
                    ldap.secure ? "on" : "off"
                  }`,
                }}
                data-testid="accounts__use-ssl-switch"
                checked={ldap.secure}
                onChange={updateSwitch}
                color="primary"
                name="secure"
              />
            }
            style={{ marginBottom: "16px" }}
            label={t("ldap.secure")}
          />
          <StyledTextField
            fullWidth
            name="userDn"
            inputProps={{
              "data-testid": "accounts__source-user",
            }}
            label={t("ldap.userDn")}
            required
            onChange={updateField}
            value={ldap.userDn}
            variant="outlined"
          />
          <StyledTextField
            fullWidth
            name="password"
            onChange={updateField}
            inputProps={{
              "data-testid": "accounts__source-password",
            }}
            label={t("ldap.password")}
            required
            type="password"
            value={ldap.password}
            variant="outlined"
          />
          <StyledTextField
            fullWidth
            inputProps={{
              "data-testid": "accounts__source-user-filter",
            }}
            name="usersFilter"
            onChange={updateField}
            label={t("ldap.usersFilter")}
            multiline
            value={ldap.usersFilter}
            variant="outlined"
          />
          <StyledTextField
            fullWidth
            inputProps={{
              "data-testid": "accounts__source-group-filter",
            }}
            name="groupsFilter"
            onChange={updateField}
            label={t("ldap.groupsFilter")}
            variant="outlined"
            multiline
            value={ldap.groupsFilter}
          />
          <StyledTextField
            fullWidth
            inputProps={{
              "data-testid": "accounts__source-base-filter",
            }}
            name="base"
            onChange={updateField}
            label={t("ldap.base")}
            variant="outlined"
            value={ldap.base}
          />
          <WithIconHelper
            position="after"
            text={t("common:chipInputHelper")}
            icon={<InfoOutlinedIcon fontSize="small" color="primary" />}
            buttonStyles={{ marginLeft: "10px" }}
          >
            <ChipInput
              data-testid="accounts__source-mail-attributes"
              color="primary"
              fullWidth
              label={t("ldap.additionalMailAttributes")}
              onChange={values => {
                setLdap(old => ({ ...old, additionalMailAttributes: values }));
              }}
              variant="outlined"
              name="additionalMailAttributes"
              defaultValue={ldap.additionalMailAttributes}
            />
          </WithIconHelper>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="accounts__source-cancel-edit"
          onClick={handleDialogClose}
        >
          {t("common:cancel")}
        </Button>
        <ButtonWithLoading
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => form.current.requestSubmit()}
          loading={isSaving}
          data-testid="accounts__save-source-btn"
        >
          {t("common:save")}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
};

LdapSourceDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ldapToEdit: PropTypes.object,
  id: PropTypes.string,
};

LdapSourceDialog.defaultProps = {
  ldapToEdit: null,
  id: null,
};

export default LdapSourceDialog;
