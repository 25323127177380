import { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@material-ui/core/colors";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import nextId from "react-id-generator";
import { Source } from "@dashboard-v3/api";

function tabInitiallySelected(warnings) {
  if (warnings?.accounts?.length) return "accounts";
  if (warnings?.groups?.length) return "groups";
  if (warnings?.domains?.length) return "domains";
  return "accounts";
}

interface Props {
  open: boolean;
  onClose: () => void;
  warnings: Source["synchronization"]["warnings"];
}

const WarningListDialog = ({ open, onClose, warnings }: Props) => {
  const [tabSelected, setTabSelected] = useState(
    tabInitiallySelected(warnings)
  );
  const { t } = useTranslation("accounts");

  if (!warnings) return null;

  return (
    <Dialog maxWidth="md" fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        {t("lastSynchronization")}
        <Typography>{t("warningMessages")}</Typography>
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TabContext value={tabSelected}>
          <TabList
            onChange={(_, newValue) => {
              setTabSelected(newValue);
            }}
            value="accounts"
            indicatorColor="primary"
            textColor="primary"
            style={{ borderBottom: "1px solid #d6dbe0" }}
          >
            <Tab label={t("account_plural")} value="accounts" />
            <Tab label={t("group_plural")} value="groups" />
            <Tab label={t("domains")} value="domains" />
          </TabList>
          <TabPanel value="accounts" style={{ paddingTop: 0 }}>
            <List>
              {warnings.accounts.map(item => (
                <ListItem
                  key={nextId()}
                  style={{ borderBottom: `1px solid ${grey[300]}` }}
                >
                  <ListItemText
                    primary={item.details}
                    secondary={t(`warnings.${item.code}`)}
                  />
                </ListItem>
              ))}
              {warnings.accounts.length === 0 && (
                <div>{t("withoutWarnings")}</div>
              )}
            </List>
          </TabPanel>
          <TabPanel value="groups" style={{ paddingTop: 0 }}>
            <List>
              {warnings.groups.map(item => (
                <ListItem
                  key={nextId()}
                  style={{ borderBottom: `1px solid ${grey[300]}` }}
                >
                  <ListItemText
                    primary={item.details}
                    secondary={t(`warnings.${item.code}`)}
                  />
                </ListItem>
              ))}
              {warnings.groups.length === 0 && (
                <Box my={2}>{t("withoutWarnings")}</Box>
              )}
            </List>
          </TabPanel>
          <TabPanel value="domains" style={{ paddingTop: 0 }}>
            <List>
              {warnings.domains.map(item => (
                <ListItem
                  key={nextId()}
                  style={{ borderBottom: `1px solid ${grey[300]}` }}
                >
                  <ListItemText
                    primary={item.details}
                    secondary={t(`warnings.${item.code}`)}
                  />
                </ListItem>
              ))}
              {warnings.domains.length === 0 && (
                <Box my={2}>{t("withoutWarnings")}</Box>
              )}
            </List>
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
};

export default WarningListDialog;
