import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
import { green } from "@material-ui/core/colors";
import { StyledTypography } from "../../Styled";
import ButtonWithLoading from "components/ButtonWithLoading";

const syncHref =
  "https://support.mxhero.com/hc/en-us/articles/115002669183-Setting-up-a-BOX-Enterprise-or-Business-account";

const iconStyles: CSSProperties = {
  fontSize: "17px",
  position: "relative",
  top: "3px",
};

export function SyncStatus({ installed }) {
  const { t } = useTranslation("storage");

  if (installed) {
    return (
      <StyledTypography
        display="inline"
        style={{ color: green[400] }}
        data-testid="storage__synced-label"
      >
        <CheckIcon style={iconStyles} />
        {t("synced")}
      </StyledTypography>
    );
  }

  if (installed === false) {
    return (
      <StyledTypography
        display="inline"
        color="error"
        data-testid="storage__not-synced-label"
      >
        <ErrorOutlineIcon style={iconStyles} /> {t("notInstalled")}
        <Link
          color="textSecondary"
          style={{ marginLeft: "15px" }}
          data-testid="storage__enable-link"
          href={syncHref}
          target="_blank"
        >
          {t("enable")}
        </Link>
      </StyledTypography>
    );
  }

  return null;
}

export function NotInstalled({ loading, onClick }) {
  const { t } = useTranslation("storage");

  return (
    <>
      <StyledTypography
        style={{ marginLeft: 0 }}
        display="inline"
        color="error"
        data-testid="storage__not-synced-label"
      >
        <ErrorOutlineIcon style={iconStyles} /> {t("notInstalled")}
      </StyledTypography>
      <ButtonWithLoading
        variant="text"
        color="default"
        disableElevation
        loading={loading}
        style={{ marginLeft: "15px" }}
        data-testid="storage__enable-link"
        onClick={onClick}
      >
        {t("install")}
      </ButtonWithLoading>
    </>
  );
}
