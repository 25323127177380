import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ParentType } from ".";
import useOrganization from "utils/useOrganization";
import { Suspense } from "react";
import { Skeleton } from "@material-ui/lab";
import { StorageProvider } from "@dashboard-v3/api";

type Params = {
  value: ParentType;
  onChange: (value: ParentType) => void;
  storageProvider?: StorageProvider;
};

export default function Loader(params: Params) {
  return (
    <Suspense
      fallback={
        <Skeleton width="30rem">
          <Radio />
        </Skeleton>
      }
    >
      <SelectParentType {...params} />
    </Suspense>
  );
}

function SelectParentType({ value, onChange, storageProvider }: Params) {
  const { t } = useTranslation("components");
  const { organization } = useOrganization({ suspense: true });

  const sharepointIsEnabled = Boolean(
    organization?.integrations?.office365?.oneDrive
  );

  const showSharepointOption =
    (!storageProvider || storageProvider === "ONEDRIVE") && sharepointIsEnabled;

  function emit(event) {
    const newType = event.target.value as ParentType;
    onChange(newType);
  }

  return (
    <RadioGroup onChange={emit} row value={value || ParentType.Root}>
      <FormControlLabel
        value={ParentType.Root}
        control={<Radio color="primary" />}
        label={t("storageFilepath.root")}
      />
      <FormControlLabel
        value={ParentType.Variable}
        control={<Radio color="primary" />}
        label={t("storageFilepath.variable")}
      />
      {showSharepointOption && (
        <FormControlLabel
          value={ParentType.SharepointSite}
          control={<Radio color="primary" />}
          label={t("storageFilepath.sharepointSite")}
        />
      )}
      <FormControlLabel
        value={ParentType.Storage}
        control={<Radio color="primary" />}
        label={t("storageFilepath.pickFromStorage")}
      />
    </RadioGroup>
  );
}
