import produce from "immer";
import { Option } from "pages/Rules/FromToSelector/types";
import { State } from "../types";

export type CheckUnmanagedFlow = {
  type: "checkUnmanagedFlow";
  payload: { to: Option; from: Option };
};

export const checkUnmanagedFlow = (
  state: State,
  payload: CheckUnmanagedFlow["payload"]
) => {
  return produce(state, draft => {
    if (isUnmanagedFlow(payload)) {
      draft.checks.emailFlow.error = "UNMANAGED_FLOW";
      draft.steps.target.valid = false;
    } else {
      draft.checks.emailFlow.error = null;
    }
    // Set for use in canTrackEmails
    draft.checks.emailFlow.fromOption = payload.from;
  });
};

const isUnmanagedFlow = (paylaod: CheckUnmanagedFlow["payload"]) => {
  if (paylaod.from && paylaod.to) {
    const { from, to } = paylaod;
    return !from.managed && !to.managed;
  }
  return false;
};
