const units = ["Bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

export default function formatBytes(bytes: number) {
  if (!bytes || bytes < 0) return `0 ${units[0]}`;

  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const number = (bytes / Math.pow(k, i)).toFixed();

  return `${number.toLocaleString()} ${units[i]}`;
}
