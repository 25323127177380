import { ChangeEvent, useEffect, useState } from "react";
import {
  Badge,
  Fab,
  Grid,
  InputBase,
  Paper,
  Tooltip,
  FormControl,
  FormControlLabel,
  Collapse,
  Typography,
  Button,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import styled from "styled-components";
import { RuleSearchCriteria } from "./index";
import produce from "immer";

interface Props {
  onChange: (criteria: RuleSearchCriteria) => void;
  init: RuleSearchCriteria;
  showStatusFilter?: boolean;
}

export default function ListSearch({
  onChange,
  init,
  showStatusFilter,
}: Props) {
  const { t } = useTranslation("rules");
  const [inputFocus, setInputFocus] = useState(false);
  const [filterPanel, setFilterPanel] = useState<boolean>(false);
  const [filters, setFilters] = useState<RuleSearchCriteria>(init);
  const { actionType, targetType, enabled } = filters;
  const filtersCount =
    (actionType ? 1 : 0) + (targetType ? 1 : 0) + (enabled ? 1 : 0);

  useEffect(() => {
    setFilters(init);
  }, [init]);

  const handleFilterChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: keyof RuleSearchCriteria
  ) => {
    const { value } = e.target;

    setFilters(
      produce(draft => {
        if (type === "enabled") {
          draft.enabled = value as "true" | "false";
        }
        if (type === "actionType") {
          draft[type] = value as RuleSearchCriteria["actionType"];
        }
        if (type === "targetType") {
          draft[type] = value as RuleSearchCriteria["targetType"];
        }
        if (type === "partialText") {
          draft[type] = value;
        }
      })
    );
  };

  const handleSubmitSearch = () => {
    onChange(filters);
  };

  const handleOpenFilters = () => {
    setFilterPanel(prevState => !prevState);
  };

  return (
    <>
      <Grid
        container
        alignItems="stretch"
        spacing={2}
        style={{ marginBottom: "20px", marginTop: "20px" }}
      >
        <GridAnim item xs={inputFocus ? 6 : 4}>
          <SearchContainer elevation={0}>
            <SearchIcon color="disabled" fontSize="small" />
            <TextField
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
              onChange={e => handleFilterChange(e, "partialText")}
              fullWidth
              value={filters.partialText}
              placeholder={t("search.searchARuleOrSelectAFilter")}
            />
          </SearchContainer>
        </GridAnim>
        <Grid item>
          <Button
            size="large"
            color="primary"
            disableElevation
            variant="contained"
            onClick={handleSubmitSearch}
          >
            {t("search.action")}
          </Button>
        </Grid>
        <Grid item>
          <Badge
            color="primary"
            badgeContent={filtersCount}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            overlap="circular"
          >
            <Tooltip title={t("search.showFilters")} placement="right">
              <Fab
                size="small"
                onClick={handleOpenFilters}
                style={{ boxShadow: "none" }}
              >
                <FilterListIcon fontSize="small" />
              </Fab>
            </Tooltip>
          </Badge>
        </Grid>
      </Grid>
      <Collapse in={filterPanel}>
        <FiltersContainer elevation={0}>
          <FormControl fullWidth>
            <FilterLabel color="textSecondary" variant="body2">
              {t("action")}:
            </FilterLabel>
            <RadioGroup
              value={filters.actionType || ""}
              onChange={e => handleFilterChange(e, "actionType")}
              row
            >
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                label={t("search.all")}
                value=""
              />
              {actionTypes.map(action => (
                <FormControlLabel
                  control={<Radio color="primary" size="small" />}
                  label={t(`actions.${action}.title`)}
                  key={action}
                  value={action}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <FilterLabel color="textSecondary" variant="body2">
              {t("targetStep.label")}:
            </FilterLabel>
            <RadioGroup
              value={filters.targetType || ""}
              onChange={e => handleFilterChange(e, "targetType")}
              row
            >
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                label={t("search.all")}
                value=""
              />
              {targetTypes.map(target => (
                <FormControlLabel
                  control={<Radio size="small" color="primary" />}
                  label={t(target)}
                  key={target}
                  value={target}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {showStatusFilter && (
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <FilterLabel color="textSecondary" variant="body2">
                {t("search.state")}:
              </FilterLabel>
              <RadioGroup
                value={filters.enabled || ""}
                onChange={e => handleFilterChange(e, "enabled")}
                row
              >
                <FormControlLabel
                  value=""
                  control={<Radio color="primary" size="small" />}
                  label={t("search.both")}
                />
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" size="small" />}
                  label={t("list.enable")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" size="small" />}
                  label={t("list.disable")}
                />
              </RadioGroup>
            </FormControl>
          )}
        </FiltersContainer>
      </Collapse>
    </>
  );
}

const targetTypes: Array<RuleSearchCriteria["targetType"]> = [
  "ARCHIVING_ADDRESS",
  "EMAIL_FLOW",
  "MAIL_BOX",
];

const actionTypes: Array<RuleSearchCriteria["actionType"]> = [
  "BLOCK_ATTACHMENTS",
  "COPY_EMAILS",
  "DRAG_AND_DROP",
  "MOVE_EMAILS",
  "PROTECT_EMAILS",
  "REPLACE_ATTACHMENTS",
];

const FiltersContainer = styled(Paper)`
  padding: 15px 20px 10px;
  margin-bottom: 25px;
`;

const FilterLabel = styled(Typography)`
  margin-bottom: -4px;
`;

const TextField = styled(InputBase)`
  margin-left: 10px;
`;

const GridAnim = styled(Grid)`
  transition: all 0.5s ease-in-out;
`;

const SearchContainer = styled(Paper)`
  padding: 6px 15px;
  display: flex;
  align-items: center;
`;
