import { Box, Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useManualDomains from "pages/OrgAndDomains/ManualDomains/useManualDomains";
import { useState } from "react";
import { freeManualDomains } from "../freeManualDomains";
import { ManualDomain } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";

type Props = {
  value: Array<string>;
  onChange: (newAliases: string[]) => void;
};

export default function SelectAliases({ value, onChange: emitChange }: Props) {
  const { t } = useTranslation("orgAndDomains");
  const { data } = useManualDomains();
  const [options, setOptions] = useState(
    freeManualDomains(data || []).map(domain => domain.name)
  );

  function removeDisabled(option: string) {
    return !find(option, data);
  }

  if (!data?.length) return <></>;

  return (
    <Box mb={2}>
      <Autocomplete
        multiple
        id="selectAliases"
        value={value}
        noOptionsText={t("selectAliases.noOptions")}
        options={options}
        disableClearable
        getOptionDisabled={option => !find(option, data)}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              label={getLabel(option)}
              {...getTagProps({ index })}
              disabled={removeDisabled(option)}
            />
          ));
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={t("selectAliases.aliasesLabel")}
            variant="outlined"
          />
        )}
        onChange={(_, newValue) => {
          const { removed, added } = arrayDiff(value, newValue);

          setOptions(options => {
            const updated = options.reduce((updated, domainName) => {
              if (!added.includes(domainName)) {
                updated.push(domainName);
              }
              return updated;
            }, []);

            return updated.concat(removed);
          });

          emitChange(newValue);
        }}
      />
    </Box>
  );
}

const getLabel = (option: string | ManualDomain) => {
  if (typeof option === "string") return option;
  return option.name;
};

function find(value: string, list?: ManualDomain[]) {
  return list?.find(domain => domain.name === value);
}

function arrayDiff(oneList: string[], anotherList: string[]) {
  const removed = oneList.filter(alias => !anotherList.includes(alias));

  const added = anotherList.filter(alias => !oneList.includes(alias));

  return { removed, added };
}
