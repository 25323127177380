import { TextField, CircularProgress } from "@material-ui/core";
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Option } from "../types";
import optionLabel from "./optionLabel";
import renderOption from "./renderOption";
import useAutocomplete from "./useAutocomplete";
import sameOption from "./sameOption";

export type AllowedTypes = Array<Option["type"]>;

type Props = {
  label: string;
  onChange: (option: Option) => void;
  initialSelection?: Option;
  allowedTypes?: AllowedTypes;
};

export default function DirectionAutocomplete(params: Props) {
  const { t } = useTranslation("fromToSelector");
  const { label, ...props } = params;
  const { changeInputValue, changeSelection, selection, loading, options } =
    useAutocomplete(props);

  const onInputChange = (
    _,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === "input" || reason === "clear") {
      changeInputValue(value);
    }
  };

  const getFilteredOptions = () => {
    const { allowedTypes } = props;
    if (allowedTypes && allowedTypes.length) {
      return options.filter(opt => allowedTypes.includes(opt.type));
    }
    return options;
  };

  return (
    <Autocomplete
      options={getFilteredOptions()}
      getOptionLabel={optionLabel}
      getOptionSelected={sameOption}
      noOptionsText={t("noOption")}
      loading={loading}
      fullWidth
      forcePopupIcon
      value={selection}
      onChange={(_, value) => changeSelection(value)}
      onInputChange={onInputChange}
      renderOption={option => renderOption(option, t)}
      renderInput={params => {
        let labelToShow = label;

        if (selection) {
          const type = selection?.type;
          if (!fixedTypes.includes(type)) {
            labelToShow += ` ${t(`option.${type}`)}`;
          }
        }

        return (
          <TextField
            {...params}
            label={labelToShow}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
}

const fixedTypes = ["ORGANIZATION", "ANYONE", "ANYONE_ELSE"];
