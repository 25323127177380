import { green, red } from "@material-ui/core/colors";
import styled from "styled-components";
import LensIcon from "@material-ui/icons/Lens";

const StatusIcon = styled(LensIcon)<{ state?: "enable" | "disable" }>`
  font-size: 0.7rem;
  margin-right: 6px;
  color: ${props => (props.state === "enable" ? green.A200 : red.A200)};
`;

export default StatusIcon;
