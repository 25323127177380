import { useState } from "react";
import { useTranslation } from "react-i18next";
import SwitchField from "components/Forms/SwitchField";
import PasswordInput from "components/PaswordInput";
import { Error } from "utils/useValidation";
import { StyledTextField } from "./Styled";
import VisibilityButton from "../VisibilityButton";
import PasswordOptionSubtitle from "./PasswordOptionSubtitle";
import { FormFields, isPasswordExclusive } from "./utils";

import { LoginOptionNames, Users } from "@dashboard-v3/api";

interface PasswordSectionProps {
  user: Users.Item;
  errors: Error;
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  fields: FormFields;
  loginOptions: LoginOptionNames[];
  isSubmitting: boolean;
}

type PasswordField = "password" | "confirmPassword";

export default function PasswordSection({
  user,
  errors,
  fields,
  onChange,
  onBlur,
  loginOptions,
  isSubmitting,
}: PasswordSectionProps) {
  const { t } = useTranslation("users");
  const [showValue, setShowValue] = useState(initValueVisibility());
  const changeLabel = user?.isPasswordSet
    ? "passwordOptions.reset.label"
    : "passwordOptions.create.label";

  const handleValueVisibility = (field: PasswordField) => _ => {
    setShowValue(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const getAdornment = (field: PasswordField) => ({
    endAdornment: (
      <VisibilityButton
        isVisible={showValue[field]}
        onClick={handleValueVisibility(field)}
      />
    ),
  });

  if (!user && isPasswordExclusive(loginOptions)) {
    return (
      <>
        <PasswordInput
          value={fields.password}
          onChange={onChange}
          triggerValidation={isSubmitting}
        />
        <StyledTextField
          variant="outlined"
          fullWidth
          name="confirmPassword"
          label={t("confirmPassword")}
          type={showValue.confirmPassword ? "text" : "password"}
          error={!!errors.confirmPassword}
          value={fields.confirmPassword}
          onChange={onChange}
          onBlur={onBlur}
          helperText={errors.confirmPassword}
          InputProps={getAdornment("confirmPassword")}
          inputProps={{
            "data-testid": "user-confirm-pass-input",
          }}
        />
      </>
    );
  }

  return (
    <>
      <SwitchField
        name="enablePassword"
        label={t(changeLabel)}
        value={fields.enablePassword}
        onChange={onChange}
        description={
          <PasswordOptionSubtitle
            user={user}
            isVisible={fields.enablePassword}
          />
        }
      />
      {fields.enablePassword && (
        <>
          <PasswordInput
            value={fields.password}
            onChange={onChange}
            triggerValidation={isSubmitting}
          />
          <StyledTextField
            variant="outlined"
            fullWidth
            name="confirmPassword"
            label={t("confirmPassword")}
            type={showValue.confirmPassword ? "text" : "password"}
            error={!!errors.confirmPassword}
            value={fields.confirmPassword}
            onChange={onChange}
            onBlur={onBlur}
            helperText={errors.confirmPassword}
            InputProps={getAdornment("confirmPassword")}
            inputProps={{
              "data-testid": "user-confirm-pass-input",
            }}
          />
        </>
      )}
    </>
  );
}

const initValueVisibility = () => () => ({
  password: false,
  confirmPassword: false,
});
