import React from "react";
import SectionHeader from "components/SectionHeader";
import { Box, Paper, LinearProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Subscriptions from "./Suscriptions";
import Contacts from "./Contacts";
import useBilling from "./useBilling";
import Invoices from "./Invoices";

const Billing = () => {
  const { t } = useTranslation("billing");

  const { withError, loading, contacts, subscriptions, xeroContact } =
    useBilling();

  if (withError)
    return (
      <Layout>
        <Paper
          style={{ padding: "12px 16px" }}
          data-testid="billing_loading-error"
        >
          <Typography>{t("noInformation")}</Typography>
        </Paper>
      </Layout>
    );

  if (loading)
    return (
      <Layout>
        <Paper style={{ padding: "12px 16px" }} data-testid="billing_loading">
          <LinearProgress />
        </Paper>
      </Layout>
    );

  return (
    <Layout>
      <Contacts contacts={contacts} />
      <Box mt={4}>
        <Subscriptions subscriptions={subscriptions} />
      </Box>
      <Box mt={4}>
        <Invoices xeroContact={xeroContact} />
      </Box>
    </Layout>
  );
};

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  const { t } = useTranslation("billing");
  return (
    <>
      <SectionHeader title={t("pageTitle")} />
      {children}
    </>
  );
};

export default Billing;
