import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export function DeleteRowButton({ onClick, disabled }) {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <DeleteIcon />
    </IconButton>
  );
}
