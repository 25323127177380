import * as Sentry from "@sentry/react";
import { Group } from "@dashboard-v3/api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import ButtonWithLoading from "components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { fetchGroup } from "utils/api/groups";
import apiRequest from "utils/apiRequestWithErrorCode";
import useStorageAccountValidation, {
  ValidateAccessResult,
} from "utils/useStorageAccountValidation";

type AccountErrors = ValidateAccessResult["errors"];

export default function EditButton({ id }) {
  const { t } = useTranslation("accounts");
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [changes, setChanges] = useState<Group["storage"]>();
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<AccountErrors>();

  const validateAccess = useStorageAccountValidation();

  const { data: groupDetails, mutate } = useSWR(
    `/groups/${id}`,
    () => fetchGroup(id),
    {
      suspense: true,
    }
  );

  const changeStorageAccount = e => {
    const { name, value } = e.target;
    setChanges(previous => {
      const updates: Group["storage"] = previous
        ? { ...previous }
        : { ...groupDetails.storage };

      updates[name] = value.trim();
      return updates;
    });
  };

  function firstWithValue(one: string, other: string) {
    if (one || one === "") return one;
    if (other || other === "") return other;
    return "";
  }

  function closeModal() {
    setOpenDialog(false);
    setChanges(null);
    setErrors(null);
  }

  function removeEmptyValues(storage: Group["storage"]) {
    return Object.keys(storage).reduce((cleaned, key) => {
      if (storage[key]) {
        cleaned[key] = storage[key];
      }
      return cleaned;
    }, {} as Group["storage"]);
  }

  async function saveChanges() {
    try {
      setIsSaving(true);
      setErrors(null);

      const toSave = removeEmptyValues(changes);
      const { isValid, errors } = await validateAccess(toSave);

      if (!isValid) {
        setErrors(errors);
        return;
      }

      const updatedGroup = await apiRequest<Group>("PUT", `/groups/${id}`, {
        storage: removeEmptyValues(changes),
      });

      mutate(updatedGroup);
      closeModal();
      return;
    } catch (error) {
      enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
      Sentry.captureException(error);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={event => {
          event.stopPropagation();
          setOpenDialog(true);
        }}
      >
        {t("common:edit")}
      </Button>
      <Dialog
        open={openDialog}
        onClick={e => e.stopPropagation()}
        onClose={closeModal}
      >
        <DialogTitle>
          {t("groups.storageAccounts")}
          <Typography>{groupDetails.name}</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginBottom: "20px" }}
            fullWidth
            label={t("orgStorageAccount.label", { value: "Box" })}
            type="text"
            name="box"
            value={firstWithValue(changes?.box, groupDetails.storage?.box)}
            variant="outlined"
            onChange={changeStorageAccount}
            error={Boolean(errors?.box)}
            helperText={errors?.box}
          />

          <TextField
            style={{ marginBottom: "20px" }}
            fullWidth
            label={t("orgStorageAccount.label", { value: "Dropbox" })}
            type="text"
            name="dropbox"
            value={firstWithValue(
              changes?.dropbox,
              groupDetails.storage?.dropbox
            )}
            variant="outlined"
            onChange={changeStorageAccount}
            error={Boolean(errors?.dropbox)}
            helperText={errors?.dropbox}
          />

          <TextField
            style={{ marginBottom: "20px" }}
            fullWidth
            label={t("orgStorageAccount.label", { value: "OneDrive" })}
            type="text"
            name="onedrive"
            value={firstWithValue(
              changes?.onedrive,
              groupDetails.storage?.onedrive
            )}
            variant="outlined"
            onChange={changeStorageAccount}
            error={Boolean(errors?.onedrive)}
            helperText={errors?.onedrive}
          />

          <TextField
            style={{ marginBottom: "20px" }}
            fullWidth
            label={t("orgStorageAccount.label", { value: "Google Drive" })}
            type="text"
            name="drive"
            value={firstWithValue(changes?.drive, groupDetails.storage?.drive)}
            variant="outlined"
            onChange={changeStorageAccount}
            error={Boolean(errors?.drive)}
            helperText={errors?.drive}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            {t("common:cancel")}
          </Button>
          <ButtonWithLoading
            color="primary"
            disableElevation
            variant="contained"
            loading={isSaving}
            onClick={saveChanges}
          >
            {t("common:save")}
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </>
  );
}
