import { Draft } from "immer";
import { State } from "../../types";
import {
  emailFlowFilter,
  mailboxFilter,
} from "pages/Rules/CreateOrEdit/Filters/helpers";
import isValidFromTo from "./isValidFromTo";
import { getEmailFlow } from "../../helpers";
import { isBlank } from "utils/string";
import { MailboxFilter } from "@dashboard-v3/api";

export default function validate(draft: Draft<State>) {
  const { targetType } = draft.rule;

  if (targetType === "EMAIL_FLOW") {
    const filter = emailFlowFilter(draft.rule);
    const emailFlow = getEmailFlow(draft.rule);

    if (filter?.exclude) {
      const { isValid, errors } = isValidFromTo(filter.exclude, emailFlow);
      draft.filters.targetFilter.valid = isValid;
      draft.filters.targetFilter.errors.exclusions = errors;
    } else {
      draft.filters.targetFilter.valid = true;
      draft.filters.targetFilter.errors.exclusions = {};
    }
  }

  if (targetType === "MAIL_BOX") {
    const filter = mailboxFilter(draft.rule);

    if (filter?.includeFromTo) {
      const { includeFromTo } = filter;
      const excludeFromTo = filter?.excludeFromTo || [];
      const inclusions = isValidFromTo([...includeFromTo, ...excludeFromTo]);
      draft.filters.targetFilter.valid = inclusions.isValid;
      draft.filters.targetFilter.errors.inclusions = inclusions.errors;
    }

    if (filter?.excludeFromTo) {
      const { excludeFromTo } = filter;
      const includeFromTo = filter?.includeFromTo || [];
      const exclusions = isValidFromTo([...excludeFromTo, ...includeFromTo]);
      draft.filters.targetFilter.valid = exclusions.isValid;
      draft.filters.targetFilter.errors.exclusions = exclusions.errors;
    }

    validateIncludeAndExcludeFolders(filter, draft.filters);
  }
}

function validateIncludeAndExcludeFolders(
  filter: MailboxFilter,
  filtersState: Draft<State["filters"]>
) {
  let includeFolderValid = true;
  let excludeFolderValid = true;

  const inclusionFolders =
    filter?.includeFolders?.map(inclusion => inclusion.folder) || [];

  const exclusionFolders =
    filter?.excludeFolders?.map(inclusion => inclusion.folder) || [];

  if (inclusionFolders.length > 0) {
    const includeErrors = validateIncludeFolders(
      inclusionFolders,
      exclusionFolders
    );

    includeFolderValid = Object.keys(includeErrors).length === 0;
    filtersState.targetFilter.errors.inclusions = includeErrors;
  }

  if (exclusionFolders.length > 0) {
    const excludeErrors = validateExcludeFolders(
      inclusionFolders,
      exclusionFolders
    );

    excludeFolderValid = Object.keys(excludeErrors).length === 0;
    filtersState.targetFilter.errors.exclusions = excludeErrors;
  }

  filtersState.targetFilter.valid = includeFolderValid && excludeFolderValid;
}

type FolderIndex = number;
type ErrorI18nKey = string;
type Errors = Record<FolderIndex, ErrorI18nKey>;

function validateExcludeFolders(
  includeFolders: string[],
  excludeFolders: string[]
): Errors {
  return excludeFolders.reduce((errors, excludeFolder, currentIndex) => {
    if (isBlank(excludeFolder)) {
      errors[currentIndex] = "common:is_required";
    } else {
      if (isDuplicated(excludeFolder, excludeFolders)) {
        errors[currentIndex] = "excludeFolders.name.alreadyInUse";
      } else if (includeFolders.includes(excludeFolder)) {
        errors[currentIndex] = "excludeFolders.name.useInInclude";
      }
    }
    return errors;
  }, {});
}

function validateIncludeFolders(
  includeFolders: string[],
  excludeFolders: string[]
): Errors {
  return includeFolders.reduce((errors, includeFolder, currentIndex) => {
    if (isBlank(includeFolder)) {
      errors[currentIndex] = "common:is_required";
    } else {
      if (isDuplicated(includeFolder, includeFolders)) {
        errors[currentIndex] = "includeFolders.name.alreadyInUse";
      } else if (excludeFolders.includes(includeFolder)) {
        errors[currentIndex] = "includeFolders.name.useInExclude";
      }
    }
    return errors;
  }, {});
}

function isDuplicated(folder: string, folders: string[]) {
  return folders.filter(folderInList => folderInList === folder).length > 1;
}
