import { useTranslation } from "react-i18next";
import { Theme, Typography, makeStyles } from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { Office365Gateway } from "..";

interface StatusProps {
  flow: Office365Gateway;
}

export const InstalledStatus = (props: StatusProps) => {
  const { t } = useTranslation("gateway");
  const classes = useStyles(props);
  const { flow } = props;
  const StatusIcon = flow.enabled ? CheckIcon : ErrorOutlineIcon;
  const status = flow.enabled
    ? t("O365Gateway.enabled")
    : t("O365Gateway.disabled");

  return (
    <Typography
      component="div"
      className={classes.text}
      data-testid="installed-status"
    >
      <StatusIcon className={classes.icon} />
      {` ${t(`flow.${flow.type}`)} - ${status}`}
    </Typography>
  );
};

const useStyles = makeStyles<Theme, StatusProps>({
  text: {
    display: "flex",
    gap: "5px",
    color: props => (props.flow.enabled ? green[400] : orange[300]),
    fontSize: ".9rem",
    fontWeight: 500,
    alignItems: "center",
  },
  icon: { fontSize: "17", position: "relative" },
});
