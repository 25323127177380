import { useTranslation } from "react-i18next";
import {
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AdvancedOptionsContainer, OptionGroup } from "components/Styled";
import SwitchField from "components/Forms/SwitchField";
import { ArchivingAddress } from "@dashboard-v3/api";

type AdvancedOptionsProps = {
  updateTarget: (updates: Partial<ArchivingAddress>) => void;
  archivingAddress: ArchivingAddress;
};

const AdvancedOptions = ({
  updateTarget,
  archivingAddress,
}: AdvancedOptionsProps) => {
  const { t } = useTranslation("rules");

  const handleRedirectAddress = e => {
    const { value } = e.target;
    updateTarget({ redirectOnErrorEmailAddress: value });
  };

  const handleSaveForwardMessages = e => {
    const { name, checked } = e.target;
    updateTarget({ [name]: checked });
  };

  return (
    <AdvancedOptionsContainer>
      <AccordionSummary
        data-testid="target__archiving_advanced-options"
        expandIcon={<ExpandMoreIcon />}
        style={{ padding: "0 24px" }}
      >
        <Typography>{t("advancedOptions")}</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ padding: "8px 24px 24px", flexDirection: "column" }}
      >
        <OptionGroup>
          <Typography
            gutterBottom
            variant="subtitle1"
            style={{ fontWeight: "500" }}
          >
            {t("redirectAddress")}
          </Typography>
          <TextField
            type="email"
            variant="outlined"
            fullWidth
            value={archivingAddress.redirectOnErrorEmailAddress}
            onChange={handleRedirectAddress}
            inputProps={{ "data-testid": "archiving-address__redirect-email" }}
            required
          />
          <FormHelperText>
            {t("useThisEmailAddressIfAMessageCanNotBeStored")}
          </FormHelperText>
        </OptionGroup>
        <OptionGroup>
          <OptionGroup>
            <SwitchField
              name="saveForwardedMessages"
              label={t("saveForwardedMessages")}
              description={t("saveForwardedMessagesAsAttachmentsSeparately")}
              value={archivingAddress.saveForwardedMessages}
              onChange={handleSaveForwardMessages}
            />
          </OptionGroup>
        </OptionGroup>
      </AccordionDetails>
    </AdvancedOptionsContainer>
  );
};

export default AdvancedOptions;
