import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldSkeleton } from "components/SkeletonLoading";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useVariables from "utils/useVariables";

type Props = {
  onChange: (variableId?: string) => unknown;
  value: string | null;
  error: boolean;
};

export default function SelectVariable({ onChange, value, error }: Props) {
  const { loading, userVariables } = useVariables();
  const { t } = useTranslation("securityPolicies");

  const isLoadingOrEmpty = loading || userVariables?.length === 0;

  if (loading || !userVariables) {
    return <FieldSkeleton />;
  }

  function handleOnChange(_, value) {
    onChange(value?.id);
  }

  return (
    <Autocomplete
      style={{ width: "100%" }}
      disabled={isLoadingOrEmpty}
      options={userVariables}
      getOptionLabel={option => {
        return option?.name;
      }}
      getOptionSelected={(opt, selected) => {
        return opt.id === selected?.id;
      }}
      value={userVariables.find(options => options.id === value) || null}
      onChange={handleOnChange}
      renderOption={({ id, name, description }) => (
        <OptionContainer key={id}>
          <span>{name}</span>
          {description && <span>{description}</span>}
        </OptionContainer>
      )}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            error={Boolean(error)}
            helperText={error}
            label={t("form.predicates.variableId.label")}
          />
        );
      }}
    />
  );
}

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  span:nth-child(2) {
    font-size: 12px;
    color: grey;
  }
`;
