import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";
import { Domain, UpdateDomainBody } from "@dashboard-v3/api";
import pick from "utils/pick";
import SelectAliases from "./SelectAliases";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import { mutate } from "swr";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "components/ButtonWithLoading";

export type Params = { domain: Domain };

export default function EditDomainBtn({ domain: initValue }: Params) {
  const [changes, setChanges] = useState<UpdateDomainBody>(
    pickEditableFields(initValue)
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation("orgAndDomains");
  const { enqueueSnackbar } = useSnackbar();

  const domainName = initValue.domain;

  const openModal = e => {
    e.stopPropagation();
    setOpenDialog(true);
  };

  const closeModal = () => setOpenDialog(false);

  const handleChange = event => {
    const { name, value, checked } = event.target;

    setChanges(current => {
      return { ...current, [name]: isCheckbox(name) ? checked : value };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setSaving(true);
    try {
      await apiRequest<Domain>("PUT", `/domains/${domainName}`, changes);
      mutate("/domains");
      mutate("/manual-domains");
      closeModal();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, { extra: { domainName, changes } });
      const code = getErrorCode<"INVALID_ALIASES">(error);

      const errorMessage = t([
        `error.editDomain.${code}`,
        `error.editDomain.SYSTEM_FAILURE`,
      ]);

      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  return (
    <>
      <Button
        data-testid="editDomainBtn"
        disableElevation
        onClick={openModal}
        variant="outlined"
        size="small"
      >
        {t("common:edit")}
      </Button>
      <Dialog
        open={openDialog}
        onClick={e => e.stopPropagation()}
        onClose={closeModal}
      >
        <DialogTitle>
          {t("list.editSomeDomain", { value: domainName })}
        </DialogTitle>
        <form
          data-testid="editDialogForm"
          style={{ minWidth: "450px" }}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <StyledTextField
              fullWidth
              label={t("list.server")}
              inputProps={{
                "data-testid": "serverInput",
              }}
              onChange={handleChange}
              type="text"
              name="server"
              value={changes.server}
              variant="outlined"
            />
            <StyledTextField
              fullWidth
              label={t("list.relayServer")}
              inputProps={{
                "data-testid": "relayServerInput",
              }}
              onChange={handleChange}
              type="text"
              name="relayServer"
              value={changes.relayServer}
              variant="outlined"
            />
            <SelectAliases
              value={changes.aliases}
              onChange={values => {
                setChanges(changes => ({ ...changes, aliases: values }));
              }}
            />

            <FormControlLabel
              label={t("list.rcptLookup")}
              control={
                <Switch
                  checked={changes.rcptLookup}
                  onChange={handleChange}
                  name="rcptLookup"
                  color="primary"
                />
              }
            />
            <FormControlLabel
              label={t("list.mxLookup")}
              control={
                <Switch
                  checked={changes.mxLookup}
                  onChange={handleChange}
                  name="mxLookup"
                  color="primary"
                />
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeModal}
              color="primary"
              data-testid="closeEditDialog"
            >
              {t("common:cancel")}
            </Button>
            <ButtonWithLoading
              color="primary"
              disableElevation
              type="submit"
              variant="contained"
              data-testid="saveEditBtn"
              loading={saving}
            >
              {t("common:save")}
            </ButtonWithLoading>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

function pickEditableFields(domain: Domain): UpdateDomainBody {
  return pick(domain, [
    "aliases",
    "mxLookup",
    "rcptLookup",
    "relayServer",
    "server",
  ]);
}

const StyledTextField = styled(TextField)`
  margin-bottom: 16px;
`;

const isCheckbox = fieldName =>
  fieldName === "rcptLookup" || fieldName === "mxLookup";
