import produce from "immer";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ChipInput from "material-ui-chip-input";
import { OptionGroup } from "components/Styled";
import SwitchField from "components/Forms/SwitchField";
import WithIconHelper from "components/WithIconHelper";
import { getContentFilter } from "./utils";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";

import { FieldGroupProps, FilterName } from "./types";

const chipInputs: FilterName[] = [
  "includeAttachmentExtensions",
  "excludeAttachmentExtensions",
  "includeAttachmentNames",
  "excludeAttachmentNames",
];

function ChipInputs({
  isEnabled,
  handleEnabledFields,
  updateFilter,
}: FieldGroupProps) {
  const { t } = useTranslation("rules");
  const { state } = useRuleContext();
  const filters = getContentFilter(state);

  const valueExistsInFilter = (fieldName: string, value: string) => {
    let values = [];
    if (fieldName.includes("AttachmentExtensions")) {
      values = filters.includeAttachmentExtensions.concat(
        filters.excludeAttachmentExtensions
      );
    } else {
      values = filters.includeAttachmentNames.concat(
        filters.excludeAttachmentNames
      );
    }
    return values.some(v => v.toLowerCase() === value.toLowerCase());
  };

  const handleChipInput = (name: string) => (chip: string, index?: number) => {
    const value = name.includes("AttachmentExtensions")
      ? chip.replaceAll(".", "")
      : chip;
    const update = produce(filters, draft => {
      // Should delete or add to input. Event callback for chip inputs
      // includes an index parameter onDelete only
      if (index !== undefined) {
        draft[name] = draft[name].filter((v: string) => v !== value);
      } else {
        draft[name] = valueExistsInFilter(name, value)
          ? draft[name]
          : [...draft[name], value];
      }
    });

    updateFilter(update);
  };

  function parseValues(name: string) {
    if (name.includes("AttachmentExtensions")) {
      return filters[name].map((val: string) => `.${val}`);
    }
    return filters[name];
  }

  return (
    <>
      {chipInputs.map(name => (
        <OptionGroup key={name}>
          <SwitchField
            name={name}
            label={t(`actionFilters.${name}.label`)}
            description={t(`actionFilters.${name}.desc`)}
            onChange={handleEnabledFields}
            value={isEnabled(name)}
          />
          {isEnabled(name) && (
            <WithIconHelper
              position="after"
              text={t("common:chipInputHelper")}
              icon={<InfoOutlinedIcon fontSize="small" color="primary" />}
              buttonStyles={{ marginLeft: "10px" }}
            >
              <ChipInput
                color="primary"
                variant="outlined"
                fullWidth
                style={{ marginTop: "5px" }}
                onAdd={handleChipInput(name)}
                onDelete={handleChipInput(name)}
                value={parseValues(name)}
              />
            </WithIconHelper>
          )}
        </OptionGroup>
      ))}
    </>
  );
}

export default ChipInputs;
