import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MoreVertIcon from "@material-ui/icons/MoreVertOutlined";
import ConfirmDelete from "components/ConfirmDelete";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { ItemAction } from "./Item";
import { Rule } from "@dashboard-v3/api";
import { canRestart } from "../CreateOrEdit/context/helpers";

interface MenuButtonProps {
  rule: Rule;
  options: Array<{
    key: string;
    value: ItemAction;
    text: string;
  }>;
  onSelect?: (val: ItemAction) => void;
}

function MenuButton({ rule, options, onSelect }: MenuButtonProps) {
  const { t } = useTranslation("rules");
  const [anchor, setAnchor] = useState(null);

  const onButtonClick: React.MouseEventHandler = e => {
    const { target } = e;
    e.stopPropagation();
    setAnchor(target);
  };

  const onMenuClose = e => {
    e.stopPropagation();
    setAnchor(null);
  };

  return (
    <>
      <Button
        size="small"
        style={{ backgroundColor: "transparent" }}
        disableFocusRipple
        disableRipple
        onClick={onButtonClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={onMenuClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options.map(({ key, value, text }) => {
          if (value === ItemAction.DELETE) {
            return (
              <DeleteOption
                key={key}
                message={t("common:confirmDeletion", {
                  name: rule.name,
                  interpolation: { escapeValue: false },
                })}
                onAccept={() => onSelect(value)}
              />
            );
          }

          return (
            <MenuItem
              key={key}
              value={value}
              disabled={value === ItemAction.ENABLE && !canRestart(rule)}
              onClick={e => {
                onMenuClose(e);
                onSelect(value);
              }}
            >
              <Typography variant="body2">{t(text)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

const DeleteOption = styled(ConfirmDelete)`
  width: 100%;
  justify-content: flex-start;
  padding: 6px 16px 6px 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #f44336;
`;

export default MenuButton;
