import { useState, useEffect } from "react";

const PROGRESS_STEP = 5;

export default function useProgress(onFinish) {
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState();

  const start = () => {
    setProgress(PROGRESS_STEP);
  };

  useEffect(() => {
    if (progress === 0) return;

    if (progress >= 100) {
      clearInterval(timer);
      onFinish();
      return;
    }

    if (progress > PROGRESS_STEP) return;

    setTimer(() => {
      return setInterval(() => {
        setProgress(oldProgress => oldProgress + 5);
      }, 300);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return { progress, start };
}
