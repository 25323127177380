import { Box, Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { MouseEventHandler, ReactNode } from "react";

type Params = {
  onClick?: MouseEventHandler;
  loading: boolean;
  children: ReactNode;
  component?: string;
} & ButtonProps;

export default function ButtonWithLoading({
  onClick,
  loading = false,
  children,
  ...attrs
}: Params) {
  return (
    <Box style={{ position: "relative" }}>
      <Button
        color="primary"
        role="button"
        onClick={onClick}
        {...attrs}
        aria-busy={loading ? "true" : "false"}
        disabled={loading ? loading : attrs.disabled}
      >
        {loading && (
          <CircularProgress size={20} style={{ position: "absolute" }} />
        )}
        {children}
      </Button>
    </Box>
  );
}
