import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { SinceTimeRange } from "@dashboard-v3/api";

type Props = {
  value: SinceTimeRange;
  disabled?: boolean;
  onChange: (value: SinceTimeRange) => void;
};

export default function SinceDateConfig({ value, disabled, onChange }: Props) {
  const { t } = useTranslation("rules");

  return (
    <TextField
      type="date"
      variant="outlined"
      fullWidth
      label={t("since")}
      disabled={disabled}
      inputProps={{
        "data-testid": "mailbox__from-date_since-textfield",
      }}
      value={value.since}
      onChange={event => onChange({ mode: "DATES", since: event.target.value })}
    />
  );
}
