import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  Typography,
  makeStyles,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion } from "components/AccordionForm";

import { MxheroShareParams } from "@dashboard-v3/api";

interface Props {
  filters: MxheroShareParams;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
  onApply: () => void;
  onClearFilters: () => void;
}

export default function ListFilters({
  filters,
  onChange,
  onApply,
  onClearFilters,
}: Props) {
  const { t } = useTranslation("storageShares");
  const classes = useStyles();
  const [openFilters, setOpenFilters] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const onApplyFilters = e => {
    e.preventDefault();
    const { limit, offset, ...rest } = filters;
    setFilterCount(Object.keys(rest).length);
    onApply();
  };

  return (
    <Accordion
      expanded={openFilters}
      style={{ width: "100%", margin: 0 }}
      onChange={() => setOpenFilters(!openFilters)}
    >
      <AccordionSummary
        data-testid="tracing__search-filter-summary"
        expandIcon={<ExpandMoreIcon />}
      >
        <div style={{ width: "100%" }}>
          <Typography variant="subtitle2">
            {t("list.filters.shares")}
          </Typography>
          {!openFilters && (
            <Typography variant="caption" color="textSecondary">
              {filterCount
                ? t("list.filters.applied", { count: filterCount })
                : t("list.filters.noFilters")}
            </Typography>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={onApplyFilters} style={{ width: "100%" }}>
          <div className={classes.inputsContainer}>
            <TextField
              className={classes.shrinkedInput}
              variant="outlined"
              name="filename"
              label={t("list.filters.filename.label")}
              fullWidth
              onChange={onChange}
              value={filters?.filename || ""}
            />
            <TextField
              className={classes.shrinkedInput}
              variant="outlined"
              name="owner"
              label={t("list.filters.owner.label")}
              fullWidth
              onChange={onChange}
              value={filters?.owner || ""}
            />
            <TextField
              variant="outlined"
              name="id"
              label={t("list.filters.id.label")}
              fullWidth
              onChange={onChange}
              value={filters?.id || ""}
            />
          </div>

          <div className={classes.filtersBtnContainer}>
            {!!filterCount && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                disableElevation
                onClick={onClearFilters}
              >
                {t("list.filters.clearBtn")}
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              size="small"
              color="primary"
              disableElevation
            >
              {t("list.filters.submit")}
            </Button>
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles({
  filtersBtn: { marginBottom: "1px", marginRight: "2px" },
  filtersTitle: { padding: "15px 15px" },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px 15px 20px",
    gap: "30px",
    width: 600,
  },
  filtersBtnContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
    marginTop: "30px",
  },
  inputsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  shrinkedInput: { flex: "1 0 33%" },
});
