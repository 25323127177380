import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import logout from "utils/logout";
import PageLoading from "components/PageLoading";
import UserFormModal, { UserSaveProps } from "components/UserFormModal";
import { User } from "@dashboard-v3/api";
import { updateUser } from "pages/Users/utils";

const ToolbarBtn = styled(Button)`
  justify-self: end;
`;

interface HeaderMenuProps {
  user: User;
  onUserChange: React.Dispatch<React.SetStateAction<User>>;
}

export default function HeaderMenu({ user, onUserChange }: HeaderMenuProps) {
  const { t } = useTranslation("components");
  const history = useHistory();
  const [userFormIsOpen, setUserFormIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { name, email } = user;

  async function handleSave(changes: UserSaveProps) {
    const { id, ...userData } = changes;
    setIsLoading(true);
    try {
      const updatedUser = await updateUser(id, userData);
      localStorage.setItem("user", JSON.stringify(updatedUser));
      onUserChange(() => {
        const newState = { ...updatedUser, name: userData.name };
        return newState;
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    handleClose();
    await logout();
    history.push("/login");
  };

  return (
    <>
      <PageLoading loading={isLoading} />
      <ToolbarBtn
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        data-testid="user-name"
        disableElevation
        onClick={handleClick}
        variant="contained"
      >
        {name ? name : email}
      </ToolbarBtn>
      <Menu
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="header-menu__accounts-btn"
          onClick={() => setUserFormIsOpen(true)}
          style={{ flexDirection: "column" }}
          disabled={isDisableAccountSettings(user)}
        >
          <Typography display="block">
            {t("dashboard.accountSettings")}
          </Typography>
          {name && (
            <Typography
              variant="caption"
              display="block"
              style={{ alignSelf: "flex-start" }}
            >
              {user.email}
            </Typography>
          )}
        </MenuItem>
        <MenuItem data-testid="header-menu__logout-btn" onClick={handleLogout}>
          <Typography>{t("dashboard.logOut")}</Typography>
        </MenuItem>
      </Menu>
      {userFormIsOpen && (
        <UserFormModal
          isOpen={userFormIsOpen}
          onClose={() => setUserFormIsOpen(false)}
          onSave={handleSave}
          user={user}
        />
      )}
    </>
  );
}

const isDisableAccountSettings = (user: User) => {
  const { organizationId, originalOrganizationId, superUser } = user;
  return superUser && organizationId !== originalOrganizationId;
};
