import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  ListItem,
  Paper,
} from "@material-ui/core";

export const WebHookContainer = styled.div`
  padding: 0 24px 24px;
`;

export const ListContainer = styled(Paper)`
  max-height: 400px;
  margin-top: 5px;
  box-shadow: none;
  overflow: auto;
`;

export const ListTitleWrapper = styled(Paper)`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
  box-shadow: none;
  padding: 0 16px;
`;

export const EmptyListContainer = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 150px;
  width: 100%;
  margin-top: 10px;
  box-shadow: none;
  border: 1px grey dashed;
`;

export const AdvancedOptionsContainer = styled(Accordion)`
  border-top: 1px solid #d6dbe0;
  box-shadow: none;

  .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0 24px;
  }
  :before {
    opacity: 0;
  }
`;

export const AdvancedOptionsDetails = styled(AccordionDetails)`
  padding: 8px 24px 24px;
  flex-direction: column;
  div:first-child {
    width: 75%;
  }
`;

export const StyledFolderItem = styled(ListItem)<{ $inConflict: boolean }>`
  border-radius: 6px;
  margin-bottom: 5px;
  background-color: ${({ $inConflict }) =>
    $inConflict ? "rgb(253, 237, 237)" : "initial"};
`;
