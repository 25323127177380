import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Office365Button, RegisterButton } from "components/Landing/Buttons";
import LoginGoogleButton from "components/Landing/Buttons/LoginGoogleButton";
import PageLoading from "components/PageLoading";
import RegisterForm from "./Forms";
import { canRegisterWithDomain } from "./api";
import { loginInOffice } from "../Login/api";
import {
  FullWidthContainer,
  StyledDivider,
  StyledForm,
  StyledLoginPanel,
} from "./styled";

export type UserForm = {
  source: "google" | "office" | "on_premise";
  email: string;
  domain: string;
};

export default function RegisterPanel() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("register");
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userForm, setUserForm] = useState<UserForm>({
    source: "on_premise",
    email: "",
    domain: "",
  });

  const extractDomain = userEmail => userEmail.split("@").slice(-1)[0];

  async function validateEmail(emailToCheck) {
    try {
      setIsLoading(true);
      const domain = extractDomain(emailToCheck);
      const result = await canRegisterWithDomain(domain);
      if (!result.valid) {
        const message = t([`error.${result.code}`, "error.SYSTEM_FAILURE"]);
        enqueueSnackbar(message, {
          variant: "error",
        });
        return false;
      }
      return true;
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmitRegisterWithMxHero = async event => {
    event.preventDefault();
    const emailInputValue = event.target.elements.email.value;
    if (!emailInputValue) return;
    const isValid = await validateEmail(emailInputValue);
    if (isValid) {
      setUserForm({
        domain: extractDomain(emailInputValue),
        email: emailInputValue,
        source: "on_premise",
      });
      setIsLoading(false);
      setIsOpen(true);
    }
  };

  const registerWithGoogle = async (_, userEmail, userDomain) => {
    if (!userEmail) return;
    const isValid = await validateEmail(userEmail);
    if (isValid) {
      setUserForm({
        domain: userDomain,
        email: userEmail,
        source: "google",
      });
      setIsOpen(true);
    }
  };

  const registerWithOffice = async () => {
    const email = await loginInOffice();
    if (!email) return;
    const isValid = await validateEmail(email);
    if (isValid) {
      const domain = extractDomain(email);
      setUserForm({
        domain,
        email,
        source: "office",
      });
      setIsOpen(true);
    }
  };

  return (
    <>
      <PageLoading loading={isLoading} />
      <StyledLoginPanel>
        <LoginGoogleButton onLogin={registerWithGoogle} />
        <Office365Button onClick={registerWithOffice}>
          {t("registerWithOffice")}
        </Office365Button>
        <StyledDivider />
        <StyledForm onSubmit={onSubmitRegisterWithMxHero}>
          <FullWidthContainer>
            <Typography
              variant="body2"
              align="center"
              style={{ marginBottom: "5px" }}
            >
              {t("registerWithMxhero")}
            </Typography>
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "landing__mxhero-register-input",
              }}
              // onChange={handleUserEmailInput}
              placeholder="user@domain.com"
              required
              size="small"
              style={{ marginBottom: "18px" }}
              type="email"
              variant="outlined"
              name="email"
            />
          </FullWidthContainer>
          <RegisterButton onClick={() => {}} />
        </StyledForm>
        <Button
          data-testid="landing__switch-signin-btn"
          onClick={() => history.push("/login")}
          size="large"
        >
          {t("switchToSignIn")}
        </Button>
        {isOpen && (
          <RegisterForm
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            registerData={userForm}
          />
        )}
      </StyledLoginPanel>
    </>
  );
}
