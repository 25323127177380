import { typePath as getTypePath } from "pages/Rules/CreateOrEdit/context/helpers";
import { Rule } from "pages/Rules/types";
import { useTranslation } from "react-i18next";
import EmailFlowDetails from "./EmailFlowDetails";
import MailboxDetails from "./MailboxDetails";
import { UnstyledList } from "./Styles";

export default function TargetDetails({ rule }: { rule: Rule }) {
  const { t } = useTranslation("rules");
  if (!rule.targetType) return null;

  const typePath = getTypePath(rule.actionType, rule);

  if (
    rule.targetType === "ARCHIVING_ADDRESS" &&
    "archivingAddress" in typePath
  ) {
    return (
      <UnstyledList>
        <li>
          {t("sentTo")}
          {typePath?.archivingAddress?.emailAddress}
        </li>
      </UnstyledList>
    );
  }

  if ("mailbox" in typePath && typePath.mailbox) {
    return <MailboxDetails mailbox={typePath.mailbox} />;
  }

  if ("emailFlow" in typePath && typePath.emailFlow) {
    return <EmailFlowDetails emailFlow={typePath.emailFlow} />;
  }

  return null;
}
