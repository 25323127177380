/* eslint-disable react/prop-types */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  DialogTitle,
  Divider,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Office365Button,
  SignInButton,
  SamlButton,
} from "components/Landing/Buttons";
import LoginGoogleButton from "components/Landing/Buttons/LoginGoogleButton";
import PageLoading from "components/PageLoading";
import { updateLastLogin } from "utils/authentication";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import {
  reauthenticateGoogle,
  reauthenticateOffice,
  reauthenticatePassword,
} from "./api";

interface SignInOptionsProps {
  userEmail: string;
  availableOptions: ("GOOGLE" | "PASSWORD" | "OFFICE" | "SAML")[];
  onSuccess: () => void;
}

export default function AuthenticationOptions({
  userEmail,
  availableOptions,
  onSuccess,
}: SignInOptionsProps) {
  const { t } = useTranslation("login");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  if (onlySaml(availableOptions)) {
    loginWithSaml(userEmail);
    return null;
  }

  const handlePassword = async event => {
    event.preventDefault();
    closeSnackbar();
    const password = event.target.elements.password.value;
    if (!password) return;

    setIsLoading(true);
    try {
      const lastLogin = await reauthenticatePassword(userEmail, password);
      updateLastLogin(lastLogin);
      onSuccess();
    } catch (error) {
      setIsLoading(false);
      const message = t([
        `error.${getErrorCode(error)}`,
        "error.SYSTEM_FAILURE",
      ]);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogle = async tokenId => {
    try {
      setIsLoading(true);
      const lastLogin = await reauthenticateGoogle(tokenId);
      updateLastLogin(lastLogin);
      setIsLoading(false);
      onSuccess();
    } catch (error) {
      const message = t([
        `error.${getErrorCode(error)}`,
        "error.SYSTEM_FAILURE",
      ]);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOffice = async () => {
    setIsLoading(true);
    try {
      const lastLogin = await reauthenticateOffice();
      updateLastLogin(lastLogin);
      onSuccess();
    } catch (error) {
      const message = t([
        `error.${getErrorCode(error)}`,
        "error.SYSTEM_FAILURE",
      ]);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  function isEnabled(type) {
    return (availableOptions || []).includes(type);
  }

  return (
    <>
      <DialogTitle disableTypography className={classes.formTitle}>
        <Typography variant="h6">{t("requireAuthModal.title")}</Typography>
        <Typography variant="caption" color="textSecondary">
          {t("requireAuthModal.subtitle")}
        </Typography>
      </DialogTitle>
      <Paper className={classes.optionsContainer}>
        <PageLoading loading={isLoading} />

        {isEnabled("GOOGLE") && (
          <LoginGoogleButton key="GOOGLE" onLogin={handleGoogle} />
        )}

        {isEnabled("OFFICE") && (
          <Office365Button key="OFFICE" onClick={handleOffice}>
            {t("signWithOffice")}
          </Office365Button>
        )}

        {isEnabled("SAML") && (
          <SamlButton onClick={() => loginWithSaml(userEmail)} />
        )}

        {isEnabled("PASSWORD") && (
          <>
            <Divider className={classes.divider} />
            <form className={classes.form} onSubmit={handlePassword}>
              <Typography
                variant="body2"
                align="center"
                style={{ marginBottom: "5px" }}
              >
                {t("signWithMxhero")}
              </Typography>
              <TextField
                fullWidth
                inputProps={{ "data-testid": "login__mxhero-account-input" }}
                placeholder={t("password")}
                required
                size="small"
                style={{ marginBottom: "18px" }}
                type="password"
                variant="outlined"
                name="password"
              />
              <SignInButton text="Authenticate" onClick={() => {}} />
            </form>
          </>
        )}
      </Paper>
    </>
  );
}

const useStyles = makeStyles({
  formTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
  },
  optionsContainer: {
    display: "grid",
    padding: "8px 65px 50px 65px",
    minWidth: "390px",
    gridRowGap: "18px",
    borderRadius: "8px",
  },
  divider: {
    width: "100%",
  },
  form: {
    display: "grid",
    width: "100%",
  },
});

function onlySaml(availableOptions) {
  return availableOptions.length === 1 && availableOptions.includes("SAML");
}

function loginWithSaml(email) {
  const returnUrl = encodeURIComponent(
    `${window.location.origin}/saml-response/`
  );
  window.location.replace(
    `${process.env.REACT_APP_API_URL}/auth/saml/${email}?returnUrl=${returnUrl}`
  );
}
