import { Box, List } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { BulkSaveResult } from "pages/Accounts/accountContext";
import AccountItem from "./AccountItem";

interface ErrorProps {
  results: BulkSaveResult;
}

export default function SaveResults(props: ErrorProps) {
  const { t } = useTranslation("accounts");
  const { errors, accountsDone } = props.results;

  return (
    <Box pb={2} style={{ marginTop: "10px" }}>
      {accountsDone === 0 ? (
        <Alert variant="filled" severity="error" style={{ lineHeight: 0 }}>
          {t("bulk.allAccountsFailed")}
        </Alert>
      ) : (
        <Alert variant="filled" severity="success">
          {t("bulk.success", { count: accountsDone })}
        </Alert>
      )}
      <Box mt={4} fontWeight={500} fontSize="1rem">
        {t("bulk.withErrors")}
      </Box>
      <List>
        {errors.map(account => (
          <AccountItem account={account} key={account.email} />
        ))}
      </List>
    </Box>
  );
}
