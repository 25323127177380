import { ManualDomain } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";
import useSWR from "swr";

const fetcher = async (url: string) => apiRequest<ManualDomain[]>("GET", url);

export default function useManualDomains() {
  const { data, isLoading, mutate } = useSWR("/manual-domains", fetcher, {
    suspense: true,
    revalidateOnFocus: false,
  });

  async function remove(manualDomain: ManualDomain) {
    await apiRequest(
      "DELETE",
      `/manual-domains/${encodeURIComponent(manualDomain.name)}`,
      {}
    );
    mutate();
  }

  async function create(manualDomain: ManualDomain) {
    const updatedDomain = await apiRequest<ManualDomain>(
      "POST",
      "/manual-domains",
      manualDomain
    );
    mutate([...data, updatedDomain]);
    return updatedDomain;
  }

  async function verify(manualDomain: ManualDomain) {
    const updatedDomain = await apiRequest<ManualDomain>(
      "PUT",
      `/manual-domains/${encodeURIComponent(manualDomain.name)}/verify-domain`,
      {}
    );

    mutate();
    return updatedDomain;
  }

  return { data, isLoading, remove, create, verify };
}
