/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Collapse,
  Box,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import PaperTable from "./PaperTable";

const { Headers, Body } = PaperTable;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

/**
 *
 * @param {object} params
 * @param {import('./types').Subscription[]} params.subscriptions
 * @returns
 */
const Suscriptions = ({ subscriptions }) => {
  const { t } = useTranslation("billing");

  return (
    <PaperTable title={t("subscriptions.title")}>
      <Headers>
        {[
          t("subscriptions.status"),
          t("subscriptions.startDate"),
          t("subscriptions.endDate"),
          t("subscriptions.terms"),
          "",
        ]}
      </Headers>

      <Body>
        {subscriptions.map(subscription => (
          <Row key={subscription.id} item={subscription} />
        ))}
      </Body>
    </PaperTable>
  );
};

const SubTableHeader = ({ children }) => (
  <TableCell style={{ color: "gray" }}>{children}</TableCell>
);

const Row = ({ item }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("billing");
  const classes = useRowStyles();

  const { id, status, startDate, endDate, months, orders } = item;

  return (
    <>
      <TableRow key={id} className={classes.root}>
        <TableCell>
          <Typography>{status}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{new Date(startDate).toLocaleDateString()}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{new Date(endDate).toLocaleDateString()}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{months}</Typography>
        </TableCell>
        <TableCell>
          <Button
            endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={() => {
              setOpen(previous => !previous);
            }}
          >
            {t("subscriptions.orders")}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow key={`${id}-orders-subtable`}>
        <TableCell colSpan={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box padding="1rem" paddingBottom="2rem">
              <Typography style={{ paddingBottom: "1rem", fontWeight: "500" }}>
                {t("subscriptions.orders")}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <SubTableHeader>
                      {t("subscriptions.startDate")}
                    </SubTableHeader>
                    <SubTableHeader>
                      {t("subscriptions.licences")}
                    </SubTableHeader>
                    <SubTableHeader>
                      {t("subscriptions.feature")}
                    </SubTableHeader>
                    <SubTableHeader>
                      {t("subscriptions.product")}
                    </SubTableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map(order => (
                    <TableRow key={order.id}>
                      <TableCell>
                        {new Date(order.startDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{order.quantity}</TableCell>
                      <TableCell>{order.feature}</TableCell>
                      <TableCell>{order.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Suscriptions;
