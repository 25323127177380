import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";

import { Rule } from "@dashboard-v3/api";
import { ListTab, SearchCriteria } from ".";

interface EmptyMessageProps {
  rules: Rule[];
  currentTab: ListTab;
  criteria: SearchCriteria;
  onTabChange: (value: ListTab) => Promise<void>;
}

export default function EmptyListMessage(props: EmptyMessageProps) {
  const { t } = useTranslation("rules");
  const history = useHistory();
  const { rules, currentTab, criteria, onTabChange } = props;
  const emptyText =
    currentTab === ListTab.Executing
      ? t("list.noActiveRules")
      : t("list.emptyList");

  if (noResultsInSearch(rules, criteria)) {
    return (
      <Box my={2}>
        <Box mb={2}>{t("list.emptySearchResults")}</Box>
      </Box>
    );
  }

  return (
    <Box my={2}>
      <Box mb={2}>{emptyText}</Box>
      <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
        <Button
          size="small"
          wording={t("list.createBtn")}
          onClick={() => history.push("/rules/create")}
        />
        {currentTab === ListTab.Executing && (
          <Button
            size="small"
            variant="text"
            wording={t("list.allRulesBtn")}
            onClick={() => onTabChange(ListTab.All)}
          />
        )}
      </div>
    </Box>
  );
}

function noResultsInSearch(rules: Rule[], criteria: SearchCriteria) {
  if (criteria.withCriteria && rules.length === 0) return true;

  return false;
}
