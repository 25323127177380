import { TimePeriodStat } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { monthlyStats } from "./utils";
import LoadingBlock from "./LoadingBlock";
import Block from "./Block";

dayjs.extend(utc);

interface Props {
  loading: boolean;
  stats: TimePeriodStat[];
}

export default function ProcessedEmailsBlock({ loading, stats }: Props) {
  const { t } = useTranslation("usageReports");

  if (loading) return <LoadingBlock />;

  const { lastMonth, previousToLastMonth } = monthlyStats(
    stats,
    "rule_mail_executed_"
  );

  return (
    <Block
      label={t("processedEmails.title")}
      value={lastMonth.amount.toLocaleString()}
      previousAmount={previousToLastMonth.amount}
      currentAmount={lastMonth.amount}
    />
  );
}
