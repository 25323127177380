/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Dialog, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PageLoading from "components/PageLoading";
import AuthenticationOptions from "./AuthenticationOptions";
import TwoFactorAuthentication from "./2FAuthtentication";
import { fetchAuthenticationData } from "./api";

import { CurrentAuthData } from "@dashboard-v3/api";
import dayjs from "dayjs";

enum AuthenticationType {
  TwoFactorCode = "TWO_FACTOR_CODE",
  Options = "OPTIONS",
}

interface LoginProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function AuthenticationModal({
  isOpen,
  onClose,
  onSuccess,
}: LoginProps) {
  const { t } = useTranslation("login");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [authData, setAuthData] = useState<CurrentAuthData>(null);
  const [authType, setAuthType] = useState<AuthenticationType>();

  useEffect(() => {
    const getAuthOptions = async () => {
      setLoading(true);
      try {
        const result = await fetchAuthenticationData();
        setAuthData(result);
      } catch (error: unknown) {
        const message = t("error.SYSTEM_FAILURE");
        enqueueSnackbar(message, { variant: "error" });
        onClose();
      } finally {
        setLoading(false);
      }
    };
    getAuthOptions();
  }, []);

  useEffect(() => {
    if (!authData) return;

    if (authData.twoFactorAuthEnabled) {
      const codeExpiration = dayjs().unix().toString();
      window.sessionStorage.setItem("2FA-EXPIRATION", codeExpiration);
      setAuthType(AuthenticationType.TwoFactorCode);
    } else {
      setAuthType(AuthenticationType.Options);
    }
  }, [authData]);

  if (loading) return <PageLoading loading={loading} />;

  return (
    <Dialog open={isOpen}>
      <IconButton size="small" className={classes.closeBtn} onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
      {authType === AuthenticationType.Options && (
        <AuthenticationOptions
          userEmail={authData.email}
          availableOptions={authData.options}
          onSuccess={onSuccess}
        />
      )}
      {authType === AuthenticationType.TwoFactorCode && (
        <TwoFactorAuthentication
          email={authData.email}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
}

const useStyles = makeStyles({
  form: {
    display: "grid",
    padding: "10px 65px 50px 65px",
    minWidth: "390px",
    gridRowGap: "18px",
    borderRadius: "8px",
  },
  formTitle: {
    display: "flex",
    flexDirection: "column",
  },
  closeBtn: {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
  },
});
