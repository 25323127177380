/* eslint-disable no-use-before-define */
import i18n from "i18n";
import {
  AuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import apiRequest from "utils/apiRequestWithErrorCode";

import {
  AuthCodeValidationRequest,
  LoginOptionNames,
  User,
} from "@dashboard-v3/api";
export type { LoginOptionsErrors, LoginOptionNames } from "@dashboard-v3/api";

type LoginResponse = { sessionDuration: number; user: User };

export async function fetchSignInOptions(email: string) {
  return apiRequest<LoginOptionNames[]>(
    "GET",
    `/auth/options/${encodeURIComponent(email)}`
  );
}

export async function loginWithEmail(email: string, password: string) {
  return apiRequest<User>("POST", "/auth/login", {
    email,
    password,
    locale: i18n.language,
  });
}

export async function loginWithSamlCode(code: string) {
  // INVALID_CODE | SYSTEM_FAILURE
  return apiRequest<LoginResponse>("POST", "/auth/saml/validate", {
    code,
    locale: i18n.language,
  });
}

export async function loginWithGoogle(tokenId: string) {
  return apiRequest<User>("POST", "/auth/google", {
    tokenId,
    locale: i18n.language,
  });
}

const officeLoginRequest = {
  scopes: ["user.read"],
  forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
  prompt: "select_account",
};

let officeClientApp;

function getOfficeClientApp(): IPublicClientApplication {
  if (!officeClientApp) {
    officeClientApp = new PublicClientApplication({
      auth: {
        clientId: process.env.REACT_APP_OFFICE_AUTH_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
        // eslint-disable-next-line no-restricted-globals
        // redirectUri: location.origin,
        navigateToLoginRequestUrl: false,
      },
      system: {
        // Caused by https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5477
        pollIntervalMilliseconds: 15,
      },
    });
  }
  return officeClientApp;
}

export async function loginInOffice() {
  const clientApp = getOfficeClientApp();

  try {
    const loginResponse = await clientApp.loginPopup(officeLoginRequest);
    const email = loginResponse.account.username;
    clientApp.setActiveAccount(loginResponse.account);
    return email;
  } catch (error: unknown) {
    if (error instanceof AuthError && error.errorCode === "user_cancelled") {
      return null;
    }
    console.error(error);
    throw new Error("SYSTEM_FAILURE");
  }
}

export async function loginWithOfficeInApi() {
  let accessToken: string;
  let email: string;

  try {
    email = await loginInOffice();
    const clientApp = getOfficeClientApp();
    const tokenResponse = await clientApp.acquireTokenSilent(
      officeLoginRequest
    );
    accessToken = tokenResponse.accessToken;
  } catch (error: any) {
    if (error.errorCode === "user_cancelled") return null;
    console.error(error);
    throw new Error("SYSTEM_FAILURE");
  }

  return apiRequest("POST", "/auth/office", {
    accessToken,
    email,
    locale: i18n.language,
  });
}

type UserAndSession = {
  user: User;
  sessionDuration: number;
};

export async function validate2FACode(body: AuthCodeValidationRequest) {
  return apiRequest<UserAndSession>("POST", "/auth/validate-auth-code", body);
}
