import { Store } from "@dashboard-v3/api";
import { MenuItem, Select } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SelectField from "components/Forms/SelectField";
import { OptionGroup } from "components/Styled";
import { Suspense } from "react";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";

type Params = {
  onChange: (storeId: string) => void;
  storeId?: string;
};
export default function Loader(params: Params) {
  return (
    <Suspense
      fallback={
        <Skeleton variant="rect" width="100%" height="56px">
          <Select />
        </Skeleton>
      }
    >
      <SelectStore {...params} />
    </Suspense>
  );
}

function SelectStore({ storeId, onChange: emit }: Params) {
  const { data: stores } = useSWR<Store[]>(
    "/stores?provider=ONEDRIVE",
    fetcher,
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return (
    <OptionGroup data-testid="SelectStores">
      <SelectField<Store>
        allowEmpty
        options={stores}
        disabled={!stores.length}
        value={storeId || ""}
        onChange={event => emit(event.target.value as string)}
        renderValue={value => stores.find(s => s.id === value)?.label || ""}
        required
        renderOption={({ id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        )}
      />
    </OptionGroup>
  );
}
