/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@material-ui/core";
import cloudStorageOptions from "components/cloudStorageOptions";
import { sourceLabels } from "pages/Accounts/SourceList/helpers";
import { TFunction, useTranslation } from "react-i18next";
import {
  EventItem,
  StorageAccountEvent,
  Event,
  SourceEvent,
  StorageEvent,
  SecurityEvent,
  UserUpdatedEvent,
  StorageAccountCreatedEvent,
  OrganizationEvent,
} from "@dashboard-v3/api";
import styled from "styled-components";

export default function Affected({ userEvent }: { userEvent: EventItem }) {
  const { t } = useTranslation("userActivity");
  return (
    <Box className="MuiTypography-root MuiTypography-body1">
      {label(userEvent, t)}
    </Box>
  );
}

const AffectedList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
`;

const getEmail = (event: Event): string => {
  if ("email" in event.data) {
    return event.data.email;
  }
  return "";
};

const sourceChanges = (event: SourceEvent, t) => {
  const source = sourceLabels[event.type];
  if (event.data.label) {
    return `${source} ${t("change.withLabel", { label: event.data.label })}`;
  }

  return source;
};

function label(
  eventItem: EventItem,
  t: TFunction<"userActivity">
): React.ReactNode {
  const { id, event } = eventItem;
  const { type } = event;

  switch (type) {
    case "login":
      return t(`change.login.${event.data.method}`);

    case "account_deleted":
    case "account_created":
    case "account_updated":
      return getEmail(event);

    case "source_created":
    case "source_deleted":
    case "source_updated":
      return sourceChanges(event, t);

    case "storage_account_created":
      return storageAccountCreated(event, t);

    case "storage_account_deleted":
      return storageAccountDeleted(event, t);

    case "storage_settings_updated":
      return storageSettingsUpdated(event, t);

    case "organization_updated":
      return organizationUpdated(event, t);

    case "domain_deleted":
    case "domain_created":
    case "domain_updated":
      return event.data.domain;

    case "security_updated":
      return securityUpdated(event, t, id);

    case "user_updated":
      return userUpdated(event, t);

    case "user_created":
    case "user_deleted":
    case "reset_password":
      return getEmail(event);

    case "security_policy_updated":
    case "template_definition_updated":
      return `${t("change.withName")} ${event.data.updated.name}`;

    case "template_definition_created":
    case "template_definition_deleted":
    case "security_definition_created":
    case "security_definition_deleted":
    case "template_policy_created":
    case "template_policy_updated":
    case "template_policy_deleted":
    case "security_policy_created":
    case "security_policy_deleted":
    case "variable_created":
    case "variable_deleted":
    case "variable_updated":
    case "dataset_created":
    case "dataset_deleted":
    case "dataset_updated":
    case "rule_created":
    case "rule_deleted":
    case "rule_updated":
    case "rule_enabled":
    case "rule_disabled":
    case "copy_policy_created":
    case "copy_policy_deleted":
    case "copy_policy_updated":
    case "group_deleted":
      return `${t("change.withName")} ${event.data.name}`;

    default:
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled event type: ${exhaustiveCheck}`);
  }
}

function storageSettingsUpdated(event: StorageEvent, t) {
  const { type } = event.data;

  if (type === "readable_text") {
    return t(`change.readable_text.${event.data.change}`);
  }

  return t(`change.${type}`);
}

function storageAccountDeleted(event: StorageAccountEvent, t) {
  const { user, type } = event.data;
  return t("change.storageAccount", {
    user,
    storage: cloudStorageOptions[type].label,
  });
}

function storageAccountCreated(event: StorageAccountCreatedEvent, t) {
  const { user, type } = event.data;
  if (event.data.type === "DROPBOX" && user.type === "business") {
    return t("change.dropbox_business_storageAccount", { user });
  }

  return t("change.storageAccount", {
    user,
    storage: cloudStorageOptions[type].label,
  });
}

function securityUpdated(event: SecurityEvent, t, id) {
  let samlUpdated = false;
  const updates = event.data.updates.reduce((changes, { option, change }) => {
    if (change === "turn_on" || change === "turn_off") {
      changes.push(
        <li key={`${id}-${option}-${change}`}>
          {t(`change.security.${option}`)} {t(`change.security.${change}`)}
        </li>
      );
    } else if (!samlUpdated) {
      // eslint-disable-next-line no-param-reassign
      samlUpdated = true;
      changes.push(<li>{t(`change.security.saml_changed`)}</li>);
    }

    return changes;
  }, []);

  return <AffectedList>{updates}</AffectedList>;
}

function userUpdated(event: UserUpdatedEvent, t) {
  const { type, email } = event.data;
  return t(`change.user_updated.${type}`, { email });
}

function organizationUpdated(event: OrganizationEvent, t) {
  const { previous, updates } = event.data;

  if ("allowedTransportAgent" in updates) {
    return (
      <>
        <Typography>
          {t("common:previous")}: {previous.allowedTransportAgent}
        </Typography>
        <Typography>
          {t("common:new")}: {updates.allowedTransportAgent}
        </Typography>
      </>
    );
  }

  return "";
}
