import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { AuthType } from "utils/useMailboxAuthPopup";
import EgnyteTenantModal from "./EgnyteTenantModal";
import { MailboxProvider, handleStorageAccount, getErrorMsg } from "./helpers";
import { StorageAccount, StorageProviderWithAuth } from "@dashboard-v3/api";
import storageProviders from "utils/storageProviders";
// import { getUser } from "utils/authentication";

type Props = {
  addStorageAccount: (account: StorageAccount) => void;
  addMailboxAccount: (provider: MailboxProvider, authType: AuthType) => void;
};

export default function AddAccountButton({
  addStorageAccount,
  addMailboxAccount,
}: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [egnyteModal, setEgnyteModal] = useState(false);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onStorageSelect = async event => {
    const provider = event.target.id as StorageProviderWithAuth;
    handleClose(event);

    if (provider === "EGNYTE_GOV") return setEgnyteModal(true);

    handleStorageAccount(provider, {
      onSuccess(account: StorageAccount) {
        addStorageAccount(account);
      },
      onError(error: unknown, isCancel: boolean) {
        if (!isCancel) {
          enqueueSnackbar(getErrorMsg(error, t), {
            variant: "error",
            resumeHideDuration: 5000,
          });
        }
      },
    });
  };

  const onMailboxSelect = event => {
    const id = event.target.id as MailboxProvider;
    handleClose(event);
    if (id === MailboxProvider.Google) {
      return addMailboxAccount(MailboxProvider.Google, "google/individual");
    }
    if (id === MailboxProvider.Office) {
      return addMailboxAccount(MailboxProvider.Office, "office365/individual");
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disableElevation
        data-testid="storage__add-account-menu-btn"
        onClick={handleClick}
      >
        {t("add")}
      </Button>
      <Menu
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={handleClose}
      >
        {Object.values(MailboxProvider).map(provider => (
          <MenuItem
            key={provider}
            data-testid="storage__add-storage-account-btn"
            id={provider}
            onClick={onMailboxSelect}
          >
            {t(`common:integration.provider.${provider}`)}
          </MenuItem>
        ))}
        {storageProviders.map(provider => (
          <MenuItem
            key={provider}
            data-testid="storage__add-storage-account-btn"
            id={provider}
            onClick={onStorageSelect}
          >
            {t(`common:integration.provider.${provider}`)}
          </MenuItem>
        ))}
      </Menu>
      {egnyteModal && (
        <EgnyteTenantModal
          isOpen={egnyteModal}
          onClose={() => setEgnyteModal(false)}
        />
      )}
    </>
  );
}
