import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Box,
  Divider,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ChipInput from "material-ui-chip-input";
import SwitchField from "components/Forms/SwitchField";
import SectionHeader from "components/SectionHeader";
import ButtonWithLoading from "components/ButtonWithLoading";
import WithIconHelper from "components/WithIconHelper";
import apiRequest from "utils/apiRequestWithErrorCode";
import GatewayHeaders, { prohibitedName } from "./GatewayHeaders";
import O365Gateway from "./O365Gateway";
import TransportAgents from "./TransportAgents";

import { GatewayHeader, ResponseOrganization } from "@dashboard-v3/api";

type HeadersWithId = GatewayHeader & { id: string };
export type Office365Gateway = ResponseOrganization["office365Gateway"];

type Form = {
  gateway: {
    allowedTransportAgent: string;
    allowedIpMasks: string[];
    headers: HeadersWithId[];
  };
  newBackEnd: boolean;
};

export default function Gateway() {
  const { t } = useTranslation("gateway");
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<Form>(defaultState);
  const [office365Gateway, setOffice365Gateway] = useState<Office365Gateway>();
  const [state, setState] = useState<
    "loading" | "idle" | "errorOnLoading" | "saving"
  >("loading");

  useEffect(() => {
    async function fetchOrganization() {
      try {
        const organization = await apiRequest<ResponseOrganization>(
          "GET",
          "/organization"
        );
        setStateFromOrganization(organization);
        setOffice365Gateway(organization.office365Gateway);
        setState("idle");
      } catch (error) {
        console.error(error);
        setState("errorOnLoading");
      }
    }

    fetchOrganization();
  }, []);

  function setStateFromOrganization(organization) {
    const { gateway, newBackEnd } = organization;

    setForm({
      ...defaultState,
      gateway: {
        ...gateway,
        headers: gateway.headers ? headersWithId(gateway.headers) : [],
      },
      newBackEnd,
    });
  }

  const handleGatewayHeaders = newHeaders => {
    setForm(current => {
      const updated = { ...current };
      updated.gateway.headers = newHeaders;
      return updated;
    });
  };

  async function saveChanges(event) {
    event.preventDefault();
    setState("saving");

    try {
      const organization = await apiRequest("PATCH", "/organization", {
        ...form,
        gateway: {
          headers: validHeaders(form.gateway.headers),
          allowedIpMasks: form.gateway.allowedIpMasks,
        },
      });
      setStateFromOrganization(organization);
      enqueueSnackbar(t("savedOk"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("savedFail"), { variant: "error" });
    } finally {
      setState("idle");
    }
  }

  if (state === "errorOnLoading") {
    return (
      <>
        <SectionHeader title="Gateway" />
        <Paper style={{ padding: "12px 16px" }}>
          <Box p={2}>
            <Typography>{t("fetchingOrgError")}</Typography>
          </Box>
        </Paper>
      </>
    );
  }

  if (state === "loading") {
    return (
      <>
        <SectionHeader title="Gateway" />
        <Paper style={{ padding: "12px 16px" }}>
          <LinearProgress />
        </Paper>
      </>
    );
  }

  return (
    <>
      <SectionHeader title={t("mainLabel")} />
      <form onSubmit={saveChanges}>
        <Box component={Paper} py={1} px={2}>
          <Box marginTop={3}>
            <Typography variant="subtitle2">
              {t("transportAgentLabel")}
            </Typography>
            <Box my={2}>
              <TransportAgents
                gateway={form.gateway}
                onChange={org => {
                  setStateFromOrganization(org);
                }}
              />
              <WithIconHelper
                position="after"
                text={t("common:chipInputHelper")}
                icon={<InfoOutlinedIcon fontSize="small" color="primary" />}
                buttonStyles={{ marginLeft: "10px" }}
              >
                <ChipInput
                  fullWidth
                  color="primary"
                  label={t("ipMaskLabel")}
                  variant="outlined"
                  defaultValue={form.gateway.allowedIpMasks}
                  onChange={values => {
                    setForm(current => {
                      const updated = { ...current };
                      updated.gateway.allowedIpMasks = values;
                      return updated;
                    });
                    return false;
                  }}
                />
              </WithIconHelper>

              <Divider style={{ margin: "25px 0 20px" }} />

              <GatewayHeaders
                headers={form.gateway.headers}
                onChange={handleGatewayHeaders}
              />

              <Divider style={{ margin: "25px 0 20px" }} />

              <div style={{ maxWidth: "70%" }}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("o365Label")}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  {t("O365Gateway.description")}
                </Typography>
              </div>

              <O365Gateway gateway={office365Gateway} />

              <Divider style={{ margin: "25px 0 20px" }} />

              <SwitchField
                switchProps={{ color: "primary" }}
                label={t("newBackEnd.label")}
                description={t("newBackEnd.description")}
                value={form.newBackEnd}
                onChange={e => {
                  const { checked } = e.target;
                  setForm(prev => ({ ...prev, newBackEnd: checked }));
                }}
              />
            </Box>
          </Box>

          <Box mt={3} mb={3}>
            <ButtonWithLoading
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {}}
              type="submit"
              loading={state === "saving"}
            >
              {t("saveBtnLabel")}
            </ButtonWithLoading>
          </Box>
        </Box>
      </form>
    </>
  );
}

function headersWithId(headers) {
  return headers.map(header => ({
    ...header,
    // id: nextId(),
  }));
}

function validHeaders(headers) {
  const headersWithoutId = headers.map(({ id, ...rest }) => ({
    ...rest,
  }));

  return headersWithoutId.filter(header => !prohibitedName(header.name));
}

const defaultState = () => {
  return {
    gateway: {
      allowedTransportAgent: "",
      allowedIpMasks: [],
      headers: [],
    },
    newBackEnd: false,
  };
};
