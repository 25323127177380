import { Container } from "@material-ui/core";
import styled from "styled-components";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PlatformVariablesLoader from "components/PlatformVariablesLoader";

export default function DashboardLayout({ children }) {
  return (
    <div style={{ display: "flex" }}>
      <Header />
      <Sidebar />
      <PlatformVariablesLoader>
        <StyledContainer maxWidth={false}>{children}</StyledContainer>
      </PlatformVariablesLoader>
    </div>
  );
}

export const StyledContainer = styled(Container)`
  margin-top: 64px;
  padding: 30px 40px;
`;
