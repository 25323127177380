import { Source } from "@dashboard-v3/api";
import { useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import * as api from "./api";
import { getSourceType } from "./SourceList/helpers";

function loadedTypes(
  sources: Source[]
): Array<Source["configuration"]["type"]> {
  const types = new Set(sources.map(getSourceType));
  return Array.from(types);
}

export type SourceState = {
  isLoading: boolean;
  items: Source[];
  typesAlreadyLoaded: Array<Source["configuration"]["type"]>;
  warnings: Source["synchronization"]["warnings"];
  showWarnings: boolean;
};

export const initState: SourceState = {
  isLoading: true,
  items: [],
  typesAlreadyLoaded: [],
  warnings: null,
  showWarnings: false,
};

export function useActions(
  state: SourceState,
  setState: Dispatch<SetStateAction<SourceState>>,
  showError: (string) => void
) {
  const { t } = useTranslation("accounts");

  useEffect(() => {
    async function loadSources() {
      try {
        setState(current => ({ ...current, isLoading: true }));
        const response = await api.fetchSources();
        setState(current => ({
          ...current,
          isLoading: false,
          items: response,
          typesAlreadyLoaded: loadedTypes(response),
        }));
      } catch (error) {
        showError(t("error.fetchSources"));
        setState(current => ({ ...current, isLoading: false, items: [] }));
      }
    }

    loadSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateSource(source: Source) {
    setState(current => {
      const index = current.items.findIndex(item => item.id === source.id);
      const copy = [...current.items];
      copy.splice(index, 1, source);
      return { ...current, items: copy };
    });
  }

  async function deleteSource(sourceId: string) {
    try {
      await api.deleteSource(sourceId);
      setState(current => {
        const index = current.items.findIndex(item => item.id === sourceId);
        const copy = [...current.items];
        copy.splice(index, 1);
        return {
          ...current,
          items: copy,
          typesAlreadyLoaded: loadedTypes(copy),
        };
      });
      return true;
    } catch (error) {
      const message = t("error.delete", {
        type: t("source").toLowerCase(),
      });
      showError(message);
      return false;
    }
  }

  function addSource(source: Source) {
    setState(current => {
      const updatedItems = [...state.items, source];

      return {
        ...current,
        items: updatedItems,
        typesAlreadyLoaded: loadedTypes(updatedItems),
      };
    });
  }

  function showWarnings(warnings: Source["synchronization"]["warnings"]) {
    setState(current => ({ ...current, warnings, showWarnings: true }));
  }

  function closeWarnings() {
    setState(current => ({ ...current, warnings: null, showWarnings: false }));
  }

  return { showWarnings, closeWarnings, updateSource, deleteSource, addSource };
}
