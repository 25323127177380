import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tooltip, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

interface Props {
  titleKey: string;
}

export default function RuleStatsItemHeader({ titleKey }: Props) {
  const { t } = useTranslation("rules");

  const withIcon =
    titleKey === "messagesFiltered" || titleKey === "emailsDiscarded";

  if (!withIcon) {
    return (
      <Typography variant="caption" color="textSecondary">
        {t(`mailboxStats.${titleKey}`)}
      </Typography>
    );
  }

  return (
    <HeaderWithTooltip>
      <Tooltip title={t(`mailboxStats.headerTooltip.${titleKey}`)}>
        <InfoOutlinedIcon color="primary" fontSize="small" />
      </Tooltip>
      <Typography variant="caption" color="textSecondary">
        {t(`mailboxStats.${titleKey}`)}
      </Typography>
    </HeaderWithTooltip>
  );
}

const HeaderWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 2px;
  }
`;
