import { TimePeriodStat } from "@dashboard-v3/api";
import reduce90Days, { StatCode } from "../reduce90Days";

type Totals = ReturnType<typeof emptyResults>;

export type FilesSumData = Array<{ date: number } & Totals>;

export function parseData(data: Array<TimePeriodStat>): FilesSumData {
  return reduce90Days<Totals>(
    data,
    (totals, key, valueOfKey) => {
      const uploadedType = getUploadedType(key);
      if (uploadedType) {
        totals[uploadedType] += valueOfKey;
        totals.all += valueOfKey;
      }
      return totals;
    },
    emptyResults
  );
}

function emptyResults() {
  return {
    all: 0,
    attachments: 0,
    bodies: 0,
    emails: 0,
  };
}

type UploadedType = Exclude<keyof Totals, "all">;

function getUploadedType(key: StatCode): UploadedType | null {
  if (key.startsWith("storage_uploaded_emails_")) return "emails";
  if (key.startsWith("storage_uploaded_bodies_")) return "bodies";
  if (key.startsWith("storage_uploaded_attachments_")) return "attachments";

  return null;
}
