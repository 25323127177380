import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest from "utils/apiRequestWithErrorCode";
import { RegexTestButtonWrapper } from "../Styled";

import { TestRegexRequest, TestRegexResponse } from "@dashboard-v3/api";

interface TestButtonProps {
  fields: TestRegexRequest;
  test: TestRegexResponse;
  onClick: (response: TestRegexResponse) => void;
  onError: (error: unknown) => void;
}

export default function TestButton({
  fields,
  test,
  onClick,
  onError,
}: TestButtonProps) {
  const { t } = useTranslation("variables");
  const [loading, setLoading] = useState<boolean>(false);

  const handleRegexTest = async () => {
    setLoading(true);
    try {
      const testResult = await apiRequest<TestRegexResponse>(
        "POST",
        `/variables/regex-test`,
        fields
      );
      onClick(testResult);
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegexTestButtonWrapper>
      <ButtonWithLoading
        variant="contained"
        size="large"
        disableElevation
        loading={loading}
        onClick={handleRegexTest}
      >
        {t("regexTester.buttons.test")}
      </ButtonWithLoading>
      {test && (
        <>
          {!test.result && (
            <>
              <ClearIcon fontSize="small" htmlColor="red" />
              <Typography variant="body2">
                {t("regexTester.buttons.noMatch")}
              </Typography>
            </>
          )}

          {test.result && (
            <>
              <CheckIcon fontSize="small" htmlColor="green" />
              <Typography variant="body2">
                {t("regexTester.buttons.match", {
                  count: test.groups.length,
                })}
              </Typography>
            </>
          )}
        </>
      )}
    </RegexTestButtonWrapper>
  );
}
