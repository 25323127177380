import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function useClipboard() {
  const { t } = useTranslation("components");
  const { enqueueSnackbar } = useSnackbar();

  return async function copyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      enqueueSnackbar(t("clipboard.success"), { variant: "success" });
    } catch (err) {
      enqueueSnackbar(t("clipboard.error"), {
        variant: "error",
      });
    }
  };
}
