import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import {
  Checkbox,
  InputBaseComponentProps,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import PageLoading from "components/PageLoading";
import { addUserInSession, getOriginPathInSession } from "utils/authentication";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import { isNumber } from "utils/string";
import { validate2FACode } from "./api";
import * as Sentry from "@sentry/react";
import { AUTH_CODE_EXPIRATION_MINUTES } from "@dashboard-v3/shared";

interface Props {
  email: string;
}

type FormState = {
  code: string[];
  persistAccess: boolean;
};

const initFormState = (): FormState => ({
  code: [...Array(6)].map(() => ""),
  persistAccess: false,
});

const isBackspace = keyCode => keyCode === 8;

export default function AuthCodeForm({ email }: Props) {
  const { t } = useTranslation("login");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [form, setForm] = useState<FormState>(initFormState);
  const codeInputs = useRef([]);
  const classes = useStyles();
  const pathname = getOriginPathInSession() || "/";
  const codeInputProps: InputBaseComponentProps = {
    style: { textAlign: "center" },
    maxLength: 1,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const expiration = window.sessionStorage.getItem("2FA-EXPIRATION");
      const diff = dayjs().diff(dayjs.unix(Number(expiration)), "minutes");

      if (diff > AUTH_CODE_EXPIRATION_MINUTES) {
        setHasFailed(true);
        window.sessionStorage.removeItem("2FA-EXPIRATION");
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  const onCodeChange = (i: number) => e => {
    const { value } = e.target;
    if (value && !isNumber(value)) return;
    const code = [...form.code];
    code[i] = value;
    setForm(prev => ({ ...prev, code }));
    if (i !== 5 && !!value) codeInputs.current[i + 1].focus();
  };

  const onCodeKeyUp = (i: number) => e => {
    if (isBackspace(e.keyCode) && !form.code[i] && i !== 0) {
      const code = [...form.code];
      code[i] = "";
      setForm(prev => ({ ...prev, code }));
      const currentInput = codeInputs.current[i - 1];
      currentInput.focus();
      currentInput.select();
    }
  };

  const onCodePaste = e => {
    e.preventDefault();
    const { clipboardData } = e;
    const pastedData = clipboardData.getData("text/plain");
    const code = pastedData.split("");
    if (!isNumber(pastedData) || code.length < 6) return;

    setForm(prev => ({ ...prev, code }));
    codeInputs.current[5].focus();
  };

  const onSubmitError = (error: unknown) => {
    const code = getErrorCode(error);

    switch (code) {
      case "INVALID_CODE":
        enqueueSnackbar(t("2FAuth.error.invalid"), { variant: "error" });
        break;
      case "RETRIES_EXHAUSTED":
        setHasFailed(true);
        break;
      default:
        enqueueSnackbar(t("2FAuth.error.unknown"), { variant: "error" });
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const { code, persistAccess } = form;
    const body = {
      email,
      persistAccess,
      code: code.join(""),
    };
    setLoading(true);
    try {
      const { user, sessionDuration } = await validate2FACode(body);
      addUserInSession(user, sessionDuration);
      history.push(pathname);
    } catch (e) {
      Sentry.captureException(e, {
        user: { email },
        extra: { step: "Validate 2FA code", code: body.code },
      });
      onSubmitError(e);
    } finally {
      setLoading(false);
    }
  };

  if (hasFailed) {
    return (
      <div className={classes.failedContainer}>
        <PageLoading loading={loading} />
        <Typography
          className={classes.title}
          variant="subtitle2"
          align="center"
        >
          {t("2FAuth.failedVerification.title")}
        </Typography>

        <Typography
          className={classes.subtitle}
          variant="caption"
          align="center"
          color="textSecondary"
        >
          {t("2FAuth.failedVerification.subtitle")}
        </Typography>
        <Button
          variant="contained"
          wording={t("2FAuth.failedVerification.backToLogin")}
          onClick={() => window.location.reload()}
        />
      </div>
    );
  }

  return (
    <>
      <PageLoading loading={loading} />

      <form className={classes.content} onSubmit={onSubmit}>
        <Typography
          className={classes.title}
          variant="subtitle2"
          align="center"
        >
          {t("2FAuth.title")}
        </Typography>
        <Typography
          className={classes.subtitle}
          variant="caption"
          align="center"
          color="textSecondary"
        >
          {t("2FAuth.subtitle", { email })}
        </Typography>
        <div className={classes.codeInputContainer}>
          {form.code.map((digit, i) => (
            <TextField
              key={i}
              variant="outlined"
              name={`digit-${i}`}
              size="small"
              className={classes.codeInput}
              inputRef={ref => codeInputs.current.push(ref)}
              inputProps={codeInputProps}
              autoFocus={!form.code[0].length && i === 0}
              value={digit}
              onChange={onCodeChange(i)}
              onKeyUp={onCodeKeyUp(i)}
              onPaste={onCodePaste}
            />
          ))}
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            className={classes.checkbox}
            color="primary"
            size="small"
            checked={form.persistAccess}
            onChange={e =>
              setForm({ ...form, persistAccess: e.target.checked })
            }
            disableRipple
          />
          <div className={classes.checkboxTextContainer}>
            <Typography variant="subtitle2" display="inline">
              {t("2FAuth.saveCode.label")}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              display="inline"
            >
              {t("2FAuth.saveCode.description")}
            </Typography>
          </div>
        </div>

        <Button
          type="submit"
          variant="contained"
          wording={t("2FAuth.submit")}
          disabled={!form.code.every(d => !!d)}
        />
      </form>
    </>
  );
}

const useStyles = makeStyles({
  title: {
    marginBottom: "5px",
    fontSize: "1rem",
  },
  subtitle: {
    marginBottom: "10px",
  },
  content: {
    display: "grid",
    width: "100%",
    maxWidth: "min-content",
  },
  codeInputContainer: {
    width: "100%",
    display: "flex",
    gap: "20px",
    justifyContent: "center",
  },
  failedContainer: {
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
  },
  codeInput: {
    marginBottom: "18px",
    width: "50px",
  },
  checkbox: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkedbox: {
    backgroundColor: "transparent",
  },
  checkboxContainer: {
    display: "flex",
    background: "rgb(238, 242, 246)",
    gap: "3px",
    marginBottom: "18px",
    padding: "8px 8px 8px 5px",
    borderRadius: "8px",
  },
  checkboxTextContainer: { display: "flex", flexDirection: "column" },
});
