import { Box, Button, TextField, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import ButtonWithLoading from "components/ButtonWithLoading";
import * as api from "./api";
import { useEffect, useRef, useState } from "react";
import useClipboard from "utils/useClipboard";
import { DKIMRecord } from "@dashboard-v3/api";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";

type Props = {
  record: DKIMRecord;
  onVerifyResult: (record: DKIMRecord) => void;
};

type Status = "IDLE" | "VERIFYING" | "VERIFY_FAIL" | "VERIFY_SUCCESS";

export default function Form({ record, onVerifyResult }: Props) {
  const { t } = useTranslation("orgAndDomains");
  const [foundInDns, setFoundInDns] = useState<boolean>();
  const [status, setStatus] = useState<Status>("IDLE");

  const { enqueueSnackbar } = useSnackbar();

  const verifyStatusRef = useRef(status);
  verifyStatusRef.current = status;

  useEffect(() => {
    function handleErrorFadeOut(verifyState) {
      let timeId;
      if (verifyState === "VERIFY_FAIL") {
        clearTimeout(timeId);
        timeId = setTimeout(() => {
          if (verifyStatusRef.current === "VERIFY_FAIL") setStatus("IDLE");
        }, 5000);
      } else {
        clearTimeout(timeId);
      }

      return () => clearTimeout(timeId);
    }

    handleErrorFadeOut(status);
  }, [status]);

  async function verifyDns() {
    try {
      setStatus("VERIFYING");
      const response = await api.verifyDns(record.id);
      onVerifyResult(response.result);
      setFoundInDns(response.foundInDns);
      setStatus(response.result.isVerified ? "VERIFY_SUCCESS" : "VERIFY_FAIL");
    } catch (error) {
      setStatus("IDLE");
      enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
      Sentry.captureException(error);
    }
  }

  return (
    <Box alignItems="baseline">
      {!record.isVerified && (
        <Box mb={3}>
          <Typography>
            <Trans i18nKey="dkim.explain.title" t={t}>
              Create a <strong>TXT record</strong> in your DNS with the
              following:
            </Trans>
          </Typography>
        </Box>
      )}
      <>
        <DnsEntryName value={`${record.selector}._domainkey`} />
        <Box mt={3}>
          <DnsEntryValue value={record.dnsValue} />
        </Box>
      </>
      {!record.isVerified && (
        <>
          <Box mt={3} maxWidth="600px">
            <Alert severity="info">{t("dkim.explain.info")}</Alert>
          </Box>
          <Box mt={3} display="flex" gridGap="15px" alignItems="center">
            <ButtonWithLoading
              type="button"
              variant="contained"
              color="primary"
              disableElevation
              loading={status === "VERIFYING"}
              onClick={verifyDns}
            >
              {t("dkim.form.verify")}
            </ButtonWithLoading>

            {status === "VERIFY_FAIL" && (
              <Box display="flex" flexDirection="column">
                <Typography color="error">
                  {foundInDns
                    ? t("dkim.validationWrongValue")
                    : t("dkim.validationFails")}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

function DnsEntryValue({ value }: { value: string }) {
  const { t } = useTranslation("orgAndDomains");
  const copyToClipboard = useClipboard();

  return (
    <>
      <Typography>{t("dkim.explain.step2")}</Typography>
      <Box mt={1} display="flex">
        <TextField
          multiline
          minRows={4}
          style={{ minWidth: "600px" }}
          variant="outlined"
          aria-readonly="true"
          value={value}
          InputProps={{ readOnly: true }}
        ></TextField>
        <Box ml={2}>
          <Button
            variant="text"
            color="primary"
            onClick={() => copyToClipboard(value)}
          >
            {t("manualDomains.copyTxt")}
          </Button>
        </Box>
      </Box>
    </>
  );
}

function DnsEntryName({ value }: { value: string }) {
  const { t } = useTranslation("orgAndDomains");
  const copyToClipboard = useClipboard();

  return (
    <Box>
      <Typography>{t("dkim.explain.step1")}</Typography>
      <Box mt={1} display="flex" gridGap="15px">
        <TextField
          style={{ minWidth: "600px" }}
          variant="outlined"
          aria-readonly="true"
          value={value}
          InputProps={{ readOnly: true }}
        ></TextField>
        <Button
          variant="text"
          color="primary"
          onClick={() => copyToClipboard(value)}
        >
          {t("manualDomains.copyTxt")}
        </Button>
      </Box>
    </Box>
  );
}
