import { useTranslation, Trans } from "react-i18next";
import { LinearProgress, Paper, Typography } from "@material-ui/core";
import { ShowMoreBtn } from "components/List";
import Search from "components/AccountSearch";
import Item from "./Item";
import { Li, MainPanel, StyledList } from "./Styled";
import AddSourceBtn from "../Buttons/AddSource";
import { useGroupsState } from "../groupsContext";

export default function GroupList() {
  const { t } = useTranslation("accounts");
  const { state, actions } = useGroupsState();
  const { items, status, hasMoreItems, deletingId } = state;

  return (
    <StyledList disablePadding data-testid="accounts__group-list">
      <li>
        <Search
          placeholder={t("groups.searchPlaceholder")}
          onClear={actions.search}
          onSearch={actions.search}
        />
      </li>

      {status === "loading" && (
        <Li>
          <MainPanel data-testid="accounts__group-list_loading">
            <LinearProgress />
          </MainPanel>
        </Li>
      )}

      {status === "show groups" && items.length === 0 && (
        <Li>
          <MainPanel data-testid="accounts__group-list-panel">
            <Typography style={{ marginBottom: "8px" }}>
              {t("groups.emptyGroupList")}
            </Typography>
            <Typography>
              <Trans i18nKey="groups.howToAddGroups" t={t}>
                You can <AddSourceBtn omitOptions={[]} /> to import groups.
              </Trans>
            </Typography>
          </MainPanel>
        </Li>
      )}

      {status === "show search result" && items.length === 0 && (
        <Li>
          <Paper style={{ padding: "1rem" }}>{t("noSearchResult")}</Paper>
        </Li>
      )}

      {status !== "loading" && (
        <>
          {items.map(group => {
            return (
              <Item
                key={group.id}
                group={group}
                deleting={status === "deleting" && deletingId === group.id}
                onDelete={actions.deleteGroup}
              />
            );
          })}

          {hasMoreItems ? (
            <div style={{ marginTop: "10px" }}>
              <ShowMoreBtn
                loading={status === "fetching more"}
                onClick={actions.loadMore}
              />
            </div>
          ) : null}
        </>
      )}
    </StyledList>
  );
}
