import { Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TimeRangeUnits } from "@dashboard-v3/api";

type Props = {
  value: TimeRangeUnits;
  disabled?: boolean;
  onChange: (newValue: TimeRangeUnits) => void;
};

export default function RepeateUpToDateConfig({
  value,
  disabled,
  onChange,
}: Props) {
  const { t } = useTranslation("rules");
  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item>
        <TextField
          type="number"
          variant="outlined"
          style={{ width: "6em" }}
          InputProps={{ inputProps: { min: 1 } }}
          disabled={disabled}
          value={value.unit}
          onChange={event => {
            onChange({ ...value, unit: Number(event.target.value) });
          }}
        />
      </Grid>
      <Grid item>
        <Select
          variant="outlined"
          disabled={disabled}
          value={value.timeUnit}
          onChange={event => {
            const timeUnit = event.target.value as TimeRangeUnits["timeUnit"];
            onChange({ ...value, timeUnit });
          }}
        >
          {["DAY", "MONTH", "YEAR"].map(timeUnit => (
            <MenuItem key={timeUnit} value={timeUnit}>
              {t(`beforeUnits.${timeUnit}`, { count: value.unit })}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
