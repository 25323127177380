import { Suspense } from "react";
import Routes, { Loader } from "Routes";
import MxHeroThemeProvider from "theme/MxHeroThemeProvider";
import * as Sentry from "@sentry/react";
import ErrorFallback from "components/ErrorFallback";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

export default function App() {
  return (
    // Suspense is required to use locize (see the i18n.js file)
    <Suspense fallback={<Loader />}>
      <MxHeroThemeProvider>
        <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
          <Routes />
        </Sentry.ErrorBoundary>
      </MxHeroThemeProvider>
    </Suspense>
  );
}
