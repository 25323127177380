import { Source } from "@dashboard-v3/api";
import { SourceLoading } from "./types";

export const sourceLabels = {
  GAPPS: "Google Apps",
  OFFICE365: "Office 365",
  LDAP: "LDAP",
};

export const getSourceType = (source: Source) => source.configuration.type;

export const hasWarnings = source => {
  const { accounts, groups, domains } = source.synchronization.warnings || {
    accounts: [],
    groups: [],
    domains: [],
  };
  return accounts.length > 0 || groups.length > 0 || domains.length > 0;
};

export const isOfficeUsersError = (source: Source) => {
  const { configuration: config, synchronization: sync } = source;
  if (config.type === "OFFICE365" && sync.errorDetails) {
    return sync.errorDetails.startsWith(
      "error_reading_users_azuread for accessToken"
    );
  }
  return false;
};

export const tsToDateStr = (timestamp: number) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return new Date(timestamp).toLocaleString(undefined, dateOptions);
};

export const getRunDates = (source: Source) => {
  if (source.synchronization) {
    const { synchronization: sync } = source;
    const runs: Array<{ label: string; date: number }> = [];

    if (sync.lastRun) {
      runs[0] = { label: "sources.lastUpdate", date: sync.lastRun };
    }
    if (sync.nextRun) {
      runs.push({ label: "sources.nextUpdate", date: sync.nextRun });
    }
    return runs;
  }
  return [];
};

export const initSourceLoading = (): SourceLoading => ({
  refresh: false,
  delete: false,
});

export const updateSourceLoading =
  (type: "refresh" | "delete", value: boolean) => (prev: SourceLoading) => {
    return { ...prev, [type]: value };
  };
