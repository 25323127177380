/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "@material-ui/core";
import { FieldRow } from "components/Forms/StyledComponents";
import { ConditionByType, conditionsByType } from "../../common/options";
import { useConditionsCtx } from "../../state/ConditionsProvider";
import OpenAIContext from "./OpenAIContext";
import { AndIndex } from "pages/Variables/types";
import { isMapCondition } from "pages/Variables/helpers";
import ConditionValue from "./ConditionValue";
import SelectConditionType from "./SelectConditionType";
import SelectDataSet from "./SelectDataSet";
import { Skeleton } from "@material-ui/lab";
import { andCondition, isReadOnly } from "../read";
import useSWR from "swr";
import { Dataset } from "@dashboard-v3/api";
import { fetcher } from "utils/apiRequestWithErrorCode";
import { cancelAiQuestion, changeAndCondition } from "../actions";

export default function Conditions({ index }: { index: AndIndex }) {
  const { t } = useTranslation("variables");
  const { variable, updateVariable } = useConditionsCtx();
  const { type, sourceType } = andCondition(variable, index);

  const isAIQuestion = type === "AI_QUESTION";

  const { data: datasets, isLoading } = useSWR<Dataset[]>(
    isMapCondition(type) ? "/datasets?limit=1000" : null,
    fetcher,
    {
      revalidateOnFocus: false,
      suspense: false,
    }
  );

  if (isLoading) return <Loading />;

  const handleType = e => {
    const { value }: { value: ConditionByType["value"] } = e.target;
    changeAndCondition(updateVariable, index, value);
  };

  return (
    <>
      <FieldRow $wrap={isAIQuestion}>
        {type !== "AI_QUESTION" && (
          <SelectConditionType
            options={conditionsByType[sourceType]}
            value={type}
            onChange={handleType}
            isReadOnly={isReadOnly(variable)}
          />
        )}
        {isMapCondition(type) ? (
          <SelectDataSet options={datasets} index={index} />
        ) : (
          <ConditionValue index={index} />
        )}
      </FieldRow>

      {isAIQuestion && (
        <>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => cancelAiQuestion(updateVariable, index)}
            >
              {t("form.condition.iaCancelBtn")}
            </Button>
          </Box>
          <OpenAIContext />
        </>
      )}
    </>
  );
}

function Loading() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Skeleton height="55px" style={{ transform: "none" }} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height="55px" style={{ transform: "none" }} />
      </Grid>
    </Grid>
  );
}
