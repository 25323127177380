import { Box, Typography, makeStyles } from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface MessageProps {
  isDisabled: boolean;
}

export function EmptyListMessage({ isDisabled }: MessageProps) {
  const { t } = useTranslation("storageShares");
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box my={2}>
      <Box mb={2}>
        <Typography className={classes.msg} variant="caption">
          {isDisabled ? t("list.disabledForOrg") : t("list.emptyMessage")}
        </Typography>
      </Box>
      {isDisabled && (
        <div className={classes.btnContainer}>
          <Button
            size="small"
            wording={t("Go to integrations")}
            onClick={() => history.push("/integrations")}
          />
        </div>
      )}
    </Box>
  );
}

const useStyles = makeStyles({
  msg: { fontSize: 15 },
  btnContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  },
});
