import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import ButtonWithLoading from "components/ButtonWithLoading";
import { DialogContentWrapper, StyledDialogContent } from "../../Styled";

import { VerifyGappsResponse } from "@dashboard-v3/api";

type Props = {
  isOpen: boolean;
  loading: boolean;
  data: VerifyGappsResponse;
  onCancel: () => void;
  onConfirm: (customerId: string) => void;
};

export default function ConfirmModal(props: Props) {
  const { t } = useTranslation("storage");
  const { isOpen, loading, data, onCancel, onConfirm } = props;
  const { email, customerId, organizationName } = data;

  const orgNameOrDefault = () =>
    organizationName
      ? organizationName
      : t("gapps.verifyModal.organization.nameDefault");

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle disableTypography>
        <Typography variant="h6" gutterBottom>
          {t("gapps.verifyModal.title")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("gapps.verifyModal.subtitle")}
        </Typography>
      </DialogTitle>
      <StyledDialogContent>
        <DialogContentWrapper>
          <Typography variant="subtitle2">
            {t("gapps.verifyModal.user.title")}
          </Typography>
          <Typography variant="body2">
            {t("gapps.verifyModal.user.account")}
            <span>{email}</span>
          </Typography>
        </DialogContentWrapper>
        <DialogContentWrapper>
          <Typography variant="subtitle2">
            {t("gapps.verifyModal.organization.title")}
          </Typography>

          <Typography variant="body2">
            {t("gapps.verifyModal.organization.name")}
            <span>{orgNameOrDefault()}</span>
          </Typography>

          <Typography variant="body2">
            {t("gapps.verifyModal.organization.customerId")}
            <span>{customerId}</span>
          </Typography>
        </DialogContentWrapper>
      </StyledDialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          wording={t("common:cancel")}
          onClick={onCancel}
          disabled={loading}
        />
        <ButtonWithLoading
          variant="contained"
          size="small"
          disableElevation
          loading={loading}
          onClick={() => onConfirm(customerId)}
        >
          {t("common:confirm")}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
}
