import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { debounce } from "@material-ui/core";
import { useFetch } from "utils/api/useFetch";

interface SearchConfig<T extends any[], K> {
  url: string;
  searchParam: string;
  mapper: (res: T) => K[];
  error: string;
}

function useSearch<T extends any[], K>(config: SearchConfig<T, K>) {
  const { enqueueSnackbar } = useSnackbar();
  const { url, mapper, error, searchParam } = config;
  const [searchTerm, setSearchTerm] = useState<string>();

  const { data: results, isLoading } = useFetch<T>(
    searchTerm ? `${url}?${searchParam}=${searchTerm}` : null,
    {
      cacheIndividuals: true,
      onError() {
        enqueueSnackbar(error, {
          variant: "error",
        });
      },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getResults = useCallback(
    debounce(async (term: string) => {
      setSearchTerm(term || null);
    }, 500),
    []
  );

  return {
    getResults,
    resetSearch: () => setSearchTerm(null),
    results: searchTerm && results ? mapper(results) : [],
    loading: isLoading,
  };
}

export default useSearch;
