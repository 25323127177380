import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { EditState } from "../types";
import { toFormState } from "../policyMapper";
import Item from "components/List/Item";
import { useDependencyModal } from "components/DependencyModal";
import { deleteCopyPolicy } from "utils/api/copyPolicies";
import { CopyPolicy } from "@dashboard-v3/api";
import { Skeleton } from "@material-ui/lab";

interface Params {
  policy: CopyPolicy;
  onDelete: (policy: CopyPolicy) => void;
}

const PolicyItem = ({ policy, onDelete }: Params) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation("copyPolicy");
  const { errorHandler } = useDependencyModal();
  const history = useHistory();

  const handleEditBtn = () => {
    const data: EditState = {
      pathname: `/copy-policies/${policy.id}/edit`,
      state: {
        policyId: policy.id,
        selectedPolicy: toFormState(policy),
      },
    };
    history.push(data);
  };

  const deletePolicy = async () => {
    setIsDeleting(true);
    try {
      await deleteCopyPolicy(policy.id);
      onDelete(policy);
    } catch (error) {
      errorHandler(error, t("form.errors.deleteError"));
    } finally {
      setIsDeleting(false);
    }
  };
  /*
   */

  if (isDeleting) {
    return (
      <Skeleton key={policy.id} height="5rem" animation="wave" variant="rect" />
    );
  }

  return <Item item={policy} onEdit={handleEditBtn} onDelete={deletePolicy} />;
};

export default PolicyItem;
