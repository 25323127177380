/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonBase, Icon } from "@material-ui/core";
import styled from "styled-components";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";

const WhiteButton = styled(ButtonBase)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: grid;
  font-family: "Roboto", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  padding: 9px 22px;
  width: 100%;
`;

const OfficeLogo = styled(Icon)`
  margin-right: 11px;
`;

export const Office365Button = ({ children, onClick }) => {
  return (
    <Button
      color="primary"
      data-testid="landing__office365-btn"
      disableElevation
      fullWidth
      size="large"
      variant="contained"
      startIcon={
        <OfficeLogo style={{ fontSize: "16px" }} className="icon-windows8" />
      }
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const signinStartIcon = (
  <ExitToAppIcon style={{ marginRight: "5px", fontSize: "20px" }} />
);

export const SignInButton = ({ text = "", onClick }) => {
  const { t } = useTranslation("components");

  return (
    <Button
      color="primary"
      data-testid="login__signin-btn"
      disableElevation
      fullWidth
      onClick={onClick}
      size="large"
      startIcon={signinStartIcon}
      type="submit"
      variant="contained"
    >
      {text || t("landing.signIn")}
    </Button>
  );
};

const registerButtonStartIcon = (
  <AssignmentTurnedInOutlinedIcon
    style={{ marginRight: "5px", fontSize: "20px" }}
  />
);

export const RegisterButton = ({ onClick }) => {
  const { t } = useTranslation("components");

  return (
    <Button
      color="primary"
      data-testid="landing__register-btn"
      disableElevation
      fullWidth
      onClick={onClick}
      size="large"
      startIcon={registerButtonStartIcon}
      type="submit"
      variant="contained"
    >
      {t("landing.register")}
    </Button>
  );
};

export const SamlButton = ({ onClick }) => {
  const { t } = useTranslation("components");

  return (
    <WhiteButton data-testid="login__use-own-domain-btn" onClick={onClick}>
      {t("landing.useOwnDomain")}
    </WhiteButton>
  );
};
