import { LoginOptionNames } from "@dashboard-v3/api";
import apiRequest from "utils/apiRequestWithErrorCode";

export type FormFields = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  enablePassword: boolean;
  isSubmitting: boolean;
};

export const getLoginOptions = async () => {
  const options = await apiRequest<LoginOptionNames[]>("GET", `/login-options`);
  const types = Object.keys(options);
  const optionsArr = types.filter(opt => opt !== "twoFactorAuthEnabled");
  return optionsArr as LoginOptionNames[];
};

export const isPasswordExclusive = (loginOptions: LoginOptionNames[]) => {
  return loginOptions.length === 1 && loginOptions.includes("PASSWORD");
};
