import { Variable } from "@dashboard-v3/api";
import { AndIndex } from "../types";

export function orCondition(variable: Variable, index: AndIndex) {
  const [orIndex] = index;
  return variable.predicate.children[orIndex];
}

export function andCondition(variable: Variable, index: AndIndex) {
  const [orIndex, andIndex] = index;
  return variable.predicate.children[orIndex].children[andIndex];
}

export function isReadOnly(variable: Variable): boolean {
  return Boolean(variable?.platformVariable);
}
