/* eslint-disable  react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import produce from "immer";
import { capitalize, TextField, Typography } from "@material-ui/core";
import SwitchField from "components/Forms/SwitchField";
import SearchInput from "components/AutocompleteSearchInput";
import {
  AdvancedOptionsContainer,
  Label,
  OptionGroup,
} from "components/Styled";
import useVariables from "utils/useVariables";
import { isBlank } from "utils/string";
import {
  actionsByContentType,
  canCollaborate,
  getCurrentPathFromLocation,
  isValidPath,
  toParsedContent,
} from "./utils";
import CollaborateSubfolder, { Params } from "./CollaborateSubfolder";
import { StyledDetails, StyledSummary } from "./Styled";
import { getStorage } from "../CloudStorage/utils";
import { getContentToSave } from "../CreateOrEdit/context/helpers";
import { getActionState } from "../RuleActions/utils";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";

import { CollaboratorRole, Content } from "@dashboard-v3/api";
import { ControlField, ControlProps } from "./types";

enum SubfolderType {
  EMAIL = "emailSubfolder",
  ATTACHMENTS = "attachmentsSubfolder",
  BODY = "bodySubfolder",
}

type Error = "empty" | "invalid";

type FormatErrors = {
  email: Error | null;
  attachments: Error | null;
  pdf: Error | null;
};

const initErrors = () => ({
  email: null,
  attachments: null,
  pdf: null,
  emailSubfolder: null,
  attachmentsSubfolder: null,
  bodySubfolder: null,
});

const contentTypes = ["email", "attachments", "pdf"] as const;
type ContentType = (typeof contentTypes)[number];

const AdvancedOptions = ({ controls, handleControls }: ControlProps) => {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const [formatErrors, setFormatErrors] = useState<FormatErrors>(initErrors());
  const { variableOptions, mapVarToLabel, mapVarToValue } = useVariables(
    state.rule
  );
  const storage = getStorage(state.rule);
  const { content } = getContentToSave(state.rule);
  const action = getActionState(state);
  const parsedContent = toParsedContent(action.content);
  const currentPath = getCurrentPathFromLocation(storage?.location);
  const { position, role } = storage?.collaboration || {};
  const { fieldsWithError } = state.checks.storageFilename;

  useEffect(() => {
    if (fieldsWithError?.length) {
      fieldsWithError.forEach(field => {
        setFormatErrors(prev =>
          produce(prev, draft => {
            draft[field] = "invalid";
          })
        );
      });
    }
  }, [fieldsWithError]);

  const handleSubFolders = e => {
    const { name, value } = e.target;
    setFormatErrors(prev => ({ ...prev, [name]: null }));
    dispatch({
      type: "updateStep",
      payload: {
        step: "filePath",
        changes: { location: { [name]: value } },
      },
    });
  };

  const handleSubfolderControls =
    (control: ControlField, field: SubfolderType) => (_, checked: boolean) => {
      setFormatErrors(prev => ({ ...prev, [field]: null }));
      handleControls(control, checked);
    };

  const handleCollaboration: Params["onChange"] = (type, value) => {
    const { collaboration } = produce(storage, draft => {
      if (type === "position") {
        draft.collaboration.position = value as number;
      }
      if (type === "role") {
        draft.collaboration.role = value as CollaboratorRole;
      }
    });

    dispatch({
      type: "updateStep",
      payload: {
        step: "filePath",
        changes: { collaboration },
      },
    });
  };

  const handleOverride = e => {
    const { checked } = e.target;
    dispatch({
      type: "updateStep",
      payload: {
        step: "filePath",
        changes: { override: !checked },
      },
    });
  };

  const updateNameFormat = (type: ContentType) => (val: string) => {
    setFormatErrors(prev => ({ ...prev, [type]: null }));
    const { content } = produce(action, draft => {
      draft.content = draft.content.map(item => {
        const updateByType = actionsByContentType[type];
        if (updateByType(item)) {
          return { ...item, nameFormat: mapVarToValue(val, variableOptions) };
        }
        return item;
      });
    });

    dispatch({
      type: "updateStep",
      payload: { step: "action", changes: { content } },
    });
  };

  const handleNameFormatBlur = (type: ContentType) => e => {
    const { value } = e.target;
    if (isBlank(value)) {
      setFormatErrors(prev => ({ ...prev, [type]: "empty" }));
    }
  };

  const handleSubfoldersBlur = e => {
    const { name, value } = e.target;
    if (isBlank(value)) {
      setFormatErrors(prev => ({ ...prev, [name]: "empty" }));
    }
  };

  return (
    <AdvancedOptionsContainer>
      <StyledSummary>
        <Typography>{t("advancedOptions")}</Typography>
      </StyledSummary>
      <StyledDetails>
        {contentTypes.map(
          type =>
            parsedContent[type] && (
              <OptionGroup key={type}>
                <Label
                  gutterBottom
                  wording={t(`ruleAction.advanced.textFields.${type}`)}
                />
                <SearchInput
                  fullWidth
                  variables={variableOptions}
                  disabled={false}
                  value={mapVarToLabel(
                    parsedContent[type].nameFormat,
                    variableOptions
                  )}
                  onChange={updateNameFormat(type)}
                  onBlur={handleNameFormatBlur(type)}
                  error={Boolean(formatErrors[type])}
                  errorText={t(
                    `ruleAction.advanced.nameFormat.${formatErrors[type]}`
                  )}
                />
              </OptionGroup>
            )
        )}
        {subfolders.map(
          ({ field, control }) =>
            showSubfolder(field, content) && (
              <OptionGroup key={field}>
                <SwitchField
                  name={control}
                  label={t(`storageFilepath.${control}.label`)}
                  onChange={handleSubfolderControls(control, field)}
                  value={controls[control]}
                  style={{ marginBottom: "5px" }}
                />
                {controls[control] && (
                  <TextField
                    variant="outlined"
                    fullWidth
                    name={field}
                    label={t(`storageFilepath.${field}.label`)}
                    value={storage.location[field] || ""}
                    onChange={handleSubFolders}
                    onBlur={handleSubfoldersBlur}
                    error={Boolean(formatErrors[field])}
                    helperText={
                      Boolean(formatErrors[field]) &&
                      t(`storageFilepath.${field}.${formatErrors[field]}`)
                    }
                    required
                  />
                )}
              </OptionGroup>
            )
        )}
        {canCollaborate(state.rule) && (
          <OptionGroup>
            <SwitchField
              name="enableCollaborate"
              label={t("storageFilepath.enableCollaborate.label")}
              description={t("storageFilepath.enableCollaborate.desc")}
              onChange={event => {
                handleControls("enableCollaborate", event.target.checked);
              }}
              value={controls.enableCollaborate}
              disabled={!isValidPath(currentPath)}
            />
            {controls.enableCollaborate && (
              <CollaborateSubfolder
                currentPath={currentPath}
                position={position}
                role={role}
                provider={storage.provider}
                onChange={handleCollaboration}
              />
            )}
          </OptionGroup>
        )}
        <OptionGroup>
          <SwitchField
            name="override"
            label={t("storageFilepath.override.label")}
            onChange={handleOverride}
            value={!storage.override}
          />
        </OptionGroup>
      </StyledDetails>
    </AdvancedOptionsContainer>
  );
};

const showSubfolder = (field: SubfolderType, content: Content[]) => {
  switch (field) {
    case SubfolderType.EMAIL:
      return content.some(c => c.type === "EML" || c.type === "MSG");
    case SubfolderType.BODY:
      return content.some(c => c.type === "PDF");
    case SubfolderType.ATTACHMENTS:
      return content.some(c => c.type === "ATTACHMENTS");
    default:
      return false;
  }
};

const subfolders: Array<{ field: SubfolderType; control: ControlField }> =
  Object.values(SubfolderType).map(field => ({
    field,
    control: `enable${capitalize(field)}` as ControlField,
  }));

export default AdvancedOptions;
