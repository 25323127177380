import random from "./random";

export type ListWithKey<T> = Array<{ key: string; value: T }>;

export function addKeys<T>(list: Array<T>): ListWithKey<T> {
  if (!list) return [];
  return list.map(item => ({ key: random(), value: item }));
}

export function extractKeys<T>(list: ListWithKey<T>): Array<T> {
  if (!list) return [];
  return list.map(item => item.value);
}

export function replace<T>(
  list: ListWithKey<T>,
  key: string,
  newItem: T
): ListWithKey<T> {
  return list.reduce((newList, item) => {
    newList.push(item.key === key ? { key, value: newItem } : item);
    return newList;
  }, []);
}

export function removeItem<T>(list: ListWithKey<T>, key: string) {
  return list.filter(item => item.key !== key);
}
