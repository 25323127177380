import { Direction } from "@dashboard-v3/api";
import { Option } from "./types";

export default function optionToDirection(
  sideValue?: Option
): Direction | null {
  if (!sideValue) return null;

  switch (sideValue.type) {
    case "ACCOUNT":
      return { type: "EMAIL", value: sideValue.label };

    case "TAG":
    case "DOMAIN":
      return { type: sideValue.type, value: sideValue.label };

    case "GROUP":
      return { type: sideValue.type, value: sideValue.groupId };

    case "ORGANIZATION":
    case "ANYONE":
    case "ANYONE_ELSE":
      return { type: sideValue.type };
  }
}
