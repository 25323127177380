import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Typography,
  withStyles,
} from "@material-ui/core";

export const ListHeader = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 3fr 2fr 2fr 2fr 2fr;
  padding: 0 16px 8px 16px;
  align-items: center;
`;

export const StyledDetails = styled(AccordionDetails)`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 3fr 2fr 2fr 2fr 2fr;
  align-items: center;
  padding: 0;
`;

export const ButtonContainer = styled.div`
  justify-self: end;
  display: flex;
  gap: 2px;
`;

export const StyledFab = styled(Fab)`
  background-color: rgb(253, 236, 234);
  box-shadow: none;
  color: #f44336;
  height: 30px;
  width: 30px;
  min-height: 30px;
`;

export const StyledError = styled(Typography)`
  ::before {
    padding-right: 8px;
  }
`;

export const SummaryContainer = withStyles({
  root: {
    padding: 0,
  },
  content: {
    margin: 0,
  },
})(AccordionSummary);

export const StepperContainer = styled.div`
  width: 100%;
  border-top: 1px solid #ebebeb;
  padding-top: 25px;
`;

export const SummaryGrid = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 3fr 2fr 2fr 2fr 2fr;
  align-items: center;
  padding: 0;
`;

export const AccordionContainer = styled(Accordion)`
  width: 100%;
  padding: 8px 5px;
`;
