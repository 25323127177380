import { MailboxAccountState, MailboxRuleStats, Rule } from "@dashboard-v3/api";
import useStorageAccountStatus from "../CloudStorage/useStorageAccountStatus";
import { getStorage } from "../CloudStorage/utils";
import { getMailbox } from "../CreateOrEdit/context/helpers";
import {
  getAuthSingleAccount,
  isSingleAccount,
} from "../CreateOrEdit/Steps/Target/Mailbox/utils";
import useSWR from "swr";
import { fetcher as fetchFromApi } from "utils/apiRequestWithErrorCode";
import { Box, Tooltip } from "@material-ui/core";
import { StyledError, StyledFab } from "./Styled";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export default function ErrorFeedback({ rule }: { rule: Rule }) {
  const { t } = useTranslation("rules");

  const storage = getStorage(rule);
  const status = useStorageAccountStatus(storage);

  const mailbox = getMailbox(rule);
  const singleAccount = isSingleAccount(mailbox);

  const { data: mailboxState } = useSWR<MailboxAccountState>(
    singleAccount
      ? path(mailbox.provider, getAuthSingleAccount(mailbox))
      : null,
    fetchFromApi,
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  const { data: mailboxRuleStats } = useSWR<MailboxRuleStats>(
    mailbox ? `/rules/${rule.id}/stats` : null,
    fetchFromApi,
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  const unsyncMailbox = singleAccount && !mailboxState?.sync;
  const cloudStorageIssue = status !== "OK";
  const someMailboxAccountWithError =
    !singleAccount &&
    mailbox &&
    haveAnyMailboxAccountsWithError(mailboxRuleStats);

  if (!cloudStorageIssue && !unsyncMailbox && !someMailboxAccountWithError)
    return null;

  return (
    <>
      <Tooltip
        arrow
        style={{ maxWidth: "none" }}
        placement="top"
        title={
          <>
            {unsyncMailbox && (
              <StyledError display="block" variant="caption">
                {t(`list.accountErrors.mailbox`)}
              </StyledError>
            )}
            {cloudStorageIssue && (
              <StyledError display="block" variant="caption">
                {t(`list.accountErrors.storage.${status}`)}
              </StyledError>
            )}
            {someMailboxAccountWithError && (
              <StyledError display="block" variant="caption">
                {t("list.accountErrors.multiAccountsMailbox")}
              </StyledError>
            )}
          </>
        }
      >
        <Box mr={1}>
          <StyledFab color="inherit" size="small">
            <ErrorOutlineIcon fontSize="small" />
          </StyledFab>
        </Box>
      </Tooltip>
    </>
  );
}

function haveAnyMailboxAccountsWithError(mailboxRuleStats: MailboxRuleStats) {
  return (
    mailboxRuleStats.accounts.amountAccountsWithConnectionError +
      mailboxRuleStats.accounts.amountAccountsWithOtherErrors >
    0
  );
}

const path = (provider: string, userId: string) =>
  `/auth/mailbox/${provider.toLowerCase()}/individual/${encodeURIComponent(
    userId
  )}`;
