/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useSnackbar } from "notistack";
import * as api from "utils/api/accounts";
import { isNotFound } from "utils/apiRequestWithErrorCode";

import {
  Account,
  CreateAccountBody,
  UpdateAccountBody,
} from "@dashboard-v3/api";

type Status =
  | "loading"
  | "show search result"
  | "show accounts"
  | "fetching more";

type State = {
  items: Account[];
  tags: string[];
  status: Status;
  hasMoreItems: boolean;
  offset: number;
  searchCriteria: string;
  tagFilter?: string;
};

export type BulkSaveResult = {
  accountsDone: number;
  errors: AccountWithError[] | null;
};

type AccountWithError = Account & {
  error: string;
};

const AccountContext = React.createContext(null);

const listReqLimit = 10;

const initState: State = {
  items: [],
  tags: [],
  status: "loading",
  hasMoreItems: false,
  offset: 0,
  searchCriteria: "",
};

function useActions(
  state: State,
  setState: Dispatch<SetStateAction<State>>,
  showError: (string) => void
) {
  const { t } = useTranslation(["accounts", "rules"]);

  React.useEffect(() => {
    loadTags();
  }, []);

  React.useEffect(() => {
    loadAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchCriteria, state.tagFilter]);

  const startLoading = (newStatus: Status = "loading") =>
    setState(current => {
      if (current.status !== newStatus) {
        return { ...current, status: newStatus };
      }
      return current;
    });

  const endLoading = () =>
    setState(current => ({
      ...current,
      status: current.searchCriteria ? "show search result" : "show accounts",
    }));

  async function loadAccounts() {
    startLoading();

    try {
      const accounts = await api.fetchAccounts({
        limit: listReqLimit,
        partialEmailOrAlias: state.searchCriteria,
        tag: state.tagFilter,
      });

      setState(current => ({
        ...current,
        items: accounts,
        status: current.searchCriteria ? "show search result" : "show accounts",
        hasMoreItems: accounts.length === listReqLimit,
      }));
    } catch (error) {
      console.error(error);
      showError(t("error.fetchAccounts"));
      endLoading();
    }
  }

  async function loadTags() {
    try {
      const tags = await api.fetchTags();
      setState(current => ({ ...current, tags }));
    } catch (error) {
      console.error(error);
      showError(t("list.tags.fetchError"));
    }
  }

  async function loadMore() {
    try {
      startLoading("fetching more");

      const moreItems = await api.fetchAccounts({
        limit: listReqLimit,
        offset: state.offset + listReqLimit,
        partialEmailOrAlias: state.searchCriteria,
        tag: state.tagFilter,
      });

      setState(current => ({
        ...current,
        offset: current.offset + listReqLimit,
        hasMoreItems: moreItems.length === listReqLimit,
        items: current.items.concat(moreItems),
        tagFilter: current.tagFilter,
        status: current.searchCriteria ? "show search result" : "show accounts",
      }));
    } catch (error) {
      console.error(error);
      showError(t("error.fetchAccounts"));
      endLoading();
    }
  }

  async function deleteAccount(email: string) {
    try {
      startLoading();
      await api.deleteAccount(email);
    } catch (error) {
      const notFound = isNotFound(error);
      if (!notFound) {
        const message = t("error.delete", { type: t("account").toLowerCase() });
        showError(message);
      }
      endLoading();
    }
    loadAccounts();
    loadTags();
  }

  async function updateAccount(email: string, changes: UpdateAccountBody) {
    await api.updateAccount(email, changes);
    await loadAccounts();
    await loadTags();
  }

  async function search(criteria: string, tagFilter?: string) {
    setState(current => ({
      ...current,
      tagFilter,
      searchCriteria: criteria,
    }));
  }

  async function createAccount(account: CreateAccountBody) {
    await api.createAccount(account);
    await loadAccounts();
    await loadTags();
  }

  async function bulkSave(accounts: CreateAccountBody[]) {
    const responses = await api.createAccountsInBulk(accounts);

    const accountsAndErrors = responses.reduce(
      (result, [ok, account]) => {
        if (ok) {
          return { ...result, accountsDone: result.accountsDone + 1 };
        }
        const error = {
          ...account,
          error: t([
            `accountError.${account.error}`,
            "accountError.UNKNOWN_ERROR",
          ]),
        };

        return { ...result, errors: [...result.errors, error] };
      },
      { accountsDone: 0, errors: [] }
    );

    return accountsAndErrors;
  }

  async function synchronizeAccounts() {
    try {
      await api.synchronizeAccounts();
    } catch (e) {
      console.error(e);
    }
  }

  return {
    reloadAccounts: loadAccounts,
    loadMore,
    deleteAccount,
    updateAccount,
    search,
    createAccount,
    bulkSave,
    synchronizeAccounts,
  };
}

type Context = {
  state: State;
  actions: ReturnType<typeof useActions>;
  t: TFunction;
};

function AccountProvider({ children }) {
  const { t } = useTranslation("accounts");
  const { enqueueSnackbar } = useSnackbar();

  const showError = message => enqueueSnackbar(message, { variant: "error" });

  const [state, setState] = React.useState(initState);
  const actions = useActions(state, setState, showError);
  const context = { state, t, actions };

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
}

function useAccountState(): Context {
  const context = React.useContext(AccountContext);

  if (context === undefined) {
    throw new Error("useAccountState must be used within a AccountProvider");
  }

  return context;
}

export { AccountProvider, useAccountState };
