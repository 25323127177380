import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";

export default function EventMessage({ message }: { message: string }) {
  const { t } = useTranslation("tracing");

  return (
    <Grid item xs={6} style={{ marginBottom: "10px" }}>
      <Typography color="textSecondary">
        {t("failMessage")} {message}
      </Typography>
    </Grid>
  );
}
