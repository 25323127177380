/* eslint-disable react/prop-types */
import { useState, ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-input-2/lib/material.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PageLoading from "components/PageLoading";
import VisibilityButton from "components/VisibilityButton";
import { addUserInSession } from "utils/authentication";
import PasswordInput from "components/PaswordInput";
import { isBlank } from "utils/string";
import useValidation from "utils/useValidation";
import { getValidators } from "./validators";
import "./phoneInputStyles.css";
import { Field } from "../styled";
import { registerDomain } from "../api";

import { UserForm } from "../";
import { isErrorWithCode } from "utils/apiRequestWithErrorCode";

type RegisterFormProps = {
  isOpen: boolean;
  onClose: () => void;
  registerData: UserForm;
};

export type FormState = {
  isSubmitting: boolean;
  userName: string;
  domain: string;
  password: string;
  confirmPassword: string;
  organizationName: string;
  contactEmail: string;
  contactNumber: {
    value: string;
    data: CountryData;
  };
};

export default function RegisterForm({
  isOpen,
  onClose,
  registerData,
}: RegisterFormProps) {
  const { source, domain } = registerData;
  const { t } = useTranslation("register");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<FormState>(initForm(registerData));
  const [showValue, setShowValue] = useState(initShowValue());
  const { errors, validateAll, clearErrors, addError } = useValidation(form);
  const validators = getValidators(form);

  const updateForm: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;
    clearErrors(name);
    setForm(prev => ({ ...prev, [name]: value, isSubmitting: false }));
  };

  const updateContactNumber = (value: string, data: CountryData) => {
    clearErrors("contactNumber");
    setForm(prev => ({ ...prev, contactNumber: { value, data } }));
  };

  const validateContactNumber = () => {
    const { value, data } = form.contactNumber;
    const code = data?.countryCode?.toUpperCase() as CountryCode;
    const number = parsePhoneNumberFromString(value, code);
    const partialKey = "form.phone.errors";

    if (isBlank(number)) {
      addError("contactNumber", t(`${partialKey}.required`));
      return false;
    }

    if (!number.isValid()) {
      addError("contactNumber", t(`${partialKey}.invalid`));
      return false;
    }

    return true;
  };

  const validateForm = () => {
    const formsToValidate = ["organizationName", "contactEmail"];
    if (source === "on_premise") {
      formsToValidate.push("password");
      formsToValidate.push("confirmPassword");
    }
    const isValidPhone = validateContactNumber();
    const validFields = validateAll(validators, formsToValidate);

    return validFields && isValidPhone;
  };

  const getErrorMessage = (error: unknown) => {
    if (isErrorWithCode(error)) {
      return t([`error.${error.code}`, "error.SYSTEM_FAILURE"]);
    }

    return t("error.SYSTEM_FAILURE");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setForm(prev => ({ ...prev, isSubmitting: true }));
    const formIsValid = validateForm();

    if (!formIsValid) return;

    setLoading(true);
    try {
      const response = await registerDomain(source, {
        domain,
        organizationName: form.organizationName,
        email: form.userName,
        password: form.password,
        contactNumber: form.contactNumber.value,
        contactEmail: form.contactEmail,
        userName: null,
      });

      if (source === "on_premise") {
        enqueueSnackbar(t("registerInPromiseStarted"), {
          variant: "success",
        });
        return onClose();
      }
      addUserInSession(response.user);
      history.push("/");
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const getVisibilityButton = () => {
    return {
      endAdornment: (
        <VisibilityButton
          isVisible={showValue.confirmPassword}
          onClick={() =>
            setShowValue(prev => ({
              ...prev,
              confirmPassword: !prev.confirmPassword,
            }))
          }
        />
      ),
    };
  };

  return (
    <Dialog open={isOpen} maxWidth="xs" onClose={onClose}>
      <PageLoading loading={loading} />
      <DialogTitle data-testid="landing__register-form-header">
        {t("signDomainInMxhero")}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ paddingTop: "10px" }}>
          {source === "on_premise" && (
            <>
              <Field
                name="userName"
                label={t("common:userName")}
                variant="outlined"
                fullWidth
                value={form.userName}
                disabled
                inputProps={{
                  "data-testid": "landing__register-form-username",
                }}
              />
              <PasswordInput
                value={form.password}
                onChange={updateForm}
                triggerValidation={form.isSubmitting}
              />
              <Field
                name="confirmPassword"
                label={t("confirmPassword")}
                variant="outlined"
                type={showValue.confirmPassword ? "text" : "password"}
                fullWidth
                value={form.confirmPassword}
                onChange={updateForm}
                error={!!errors["confirmPassword"]}
                helperText={t(errors["confirmPassword"])}
                InputProps={getVisibilityButton()}
                inputProps={{
                  "data-testid": "landing__register-form-password",
                }}
              />
            </>
          )}
          <Field
            name="domain"
            label={t("domainName")}
            value={form.domain}
            variant="outlined"
            fullWidth
            disabled
            inputProps={{
              "data-testid": "landing__register-form-domain",
            }}
          />
          <Field
            name="organizationName"
            label={t("organizationName")}
            variant="outlined"
            fullWidth
            value={form.organizationName}
            onChange={updateForm}
            error={!!errors["organizationName"]}
            helperText={t(errors["organizationName"])}
            inputProps={{
              "data-testid": "landing__register-form-company",
            }}
          />
          <PhoneInput
            country="us"
            specialLabel={t("contactNumber")}
            value={form.contactNumber.value}
            onChange={updateContactNumber}
            isValid={() => errors["contactNumber"] || true}
            inputProps={{
              name: "contactNumber",
              "data-testid": "landing__register-form-phone",
            }}
          />
          <Field
            name="contactEmail"
            label={t("contactEmailAddress")}
            fullWidth
            variant="outlined"
            value={form.contactEmail}
            onChange={updateForm}
            error={!!errors["contactEmail"]}
            helperText={t(errors["contactEmail"])}
            inputProps={{
              "data-testid": "landing__register-form-email",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            size="small"
            data-testid="landing__register-form-close-btn"
            onClick={onClose}
          >
            {t("common:close")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            type="submit"
            data-testid="landing__register-form-register-btn"
          >
            {t("common:register")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const initForm = (data: RegisterFormProps["registerData"]) => (): FormState => {
  return {
    isSubmitting: false,
    userName: data.email || "",
    domain: data.domain,
    password: "",
    confirmPassword: "",
    contactNumber: { value: "", data: null },
    organizationName: "",
    contactEmail: data.email,
  };
};

const initShowValue = () => () => ({
  password: false,
  confirmPassword: false,
});
