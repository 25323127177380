import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import RegexTester from "pages/Variables/RegexTester";
import { useConditionsCtx } from "../../state/ConditionsProvider";
import { AndChild, AndIndex } from "pages/Variables/types";
import { isRegexType } from "pages/Variables/helpers";
import { andCondition, isReadOnly } from "../read";
import { updateConditionValue } from "../actions";

export default function ConditionValue({ index }: { index: AndIndex }) {
  const { t } = useTranslation("variables");
  const theme = useTheme();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));

  const [openRegexTester, setOpenRegexTester] = useState(false);
  const { updateVariable, variable } = useConditionsCtx();

  const { type, sourceType, parameters }: AndChild = andCondition(
    variable,
    index
  );

  const isAIQuestion = type === "AI_QUESTION";

  const parametersLabel = isAIQuestion
    ? t("form.conditionValue.questionLabel")
    : t("form.conditionValue.label");

  const inputType = sourceType === "NUMBER" ? "number" : "text";

  const onConditionValueChange = e => {
    const { value } = e.target;
    updateConditionValue(updateVariable, index, value);
  };

  return (
    <>
      <TextField
        name="conditionValue"
        label={parametersLabel}
        type={inputType}
        inputProps={{ "data-testid": "vars_conditions-value-input" }}
        variant="outlined"
        value={parameters[0] || ""}
        onChange={onConditionValueChange}
        fullWidth
        required
        disabled={isReadOnly(variable)}
        multiline={isAIQuestion}
        minRows={4}
        InputProps={{
          endAdornment: isRegexType(type) && (
            <InputAdornment position="end">
              <Tooltip title={xlBreakpoint ? "" : t("regexTester.tooltip")}>
                <Button
                  color="default"
                  disableElevation
                  onClick={() => setOpenRegexTester(true)}
                  size="small"
                  variant="contained"
                >
                  {xlBreakpoint
                    ? t("regexTester.tooltip")
                    : t("regexTester.buttons.test")}
                </Button>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      {openRegexTester && (
        <RegexTester
          isOpen={openRegexTester}
          onClose={() => setOpenRegexTester(false)}
        />
      )}
    </>
  );
}
