import styled from "styled-components";
import { TextField } from "@material-ui/core";

export const StyledTextField = styled(TextField)({
  marginBottom: "30px",
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "98%",
    fontSize: "11px",
  },
});

export const HelperContainer = styled.span`
  display: flex;
  gap: 5px;
  > svg {
    font-size: 16px;
    cursor: pointer;
  }
`;
