import useSWR, { SWRConfiguration, useSWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";
import request from "utils/apiRequestWithErrorCode";

type ExtendedConfig = SWRConfiguration & {
  cacheIndividuals?: boolean;
  inmutable?: boolean;
};

export function useFetch<T>(path?: string, config?: ExtendedConfig) {
  const { cacheIndividuals, inmutable, ...more } = config || {};
  const { mutate: mutateItem } = useSWRConfig();

  const swrConfig = {
    revalidateOnFocus: false,
    keepPreviousData: true,
    onSuccess(...params: Parameters<SWRConfiguration["onSuccess"]>) {
      try {
        const [data] = params;
        if (cacheIndividuals) {
          if (Array.isArray(data)) {
            const url = new URL(path, "http://localhost");
            const { pathname } = url;

            data.forEach(item => {
              const key = `${pathname}/${item.id}`;
              mutateItem(key, item, false);
            });
          }
        }
        if (config?.onSuccess) {
          config.onSuccess(...params);
        }
      } catch (error) {
        console.error("Fail SWR callback onSuccess", error);
        throw error;
      }
    },
    ...more,
  };

  const params = [path, fetch, swrConfig] as const;
  const hook = inmutable ? useSWRImmutable : useSWR;

  const { data, ...rest } = hook<T>(...params);

  return {
    data,
    ...rest,
  };
}

function fetch<T>(url: string) {
  return request<T>("GET", url);
}
