import apiRequest from "utils/apiRequestWithErrorCode";
import type {
  Configuration,
  GappsConfiguration,
  LdapConfiguration,
  OfficeConfiguration,
  Source,
} from "@dashboard-v3/api";

// Sources ---
export async function fetchSources() {
  return apiRequest<Source[]>("GET", "/sources");
}

export async function fetchSource(id: string) {
  return apiRequest<Source>("GET", `/sources/${id}`);
}

export async function deleteSource(id: string) {
  await apiRequest("DELETE", `/sources/${id}`);
}

export async function refreshSync(id: string) {
  return apiRequest("PATCH", `/sources/${id}`, { refresh: true });
}

export async function createSource(
  type: Configuration["type"],
  sourceConfig:
    | LdapConfiguration["ldapSettings"]
    | GappsConfiguration["gappsSettings"]
    | OfficeConfiguration["office365Settings"]
): Promise<Source> {
  let data = { type };

  if (type === "GAPPS") {
    data = { ...data, gappsSettings: sourceConfig } as Configuration;
  }
  if (type === "LDAP") {
    data = { ...data, ldapSettings: sourceConfig } as Configuration;
  }
  if (type === "OFFICE365") {
    data = { ...data, office365Settings: sourceConfig } as Configuration;
  }

  const source = await apiRequest<Source>(
    "POST",
    "/sources",
    data as Configuration
  );

  return source;
}

export async function updateSource(
  type: Configuration["type"],
  id: string,
  sourceConfig:
    | LdapConfiguration["ldapSettings"]
    | GappsConfiguration["gappsSettings"]
    | OfficeConfiguration["office365Settings"]
): Promise<Source> {
  let data = { type };

  if (type === "GAPPS") {
    data = {
      ...data,
      gappsSettings: sourceConfig,
    } as Configuration;
  }

  if (type === "LDAP") {
    data = { type, ldapSettings: sourceConfig } as Configuration;
  }

  if (type === "OFFICE365") {
    data = { type, office365Settings: sourceConfig } as Configuration;
  }

  const source = await apiRequest<Source>("PATCH", `/sources/${id}`, data);

  return source;
}
