import { Grid, Box, Divider, Typography } from "@material-ui/core";

type Params = {
  label: string;
  timestamp: number;
};

export default function Title({ label, timestamp }: Params) {
  return (
    <Grid item lg={12}>
      <Box mt={3}>
        <Divider />
        <Box mt={2}>
          <Typography color="textSecondary" variant="body2">
            {new Date(timestamp).toLocaleString()}
          </Typography>

          <Typography style={{ fontWeight: "500" }}>{label}</Typography>
        </Box>
      </Box>
    </Grid>
  );
}
