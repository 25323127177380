import { getStorageWebhook } from "pages/Rules/CreateOrEdit/context/helpers";
import apiRequest from "utils/apiRequestWithErrorCode";

import { NotificationEvent, Rule, SearchCriteria } from "@dashboard-v3/api";

function queryParams(searchCriteria: SearchCriteria) {
  const params = new URLSearchParams();
  Object.keys(searchCriteria).forEach(key => {
    if (searchCriteria[key]) {
      params.append(key, String(searchCriteria[key]));
    }
  });

  return params;
}

export function fetchNotifications(searchCriteria: SearchCriteria) {
  return apiRequest<NotificationEvent[]>(
    "GET",
    `/notifications?${queryParams(searchCriteria)}`
  );
}

export const getWebhookData = (rule: Rule, folderId: string) => {
  if (!rule) return null;
  const webhook = getStorageWebhook(rule);
  return (
    webhook && {
      account: webhook.account,
      folder: webhook.folders.find(f => f.id === folderId),
    }
  );
};

export const isWebhookErrorNotification = (notification: NotificationEvent) =>
  notification.code === "web_hook_deleted";

export const isEmailErrorNotification = (notification: NotificationEvent) => {
  const { data } = notification;
  return (
    data.emailAccount &&
    (notification.code === "connection_error" ||
      notification.code === "connection_error_max_retry")
  );
};

export const isBalancedUploadsErrorNotification = (
  notification: NotificationEvent
) => {
  const { data } = notification;
  return (
    data.error === "storage_account_with_wrong_configuration" &&
    data.storageUser
  );
};
