import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import { EmailDetailsContainer } from "../Styled";

import { LoadedEmail } from "../types";
import EmailFieldsModal from "./EmailFieldsModal";

interface Props {
  email: LoadedEmail;
}

export default function EmailDetailsBox({ email }: Props) {
  const { t } = useTranslation(["variables", "platformVariables"]);
  const [openFieldsModal, setOpenFieldsModal] = useState<boolean>(false);

  return (
    <EmailDetailsContainer variant="outlined" elevation={0}>
      <div>
        <Typography variant="subtitle2">
          {t("variableTester.loadedEmail.title")}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {email.name}
        </Typography>
      </div>
      <Button
        variant="text"
        wording={t("variableTester.loadedEmail.showFields")}
        onClick={() => setOpenFieldsModal(true)}
      />
      {openFieldsModal && (
        <EmailFieldsModal
          email={email}
          isOpen={openFieldsModal}
          onClose={() => setOpenFieldsModal(false)}
        />
      )}
    </EmailDetailsContainer>
  );
}
