import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { useTranslation } from "react-i18next";
import { AddItemButton } from "./AddItemButton";
import { ExcludeFolder } from "@dashboard-v3/api";
import { useState } from "react";
import { convertToMapWithKey } from "./convertToMapWithKey";
import { getMailbox } from "pages/Rules/CreateOrEdit/context/helpers";
import { Rule } from "pages/Rules/types";
import SelectType from "./SelectType";
import { DeleteRowButton } from "./DeleteRowButton";

export default function ExcludeFoldersFilter({ disabled }) {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const [items, setItems] = useState<Map<string, ExcludeFolder>>(
    mapOfExcludeFolders(state.rule)
  );

  function getError(index: number) {
    const exclusions = state.filters.targetFilter?.errors?.exclusions || [];
    return exclusions[index] ?? null;
  }

  function updateState(items: Map<string, ExcludeFolder>) {
    setItems(items);
    const filters = Array.from(items.values());
    dispatch({ type: "updateExcludeFolders", payload: filters });
  }

  function deleteItem(key) {
    items.delete(key);
    updateState(items);
  }

  function changeItem(key, changes: Partial<ExcludeFolder>) {
    const updated = { ...items.get(key), ...changes };
    items.set(key, updated);
    updateState(items);
  }

  function addItem() {
    const exclusion: ExcludeFolder = { folder: "", type: "BOTH" };

    items.set(crypto.randomUUID(), exclusion);
    updateState(items);
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
            {t("mailboxFilters.excludeFolders.label")}
          </Typography>
          <FormHelperText>
            {t("mailboxFilters.excludeFolders.desc")}
          </FormHelperText>
        </Box>
        <AddItemButton onClick={addItem} disabled={disabled}>
          {t("mailboxFilters.excludeFolders.addBtnLabel")}
        </AddItemButton>
      </Box>
      {items.size > 0 && (
        <>
          <Header />
          <Grid container spacing={2} alignItems="flex-start">
            {Array.from(items, ([key, item], index) => (
              <Row
                key={key}
                item={item}
                onDelete={() => deleteItem(key)}
                onChange={changes => changeItem(key, changes)}
                disabled={disabled}
                error={getError(index)}
              />
            ))}
          </Grid>
        </>
      )}
    </>
  );
}

function mapOfExcludeFolders(rule: Rule): Map<string, ExcludeFolder> {
  const mailbox = getMailbox(rule);
  const excludeFolders = mailbox.filter?.excludeFolders || [];

  return convertToMapWithKey(excludeFolders);
}

function Header() {
  const { t } = useTranslation("rules");
  return (
    <Grid container spacing={3} style={{ marginTop: "5px" }}>
      <Grid item xs={6}>
        <Typography variant="caption">
          {t("mailboxFilters.folders.folderLabel")}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="caption">
          {t("mailboxFilters.excludeFolders.excludeTypeLabel")}
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
}

type Params = {
  item: ExcludeFolder;
  onDelete: () => void;
  onChange: (update: Partial<ExcludeFolder>) => void;
  disabled: boolean;
  error?: string;
};

function Row({ item, onDelete, onChange, disabled, error }: Params) {
  const { t } = useTranslation(["rules", "common"]);
  return (
    <>
      <Grid item xs={6}>
        <TextField
          type="text"
          variant="outlined"
          fullWidth
          defaultValue={item.folder}
          onBlur={event => {
            onChange({ folder: event.target.value.trim() });
          }}
          error={Boolean(error)}
          helperText={Boolean(error) ? t(error) : ""}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={5}>
        <SelectType
          type={item.type}
          onChange={type => onChange({ type })}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={1} style={{ textAlign: "center" }}>
        <DeleteRowButton onClick={onDelete} disabled={disabled} />
      </Grid>
    </>
  );
}
