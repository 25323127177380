import {
  AccordionDetails,
  Card,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SearchContainer = styled.div`
  width: 50%;
`;

export const RuleCardContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  text-align: left;
  text-decoration: none;
  > div:first-child {
    width: 100%;
    padding: 0;
  }
`;

export const RuleButtonsContainer = styled(ListItemSecondaryAction)`
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  width: 140px;
`;

export const RuleDetailsContainer = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 25px 0 0 0;
  border-top: 1px solid #ebebeb;
`;

export const StatCardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 0 10px 0;
`;

export const StyledStatsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 125px;
  width: 150px;
  border: none;
  box-shadow: none;
`;

export const TooltipHeaderTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
`;
