import { ResponseOrganization } from "@dashboard-v3/api";
import { Box, ListItem, ListItemText, TextField } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ButtonWithLoading from "components/ButtonWithLoading";
import SwitchField from "components/Forms/SwitchField";
import { useSnackbar } from "notistack";
import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBlank } from "utils/string";
import useOrganization from "utils/useOrganization";

type FormState = {
  embeddedFooter: string;
  embeddedDownload: boolean;
};

export default function EmbeddedLinkText() {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, updateOrganization, organization } = useOrganization();
  const [form, setForm] = useState<FormState>(initForm(organization));
  const [isSaving, setIsSaving] = useState(false);

  const updateForm: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value, checked } = e.target;
    setForm(prev => {
      const update: FormState = { ...prev };
      if (name === "embeddedFooter") update.embeddedFooter = value;
      if (name === "embeddedDownload") update.embeddedDownload = checked;
      return update;
    });
  };

  async function saveChanges(e) {
    e.preventDefault();
    const { embeddedFooter, embeddedDownload } = form;
    const storagesPolicy = {
      embeddedDownload,
      embeddedFooter: isBlank(embeddedFooter) ? null : embeddedFooter.trim(),
    };

    setIsSaving(true);
    try {
      await updateOrganization({ storagesPolicy });
    } catch (error) {
      enqueueSnackbar(t("embeddedLinkText.saveError"), { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <ListItem style={{ flexDirection: "column", alignItems: "stretch" }}>
      <ListItemText
        primary={t("embeddedLink.title")}
        secondary={t("embeddedLink.subtitle")}
        primaryTypographyProps={{ variant: "subtitle2" }}
        secondaryTypographyProps={{ variant: "caption" }}
      />
      <form onSubmit={saveChanges}>
        {isLoading ? (
          <Skeleton height={90} />
        ) : (
          <Box mt="15px">
            <TextField
              name="embeddedFooter"
              label={t("embeddedFooter.label")}
              variant="outlined"
              fullWidth
              value={form.embeddedFooter}
              onChange={updateForm}
              disabled={isSaving}
            />
          </Box>
        )}
        <Box mt="20px" marginBottom="25px">
          <SwitchField
            name="embeddedDownload"
            label={t("embeddedDownload.label")}
            description={t("embeddedDownload.description")}
            value={form.embeddedDownload}
            onChange={updateForm}
            disabled={isLoading || isSaving}
          />
        </Box>
        <Box marginTop="15px" textAlign="left" width="100%">
          <ButtonWithLoading
            color="primary"
            variant="contained"
            disableElevation
            disabled={isLoading}
            loading={isSaving}
            type="submit"
          >
            {t("common:save")}
          </ButtonWithLoading>
        </Box>
      </form>
    </ListItem>
  );
}

const initForm = (organization: ResponseOrganization) => () => {
  return {
    embeddedFooter: organization?.storagesPolicy?.embeddedFooter ?? "",
    embeddedDownload: organization?.storagesPolicy.embeddedDownload ?? false,
  };
};
