import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SelectProps,
  Typography,
} from "@material-ui/core";
import SelectField from "components/Forms/SelectField";

import { StorageProvider } from "@dashboard-v3/api";

interface SelectStorageProps {
  value: StorageProvider;
  onChange: (provider: StorageProvider) => void;
  disabled: boolean;
}

export default function StorageProviderSelector({
  value,
  onChange,
  disabled,
}: SelectStorageProps) {
  const { t } = useTranslation("copyPolicy");
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [currentProvider, setCurrentProvider] = useState<StorageProvider>(null);

  const onProviderChange: SelectProps["onChange"] = e => {
    const newValue = e.target.value as StorageProvider;

    if (value) {
      setCurrentProvider(newValue);
      setIsWarningOpen(true);
    } else {
      onChange(newValue);
    }
  };

  const onWarningConfirm = () => {
    onChange(currentProvider);
    setIsWarningOpen(false);
  };

  const onWarningClose = () => setIsWarningOpen(false);

  return (
    <>
      <SelectField<StorageProvider>
        name="storageProvider"
        label={t("components:storageFilepath.selectACloudService")}
        fullWidth={false}
        style={{ width: "250px" }}
        options={availableProviders}
        value={value || ""}
        onChange={onProviderChange}
        disabled={disabled}
        required
        renderOption={provider => (
          <MenuItem key={provider} value={provider}>
            {t(`common:integration.provider.${provider}`)}
          </MenuItem>
        )}
      />

      {isWarningOpen && (
        <Dialog open={isWarningOpen} onClose={onWarningClose}>
          <DialogTitle>{t("form.enableProvider.warning.title")}</DialogTitle>
          <DialogContent>
            <Typography variant="caption">
              {t("form.enableProvider.warning.description")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="text" size="small" onClick={onWarningClose}>
              {t("common:cancel")}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={onWarningConfirm}
            >
              {t("common:continue")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const availableProviders: StorageProvider[] = [
  "BOX",
  "GOOGLEDRIVE",
  "ONEDRIVE",
];
