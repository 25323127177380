import { Button } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";

interface Props extends AlertProps {
  actionBtn: JSX.Element | string;
  children: string;
  severity?: AlertProps["severity"];
  onClick?: React.MouseEventHandler;
}

const AlertMsg = ({ actionBtn, children, onClick, ...rest }: Props) => {
  return (
    <Alert
      severity="error"
      variant="standard"
      style={{ marginTop: "10px" }}
      action={
        <Button color="inherit" onClick={onClick} disableElevation size="small">
          {actionBtn}
        </Button>
      }
      {...rest}
    >
      {children}
    </Alert>
  );
};

export default AlertMsg;
