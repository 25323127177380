import { Typography } from "@material-ui/core";
import { TFunction } from "react-i18next";
import styled from "styled-components";
import { Option } from "../types";

const SelectItem = styled.div`
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: Rubik, Arial;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 6px;
  white-space: nowrap;
  padding-bottom: 6px;
`;

function subHint(option: Option, t: TFunction) {
  if (!option.managed && option.type !== "ANYONE") {
    return <Typography variant="caption">{t("option.not-managed")}</Typography>;
  }
}

export default function renderOption(option: Option, t: TFunction) {
  if (!option) return "";

  const withCaption = !["ANYONE", "ANYONE_ELSE", "ORGANIZATION"].includes(
    option.type
  );

  return (
    <SelectItem>
      {withCaption && (
        <Typography variant="caption">{t(`option.${option.type}`)}</Typography>
      )}
      <Typography>{option.label}</Typography>
      {subHint(option, t)}
    </SelectItem>
  );
}
