import React from "react";
import { Typography, Paper } from "@material-ui/core";
import styled from "styled-components";

const DescriptionPaper = styled(Paper)`
  background-color: rgb(238, 242, 246);
  margin-top: 25px;
  padding: 12px 16px;
  text-align: left;
`;

type Props = {
  title: string;
  titleStyles?: object;
  styleResets?: object;
  renderTitle?: () => JSX.Element;
  renderDescription?: () => JSX.Element;
  children?: React.ReactNode;
};

const DescriptionBlock: React.FC<Props> = ({
  title,
  titleStyles = {},
  styleResets = {},
  renderDescription,
  children,
}): JSX.Element => {
  return (
    <DescriptionPaper elevation={0} style={styleResets}>
      <Typography gutterBottom variant="subtitle2" style={titleStyles}>
        {title}
      </Typography>
      {renderDescription ? (
        renderDescription()
      ) : (
        <Typography variant="body2">{children}</Typography>
      )}
    </DescriptionPaper>
  );
};

export default DescriptionBlock;
