import { Paper, Box, Link, Container } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { red } from "@material-ui/core/colors";
import HeaderWithLogo from "./Layouts/Header/HeaderWithLogo";

export default function ErrorFallback() {
  const { t } = useTranslation("components");

  return (
    <div style={{ display: "flex" }}>
      <HeaderWithLogo />
      <StyledContainer maxWidth={false}>
        <Paper style={{ maxWidth: "300px" }}>
          <Box
            sx={{
              gridRowGap: "20px",
              gridColumnGap: "20px",
              fontSize: "1rem",
              display: "flex",
              padding: "20px",
              alignItems: "center",
            }}
          >
            <Box color={red[400]} display="flex" alignItems="center">
              <ErrorOutlineIcon fontSize="large" />
            </Box>
            <div>
              <div>{t("errorFallback.errorMsg")}</div>
              <Box fontSize="1rem">
                <Link href="/">{t("errorFallback.linkLabel")}</Link>
              </Box>
            </div>
          </Box>
        </Paper>
      </StyledContainer>
    </div>
  );
}

const StyledContainer = styled(Container)`
  margin-top: 64px;
  padding: 30px 40px;
  display: flex;
  justify-content: center;
`;
