import { ChangeEvent, useEffect, useState } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { OptionGroup, StyledPaper, VerticalLine } from "components/Styled";
import StorageFilepathSelector, { FolderPath } from "./StorageFilepathSelector";
import { DefaultConditionGroup } from "../Styled";
import { DefaultType, FormState } from "../types";
import { StorageProvider } from "@dashboard-v3/api";
import { canResetStorageProvider } from "./StorageFilepathSelector/utils";

type ResetFields = { [Property in keyof Partial<FormState>]: boolean };

interface Params {
  isEdit: boolean;
  pathErrors: { [key in DefaultType]?: string };
  resetPathErrors: (type: DefaultType) => void;
  formData: {
    formState: FormState;
    setValues: (values: Partial<FormState>) => void;
    reset: (fields?: ResetFields | string[]) => void;
  };
}

const defaultTypes: Array<DefaultType> = ["NONE", "MANY", "ALWAYS"];

const DefaultCondition = ({
  formData,
  isEdit,
  pathErrors,
  resetPathErrors,
}: Params) => {
  const { t } = useTranslation("copyPolicy");
  const [enableDefault, setEnableDefault] = useState<boolean>(false);
  const { formState, setValues } = formData;
  const { defaultCopies } = formState;

  useEffect(() => {
    let isDefault = false;
    const defaultTypes = Object.keys(defaultCopies) as DefaultType[];
    const containsFolderPath = defaultTypes.some(key => {
      return !!defaultCopies[key];
    });

    if (defaultCopies && containsFolderPath) {
      isDefault = defaultTypes.some(key => {
        const { folderStructure, parentId } = defaultCopies[key];
        return !!folderStructure || !!parentId;
      });
    }

    if (isEdit && isDefault) {
      setEnableDefault(true);
    }
  }, [isEdit, defaultCopies]);

  function addInitialDefault(type: DefaultType) {
    const newDefault = {
      [type]: {
        folderStructure: "",
        parentName: "",
        parentId: "",
      },
    };

    setValues({ defaultCopies: { ...defaultCopies, ...newDefault } });
  }

  const handleEnableDefault = () => {
    setEnableDefault(prev => {
      if (prev) setValues({ defaultCopies: {} });
      return !prev;
    });
  };

  const handleType = (e: ChangeEvent<HTMLInputElement>, type: DefaultType) => {
    const { checked } = e.target;

    if (checked) addInitialDefault(type);

    if (!checked) {
      const update = { ...defaultCopies };
      delete update[type];
      setValues({ defaultCopies: update });
      resetPathErrors(type);
    }
  };

  const handleFolderPath = (type: DefaultType, update: FolderPath) => {
    resetPathErrors(type);
    setValues(
      produce(formState, draft => {
        draft.defaultCopies[type] = update;
      })
    );
  };

  const onStorageProviderChange = (provider: StorageProvider) => {
    const update = produce(formState, draft => {
      draft.storageProvider = provider;
    });
    setValues(update);
  };

  const isProviderDisabled = () => {
    const { storageProvider, predicates } = formState;
    if (predicates.some(predicate => predicate.folderPath.storeId)) {
      return true;
    }

    const predicatesWithPicker = predicates.some(
      p => !!p.folderPath.parentName
    );

    return storageProvider && !!predicatesWithPicker;
  };

  return (
    <>
      <StyledPaper>
        <FormControlLabel
          control={
            <Switch
              checked={enableDefault}
              color="primary"
              onChange={handleEnableDefault}
            />
          }
          label={
            <Typography
              color="textSecondary"
              component="span"
              style={{ fontWeight: "500" }}
            >
              {t("form.useADefaultCondition")}
            </Typography>
          }
        />
        {enableDefault && (
          <>
            <DefaultConditionGroup>
              <FormControl style={{ width: "100%" }}>
                <Typography
                  style={{ fontWeight: "500" }}
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {t("form.whenToUseIt")}
                </Typography>
              </FormControl>
            </DefaultConditionGroup>
            {defaultTypes.map(type => (
              <Box key={type}>
                <FormControlLabel
                  label={t(`form.defaultCopies.types.${type}`)}
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={!!defaultCopies[type]}
                      onChange={e => handleType(e, type)}
                    />
                  }
                />
                {defaultCopies[type] && (
                  <OptionGroup style={{ marginLeft: 28, marginBottom: 35 }}>
                    <StorageFilepathSelector
                      folderPath={defaultCopies[type]}
                      storageProvider={formState.storageProvider}
                      isProviderDisabled={isProviderDisabled()}
                      pathError={pathErrors?.[type]}
                      onFolderPathChange={path => handleFolderPath(type, path)}
                      onStorageProviderChange={onStorageProviderChange}
                      canResetStorage={canResetStorageProvider(formState)}
                    />
                  </OptionGroup>
                )}
              </Box>
            ))}
          </>
        )}
      </StyledPaper>
      <VerticalLine size="sm" />
    </>
  );
};

export default DefaultCondition;
