import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SuggestionDataItem } from "react-mentions";

interface Props {
  suggestion: SuggestionDataItem;
}

type VariableSuggestion = SuggestionDataItem & {
  description: string;
  origin: "USER" | "PLATFORM" | "DEFAULT";
};

export default function SuggestionItem({ suggestion }: Props) {
  const { t } = useTranslation("platformVariables");
  const { display, description, origin } = suggestion as VariableSuggestion;
  const translatedDescription = Array.isArray(description)
    ? description
    : t(description);

  return (
    <>
      {origin && (
        <span style={{ fontSize: "12px" }}>
          {t(`variables.type.${origin}`)}
        </span>
      )}
      <Typography variant="subtitle1">{t(display)}</Typography>
      <Typography variant="caption" color="textSecondary">
        {translatedDescription}
      </Typography>
    </>
  );
}
