import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Link,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { FiltersHelper } from "./FiltersHelper";

import { TracingReport } from "@dashboard-v3/api";
import { Skeleton } from "@material-ui/lab";

interface ItemProps {
  report: TracingReport;
  loading: boolean;
  onCancelReport: (id: string) => void;
}

export default function ReportItem({
  report,
  loading,
  onCancelReport,
}: ItemProps) {
  const { t } = useTranslation("tracing");
  const { id, createdAt, user, status, filters, downloadUrl } = report;
  const classes = useStyles({ status });

  if (loading)
    return (
      <TableRow>
        <TableCell align="left">
          <Skeleton variant="text" />
        </TableCell>
        <TableCell align="left">
          <Skeleton variant="text" />
        </TableCell>
        <TableCell align="left">
          <Skeleton variant="text" />
        </TableCell>
        <TableCell align="left">
          <Skeleton variant="text" />
        </TableCell>
        <TableCell align="left">
          <Skeleton variant="text" />
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow>
      <TableCell align="left">{new Date(createdAt).toLocaleString()}</TableCell>
      <TableCell className={classes.statusCell} align="left">
        <div className={classes.statusContainer}>
          {status === "PENDING" && (
            <CircularProgress
              size={16}
              style={{ color: statusColors.get("PENDING") }}
            />
          )}
          <Typography className={classes.statusText}>
            {t(`reports.status.${status}`)}
          </Typography>
        </div>
      </TableCell>
      <TableCell align="left">{user}</TableCell>
      <TableCell align="left">
        <FiltersHelper filters={filters} />
      </TableCell>
      <TableCell className={classes.actionsCell} align="center">
        {status === "DONE" && downloadUrl && (
          <Link href={downloadUrl}>{t("reports.downloadLink")}</Link>
        )}
        {status === "PENDING" && (
          <Link
            className={classes.cancelLink}
            onClick={() => onCancelReport(id)}
          >
            {t("common:cancel")}
          </Link>
        )}
      </TableCell>
    </TableRow>
  );
}

const statusColors = new Map<TracingReport["status"], CSSProperties["color"]>([
  ["DONE", green[500]],
  ["PENDING", "#009ed2"],
  ["FAILED", "#f44336"],
  ["CANCELED", "rgba(0, 0, 0, 0.54)"],
]);

const useStyles = makeStyles<Theme, { status: TracingReport["status"] }>({
  statusCell: { width: "18%" },
  statusContainer: { display: "flex", gap: 10, alignItems: "center" },
  statusText: {
    fontSize: "0.875rem",
    color: ({ status }) => statusColors.get(status),
  },
  actionsCell: { width: "14.5%" },
  cancelLink: {
    color: statusColors.get("FAILED"),
    cursor: "pointer",
  },
});
