/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import produce from "immer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Alert } from "@material-ui/lab";
import ChipInput from "material-ui-chip-input";
import ButtonWithLoading from "components/ButtonWithLoading";
import SwitchField from "components/Forms/SwitchField";
import WithIconHelper from "components/WithIconHelper";
import {
  getErrorCode,
  getErrorDescription,
  isConflict,
} from "utils/apiRequestWithErrorCode";
import isEmail from "utils/isEmail";
import { isBlank } from "utils/string";
import { useSourcesContext } from "../sourcesProvider";
import { createSource, updateSource } from "../api";
import { AdvancedFiltersAccordion, FiltersHelpLink } from "../Styled";

import { TestSourceErrorCode } from "@dashboard-v3/api";
import { GappsSettings } from "../types";

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  gappsToEdit?: GappsSettings | null;
  id?: string | null;
}

export default function AddGappsSourceDialog({
  isOpen,
  onClose,
  gappsToEdit,
  id,
}: DialogProps) {
  const { t, actions } = useSourcesContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notInstalled, setNotInstalled] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [formState, setFormState] = useState<GappsSettings>(
    initForm(gappsToEdit)
  );

  useEffect(() => {
    if (!gappsToEdit) {
      setFormState(prev => ({ ...prev, userEmail: "" }));
    }
    return () => {
      setErrorMsg(null);
      setNotInstalled(false);
    };
  }, [isOpen]);

  const verifyAndSave = async () => {
    const { userEmail } = formState;
    if (!isEmail(userEmail) || isBlank(userEmail)) {
      return setErrorMsg(t("sources.invalidEmail"));
    }

    setErrorMsg(null);
    setIsLoading(true);
    try {
      if (id) {
        const source = await updateSource("GAPPS", id, formState);
        actions.updateSource(source);
      } else {
        const source = await createSource("GAPPS", formState);
        actions.addSource(source);
      }
      onClose();
    } catch (error) {
      const errorCode = getErrorCode<TestSourceErrorCode>(error);
      const details = getErrorDescription(error);

      if (errorCode === "APP_NOT_INSTALLED") {
        setNotInstalled(true);
      } else if (isSourceFilterError(details)) {
        setErrorMsg(t(`error.testSource.${details}`));
      } else if (isConflict(error)) {
        setErrorMsg(t("error.gappsAlreadyExist"));
      } else {
        setErrorMsg(t(id ? `error.updateSource` : "error.createSource"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChange = e => {
    const { name, value } = e.target;
    setFormState(prev =>
      produce(prev, draft => {
        if (isBlank(value) && name !== "userEmail") {
          delete draft[name];
        } else {
          draft[name] = value;
        }
      })
    );
  };

  const handleExcludeDomains =
    (action: "add" | "delete") => (domain: string) => {
      setFormState(prev => {
        let update = [...prev.excludeDomains];
        if (action === "add") {
          update.push(domain);
        }
        if (action === "delete") {
          update = update.filter(d => d !== domain);
        }

        return {
          ...prev,
          excludeDomains: update,
        };
      });
    };

  const handleFilterByGroup = e => {
    const { checked: filterByGroup } = e.target;
    setFormState(prev => ({ ...prev, filterByGroup }));
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    verifyAndSave();
  };

  const getDialogTitle = () => {
    const config = { type: t("sourceTypes.GAPPS") };

    return id
      ? t("sources.editTypeOfSource", config)
      : t("sources.addTypeOfSource", config);
  };

  if (isLoading) {
    return (
      <Dialog open={isOpen}>
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <DialogContent style={{ marginBottom: "2rem", textAlign: "center" }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (notInstalled) {
    return (
      <Dialog open={isOpen}>
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <DialogContent
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>
            {t("sources.installGapps")}

            <Button
              disableRipple
              disableFocusRipple
              color="primary"
              size="small"
              variant="text"
              onClick={() => {
                window.open(process.env.REACT_APP_GOOGLE_MARKETPLACE_APP);
                onClose();
              }}
            >
              {t("sources.googleMarketplace")}
              <LaunchOutlinedIcon style={{ fontSize: 14, marginLeft: 5 }} />
            </Button>
          </p>
          <p>{t("sources.installGapps2")}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("common:cancel")}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Dialog
        open={isOpen}
        onClick={e => e.stopPropagation()}
        onClose={onClose}
        scroll="paper"
      >
        <DialogTitle style={{ paddingBottom: "0" }}>
          {getDialogTitle()}
        </DialogTitle>
        <DialogContent style={{ marginBottom: "32px", overflowY: "unset" }}>
          <DialogContentText variant="caption">
            {t("sources.adminAccountHint")}
          </DialogContentText>
          {errorMsg && (
            <Box mb={1}>
              <Alert elevation={0} variant="filled" severity="error">
                {errorMsg}
              </Alert>
            </Box>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              name="userEmail"
              label={t("sources.emailAddressLabel")}
              type="email"
              value={formState.userEmail}
              onChange={handleOnChange}
              inputProps={{
                "data-testid": "accounts__source-email-address",
              }}
            />
            <AdvancedFiltersAccordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography variant="subtitle2">
                    {t("sources.advancedFilters.title")}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    {t("sources.advancedFilters.description")}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  name="usersFilterQuery"
                  label={t("sources.usersFilterQuery.label")}
                  margin="dense"
                  fullWidth
                  multiline
                  onChange={handleOnChange}
                  value={formState?.usersFilterQuery || ""}
                  helperText={
                    <>
                      <Typography variant="caption">
                        Ex. “email:admin*”{" "}
                      </Typography>
                      <FiltersHelpLink
                        target="_blank"
                        href="https://developers.google.com/admin-sdk/directory/v1/guides/search-users"
                      >
                        {t("sources.usersFilterQuery.helper")}
                        <OpenInNewIcon />
                      </FiltersHelpLink>
                    </>
                  }
                />
                <TextField
                  name="groupsFilterQuery"
                  label={t("sources.groupsFilterQuery.label")}
                  margin="dense"
                  fullWidth
                  multiline
                  onChange={handleOnChange}
                  value={formState?.groupsFilterQuery || ""}
                  helperText={
                    <>
                      <Typography variant="caption">
                        Ex. “email:support*”{" "}
                      </Typography>
                      <FiltersHelpLink
                        target="_blank"
                        href="https://developers.google.com/admin-sdk/directory/v1/guides/search-groups"
                      >
                        {t("sources.groupsFilterQuery.helper")}
                        <OpenInNewIcon />
                      </FiltersHelpLink>
                    </>
                  }
                />
                <SwitchField
                  name="filterByGroup"
                  label={t("sources.filterByGroup.label")}
                  labelProps={{ variant: "body2" }}
                  switchProps={{ size: "small" }}
                  value={formState.filterByGroup}
                  onChange={handleFilterByGroup}
                  disabled={isBlank(formState.groupsFilterQuery)}
                />
                <WithIconHelper
                  position="after"
                  text={t("common:chipInputHelper")}
                  icon={<InfoOutlinedIcon fontSize="small" color="primary" />}
                  buttonStyles={{ marginLeft: "10px" }}
                >
                  <ChipInput
                    label={t("sources.excludeDomains.label")}
                    fullWidth
                    allowDuplicates={false}
                    value={formState.excludeDomains}
                    onAdd={handleExcludeDomains("add")}
                    onDelete={handleExcludeDomains("delete")}
                    helperText={
                      <Typography
                        variant="caption"
                        style={{ position: "relative", top: "4px" }}
                      >
                        {t("sources.excludeDomains.description")}
                      </Typography>
                    }
                  />
                </WithIconHelper>
              </AccordionDetails>
            </AdvancedFiltersAccordion>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            data-testid="accounts__source-cancel-edit"
            onClick={onClose}
          >
            {t("common:cancel")}
          </Button>
          <ButtonWithLoading
            variant="contained"
            size="small"
            color="primary"
            disableElevation
            data-testid="accounts__source-save-edit"
            onClick={verifyAndSave}
            loading={isLoading}
          >
            {id ? t("buttons.saveSource") : t("buttons.addSource")}
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </form>
  );
}

const initForm = (settings: GappsSettings) => () => {
  const {
    userEmail = "",
    excludeDomains = [],
    groupsFilterQuery,
    usersFilterQuery,
    filterByGroup,
  } = settings || {};

  return {
    userEmail,
    excludeDomains,
    filterByGroup: filterByGroup || false,
    ...(groupsFilterQuery && { groupsFilterQuery }),
    ...(usersFilterQuery && { usersFilterQuery }),
  };
};

const isSourceFilterError = (
  desc: unknown
): desc is "ERROR_GROUPS_FILTER" | "ERROR_USERS_FILTER" => {
  const errorDescription = desc.toString();
  return (
    errorDescription === "ERROR_GROUPS_FILTER" ||
    errorDescription === "ERROR_USERS_FILTER"
  );
};
