/* eslint-disable no-use-before-define */
import dayjs from "dayjs";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  isAuthenticated,
  getSessionDuration,
  removeUserFromSession,
  getLastApiRequestTime,
} from "utils/authentication";

const everyMinute = 1000 * 60;

const AutoLogout = () => {
  const history = useHistory();

  function logout() {
    removeUserFromSession();
    history.push("/login");
  }

  function checkSessionTimeout() {
    if (isAuthenticated() && isSessionExpired()) {
      logout();
    }
  }

  useEffect(() => {
    checkSessionTimeout();
    setInterval(checkSessionTimeout, everyMinute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

function isSessionExpired() {
  const sessionDuration = getSessionDuration() || 3.0;
  const lastRequestTime = getLastApiRequestTime();

  if (!lastRequestTime) return true;

  const expireAt = dayjs(lastRequestTime).add(sessionDuration, "hours");
  const now = dayjs();
  return now.isAfter(expireAt);
}

export default AutoLogout;
