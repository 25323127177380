import { isBlank } from "utils/string";
import { FormState } from ".";
import isEmail from "utils/isEmail";
import { validatePassword } from "@dashboard-v3/shared";

const key = (field: string, key: string) => `form.${field}.errors.${key}`;

export const getValidators = (form: FormState) => ({
  password(value) {
    const [isValid, errors] = validatePassword(value) as [boolean, string[]];

    if (isValid) return null;

    if (errors.includes("MIN_LENGTH")) {
      return key("password", "length");
    }

    if (errors.includes("SPECIAL_CHARS_REGEX")) {
      return key("password", "specialChars");
    }

    if (errors.includes("UPPER_CASE")) {
      return key("password", "case");
    }

    return null;
  },
  confirmPassword(value) {
    const { password, confirmPassword } = form;
    if (isBlank(value)) {
      return key("confirmPassword", "required");
    }
    if (password !== confirmPassword) {
      return key("confirmPassword", "match");
    }
    return null;
  },
  organizationName(value) {
    if (isBlank(value)) {
      return key("organizationName", "required");
    }
    return null;
  },
  contactEmail(value) {
    if (isBlank(value)) {
      return key("contactEmail", "required");
    }
    if (!isEmail(value)) {
      return key("contactEmail", "invalid");
    }
    return null;
  },
});
