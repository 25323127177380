import { Group, Rule } from "@dashboard-v3/api";
import { Link } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { fetchGroups } from "utils/api/groups";
import { TFunction } from "i18next";
import apiRequest, {
  getErrorDescription,
  isConflict,
} from "utils/apiRequestWithErrorCode";

const listReqLimit = 10;

type Status =
  | "loading"
  | "show search result"
  | "show groups"
  | "fetching more"
  | "deleting";

type State = {
  items: Group[];
  status: Status;
  hasMoreItems: boolean;
  offset: number;
  searchCriteria: string;
  deletingId?: string;
};

const initState: State = {
  items: [],
  status: "loading",
  hasMoreItems: false,
  offset: 0,
  searchCriteria: "",
};

function useActions(
  state: State,
  setState: Dispatch<SetStateAction<State>>,
  showError: (string) => void
) {
  const { t } = useTranslation("accounts");
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchCriteria]);

  const startLoading = (newStatus: Status = "loading") =>
    setState(current => {
      if (current.status !== newStatus) {
        return { ...current, status: newStatus };
      }
      return current;
    });

  const endLoading = () =>
    setState(current => ({
      ...current,
      status: current.searchCriteria ? "show search result" : "show groups",
    }));

  async function loadGroups() {
    try {
      startLoading();
      const groups = await fetchGroups(listReqLimit, 0, state.searchCriteria);
      setState(current => ({
        ...current,
        items: groups,
        status: "show groups",
        hasMoreItems: groups.length === listReqLimit,
      }));
    } catch (error) {
      console.error(error);
      showError(t("error.fetchGroups"));
      endLoading();
    }
  }

  async function loadMore() {
    try {
      startLoading("fetching more");
      const moreItems = await fetchGroups(
        listReqLimit,
        state.offset + listReqLimit,
        state.searchCriteria
      );
      setState(current => ({
        ...current,
        offset: current.offset + listReqLimit,
        hasMoreItems: moreItems.length === listReqLimit,
        items: current.items.concat(moreItems),
        status: "show groups",
      }));
    } catch (error) {
      console.error(error);
      showError(t("error.fetchGroups"));
      endLoading();
    }
  }

  function search(criteria: string) {
    setState(current => ({
      ...current,
      searchCriteria: criteria,
    }));
  }

  async function deleteGroup(id: string): Promise<void> {
    try {
      startLoading("deleting");
      setState(prev => ({ ...prev, deletingId: id }));
      await apiRequest("DELETE", `/groups/${id}`);
      enqueueSnackbar(t("success.removeGroup"), {
        variant: "success",
      });
    } catch (error) {
      const errorDescription = getErrorDescription(error) as { rules: Rule[] };
      console.error(error);
      if (isConflict(error)) {
        const links = (errorDescription.rules as Rule[]).flatMap(
          ({ id, name }) => [
            <Link
              href={`/rules/${id}/edit`}
              key={id}
              target="_blank"
              color="inherit"
              style={{ fontWeight: "bold" }}
              underline="hover"
            >
              {name}
            </Link>,
            <span>{", "}</span>,
          ]
        );

        // Add error's text part at the start of the array
        links.unshift(t("error.removeGroupConflict"));
        // Remove the last ", " element from the array.
        links.pop();

        enqueueSnackbar(<div>{links}</div>, {
          variant: "error",
        });
      } else {
        showError(t("error.removeGroup"));
      }
      endLoading();
      setState(prev => ({ ...prev, deletingId: null }));
    } finally {
      loadGroups();
    }
  }

  return { loadMore, search, deleteGroup, reloadGroups: loadGroups };
}

type Context = {
  state: State;
  t: TFunction;
  actions: ReturnType<typeof useActions>;
};

const GroupsContext = React.createContext<Context>(null);

// eslint-disable-next-line react/prop-types
function GroupsProvider({ children }) {
  const { t } = useTranslation("accounts");
  const { enqueueSnackbar } = useSnackbar();

  const showError = message => enqueueSnackbar(message, { variant: "error" });

  const [state, setState] = React.useState(initState);
  const actions = useActions(state, setState, showError);
  const context = { state, t, actions };

  return (
    <GroupsContext.Provider value={context}>{children}</GroupsContext.Provider>
  );
}

function useGroupsState(): Context {
  const context = React.useContext(GroupsContext);

  if (context === undefined) {
    throw new Error("useGroupsState must be used within a GroupsProvider");
  }

  return context;
}

export { GroupsProvider, useGroupsState };
