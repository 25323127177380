import { Fab, Typography } from "@material-ui/core";
import styled from "styled-components";

type Params = {
  label: string;
  onClick: () => unknown;
  disabled?: boolean;
  testid?: string;
};

export default function FloatingButton({
  disabled,
  onClick,
  testid,
  label,
}: Params) {
  return (
    <div style={{ textAlign: "center" }}>
      <StyledFab
        data-testid={testid || ""}
        disabled={disabled}
        size="medium"
        variant="extended"
        onClick={onClick}
      >
        <Typography color={disabled ? "initial" : "primary"} variant="button">
          {label}
        </Typography>
      </StyledFab>
    </div>
  );
}

const StyledFab = styled(Fab)`
  background-color: white;
  :hover {
    background-color: #f1f1f1;
  }
`;
