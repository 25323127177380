import apiRequest, {
  getErrorCode,
  getErrorDescription,
  isConflict,
} from "utils/apiRequestWithErrorCode";
import { FoldersInUse, MissingFolder } from ".";
import { State } from "pages/Rules/CreateOrEdit/context/types";
import {
  StorageFolder,
  WebhookFolder,
} from "pages/Rules/StorageFilepath/FilepathSelector/types";
import { WebhookCheckRequest, WebhookCheckResponse } from "@dashboard-v3/api";

export const isWebhookFolderError = (state: State, error: unknown) => {
  const errorCode = getErrorCode(error);
  const errorDescription = getErrorDescription(error) as object;
  const { actionType } = state.rule;

  if (actionType === "DRAG_AND_DROP") {
    return (
      (isConflict(error) && "foldersInUse" in errorDescription) ||
      (errorCode === "NOT_FOUND" && "missingFolder" in errorDescription)
    );
  }

  return false;
};

export function isWebhookForbiddenError(error: unknown) {
  return (
    (getErrorCode(error) as string) === "FORBIDDEN" &&
    getErrorDescription(error)?.toString() === "create_webhook_error"
  );
}

export const toFolderItem = (
  currentFolder: {
    id: string;
    path: string;
  },
  foldersInUse: FoldersInUse,
  missingFolder: MissingFolder
) => {
  const { id, path } = currentFolder;
  const folderName = path.split("/").pop();
  const folderPath = path.replace(`/${folderName}`, "");
  const folderInUse = foldersInUse.find(
    e => e.searchedFolder === id || e.conflictFolder === id
  );
  const isMissing = currentFolder.id === missingFolder;

  return {
    id,
    name: folderName,
    path: folderPath,
    folderInUse,
    isInUse: !!folderInUse,
    isMissing,
  };
};

export const toWebhookFolders = (folders: StorageFolder[]): WebhookFolder[] => {
  if (folders.length) {
    return folders.map(f => ({ id: f.parentId, path: f.parentName }));
  }
  return [];
};

export const handleWebhookCheck = async (
  folders: WebhookFolder[],
  account: string,
  ruleId: string
) => {
  const reqBody: WebhookCheckRequest = {
    storageApplication: "BOX",
    storageWebhook: { account, folders },
    ruleId: ruleId || "NEW_RULE",
  };
  const { success, ...result } = await apiRequest<WebhookCheckResponse>(
    "POST",
    "/rules/webhook/check",
    reqBody
  );
  return { ...result, isValid: success };
};
