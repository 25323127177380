import { Direction, EmailFlow } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";
import GroupName from "./GroupName";

export default function EmailFlowDetails({
  emailFlow,
}: {
  emailFlow: EmailFlow;
}) {
  const { t } = useTranslation("rules");

  const { fromDirection, toDirection } = emailFlow.fromTo;

  return (
    <div>
      <>
        {emailFlow.bidirectional
          ? t("emailFlowSection.between")
          : t("emailFlowSection.from")}{" "}
      </>
      <Label direction={fromDirection} />
      <>
        {" "}
        {emailFlow.bidirectional
          ? t("emailFlowSection.and")
          : t("emailFlowSection.to")}{" "}
      </>
      <Label direction={toDirection} />
    </div>
  );
}

function Label({ direction }: { direction: Direction }) {
  const { t } = useTranslation("fromToSelector");

  if (!direction) return <>?</>;

  if (direction.type === "GROUP") {
    return (
      <>
        {t(`details.${direction.type}`)} <GroupName id={direction.value} />
      </>
    );
  }

  return <>{t(`details.${direction.type}`, { value: direction.value })}</>;
}
