import { useTranslation } from "react-i18next";
import nextId from "react-id-generator";
import { Chip, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { Account } from "@dashboard-v3/api";

interface AccountTagsProps {
  tags: Account["tags"];
}

export default function AccountTags({ tags }: AccountTagsProps) {
  const { t } = useTranslation("accounts");

  if (tags.length === 0) {
    return (
      <Typography style={{ color: grey[400] }}>{t("list.noTags")}</Typography>
    );
  }
  return (
    <>
      {tags.map(tag => (
        <Chip
          key={nextId()}
          label={tag}
          style={{ marginRight: "5px", marginBottom: "5px" }}
        />
      ))}
    </>
  );
}
