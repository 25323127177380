import {
  Accordion,
  AccordionDetails,
  Avatar,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { grey, orange } from "@material-ui/core/colors";

export const AccountItemContainer = styled(Accordion)`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 20px !important;
  background: ${grey[100]};
`;

export const AccountIcon = styled(ListItemIcon)`
  min-width: fit-content;
  margin-right: 15px;
  > div {
    height: 30px;
    width: 30px;
    background-color: ${orange[300]};
  }
`;

export const AccountAvatar = styled(Avatar)`
  align-self: center;
  text-transform: uppercase;
`;

export const AccountDetails = styled(AccordionDetails)`
  display: block;
  padding-bottom: 0;
  padding-top: 0;
`;

export const AccountPropertyContainer = styled.div`
  margin-bottom: 20px;
`;

export const AccountPropertyLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.82rem;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const AccountPropertyValue = styled(Typography)`
  min-height: 1.5rem;
`;

export const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;
