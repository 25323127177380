import {
  FormControlLabel,
  FormHelperText,
  Switch,
  SwitchProps,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { ReactNode } from "react";

interface Props {
  name?: string;
  label: string;
  labelProps?: TypographyProps;
  switchProps?: SwitchProps;
  description?: string | ReactNode;
  disabled?: boolean;
  style?: object;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  value: boolean;
}

interface InputDataProps extends React.HTMLAttributes<HTMLInputElement> {
  "data-testid"?: string;
}

const SwitchField = ({
  name,
  label,
  labelProps,
  switchProps,
  description,
  onChange,
  value,
  style,
  disabled,
}: Props) => {
  const inputProps = {
    "data-testid": `option_${name}_${value ? "on" : "off"}`,
  } as InputDataProps;

  return (
    <div>
      <FormControlLabel
        style={style}
        control={
          <Switch
            {...switchProps}
            color={switchProps?.color || "primary"}
            name={name}
            onChange={onChange}
            checked={value ?? false}
            disabled={disabled}
            inputProps={inputProps}
          />
        }
        label={
          <Typography {...labelProps} style={{ fontWeight: "500" }}>
            {label}
          </Typography>
        }
      />
      {description && <FormHelperText>{description}</FormHelperText>}
    </div>
  );
};

export default SwitchField;
