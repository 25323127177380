import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import AuthenticationModal from "components/AuthenticationModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { shouldAuthenticate } from "utils/authentication";

export default function DeleteBtn({ userId, onDelete }) {
  const { t } = useTranslation("users");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [authIsOpen, setAuthIsOpen] = useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOpen = () => {
    if (shouldAuthenticate()) {
      setAuthIsOpen(true);
    } else {
      setDialogOpen(true);
    }
  };

  const onAuthSuccess = () => {
    setAuthIsOpen(false);
    setDialogOpen(true);
  };

  const handleClickDelete = async () => {
    setDialogOpen(false);
    onDelete(userId);
  };

  return (
    <>
      <DeleteBtnWrapper>
        <Button
          data-testid="users__delete-user-btn"
          onClick={handleOpen}
          style={{ marginLeft: "10px" }}
          variant="outlined"
        >
          {t("common:delete")}
        </Button>
      </DeleteBtnWrapper>
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogContent>
            <DialogContentText>
              {t("items.deleteConfirmation")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("common:no")}
            </Button>
            <Button
              disableElevation
              onClick={handleClickDelete}
              color="primary"
              autoFocus
              variant="contained"
            >
              {t("common:yes")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {authIsOpen && (
        <AuthenticationModal
          isOpen={authIsOpen}
          onSuccess={onAuthSuccess}
          onClose={() => setAuthIsOpen(false)}
        />
      )}
    </>
  );
}

const DeleteBtnWrapper = styled.div`
  display: inline-block;
  position: relative;
`;
