import { useTranslation } from "react-i18next";
import { useHistory, generatePath } from "react-router-dom";
import { useDependencyModal } from "components/DependencyModal";
import apiRequest from "utils/apiRequestWithErrorCode";
import { ListItemProps } from "../types";
import Item from "components/List/Item";

const ListItem = ({
  fetchPolicies,
  policy,
  setLoading,
  setLoadingItem,
}: ListItemProps) => {
  const { t } = useTranslation("securityPolicies");
  const { errorHandler } = useDependencyModal();
  const history = useHistory();
  const { id } = policy;

  const goToEdit = () => {
    history.push({
      pathname: generatePath("security-policies/edit/:id", { id }),
      state: {
        policyId: policy.id,
        selectedPolicy: {
          name: policy.name,
          description: policy.description || "",
          storageType: policy.storageType,
          predicates: policy.predicates,
          defaultDefinition: policy.defaultDefinition || "",
        },
      },
    });
  };

  const deleteSecurityPolicy: (id: string) => Promise<void> = async id => {
    setLoading("ITEM");
    setLoadingItem(id);
    try {
      await apiRequest<void>("DELETE", `/security-policies/${id}`);
      await fetchPolicies();
    } catch (error) {
      errorHandler(error, t("errors.deleteError"));
    } finally {
      setLoading(null);
      setLoadingItem(null);
    }
  };

  return (
    <Item
      item={policy}
      onEdit={goToEdit}
      onDelete={() => deleteSecurityPolicy(id)}
    />
  );
};

export default ListItem;
