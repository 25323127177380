import { ManualDomain } from "@dashboard-v3/api";

export function freeManualDomains(manualDomains: ManualDomain[]) {
  const result = manualDomains.filter(
    manualDomain =>
      manualDomain.lastStatus === "VERIFIED" && !manualDomain?.connected
  );

  return result;
}

export function anyfreeManualDomain(manualDomains: ManualDomain[]) {
  return freeManualDomains(manualDomains).length > 0;
}
