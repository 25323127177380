import { StatConfig } from "../types";

export type FilterBy = "all" | "action" | "target";

const colorAlpha = "0.2";

export const colors = [
  ["rgb(65, 105, 225)", `rgb(65, 105, 225, ${colorAlpha})`],
  ["rgb(52, 84, 180)", `rgb(52, 84, 180, ${colorAlpha})`],
  ["rgb(39, 63, 135)", `rgb(39, 63, 135, ${colorAlpha})`],
  ["rgb(66, 214, 164)", `rgb(66, 214, 164, ${colorAlpha})`],
  ["rgb(89, 173, 246)", `rgb(89, 173, 246, ${colorAlpha})`],
  ["rgb(199, 128, 232)", `rgb(199, 128, 232, ${colorAlpha})`],
];

export const chartLines: Record<FilterBy, StatConfig[]> = {
  all: [
    {
      dataKey: "all",
      stroke: colors[0][0],
      name: "processedEmails.filter.all",
      fill: "url(#one)",
      enabled: true,
    },
  ],
  action: [
    {
      dataKey: "drag_and_drop",
      name: `rules:actions.${"drag_and_drop".toUpperCase()}.title`,
      stroke: colors[0][0],
      fill: "url(#one)",
      enabled: true,
    },
    {
      dataKey: "replace_attachments",
      name: `rules:actions.${"replace_attachments".toUpperCase()}.title`,
      stroke: colors[1][0],
      fill: "url(#two)",
      enabled: true,
    },
    {
      dataKey: "protect_emails",
      name: `rules:actions.${"protect_emails".toUpperCase()}.title`,
      stroke: colors[2][0],
      fill: "url(#three)",
      enabled: true,
    },
    {
      dataKey: "move_emails",
      stroke: colors[3][0],
      fill: "url(#four)",
      enabled: true,
      name: `rules:actions.${"move_emails".toUpperCase()}.title`,
    },
    {
      dataKey: "copy_emails",
      name: `rules:actions.${"copy_emails".toUpperCase()}.title`,
      stroke: colors[4][0],
      fill: "url(#five)",
      enabled: true,
    },
    {
      dataKey: "block_attachments",
      name: `rules:actions.${"block_attachments".toUpperCase()}.title`,
      stroke: colors[5][0],
      fill: "url(#six)",
      enabled: true,
    },
  ],
  target: [
    {
      dataKey: "email_flow",
      name: `rules:${"email_flow".toUpperCase()}`,
      stroke: colors[0][0],
      fill: "url(#one)",
      enabled: true,
    },
    {
      dataKey: "archiving_address",
      name: `rules:${"archiving_address".toUpperCase()}`,
      stroke: colors[1][0],
      fill: "url(#two)",
      enabled: true,
    },
    {
      dataKey: "mail_box",
      name: `rules:${"mail_box".toUpperCase()}`,
      stroke: colors[2][0],
      fill: "url(#three)",
      enabled: true,
    },
    {
      dataKey: "storage_webhook",
      name: `rules:${"storage_webhook".toUpperCase()}`,
      stroke: colors[3][0],
      fill: "url(#four)",
      enabled: true,
    },
  ],
};
