/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  capitalize,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { RouteGroup, sectionRoutes } from ".";
import { StyledListItemText, useStyles } from "./Styled";
import { useTranslation } from "react-i18next";

interface Props {
  group: RouteGroup;
  selected: RouteGroup;
  disableDivider?: boolean;
  children: React.ReactNode;
  onClick: (selected: RouteGroup) => void;
}

export default function SidebarSection({
  group,
  selected,
  disableDivider,
  children,
  onClick,
}: Props) {
  const { t } = useTranslation("sidebar");
  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    const [, path] = pathname.split("/");
    Object.values(RouteGroup).forEach(section => {
      if (sectionRoutes[section].includes(`/${path}`)) {
        onClick(section);
      }
    });
  }, [pathname]);

  const renderExpandIcon = (routeGroup: RouteGroup) => {
    return selected === routeGroup ? <ExpandLess /> : <ExpandMore />;
  };

  return (
    <List component="div" disablePadding>
      <ListItem
        dense
        button
        className={classes.nested}
        data-testid={`menuItem ${capitalize(group)}`}
        onClick={() => onClick(group)}
      >
        <StyledListItemText
          primary={t(group)}
          primaryTypographyProps={{ style: { fontWeight: "500" } }}
        />
        {renderExpandIcon(group)}
      </ListItem>
      {selected && (
        <Collapse unmountOnExit in={group === selected} timeout="auto">
          {children}
        </Collapse>
      )}
      {!disableDivider && <Divider />}
    </List>
  );
}
