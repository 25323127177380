import { State } from "../types";
import produce from "immer";
import { verifyIfCanSave } from "./verifyIfCanSave";

export function updateRuleLabels(
  state: State,
  payload: UpdateRuleLabels["payload"]
): State {
  const { type, input } = payload;
  return produce(state, draft => {
    if (type === "name") {
      draft.rule.name = input;
      verifyIfCanSave(draft);
    }
    if (type === "description") {
      draft.rule.description = input;
    }
  });
}

export type UpdateRuleLabels = {
  type: "updateRuleLabels";
  payload: {
    type: "name" | "description";
    input: string;
  };
};
