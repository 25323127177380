import produce from "immer";
import { State } from "../types";

export type UpdateWebhookProcessing = {
  type: "updateWebhookProcessing";
  payload: State["webhookProcessing"];
};

export const updateWebhookProcessing = (
  state: State,
  payload: UpdateWebhookProcessing["payload"]
) => {
  return produce(state, draft => {
    draft.webhookProcessing = payload;
  });
};
