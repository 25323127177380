import { IncludeFolder } from "@dashboard-v3/api";
import { State } from "../types";
import produce from "immer";
import { getMailbox } from "../helpers";
import validateFilter from "./validateFilters";

export function updateIncludeFolders(
  state: State,
  changes: IncludeFolder[]
): State {
  return produce(state, draft => {
    const mailbox = getMailbox(draft.rule);
    if (!mailbox.filter) {
      mailbox.filter = {
        includeFromTo: [],
        excludeFromTo: [],
        includeFolders: [],
      };
    }

    mailbox.filter.includeFolders = changes;
    validateFilter("targetFilter", draft);
  });
}

export type UpdateIncludeFolders = {
  type: "updateIncludeFolders";
  payload: IncludeFolder[];
};
