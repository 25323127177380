import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LinearProgress, List, Paper } from "@material-ui/core";
import { ShowMoreBtn } from "components/List";
import AccountSearch from "components/AccountSearch";
import Item from "./Item";
import Empty from "./Empty";
import { useAccountState } from "../accountContext";

export default function AccountList() {
  const { t } = useTranslation("accounts");
  const { state, actions } = useAccountState();
  const { items, status, hasMoreItems } = state;

  return (
    <StyledList disablePadding data-testid="accounts__account-list">
      <li style={{ marginBottom: "8px" }}>
        <AccountSearch
          placeholder={t("searchPlaceholder")}
          withTagFilter
          tags={state.tags}
          onSearch={actions.search}
          onClear={actions.search}
        />
      </li>

      {status === "loading" && (
        <li>
          <Loading />
        </li>
      )}

      {isEmpty(status, items) && <Empty />}

      {isSearchResultEmpty(status, items) && (
        <Paper style={{ padding: "1rem", marginTop: "14px" }}>
          {t("noSearchResult")}
        </Paper>
      )}

      {status !== "loading" && (
        <>
          {items.map(account => (
            <Item key={account.email} account={account} />
          ))}

          {hasMoreItems && (
            <li style={{ marginTop: "10px" }} data-testid="loadMoreContainer">
              <ShowMoreBtn
                loading={status === "fetching more"}
                onClick={actions.loadMore}
              />
            </li>
          )}
        </>
      )}
    </StyledList>
  );
}

const StyledList = styled(List)`
  li:first-child {
    padding-top: 0;
  }
  li:last-child {
    padding-bottom: 0;
  }
`;

const Loading = () => (
  <Paper
    style={{ padding: "12px 16px", marginTop: "14px" }}
    data-testid="list loading"
  >
    <LinearProgress />
  </Paper>
);

function isEmpty(status, accounts) {
  return status === "show accounts" && accounts.length === 0;
}

function isSearchResultEmpty(status, accounts) {
  return accounts.length === 0 && status === "show search result";
}
