/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
} from "@material-ui/core";

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`;

const hasProperty = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj || {}, key);
};

const prop = (object, key) => {
  if (hasProperty(object, key)) return object[key];
  return null;
};

const SamlBtn = ({
  color,
  disabled,
  settings: initSettings,
  onSave,
  certIsRequired,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [settings, setSettings] = useState({});

  const { t } = useTranslation("security");

  const change = field => event => {
    const { value } = event.target;
    setSettings(previous => ({
      ...previous,
      [field]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    onSave(settings);
    setDialogOpen(false);
  };

  useEffect(() => {
    setSettings(initSettings);
  }, [initSettings]);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => {
    setDialogOpen(false);
    setSettings(initSettings);
  };

  return (
    <>
      <Button
        data-testid="security__edit-saml-btn"
        disabled={disabled}
        onClick={openDialog}
        style={{ marginLeft: "10px" }}
        variant="outlined"
        color={color}
      >
        {t("common:edit")}
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle id="alert-dialog-title">
          {t("samlBtn.modalTitle")}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": "security__saml-entityid-input",
              }}
              label={t("samlBtn.entityId")}
              onChange={change("entityId")}
              required
              type="string"
              value={prop(settings, "entityId") || ""}
              variant="outlined"
            />
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": "security__saml-single-signin-input",
              }}
              label={t("samlBtn.singleSignIn")}
              onChange={change("ssoUrl")}
              required
              type="string"
              value={prop(settings, "ssoUrl") || ""}
              variant="outlined"
            />
            <StyledTextField
              fullWidth
              inputProps={{
                "data-testid": "security__saml-certificate-input",
              }}
              label={t(
                certIsRequired
                  ? "samlBtn.certificate"
                  : "samlBtn.changeCertificate"
              )}
              multiline
              onChange={change("cert")}
              required={certIsRequired}
              value={prop(settings, "cert") || ""}
              variant="outlined"
              helperText={
                certIsRequired ? (
                  t("samlBtn.certificateHint")
                ) : (
                  <>
                    <div>{t("samlBtn.certificateHint")}</div>
                    <div>{t("samlBtn.hiddenCertificate")}</div>
                  </>
                )
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="security__cancel-saml-btn"
              onClick={closeDialog}
              color="primary"
            >
              {t("common:cancel")}
            </Button>
            <Button
              data-testid="security__save-saml-btn"
              disableElevation
              type="submit"
              color="primary"
              autoFocus
              variant="contained"
            >
              {t("common:save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

SamlBtn.propTypes = {
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    entityId: PropTypes.string,
    ssoUrl: PropTypes.string,
    cert: PropTypes.string,
  }),
  certIsRequired: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

SamlBtn.defaultProps = {
  settings: {
    entityId: "",
    ssoUrl: "",
    cert: "",
  },
};

export default SamlBtn;
