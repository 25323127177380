import { StorageProviderWithAuth } from "@dashboard-v3/api";

type Option = Record<
  StorageProviderWithAuth | "ONEDRIVE_ORG",
  {
    label: string;
    windowSize: { width: number; height: number };
    /** Path use in the URL
     * requested. If it is empty then use
     * the storage type (@see src/pages/Storages/api#getAuthUrl) */
    path?: string;
  }
>;

const cloudStorageOption: Option = {
  BOX: {
    label: "Box",
    windowSize: { width: 450, height: 655 },
  },
  GOOGLEDRIVE: {
    label: "Google Drive",
    windowSize: { width: 500, height: 680 },
    path: "drive",
  },
  ONEDRIVE: {
    label: "OneDrive",
    windowSize: { width: 450, height: 620 },
    path: "onedrive/individual",
  },
  EGNYTE: {
    label: "Egnyte",
    windowSize: { width: 400, height: 500 },
  },
  ONEDRIVE_ORG: {
    label: "OneDrive",
    windowSize: { width: 450, height: 620 },
    path: "onedrive/organizations",
  },
  DROPBOX: {
    label: "Dropbox",
    windowSize: { width: 450, height: 620 },
  },
  EGNYTE_GOV: {
    label: "Egnyte Gov",
    windowSize: { width: 400, height: 500 },
    path: "egnytegov",
  },
};

export default cloudStorageOption;
