import { FormHelperText, MenuItem } from "@material-ui/core";
import { Label, OptionGroup } from "components/Styled";
import SelectCollaboratePath from "./SelectCollaboratePath";
import SelectField from "components/Forms/SelectField";
import { CollaboratorRole, StorageProvider } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";

export interface Params {
  currentPath: string;
  position?: number;
  role?: CollaboratorRole;
  provider: StorageProvider;
  onChange: (
    type: "position" | "role",
    value: number | CollaboratorRole
  ) => void;
}

export default function CollaborateSubfolder({
  currentPath,
  position,
  role,
  provider,
  onChange,
}: Params) {
  const { t } = useTranslation("rules");

  return (
    <>
      <SelectCollaboratePath
        currentPath={currentPath}
        position={position}
        onChange={event => {
          onChange("position", parseInt(event.target.value, 10));
        }}
      />
      <OptionGroup>
        <Label wording={t("storageFilepath.roles.title")} />
        <SelectField
          name="role"
          label={t("storageFilepath.roles.label")}
          options={getRoleOptions(provider)}
          value={role || ""}
          onChange={event => {
            onChange("role", event.target.value as CollaboratorRole);
          }}
          renderOption={role => (
            <MenuItem key={role} value={role}>
              {t(`storageFilepath.roles.options.${role}`)}
            </MenuItem>
          )}
        />
        {!role && (
          <FormHelperText
            margin="dense"
            error
            data-testid="storageFilepath_roles_error"
          >
            {t("storageFilepath.roles.error")}
          </FormHelperText>
        )}
      </OptionGroup>
    </>
  );
}

function getRoleOptions(provider: StorageProvider) {
  switch (provider) {
    case "GOOGLEDRIVE":
    case "ONEDRIVE":
      return ["CO_OWNER", "EDITOR", "VIEWER"];

    case "DROPBOX":
      return ["EDITOR", "VIEWER"];

    case "BOX":
      return ["CO_OWNER", "EDITOR", "PREVIEWER", "VIEWER"];

    default:
      return [];
  }
}
