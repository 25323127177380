import { useState } from "react";
import apiRequest from "utils/apiRequestWithErrorCode";
import { PlatformVariable } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";
import secureJsonParse from "./secureJsonParse";
import { DashboardVariable } from "types";

type Status = "UNLOAD" | "LOADING" | "LOADED";

const storageKey = "platformVariables";

function getFromStorage() {
  return localStorage.getItem(storageKey);
}

function isInStorage() {
  return Boolean(getFromStorage());
}

export const usePlatformVariables = () => {
  const { i18n } = useTranslation(storageKey);
  const [status, setStatus] = useState<Status>(
    isInStorage() ? "LOADED" : "UNLOAD"
  );
  const [platformVariables, setPlatformVariables] = useState<
    DashboardVariable[]
  >(secureJsonParse(getFromStorage()));

  const getTranslationKeys = (varId: string) => {
    const config = { ns: storageKey };
    const nameKey = `variables.${varId}.name`;
    const descriptionKey = `variables.${varId}.description`;
    const nameExists = i18n.exists(nameKey, config);
    const descExists = i18n.exists(descriptionKey, config);
    return {
      name: nameExists ? nameKey : varId,
      description: descExists ? descriptionKey : "helper.emptyDescription",
    };
  };

  const loadPlatformVariables = async () => {
    setStatus("LOADING");
    try {
      const response = await apiRequest<PlatformVariable[]>(
        "GET",
        "/platform/variables/sources"
      );

      const variables = response.map(v => {
        const t = getTranslationKeys(v.id);
        return {
          ...v,
          id: `{${v.id}}`,
          name: t.name,
          description: t.description,
          origin: "PLATFORM",
        } as DashboardVariable;
      });

      setPlatformVariables(variables);
      localStorage.setItem(storageKey, JSON.stringify(variables));
      setStatus("LOADED");
    } catch (error) {
      setStatus("UNLOAD");
    }
  };

  return {
    status,
    variables: platformVariables,
    loadPlatformVariables,
  };
};

export const clearVariablesFromStorage = () =>
  localStorage.removeItem(storageKey);
