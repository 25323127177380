import { CopyPolicy, CopyPolicyReqBody } from "@dashboard-v3/api";

import { DefaultType, FormState } from "./types";

export const toFormState = (policy: CopyPolicy): FormState => {
  return {
    name: policy.name,
    description: policy.description,
    conditionMatch: policy.firstMatchOnly ? "first" : "all",
    predicates: policy.predicates,
    defaultCopies: policy.defaultCopies.reduce((acc, item) => {
      return { ...acc, [item.type]: { ...item.folderPath } };
    }, {}),

    ...(policy.storageType && { storageProvider: policy.storageType }),
  };
};

export const toPolicyReqBody = (form: FormState): CopyPolicyReqBody => {
  const policy: CopyPolicyReqBody = {
    name: form.name,
    description: form.description,
    firstMatchOnly: form.conditionMatch === "first",
    predicates: form.predicates,
  };

  if (form.defaultCopies) {
    const keys = Object.keys(form.defaultCopies) as DefaultType[];
    const defaultCopies = keys.map(type => {
      return { type, folderPath: form.defaultCopies[type] };
    });

    if (defaultCopies.length) {
      policy.defaultCopies = defaultCopies;
    }
  }

  if (form.storageProvider) {
    policy.storageType = form.storageProvider;
  }

  return policy;
};
