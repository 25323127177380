/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 as keyGen } from "uuid";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Skeleton } from "@material-ui/lab";
import SearchInput from "components/AutocompleteSearchInput";
import { useConditionsCtx } from "pages/Variables/state/ConditionsProvider";
import useIaContext from "pages/Variables/useIaContext";
import useVariables, { toUserVariableOption } from "utils/useVariables";
import { DashboardVariable } from "types";
import { Variable } from "@dashboard-v3/api";

type ContextState = {
  context: string;
  answerFormat: string;
};

const initContext = () => ({ context: "", answerFormat: "" });

export default function OpenAIContext() {
  const { t } = useTranslation("variables");
  const classes = useStyles();
  const { iaContext } = useIaContext();
  const [textarea, setTextarea] = useState<HTMLTextAreaElement>(null);
  const [contextState, setContextState] = useState<ContextState>(initContext);
  const [enableEdit, setEnableEdit] = useState(false);
  const { mapVarToLabel, mapVarToValue, platformVariables, userVariables } =
    useVariables();
  const { updateCondition, selectFromCondition } = useConditionsCtx();
  const { parameters } = selectFromCondition();
  const defaultContext = iaContext?.defaultPrompt;
  const defaultAnswerFormat = iaContext?.aiTemplate?.validationRegex;

  const autocompleteVariables = [
    ...platformVariables,
    ...filterNotCompoundVariables(userVariables),
  ];

  useEffect(() => {
    const newContext = parameters?.length >= 2 ? parameters[1] : defaultContext;
    const answerFormat = parameters[2] || defaultAnswerFormat;

    setContextState({
      context: mapVarToLabel(newContext),
      answerFormat,
    });
  }, [iaContext]);

  useLayoutEffect(() => {
    if (textarea) {
      textarea.style.height = "inherit";
      textarea.style.height = `${Math.max(textarea.scrollHeight - 30, 75)}px`;
    }
  }, [textarea, contextState.context]);

  function revertToDefault() {
    setContextState({
      context: mapVarToLabel(defaultContext),
      answerFormat: defaultAnswerFormat,
    });
  }

  function saveChanges() {
    const { context, answerFormat } = contextState;
    const contextUpdated = mapVarToValue(context);
    const update = [parameters[0]];

    if (defaultContext !== contextUpdated) {
      update[1] = contextUpdated;
      update[2] = answerFormat;
    } else if (answerFormat !== defaultAnswerFormat) {
      update[1] = defaultContext;
      update[2] = answerFormat || defaultAnswerFormat;
      setContextState(prev => ({
        ...prev,
        answerFormat: answerFormat || defaultAnswerFormat,
      }));
    }

    updateCondition({ parameters: update });
    setEnableEdit(false);
  }

  function cancelChanges() {
    setEnableEdit(false);
    const answerFormat = parameters[2] || defaultAnswerFormat;
    const context =
      parameters?.length > 1
        ? mapVarToLabel(parameters[1])
        : mapVarToLabel(defaultContext);

    setContextState({ context, answerFormat });
  }

  function getHighlightedContext() {
    const { context } = contextState;

    if (!context) return "";

    const varRegex = /(?=[{])|(?<=[}])/g;
    const parts = context.split(varRegex);
    return parts.map(part => {
      const key = keyGen();
      return varRegex.test(part) ? <b key={key}>{part}</b> : part;
    });
  }

  function onContextChange(context) {
    setContextState(prev => ({ ...prev, context }));
  }

  function onAnswerFormatChange(e) {
    const { value: answerFormat } = e.target;
    setContextState(prev => ({ ...prev, answerFormat }));
  }

  const onCollapse: AccordionProps["onChange"] = (_, expanded) => {
    if (!expanded) cancelChanges();
  };

  if (!iaContext) {
    return (
      <Skeleton variant="rect" animation="wave" width="100%" height={50} />
    );
  }

  return (
    <>
      <Accordion className={classes.accordion} onChange={onCollapse}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" color="textSecondary">
            {t("form.aiQuestion.context.title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {!enableEdit ? (
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                component="pre"
                className={classes.accordionTitle}
              >
                {getHighlightedContext()}
              </Typography>
            </Box>
          ) : (
            <SearchInput
              fullWidth
              className={classes.contextInput}
              variables={autocompleteVariables}
              innerRef={e => setTextarea(e)}
              value={contextState.context}
              onChange={onContextChange}
              error={!contextState.context}
              errorText={t("form.aiQuestion.context.emptyError", {
                actionBtnLabel: t("form.aiQuestion.context.revertBtn"),
              })}
            />
          )}
          {!enableEdit && <Divider />}
          <div>
            <Typography
              variant="subtitle1"
              className={classes.answerFormatTitle}
            >
              {t("form.aiQuestion.answerFormat.title")}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <Trans i18nKey="variables:form.aiQuestion.answerFormat.description">
                <b></b>
              </Trans>
            </Typography>
            <TextField
              fullWidth
              className={classes.answerFormatinput}
              variant="outlined"
              label={t("form.aiQuestion.answerFormat.label")}
              value={contextState.answerFormat || ""}
              onChange={onAnswerFormatChange}
              disabled={!enableEdit}
            />
          </div>
        </AccordionDetails>
        <AccordionActions
          className={!contextState.context ? classes.accordionAction : ""}
        >
          {enableEdit && (
            <>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                onClick={saveChanges}
                disabled={!contextState.context}
              >
                {t("common:save")}
              </Button>
              <Button size="small" onClick={cancelChanges}>
                {t("common:cancel")}
              </Button>
              <Button size="small" color="secondary" onClick={revertToDefault}>
                {t("form.aiQuestion.context.revertBtn")}
              </Button>
            </>
          )}
          {!enableEdit && (
            <Button
              color="primary"
              size="small"
              variant="contained"
              disableElevation
              onClick={() => setEnableEdit(true)}
            >
              {t("common:edit")}
            </Button>
          )}
        </AccordionActions>
      </Accordion>
    </>
  );
}

const useStyles = makeStyles({
  contextInput: {
    fontSize: "0.875rem",
    minHeight: 75,
    resize: "none",
  },
  answerFormatTitle: { fontWeight: 500 },
  answerFormatinput: {
    marginTop: "20px",
  },
  accordionTitle: { whiteSpace: "pre-wrap" },
  accordion: {
    margin: "0 !important",
    border: "1px solid rgba(0,0,0,0.14)",
    width: "100%",
    borderRadius: "4px",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  accordionDetails: { display: "flex", flexDirection: "column", gap: "35px" },
  accordionAction: { marginTop: "20px" },
});

function filterNotCompoundVariables(userVariables: Variable[]) {
  return (userVariables || []).reduce((mapped, variable) => {
    if (!variable.compound) {
      mapped.push(toUserVariableOption(variable));
    }
    return mapped;
  }, [] as Array<DashboardVariable>);
}
