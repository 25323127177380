import React from "react";
import { Mailbox, TimeRange, ProviderAccounts } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dayjs from "dayjs";
import { UnstyledList } from "./Styles";
import GroupName from "./GroupName";

const ImapHost = styled.li`
  overflow-wrap: break-word;
`;

function useStorageProvider(
  provider: Mailbox["provider"],
  authentication: Mailbox["authentication"]
) {
  const { t } = useTranslation("rules");

  if (provider && authentication) {
    const serverType = `${provider}_${authentication}`;
    return t(`mailBoxServerType.${serverType}`);
  }
  return "";
}

const TimeRangeLine = ({ range, repeatable }) => {
  const { t } = useTranslation("rules");

  if (!range) {
    if (!repeatable) return <li>{t("ALL")}</li>;
    return <li>{t("REPEAT_ALL")}</li>;
  }

  const { mode } = range as TimeRange;

  switch (mode) {
    case "DATES":
      if ("since" in range && "until" in range) {
        return <li>{t("BETWEEN_DATES")}</li>;
      }
      if ("since" in range) {
        return (
          <li>
            {t("sinceTheDate", {
              date: dayjs(range.since).format("DD/MM/YYYY"),
              interpolation: { escapeValue: false },
            })}
          </li>
        );
      }
      return <li>{t("UP_TO_A_DATE")}</li>;
    case "AFTER_UNIT":
      return <li>{t("REPEAT_SINCE")}</li>;
    case "BEFORE_UNIT":
      return <li>{t("REPEAT_UP_TO")}</li>;
    default:
      return null;
  }
};

function elementsToShow(entities: ProviderAccounts["entities"]) {
  let elements = entities.slice(0, 3);
  if (entities.length > 3) elements.push("...");

  return elements.join(", ") as string;
}

const TargetAccounts = ({ accounts }: { accounts: ProviderAccounts }) => {
  const { entities, type } = accounts;

  const { t } = useTranslation("rules");
  if (type === "ORGANIZATION") {
    return <li>{t("selectAccounts.organization")}</li>;
  }

  if (type === "GROUP") {
    if (entities.length === 0) return null;
    return (
      <li>
        {t(`selectAccounts.type.${type}`, { count: entities.length })}
        <Join
          items={entities}
          render={groupId => <GroupName id={groupId} />}
          lastSeparator={t("emailFlowSection.and")}
        />
      </li>
    );
  }

  return (
    <li>
      {t(`selectAccounts.type.${type}`, {
        count: entities.length,
        [type]: elementsToShow(entities),
      })}
    </li>
  );
};

function Join({
  items,
  render,
  lastSeparator,
}: {
  items: string[];
  render: (item: string) => React.ReactElement;
  lastSeparator: string;
}) {
  const isLastItem = (index: number) =>
    items.length > 1 && items.length === index + 1;

  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={item}>
          {isLastItem(index) && `${" "}${lastSeparator}${" "}`}
          {Boolean(index) && !isLastItem(index) && ", "}
          {render(item)}
        </React.Fragment>
      ))}
    </>
  );
}

export default function MailboxDetails({ mailbox }: { mailbox: Mailbox }) {
  const {
    provider,
    authentication,
    imapServer,
    accounts,
    timeRange,
    repeatable,
  } = mailbox;
  const storageProvider = useStorageProvider(provider, authentication);

  return (
    <UnstyledList>
      {storageProvider && (
        <>
          <li>{storageProvider}</li>
          {imapServer?.host && <ImapHost>{imapServer.host}</ImapHost>}
          <TargetAccounts accounts={accounts} />
        </>
      )}
      <TimeRangeLine range={timeRange} repeatable={repeatable} />
    </UnstyledList>
  );
}
