import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import { Paper, Typography } from "@material-ui/core";
import AddAccountBtn from "../Buttons/AddOrEditAccount";
import AddSourceBtn from "../Buttons/AddSource";

export default function Empty() {
  const { t } = useTranslation("accounts");

  return (
    <MainPanel data-testid="accounts__account-list">
      <div>
        <Typography style={{ marginBottom: "8px" }}>
          {t("list.emptyAccountList")}
        </Typography>
        <Typography>
          <Trans i18nKey="list.howToAddAccounts" t={t}>
            You can <AddAccountBtn size="small" />
            or <AddSourceBtn omitOptions={[]} /> to import accounts.
          </Trans>
        </Typography>
      </div>
    </MainPanel>
  );
}

const MainPanel = styled(Paper)`
  min-height: 247px;
  align-self: start;
  align-items: center;
  display: grid;
  padding: 16px;
  text-align: center;
`;
