import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "components/Forms/StyledComponents";
import useAuthPopup from "components/useAuthPopup";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function EgnyteTenantModal({ isOpen, onClose }: ModalProps) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [tenant, setTenant] = useState("");
  const { open } = useAuthPopup();

  const onDomainChange = e => {
    const { value } = e.target;
    setTenant(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    try {
      const params = new URLSearchParams();
      params.append("tenant", tenant);
      const url = `/storage-accounts/egnytegov/authorize?${params.toString()}`;
      open({ url });
    } catch (e) {
      enqueueSnackbar(t("egnyteGov.unknownAddError"), { variant: "error" });
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6">{t("egnyteGovModal.title")}</Typography>
        <CloseButton size="small" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0px 24px 24px 24px" }}>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ fontSize: "13px" }}
        >
          {t("egnyteGovModal.subtitle")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TenantInput
            name="egnyteTenant"
            variant="outlined"
            fullWidth
            style={{ marginTop: "5px" }}
            placeholder={t("egnyteGovModal.tenant.placeholder")}
            value={tenant}
            onChange={onDomainChange}
          />
          <Button
            type="submit"
            fullWidth
            style={{ marginTop: "5px" }}
            wording={t("egnyteGovModal.submitBtn")}
            disabled={!tenant}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export const TenantInput = styled(TextField)({
  marginBottom: "8px",
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "98%",
    fontSize: "11px",
  },
});

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
