import { List, ListItem, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { orange } from "@material-ui/core/colors";
import { FormatErrorItem } from "./styled";
import { ImportCSVResult } from "../utils";
import { useTranslation } from "react-i18next";

interface FormatErrorProps {
  file: ImportCSVResult;
}

export default function FormatErrorList({ file }: FormatErrorProps) {
  const { t } = useTranslation("accounts");
  const { name, importErrors } = file;

  return (
    <List>
      <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
        {t("importAccountsModal.filename", { name })}
      </Typography>
      {importErrors.map(({ row, value, error }) => {
        const translateProps = { row, value, error: t(error) };
        return (
          <ListItem disableGutters>
            <FormatErrorItem>
              {!value && (
                <>
                  <ErrorOutlineIcon color="error" />
                  <Typography variant="caption">{t(error)}</Typography>
                </>
              )}
              {value && (
                <>
                  <WarningRoundedIcon style={{ color: orange[500] }} />
                  <Typography variant="caption">
                    {t("importAccountsModal.files.formatError", translateProps)}
                  </Typography>
                </>
              )}
            </FormatErrorItem>
          </ListItem>
        );
      })}
    </List>
  );
}
