import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "components/Forms/StyledComponents";
import { getStorageWebhook } from "pages/Rules/CreateOrEdit/context/helpers";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import ConflictFolderWarning from "./ConflictFolderWarning";
import { EmptyListContainer, StyledFolderItem } from "./Styled";
import { ListContainer, ListTitleWrapper } from "./Styled";
import { handleWebhookCheck, toFolderItem } from "./utils";
import { StorageWebhookUpdate } from "pages/Rules/CreateOrEdit/context/reducers/updateStep";

interface Props {
  setOpenModal: React.Dispatch<SetStateAction<boolean>>;
}

const FolderPathList = ({ setOpenModal }: Props) => {
  const { t } = useTranslation("rules");
  const [loadingItem, setLoadingItem] = useState<string>();
  const { state, dispatch } = useRuleContext();
  const webhook = getStorageWebhook(state.rule);
  const { foldersInUse, missingFolder } = state.webhookStatus;

  const deleteFolderItem = async (folderId: string) => {
    const { folders: currentFolders } = webhook;
    const folders = currentFolders.filter(({ id }) => id !== folderId);
    const changes: Partial<StorageWebhookUpdate> = { folders };

    if (folders.length) {
      setLoadingItem(folderId);
      const webhookStatus = await handleWebhookCheck(
        folders,
        webhook.account,
        state.rule.id
      );
      changes.webhookStatus = webhookStatus;
    }

    dispatch({
      type: "updateStep",
      payload: {
        step: "target",
        changes,
      },
    });
    setLoadingItem("");
  };

  const handleModal = () => setOpenModal(true);

  if (!webhook.folders.length) {
    return (
      <EmptyListContainer>
        <Button
          variant="text"
          wording={t("webhookSection.list.empty")}
          onClick={handleModal}
        />
      </EmptyListContainer>
    );
  }

  return (
    <>
      <ListTitleWrapper>
        <Typography variant="subtitle2" color="textSecondary">
          {t("webhookSection.list.title")}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          wording={t("webhookSection.list.btn")}
          onClick={handleModal}
        />
      </ListTitleWrapper>
      <ListContainer>
        <List dense>
          {webhook.folders.map(folder => {
            const item = toFolderItem(folder, foldersInUse, missingFolder);
            return (
              <StyledFolderItem
                key={item.id}
                button={false as any}
                $inConflict={item.isInUse || item.isMissing}
              >
                {(item.isInUse || item.isMissing) && (
                  <ConflictFolderWarning
                    name={item.name}
                    path={item.path}
                    isMissing={item.isMissing}
                    folderInUse={item.folderInUse}
                  />
                )}
                {!item.isInUse && !item.isMissing && (
                  <>
                    <ListItemAvatar>
                      <Avatar variant="rounded">
                        <FolderIcon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} secondary={item.path} />
                  </>
                )}
                <ListItemSecondaryAction>
                  {loadingItem === item.id ? (
                    <div style={{ paddingTop: "10px" }}>
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    <IconButton
                      edge="end"
                      size="medium"
                      onClick={() => deleteFolderItem(item.id)}
                    >
                      <DeleteIcon fontSize="medium" />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </StyledFolderItem>
            );
          })}
        </List>
      </ListContainer>
    </>
  );
};

export default FolderPathList;
