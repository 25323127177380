import { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion } from "components/AccordionForm";
import SwitchField from "components/Forms/SwitchField";
import { DateInputContainer, EmailInputContainer } from "./Styled";

import { TracingSearchRequest } from "@dashboard-v3/api";
import { Status } from ".";
import ButtonWithLoading from "components/ButtonWithLoading";

type Params = {
  status: Status;
  disableCreateReport: boolean;
  expanded: boolean;
  onExpand: () => void;
  onSearch: (criteria: TracingSearchRequest) => void;
  onCreateReport: (criteria: TracingSearchRequest) => void;
};

dayjs.extend(utc);

export default function TracingSearch({
  status,
  disableCreateReport,
  expanded,
  onExpand,
  onSearch,
  onCreateReport,
}: Params) {
  const { t } = useTranslation("tracing");
  const [form, setForm] = useState(initForm);

  const handleOnChange = event => {
    const { name, value, checked } = event.target;
    let update = value;
    if (name === "hasError") {
      update = checked;
    }
    setForm(previous => ({ ...previous, [name]: update }));
  };

  const toSearchCriteria = () => {
    const criteria = {
      dateFrom: dayjs(`${form.fromDate} ${form.fromTime}`).utc().valueOf(),
      dateTo: dayjs(`${form.toDate} ${form.toTime}`).utc().valueOf(),
      ...(form.sender && { sender: form.sender }),
      ...(form.recipient && { recipient: form.recipient }),
      ...(form.subject && { subject: form.subject }),
      ...(form.messageId && { messageId: form.messageId }),
      ...(form.hasError && { hasError: form.hasError }),
    };

    return criteria;
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSearch(toSearchCriteria());
  };

  const handleCreateReport = e => {
    e.preventDefault();
    onCreateReport(toSearchCriteria());
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={onExpand}
      data-testid="tracing_search"
    >
      <AccordionSummary
        data-testid="tracing__search-filter-summary"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="subtitle2">{t("searchFilters")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <EmailInputContainer>
            <TextField
              id="sender"
              inputProps={{ "data-testid": "tracing-search__sender-textfield" }}
              name="sender"
              label={t("sender")}
              onInput={handleOnChange}
              value={form.sender}
              variant="outlined"
            />
            <TextField
              id="recipient"
              inputProps={{
                "data-testid": "tracing-search__recipient-textfield",
              }}
              name="recipient"
              label={t("recipient")}
              onInput={handleOnChange}
              value={form.recipient}
              variant="outlined"
            />
            <TextField
              inputProps={{
                "data-testid": "tracing-search__subject-textfield",
              }}
              name="subject"
              label={t("subject")}
              onInput={handleOnChange}
              value={form.subject}
              variant="outlined"
            />
            <TextField
              inputProps={{
                "data-testid": "tracing-search__message-id-textfield",
              }}
              name="messageId"
              label={t("messageId")}
              onInput={handleOnChange}
              value={form.messageId}
              variant="outlined"
            />
          </EmailInputContainer>
          <DateInputContainer>
            <TextField
              inputProps={{
                "data-testid": "tracing-search__from-date-textfield",
                ...getDateLimits(),
              }}
              name="fromDate"
              label={t("common:fromDate")}
              type="date"
              onChange={handleOnChange}
              value={form.fromDate}
              variant="outlined"
            />
            <TextField
              inputProps={{
                "data-testid": "tracing-search__from-time-textfield",
              }}
              name="fromTime"
              label={t("common:time")}
              type="time"
              onChange={handleOnChange}
              value={form.fromTime}
              variant="outlined"
            />
            <TextField
              inputProps={{
                "data-testid": "tracing-search__to-date-textfield",
                ...getDateLimits(),
              }}
              name="toDate"
              label={t("common:toDate")}
              type="date"
              onChange={handleOnChange}
              value={form.toDate}
              variant="outlined"
            />
            <TextField
              inputProps={{
                "data-testid": "tracing-search__to-time-textfield",
              }}
              name="toTime"
              label={t("common:time")}
              type="time"
              onChange={handleOnChange}
              value={form.toTime}
              variant="outlined"
            />
          </DateInputContainer>
          <div style={{ marginBottom: "30px" }}>
            <SwitchField
              name="hasError"
              label={t("hasError.label")}
              description={t("hasError.description")}
              value={form.hasError}
              onChange={handleOnChange}
            />
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              color="primary"
              data-testid="searchBtn"
              disableElevation
              type="submit"
              variant="contained"
            >
              {t("search")}
            </Button>
            <ButtonWithLoading
              loading={status === Status.GeneratingReport}
              color="primary"
              data-testid="createReportBtn"
              disableElevation
              variant="outlined"
              onClick={handleCreateReport}
              disabled={disableCreateReport}
            >
              {t("reports.createBtn")}
            </ButtonWithLoading>
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );
}

const dateFormat = "YYYY-MM-DD";

function now() {
  return dayjs().format(dateFormat);
}

function lastWeek() {
  return dayjs().subtract(7, "days").format(dateFormat);
}

function getDateLimits() {
  const date = dayjs();
  return {
    min: date.subtract(1, "year").format(dateFormat),
    max: date.format(dateFormat),
  };
}

function initForm() {
  return {
    sender: "",
    recipient: "",
    subject: "",
    messageId: "",
    fromDate: lastWeek(),
    fromTime: "00:00",
    toDate: now(),
    toTime: "23:59",
    hasError: false,
  };
}
