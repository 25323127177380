import styled from "styled-components";
import { Typography, DialogContent } from "@material-ui/core";

export const StyledTypography = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 9px;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DialogContentWrapper = styled.div`
  h6 {
    margin-bottom: 5px;
  }
  p {
    margin-top: 2px;
    margin-left: 10px;
    font-weight: 400;
  }
  p > span {
    font-weight: 300;
    margin-left: 5px;
  }
`;
