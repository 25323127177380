import React from "react";
import { useTranslation } from "react-i18next";
import { LinearProgress, Typography } from "@material-ui/core";
import AddSourceBtn from "../Buttons/AddSource";
import WarningListDialog from "./WarningListDialog";
import Item from "./Item";
import { useSourcesContext } from "../sourcesProvider";
import { Panel, PanelHeader, StyledDivider } from "../Styled";

export default function SourceList() {
  const { state, actions, t } = useSourcesContext();
  const { isLoading, items, typesAlreadyLoaded } = state;

  return (
    <Layout
      label={t("sources.list.title")}
      menu={<AddSourceBtn omitOptions={typesAlreadyLoaded} />}
    >
      {isLoading && <Loading />}

      {!isLoading && items.length === 0 && <Empty />}

      {!isLoading && items.length > 0 && (
        <>
          {items.map(source => (
            <Item key={source.id} source={source} />
          ))}
          <WarningListDialog
            open={state.showWarnings}
            onClose={actions.closeWarnings}
            warnings={state.warnings}
          />
        </>
      )}
    </Layout>
  );
}

const Layout = ({ label, menu, children }) => (
  <Panel elevation={1} data-testid="accounts__source-list_loading">
    <PanelHeader>
      <Typography
        variant="subtitle2"
        style={{ justifySelf: "start", textTransform: "capitalize" }}
      >
        {label}
      </Typography>
      <div style={{ justifySelf: "end" }}>{menu}</div>
    </PanelHeader>
    <StyledDivider />
    {children}
  </Panel>
);

const Loading = React.memo(() => (
  <div style={{ marginBottom: "8px", marginTop: "40px" }}>
    <LinearProgress />
  </div>
));

const Empty = React.memo(() => {
  const { t } = useTranslation("accounts");
  return (
    <>
      <Typography style={{ marginBottom: "8px", marginTop: "40px" }}>
        {t("sources.emptySourceList")}
      </Typography>
      <Typography style={{ marginBottom: "40px" }}>
        {t("sources.howToAddSources")}
      </Typography>
    </>
  );
});
