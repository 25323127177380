import { useTranslation } from "react-i18next";
import { ButtonGroup, Typography } from "@material-ui/core";
import { FilterBy } from "./statLinesConfig";
import ToggleButton from "../../ToggleButton";

type Params = {
  value: FilterBy;
  onChange: (newValue: FilterBy) => void;
};

export default function FilterOptions({ value, onChange }: Params) {
  const { t } = useTranslation("usageReports");

  return (
    <>
      <Typography
        display="inline"
        variant="body2"
        style={{ marginRight: ".5rem" }}
      >
        {t("processedEmails.filter.label")}
      </Typography>
      <ButtonGroup
        disableElevation
        variant="contained"
        color="primary"
        size="small"
      >
        <ToggleButton active={value === "all"} onClick={() => onChange("all")}>
          {t("processedEmails.filter.all")}
        </ToggleButton>
        <ToggleButton
          active={value === "action"}
          onClick={() => onChange("action")}
        >
          {t("processedEmails.filter.action")}
        </ToggleButton>
        <ToggleButton
          active={value === "target"}
          onClick={() => onChange("target")}
        >
          {t("processedEmails.filter.target")}
        </ToggleButton>
      </ButtonGroup>
    </>
  );
}
