import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import apiRequest from "utils/apiRequestWithErrorCode";
import { SecurityDefinition, StorageProvider } from "@dashboard-v3/api";
import { byLastModified } from "./sort";

type Result = {
  fetchDefinitions: (limit: number, offset?: number) => Promise<void>;
  securityDefinitions: SecurityDefinition[];
  loadMore: (limit: number) => Promise<void>;
  loading: boolean;
  hasMore: boolean;
  getByStorageType: (storage?: StorageProvider) => SecurityDefinition[] | [];
  getPresetsByProvider: (storage?: StorageProvider) => SecurityDefinition[];
};

export default function useSecurityDefinitions(): Result {
  const { t } = useTranslation("securityDefinitions");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [presets, setPresets] = useState<SecurityDefinition[]>();
  const [userDefined, setUserDefined] = useState<SecurityDefinition[]>();
  const [page, setPage] = useState(0);

  const [securityDefinitions, setSecurityDefinitions] =
    useState<SecurityDefinition[]>();

  async function fetchDefinitions(itemsByPage: number): Promise<void> {
    setLoading(true);
    setHasMore(false);
    try {
      let presetsLoaded = presets;
      if (!presetsLoaded) {
        presetsLoaded = await apiRequest<SecurityDefinition[]>(
          "GET",
          "/platform/security-definitions"
        );
        setPresets(presetsLoaded);
      }

      const customDefinitions = await apiRequest<SecurityDefinition[]>(
        "GET",
        `/security-definitions?offset=0&limit=${itemsByPage}`
      );

      setHasMore(customDefinitions.length === itemsByPage);

      const sorted = customDefinitions.sort(byLastModified);
      setUserDefined(sorted);
      setSecurityDefinitions(presetsLoaded.concat(sorted));
    } catch (error: unknown) {
      console.log(error);
      enqueueSnackbar(t(`errors.definitionsRequests.getError`), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function loadMore(itemsByPage: number) {
    setPage(page + 1);
    setLoading(true);
    const customDefinitions = await apiRequest<SecurityDefinition[]>(
      "GET",
      `/security-definitions?offset=${
        (page + 1) * itemsByPage
      }&limit=${itemsByPage}`
    );
    setHasMore(customDefinitions.length === itemsByPage);
    const items = userDefined.concat(customDefinitions).sort(byLastModified);
    setUserDefined(items);
    setSecurityDefinitions(presets.concat(items));
    setLoading(false);
  }

  const getByStorageType = (storage?: StorageProvider) => {
    if (!securityDefinitions) return [];

    return securityDefinitions.filter(item => {
      if (item.systemDefault) return false;
      if (storage) return item.allowedStorages.includes(storage);
      return true;
    });
  };

  const getPresetsByProvider = (storage?: StorageProvider) => {
    if (!securityDefinitions) return [];

    return securityDefinitions.filter(item => {
      if (!item.systemDefault) return false;
      if (storage) return item.allowedStorages.includes(storage);
      return true;
    });
  };

  return {
    fetchDefinitions,
    loadMore,
    securityDefinitions,
    loading,
    hasMore,
    getByStorageType,
    getPresetsByProvider,
  };
}
