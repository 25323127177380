export default function getSharePathOptions(path: string) {
  return splitByPaths(path).map((item, index) => ({
    key: `${index}_${item}`,
    text: item,
    value: index + 1,
  }));
}

export function splitByPaths(value: string) {
  let inVariable = false;
  const items = [];
  let item = "";
  for (let char of value) {
    if (char === "{") inVariable = true;
    if (char === "}") inVariable = false;

    if (char === "/" && !inVariable) {
      items.push(item);
      item = "";
    } else {
      item += char;
    }
  }

  if (item) items.push(item);

  return items;
}
