const icons = {
  PROTECT_EMAILS: (
    <>
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
      <circle cx="12" cy="11" r="1" />
      <line x1="12" y1="12" x2="12" y2="14.5" />
    </>
  ),
  REPLACE_ATTACHMENTS: (
    <>
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3" />
      <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3" />
    </>
  ),
  DRAG_AND_DROP: (
    <>
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M19 11v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
      <path d="M13 13l9 3l-4 2l-2 4l-3 -9" />
      <line x1="3" y1="3" x2="3" y2="3.01" />
      <line x1="7" y1="3" x2="7" y2="3.01" />
      <line x1="11" y1="3" x2="11" y2="3.01" />
      <line x1="15" y1="3" x2="15" y2="3.01" />
      <line x1="3" y1="7" x2="3" y2="7.01" />
      <line x1="3" y1="11" x2="3" y2="11.01" />
      <line x1="3" y1="15" x2="3" y2="15.01" />
    </>
  ),
};

export const ActionIcon = ({ type }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
      width="1em"
      height="1em"
      fontSize="inherit"
      viewBox="0 0 24 24"
      strokeWidth="1.75"
      style={{ stroke: "currentColor", fill: "none" }}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {icons[type]}
    </svg>
  );
};
