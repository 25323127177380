import styled from "styled-components";
import {
  Accordion,
  AccordionSummary,
  ListItemSecondaryAction,
  AccordionDetails,
} from "@material-ui/core";

export const DefinitionsContainer = styled.div`
  width: 100%;
`;

export const ListItemAccordion = styled(Accordion)`
  width: 100%;
`;

export const StyledSecondaryAction = styled(ListItemSecondaryAction)`
  display: flex;
  justify-content: space-between;
  margin-right: 35px;
  width: 140px;
`;

export const StyledDetails = styled(AccordionDetails)`
  padding: 16px 0 16px 40px;
  display: block;
`;

export const StyledContentList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 15px 25px;
  padding: 0;
  p {
    margin: 0;
  }
`;

export const StyledSummary = styled(AccordionSummary)<{ collapsed?: string }>`
  height: ${({ collapsed }) => (collapsed === "true" ? "unset" : "80px")};
  transition: 500ms ease-in-out;
  box-shadow: ${({ collapsed }) =>
    collapsed === "true" ? "none" : "0px 18px 1px -18px grey"};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SwitchDescription = styled.span`
  font-size: 14px;
  color: grey;
  width: 80%;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  max-height: 300px;
  overflow: auto;
  margin-bottom: 16px;
  padding-bottom: 0;
`;
