/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import nextId from "react-id-generator";
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { fetchGroup } from "utils/api/groups";
import { Group } from "@dashboard-v3/api";
import { Suspense } from "react";
import useSWR from "swr";
import StorageProperties from "../AccountList/StorageProperties";
import { StyledListContainer } from "./Styled";
import styled from "styled-components";

interface ItemDetailsProps {
  accounts: Group["members"];
  id: string;
}

export default function ItemDetails({ accounts, id }: ItemDetailsProps) {
  const { t } = useTranslation(["accounts", "rules"]);
  const classes = useStyles();

  return (
    <>
      <Box mb={2}>
        <PropertyLabel>{t("groups.members")}</PropertyLabel>
      </Box>
      <StyledListContainer>
        <List data-testid="accounts__group-list-item-details" disablePadding>
          {accounts.length === 0 && (
            <span className={classes.noMembers}>{t("noMembers")}</span>
          )}
          {accounts.map(account => (
            <ListItem disableGutters key={nextId()}>
              <div className={classes.dataContainer}>
                <div className={classes.accountContainer}>{account}</div>
              </div>
            </ListItem>
          ))}
        </List>
      </StyledListContainer>

      <Suspense fallback={<Loading />}>
        <StorageDetails id={id} />
      </Suspense>
    </>
  );
}

const PropertyLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.82rem;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

function StorageDetails({ id }) {
  const { t } = useTranslation(["accounts"]);

  const { data: groupDetails } = useSWR(`/groups/${id}`, () => fetchGroup(id), {
    suspense: true,
  });

  const storageDetails = groupDetails?.storage;

  if (!storageDetails) return <></>;

  return (
    <>
      <Box mb={3} mt={4}>
        <PropertyLabel>{t("groups.storageAccounts")}</PropertyLabel>
      </Box>
      <StorageProperties
        providers={groupDetails?.storage}
        validateStorage={false}
      />
    </>
  );
}

function Loading() {
  return (
    <Box mb={2} mt={1}>
      <LinearProgress />
    </Box>
  );
}

const useStyles = makeStyles({
  noMembers: { color: grey[500] },
  dataContainer: { display: "flex", flexDirection: "column" },
  accountContainer: { display: "flex", gap: "10px", alignItems: "center" },
  errorText: { fontSize: "11px" },
});
