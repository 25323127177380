/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OptionGroup } from "components/Styled";
import SwitchField from "components/Forms/SwitchField";
import ButtonWithLoading from "components/ButtonWithLoading";
import { useFetch } from "utils/api/useFetch";
import FilepathSelector from "./FilepathSelector";
import SelectCopyPolicy from "./SelectCopyPolicy";
import { ContentWrapper } from "./Styled";
import { getDefaultFolderStructure, emptyLocation } from "./utils";
import { getStorage } from "../CloudStorage/utils";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import { CopyPolicy } from "@dashboard-v3/api";
import { ControlProps, LocationUpdate } from "./types";
import { State } from "../CreateOrEdit/context/types";

export default function FilepathLocation({
  controls,
  handleControls,
}: ControlProps) {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const storage = getStorage(state.rule);
  const { enablePolicy } = controls;
  const { actionType, targetType } = state.rule;
  const { copyPolicy } = storage?.location || emptyLocation();
  const defaultSubfolder = getDefaultFolderStructure(actionType, targetType, t);

  const { data: policies, isLoading } = useFetch<CopyPolicy[]>(
    "/copy-policies?limit=1000",
    { cacheIndividuals: true }
  );

  useEffect(() => {
    const { provider, location } = storage;
    const { parentId, copyPolicy } = location;

    if (
      (provider === "EGNYTE" || provider === "EGNYTE_GOV") &&
      !parentId &&
      !copyPolicy
    ) {
      updateLocation({
        parentId: "shared",
        folderStructure: defaultSubfolder,
      });
    }
  }, [storage.provider]);

  /** Dispatch the change of location in rule */
  const updateLocation = (changes: LocationUpdate) => {
    dispatch({
      type: "updateStep",
      payload: {
        step: "filePath",
        changes: { location: changes },
      },
    });
  };

  const handleConfirm = () =>
    dispatch({ type: "confirmStep", payload: "storageFilepath" });

  return (
    <ContentWrapper>
      {!enablePolicy && <FilepathSelector />}

      <OptionGroup>
        <SwitchField
          style={{ marginBottom: "5px" }}
          name="enablePolicy"
          label={t("storageFilepath.copyPolicies.enable")}
          onChange={event => {
            handleControls("enablePolicy", event.target.checked);
          }}
          value={enablePolicy}
          disabled={storage.provider === "MXHERO_STORAGE"}
        />
        {enablePolicy && (
          <SelectCopyPolicy
            options={policies}
            storageProvider={storage.provider}
            loading={isLoading}
            value={copyPolicy}
            onChange={updateLocation}
          />
        )}
      </OptionGroup>
      <ButtonWithLoading
        variant="contained"
        size="large"
        fullWidth
        disableElevation
        loading={state.asyncStatus === "validate_filename_format"}
        disabled={isConfirmDisabled(state)}
        onClick={handleConfirm}
      >
        {t("confirm")}
      </ButtonWithLoading>
    </ContentWrapper>
  );
}

function isConfirmDisabled(state: State) {
  if (state.steps?.storageFilepath?.done) return true;
  return !state.steps?.storageFilepath?.valid;
}
