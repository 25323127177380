import apiRequest from "utils/apiRequestWithErrorCode";
import i18next from "i18next";

/**
 * Errors: "SYSTEM_FAILURE" | "USER_NOT_FOUND"
 * @param {string} email
 * @returns {Promise<null>}
 */
export default async function requestResetPassword(email) {
  // eslint-disable-next-line no-restricted-globals
  const callback = `${location.origin}/reset-password`;
  await apiRequest("POST", "/auth/reset-password", {
    email,
    callback,
    lng: i18next.language,
  });
}
