import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, generatePath } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  Typography,
  Button,
  Chip,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { ListItemAccordion } from "components/List/StyledComponents";
import { useDependencyModal } from "components/DependencyModal";
import ConfirmDelete from "components/ConfirmDelete";
import useVariables from "utils/useVariables";
import { deepCopy } from "utils/deepCopy";
import { getFormattedTimeStamp } from "utils/dateUtils";

import { Variable } from "@dashboard-v3/api";

type ItemProps = {
  variable: Variable;
  setLoading: Dispatch<SetStateAction<"ALL" | "ITEM">>;
  setLoadingItem: Dispatch<SetStateAction<string>>;
  isDefault?: boolean;
};

export enum UserAction {
  EDIT = "edit",
  COPY = "copy",
  SHOW = "show",
}

export default function VariableItem({
  variable,
  setLoading,
  setLoadingItem,
  isDefault,
}: ItemProps) {
  const { t } = useTranslation("variables");
  const { errorHandler } = useDependencyModal();
  const { deleteUserVariable } = useVariables();
  const classes = useStyles();
  const history = useHistory();
  const tagText = variable.compound ? t("tag.compound") : t("tag.simple");
  const descriptionText = variable.description?.replace(/[^a-zA-Z0-9]/g, "")
    ? variable.description
    : t("description.default");

  const deleteVariable = async () => {
    const { id } = variable;
    setLoading("ITEM");
    setLoadingItem(id);
    await deleteUserVariable(id, error => {
      errorHandler(error, t("errors.variables.deleteError"));
    });
    setLoading(null);
    setLoadingItem(null);
  };

  const handleUserAction = (action: UserAction) => async () => {
    const { id } = variable;
    const selectedVariable = deepCopy(variable);

    if (action === UserAction.SHOW) {
      return history.push({
        pathname: generatePath(`variables/:id`, { id }),
        state: { selectedVariable },
      });
    }

    if (action === UserAction.COPY) {
      selectedVariable.name = t("form.name.copyName", { name: variable.name });
      selectedVariable.platformVariable = false;
      selectedVariable.id = null;
    }

    return history.push({
      pathname: generatePath(`variables/:id/${action}`, { id }),
      state: { selectedVariable },
    });
  };

  return (
    <ListItem key={variable.id} disableGutters>
      <ListItemAccordion className={classes.itemAccordion}>
        <div className={classes.contentContainer}>
          <ListItemText
            disableTypography
            primary={
              <>
                <Typography className={classes.itemName}>
                  {variable.name}
                </Typography>

                <Tooltip title={variable.description}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    className={classes.itemDescription}
                  >
                    {descriptionText}
                  </Typography>
                </Tooltip>
              </>
            }
            secondary={
              <div className={classes.secondaryTextContainer}>
                <Chip
                  size="small"
                  color="primary"
                  variant="outlined"
                  className={classes.typeTag}
                  label={tagText}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.updatedAt}
                >
                  {t("common:updatedAt", {
                    date: getFormattedTimeStamp(variable.updatedAt, true),
                  })}
                </Typography>
              </div>
            }
          />
          <div className={classes.buttonsContainer}>
            {!isDefault && (
              <>
                <Button
                  color="default"
                  size="small"
                  variant="outlined"
                  onClick={handleUserAction(UserAction.EDIT)}
                >
                  {t("common:edit")}
                </Button>

                <Button
                  size="small"
                  color="default"
                  variant="outlined"
                  className={classes.actionButton}
                  onClick={handleUserAction(UserAction.COPY)}
                >
                  {t("common:copy")}
                </Button>

                <ConfirmDelete
                  size="small"
                  variant="outlined"
                  className={classes.actionButton}
                  onAccept={deleteVariable}
                  message={t("common:confirmDeletion", { name: variable.name })}
                />
              </>
            )}

            {isDefault && (
              <>
                <Button
                  size="small"
                  color="default"
                  variant="outlined"
                  className={classes.actionButton}
                  onClick={handleUserAction(UserAction.SHOW)}
                >
                  {t("show")}
                </Button>
                <Button
                  size="small"
                  color="default"
                  variant="outlined"
                  className={classes.actionButton}
                  onClick={handleUserAction(UserAction.COPY)}
                >
                  {t("common:copy")}
                </Button>
              </>
            )}
          </div>
        </div>
      </ListItemAccordion>
    </ListItem>
  );
}

const useStyles = makeStyles({
  itemAccordion: { padding: "12px 16px" },
  secondaryTextContainer: { display: "flex", alignItems: "flex-end" },
  itemName: { fontWeight: 500 },
  typeTag: { marginRight: "10px" },
  updatedAt: { marginTop: "7px" },
  actionButton: { marginLeft: "10px" },
  buttonsContainer: { alignSelf: "center" },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    columnGap: "15px",
  },
  itemDescription: {
    width: "95%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.9",
  },
});
