import { MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
  onChange: (bidirectional: boolean) => void;
  value: boolean;
};

export default function SelectDirection({ onChange, value }: Props) {
  const { t } = useTranslation("rules");

  return (
    <Select
      fullWidth
      variant="outlined"
      onChange={change => onChange(change.target.value === "between")}
      value={value ? "between" : "from"}
    >
      <MenuItem data-testid="email-flow__start-between-item" value="between">
        {t("emailFlowSection.between")}
      </MenuItem>
      <MenuItem data-testid="email-flow__start-from-item" value="from">
        {t("emailFlowSection.from")}
      </MenuItem>
    </Select>
  );
}
