import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ImportFileSection from "./ImportSection";
import FileList from "./Files/FileList";
import LoadedAccounts from "./Accounts/LoadedAccounts";
import SaveResults from "./Accounts/SaveResults";
import FormatErrorList from "./Files/FormatErrorList";
import FileExample from "./Files/FileExample";
import { ImportCSVResult, getResultFileHref } from "./utils";
import {
  CloseButton,
  DialogHeader,
  ExampleLink,
  FileExampleContainer,
} from "./styled";

import { BulkSaveResult } from "pages/Accounts/accountContext";
import { useSnackbar } from "notistack";

interface UploadFilesProps {
  isOpen: boolean;
  onClose: () => void;
}

enum View {
  Example = "EXAMPLE",
  Import = "IMPORT",
  Accounts = "ACCOUNTS",
  SaveResults = "SAVE_RESULTS",
  FormatErrors = "FORMAT_ERRORS",
}

export default function UploadFilesModal({
  isOpen,
  onClose,
}: UploadFilesProps) {
  const { t } = useTranslation("accounts");
  const { enqueueSnackbar } = useSnackbar();
  const [loadedFiles, setLoadedFiles] = useState<ImportCSVResult[]>([]);
  const [selectedFile, setSelectedFile] = useState<ImportCSVResult>(null);
  const [currentView, setCurrentView] = useState<View>(View.Import);
  const [saveResults, setSaveResults] = useState<BulkSaveResult>(null);

  const removeFile = (index: number) => {
    setLoadedFiles(prev => {
      const update = [...prev];
      update.splice(index, 1);
      return update;
    });
  };

  const onFileImport = (files: ImportCSVResult[]) => {
    setLoadedFiles(prev => {
      const update = [...prev, ...files];

      if (update.length > 20) {
        enqueueSnackbar(t("importAccountsModal.import.fileCountError"), {
          variant: "error",
        });
        return prev;
      }

      return update.filter((file, i) => {
        return i === update.findIndex(f => f.name === file.name);
      });
    });
  };

  const onShowAccounts = (file: ImportCSVResult) => {
    setSelectedFile(file);
    setCurrentView(View.Accounts);
  };

  const onShowFormatErrors = (file: ImportCSVResult) => {
    setSelectedFile(file);
    setCurrentView(View.FormatErrors);
  };

  const onSaveResults = (result: BulkSaveResult) => {
    setLoadedFiles([]);
    if (result.errors.length) {
      setSaveResults(result);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogHeader disableTypography>
        <Typography variant="h6">{t("importAccountsModal.title")}</Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ marginBottom: "10px", maxWidth: "80%" }}
        >
          {t(`importAccountsModal.subtitle.${currentView}`)}
        </Typography>
        {currentView === View.Import && (
          <ExampleLink
            variant="caption"
            onClick={() => setCurrentView(View.Example)}
          >
            {t("importAccountsModal.example.link")}
          </ExampleLink>
        )}
        <CloseButton size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </CloseButton>
      </DialogHeader>
      <DialogContent>
        {currentView === View.Import && (
          <>
            <ImportFileSection
              files={loadedFiles}
              onImport={onFileImport}
              onSaveResults={onSaveResults}
              onShowErrors={() => setCurrentView(View.SaveResults)}
            />
            <FileList
              files={loadedFiles}
              onFileRemove={removeFile}
              onShowAccounts={onShowAccounts}
              onShowFormatErrors={onShowFormatErrors}
            />
          </>
        )}
        {currentView === View.Example && (
          <FileExampleContainer>
            <FileExample />
            <Typography variant="caption" color="textSecondary">
              {t("importAccountsModal.example.help")}
            </Typography>
          </FileExampleContainer>
        )}

        {currentView === View.Accounts && (
          <LoadedAccounts file={selectedFile} />
        )}
        {currentView === View.SaveResults && (
          <SaveResults results={saveResults} />
        )}
        {currentView === View.FormatErrors && (
          <FormatErrorList file={selectedFile} />
        )}
      </DialogContent>
      <DialogActions>
        {currentView === View.Example && (
          <Button
            color="primary"
            size="small"
            component="a"
            data-testid="accounts__example-csv-btn"
            href="/files/accounts_upload_example.csv"
          >
            {t("importAccountsModal.example.downloadBtn")}
          </Button>
        )}
        {currentView === View.SaveResults && (
          <Button
            variant="text"
            size="small"
            color="primary"
            component="a"
            download="results.csv"
            href={getResultFileHref(saveResults.errors)}
          >
            {t("importAccountsModal.saveResults.downloadBtn")}
          </Button>
        )}
        {currentView !== View.Import && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setCurrentView(View.Import)}
          >
            {t("importAccountsModal.goBackBtn")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
