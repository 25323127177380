import {
  StorageAccount,
  StorageProvider,
  StorageProviderWithAuth,
} from "@dashboard-v3/api";
import { TFunction } from "i18next";
import openAuthPopup, { isClosedByUser } from "./openAuthPopup";

export enum AccountType {
  Mailbox = "MAILBOX",
  Storage = "STORAGE",
}

export enum MailboxProvider {
  Google = "GOOGLE",
  Office = "OFFICE365",
}

export async function handleStorageAccount(
  provider: StorageProviderWithAuth,
  handlers: {
    onSuccess: (account: StorageAccount, provider: StorageProvider) => void;
    onError: (error: unknown, isCancel: boolean) => void;
  }
): Promise<void> {
  try {
    const newAccount = await openAuthPopup(provider);
    if (newAccount) handlers.onSuccess(newAccount, provider);
  } catch (error) {
    handlers.onError(error, isClosedByUser(error));
  }
}

export function getErrorMsg(error: unknown, t: TFunction) {
  let message = t("error.whenTryToAuthorize");

  if (error instanceof Error && error.message === "ACCESS_DENIED") {
    message = t("error.accessDenied");
  }

  return message;
}
