import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DirectionAutocomplete, { AllowedTypes } from "./DirectionAutocomplete";
import directionToOption from "./directionToOption";
import optionToDirection from "./optionToDirection";

import type { FromTo } from "@dashboard-v3/api";
import { Option } from "./types";

type Params = {
  onChange: (newSelection: FromTo) => void;
  initialSelectedItem?: FromTo;
  /** Separator label, "to" by default */
  separator?: string;
  allowedTypes?: AllowedTypes;
  onOptionSelect?: (update: { from: Option; to: Option }) => void;
};

export default function FromToSelector({
  separator,
  onChange,
  initialSelectedItem,
  allowedTypes,
  onOptionSelect,
}: Params) {
  const { t } = useTranslation("fromToSelector");
  const [loading, setLoading] = useState(true);
  const [fromOption, setFromOption] = useState<Option>();
  const [toOption, setToOption] = useState<Option>();

  useEffect(() => {
    async function init() {
      if (initialSelectedItem) {
        const { fromDirection, toDirection } = initialSelectedItem;
        const [from, to] = await Promise.all([
          directionToOption(fromDirection, t),
          directionToOption(toDirection, t),
        ]);

        setFromOption(from);
        setToOption(to);
      }
      setLoading(false);
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onOptionSelect) {
      onOptionSelect({ from: fromOption, to: toOption });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromOption, toOption]);

  function changeFrom(selection: Option) {
    setFromOption(selection);
    onChange({
      fromDirection: optionToDirection(selection),
      toDirection: optionToDirection(toOption),
    });
  }

  function changeTo(selection: Option) {
    setToOption(selection);
    onChange({
      fromDirection: optionToDirection(fromOption),
      toDirection: optionToDirection(selection),
    });
  }

  return (
    <Box display="grid" gridTemplateColumns="1fr auto 1fr" gridColumnGap="10px">
      {loading ? (
        <Skeleton height={56} variant="rect" />
      ) : (
        <Box>
          <DirectionAutocomplete
            label={t("sender")}
            allowedTypes={allowedTypes}
            initialSelection={fromOption}
            onChange={changeFrom}
          />
        </Box>
      )}
      <Box justifySelf="center" alignSelf="center">
        <Typography>{separator || t("separator")}</Typography>
      </Box>
      {loading ? (
        <Skeleton height={56} variant="rect" />
      ) : (
        <Box>
          <DirectionAutocomplete
            label={t("recipient")}
            allowedTypes={allowedTypes}
            initialSelection={toOption}
            onChange={changeTo}
          />
        </Box>
      )}
    </Box>
  );
}
