import { Skeleton } from "@material-ui/lab";

export default function LoadingBlock() {
  return (
    <Skeleton
      variant="rect"
      height="118px"
      width="280px"
      style={{ borderRadius: "4px" }}
    />
  );
}
