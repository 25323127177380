import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SwitchField from "components/Forms/SwitchField";
import { OptionGroup } from "components/Styled";
import { SwitchSkeleton } from "components/SkeletonLoading";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import useOrganization from "utils/useOrganization";
import { AdvancedOptionsContainer, AdvancedOptionsDetails } from "./Styled";

const AdvancedOptions = () => {
  const { t } = useTranslation("rules");
  const { dispatch } = useRuleContext();
  const { organization, isLoading } = useOrganization({
    onSuccess(organization) {
      if (organization) {
        const { preventReProcessingDragAndDrop } = organization.storagesPolicy;
        setPreProcessing(preventReProcessingDragAndDrop);
      }
    },
  });
  const [preProcessing, setPreProcessing] = useState<boolean>(false);

  const handlePreProcessing = e => {
    const { checked } = e.target;
    const { preventReProcessingDragAndDrop } = organization.storagesPolicy;
    setPreProcessing(checked);

    dispatch({
      type: "updateWebhookProcessing",
      payload: {
        hasChanged: preventReProcessingDragAndDrop !== checked,
        currentValue: checked,
      },
    });
  };

  return (
    <AdvancedOptionsContainer>
      <AccordionSummary
        data-testid="target__drag_n_drop_advanced-options"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{t("advancedOptions")}</Typography>
      </AccordionSummary>
      <AdvancedOptionsDetails>
        <OptionGroup>
          <SwitchSkeleton loading={isLoading && !organization}>
            <SwitchField
              name="webHookPreProcessing"
              label={t("webhookSection.preProcessing.label")}
              description={t("webhookSection.preProcessing.description")}
              onChange={handlePreProcessing}
              value={preProcessing || false}
            />
          </SwitchSkeleton>
        </OptionGroup>
      </AdvancedOptionsDetails>
    </AdvancedOptionsContainer>
  );
};

export default AdvancedOptions;
