import { ReactNode } from "react";
import styled from "styled-components";
import { Skeleton, SkeletonProps } from "@material-ui/lab";

interface FieldSkeletonProps {
  withLabel?: boolean;
  labelProps?: SkeletonProps;
  fieldProps?: SkeletonProps;
  testId?: string;
}

interface SwitchSkeletonProps {
  loading: boolean;
  children: ReactNode;
}

export const FieldSkeleton = ({
  withLabel,
  labelProps,
  fieldProps,
  testId,
}: FieldSkeletonProps) => (
  <>
    {withLabel && (
      <Skeleton
        variant="text"
        animation="wave"
        height={50}
        width={200}
        data-testid={testId}
        {...labelProps}
      />
    )}
    <Skeleton variant="rect" animation="wave" height={56} {...fieldProps} />
  </>
);

const SwitchContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 3px;
`;

export const SwitchSkeleton = ({ loading, children }: SwitchSkeletonProps) => {
  if (loading) {
    return (
      <>
        <SwitchContainer>
          <Skeleton variant="circle" animation="pulse" height={38} width={38} />
          <Skeleton variant="text" animation="wave" height={28} width={176} />
        </SwitchContainer>
        <Skeleton variant="text" animation="wave" height={20} width={"100%"} />
        <Skeleton variant="text" animation="wave" height={20} width={"75%"} />
      </>
    );
  }
  return <>{children}</>;
};
