import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconButton, ListItem, Popover, Typography } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { TFunction } from "i18next";

import { DashboardVariable } from "types";

interface HelperProps {
  value: string | DashboardVariable[];
  variables: DashboardVariable[];
  autoCompleteDescription?: boolean;
}

export default function VariablesHelper({
  value,
  variables,
  autoCompleteDescription,
}: HelperProps) {
  const { t } = useTranslation("platformVariables");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const currentVars = Array.isArray(value)
    ? value.filter((v, i, self) => i === self.findIndex(s => s.id === v.id))
    : extractVariables(value, variables, t);
  const autoCompleteDescriptionStyles = {
    padding: !currentVars.length ? "15px" : "15px 15px 0",
  };

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
    setIsOpen(prev => !prev);
  };

  return (
    <HelpButton
      edge="end"
      onClick={handleOpen}
      disabled={!autoCompleteDescription && !currentVars.length}
    >
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {autoCompleteDescription && (
          <Typography variant="body2" style={autoCompleteDescriptionStyles}>
            {t("variables.helperDescription")}
          </Typography>
        )}
        {!!currentVars.length && <HelperItems items={currentVars} />}
      </Popover>
      <HelpOutlineIcon fontSize="small" />
    </HelpButton>
  );
}

const HelperItems = ({ items }: { items: DashboardVariable[] }) => {
  const { t } = useTranslation("platformVariables");

  const getItemName = (name: string) => {
    const parsedName = t(name).replace(/{|}/g, "");
    return t("helper.name", {
      name: parsedName,
      interpolation: { escapeValue: false },
    });
  };

  const getItemDescription = (desc: string) => {
    return t([desc, t("helper.emptyDescription")]);
  };

  return (
    <HelperItemList>
      {items.map(({ id, name, description }) => (
        <ListItem key={id} disableGutters>
          <div>
            <Typography variant="subtitle2">{getItemName(name)}</Typography>
            <Typography variant="caption">
              {getItemDescription(description)}
            </Typography>
          </div>
        </ListItem>
      ))}
    </HelperItemList>
  );
};

const extractVariables = (
  currentValue: string,
  variables: DashboardVariable[],
  t: TFunction
) => {
  const matches = currentValue.match(/\{.*?\}/g);

  if (variables && matches) {
    return variables.filter(v => matches.includes(t(v.name)));
  }
  return [];
};

export const HelpButton = styled(IconButton).attrs({
  disableRipple: true,
})`
  padding: 15px;
  align-self: center;
  background-color: transparent;
  svg {
    color: ${({ disabled }) => (!disabled ? "#009ed2" : "inherit")};
  }
  :hover {
    background-color: transparent;
    svg {
      color: ${({ disabled }) => (!disabled ? "#0082ad" : "inherit")};
    }
  }
`;

export const HelperItemList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  li {
    padding: 0;
  }
`;
