import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CloseIcon from "@material-ui/icons/Close";
import FileItemTitle from "./FileItemTitle";
import FileItemSubtitle from "./FileItemSubTitle";
import { ImportCSVResult } from "../utils";

interface FileListProps {
  files: ImportCSVResult[];
  onShowFormatErrors: (file: ImportCSVResult) => void;
  onShowAccounts: (file: ImportCSVResult) => void;
  onFileRemove: (index: number) => void;
}

export default function FileList({
  files,
  onShowAccounts,
  onShowFormatErrors,
  onFileRemove,
}: FileListProps) {
  const classes = useStyles();

  return (
    <List dense disablePadding>
      {files.map((file, i) => (
        <ListItem className={classes.fileItem}>
          <InsertDriveFileOutlinedIcon
            className={classes.fileIcon}
            fontSize="small"
          />

          <ListItemText
            primaryTypographyProps={{
              variant: "caption",
              style: { fontWeight: "500" },
            }}
            secondaryTypographyProps={{ variant: "caption" }}
            primary={
              <FileItemTitle
                fileName={file.name}
                containsErrors={!!file.importErrors.length}
                showFileErrors={() => onShowFormatErrors(file)}
              />
            }
            secondary={
              <FileItemSubtitle
                file={file}
                onFileSelect={() => onShowAccounts(file)}
              />
            }
          />
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={() => onFileRemove(i)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles({
  fileItem: {
    border: "1px solid rgba(0, 0, 0, 0.20)",
    borderRadius: "6px",
    marginBottom: "10px",
  },
  fileIcon: {
    marginRight: "15px",
    alignSelf: "flex-start",
    marginTop: "13px",
  },
});
