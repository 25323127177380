import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ComponentList from "components/ComponentList";
import { TemplateDefinition } from "@dashboard-v3/api";
import Item from "./Item";
import apiRequest from "utils/apiRequestWithErrorCode";
import { ShowMoreBtn } from "components/List";
import { Skeleton } from "@material-ui/lab";

const listLimit = 10;

export default function List() {
  const { t } = useTranslation("templateDefinitions");
  const [templateList, setTemplateList] = useState<TemplateDefinition[]>([]);
  const [searchOffset, setSearchOffset] = useState(0);
  const [loading, setLoading] = useState<"ALL" | "MORE" | "ITEM">("ALL");
  const [loadingItem, setLoadingItem] = useState<string>();
  const [hasMore, setHasMore] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initialFetch = async () => {
      setLoading("ALL");
      await fetchDefinitions();
      setLoading(null);
    };

    if (!templateList.length) {
      initialFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchDefinitions(offset = 0): Promise<void> {
    try {
      const data = await apiRequest<TemplateDefinition[]>(
        "GET",
        `/template-definitions?offset=${offset}&limit=${listLimit}`
      );
      setHasMore(data.length === listLimit);
      setTemplateList(prevTemplates => {
        const newTemplates = offset ? [...data, ...prevTemplates] : data;

        return newTemplates.sort(
          (a: TemplateDefinition, b: TemplateDefinition): number =>
            b.updatedAt - a.updatedAt
        );
      });
    } catch (error: unknown) {
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    }
  }

  const loadMore = async () => {
    setLoading("MORE");
    const newOffset = searchOffset + listLimit;
    await fetchDefinitions(newOffset);
    setSearchOffset(newOffset);
    setLoading(null);
  };

  return (
    <>
      <ComponentList
        list={templateList}
        emptyMsg={t("list.emptyList")}
        loading={loading === "ALL"}
        renderItems={template =>
          loadingItem === template.id ? (
            <Skeleton
              key={template.id}
              height="5rem"
              animation="wave"
              variant="rect"
            />
          ) : (
            <Item
              key={template.id}
              template={template}
              setLoading={setLoading}
              setLoadingItem={setLoadingItem}
              fetchDefinitions={fetchDefinitions}
            />
          )
        }
      />
      {hasMore && (
        <ShowMoreBtn loading={loading === "MORE"} onClick={loadMore} />
      )}
    </>
  );
}
