import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, TextField, Typography } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Button } from "components/Forms/StyledComponents";
import {
  RegexDialogBody,
  RegexDialogContainer,
  RegexDialogFooter,
  RegexDialogHeader,
} from "../Styled";
import { useConditionsCtx } from "../state/ConditionsProvider";

import { TestRegexRequest, TestRegexResponse } from "@dashboard-v3/api";
import TestButton from "./TestButton";
import TestResults from "./TestResults";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function RegexTester({ isOpen, onClose }: Props) {
  const { t } = useTranslation("variables");
  const { enqueueSnackbar } = useSnackbar();
  const { selectFromCondition, updateCondition, isReadOnly } =
    useConditionsCtx();
  const { parameters: params } = selectFromCondition();
  const [fields, setFields] = useState<TestRegexRequest>(initFields(params));
  const [testResponse, setTestResponse] = useState<TestRegexResponse>(null);
  const [regexError, setRegexError] = useState<string>(null);

  const handleOnChange = e => {
    const { name, value } = e.target;
    if (name === "expression") {
      setRegexError(null);
    }
    setTestResponse(null);
    setFields(prev => ({ ...prev, [name]: value }));
  };

  const handleUseRegex = () => {
    updateCondition({ parameters: [fields.expression] });
    onClose();
  };

  const handleTestError = (error: unknown) => {
    const code = getErrorCode(error);
    const { expression } = fields;

    if (code === `WRONG_REGEX ${expression}`) {
      setRegexError(t("regexTester.error.wrongRegex", { expression }));
    } else {
      enqueueSnackbar(t("regexTester.error.unknown"), { variant: "error" });
    }
  };

  return (
    <RegexDialogContainer open={isOpen} onClose={onClose}>
      <RegexDialogHeader>
        <Typography variant="h6">{t("regexTester.title")}</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </RegexDialogHeader>
      <RegexDialogBody>
        <TextField
          variant="outlined"
          name="expression"
          label={t("regexTester.testRegex.label")}
          value={fields.expression}
          onChange={handleOnChange}
          fullWidth
          error={!!regexError}
          helperText={regexError}
        />
        <TextField
          variant="outlined"
          multiline
          minRows={6}
          name="value"
          label={t("regexTester.testValue.label")}
          value={fields.value}
          onChange={handleOnChange}
          fullWidth
        />
      </RegexDialogBody>
      <RegexDialogFooter>
        <TestButton
          fields={fields}
          test={testResponse}
          onClick={result => setTestResponse(result)}
          onError={handleTestError}
        />
        <Button
          variant="text"
          size="large"
          wording={t("regexTester.buttons.useRegex")}
          onClick={handleUseRegex}
          disabled={isReadOnly}
        />
      </RegexDialogFooter>
      {testResponse?.groups?.map((group, i) => (
        <TestResults key={group[i]} matches={{ group, matchCount: i }} />
      ))}
    </RegexDialogContainer>
  );
}

const initFields = (parameters: string[]) => () => {
  const [regex] = parameters;
  return {
    expression: regex || "",
    value: "",
  };
};
