import {
  Avatar,
  Link,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  path: string;
  folderInUse: {
    searchedFolder: string;
    conflictFolder: string;
    ruleId: string;
    ruleName: string;
  };
  isMissing: boolean;
}

export default function ConflictFolderWarning({
  name,
  path,
  folderInUse,
  isMissing,
}: Props) {
  const { t } = useTranslation("rules");
  const { state } = useRuleContext();
  const { ruleId, ruleName, searchedFolder, conflictFolder } =
    folderInUse || {};
  const isSameRule = ruleId === "NEW_RULE" || ruleId === state.rule.id;
  const error =
    searchedFolder === conflictFolder
      ? t("webhookInUseError.current", { name })
      : t("webhookInUseError.parentOrChild", { name });

  return (
    <>
      <ListItemAvatar>
        <Avatar variant="rounded" style={{ backgroundColor: "transparent" }}>
          <ErrorOutlineIcon color="error" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        disableTypography={true}
        secondary={
          <>
            <Typography variant="body2">{path}</Typography>
            {isMissing && (
              <Typography variant="body2" color="error">
                {t("webhookMissingError")}
              </Typography>
            )}
            {folderInUse && (
              <>
                {!isSameRule && (
                  <Typography variant="body2" color="error">
                    {error}{" "}
                    <Typography
                      display="inline"
                      color="textSecondary"
                      variant="body2"
                    >
                      {t("webhookInUseError.conflictRule")}{" "}
                    </Typography>
                    <Link
                      href={`/rules/${ruleId}/edit`}
                      underline="always"
                      target="_blank"
                    >
                      {ruleName}
                    </Link>
                  </Typography>
                )}
                {isSameRule && (
                  <Typography variant="body2" color="error">
                    {t("webhookInUseError.currentRule")}
                  </Typography>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
}
