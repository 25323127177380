import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import ButtonWithLoading from "components/ButtonWithLoading";

export enum WarningAction {
  GENERATE = "GENERATE",
  REMOVE = "REMOVE_PREVIOUS",
}

type Props = {
  type: WarningAction;
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: (e: any) => void;
};

export default function TransportAgentWarning({
  type,
  isOpen,
  loading,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation("orgAndDomains");

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {type === WarningAction.GENERATE && (
        <>
          <DialogTitle>{t("org.transportAgent.warning.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              {t("org.transportAgent.warning.desc")}
            </DialogContentText>
          </DialogContent>
        </>
      )}

      {type === WarningAction.REMOVE && (
        <>
          <DialogTitle>{t("org.transportAgent.remove.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              {t("org.transportAgent.remove.desc")}
            </DialogContentText>
          </DialogContent>
        </>
      )}

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("common:no")}
        </Button>
        <ButtonWithLoading
          variant="contained"
          color="primary"
          disableElevation
          loading={loading}
          onClick={onConfirm}
        >
          {t("common:yes")}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
}
