import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";

export default function HeaderWithLogo({ children }: { children?: unknown }) {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.appBar}
      color="inherit"
      position="fixed"
      elevation={0}
    >
      <StyledToolbar>
        <>
          <Logo>mxHERO</Logo>
          {children}
        </>
      </StyledToolbar>
    </AppBar>
  );
}

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledToolbar = styled(Toolbar)`
  align-items: center;
  border-bottom: 1px solid #d6dbe0;
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 240px 1fr;
  justify-items: start;
  padding-left: 30px;
  padding-right: 40px;
`;

const Logo = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 600;
`;
