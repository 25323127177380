import { Typography } from "@material-ui/core";
import { ReactNode } from "react";

export default function ChartTitle({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <Typography
      variant="subtitle1"
      display="block"
      gutterBottom
      style={{ marginBottom: "20px" }}
    >
      {children}
    </Typography>
  );
}
