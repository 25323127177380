/* eslint-disable no-use-before-define */
import apiRequest from "utils/apiRequestWithErrorCode";

import {
  AuthCodeValidationRequest,
  CurrentAuthData,
  User,
} from "@dashboard-v3/api";
import {
  AuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { Timestamp } from "@dashboard-v3/api/src/types/commons";

export type { LoginOptionsErrors, LoginOptionNames } from "@dashboard-v3/api";

export type LoginResponse = {
  sessionDuration: number;
  lastLogin: number;
  user: User;
};

export async function fetchAuthenticationData() {
  return apiRequest<CurrentAuthData>("GET", "/auth/current");
}

export async function reauthenticatePassword(email: string, password: string) {
  return apiRequest<number>("POST", "/auth/current/password", {
    email,
    password,
  });
}

export async function reauthenticateSaml(code: string) {
  return apiRequest<Timestamp>("POST", "/auth/current/validate-saml", { code });
}

export async function reauthenticateGoogle(tokenId: string) {
  return apiRequest<Timestamp>("POST", "/auth/current/google", { tokenId });
}

export async function validate2FACode(body: AuthCodeValidationRequest) {
  return apiRequest<Timestamp>("POST", "/auth/current/validate-code", body);
}

const officeLoginRequest = {
  scopes: ["user.read"],
  forceRefresh: true, // Set this to "true" to skip a cached token and go to the server to get a new token
  prompt: "select_account",
};

let officeClientApp: IPublicClientApplication;

function getOfficeClientApp(): IPublicClientApplication {
  if (!officeClientApp) {
    officeClientApp = new PublicClientApplication({
      auth: {
        clientId: process.env.REACT_APP_OFFICE_AUTH_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
        // eslint-disable-next-line no-restricted-globals
        redirectUri: location.origin,
        navigateToLoginRequestUrl: false,
      },
      system: {
        // Caused by https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5477
        pollIntervalMilliseconds: 15,
      },
    });
  }
  return officeClientApp;
}

export async function loginInOffice() {
  const clientApp = getOfficeClientApp();

  try {
    const loginResponse = await clientApp.loginPopup(officeLoginRequest);
    const email = loginResponse.account.username;
    clientApp.setActiveAccount(loginResponse.account);
    return email;
  } catch (error: unknown) {
    if (error instanceof AuthError && error.errorCode === "user_cancelled") {
      return null;
    }
    console.error(error);
    throw new Error("SYSTEM_FAILURE");
  }
}

export async function reauthenticateOffice() {
  let accessToken: string;
  let email: string;

  try {
    email = await loginInOffice();
    const clientApp = getOfficeClientApp();
    const tokenResponse = await clientApp.acquireTokenSilent(
      officeLoginRequest
    );
    accessToken = tokenResponse.accessToken;
  } catch (error: any) {
    if (error.errorCode === "user_cancelled") return null;
    console.error(error);
    throw new Error("SYSTEM_FAILURE");
  }

  return apiRequest<number>("POST", "/auth/current/office", {
    accessToken,
    email,
  });
}
