/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { CircularProgress, Dialog, Typography } from "@material-ui/core";
import BoxSync from "pages/Integrations/Applications/BoxSync";
import OnedriveSync from "pages/Integrations/Applications/OnedriveSync";
import GappsSync from "pages/Integrations/Applications/GappsSync";
import {
  SyncDialogCloseButton,
  SyncDialogContent,
  SyncDialogTitleContainer,
} from "./Styled";
import { StorageSettingsUpdate } from "pages/Integrations/types";
import { Item } from "./types";
import { StorageSettings } from "@dashboard-v3/api";
import { useFetch } from "utils/api/useFetch";

interface Props {
  isOpen: boolean;
  selectedAccount: Item;
  onClose: () => void;
  onChange: () => void;
}

interface DialogHeader {
  title: string;
  subtitle: string;
  onClose: Props["onClose"];
}

const AccountSyncModal = ({
  isOpen,
  selectedAccount,
  onClose,
  onChange,
}: Props) => {
  const { t } = useTranslation("storage");
  const { storageAccount } = selectedAccount;
  const { provider } = storageAccount;

  const { data: settings, isLoading } =
    useFetch<StorageSettings>("/storages/settings");

  const handleOnAccountSync = (update: StorageSettingsUpdate) => {
    const providerKey =
      provider === "GOOGLEDRIVE" ? "gapps" : provider.toLowerCase();
    const { installed } = update[providerKey];

    if (installed) {
      onChange();
    }
  };

  if (isLoading) {
    return (
      <Dialog open={isOpen}>
        <SyncDialogContent style={{ height: "156px" }}>
          <CircularProgress style={{ alignSelf: "center" }} />
        </SyncDialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <SyncDialogContent>
        {provider === "BOX" && (
          <>
            <DialogTitle
              title={t("boxSync")}
              subtitle={t("boxSyncExplanation")}
              onClose={onClose}
            />
            <BoxSync settings={settings} onAccountSync={handleOnAccountSync} />
          </>
        )}
        {provider === "ONEDRIVE" && (
          <>
            <DialogTitle
              title={t("onedriveSync")}
              subtitle={t("onedriveSyncExplanation")}
              onClose={onClose}
            />
            <OnedriveSync
              settings={settings}
              onAccountSync={handleOnAccountSync}
            />
          </>
        )}
        {provider === "GOOGLEDRIVE" && (
          <>
            <DialogTitle
              title={t("googleDriveSync")}
              subtitle={t("googleDriveSyncExplanation")}
              onClose={onClose}
            />
            <GappsSync
              settings={settings}
              onAccountSync={handleOnAccountSync}
            />
          </>
        )}
      </SyncDialogContent>
    </Dialog>
  );
};

const DialogTitle = ({ title, subtitle, onClose }: DialogHeader) => {
  return (
    <>
      <SyncDialogTitleContainer>
        <Typography variant="h6">{title}</Typography>
        <SyncDialogCloseButton onClick={onClose} />
      </SyncDialogTitleContainer>
      <Typography variant="caption" color="textSecondary">
        {subtitle}
      </Typography>
    </>
  );
};

export default AccountSyncModal;
