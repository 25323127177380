/* eslint-disable  react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useConditionsCtx } from "pages/Variables/state/ConditionsProvider";
import useVariables from "utils/useVariables";
import { useParams } from "react-router-dom";

import { Variable } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";

export default function SelectVariables() {
  const { t } = useTranslation("variables");
  const { userVariables = [] } = useVariables();
  const { updateCondition, selectFromCondition } = useConditionsCtx();
  const { variableIdList } = selectFromCondition();
  const [selected, setSelected] = useState<Variable[]>([]);
  const { id: currentId } = useParams();
  const options = userVariables
    .filter(v => !v.compound && v.id !== currentId)
    .sort(a => a.platformVariable && -1);

  useEffect(() => {
    const selected = userVariables.filter(v => variableIdList.includes(v.id));
    setSelected(selected);
  }, [userVariables]);

  function handleVariableList(_, values) {
    setSelected(values);
    updateCondition({
      variableIdList: values.map(v => v.id),
      sourceType: "TEXT",
    });
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      filterSelectedOptions
      limitTags={3}
      getOptionLabel={v => v.name}
      getOptionSelected={(opt, selected) => opt.id === selected.id}
      options={options}
      disabled={!options}
      value={selected}
      onChange={handleVariableList}
      renderOption={({ id, name, description }) => (
        <OptionContainer key={id}>
          <span>{name}</span>
          {description && <span>{description}</span>}
        </OptionContainer>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          label={t("form.selectVariable.label")}
        />
      )}
    />
  );
}

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  span:nth-child(2) {
    font-size: 12px;
    color: grey;
  }
`;
