import styled from "styled-components";
import { Box, List, Paper, Typography } from "@material-ui/core";
import LinearLoader from "components/LinearLoader";
import { DependencyModalProvider } from "./DependencyModal";

export const EmptyListContainer = styled(Paper)`
  min-height: 100px;
  align-self: start;
  align-items: center;
  display: grid;
  padding: 16px;
  text-align: center;
`;

export default function ComponentList<T>({
  list,
  loading,
  emptyMsg,
  testId = "",
  renderItems,
}: {
  list: Array<T>;
  loading: boolean;
  emptyMsg: React.ReactNode;
  testId?: string;
  renderItems: (item: T, index: number, list: Array<T>) => React.ReactNode;
}) {
  const isEmpty = Boolean(!list?.length);

  const renderList = () => {
    if (loading) {
      return (
        <Box mt={2} data-testid="ComponentList__loading">
          <LinearLoader />
        </Box>
      );
    }

    if (isEmpty) {
      return (
        <EmptyListContainer data-testid="empty-list__message">
          <Typography component="div">{emptyMsg}</Typography>
        </EmptyListContainer>
      );
    }

    return (
      <List data-testid={testId || ""}>
        {list.map((element, index, list) => renderItems(element, index, list))}
      </List>
    );
  };

  return <DependencyModalProvider>{renderList()}</DependencyModalProvider>;
}
