// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export default function formatNumber(
  number: number,
  options?: Intl.NumberFormatOptions
) {
  return Intl.NumberFormat(
    document.documentElement.lang || "en-US",
    options
  ).format(number);
}
