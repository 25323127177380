import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ArrowRightSharpIcon from "@material-ui/icons/ArrowRightSharp";
import { Dependencies, EntityRef } from "./types";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  dependencies: Dependencies;
}

const StyledListItem = styled(ListItem)`
  display: flex;
  gap: 5px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  font-style: oblique;
  color: #009ed2;
  :visited,
  a:hover,
  a:active {
    color: #009ed2;
  }
`;

const getLinkRoute = (key: string, id: string) => {
  switch (key) {
    case "rules":
      return `/rules/${id}/edit`;
    case "copyPolicies":
      return `/copy-policies/edit/${id}`;
    case "securityPolicies":
      return `/security-policies/edit/${id}`;
    case "templateDefinitions":
      return `/template-definitions/edit/${id}`;
    case "templatePolicies":
      return `/template-policies/edit/${id}`;
    case "variables":
      return `/variables/${id}/edit`;
    default:
      return "";
  }
};

const mapDependencies = (dependencies: Dependencies) => {
  return Object.keys(dependencies).map(key => {
    return {
      type: key,
      data: dependencies[key].map((d: EntityRef) => ({
        ...d,
        route: getLinkRoute(key, d.id),
      })),
    };
  });
};

const Modal = ({ open, onClose, dependencies }: ModalProps) => {
  const { t } = useTranslation("components");
  const deps = mapDependencies(dependencies);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6" paragraph>
          {t("dependenciesModal.title")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("dependenciesModal.subtitle")}
        </Typography>
      </DialogTitle>
      {deps.map(({ type, data }) => (
        <DialogContent key={type}>
          <Typography variant="button">
            {t(`dependenciesModal.types.${type}`)}
          </Typography>
          <List>
            {data.map(({ id, name, route }) => (
              <StyledListItem key={id} button={false as any}>
                <ArrowRightSharpIcon fontSize="inherit" />
                <StyledLink to={route} target="blank">
                  {name}
                  <OpenInNewIcon color="primary" fontSize="inherit" />
                </StyledLink>
              </StyledListItem>
            ))}
          </List>
        </DialogContent>
      ))}
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onClose}
        >
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
