/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { removeUserFromSession } from "utils/authentication";
import { usePlatformVariables } from "utils/usePlatformVariables";
import PageLoading from "./PageLoading";

const PlatformVariablesLoader = ({ children }) => {
  const { t } = useTranslation("platformVariables");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { loadPlatformVariables, status } = usePlatformVariables();
  const loading = status === "LOADING" || status === "UNLOAD";

  async function initPlatformVariables() {
    try {
      await loadPlatformVariables();
    } catch (err) {
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
      removeUserFromSession();
      history.push("/login");
    }
  }

  useEffect(() => {
    if (status === "UNLOAD") {
      initPlatformVariables();
    }
  }, []);

  return (
    <>
      <PageLoading loading={loading} />
      {status === "LOADED" && children}
    </>
  );
};

export default PlatformVariablesLoader;
