/* eslint-disable  react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  FormControl,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { Label, OptionGroup } from "components/Styled";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import isEmail from "utils/isEmail";
import { getStorage, isIndividual } from "../utils";
import { useRuleContext } from "../../CreateOrEdit/context/ruleContext";
import { Storage, Store } from "@dashboard-v3/api";
import SelectField from "components/Forms/SelectField";

type StoreUserField = {
  error: string;
  value: string;
};

export const canUseStores = (storage: Storage): boolean => {
  const provider = storage?.provider;

  if (provider === "DROPBOX") return true;

  return (
    !isIndividual(storage?.authentication) &&
    (provider === "ONEDRIVE" || provider === "GOOGLEDRIVE")
  );
};

export default function SelectStores() {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useRuleContext();
  const { provider, storeId, storeIdUserId } = getStorage(state.rule);
  const [stores, setStores] = useState<Store[]>([]);
  const [userId, setUserId] = useState<StoreUserField>(initUser(storeIdUserId));
  const [permissionsError, setPermissionsError] = useState("");
  const [loading, setLoading] = useState(false);
  const storesKey = `cloudStorage.stores.${provider}`;

  useEffect(() => {
    if (provider === "ONEDRIVE" || provider === "DROPBOX") {
      fetchStorageStores();
      setUserId({ error: "", value: "" });
    }

    if (provider === "GOOGLEDRIVE") {
      if (storeIdUserId) {
        fetchStorageStores(storeIdUserId);
      } else {
        setStores([]);
      }
    }
  }, []);

  async function fetchStorageStores(user?: string) {
    const params = new URLSearchParams();
    let userError = "";
    params.append("provider", provider);

    if (user) {
      params.append("user", user);
    }

    setLoading(true);
    try {
      const stores = await apiRequest<Store[]>(
        "GET",
        `/stores?${params.toString()}`
      );
      if (!stores.length) {
        userError = t(`${storesKey}.account.errors.empty`);
      }
      setStores(stores);
    } catch (error) {
      const errorCode = getErrorCode(error);

      if (errorCode === "FORBIDDEN") {
        return setPermissionsError(t(`${storesKey}.forbiddenError`));
      }

      if (errorCode === "NOT_FOUND") {
        userError = t(`${storesKey}.account.errors.notFound`);
      } else {
        enqueueSnackbar(t(`${storesKey}.fetchError`), {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
      setUserId(prev => ({ ...prev, error: userError }));
    }
  }

  const handleStoreId = e => {
    const { value = null } = e.target;

    dispatch({
      type: "updateStep",
      payload: {
        step: "cloudStorage",
        changes: {
          storeId: value,
          ...(provider === "GOOGLEDRIVE" && { storeIdUserId: userId.value }),
        },
      },
    });
  };

  const onUserAccountChange = e => {
    const { value } = e.target;
    setUserId({ error: "", value });
    setStores([]);
  };

  const onUserAccountBlur = e => {
    const { value: user } = e.target;
    let error = "";
    if (isEmail(user)) {
      fetchStorageStores(user);
      error = "";
    } else {
      setStores([]);
      error = t(`${storesKey}.account.errors.invalid`);
    }
    setUserId(prev => ({ ...prev, error }));
  };

  return (
    <>
      <OptionGroup data-testid="SelectStores">
        <Label wording={t(`${storesKey}.subtitle`)} />
        <FormControl variant="outlined" fullWidth>
          <div style={{ display: "flex", gap: "10px" }}>
            {provider === "GOOGLEDRIVE" && (
              <TextField
                variant="outlined"
                fullWidth
                name="googleAccount"
                value={userId.value}
                label={t(`${storesKey}.account.label`)}
                onBlur={onUserAccountBlur}
                onChange={onUserAccountChange}
                error={!!userId.error}
                helperText={userId.error}
              />
            )}
            {loading ? (
              <Skeleton variant="rect" width="100%">
                <Select />
              </Skeleton>
            ) : (
              <SelectField<Store>
                allowEmpty
                options={stores}
                disabled={!stores.length}
                value={storeId || ""}
                emptyOption={
                  provider === "DROPBOX"
                    ? t("cloudStorage.stores.userHomeNamespace")
                    : null
                }
                onChange={handleStoreId}
                renderValue={v => stores.find(s => s.id === v)?.label}
                renderOption={({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                )}
              />
            )}
          </div>
        </FormControl>
        {permissionsError && (
          <Alert severity="warning" style={{ marginTop: "10px" }}>
            {permissionsError}
            <Link style={{ marginLeft: "3px" }} href="/integrations">
              {t("cloudStorage.stores.goToIntegrations")}
            </Link>
          </Alert>
        )}
      </OptionGroup>
    </>
  );
}

const initUser = (storeIdUserId?: string) => ({
  error: null,
  value: storeIdUserId || "",
});
