import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { OptionGroup, StyledPaper } from "components/Styled";
import styled from "styled-components";

export type Props = {
  value: string;
  checked: boolean;
  onChange: (type: "checked" | "value", value: string | boolean) => void;
  disabled?: boolean;
};

export default function OnNotMatch({
  onChange,
  value,
  checked,
  disabled,
}: Props) {
  const { t } = useTranslation("variables");

  return (
    <>
      <StyledPaper>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={checked}
              onChange={event => onChange("checked", event.target.checked)}
              disabled={disabled}
            />
          }
          label={
            <Typography
              color="textSecondary"
              component="span"
              style={{ fontWeight: "500" }}
            >
              {t("form.default.title")}
            </Typography>
          }
        />
        {checked && (
          <DefaultDefinitionGroup>
            <TextField
              name="defaultValue"
              label={t("form.default.label")}
              variant="outlined"
              onChange={event => onChange("value", event.target.value)}
              value={value || ""}
              fullWidth
              disabled={disabled}
            />
          </DefaultDefinitionGroup>
        )}
      </StyledPaper>
    </>
  );
}

const DefaultDefinitionGroup = styled(OptionGroup)`
  border-top: 1px solid #d6dbe0;
  margin-top: 1rem;
  padding-top: 1rem;
`;
