import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";

export default function InstallButton() {
  const { t } = useTranslation("storage");
  const [openInfo, setOpenInfo] = useState(false);

  const handleInstall = () => {
    setOpenInfo(false);
    window.open(process.env.REACT_APP_GOOGLE_MARKETPLACE_APP);
  };

  return (
    <>
      <Button color="default" variant="text" onClick={() => setOpenInfo(true)}>
        {t("install")}
        <LaunchOutlinedIcon
          style={{ fontSize: 14, marginLeft: 5, color: grey[600] }}
        />
      </Button>
      {openInfo && (
        <Dialog open={openInfo} onClose={() => setOpenInfo(false)}>
          <DialogTitle>{t("gappsInstallModal.title")}</DialogTitle>
          <DialogContent style={{ marginBottom: "20px" }}>
            <Typography variant="caption">
              {t("gappsInstallModal.body")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              size="small"
              color="primary"
              disableElevation
              onClick={() => setOpenInfo(false)}
            >
              {t("common:cancel")}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disableElevation
              onClick={handleInstall}
            >
              {t("gappsInstallModal.continueBtn")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
