/*
| 'account_created'
  | 'account_deleted'
  | 'account_updated';
*/
export const filtersAndSubfilters = {
  account: ["account_created", "account_updated", "account_deleted"],
  copy_policy: [
    "copy_policy_created",
    "copy_policy_deleted",
    "copy_policy_updated",
  ],
  dataset: ["dataset_created", "dataset_deleted", "dataset_updated"],
  domain: ["domain_created", "domain_deleted", "domain_updated"],
  organization: ["organization_updated"],
  rule: [
    "rule_created",
    "rule_deleted",
    "rule_updated",
    "rule_enabled",
    "rule_disabled",
  ],
  security: ["security_updated"],
  security_definition: [
    "security_definition_created",
    "security_definition_deleted",
  ],
  security_policy: ["security_policy_created", "security_policy_updated"],
  source: ["source_created", "source_updated", "source_deleted"],
  storage: [
    "storage_account_created",
    "storage_account_deleted",
    "storage_settings_updated",
    "update_storage_for_organization",
    // "update_onedrive_for_organization",
    // "update_gapps_for_organization",
    // "update_box_for_organization",
    "update_settings",
    // "attachment_header",
    // "attachment_footer",
  ],
  template_definition: [
    "template_definition_created",
    "template_definition_updated",
  ],
  template_policy: [
    "template_policy_created",
    "template_policy_updated",
    "template_policy_deleted",
  ],
  user: ["user_created", "login", "user_updated", "user_deleted"],
  variable: ["variable_created", "variable_deleted", "variable_updated"],
};

export const filters = Object.keys(filtersAndSubfilters);
