import { useTranslation } from "react-i18next";
import { useRuleContext } from "../../context/ruleContext";
import getEmailActionType from "./getEmailActionType";
import { UpdateMoreActions } from "../../context/reducers/updateMoreActions";
import { Label, OptionGroup } from "components/Styled";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

export default function BasicStorageErrorHandling() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const value = getEmailActionType(state.rule);

  function changeOption(newValue: "REJECT" | null) {
    const changes: UpdateMoreActions = {
      type: "updateMoreActions",
      payload: {
        onStorageError: {
          type: newValue,
        },
      },
    };

    dispatch(changes);
  }

  return (
    <OptionGroup>
      <Label wording={t("moreActions.onError.label")} />
      <RadioGroup
        name="onStorageError"
        value={value}
        onChange={event => {
          const newValue = event.target.value;
          changeOption(newValue === "DEFAULT" ? null : "REJECT");
        }}
      >
        <FormControlLabel
          control={<Radio color="primary" size="small" />}
          label={t("moreActions.onError.default")}
          value="DEFAULT"
        />
        <FormControlLabel
          control={<Radio color="primary" size="small" />}
          label={t("moreActions.onError.reject")}
          value="REJECT"
        />
      </RadioGroup>
    </OptionGroup>
  );
}
