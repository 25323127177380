import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@material-ui/core";
import ApplicationFields from "components/ApplicationFields";
import { FieldRow } from "components/Forms/StyledComponents";
import { useConditionsCtx } from "../../state/ConditionsProvider";
import useOrganization from "utils/useOrganization";
import { useState } from "react";
import SelectVariables from "./SelectVariables";
import { DashboardVariable } from "types";
import { changeTypeOfAnd, setApplicationFields } from "../actions";
import { AndIndex } from "pages/Variables/types";
import * as read from "../read";

type Option = "FIELDS" | "VARIABLES" | "AI";

export default function Sources({ index }: { index: AndIndex }) {
  const { t } = useTranslation("variables");
  const { organization } = useOrganization();
  const { updateVariable, variable } = useConditionsCtx();

  const { sources, type, variableIdList } = read.andCondition(variable, index);

  const [prevType, setPrevType] = useState(type);

  const [option, setOption] = useState<Option>(() => {
    if (type === "AI_QUESTION") return "AI";
    if (variableIdList.length) return "VARIABLES";
    return "FIELDS";
  });

  // Handles the cancel of AI Question
  if (prevType !== type) {
    setOption(prevOption =>
      prevType === "AI_QUESTION" ? "FIELDS" : prevOption
    );

    setPrevType(type);
  }

  const isReadOnly = read.isReadOnly(variable);
  const supportsOpenIA = organization?.integrations?.openAI;

  function handleSources(_, newVariables: DashboardVariable[]) {
    setApplicationFields(updateVariable, index, newVariables);

    /*
    if (vars.length) {
      const [{ type: sourceType }] = vars;
      const sources = vars.map(({ id }) => removeBraces(id));
      return updateCondition({ sources, sourceType });
    }
    return updateCondition({ sources: [], sourceType: "" });
    */
  }

  function onOptionChange(event) {
    const value = event.target.value as Option;
    setOption(value);
    changeTypeOfAnd(updateVariable, index, value);
    /*

    if (value === "AI") {
      updateCondition({
        type: "AI_QUESTION",
        sources: [],
        sourceType: "TEXT",
        parameters: [],
        toValue: removeVariablesInToValue(toValue, index),
      });
    } else {
      updateCondition({
        type: "",
        sources: value === "VARIABLES" ? [] : sources,
        variableIdList: value === "FIELDS" ? [] : variableIdList,
      });
    }
    */
  }

  if (type === "AI_QUESTION") return <></>;

  return (
    <FieldRow>
      <Select
        variant="outlined"
        name="option"
        value={option}
        onChange={onOptionChange}
        disabled={isReadOnly}
      >
        <MenuItem value="FIELDS">{t("form.sources.type.fields")}</MenuItem>
        <MenuItem value="VARIABLES">
          {t("form.sources.type.variables")}
        </MenuItem>
        {supportsOpenIA && (
          <MenuItem value="AI">{t("form.sources.type.aiQuestion")}</MenuItem>
        )}
      </Select>
      {option === "FIELDS" && (
        <ApplicationFields
          name="sources"
          fullWidth
          value={sources || []}
          onChange={handleSources}
          required={sources?.length === 0}
          disabled={isReadOnly}
        />
      )}
      {option === "VARIABLES" && <SelectVariables />}
    </FieldRow>
  );
}
