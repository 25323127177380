import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  capitalize,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@material-ui/core";
import { StyledPaper } from "components/Styled";
import SelectField from "components/Forms/SelectField";
import { DefaultDefinitionGroup, StyledTitle } from "../Styled";
import { DefaultDefProps } from "../types";

export const DefaultDefinition = ({
  formState,
  definitions,
  onDefault,
}: DefaultDefProps) => {
  const { t } = useTranslation("templatePolicies");
  const [enableDefault, setEnableDefault] = useState(false);
  const { pathname } = useLocation();
  const { defaultDefinition, cloudStorage } = formState;
  const isEdit: boolean = pathname.includes("/edit");

  useEffect(() => {
    if (isEdit && defaultDefinition) {
      setEnableDefault(true);
    }
  }, [isEdit, defaultDefinition]);

  return (
    <>
      <StyledPaper>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={enableDefault}
              onChange={() => setEnableDefault(prev => !prev)}
              disabled={!definitions}
            />
          }
          label={
            <Typography
              color="textSecondary"
              component="span"
              style={{ fontWeight: "500" }}
            >
              {t("form.defaultDefinition.switch")}
            </Typography>
          }
        />
        {enableDefault && (
          <DefaultDefinitionGroup>
            <StyledTitle>{t("form.defaultDefinition.title")}</StyledTitle>
            <SelectField
              name="defaultDefinition"
              label={t("form.defaultDefinition.label")}
              options={definitions}
              value={defaultDefinition}
              onChange={onDefault}
              disabled={!cloudStorage}
              menuTitle={t("form.predicates.definitionId.default", {
                storage: capitalize(cloudStorage),
              })}
              renderOption={({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              )}
            />
          </DefaultDefinitionGroup>
        )}
      </StyledPaper>
    </>
  );
};

export default DefaultDefinition;
