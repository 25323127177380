import { State } from "../types";

export function saveRuleFail(
  state: State,
  payload: SaveRuleFail["payload"]
): State {
  const { errorCode } = payload;
  console.log("errorCode: ", errorCode);
  return { ...state, asyncStatus: "idle" };
}

export type SaveRuleFail = {
  type: "saveRuleFail";
  payload: {
    errorCode?: string;
  };
};
