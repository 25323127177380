import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { Rule } from "pages/Rules/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StepDetails from "./StepDetails";
import steps from "./steps";
import StepTitle from "./StepTitle";

type Params = {
  rule: Rule;
};

export default function HorizontalStepper({ rule }: Params) {
  const { t } = useTranslation("rules");

  return (
    <Stepper alternativeLabel orientation="horizontal" activeStep={99}>
      {steps(rule, t).map(({ step, label }) => (
        <Step key={step} completed expanded>
          <StepLabel>
            <StepType>{label}</StepType>
            <Typography color="primary" variant="subtitle2">
              <StepTitle rule={rule} step={step} activeStep={99} />
            </Typography>
          </StepLabel>

          <StepDetailsContainer>
            <StepDetails rule={rule} step={step} />
          </StepDetailsContainer>
        </Step>
      ))}
    </Stepper>
  );
}

const StepType = styled("div")`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.58rem;
  color: #6d6d6d;
`;

const StepDetailsContainer = styled.div`
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
  & > ul {
    list-style-type: none;
    padding-inline-start: 0;
  }
`;
