import { useEffect, useState } from "react";
import type { Option } from "../types";
import throttle from "utils/throttle";
import useSearchOptions from "./useSearchOptions";
import sameOption from "./sameOption";

type Props = {
  onChange: (option: Option) => void;
  initialSelection?: Option;
};

export default function useAutocomplete({
  onChange: emitChange = () => {},
  initialSelection,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [selection, setSelection] = useState<Option>(initialSelection);
  const [options, setOptions] = useState<Option[]>([]);

  const search = useSearchOptions();

  useEffect(() => {
    async function searchTerm() {
      if (inputValue.trim()) {
        throttle(async () => {
          setLoading(true);
          const options = await search(inputValue);
          setOptions(options);
          setLoading(false);
        }, 200);
      }
    }

    searchTerm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  function changeSelection(value) {
    const option = value as Option;

    if (sameOption(selection, option)) return;

    setSelection(option);
    emitChange(option);
  }

  return {
    changeInputValue: setInputValue,
    changeSelection,
    inputValue,
    selection,
    loading,
    options,
  };
}
