import { Option } from "../types";
import { fetchAccount, fetchAccounts } from "utils/api/accounts";
import { fetchGroup, fetchGroups } from "utils/api/groups";
import { getErrorCode } from "utils/apiRequestWithErrorCode";

export async function searchAccounts(startWith: string): Promise<Option[]> {
  const accounts = await fetchAccounts({
    limit: 100000,
    partialEmailOrAlias: startWith,
  });
  const searchTerm = startWith.toLowerCase();

  return accounts.reduce((listFiltered, item) => {
    if (item.email.toLowerCase().startsWith(searchTerm)) {
      const option = {
        label: item.email,
        type: "ACCOUNT",
        managed: true,
      };

      listFiltered.push(option);
    }
    return listFiltered;
  }, []);
}

export async function searchGroups(startWith: string): Promise<Option[]> {
  const groups = await fetchGroups(100000, 0, startWith);
  const searchTerm = startWith.toLowerCase();
  return groups.reduce((listFiltered, item) => {
    if (item.name.toLowerCase().startsWith(searchTerm)) {
      const option = {
        label: item.name,
        type: "GROUP",
        managed: true,
        groupId: item.id,
      };

      listFiltered.push(option);
    }
    return listFiltered;
  }, []);
}

export async function getAccountOption(email: string): Promise<Option | null> {
  try {
    const account = await fetchAccount(email);
    if (!account) return null;

    const accountOption: Option = {
      label: account.email,
      type: "ACCOUNT",
      managed: true,
    };

    return accountOption;
  } catch (error) {
    const errorCode = getErrorCode(error);
    console.error("ErroCode when fetch account: ", errorCode);
    return null;
  }
}

export async function getGroupOption(groupId: string): Promise<Option | null> {
  try {
    const group = await fetchGroup(groupId);
    if (!group) return null;

    const option: Option = {
      label: group.name,
      type: "GROUP",
      managed: true,
      groupId: group.id,
    };

    return option;
  } catch (error) {
    const errorCode = getErrorCode(error);
    console.error("ErroCode when fetch account: ", errorCode);
    return null;
  }
}
