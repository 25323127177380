import apiRequest, { isErrorWithCode } from "utils/apiRequestWithErrorCode";
import { User } from "@dashboard-v3/api";
import i18n from "i18n";

/**
 * Errors: "DOMAIN_NOT_ALLOWED" | "DOMAIN_ALREADY_EXIST" | "SYSTEM_FAILURE"
 *
 * @param {string} domain
 * @returns {Promise<({ valid: boolean, code: string })>}
 */
export async function canRegisterWithDomain(domain) {
  try {
    await apiRequest("GET", `/domains/can-register?domain=${domain}`);
    return { valid: true };
  } catch (error) {
    if (isErrorWithCode(error)) {
      return { valid: false, code: error.code };
    }

    return {
      valid: false,
      code: error instanceof Error ? error.message : null,
    };
  }
}

type Registration = {
  domain: string;
  organizationName: string;
  email: string;
  password?: string;
  contactNumber: string;
  contactEmail?: string;
  userName: string;
};

type RegistrationResponse = {
  user: User;
  sessionDuration: number;
};

/**
 * Errors: 'ORGANIZATION_ALREADY_EXIST',
 * 'USER_ALREADY_EXIST',
 * 'DOMAIN_ALREADY_EXIST',
 * 'DOMAIN_NOT_ALLOWED',
 *
 *
 * Return null when use "on_premise"
 */
export async function registerDomain(
  source: "google" | "office" | "on_premise",
  registration: Registration
): Promise<RegistrationResponse> {
  if (source === "on_premise") {
    // eslint-disable-next-line no-restricted-globals
    const callbackUrl = `${location.origin}/register`;
    await apiRequest("POST", "/register/on-premise", {
      ...registration,
      callbackUrl,
      lng: i18n.language,
    });
    return null;
  }
  return apiRequest("POST", `/register/${source}`, registration);
}

export async function endOnPremiseRegistration(id) {
  return apiRequest("POST", `/register/on-premise/${id}`);
}
