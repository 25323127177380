import styled from "styled-components";
import { Accordion, Divider, Link, Paper } from "@material-ui/core";

export const ItemContainer = styled.div`
  background-color: #eef2f6;
  border-radius: 4px;
  margin: 16px 0;
  text-align: left;
  padding: 16px 16px 8px 16px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const WarningLink = styled(Link)<{ component: string }>`
  margin-left: 5px;
  vertical-align: inherit;
  font-size: 1rem;
`;

export const Panel = styled(Paper)`
  align-self: start;
  padding: 20px;
  text-align: center;
`;

export const PanelHeader = styled.header`
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  text-align: center;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const FiltersHelpLink = styled(Link)`
  line-height: 0;
  margin-top: 4px;
  svg {
    font-size: 14px;
    cursor: pointer;
    margin-left: 3px;
    vertical-align: text-bottom;
  }
`;

export const AdvancedFiltersAccordion = styled(Accordion)`
  box-shadow: none;

  ::before {
    display: none;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordionDetails-root {
    flex-direction: column;
    padding: 0;
    gap: 15px;
  }
`;
