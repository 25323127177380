import { Group } from "@dashboard-v3/api";
import { Skeleton } from "@material-ui/lab";
import { useFetch } from "utils/api/useFetch";

export default function GroupName({ id }: { id: string }) {
  const { data: group, isLoading } = useFetch<Group>(`/groups/${id}`);

  if (isLoading) {
    return <Skeleton variant="text" width="10rem" />;
  }

  return <>{group.name}</>;
}
