import { Box } from "@material-ui/core";
import { OptionGroup } from "components/Styled";
import styled from "styled-components";
import { useState } from "react";
import {
  BetweenTimeRange,
  SinceTimeRange,
  TimeRange,
  TimeRangeUnits,
  UntilTimeRange,
} from "@dashboard-v3/api";
import SinceDate from "./SinceDate";
import RepeatSince from "./RepeatSince";
import UpToDate from "./UpToDate";
import RepeateUpToDate from "./RepeateUpToDate";
import BetweenDates from "./BetweenDates";
import dayjs from "dayjs";
import Selector from "./Selector";
import { isRepeatable, TimeRangeOption } from "./timeRangeOptions";
import dateToInputValue from "./dateToInputValue";

export const SelectContainer = styled.div`
  grid-template-columns: auto auto;
  display: grid;
  column-gap: 15px;
  align-items: center;
  margin-top: 20px;
`;

export type TimeRangeAndRepeat = {
  timeRange?: TimeRange;
  repeatable: boolean;
};

type Params = {
  value?: TimeRangeAndRepeat;
  disabled?: boolean;
  onChange: (newValue: TimeRangeAndRepeat) => void;
};

export default function SelectTimeRange({ value, disabled, onChange }: Params) {
  const [option, setOption] = useState<TimeRangeOption>(
    timeRangeToOption(value)
  );

  const [timeRangeValue, setTimeRangeValue] = useState<TimeRangeAndRepeat>(
    value || toValue(option)
  );

  function changeOption(newOption: TimeRangeOption) {
    changeValue(toValue(newOption));
    setOption(newOption);
  }

  function changeTimeRange(timeRange: TimeRange) {
    changeValue({ timeRange, repeatable: isRepeatable(option) });
  }

  function changeValue(newValue: TimeRangeAndRepeat) {
    setTimeRangeValue(newValue);
    onChange(newValue);
  }

  return (
    <>
      <OptionGroup>
        <Selector value={option} disabled={disabled} onChange={changeOption} />
        <Box mt="20px">
          {option === "SINCE_A_DATE" && (
            <SinceDate
              value={timeRangeValue.timeRange as SinceTimeRange}
              onChange={changeTimeRange}
              disabled={disabled}
            />
          )}

          {option === "REPEAT_SINCE" && (
            <RepeatSince
              value={timeRangeValue.timeRange as TimeRangeUnits}
              onChange={changeTimeRange}
              disabled={disabled}
            />
          )}

          {option === "UP_TO_A_DATE" && (
            <UpToDate
              value={timeRangeValue.timeRange as UntilTimeRange}
              onChange={changeTimeRange}
              disabled={disabled}
            />
          )}

          {option === "REPEAT_UP_TO" && (
            <RepeateUpToDate
              value={timeRangeValue.timeRange as TimeRangeUnits}
              onChange={changeTimeRange}
              disabled={disabled}
            />
          )}

          {option === "BETWEEN_DATES" && (
            <BetweenDates
              value={timeRangeValue.timeRange as BetweenTimeRange}
              onChange={changeTimeRange}
              disabled={disabled}
            />
          )}
        </Box>
      </OptionGroup>
    </>
  );
}

function toValue(option: TimeRangeOption): TimeRangeAndRepeat | null {
  const currentDate = dateToInputValue(new Date());

  switch (option) {
    case "ALL":
      return { repeatable: false };
    case "REPEAT_ALL":
      return { repeatable: true };
    case "SINCE_A_DATE":
      return {
        timeRange: {
          mode: "DATES",
          since: currentDate,
        },
        repeatable: false,
      };
    case "REPEAT_SINCE":
      return {
        timeRange: {
          timeUnit: "MONTH",
          unit: 3,
          mode: "AFTER_UNIT",
        },
        repeatable: true,
      };
    case "UP_TO_A_DATE":
      return {
        timeRange: {
          mode: "DATES",
          until: currentDate,
        },
        repeatable: false,
      };
    case "REPEAT_UP_TO":
      return {
        timeRange: {
          timeUnit: "MONTH",
          unit: 3,
          mode: "BEFORE_UNIT",
        },
        repeatable: true,
      };
    case "BETWEEN_DATES":
      return {
        timeRange: {
          mode: "DATES",
          since: dateToInputValue(dayjs().subtract(2, "days").toDate()),
          until: currentDate,
        },
        repeatable: false,
      };
  }
}

function timeRangeToOption(value?: TimeRangeAndRepeat) {
  if (!value) return "ALL";

  if (!value.timeRange && value.repeatable) return "REPEAT_ALL";

  const mode = value.timeRange?.mode;
  if (mode === "DATES") {
    if ("since" in value.timeRange && "until" in value.timeRange) {
      return "BETWEEN_DATES";
    }
    if ("since" in value.timeRange) return "SINCE_A_DATE";
    if ("until" in value.timeRange) return "UP_TO_A_DATE";
  }

  if (mode === "BEFORE_UNIT") return "REPEAT_UP_TO";
  if (mode === "AFTER_UNIT") return "REPEAT_SINCE";
}
