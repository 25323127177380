import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { initState, SourceState, useActions } from "./sourcesContext";

const SourcesContext = React.createContext(null);

export function SourcesProvider({ children }) {
  const { t } = useTranslation("accounts");
  const { enqueueSnackbar } = useSnackbar();

  const showError = message => enqueueSnackbar(message, { variant: "error" });

  const [state, setState] = React.useState<SourceState>(initState);
  const actions = useActions(state, setState, showError);
  const context = { state, t, actions };

  return (
    <SourcesContext.Provider value={context}>
      {children}
    </SourcesContext.Provider>
  );
}

export function useSourcesContext() {
  return React.useContext(SourcesContext);
}
