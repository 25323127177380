import { SecurityDefinition } from "@dashboard-v3/api";
import {
  Divider,
  ListSubheader,
  MenuItem,
  Typography,
} from "@material-ui/core";
import SelectField, {
  Params as SelectFieldParams,
} from "components/Forms/SelectField";
import { TFunction, useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props
  extends Omit<SelectFieldParams<string>, "options" | "renderOption"> {
  options: Array<SecurityDefinition>;
}

type Option = {
  id: string;
  name: string;
  isPreset: boolean;
};

export default function SelectSecuritytDef({
  options,
  value,
  onChange,
  ...rest
}: Props) {
  const { t } = useTranslation("securityPolicies");
  const defOptions = toSecurityDefOptions(options, t);

  function renderDefinitionValue(value: unknown) {
    const match = defOptions.find(({ id }) => id === value.toString());
    return <>{match?.name || ""}</>;
  }

  function getSecurityDefValue() {
    return value === null || typeof value === "undefined"
      ? "DO_NOT_UPLOAD"
      : value;
  }

  return (
    <SelectField<Option>
      {...rest}
      disabled={options.length <= 1}
      label={t("form.predicates.definitionId.label")}
      options={defOptions}
      value={getSecurityDefValue()}
      onChange={onChange}
      required
      renderValue={renderDefinitionValue}
      renderOption={(item, index, array) => {
        const { id, name, isPreset } = item;

        const options = [
          <MenuItem style={{ display: "block" }} value={id} key={id}>
            <Typography variant="caption">
              {isPreset
                ? t("securityDefinitions:presets.label")
                : t("securityDefinitions:definitions.label")}
            </Typography>
            <Typography>{name}</Typography>
          </MenuItem>,
        ];

        if (isLastSystemDefault(item, index, array)) {
          options.push(<Separator />);
        }

        return options;
      }}
    />
  );
}

const toSecurityDefOptions = (
  defs: SecurityDefinition[],
  t: TFunction
): Option[] => {
  if (defs && defs.length) {
    const opts = defs.map(def => ({
      id: def.id,
      name: t(`securityDefinitions:presets.${def.id}.name`, def.name),
      isPreset: def.systemDefault,
    }));

    return [
      {
        id: "DO_NOT_UPLOAD",
        name: t("securityDefinitions:presets.DO_NOT_UPLOAD.name"),
        isPreset: true,
      },
      ...opts,
    ];
  }
  return [];
};

function isLastSystemDefault(item: Option, index: number, array: Option[]) {
  if (item.isPreset) {
    const isLast = index + 1 === array.length;
    if (isLast) return false;
    return !array[index + 1].isPreset;
  }
  return false;
}

function Separator() {
  return (
    <ListSubheader key="divider">
      <StyledDivider />
    </ListSubheader>
  );
}

export const StyledDivider = styled(Divider)`
  margin: 8px 0 8px 0;
`;
