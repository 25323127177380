import { useTranslation } from "react-i18next";
import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import EditBtn from "../EditBtn";
import { Users } from "@dashboard-v3/api";
import EmptyItem from "./EmptyItem";
import DeleteBtn from "./DeleteBtn";

type Params = {
  users: Users.List;
  onDelete: (userId: string) => void;
  onChange: (id: string, changes: Users.Update) => Promise<void>;
};

export default function Items({ users, onDelete, onChange }: Params) {
  const { t } = useTranslation("users");

  const onSaveUser = user => {
    return onChange(user.id, {
      name: user.name,
      password: user.password,
    });
  };

  if (users.length === 0) {
    return <EmptyItem>{t("items.noUsersForOrganization")}</EmptyItem>;
  }

  return (
    <>
      {users.map(user => {
        const { name, email, id } = user;

        return (
          <TableRow data-testid="user-list__item" key={email}>
            <TableCell>
              {name ? (
                <Typography>{name}</Typography>
              ) : (
                <Typography style={{ color: "rgb(170, 170, 170)" }}>
                  {t("items.noName")}
                </Typography>
              )}
            </TableCell>
            <TableCell>
              <Typography>{email}</Typography>
            </TableCell>
            <TableCell align="right">
              <Box style={{ justifySelf: "end" }}>
                <EditBtn user={user} onSave={onSaveUser} />
                {users.length > 1 && (
                  <DeleteBtn userId={id} onDelete={onDelete} />
                )}
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
