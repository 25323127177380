import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import WithIconHelper from "components/WithIconHelper";
import { useStyles } from "./styles";

interface Props {
  isReadOnly: boolean;
  error: string;
  disabled: boolean;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export default function SubdomainInput(props: Props) {
  const { t } = useTranslation("storage");
  const classes = useStyles();
  const { value, error, isReadOnly, onChange, disabled } = props;

  if (isReadOnly) {
    return (
      <div className={classes.inputContainer}>
        <Typography variant="subtitle2">
          {t("mxheroShares.inputTitle")}
        </Typography>
        <TextField
          label={t("mxheroShares.inputLabel")}
          className={classes.disabledInput}
          variant="outlined"
          disabled
          fullWidth
          value={value}
        />
      </div>
    );
  }

  return (
    <div className={classes.inputContainer}>
      <Typography variant="subtitle2">
        {t("mxheroShares.inputTitle")}
      </Typography>
      <WithIconHelper
        position="after"
        buttonStyles={{ marginLeft: "10px" }}
        text={t("mxheroShares.inputHelper")}
      >
        <TextField
          variant="outlined"
          className={classes.enabledInput}
          fullWidth
          name="subdomain"
          label={t("mxheroShares.inputLabel")}
          placeholder={t("mxheroShares.inputPlaceholder")}
          error={Boolean(error)}
          helperText={error}
          disabled={disabled}
          value={value}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">https://</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {process.env.REACT_APP_MXHERO_SHARE_DOMAIN}
              </InputAdornment>
            ),
          }}
        />
      </WithIconHelper>
    </div>
  );
}
