import { useState } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Link, Typography } from "@material-ui/core";
import SectionHeader from "components/SectionHeader";
import { Button } from "components/Forms/StyledComponents";
import List from "./List";
import Form from "./Form";

function Index() {
  const { t } = useTranslation("templatePolicies");
  // TODO: Remove the loading state from here and move into Form component
  const [loading, setLoading] = useState<boolean>(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const isOnForm = pathname.includes("/new") || pathname.includes("/edit");

  const handleRouting = () => {
    return history.push(
      isOnForm ? "/template-policies" : "/template-policies/new"
    );
  };

  const renderButtonText = () => {
    return isOnForm ? t("common:backToList") : t("common:create");
  };

  return (
    <>
      <SectionHeader
        title={t("title")}
        sideBtn={
          <Button wording={renderButtonText()} onClick={handleRouting} />
        }
      >
        <Typography>
          <Trans
            i18nKey="description"
            t={t}
            components={{ lnk: <Link href="/template-definitions" /> }}
          />
        </Typography>
      </SectionHeader>
      <Switch>
        <Route path="/template-policies" exact>
          <List />
        </Route>
        <Route path="/template-policies/new" exact>
          <Form loading={loading} setLoading={setLoading} />
        </Route>
        <Route path="/template-policies/edit/:id" exact>
          <Form loading={loading} setLoading={setLoading} />
        </Route>
      </Switch>
    </>
  );
}

export default Index;
