import { useTranslation, Trans } from "react-i18next";
import produce from "immer";
import {
  Link,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VariablesSelector from "components/VariablesSelector";
import { OptionGroup, StyledPaper, VerticalLine } from "components/Styled";
import useVariables from "utils/useVariables";
import StorageFilepathSelector, { FolderPath } from "./StorageFilepathSelector";
import { ConditionTitle, StyledTitle } from "../Styled";

import { DefaultType, FormState } from "../types";
import { StorageProvider } from "@dashboard-v3/api";
import { canResetStorageProvider } from "./StorageFilepathSelector/utils";

interface Params {
  index: number;
  pathErrors: { [key: number]: string };
  resetPathErrors: () => void;
  formData: {
    formState: FormState;
    setValues: (
      values: Partial<FormState> | ((prevState: FormState) => FormState)
    ) => void;
  };
}

enum MatchOption {
  AllMatch = "ALL_MATCH",
  OneMatches = "ONE_MATCHES",
  NoMatches = "NO_MATCHES",
}

const PolicyCondition = ({
  formData,
  index,
  pathErrors,
  resetPathErrors,
}: Params) => {
  const { t } = useTranslation("copyPolicy");
  const { loading, userVariables } = useVariables();
  const { formState, setValues } = formData;
  const { predicates } = formState;

  global.copyPolicy = formState;

  const emptyVariables = () => {
    if (loading) return false;
    return userVariables?.length === 0;
  };

  const removePredicate = () => {
    if (predicates.length > 1) {
      setValues({ predicates: predicates.filter((_, i) => i !== index) });
    }
  };

  const onVariableChange = (variableIds: string[]) => {
    const newState = produce(formState, draft => {
      draft.predicates[index].variableIdList = variableIds;
    });

    setValues(newState);
  };

  const onFolderPathChange = (update: FolderPath) => {
    resetPathErrors();
    setValues(
      produce(draft => {
        draft.predicates[index].folderPath = update;
      })
    );
  };

  const onStorageProviderChange = (provider: StorageProvider) => {
    const update = produce(formState, draft => {
      draft.storageProvider = provider;
    });
    setValues(update);
  };

  const onMatchChange = event => {
    const value = event.target.value as MatchOption;
    const newState = produce(formState, draft => {
      if (value === MatchOption.AllMatch) {
        draft.predicates[index].firstMatch = false;
        draft.predicates[index].match = true;
      }

      if (value === MatchOption.NoMatches) {
        draft.predicates[index].firstMatch = false;
        draft.predicates[index].match = false;
      }

      if (value === MatchOption.OneMatches) {
        draft.predicates[index].firstMatch = true;
        draft.predicates[index].match = true;
      }
    });

    setValues(newState);
  };

  const getMatchValue = () => {
    const { match, firstMatch } = predicates[index];
    if (match && !firstMatch) return MatchOption.AllMatch;
    if (match && firstMatch) return MatchOption.OneMatches;
    if (!match && !firstMatch) return MatchOption.NoMatches;
    return MatchOption.AllMatch;
  };

  const isProviderDisabled = () => {
    const { predicates, defaultCopies } = formState;
    const defaultTypes = Object.keys(defaultCopies) as DefaultType[];

    if (predicates.some(predicate => predicate.folderPath.storeId)) {
      return true;
    }
    const containsStoreId = defaultTypes.some(type => {
      return !!defaultCopies[type].storeId;
    });

    if (containsStoreId) return true;

    const pickerIndex = predicates.findIndex(p => !!p.folderPath.parentName);

    if (pickerIndex !== -1) return pickerIndex !== index;

    return defaultTypes.some(type => !!defaultCopies[type].parentName);
  };

  return (
    <>
      <VerticalLine size="sm" />
      <StyledPaper>
        <ConditionTitle color="textSecondary">
          {t("form.policyCondition", { count: index + 1 })}
          {predicates.length > 1 && (
            <IconButton
              size="small"
              aria-label="delete"
              onClick={removePredicate}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </ConditionTitle>
        <OptionGroup>
          <StyledTitle>{t("form.whenThisVariableIsFullfilled")}</StyledTitle>
          <VariablesSelector
            label={t("form.selectAVariable")}
            value={predicates[index].variableIdList}
            onChange={onVariableChange}
            required
          />

          <Box display="flex" mt=".5em" mb="1em">
            <RadioGroup
              row
              aria-label="match-or-not"
              name="match-or-not"
              value={getMatchValue()}
              onChange={onMatchChange}
            >
              <FormControlLabel
                value={MatchOption.AllMatch}
                control={<Radio color="primary" />}
                label={t("form.allMatch")}
              />
              <FormControlLabel
                value={MatchOption.OneMatches}
                control={<Radio color="primary" />}
                label={t("form.oneMatches")}
              />
              <FormControlLabel
                value={MatchOption.NoMatches}
                control={<Radio color="primary" />}
                label={t("form.noMatches")}
              />
            </RadioGroup>
          </Box>
          {emptyVariables() && (
            <FormHelperText margin="dense" error>
              <Trans i18nKey="youCanExcludeCertainAttachments" t={t}>
                Cannot create a condition without at least one{" "}
                <Link href="/variables/new" underline="always">
                  variable
                </Link>
              </Trans>
            </FormHelperText>
          )}
          <StorageFilepathSelector
            folderPath={predicates[index].folderPath}
            storageProvider={formState?.storageProvider}
            pathError={pathErrors && pathErrors[index]}
            isProviderDisabled={isProviderDisabled()}
            onFolderPathChange={onFolderPathChange}
            onStorageProviderChange={onStorageProviderChange}
            canResetStorage={canResetStorageProvider(formState)}
          />
        </OptionGroup>
      </StyledPaper>
    </>
  );
};

export default PolicyCondition;
