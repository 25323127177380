import { DeepPartial } from "utils/mappedTypes";
import apiRequestWithErrorCode from "utils/apiRequestWithErrorCode";
import { ServerType } from "./ChooseProvider";
import { SingleAccount } from "./ChooseProvider/singleAccount";

import {
  ImapServer,
  Mailbox,
  MailboxAccountState,
  MailboxSingleAccount,
} from "@dashboard-v3/api";

export const getServerUse = (
  mailBox: DeepPartial<Mailbox>
): ServerType | null => {
  if (!mailBox?.provider) return null;

  const serverType: ServerType = {
    provider: mailBox.provider,
    authentication: mailBox.authentication,
  };

  if (
    serverType.authentication === "INDIVIDUAL" &&
    (serverType.provider === "GOOGLE" || serverType.provider === "OFFICE365")
  ) {
    serverType.accounts = mailBox.accounts as SingleAccount;
  }

  return serverType;
};

export function isSingleAccount(mailbox: Mailbox) {
  return (
    mailbox?.authentication === "INDIVIDUAL" &&
    (mailbox?.provider === "GOOGLE" || mailbox?.provider === "OFFICE365")
  );
}

export function isOfficeIndividual(mailbox: Mailbox) {
  return (
    mailbox?.authentication === "INDIVIDUAL" &&
    mailbox?.provider === "OFFICE365"
  );
}

export const enableAccountSelector = (
  mailbox: Mailbox,
  useOtherMailboxes: boolean
) => {
  if (!mailbox?.authentication) return false;

  if (isSingleAccount(mailbox) && mailbox.provider === "OFFICE365") {
    return useOtherMailboxes;
  }

  return !isSingleAccount(mailbox);
};

export function getAuthSingleAccount(mailbox: Mailbox) {
  if (!isSingleAccount(mailbox)) return null;
  if (mailbox.loginUser) return mailbox.loginUser;
  return mailbox.accounts.entities[0];
}

export const isValidImapConfig = (values: ImapServer) => {
  if (values) {
    const { ssl, port, ...server } = values;
    const keys = ["host", "username", "password", "authenticationMechanism"];
    return keys.every(key => !!server[key]);
  }
  return false;
};

export async function getAccountState(
  provider: "GOOGLE" | "OFFICE365",
  userId: string
) {
  return apiRequestWithErrorCode<MailboxAccountState>(
    "GET",
    `/auth/mailbox/${provider.toLowerCase()}/individual/${encodeURIComponent(
      userId
    )}`
  );
}

export async function getMailboxAccounts() {
  return apiRequestWithErrorCode<MailboxSingleAccount[]>(
    "GET",
    "/mailbox-accounts"
  );
}
