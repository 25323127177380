const hoursToRevalidateSensitiveActions = 1.0;
const AUTH_CODE_EXPIRATION_MINUTES = 10;
const locales = ['en-US', 'fr-FR', 'ja-JP'];

/** @type {import("../..").ChatGptModel[]} */
const chatGptModels = ['GPT_3_5_TURBO', 'GPT_4_TURBO', 'GPT_4o'];

/** @type {import("../..").ChatGptModel} */
const chatGptDefaultModel = 'GPT_3_5_TURBO';

module.exports = {
  hoursToRevalidateSensitiveActions,
  AUTH_CODE_EXPIRATION_MINUTES,
  locales,
  chatGptModels,
  chatGptDefaultModel,
};
