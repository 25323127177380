import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ImapServer,
  ValidateMailboxRequest,
  ValidateMailboxResponse,
} from "@dashboard-v3/api";
import styled from "styled-components";
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import PageLoading from "components/PageLoading";
import SelectField from "components/Forms/SelectField";
import { Button } from "components/Forms/StyledComponents";
import apiRequest from "utils/apiRequestWithErrorCode";
import { isValidImapConfig } from "./utils";

interface Params {
  value?: ImapServer;
  onChange: (imapServerChanges: ImapServer) => void;
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  > h6 {
    font-weight: 500;
  }
`;

const defaultPort = 993;

export default function ImapServerConfig({
  value: initialValue,
  onChange,
}: Params) {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  function updateField(event) {
    const { value, name, checked } = event.target;

    let updated = { ...initialValue };

    switch (name) {
      case "port":
        updated.port = Number(value);
        break;
      case "ssl":
        updated.ssl = checked;
        break;
      default:
        updated = {
          ...initialValue,
          [name]: value,
        };
        break;
    }

    updated.port ??= defaultPort;
    updated.ssl ??= false;

    onChange(updated);
  }

  const handleImapValidation = useCallback(async () => {
    setLoading(true);
    let isValid: boolean;

    try {
      const validationBody: ValidateMailboxRequest = {
        inboxType: "IMAP",
        imapServer: initialValue,
      };
      const response = await apiRequest<ValidateMailboxResponse>(
        "POST",
        "/mailbox/validate",
        validationBody
      );
      isValid = response.isValid;
    } catch (error) {
      isValid = false;
    } finally {
      setLoading(false);
    }

    if (isValid) {
      enqueueSnackbar(t("imapConfig.test.valid"), { variant: "success" });
    } else {
      enqueueSnackbar(t("target.MAIL_BOX.checkError.INVALID"), {
        variant: "error",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <>
      <PageLoading loading={loading} />
      <TitleContainer>
        <Typography variant="subtitle1" data-testid="imapConfigTitle">
          {t("imapConfig.title")}
        </Typography>
        <Button
          size="small"
          wording={t("imapConfig.test.btn")}
          onClick={handleImapValidation}
          disabled={!isValidImapConfig(initialValue)}
        />
      </TitleContainer>
      <FormControl fullWidth variant="outlined">
        <TextField
          name="username"
          value={initialValue?.username || ""}
          onChange={updateField}
          fullWidth
          label={t("imapConfig.account")}
          margin="normal"
          type="text"
          variant="outlined"
          required
          inputProps={{ "data-testid": "imap-config__username-input" }}
        />
        <TextField
          name="password"
          value={initialValue?.password || ""}
          onChange={updateField}
          fullWidth
          label={t("imapConfig.password")}
          margin="normal"
          type="password"
          variant="outlined"
          required
          inputProps={{ "data-testid": "imap-config__password-input" }}
        />
        <TextField
          name="host"
          value={initialValue?.host || ""}
          onChange={updateField}
          fullWidth
          label={t("imapConfig.host")}
          margin="normal"
          type="text"
          variant="outlined"
          required
          inputProps={{ "data-testid": "imap-config__host-input" }}
        />
        <TextField
          name="port"
          value={initialValue?.port || defaultPort}
          onChange={updateField}
          fullWidth
          label={t("imapConfig.port")}
          margin="normal"
          type="number"
          variant="outlined"
          required
          inputProps={{ "data-testid": "imap-config__port-input" }}
        />
        <Box mb={2} mt={2}>
          <SelectField
            name="authenticationMechanism"
            label={t("imapConfig.authType")}
            options={["PLAIN", "LOGIN"]}
            inputProps={{ "data-testid": "imap-config__auth-select" }}
            value={initialValue?.authenticationMechanism || ""}
            onChange={updateField}
            renderOption={val => (
              <MenuItem key={val} value={val}>
                {t(`imapConfig.authOption.${val}`)}
              </MenuItem>
            )}
          />
        </Box>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={Boolean(initialValue?.ssl)}
              name="ssl"
              onChange={updateField}
              data-testid="imap-config__port-ssl-switch"
            />
          }
          label={<Typography>{t("imapConfig.useSSL")}</Typography>}
        />
      </FormControl>
    </>
  );
}
