import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@material-ui/core";
import { StatCardsContainer, StyledStatsCard } from "./styled";
import formatNumber from "utils/formatNumber";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";
import { MailboxRuleStats } from "@dashboard-v3/api";
import { Suspense } from "react";
import { taskIsEnded } from "../getStatusGrouped";
import refreshInterval from "./refreshInterval";

type RuleIdParam = { ruleId: string };

export default function Loader({ ruleId }: RuleIdParam) {
  return (
    <Suspense fallback={<Loading />}>
      <AccountsCards ruleId={ruleId} />
    </Suspense>
  );
}

function AccountsCards({ ruleId }: RuleIdParam) {
  const { t } = useTranslation("stats");

  const { data } = useSWR<MailboxRuleStats>(`/rules/${ruleId}/stats`, fetcher, {
    revalidateOnFocus: false,
    suspense: true,
    refreshInterval: lastData =>
      taskIsEnded(lastData?.executionStatus) ? 0 : refreshInterval,
  });

  const {
    amountAccountsFinished,
    amountAccountsFresh,
    amountAccountsQueued,
    amountAccountsRemoved,
    amountAccountsRunning,
    amountAccountsTotal,
    amountAccountsWaitingNextExecution,
    amountAccountsWithConnectionError,
    amountAccountsWithOtherErrors,
  } = data?.accounts;

  return (
    <StatCardsContainer>
      <StyledStatsCard>
        <Typography variant="body1">
          {t("accounts.accountCards.stats.amountAccountsQueued")}
        </Typography>
        <Typography variant="h6">
          {formatNumber(
            amountAccountsFresh +
              amountAccountsQueued +
              amountAccountsWaitingNextExecution +
              amountAccountsRunning
          )}
        </Typography>
      </StyledStatsCard>
      <StyledStatsCard>
        <Typography variant="body1">
          {t("accounts.accountCards.stats.amountAccountsFinished")}
        </Typography>
        <Typography variant="h6">
          {formatNumber(amountAccountsFinished)}
        </Typography>
      </StyledStatsCard>
      <StyledStatsCard>
        <Typography variant="body1">
          {t("accounts.accountCards.stats.amountAccountsRemoved")}
        </Typography>
        <Typography variant="h6">
          {formatNumber(amountAccountsRemoved)}
        </Typography>
      </StyledStatsCard>
      <StyledStatsCard>
        <Typography variant="body1">
          {t("accounts.accountCards.stats.amountAccountsWithErrors")}
        </Typography>
        <Typography variant="h6">
          {formatNumber(
            amountAccountsWithConnectionError + amountAccountsWithOtherErrors
          )}
        </Typography>
      </StyledStatsCard>
      <StyledStatsCard>
        <Typography variant="body1">
          {t("accounts.accountCards.stats.amountAccountsTotal")}
        </Typography>
        <Typography variant="h6">
          {formatNumber(amountAccountsTotal)}
        </Typography>
      </StyledStatsCard>
    </StatCardsContainer>
  );
}

function Loading() {
  const loadingCards = [...Array(5).keys()];

  return (
    <StatCardsContainer>
      {loadingCards.map(id => (
        <StyledStatsCard key={id}>
          <CircularProgress style={{ color: "#009ed2" }} />
        </StyledStatsCard>
      ))}
    </StatCardsContainer>
  );
}
