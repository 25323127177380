import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Script from "@gumgum/react-script-tag";
import apiRequest from "utils/apiRequestWithErrorCode";
import "./BoxApi.css";
import openAuthPopup from "pages/Integrations/Accounts/openAuthPopup";
import { StorageAccount } from "@dashboard-v3/api";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { BoxPickerParams } from "../types";
import { ProgressContainer } from "../Styled";

type TokenResponse = {
  accessToken: string;
  expireAt: Date;
};

const BoxPicker = ({ onChange, multiSelect, open }: BoxPickerParams) => {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const [auth, setAuth] = useState<{
    accessToken?: string;
    account?: string;
  }>();

  useEffect(() => {
    async function authInBox() {
      try {
        const storageAccount = (await openAuthPopup("BOX")) as StorageAccount;
        setAuth({ account: storageAccount.user });
        await getAccessToken(storageAccount.user);
      } catch (error) {
        closeModal();
      }
    }

    async function getAccessToken(account) {
      const path = `/storage-accounts/${encodeURIComponent(
        account
      )}:BOX/access-token`;

      try {
        const response = await apiRequest<TokenResponse>("GET", path);
        setAuth(prev => ({ ...prev, accessToken: response.accessToken }));
      } catch (error) {
        console.error(error);
        closeModal();
        enqueueSnackbar(t("error.fetchBoxUserAccessToken"), {
          variant: "error",
        });
      }
    }

    if (open) {
      authInBox();
    } else {
      setAuth(null);
    }

    return () => {
      setAuth(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function isFetchingToken() {
    return auth?.accessToken && !auth?.account;
  }

  const handleFilepathSuccess = items => {
    const folders = items.map(folder => {
      const folderWithPath = folder.path_collection.entries
        .map(entry => entry.name)
        .join("/")
        .concat(`/${folder.name}`);
      return { parentName: folderWithPath, parentId: folder.id };
    });
    onChange(folders, auth.account);
  };

  function closeModal() {
    setAuth(null);
    onChange(null);
  }

  const loadBoxPicker = () => {
    const folderPicker = new global.Box.FolderPicker();

    folderPicker.addListener("choose", handleFilepathSuccess);

    folderPicker.addListener("cancel", closeModal);

    folderPicker.show("0", auth.accessToken, {
      canUpload: false,
      logoUrl: "box",
      container: ".box-element",
      ...(!multiSelect && { maxSelectable: 1 }),
    });
  };

  return (
    <>
      <Script
        src="https://cdn01.boxcdn.net/platform/elements/14.0.0/en-US/picker.js"
        type="text/javascript"
      />
      <link
        href="https://cdn01.boxcdn.net/platform/elements/14.0.0/en-US/picker.css"
        rel="stylesheet"
      />
      <Dialog
        fullWidth
        open={Boolean(auth?.accessToken)}
        style={{ height: "100%" }}
        TransitionProps={{
          onEntered: loadBoxPicker,
        }}
      >
        <DialogTitle>
          <>
            {t("selectTheLocationToSaveYourFiles")}
            <IconButton aria-label="close" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </>
        </DialogTitle>
        <DialogContent>
          {isFetchingToken() && (
            <ProgressContainer>
              <CircularProgress style={{ marginBottom: "5px" }} />
              <Typography>{t("signingInToYourCloudStorage")}</Typography>
            </ProgressContainer>
          )}
          <div className="box-element" />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BoxPicker;
