import SectionHeader from "components/SectionHeader";
import SearchFilter from "./SearchFilter";
import { useState } from "react";
import { Box, LinearProgress, Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { SearchCriteria, NotificationEvent } from "@dashboard-v3/api";
import { useTranslation } from "react-i18next";
import NotificationList from "./List";
import { ShowMoreBtn } from "components/List";
import { fetchNotifications } from "./utils";

const listLimit = 20;

const Notifications = () => {
  const [notifications, setNotifications] = useState<NotificationEvent[]>(null);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [moreToLoad, setMoreToLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("notifications");
  const { enqueueSnackbar } = useSnackbar();

  async function searchNotifications(newCriteria: SearchCriteria) {
    setLoading(true);
    try {
      setNotifications([]);
      const results = await fetchNotifications(newCriteria);
      setMoreToLoad(results.length === listLimit);
      setNotifications(results);
    } catch (error: unknown) {
      enqueueSnackbar(t("errors.fetchNotifications"), { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  async function loadMore() {
    setLoadingMore(true);
    try {
      const newSearchCriteria = {
        ...searchCriteria,
        offset: searchCriteria.offset + listLimit,
      };
      const results = await fetchNotifications(newSearchCriteria);
      setMoreToLoad(results.length === listLimit);
      setNotifications(prevNotifications => [...prevNotifications, ...results]);
      setSearchCriteria(newSearchCriteria);
    } catch (error: unknown) {
      enqueueSnackbar(t("errors.fetchNotifications"), { variant: "error" });
    } finally {
      setLoadingMore(false);
    }
  }

  const handleSubmit = filterCriteria => {
    const newCriteria = {
      ...filterCriteria,
      limit: listLimit,
      offset: 0,
    } as SearchCriteria;

    setSearchCriteria(newCriteria);
    searchNotifications(newCriteria);
  };

  return (
    <>
      <SectionHeader title="Notifications" />
      <SearchFilter onSubmit={handleSubmit} />
      {loading ? (
        <Paper style={{ padding: "12px 16px" }}>
          <LinearProgress />
        </Paper>
      ) : (
        <NotificationList notifications={notifications} />
      )}
      {moreToLoad && (
        <Box marginTop="10px">
          <ShowMoreBtn loading={loadingMore} onClick={loadMore} />
        </Box>
      )}
    </>
  );
};

export default Notifications;
