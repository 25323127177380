import { Storage, StorageAccount } from "@dashboard-v3/api";
import useSWR from "swr";
import { fetcher as fetchFromApi } from "utils/apiRequestWithErrorCode";

export default function useStorageAccountStatus(
  storage: Storage
): "OK" | "NOT_FOUND" | "OUT_OF_SYNC" {
  const { data: individualAccounts } = useSWR<StorageAccount[]>(
    isIndividual(storage) ? "/storage-accounts" : null,
    fetchFromApi,
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  if (!storage) return "OK";

  if (!isIndividual(storage)) return "OK";

  const account = individualAccounts?.find(
    ({ user, type }) => user === storage.userId && type === storage.provider
  );

  if (!account) return "NOT_FOUND";
  if (!account.synced) return "OUT_OF_SYNC";

  return "OK";
}

const isIndividual = (storage: Storage) =>
  storage?.authentication === "INDIVIDUAL" || storage?.provider === "DROPBOX";
