import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion } from "components/AccordionForm";
import ActionFilters from "./ActionFilters";
import { filters } from "../filters";
import defaultDates from "./defaultDates";
import { Filter } from "@dashboard-v3/api";

type Params = {
  onSearch: (criteria: Filter) => Promise<void>;
};

export default function FiltersPanel({ onSearch }: Params) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [fromTime, setFromTime] = useState("00:00");
  const [toTime, setToTime] = useState("23:59");
  const [actionFilters, setActionFilters] = useState([]);

  const [fromDefaultDate, toDefaultDate] = defaultDates();

  const [toDate, setToDate] = useState(toDefaultDate);
  const [fromDate, setFromDate] = useState(fromDefaultDate);
  const { t } = useTranslation("userActivity");

  async function emitSearch() {
    const actions = Object.keys(actionFilters).reduce((selected, key) => {
      return [...selected, ...actionFilters[key]];
    }, []);

    const criteria = {
      actions,
      dateFrom: new Date(`${fromDate} ${fromTime}`).getTime(),
      dateTo: new Date(`${toDate} ${toTime}`).getTime(),
      user,
    };

    await onSearch(criteria);
    setOpen(false);
  }

  function filterCount() {
    return Object.keys(actionFilters).reduce(
      (sum, key) => sum + actionFilters[key].length,
      0
    );
  }

  const changeActionFilters = (type, newActiveActionFilters) => {
    setActionFilters({
      ...actionFilters,
      [type]: newActiveActionFilters,
    });
  };

  return (
    <Accordion
      data-testid="activity_search"
      expanded={open}
      onChange={(_, expanded) => setOpen(expanded)}
    >
      <AccordionSummary
        data-testid="filter-panel__filter-summary"
        expandIcon={<ExpandMoreIcon />}
        style={{ justifyContent: "space-between" }}
      >
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography>{t("filters")}</Typography>
          <Typography>
            {filterCount()} {t("filtersSelected")}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              data-testid="userInput"
              inputProps={{
                "data-testid": "filter-panel__user-textfield",
              }}
              label={t("common:user")}
              onChange={e => setUser(e.target.value)}
              size="small"
              value={user}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={4} lg={3}>
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "filter-panel__from-date-textfield",
              }}
              label={t("common:fromDate")}
              onChange={e => setFromDate(e.target.value)}
              required
              size="small"
              type="date"
              value={fromDate}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "filter-panel__from-time-textfield",
              }}
              label={t("common:time")}
              onChange={e => setFromTime(e.target.value)}
              required
              size="small"
              type="time"
              value={fromTime}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "filter-panel__to-date-textfield",
              }}
              label={t("common:toDate")}
              onChange={e => setToDate(e.target.value)}
              required
              size="small"
              type="date"
              value={toDate}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <TextField
              fullWidth
              inputProps={{
                "data-testid": "filter-panel__to-time-textfield",
              }}
              label={t("common:time")}
              onChange={e => setToTime(e.target.value)}
              required
              size="small"
              type="time"
              value={toTime}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {filters.map(filterType => (
            <Grid item xs={4} lg={3} key={filterType}>
              <ActionFilters onChange={changeActionFilters} type={filterType} />
            </Grid>
          ))}
        </Grid>
        <div style={{ marginTop: "15px" }}>
          <Button
            color="primary"
            data-testid="searchBtn"
            disableElevation
            type="submit"
            variant="contained"
            onClick={emitSearch}
          >
            {t("search")}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
