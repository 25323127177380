/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  capitalize,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { TemplatePolicy, TemplatePolicyReqBody } from "@dashboard-v3/api";
import { FormProps, FormState } from "../types";
import { Button } from "components/Forms/StyledComponents";
import PageLoading from "components/PageLoading";
import SelectField from "components/Forms/SelectField";
import { OptionGroup, StyledPaper, VerticalLine } from "components/Styled";
import useFormState from "utils/useFormState";
import useTemplateDefinitions from "utils/useTemplateDefinitions";
import apiRequest from "utils/apiRequestWithErrorCode";
import Predicate from "./Predicates";
import DefaultDefinition from "./DefaultDefinition";
import PillButton from "components/Forms/PillButton";
import { StyledTitle } from "../Styled";
import { AllowedStorage, allowedStorages } from "utils/canUseTemplates";
import useVariables from "utils/useVariables";

const initialValues = (): FormState => ({
  name: "",
  description: "",
  cloudStorage: "",
  predicates: [
    { definitionId: "", variableIdList: [], match: true, firstMatch: false },
  ],
  defaultDefinition: "",
});

const Form = ({ loading, setLoading }: FormProps) => {
  const { t } = useTranslation("templatePolicies");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { state, pathname } = useLocation();
  const { id: paramId } = useParams();
  const { userVariables, loading: varsLoading } = useVariables();
  const {
    definitions,
    fetchDefinitions,
    getByStorageType,
    loading: defsLoader,
  } = useTemplateDefinitions();
  const { formState, updateValues, setValues } = useFormState<FormState>(
    initialValues()
  );
  const isEdit: boolean = pathname.includes("/edit");

  useEffect(() => {
    fetchDefinitions();
  }, []);

  useEffect(() => {
    const fetchPolicyById = async (policyId: string) => {
      setLoading(true);
      try {
        const {
          id,
          createdAt,
          updatedAt,
          organizationId,
          storageType,
          ...rest
        } = await apiRequest<TemplatePolicy>(
          "GET",
          `/template-policies/${policyId}`
        );
        setValues({ cloudStorage: storageType, ...rest });
        setLoading(false);
      } catch (e: unknown) {
        setLoading(false);
        enqueueSnackbar(t("common:errors.fetchError"), {
          variant: "error",
        });
        history.push("/template-policies");
      }
    };
    const handleEditData = async () => {
      if (state) {
        const { selectedPolicy, policyId } = state;
        return policyId !== paramId
          ? await fetchPolicyById(paramId)
          : setValues(selectedPolicy);
      }
      await fetchPolicyById(paramId);
    };

    if (isEdit && definitions && userVariables) {
      handleEditData();
    }
  }, [definitions, userVariables]);

  const addPredicate = () =>
    setValues({
      predicates: [
        ...formState.predicates,
        {
          definitionId: "",
          variableIdList: [],
          match: true,
          firstMatch: false,
        },
      ],
    });

  const savePolicy = async (body: TemplatePolicyReqBody, id?: string) => {
    setLoading(true);
    try {
      const method: "POST" | "PUT" = id ? "PUT" : "POST";
      const path: string = id
        ? `/template-policies/${id}`
        : "/template-policies";
      const res = await apiRequest<TemplatePolicyReqBody>(method, path, body);
      enqueueSnackbar(t("saveSuccess", { name: res.name }), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("errors.saveError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
      history.push("/template-policies");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const templatePolicy: TemplatePolicyReqBody = {
      name: formState.name,
      description: formState.description,
      storageType: formState.cloudStorage as AllowedStorage,
      predicates: formState.predicates,
      defaultDefinition: formState.defaultDefinition,
    };
    if (state && isEdit) {
      const { policyId } = state;
      savePolicy(templatePolicy, policyId);
    } else {
      savePolicy(templatePolicy);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PageLoading loading={loading || varsLoading || defsLoader} />
      <Grid container spacing={5}>
        <Grid item lg={8} xs={12}>
          <StyledPaper>
            <Typography gutterBottom variant="h6">
              {t("form.title")}
            </Typography>
            <OptionGroup>
              <TextField
                name="name"
                label="Template policy name"
                value={formState.name}
                onChange={updateValues}
                variant="outlined"
                fullWidth
                required
              />
            </OptionGroup>
            <OptionGroup>
              <TextField
                name="description"
                label={t("form.description.label")}
                value={formState.description}
                onChange={updateValues}
                variant="outlined"
                fullWidth
              />
            </OptionGroup>
            <OptionGroup>
              <StyledTitle>{t("form.cloudStorage.title")}</StyledTitle>
              <SelectField
                name="cloudStorage"
                label={t("form.cloudStorage.label")}
                value={formState.cloudStorage}
                onChange={updateValues}
                options={[...allowedStorages]}
                required
                renderOption={(val, i) => (
                  <MenuItem key={i} value={val}>
                    {capitalize(val.toLowerCase())}
                  </MenuItem>
                )}
              />
            </OptionGroup>
          </StyledPaper>
          {formState.predicates.map((_, i) => (
            <Predicate
              key={i}
              predicateIndex={i}
              formState={formState}
              setValues={setValues}
              variables={userVariables}
              definitions={getByStorageType(formState.cloudStorage)}
            />
          ))}
          <PillButton
            wording={t("form.btn.predicates")}
            onClick={addPredicate}
            lineSeparator="full"
          />
          <DefaultDefinition
            formState={formState}
            definitions={getByStorageType(formState.cloudStorage)}
            onDefault={updateValues}
          />
          <VerticalLine size="sm" />
          <StyledPaper style={{ padding: "25px 24px" }}>
            <Button
              type="submit"
              size="large"
              fullWidth={true}
              wording={t("form.btn.submit")}
            />
          </StyledPaper>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
