import PropTypes from "prop-types";
import { Container, Typography, Link } from "@material-ui/core";
import styled, { createGlobalStyle } from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageSelector from "components/LanguageSelector";

const LandingStyle = createGlobalStyle`
  body {
    background: linear-gradient(45deg, #324a75, #009ed2, #3393cb, #4ea0df);
    background-size: 400% 100%;
    animation: gradient 12s ease infinite;
    animation-direction: alternate;
    height: 100vh;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    position: relative;
  }

  body::before {
    content: "";
    display: block;
    position: fixed;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    bottom: -50%;
    left: 25%;
    box-shadow: 0 50px 400px rgb(13 89 129);
    transform: rotate(-180deg);
  }

  body::after {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    top: -50%;
    left: 25%;
    box-shadow: 0 150px 300px rgb(112 228 233);
    animation: animate_1 10s infinite;
  }

  @keyframes animate_1 {
    25% { box-shadow: 0 150px 300px rgb(136, 238, 245); }
    50% { box-shadow: 0 145px 300px rgb(137, 212, 240); }
    75% { box-shadow: 0 155px 300px rgb(113, 189, 250); }
    100% { box-shadow: 0 150px 300px rgb(112, 228, 233); }
  }

  @keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BottomLinks = styled(Typography)`
  color: white;
  display: block;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 25px;

  a + a {
    margin-left: 15px;
  }
`;

const Logo = styled.div`
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 25px;
  padding-top: 25px;
`;

const Landing = ({ children }) => {
  const { t } = useTranslation("components");

  return (
    <>
      <LandingStyle />
      <StyledContainer>
        <div>
          <Logo>mxHERO</Logo>
          {children}
          <BottomLinks variant="caption">
            <Link
              color="inherit"
              href="https://status.mxhero.com/"
              target="_blank"
            >
              {t("landing.serviceStatus")}
            </Link>
            <Link
              color="inherit"
              href="https://www.mxhero.com/tos"
              target="_blank"
            >
              {t("landing.termsOfService")}
            </Link>
            <Link
              color="inherit"
              href="https://www.mxhero.com/privacy"
              target="_blank"
            >
              {t("landing.privacyPolicy")}
            </Link>
            <LanguageSelector style={{ marginLeft: "15px" }} />
          </BottomLinks>
        </div>
      </StyledContainer>
    </>
  );
};

Landing.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Landing;
