import { Accordion, Fab, MenuItem, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";

type StyledProps = {
  size?: string;
};

export const AdvancedOptionsContainer = styled(Accordion)`
  border-top: 1px solid #d6dbe0;
  box-shadow: none;
  :before {
    opacity: 0;
  }
`;

export const StyledPaper = styled(Paper)`
  padding: 24px;
`;

export const VerticalLine = styled.div<{ size: "sm" | "md" | "lg" }>`
  height: ${({ size }: StyledProps) => (size === "sm" ? "50px" : "100px")};
  width: 50%;
  margin: 5px 0;
  border-right: 2px dashed #8f8f8f;
`;

export const OptionGroup = styled.div`
  margin-bottom: 24px;
  :last-of-type {
    margin-bottom: 0;
  }
`;

export const StepHeader = styled.header`
  border-bottom: 1px solid #d6dbe0;
  padding: 14px 24px;
`;

export const StyledFab = styled(Fab)`
  background-color: white;
  :hover {
    background-color: #f1f1f1;
  }
`;

export const OptionTitle = styled(props => (
  <Typography variant="subtitle1" color="textPrimary" {...props} />
))`
  font-weight: 500;
`;

const MenuItemWithDescription = styled(MenuItem)`
  flex-direction: column;
  align-items: flex-start;
`;

export const Description = styled(Typography)`
  font-size: 0.75rem;
  color: grey;
`;

export function menuItemWithDescription(id, name, description) {
  return (
    <MenuItemWithDescription key={id} value={id}>
      {name}
      {description && (
        <Description display="block" variant="caption">
          {description}
        </Description>
      )}
    </MenuItemWithDescription>
  );
}

export const Label = ({ wording, gutterBottom = true, ...rest }) => {
  return (
    <Typography
      gutterBottom={gutterBottom}
      variant="subtitle1"
      color="textPrimary"
      style={{ fontWeight: "500" }}
      {...rest}
    >
      {wording}
    </Typography>
  );
};
