import React from "react";
import { Typography } from "@material-ui/core";
import { StyledFab, VerticalLine } from "components/Styled";

type SeparatorPosition = "top" | "bottom" | "full";

interface Props {
  wording: string;
  onClick: React.MouseEventHandler;
  lineSeparator?: SeparatorPosition;
}

const PillButton = ({ wording, onClick, lineSeparator }: Props) => {
  const showSeparator = (position: SeparatorPosition) => {
    if (position === "top") {
      return lineSeparator === "top" || lineSeparator === "full";
    }
    if (position === "bottom") {
      return lineSeparator === "bottom" || lineSeparator === "full";
    }
    return false;
  };

  return (
    <div style={{ textAlign: "center" }}>
      {showSeparator("top") && <VerticalLine size="sm" />}
      <StyledFab size="medium" variant="extended" onClick={onClick}>
        <Typography
          style={{ lineHeight: "0" }}
          color="primary"
          variant="button"
        >
          {wording}
        </Typography>
      </StyledFab>
      {showSeparator("bottom") && <VerticalLine size="sm" />}
    </div>
  );
};

export default PillButton;
