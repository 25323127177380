/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { ActionState } from "./types";
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import {
  AdvancedOptionsContainer,
  Label,
  OptionGroup,
} from "components/Styled";
import { StyledDetails, StyledSummary } from "./Styled";
import { isAttachment, isMoveEmail, mapLinkFormat } from "./utils";
import { RuleUrlParams } from "../CreateOrEdit";
import { useParams } from "react-router-dom";
import { RuleType } from "../types";

export interface OptionsProps {
  values: ActionState;
  setValues: (updates: Partial<ActionState>) => void;
  ruleType: RuleType;
  disabled: boolean;
}

export default function AdvancedOptions({
  ruleType,
  values,
  setValues,
  disabled,
}: OptionsProps) {
  const { t } = useTranslation("rules");

  const { action }: RuleUrlParams = useParams();

  const disableAdvancedOptions = action === "edit";

  const handleLinkFormat = event => {
    const { value } = event.target;
    let linksFormat = [value];

    if (value === "BOTH") {
      linksFormat = ["PDF", "LINK"];
    }

    setValues({ linksFormat });
  };

  const handleTrash = event => {
    const { value } = event.target;
    setValues({ trash: value === "true" });
  };

  if (disabled) return <></>;

  return (
    <AdvancedOptionsContainer>
      <StyledSummary>
        <Typography>{t("advancedOptions")}</Typography>
      </StyledSummary>
      <StyledDetails>
        {isAttachment(ruleType) && (
          <OptionGroup>
            <Label wording={t("selectWhereToAddTheAttachmentLinks")} />
            <RadioGroup
              name="linkFormat"
              value={mapLinkFormat(values.linksFormat)}
              onChange={handleLinkFormat}
            >
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                label={t("emailsBody")}
                value="LINK"
              />
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                label={t("attachmentPdf")}
                value="PDF"
              />
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                label={t("common:both")}
                value="BOTH"
              />
            </RadioGroup>
          </OptionGroup>
        )}
        {isMoveEmail(ruleType) && (
          <OptionGroup>
            <Label wording={t("ruleAction.advanced.moveEmails.title")} />
            <RadioGroup
              name="trash"
              value={values.trash?.toString() || "true"}
              onChange={handleTrash}
              aria-disabled={disableAdvancedOptions}
            >
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                label={t("ruleAction.advanced.moveEmails.trash")}
                value="true"
                disabled={disableAdvancedOptions}
              />
              <FormControlLabel
                control={<Radio color="primary" size="small" />}
                label={t("ruleAction.advanced.moveEmails.delete")}
                value="false"
                disabled={disableAdvancedOptions}
              />
            </RadioGroup>
          </OptionGroup>
        )}
      </StyledDetails>
    </AdvancedOptionsContainer>
  );
}
