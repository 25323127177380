import storageProviders from "utils/storageProviders";
import { StorageAccessType, StorageProvider } from "@dashboard-v3/api";

export function canExpireLinks(
  access: StorageAccessType,
  storageProvider: StorageProvider
) {
  if (access === "NONE") return false;

  switch (storageProvider) {
    case "BOX":
    case "DROPBOX":
    case "EGNYTE":
    case "EGNYTE_GOV":
    case "MXHERO_STORAGE":
      return true;

    case "GOOGLEDRIVE":
      return access === "COLLABORATORS";

    case "ONEDRIVE":
      return ["OPEN", "ORGANIZATION"].includes(access);

    default:
      return false;
  }
}

export function canShowAccessOption(
  access: StorageAccessType,
  provider: StorageProvider
) {
  if (isEgnyte(provider)) {
    if (access === "OPEN") return true;
    if (access === "ORGANIZATION") return true;
    return false;
  }

  if (isMxheroStorage(provider)) {
    if (access === "OPEN") return true;
    if (access === "AUTHENTICATED") return true;
    if (access === "ORGANIZATION") return true;
    return false;
  }

  if (!isMxheroStorage(provider) && access === "AUTHENTICATED") {
    return false;
  }

  return true;
}

export function isEgnyte(provider: StorageProvider) {
  return provider === "EGNYTE" || provider === "EGNYTE_GOV";
}

export function isMxheroStorage(provider: StorageProvider) {
  return provider === "MXHERO_STORAGE";
}

export function canProtectWithPassword(
  access: StorageAccessType,
  storageProvider: StorageProvider
) {
  if (access === "NONE") return false;
  if (storageProvider === "DROPBOX") return true;
  if (storageProvider === "BOX" && access === "OPEN") return true;
  if (storageProvider === "ONEDRIVE" && access === "OPEN") return true;
  if (storageProvider === "MXHERO_STORAGE" && access === "OPEN") return true;
  return false;
}

export function canPreventFileDownload(
  access: StorageAccessType,
  storageProvider: StorageProvider
) {
  if (access === "NONE") return false;
  if (storageProvider === "EGNYTE" || storageProvider === "EGNYTE_GOV")
    return true;
  if (storageProvider === "BOX" && access !== "COLLABORATORS") return true;
  if (storageProvider === "DROPBOX") return true;

  return false;
}

export function canRemoveDownloadLink(
  access: StorageAccessType,
  storageProvider: StorageProvider,
  canDownload: boolean
) {
  return canPreventFileDownload(access, storageProvider) && !canDownload;
}

export const storageAccessTypes: StorageAccessType[] = [
  "OPEN", // Accessible to anyone (Public links)
  "COLLABORATORS", // Accessible to the recipients of the email
  "AUTHENTICATED", // Accessible to individual user with authentication (External)
  "ORGANIZATION", // Accessible to people in the organization (Company)
  "NONE", // Accessible to the folder collaborators (no shared link)
];

export const providerOptions: StorageProvider[] = [
  ...storageProviders,
  "MXHERO_STORAGE",
];
