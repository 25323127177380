import { TFunction } from "i18next";
import { AndCondition, VariableReqBody } from "@dashboard-v3/api";
import { DashboardVariable } from "types";
import { ActionType, VariableType } from "./types";

export const getAndConditions = (
  variable: VariableReqBody
): AndCondition["children"] => {
  const orConditions = variable.predicate.children;
  return orConditions.map(orCondition => orCondition.children).flat();
};

export const getOrConditionsToValue = (variable: VariableReqBody) => {
  const { children: orCondition } = variable.predicate;
  return orCondition.map(({ toValue }) => toValue);
};

export const getEmailFieldLabel = (source: string, t: TFunction) => {
  const translation = t(`variables.${source}.name`, {
    ns: "platformVariables",
  });

  return translation.replace(/[{}]/g, "");
};

export const parseTestSources = (val: string, vars: DashboardVariable[]) => {
  return vars
    .filter(v => val.includes(v.id))
    .map(v => ({
      id: v.id.replaceAll(/[${ }]/g, ""),
      type: v.type,
      values: [],
    }));
};

export const getTestSourceValue = (values: string[]) => {
  if (!values.length) return "";
  if (values.length > 1) {
    return values.map(v => v.replace(/\r\n\s+/g, " ")).join("\n");
  }
  return values[0];
};

export const removeVariablesInToValue = (
  value: string,
  index: number,
  multiple = false
) => {
  if (!multiple) {
    const optionRegex = new RegExp(
      `{children-${index},\\s(?:group-\\d+|value)}`,
      "g"
    );
    return value.replaceAll(optionRegex, "");
  }

  const optionRegex = /{children-\d,\s(?:group-\d+|value)}/g;
  const groupedOptionRegex = /({children-\d,\s(?:group-\d+|value)})/g;
  const digitRegex = /\d/g;
  const parts = value.split(groupedOptionRegex).filter(part => !!part);

  return parts
    .reduce((acc, part) => {
      if (!part) return acc;
      if (optionRegex.test(part)) {
        const optionIdx = parseInt(part.match(digitRegex)[0]);
        return optionIdx >= index + 1 ? [...acc, ""] : [...acc, part];
      }
      return [...acc, part];
    }, [])
    .join("");
};

export const isRegexType = (value: VariableType) => value === "REGEX";
export const isAIType = (value: VariableType) => value === "AI_QUESTION";
export const isMapCondition = (value: VariableType) =>
  value === "EQUALS_TO_DATASET_KEY" || value === "CONTAINS_DATASET_KEY";

export const isMapAction = (value: ActionType) => value === "map";

export const isConvertAction = (value: ActionType) => value === "convert";
