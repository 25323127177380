import { useState } from "react";
import { Popover } from "@material-ui/core";
import { FixedValuesList } from "pages/TemplateDefinitions/Styled";
import { Box } from "@material-ui/core";

export default function PopupHelper({ children, label }) {
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [helperOpen, setHelperOpen] = useState(false);

  const handleHelperOpen = e => {
    setPopoverAnchor(e.currentTarget);
    setHelperOpen(prev => !prev);
  };

  return (
    <Box
      onClick={handleHelperOpen}
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
    >
      {label}
      <Popover
        open={helperOpen}
        anchorEl={popoverAnchor}
        anchorOrigin={popverOrigin.anchor}
        transformOrigin={popverOrigin.transform}
      >
        <FixedValuesList>{children}</FixedValuesList>
      </Popover>
    </Box>
  );
}

const popverOrigin = {
  anchor: {
    vertical: "top",
    horizontal: "left",
  },
  transform: {
    vertical: "top",
    horizontal: "left",
  },
} as const;
