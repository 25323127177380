import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useFormState from "utils/useFormState";
import useValidation from "utils/useValidation";
import { isBlank } from "utils/string";
import apiRequest from "utils/apiRequestWithErrorCode";
import { StyledForm } from "components/List/StyledComponents";
import SwitchField from "./Fields/SwitchField";
import {
  SecurityDefinition,
  SecurityDefinitionRequest,
  // TODO: We cannot import ts files outside of the src/ it's a limitation of CRA
  StorageAccessType,
  StorageProvider,
} from "@dashboard-v3/api";
import { useSnackbar } from "notistack";
import { getProviderLabel } from "utils/storageProviders";
import {
  canExpireLinks,
  canPreventFileDownload,
  canProtectWithPassword,
  canRemoveDownloadLink,
  canShowAccessOption,
  isMxheroStorage,
  providerOptions,
  storageAccessTypes,
} from "./utils";

type Form = {
  access: StorageAccessType;
  canDownload: boolean;
  removeDownloadLink: boolean;
  individualPassword: boolean;
  name: string;
  provider: StorageProvider;
  expiresInDays?: string;
};

const initialValues: Form = {
  access: "OPEN",
  canDownload: true,
  removeDownloadLink: false,
  individualPassword: false,
  name: "",
  provider: "" as StorageProvider,
  expiresInDays: "",
};

interface FormProps {
  refreshList: () => Promise<void>;
  setLoading: Dispatch<SetStateAction<"ALL" | "MORE" | "ITEM">>;
  securityDefinition?: SecurityDefinition;
  onClose: () => void;
}

export default function SecurityDefinitionForm({
  refreshList,
  setLoading,
  onClose: closeForm,
  securityDefinition,
}: FormProps) {
  const { t } = useTranslation("securityDefinitions");
  const { enqueueSnackbar } = useSnackbar();
  const [enableExpiration, setEnableExpiration] = useState(false);
  const { formState, reset, setValues, updateValues } =
    useFormState<Form>(initialValues);
  const { errors, validate, clearErrors } = useValidation(formState);

  global.form = formState;

  useEffect(() => {
    if (securityDefinition) {
      const {
        updatedAt,
        createdAt,
        organizationId,
        expiresInDays,
        allowedStorages,
        removeDownloadLink,
        ...definition
      } = securityDefinition;

      const values: Form = {
        ...definition,
        provider: allowedStorages[0],
        ...(removeDownloadLink && { removeDownloadLink }),
      };

      if (expiresInDays) {
        values.expiresInDays = expiresInDays.toString();
      }

      setValues(values);
      setEnableExpiration(
        securityDefinition.expiresInDays > 0 ||
          isMxheroStorage(securityDefinition.allowedStorages[0])
      );
    }

    return () => {
      clearErrors();
      reset();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleEnableExpiration() {
    if (isMxheroStorage(formState.provider) && enableExpiration) return;
    const newState = !enableExpiration;
    setEnableExpiration(newState);

    reset({
      expiresInDays:
        !canExpireLinks(formState.access, formState.provider) || !newState,
    });
  }

  function updateProvider(event) {
    const newProvider = event.target.value;
    updateValues(event);
    resetForm(formState.access, newProvider, formState.canDownload);

    if (!isMxheroStorage(formState.provider) && isMxheroStorage(newProvider)) {
      setValues(prev => {
        if (!prev.expiresInDays) return { ...prev, expiresInDays: "365" };
        return prev;
      });
      return setEnableExpiration(true);
    }

    if (!canExpireLinks(formState.access, formState.provider)) {
      setEnableExpiration(false);
    }
  }

  function updateAccess(event) {
    const newAccess = event.target.value;
    resetForm(newAccess, formState.provider, formState.canDownload);
    updateValues(event);
  }

  function toggleCanDownload(event) {
    const { checked } = event.target;
    const removeDownloadLink = formState.removeDownloadLink
      ? false
      : formState.removeDownloadLink;

    setValues({ canDownload: !checked, removeDownloadLink });
  }

  function resetForm(
    access: StorageAccessType,
    storageProvider: StorageProvider,
    canDownload: boolean
  ) {
    reset({
      individualPassword: !canProtectWithPassword(access, storageProvider),
      canDownload: !canPreventFileDownload(access, storageProvider),
      expiresInDays: !canExpireLinks(access, storageProvider),
      access:
        !canShowAccessOption(access, storageProvider) ||
        isMxheroStorage(storageProvider),
      removeDownloadLink: canRemoveDownloadLink(
        access,
        storageProvider,
        canDownload
      ),
    });
  }

  const validations = {
    name(value) {
      if (isBlank(value)) {
        return t("form.fields.name.errors.empty");
      }
      return null;
    },
    provider(value) {
      if (!value) {
        return t("form.fields.provider.errors.empty");
      }
      return null;
    },
    expiresInDays(value) {
      if (isBlank(value) && enableExpiration) {
        return t("form.fields.expiresInDays.errors.empty");
      }
      if (parseInt(value, 10) <= 0) {
        return t("form.fields.expiresInDays.errors.negative");
      }
      if (isMxheroStorage(formState.provider) && value > 365) {
        return t("form.fields.expiresInDays.errors.mxHeroStorageLimit");
      }
      return null;
    },
  };

  const validateOnBlur = e => {
    const { name } = e.target;
    return validate(name, validations[name]);
  };

  const validateForm = () => {
    return ["name", "provider", "expiresInDays"]
      .map(name => validate(name, validations[name]))
      .every(val => val);
  };

  const saveDefinition = async (
    body: SecurityDefinitionRequest,
    id?: string
  ) => {
    const path = id ? `/security-definitions/${id}` : "/security-definitions";
    const method = id ? "PUT" : "POST";
    const definition = id ? { ...body, id } : body;
    try {
      setLoading("ALL");
      await apiRequest<SecurityDefinition>(method, path, definition);
      await refreshList();
    } catch (e) {
      const errorKey = id ? "createError" : "updateError";
      enqueueSnackbar(t(`errors.definitionsRequests.${errorKey}`), {
        variant: "error",
      });
    } finally {
      setLoading(null);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      const { expiresInDays, provider, ...form } = formState;

      const definition: SecurityDefinitionRequest = {
        ...form,
        allowedStorages: [provider],
      };

      if (expiresInDays) {
        const maybeNumber = parseInt(expiresInDays, 10);
        if (!isNaN(maybeNumber)) {
          definition.expiresInDays = maybeNumber;
        }
      }
      saveDefinition(definition, securityDefinition?.id);
      closeForm();
    }
  };

  return (
    <Dialog open onClose={closeForm} fullWidth>
      <DialogTitle>{t("form.title")}</DialogTitle>
      <DialogContent aria-label="securityDefinitionForm">
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            name="name"
            label={t("form.fields.name.label")}
            variant="outlined"
            fullWidth
            value={formState.name}
            onChange={updateValues}
            error={!!errors.name}
            helperText={errors.name}
            onBlur={validateOnBlur}
          />
          <FormLabel>
            <Typography variant="subtitle2" color="textSecondary">
              {t("form.fields.storageType.label")}
            </Typography>
          </FormLabel>
          <FormControl variant="outlined" fullWidth>
            <Select
              data-testid="securityDef_selectProvider"
              aria-label="selectProvider"
              name="provider"
              value={formState.provider}
              error={!!errors.provider}
              onChange={updateProvider}
              onBlur={validateOnBlur}
            >
              <MenuItem disabled value="">
                {t("form.fields.storageType.placeholder")}
              </MenuItem>
              {providerOptions.map(val => (
                <MenuItem key={val} value={val}>
                  {getProviderLabel(val)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!errors.provider}>
              {errors.provider}
            </FormHelperText>
          </FormControl>

          <FormLabel>
            <Typography variant="subtitle2" color="textSecondary">
              {t("form.fields.access.titile")}
            </Typography>
            {!isMxheroStorage(formState.provider) && (
              <Typography variant="caption" color="textSecondary">
                {t("form.fields.access.label")}
              </Typography>
            )}
          </FormLabel>
          <RadioGroup
            name="access"
            value={formState.access}
            onChange={updateAccess}
          >
            {storageAccessTypes.map(
              item =>
                canShowAccessOption(item, formState.provider) && (
                  <FormControlLabel
                    key={item}
                    label={t(`access.${item}`)}
                    control={
                      <Radio
                        size="small"
                        color="primary"
                        value={item}
                        inputProps={{
                          "aria-label": getAriaLabel(item),
                        }}
                      />
                    }
                  />
                )
            )}
          </RadioGroup>
          {canExpireLinks(formState.access, formState.provider) && (
            <>
              <SwitchField
                color="primary"
                inputProps={{
                  "aria-label":
                    "Enable automatic expiration of Mail2Cloud links",
                }}
                label={t("form.fields.expiresInDays.label.switch")}
                description={t("form.fields.expiresInDays.description")}
                checked={enableExpiration}
                onChange={toggleEnableExpiration}
              />
              {enableExpiration && (
                <TextField
                  name="expiresInDays"
                  type="number"
                  label={t("form.fields.expiresInDays.label.input")}
                  variant="outlined"
                  value={formState.expiresInDays}
                  onChange={updateValues}
                  error={!!errors.expiresInDays}
                  helperText={t(errors.expiresInDays)}
                  onBlur={validateOnBlur}
                />
              )}
            </>
          )}
          {canPreventFileDownload(formState.access, formState.provider) && (
            <SwitchField
              inputProps={{
                "aria-label": "Prevent file downloads",
              }}
              name="canDownload"
              label={t("form.fields.canDownload.label")} // Prevent file downloads
              description={t("form.fields.canDownload.description")}
              color="primary"
              checked={!formState.canDownload}
              onChange={toggleCanDownload}
            />
          )}

          <SwitchField
            name="removeDownloadLink"
            label={t("form.fields.removeDownloadLink.label")}
            description={t("form.fields.removeDownloadLink.description")}
            disabled={!formState.canDownload}
            color="primary"
            checked={formState.removeDownloadLink}
            onChange={updateValues}
          />

          {canProtectWithPassword(formState.access, formState.provider) && (
            <SwitchField
              inputProps={{
                "aria-label": "Password Protected",
              }}
              name="individualPassword"
              label={t("form.fields.individualPassword.label")}
              description={t("form.fields.individualPassword.description")}
              color="primary"
              checked={formState.individualPassword}
              onChange={updateValues}
            />
          )}
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              disableElevation
              onClick={closeForm}
            >
              {t("common:cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              {!securityDefinition ? t("common:create") : t("common:save")}
            </Button>
          </DialogActions>
        </StyledForm>
      </DialogContent>
    </Dialog>
  );
}

function getAriaLabel(access: StorageAccessType) {
  switch (access) {
    case "OPEN":
      return "Accessible to anyone";
    case "COLLABORATORS":
      return "Accessible to the recipients of the email";
    case "ORGANIZATION":
      return "Accessible to people in the organization";
    case "NONE":
      return "Accessible to the folder collaborators";
  }
}
