import SwitchField from "components/Forms/SwitchField";
import { OptionGroup } from "components/Styled";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { useTranslation } from "react-i18next";
import { AccountManagement, getStorage } from "../utils";
import { State } from "pages/Rules/CreateOrEdit/context/types";

export default function UseStoreByUserAccount() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const storage = getStorage(state.rule);

  return (
    <OptionGroup>
      <SwitchField
        label={t("cloudStorage.storeByUser.label")}
        description={t("cloudStorage.storeByUser.description")}
        value={storage.allManagedUserIds}
        onChange={(_, checked) => {
          dispatch({
            type: "updateStep",
            payload: {
              step: "cloudStorage",
              changes: {
                allManagedUserIds: checked,
              },
            },
          });
        }}
      />
    </OptionGroup>
  );
}

export function canUseStoreByUserAccount(state: State): boolean {
  if (state.rule.actionType !== "COPY_EMAILS") return false;
  if (state.rule.targetType !== "EMAIL_FLOW") return false;

  const storage = getStorage(state.rule);
  if (storage.provider === "DROPBOX") return false;

  return state.storageManagement === AccountManagement.AUTO;
}
