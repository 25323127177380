import { Button, SvgIcon, Typography } from "@material-ui/core";
import styled from "styled-components";
import type { RuleType } from "pages/Rules/types";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)`
  display: block;
  min-height: 143px;
`;

const ButtonTitle = styled(Typography)`
  font-weight: 500;
  padding: 0 2px;
`;

type ActionButtonProps = {
  action: RuleType;
  onClick: (newAction: RuleType) => void;
  active: boolean;
  icon: typeof SvgIcon;
};

const ActionButton = ({
  action,
  onClick,
  active,
  icon: Icon,
}: ActionButtonProps) => {
  const { t } = useTranslation("rules");
  return (
    <>
      <StyledButton
        color={active ? "primary" : "default"}
        disableElevation
        fullWidth
        onClick={() => onClick(action)}
        size="large"
        style={{ paddingTop: "10px" }}
        variant={active ? "contained" : "outlined"}
      >
        <Icon fontSize="large" />
        <ButtonTitle gutterBottom>{t(`actions.${action}.title`)}</ButtonTitle>
      </StyledButton>
    </>
  );
};

export default ActionButton;
