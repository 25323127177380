import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Button,
  Divider,
  Link,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Paper,
  Switch,
  Typography,
  Box,
} from "@material-ui/core";
import SectionHeader from "components/SectionHeader";
import PageLoading from "components/PageLoading";
import AuthenticationModal from "components/AuthenticationModal";
import { shouldAuthenticate } from "utils/authentication";
import SamlBtn from "./SamlBtn";
import { fetchSecuritySettings, saveSecuritySettings } from "./api";
import { LoginOptions } from "@dashboard-v3/api";

export default function OrganizationSecurity() {
  const { t } = useTranslation("security");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [authIsOpen, setAuthIsOpen] = useState(false);
  const [previousSamlConfig, setPreviousSamlConfig] = useState();
  const [settings, setSettings] = useState({
    google: false,
    office: false,
    password: false,
    saml: null,
    twoFactorAuthentication: false,
  });

  useEffect(() => {
    async function getSettings() {
      try {
        const result = await fetchSecuritySettings();
        let saml = null;

        if ("SAML" in result) {
          saml = {
            entityId: result.SAML.entityId,
            ssoUrl: result.SAML.ssoUrl,
          };
        }

        setSettings({
          google: "GOOGLE" in result,
          office: "OFFICE" in result,
          password: "PASSWORD" in result,
          saml,
          twoFactorAuthentication: result.twoFactorAuthEnabled ?? false,
        });

        setPreviousSamlConfig(saml);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("error.fetchSecuritySettings"), {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }

    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSamlPreviousLoaded = () => !!previousSamlConfig;

  function isSaveDisabled() {
    // eslint-disable-next-line no-use-before-define
    if (samlError()) return true;

    return (
      !settings.google &&
      !settings.office &&
      !settings.password &&
      !settings.saml
    );
  }

  const handleToggle = key => () => {
    setSettings(previous => {
      if (key === "saml") {
        const wasPreviousSelected = !!previous.saml;

        return {
          ...previous,
          saml: wasPreviousSelected ? null : previousSamlConfig || {},
        };
      }

      return { ...previous, [key]: !previous[key] };
    });
  };

  const handleSamlSettings = samlSettings => {
    setSettings(previous => {
      return { ...previous, saml: samlSettings };
    });
  };

  async function saveSettings() {
    setLoading(true);

    const changes: LoginOptions = {};

    if (settings.google) {
      changes.GOOGLE = {};
    }

    if (settings.office) {
      changes.OFFICE = {};
    }

    if (settings.password) {
      changes.PASSWORD = {};
    }

    if (settings.saml) {
      changes.SAML = settings.saml;
    }

    changes.twoFactorAuthEnabled = settings.twoFactorAuthentication;

    try {
      await saveSecuritySettings(changes);
      enqueueSnackbar("Saved changes", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("error.saveAndGetSettings"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (shouldAuthenticate()) {
      setAuthIsOpen(true);
    } else {
      saveSettings();
    }
  };

  const onAuthSuccess = () => {
    setAuthIsOpen(false);
    saveSettings();
  };

  const samlError = () => {
    if (!settings.saml) return false;

    const { cert, entityId, ssoUrl } = settings.saml;
    let isValid = entityId && ssoUrl;

    if (!isSamlPreviousLoaded()) {
      isValid = isValid && cert;
    }

    return !isValid;
  };

  return (
    <>
      <PageLoading loading={loading} />
      <SectionHeader title="Security">
        <>
          <Typography>{t("sectionExplanation")}</Typography>
          <Typography>
            {t("toSeeAuthorizedUsers")}{" "}
            <Link
              component="button"
              variant="button"
              onClick={() => history.push("/users")}
            >
              {t("common:user_plural")}
            </Link>
          </Typography>
        </>
      </SectionHeader>
      <form onSubmit={handleSubmit}>
        <List
          component={Paper}
          data-testid={
            loading ? "security-options_loading" : "security-options"
          }
        >
          <ListItem>
            <ListItemIcon>
              <Switch
                color="primary"
                edge="start"
                onChange={handleToggle("password")}
                checked={settings.password}
                inputProps={{
                  // @ts-ignore
                  "data-testid": `option_password_${
                    settings.password ? "on" : "off"
                  }`,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={t("userAndPassword")}
              secondary={t("allowSignInWithMxhero")}
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <ListItemIcon>
              <Switch
                color="primary"
                edge="start"
                onChange={handleToggle("google")}
                checked={settings.google}
                inputProps={{
                  // @ts-ignore
                  "data-testid": `option_google_${
                    settings.google ? "on" : "off"
                  }`,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={t("common:googleApps")}
              secondary={t("allowSignInWithGoogle")}
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <ListItemIcon>
              <Switch
                color="primary"
                edge="start"
                onChange={handleToggle("office")}
                checked={settings.office}
                inputProps={{
                  // @ts-ignore
                  "data-testid": `option_office365_${
                    settings.office ? "on" : "off"
                  }`,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={t("common:office365")}
              secondary={t("allowSignInWithOffice")}
            />
          </ListItem>
          <Divider variant="middle" />
          <ListItem>
            <ListItemIcon>
              <Switch
                color="primary"
                edge="start"
                onChange={handleToggle("saml")}
                checked={!!settings.saml}
                inputProps={{
                  // @ts-ignore
                  "data-testid": `option_saml_${settings.saml ? "on" : "off"}`,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography display="inline">SAML </Typography>
                  {samlError() && (
                    <Typography display="inline" variant="body2" color="error">
                      {t("credentialsMustBeComplete")}
                    </Typography>
                  )}
                </>
              }
              secondary={t("allowSignInWithSaml")}
            />
            <ListItemSecondaryAction>
              <SamlBtn
                settings={settings.saml}
                color={samlError() ? "secondary" : "default"}
                certIsRequired={!isSamlPreviousLoaded()}
                disabled={!settings.saml}
                onSave={handleSamlSettings}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        <Box mt={2}>
          <List component={Paper}>
            <ListItem>
              <ListItemIcon>
                <Switch
                  color="primary"
                  edge="start"
                  onChange={handleToggle("twoFactorAuthentication")}
                  checked={settings.twoFactorAuthentication}
                  inputProps={{
                    // @ts-ignore
                    "data-testid": `option_2fa_${
                      settings.twoFactorAuthentication ? "on" : "off"
                    }`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={t("2FA.option")}
                secondary={t("2FA.description")}
              />
            </ListItem>
          </List>
        </Box>
        <Button
          data-testid="options__save-btn"
          disabled={isSaveDisabled()}
          variant="contained"
          color="primary"
          style={{ marginTop: "30px" }}
          type="submit"
        >
          {t("saveSettings")}
        </Button>
      </form>
      {authIsOpen && (
        <AuthenticationModal
          isOpen={authIsOpen}
          onSuccess={onAuthSuccess}
          onClose={() => setAuthIsOpen(false)}
        />
      )}
    </>
  );
}

/*
const hasProperty = (object: Object, property: string) => {
  return Object.prototype.hasOwnProperty.call(object, property);
};
*/
