import { useTranslation } from "react-i18next";
import { v4 as keyGen } from "uuid";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  ResultIcon,
  ResultValuesRow,
  StyledDivider,
  TestResultContainer,
} from "../Styled";

import { TestPredicateResponse } from "@dashboard-v3/api";

interface Props {
  testResult: TestPredicateResponse;
  defaultValue: string;
}

export default function PredicateTestResults({
  testResult,
  defaultValue,
}: Props) {
  const { t } = useTranslation("variables");
  const { result, position } = testResult;
  const classes = useValueListStyles();

  return (
    <>
      <StyledDivider />
      <TestResultContainer>
        <Typography variant="subtitle2">Results</Typography>
        {result && (
          <>
            <ResultValuesRow>
              <ResultIcon $success={result} />
              <Typography variant="subtitle2">
                {t("variableTester.successCondition", { number: position + 1 })}
              </Typography>
            </ResultValuesRow>
            <ResultValuesRow>
              <FormatListBulletedIcon className={classes.listIcon} />
              <Typography variant="subtitle2">
                {t("variableTester.convertValues")}
              </Typography>
            </ResultValuesRow>
            <List>
              {testResult.values.map(value => (
                <ListItem key={keyGen()}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <FiberManualRecordIcon className={classes.bulletIcon} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ variant: "caption" }}>
                    {value}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {!result && (
          <>
            <ResultValuesRow>
              <ResultIcon $success={result} />
              <Typography variant="subtitle2">
                {t("variableTester.noResult")}
              </Typography>
            </ResultValuesRow>
            {defaultValue && (
              <ResultValuesRow>
                <ResultIcon $success={!result} />
                <Typography variant="subtitle2">
                  {t("variableTester.defaultValue")}
                </Typography>
                <Typography variant="caption">{`"${defaultValue}"`}</Typography>
              </ResultValuesRow>
            )}
          </>
        )}
      </TestResultContainer>
    </>
  );
}

const useValueListStyles = makeStyles(() => ({
  listIcon: { height: "18px", width: "18px" },
  listItemIcon: {
    minWidth: "30px",
  },
  bulletIcon: {
    height: "10px",
    width: "10px",
  },
}));
