import React, { useContext } from "react";
import type { Action } from "./action";
import { State } from "./types";

export const RuleContext = React.createContext(null);

export type Context = {
  state: State;
  dispatch: (action: Action) => void;
};

export const useRuleContext = () => useContext<Context>(RuleContext);
