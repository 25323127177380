import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ComponentList from "components/ComponentList";
import apiRequest from "utils/apiRequestWithErrorCode";
import { CopyPolicy } from "@dashboard-v3/api";
import Item from "./Item";
import { byLastModified } from "utils/sort";
import { ShowMoreBtn } from "components/List";
import produce from "immer";

const listLimit = 10;

export default function CopyPolicyList() {
  const [loading, setLoading] = useState<"ALL" | "MORE">();
  const [copyPolicies, setCopyPolicies] = useState<CopyPolicy[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [searchOffset, setSearchOffset] = useState(0);
  const { t } = useTranslation("copyPolicy");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function initialFetch() {
      setLoading("ALL");
      await fetchPolicies();
      setLoading(null);
    }

    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPolicies = async (offset = 0) => {
    try {
      const data = await apiRequest<CopyPolicy[]>(
        "GET",
        `/copy-policies?offset=${offset}&limit=${listLimit}`
      );
      setHasMore(data.length === listLimit);
      setCopyPolicies(prevPolicies =>
        [...data, ...prevPolicies].sort(byLastModified)
      );
    } catch (error) {
      enqueueSnackbar(t("form.errors.saveError"), {
        variant: "error",
      });
    }
  };

  const loadMore = async () => {
    setLoading("MORE");
    const newOffset = searchOffset + listLimit;
    await fetchPolicies(newOffset);
    setSearchOffset(newOffset);
    setLoading(null);
  };

  function deletedItem(deletedPolicy: CopyPolicy) {
    setCopyPolicies(
      produce(draft => {
        const index = draft.findIndex(policy => policy.id === deletedPolicy.id);
        draft.splice(index, 1);
      })
    );
    enqueueSnackbar(t("form.messages.deletedPolicy"), {
      variant: "success",
    });
  }

  return (
    <>
      <ComponentList
        list={copyPolicies}
        loading={loading === "ALL"}
        emptyMsg={t("list.noPoliciesToList")}
        renderItems={policy => (
          <Item key={policy.id} policy={policy} onDelete={deletedItem} />
        )}
      />
      {hasMore && (
        <ShowMoreBtn loading={loading === "MORE"} onClick={loadMore} />
      )}
    </>
  );
}
