import { useTranslation } from "react-i18next";
import { Rule } from "../types";
import { Button } from "@material-ui/core";

export default function GoToAccountDetailsBtn({ rule }: { rule: Rule }) {
  const { t } = useTranslation("rules");

  return (
    <Button
      href={`/rules/${rule.id}/account-stats`}
      variant="outlined"
      size="large"
      color="primary"
    >
      {t("mailboxStats.toAccountBtn")}
    </Button>
  );
}
