import { DomainSearchItem, OrganizationSearchItem } from "@dashboard-v3/api";
import { TextField, CircularProgress, Box } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apiRequest from "utils/apiRequestWithErrorCode";
import throttle from "utils/throttle";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import * as Sentry from "@sentry/react";
import { getUser } from "utils/authentication";

type OrganizationId = string;

type Params = {
  onChange: (selection: OrganizationId | null) => void;
};

export default function Search({ onChange }: Params) {
  const [search, setSearch] = useState({
    open: false,
    options: [] as Array<DomainSearchItem | OrganizationSearchItem>,
    loading: false,
    inputValue: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("components");

  useEffect(() => {
    async function getDomainsAndOrgs(term) {
      return throttle(async () => {
        try {
          setSearch(prev => ({ ...prev, loading: true }));
          const [domains, organizations] = await Promise.all([
            apiRequest<DomainSearchItem[]>("GET", `/domains/search/${term}`),
            apiRequest<OrganizationSearchItem[]>(
              "GET",
              `/organization/search/${encodeURIComponent(term)}`
            ),
          ]);

          setSearch(prev => ({
            ...prev,
            options: [...domains, ...organizations],
            loading: false,
          }));
        } catch (error) {
          Sentry.captureException(error, {
            user: getUser(),
            fingerprint: ["organizationSelector", "search"],
            extra: { term },
          });

          enqueueSnackbar(t("organizationModal.errors.unknown"), {
            variant: "error",
          });
          setSearch(prev => ({ ...prev, loading: false }));
        }
      }, 400);
    }

    if (search.inputValue.length >= 2) {
      getDomainsAndOrgs(search.inputValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.inputValue]);

  return (
    <Autocomplete
      open={search.open}
      onOpen={() => {
        setSearch(prev => ({ ...prev, open: true }));
      }}
      onClose={() => {
        setSearch(prev => ({ ...prev, open: false }));
      }}
      options={search.options}
      loading={search.loading}
      getOptionLabel={option => {
        return isDomainOption(option) ? option.organizationName : option.name;
      }}
      groupBy={option =>
        isDomainOption(option)
          ? t("organizationModal.domainsGroup")
          : t("organizationModal.organizationGroup")
      }
      onChange={(_, value) => {
        if (value) {
          const option = value as DomainSearchItem | OrganizationSearchItem;
          const orgId = isDomainOption(option)
            ? option.organizationId
            : option.id;
          onChange(orgId);
        } else {
          onChange(null);
        }
      }}
      getOptionSelected={(option, selected) => {
        const one = isDomainOption(option) ? option.domain : option.id;
        const other = isDomainOption(selected) ? selected.domain : selected.id;
        return one === other;
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === "input") {
          setSearch(prev => ({ ...prev, inputValue: newInputValue }));
        }
      }}
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          label={t("organizationModal.searchLabel")}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {search.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={option => {
        if (isDomainOption(option)) {
          return (
            <DomainOption option={option} inputValue={search.inputValue} />
          );
        }

        return (
          <OrganizationOption option={option} inputValue={search.inputValue} />
        );
      }}
    />
  );
}

function isDomainOption(
  option: DomainSearchItem | OrganizationSearchItem
): option is DomainSearchItem {
  return "domain" in option;
}

function OrganizationOption({
  option,
  inputValue,
}: {
  option: OrganizationSearchItem;
  inputValue: string;
}) {
  const matches = match(option.id, inputValue);
  const parts = parse(option.id, matches);

  return (
    <Box>
      <Box display="flex">
        <Box mr="8px">ID</Box>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </Box>
      <Box fontSize=".8rem">{option.name}</Box>
    </Box>
  );
}

function DomainOption({
  option,
  inputValue,
}: {
  option: DomainSearchItem;
  inputValue: string;
}) {
  const { t } = useTranslation("components");

  const matches = match(option.domain, inputValue);
  const parts = parse(option.domain, matches);

  return (
    <Box>
      <Box>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </Box>
      <Box fontSize=".8rem" display="flex" gridGap="3px">
        <Box component="span" color={grey[500]}>
          {t("organizationModal.of")}
        </Box>
        {option.organizationName}
      </Box>
    </Box>
  );
}
