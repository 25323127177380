import { useEffect, useState } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SectionHeader from "components/SectionHeader";
import { Button } from "components/Forms/StyledComponents";
import VariablesForm from "./VariablesForm";
import VariablesList from "./VariablesList";
import SectionTabs from "components/SectionTabs";
import { Box, Typography } from "@material-ui/core";
import useVariables from "utils/useVariables";
import useLoadVariable, { newVariable } from "./useLoadVariable";
import LinearLoader from "components/LinearLoader";
import { FormAction } from "./types";
import { KeyedVariable } from "@dashboard-v3/api";

export type TestingState = {
  isTesting: boolean;
  disabled: boolean;
};

enum ButtonType {
  ROUTE = "ROUTE",
  VARIABLE_TEST = "VARIABLE_TEST",
}

type Tab = "variables" | "defaultVariables";
export default function Variables() {
  const { t } = useTranslation("variables");
  const history = useHistory();
  const { pathname, state } = useLocation() as {
    pathname: string;
    state?: { tab?: Tab };
  };
  const [loading, setLoading] = useState<"ALL" | "ITEM">();
  const [activeTab, setActiveTab] = useState<Tab>(state?.tab ?? "variables");
  const [variableTest, setVariableTest] = useState<TestingState>(initTestState);
  const [defaultVariables, setDefaultVariables] = useState<KeyedVariable[]>([]);
  const [userVariables, setUserVariables] = useState<KeyedVariable[]>();

  const { userVariables: variables, loading: listLoading } = useVariables();

  useEffect(() => {
    if (variables) {
      const lists = variables.reduce(
        (lists, variable) => {
          if (variable.platformVariable) {
            lists.defaultVariables.push(variable);
          } else {
            lists.userVariables.push(variable);
          }
          return lists;
        },
        { defaultVariables: [], userVariables: [] }
      );
      setDefaultVariables(lists.defaultVariables);
      setUserVariables(lists.userVariables);
    }
  }, [variables]);

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  const isOnForm =
    pathname.endsWith("/new") ||
    pathname.endsWith("/edit") ||
    pathname.endsWith("/copy") ||
    !pathname.endsWith("/variables");

  const getSectionTitle = () => {
    const { isTesting } = variableTest;

    return isOnForm && isTesting
      ? t("variableTester.testVariable")
      : t("title");
  };

  const renderBtnText = (type: ButtonType) => {
    if (type === ButtonType.ROUTE) {
      return isOnForm ? t("common:backToList") : t("common:create");
    }

    if (type === ButtonType.VARIABLE_TEST) {
      const { isTesting } = variableTest;
      return isTesting
        ? t("variableTester.endTest")
        : t("variableTester.testVariable");
    }
  };

  const handleBtnClick = (type: ButtonType) => () => {
    if (type === ButtonType.ROUTE) {
      return history.push(!isOnForm ? "/variables/new" : "/variables");
    }

    if (type === ButtonType.VARIABLE_TEST) {
      return setVariableTest(prev => ({
        ...prev,
        isTesting: !prev.isTesting,
      }));
    }
  };

  return (
    <>
      <SectionHeader
        title={getSectionTitle()}
        sideBtn={
          <ButtonsContainer>
            {isOnForm && (
              <Button
                variant="contained"
                wording={renderBtnText(ButtonType.VARIABLE_TEST)}
                onClick={handleBtnClick(ButtonType.VARIABLE_TEST)}
                disabled={!variableTest.isTesting && variableTest.disabled}
              />
            )}
            {!variableTest.isTesting && (
              <Button
                variant={isOnForm ? "text" : "contained"}
                wording={renderBtnText(ButtonType.ROUTE)}
                onClick={handleBtnClick(ButtonType.ROUTE)}
              />
            )}
          </ButtonsContainer>
        }
      >
        <Typography>{t("subtitle")}</Typography>
      </SectionHeader>

      <Switch>
        <Route path="/variables" exact>
          <SectionTabs
            tabs={[
              { id: "variables", label: t("title") },
              { id: "defaultVariables", label: t("defaultVariables") },
            ]}
            onClick={id => setActiveTab(id as Tab)}
            showTab={activeTab}
          />
          <Box display={activeTab === "variables" ? "block" : "none"}>
            <VariablesList
              loading={loading === "ALL" || listLoading}
              setLoading={setLoading}
              variables={userVariables}
            />
          </Box>
          <Box display={activeTab === "defaultVariables" ? "block" : "none"}>
            <VariablesList
              loading={loading === "ALL"}
              setLoading={setLoading}
              variables={defaultVariables}
              isDefault
            />
          </Box>
        </Route>

        <Route path="/variables/new" exact>
          <VariablesForm
            variable={newVariable()}
            formAction="new"
            variableTestProps={{ variableTest, setVariableTest }}
          />
        </Route>

        <Route path="/variables/:id" exact>
          <LoadVariable variables={variables} formAction="show">
            {variable => (
              <VariablesForm
                variable={variable}
                formAction="show"
                variableTestProps={{ variableTest, setVariableTest }}
              />
            )}
          </LoadVariable>
        </Route>

        <Route path="/variables/:id/edit" exact>
          <LoadVariable variables={variables} formAction="edit">
            {variable => (
              <VariablesForm
                variable={variable}
                formAction="edit"
                variableTestProps={{ variableTest, setVariableTest }}
              />
            )}
          </LoadVariable>
        </Route>

        <Route path="/variables/:id/copy" exact>
          <LoadVariable variables={variables} formAction="copy">
            {variable => (
              <VariablesForm
                variable={variable}
                formAction="copy"
                variableTestProps={{ variableTest, setVariableTest }}
              />
            )}
          </LoadVariable>
        </Route>
      </Switch>
    </>
  );
}

function LoadVariable({
  variables,
  formAction,
  children,
}: {
  variables: KeyedVariable[];
  formAction: Exclude<FormAction, "new">;
  children: (variable: KeyedVariable) => React.ReactElement;
}) {
  const [variable] = useLoadVariable(variables, formAction);

  if (!variable) {
    return (
      <Box mt={2}>
        <LinearLoader />
      </Box>
    );
  }

  return children(variable);
}

export const initTestState = () => ({
  isTesting: false,
  disabled: true,
});

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`;
