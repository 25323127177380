import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "components/ButtonWithLoading";
import InstallButton from "./InstallButton";
import ConfirmModal from "./ConfirmModal";
import * as api from "../../api";
import openAuthPopup from "../../Accounts/openAuthPopup";
import ApplicationStatus, { SyncStatus } from "../ApplicationStatus";

import { StorageSettingsUpdate } from "../../types";
import { getErrorCode } from "utils/apiRequestWithErrorCode";

import { VerifyGappsResponse } from "@dashboard-v3/api";

interface Props {
  settings: StorageSettingsUpdate;
  onAccountSync: (update: StorageSettingsUpdate) => void;
}

export default function GappsSync({ settings, onAccountSync }: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<SyncStatus>(SyncStatus.NOT_VERIFIED);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [verifyData, setVerifyData] = useState<VerifyGappsResponse>();

  useEffect(() => {
    const installed = settings?.gapps?.installed;
    if (installed === null || installed === undefined) {
      setStatus(SyncStatus.NOT_VERIFIED);
    } else {
      setStatus(installed ? SyncStatus.INSTALLED : SyncStatus.NOT_INSTALLED);
    }
  }, [settings]);

  function handleVerificationError(error: unknown) {
    const errorCode = getErrorCode(error) as string;
    if (errorCode === "APP_IS_NOT_INSTALLED") {
      setStatus(SyncStatus.NOT_INSTALLED);
      onAccountSync({ ...settings, gapps: { installed: false } });
      return;
    }
    if (errorCode === "CHECK_ACCOUNT_ACCESS") {
      enqueueSnackbar(t("gapps.verify.error.checkAccess"), {
        variant: "error",
      });
      return;
    }
    enqueueSnackbar(t(`gapps.verify.error.${errorCode}`), {
      variant: "error",
    });
  }

  async function verifyGappsStatus() {
    setLoading(true);
    try {
      const storageAccount = await openAuthPopup("GOOGLEDRIVE");
      const verifyData = await api.verifyGapps(storageAccount.user);
      setVerifyData(verifyData);
      setShowConfirmation(true);
    } catch (error) {
      handleVerificationError(error);
    } finally {
      setLoading(false);
    }
  }

  const onConfirm = async (customerId: string) => {
    setLoading(true);
    try {
      await api.updateGapps(customerId);
      const settings = await api.fetchStorageSettings();
      onAccountSync(settings);
      setShowConfirmation(false);
    } catch (error) {
      enqueueSnackbar(t("error.onVerifyGapps"), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  function showInstall() {
    return status === SyncStatus.NOT_INSTALLED && !loading;
  }

  function showVerify() {
    return status !== SyncStatus.NOT_INSTALLED;
  }

  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <ApplicationStatus status={status} />
      {showVerify() && (
        <ButtonWithLoading
          variant="text"
          color="default"
          data-testid="storage__drive-verify"
          loading={loading}
          disabled={loading}
          onClick={verifyGappsStatus}
        >
          {t("verify")}
        </ButtonWithLoading>
      )}
      {showInstall() && <InstallButton />}
      {showConfirmation && (
        <ConfirmModal
          isOpen={showConfirmation}
          loading={loading}
          data={verifyData}
          onConfirm={onConfirm}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
}
