import { Link } from "@material-ui/core";
import { FormatErrorMessage } from "./styled";
import { useTranslation } from "react-i18next";

interface FileItemHeader {
  fileName: string;
  containsErrors: boolean;
  showFileErrors: () => void;
}

export default function FileItemTitle({
  fileName,
  containsErrors,
  showFileErrors,
}: FileItemHeader) {
  const { t } = useTranslation("accounts");

  return (
    <div>
      {fileName}
      {containsErrors && (
        <FormatErrorMessage>
          {t("importAccountsModal.files.importError")}
          <Link style={{ cursor: "pointer" }} onClick={showFileErrors}>
            {t("importAccountsModal.files.showErrorsLink")}
          </Link>
        </FormatErrorMessage>
      )}
    </div>
  );
}
