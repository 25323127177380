import { useTranslation } from "react-i18next";
import SearchInput from "components/AutocompleteSearchInput";
import useVariables from "utils/useVariables";
import { TemplateField } from "@dashboard-v3/api";
import { Box, FormHelperText } from "@material-ui/core";
import AvailableOptionsHelp from "./AvailableOptionsHelp";

export interface Props {
  field: TemplateField;
  value: string | string[];
  disabled: boolean;
  onChange: (e: any) => void;
}

export default function MappingValue({
  field,
  value,
  disabled,
  onChange,
}: Props) {
  const { t } = useTranslation("templateDefinitions");
  const { mapVarToValue, mapVarToLabel, variableOptions } = useVariables();

  const handleOnChange = (value: string) => {
    onChange({
      target: { name: "value", value: mapVarToValue(value, variableOptions) },
    });
  };

  return (
    <>
      <SearchInput
        name="value"
        variables={variableOptions}
        value={mapVarToLabel(value as string, variableOptions)}
        onChange={handleOnChange}
        placeholder={t(`form.mappings.value.placeholder`)}
        fullWidth
        required
        disabled={disabled}
      />
      {field && (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "6%" }}>
            <FormHelperText style={{ marginTop: "1px", marginLeft: "5px" }}>
              {t(`form.mappings.type.${field.type}`)}.
            </FormHelperText>
            {hasOptions(field) && (
              <Box display="flex" justifyContent="end">
                <AvailableOptionsHelp field={field} />
              </Box>
            )}
          </div>
        </>
      )}
    </>
  );
}

function hasOptions(field: TemplateField) {
  return field.options?.length > 0;
}
