import { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  Typography,
  makeStyles,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { MailboxAccountListItem } from "@dashboard-v3/api";
import { yellow } from "@material-ui/core/colors";

interface Props {
  status: string;
  error: MailboxAccountListItem["error"];
}

export default function AccountErrorDetails({ status, error }: Props) {
  const { t } = useTranslation("rules");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const retryDate = new Date(error.updatedAt).toUTCString();

  const handleOpen = e => {
    if (error.message === "inactive_inbox_account") {
      setIsModalOpen(true);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const getErrorMessage = () => {
    const key = error.message;
    const translationKey = `mailboxStats.accountErrorDetail.${key}`;

    switch (key) {
      case "inbox_account_without_credentials":
      case "inbox_account_without_permission":
      case "no_valid_labels_found":
      case "create_subscription":
        return t(translationKey);
      case "invalid_inbox_account":
      case "inbox_account_with_problem":
      case "storage_account_with_problem":
        return t(translationKey, { emailAccount: error.emailAccount });
      case "storage_account_without_permission":
      case "storage_account_with_wrong_configuration":
        return t(translationKey, { userIdForStorage: error.userIdForStorage });
      case "no_healthy_user_found_for_storage":
        return t(translationKey, { emailAccount: error.emailAccount });
      default:
        return t("mailboxStats.accountErrorDetail.unknown");
    }
  };

  const closePopover = () => setAnchorEl(null);

  return (
    <div className={classes.itemContainer}>
      <Box
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
        display="flex"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="body2">{status}</Typography>
          <Typography variant="caption">
            {maybeShowTemporal(error, t)}
          </Typography>
        </Box>

        <FeedbackIcon retries={error.consecutiveErrorsAmount} />
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.contentContainer}>
          <div className={classes.itemWrapper}>
            <Typography variant="subtitle2" color="textSecondary">
              {t("mailboxStats.accountErrorDetail.message")}
            </Typography>
            <Typography variant="body2">{getErrorMessage()}</Typography>
          </div>
          <div className={classes.itemWrapper}>
            <Typography variant="subtitle2" color="textSecondary">
              {t("mailboxStats.accountErrorDetail.retries")}
            </Typography>
            <Typography variant="body2">
              {error.consecutiveErrorsAmount}
            </Typography>
          </div>
          <div className={classes.itemWrapper}>
            <Typography variant="subtitle2" color="textSecondary">
              {t("mailboxStats.accountErrorDetail.retryDate")}
            </Typography>
            <Typography variant="body2">{retryDate}</Typography>
          </div>
        </div>
      </Popover>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>{t("mailboxStats.inactiveInbox.title")}</DialogTitle>
        <DialogContent style={{ marginBottom: "15px" }}>
          <Typography variant="body2" color="textSecondary">
            {t("mailboxStats.inactiveInbox.description")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disableElevation
            onClick={() => setIsModalOpen(false)}
          >
            {t("common:close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function isWarningError(retries: number) {
  return retries <= 20;
}

function maybeShowTemporal(
  error: MailboxAccountListItem["error"],
  t: TFunction
) {
  if (!isWarningError(error.consecutiveErrorsAmount)) return null;

  return /* Shows "(Temporal)" */ t("mailboxStats.temporalWarning");
}

const FeedbackIcon = ({ retries }: { retries: number }) => {
  if (isWarningError(retries)) {
    return (
      <InfoOutlinedIcon style={{ fontSize: "16px", color: yellow[700] }} />
    );
  }

  return <InfoOutlinedIcon color="error" style={{ fontSize: "16px" }} />;
};

const useStyles = makeStyles({
  itemContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  contentContainer: {
    padding: "10px 15px",
  },
  itemWrapper: {
    display: "flex",
    gap: "5px",
    alignItems: "baseline",
  },
});
