import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import RuleCard from "./RuleCard";
import AccountsCards from "./AccountsCards";
import AccountsTable from "./AccountsTable";
import { StatsContainer } from "./styled";

export default function AccountStats() {
  const { t } = useTranslation("stats");
  const { id: ruleId } = useParams();

  return (
    <StatsContainer>
      <Typography variant="h6" style={{ fontSize: "26px" }}>
        {t("accounts.ruleCard.title")}
      </Typography>

      <RuleCard ruleId={ruleId} />

      <Typography variant="h6" style={{ fontSize: "26px" }}>
        {t("accounts.accountCards.title")}
      </Typography>

      <AccountsCards ruleId={ruleId} />

      <AccountsTable ruleId={ruleId} />
    </StatsContainer>
  );
}
