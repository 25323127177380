import { useTranslation } from "react-i18next";
import { Switch, Route } from "react-router-dom";
import SectionHeader from "components/SectionHeader";
import List from "./List";
import StorageShareForm from "./Form";

export default function StorageShares() {
  const { t } = useTranslation("storageShares");

  return (
    <>
      <SectionHeader title={t("title")} />
      <Switch>
        <Route path="/mxhero-shares" exact>
          <List />
        </Route>
        <Route path="/mxhero-shares/:id">
          <StorageShareForm />
        </Route>
      </Switch>
    </>
  );
}
