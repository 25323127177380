/**
 *
 * @param {string | number} [timestamp]
 * @returns {number}
 */
function hoursUntilNow(timestamp) {
  if (!timestamp) return Number.POSITIVE_INFINITY;

  const now = new Date();
  const lastUse = new Date(timestamp);
  const diffInSeconds = (now.getTime() - lastUse.getTime()) / 1000;

  // Could not be negative, the timestamp allways must be in the past
  if (diffInSeconds < 0) return Number.POSITIVE_INFINITY;

  return diffInSeconds / 60 / 60;
}

module.exports = { hoursUntilNow };
