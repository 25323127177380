/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useVariables from "utils/useVariables";
import VariablesHelper from "./VariablesHelper";

import { DashboardVariable } from "types";

const AutocompleteWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  span:nth-child(2) {
    font-size: 12px;
    color: grey;
  }
`;

interface AppFieldProps {
  value: string[];
  name?: string;
  fullWidth?: boolean;
  required?: boolean;
  onChange: (
    event: ChangeEvent<MouseEvent<HTMLInputElement>>,
    variable: DashboardVariable[]
  ) => void;
  disabled?: boolean;
}

export default function ApplicationFields({
  onChange,
  value,
  required,
  disabled,
  ...rest
}: AppFieldProps) {
  const { t } = useTranslation("components");
  const { platformVariables: variables } = useVariables();
  const [options, setOptions] = useState<DashboardVariable[]>(variables);
  const [current, setCurrent] = useState<DashboardVariable[]>(
    (value || []).map(s => variables.find(v => v.id === `{${s}}`))
  );

  useEffect(() => {
    setOptions(prev => {
      if (current.length) {
        return prev.filter(opt => current[0].type === opt.type);
      }
      return variables;
    });
  }, [current]);

  const handleOnChange = (e, values) => {
    setCurrent(values);
    onChange(e, values);
  };

  const getOptionLabel = (name: string) => {
    return t(`platformVariables:${name}`).replace(/[{}]/g, "").trim();
  };

  const getOptionDescription = (id: string, desc: string) => {
    const isEmailPart = /account|domain/gi.test(desc);
    const description = t(`platformVariables:${desc}`);

    if (isEmailPart) {
      const [text, email] = description.split(/(local@domain.com)/g);
      if (email) {
        const [local, at, domain] = email.split(/(@)/g);
        return (
          <span>
            {text}
            {id.includes("account") ? <strong>{local}</strong> : local}
            {at}
            {id.includes("domain") ? <strong>{domain}</strong> : domain}
          </span>
        );
      }
    }
    return <span>{description}</span>;
  };

  return (
    <AutocompleteWrapper>
      <Autocomplete
        disabled={disabled}
        multiple
        filterSelectedOptions
        defaultValue={[]}
        limitTags={3}
        options={options}
        getOptionLabel={opt => t(`platformVariables:${opt.name}`)}
        getOptionSelected={(option, selected) => option.id === selected.id}
        value={current}
        onChange={handleOnChange}
        renderOption={({ id, name, description }) => (
          <OptionContainer data-testid="components_app-var-item">
            <span>{getOptionLabel(name)}</span>
            {getOptionDescription(id, description)}
          </OptionContainer>
        )}
        renderInput={params => (
          <TextField
            variant="outlined"
            label={t("appFields.label")}
            data-testid="components_app-vars-input"
            required={required}
            {...params}
          />
        )}
        {...rest}
      />
      <VariablesHelper value={current} variables={variables} />
    </AutocompleteWrapper>
  );
}
