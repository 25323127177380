/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import nextId from "react-id-generator";
import { makeStyles } from "@material-ui/core/styles";
import { filtersAndSubfilters as availableFilters } from "../filters";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: ".875rem",
    position: "relative",
    top: "1px",
  },
  tickSize: {
    transform: "scale(0.9)",
  },
}));

function activeFilters(filters) {
  return Object.keys(filters).filter(key => filters[key]);
}

const ActionFilters = ({ type, onChange }) => {
  const filterState = availableFilters[type].reduce((state, filter) => {
    return { ...state, [filter]: false };
  }, {});

  const [filters, setFilters] = useState(filterState);
  const classes = useStyles();
  const { t } = useTranslation("userActivity");

  const handleChange = event => {
    const newFilterState = {
      ...filters,
      [event.target.name]: event.target.checked,
    };

    setFilters(newFilterState);
    onChange(type, activeFilters(newFilterState));
  };

  const allFiltersChecked = Object.keys(filters).every(filter => {
    return filters[filter];
  });

  const handleAllFilters = () => {
    if (allFiltersChecked) {
      setFilters(filterState);
      onChange(type, activeFilters(filterState));
    } else {
      availableFilters[type].forEach(filter => {
        filterState[filter] = true;
      });
      setFilters(filterState);
      onChange(type, activeFilters(filterState));
    }
  };

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        classes={{ label: classes.label }}
        control={
          <Checkbox
            checked={allFiltersChecked}
            color="primary"
            onChange={handleAllFilters}
            size="small"
            inputProps={{
              "data-testid": `filter-${type}`,
            }}
          />
        }
        label={t(`events.${type}`)}
      />
      <FormGroup style={{ paddingLeft: "27px" }}>
        {availableFilters[type].map(filterName => {
          return (
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  checked={filters[filterName]}
                  color="primary"
                  inputProps={{
                    "data-testid": `filter-${type}-${filterName}`,
                  }}
                  name={filterName}
                  onChange={handleChange}
                  size="small"
                />
              }
              key={nextId()}
              label={t(`events.${filterName}`)}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

ActionFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ActionFilters;
