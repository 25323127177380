import { KeyedVariable } from "@dashboard-v3/api";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { ErrorWithCode, getErrorCode } from "utils/apiRequestWithErrorCode";
import { deepCopy } from "utils/deepCopy";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { defaultAddCondition } from "./state/ConditionsProvider";
import { useTranslation } from "react-i18next";
import { FormAction } from "./types";

export default function useLoadVariable(
  variables: KeyedVariable[],
  formAction: Exclude<FormAction, "new">
) {
  const { t } = useTranslation("variables");
  const history = useHistory();
  const { state } = useLocation();
  const { id: paramId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [variable, setVariable] = useState<KeyedVariable>();

  useEffect(() => {
    const handleSavedData = () => {
      if (state?.selectedVariable) {
        const selectedVariable = state.selectedVariable as KeyedVariable;
        // Clear state
        history.replace(history.pathname, null);

        return selectedVariable.id !== paramId
          ? fetchVariableById(paramId)
          : setVariable(selectedVariable);
      }
      return fetchVariableById(paramId);
    };

    if (variables) {
      handleSavedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  async function fetchVariableById(id: string) {
    try {
      const found = variables.find(variable => variable.id === id);
      if (!found) throw new ErrorWithCode("NOT_FOUND");

      let variable = deepCopy(found);

      if (formAction === "copy") {
        variable.id = null;
        variable.name = t("form.name.copyName", { name: variable.name });
        variable.platformVariable = false;
      }

      setVariable(variable);
    } catch (error: unknown) {
      const code = getErrorCode(error);
      if (code === "NOT_FOUND") {
        enqueueSnackbar(t("errors.variables.fetchNotFound"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("errors.variables.fetchError"), {
          variant: "error",
        });
      }

      history.push("/variables");
    }
  }

  return [variable, setVariable] as [typeof variable, typeof setVariable];
}

export function newVariable(): KeyedVariable {
  return {
    id: null,
    name: "",
    description: "",
    predicate: {
      type: "OR",
      children: [defaultAddCondition()],
    },
    defaultValue: null,
    createdAt: null,
    updatedAt: null,
  };
}
