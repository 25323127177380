import { Box, Link, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

export default function PlatformDetails() {
  const { t } = useTranslation("notifications");

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography component="span">{t("warnings.sync.title")}</Typography>
      <Typography variant="body2" color="textSecondary">
        <Trans i18nKey="notifications:warnings.sync.description">
          <Link href="/accounts" target="_blank"></Link>
          <b></b>
        </Trans>
      </Typography>
    </Box>
  );
}
