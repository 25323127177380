import { ManualDomain } from "@dashboard-v3/api";
import { Box, Typography, TextField, Button } from "@material-ui/core";
import ButtonWithLoading from "components/ButtonWithLoading";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useClipboard from "utils/useClipboard";

type Params = {
  domain: ManualDomain;
  onVerify: (domain: ManualDomain) => Promise<ManualDomain>;
};

type State = "IDLE" | "VERIFYING" | "VERIFY_FAIL" | "VERIFY_SUCCESS";

export default function VerifySection({ domain, onVerify }: Params) {
  const { t } = useTranslation("orgAndDomains");
  const copyToClipboard = useClipboard();

  const [verifyState, setVerifyState] = useState<State>("IDLE");
  const { name, txtEntry, lastVerification } = domain;

  const verifyStateRef = useRef(verifyState);
  verifyStateRef.current = verifyState;

  useEffect(() => {
    handleErrorFadeOut(verifyState);
  }, [verifyState]);

  function handleErrorFadeOut(verifyState) {
    let timeId;
    if (verifyState === "VERIFY_FAIL") {
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        if (verifyStateRef.current === "VERIFY_FAIL") setVerifyState("IDLE");
      }, 5000);
    }

    return () => clearTimeout(timeId);
  }

  async function verify() {
    setVerifyState("VERIFYING");
    try {
      const updateDomain = await onVerify(domain);
      setVerifyState(
        isFail(updateDomain.lastStatus) ? "VERIFY_FAIL" : "VERIFY_SUCCESS"
      );
    } catch (error) {
      setVerifyState("VERIFY_FAIL");
    }
  }

  return (
    <Box>
      <Typography variant="h6">{t("manualDomains.verifyTitle")}</Typography>
      <Box mt={2}>
        <Typography>{t("manualDomains.verifySubTitle")}</Typography>
      </Box>

      <div style={{ margin: "1em 0" }}>{t("manualDomains.verifyStep1")}</div>
      <div style={{ margin: "1em 0" }}>
        <Trans
          i18nKey="manualDomains.verifyStep2"
          values={{ domainName: name }}
          t={t}
        >
          2. Copy the TXT record below into the DNS configuration for
          <b>{name}</b>
        </Trans>

        <Box mt="10px" ml="12px" display="flex" gridGap="15px">
          <TextField
            style={{ minWidth: "550px" }}
            variant="outlined"
            value={txtEntry}
            aria-readonly="true"
            InputProps={{ readOnly: true }}
          ></TextField>
          <Button
            variant="text"
            color="primary"
            onClick={() => copyToClipboard(txtEntry)}
          >
            {t("manualDomains.copyTxt")}
          </Button>
        </Box>
      </div>
      <div style={{ margin: "1em 0" }}>
        <Trans i18nKey="manualDomains.verifyStep3" t={t}>
          3. Press <b>verify</b> below
        </Trans>
      </div>

      <Box mt={3} display="flex" gridGap="15px" alignItems="center">
        <Box>
          <ButtonWithLoading
            type="button"
            variant="contained"
            color="primary"
            disableElevation
            onClick={verify}
            loading={verifyState === "VERIFYING"}
          >
            {verifyState === "VERIFYING"
              ? t("manualDomains.verifing")
              : t("manualDomains.verify")}
          </ButtonWithLoading>
        </Box>

        {lastVerification && verifyState === "IDLE" && (
          <Box>
            {t("manualDomains.lastTry", {
              from: dayjs(lastVerification).fromNow(),
            })}
          </Box>
        )}

        {verifyState === "VERIFY_FAIL" && (
          <div>
            <Box display="flex" gridGap="5px">
              <Typography color="error">
                {t(errorTransKey(domain.lastStatus))}
              </Typography>
            </Box>
          </div>
        )}
      </Box>
    </Box>
  );
}

function errorTransKey(state: ManualDomain["lastStatus"]) {
  if (state === "NOT_VERIFIED") return "manualDomains.verificationFailed";

  if (state === "DOMAIN_NOT_FOUND") return "manualDomains.DOMAIN_NOT_FOUND";

  if (state === "DOMAIN_ALREADY_EXIST")
    return "manualDomains.DOMAIN_ALREADY_EXIST";
}

function isFail(state: ManualDomain["lastStatus"]) {
  return state !== "VERIFIED";
}
