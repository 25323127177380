import useIaContext from "./useIaContext";
import extractSources from "./extractSources";
import {
  getAndConditions,
  getOrConditionsToValue,
  parseTestSources,
} from "./helpers";

import {
  AIPredicateData,
  TestSources,
  Variable,
  VariableReqBody,
} from "@dashboard-v3/api";
import { DashboardVariable } from "types";

export default function useTestSources(
  variable: VariableReqBody,
  userVariables: Variable[],
  platformVariables: DashboardVariable[]
) {
  const { iaContext } = useIaContext();
  const andConditions = getAndConditions(variable);
  const convertValues = getOrConditionsToValue(variable).join("");
  const toValueSources = parseTestSources(
    convertValues,
    platformVariables
  ) as unknown as TestSources;

  const sources: TestSources = andConditions.reduce((sourcesArr, condition) => {
    const { parameters, type, sources, sourceType, variableIdList } = condition;

    if (type === "AI_QUESTION") {
      const [, editedContext] = parameters;
      if (editedContext) {
        const sources = extractSources(editedContext);
        return [...sourcesArr, ...mapToTestSources(sources)];
      } else {
        return [...sourcesArr, ...iaContextSources(iaContext)];
      }
    } else {
      if (userVariables && variableIdList.length) {
        const compoundSources = getExternalSources(
          userVariables,
          variableIdList,
          iaContext
        );

        compoundSources.forEach(source => {
          sourcesArr.push({ id: source, type: sourceType, values: [] });
        });
      }

      if (sources.length) {
        sources.forEach(source => {
          sourcesArr.push({ id: source, type: sourceType, values: [] });
        });
      }

      if (type === "REGEX" && parameters.length > 1) {
        const [, keyMatch] = condition.parameters;
        const keyMatchSources = parseTestSources(keyMatch, platformVariables);
        sourcesArr.push(...keyMatchSources);
      }
    }

    return sourcesArr;
  }, [] as TestSources);

  return getUniqueSources(sources.concat(toValueSources));
}

function getUniqueSources(sources: TestSources) {
  return sources.filter((source, i, sourcesArr) => {
    const duplicateIndex = sourcesArr.findIndex(s => source.id === s.id);
    return duplicateIndex === i;
  });
}

function iaContextSources(iaContext: AIPredicateData): TestSources {
  return mapToTestSources(iaContext?.aiTemplate?.defaultSources || []);
}

function mapToTestSources(sources: string[]): TestSources {
  return sources.map(source => ({
    id: source,
    type: "TEXT",
    values: [],
  })) as TestSources;
}

const getExternalSources = (
  variables: Variable[],
  varIds: string[],
  context: AIPredicateData
) => {
  const varsInUse = variables.filter(v => varIds.includes(v.id));
  const sourcesArray = varsInUse.reduce((acc, v) => {
    const andChildren = v.predicate.children.map(v => v.children).flat();
    const sources = andChildren.map(v => {
      if (v.type !== "AI_QUESTION") return v.sources;
      const [, customContext] = v.parameters;
      return customContext
        ? extractSources(customContext)
        : context?.aiTemplate?.defaultSources;
    });

    return [...acc, ...sources];
  }, []);

  return sourcesArray.flat();
};
