import produce from "immer";
import { useTranslation } from "react-i18next";
import { Label, OptionGroup } from "components/Styled";
import { ContentWrapper } from "./Styled";
import { canConfirm, canImpersonate, getStorage } from "./utils";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import SelectStorageAccount, { Change } from "./SelectStorageAccount";
import AsUserSelector from "./AsUserSelector";
import { Storage } from "@dashboard-v3/api";
import SelectSecurityOptions, {
  showSecurityOptionsIn,
} from "./SelectSecurityOptions";
import { Option } from "./types";
import ButtonWithLoading from "components/ButtonWithLoading";

interface StorageAccountProps {
  securityOptions: Option[];
}

export default function StorageAccount({
  securityOptions,
}: StorageAccountProps) {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { rule } = state;
  const storage: Storage = getStorage(rule);

  function handleStorageAccountChange(storageAccountChange: Change) {
    const { authentication, provider, userId } = storageAccountChange;

    const changes = produce(storage, draft => {
      if (authentication === "INDIVIDUAL") {
        draft.userIds = [];
        draft.asUsers = provider === "DROPBOX" ? draft.asUsers : [];
      }

      draft.provider = provider;
      draft.authentication = authentication;
      draft.asUser = null;
      draft.userId = userId || "";
      draft.storeIdUserId = null;
      draft.storeId = null;
      if (draft.location.parentId) {
        delete draft.location.parentId;
        delete draft.location.parentName;
      }

      if (provider === "MXHERO_STORAGE") {
        // default security option set
        draft.linkSecurity = {
          id: "OPEN_LINKS_7_DAYS",
          type: "DEFINITION",
        };
      } else {
        draft.linkSecurity = null;
      }
    });

    dispatch({
      type: "updateStep",
      payload: { step: "cloudStorage", changes },
    });
  }

  return (
    <ContentWrapper>
      <OptionGroup>
        <Label wording={t("cloudStorage.accounts.title")} />
        <SelectStorageAccount
          storage={storage}
          onChange={handleStorageAccountChange}
        />
      </OptionGroup>
      {canImpersonate(storage) && <AsUserSelector key={storage.provider} />}
      {showSecurityOptionsIn("STEP", state.rule) && (
        <SelectSecurityOptions options={securityOptions} />
      )}
      <ButtonWithLoading
        variant="contained"
        disableElevation
        fullWidth
        size="large"
        loading={state.asyncStatus === "validate_storage_access"}
        disabled={!canConfirm(state)}
        onClick={() => {
          dispatch({ type: "confirmStep", payload: "cloudStorage" });
        }}
      >
        {t("confirm")}
      </ButtonWithLoading>
    </ContentWrapper>
  );
}
