import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import request from "utils/apiRequestWithErrorCode";
import * as Sentry from "@sentry/react";
import { getUser } from "utils/authentication";

let authWindow;
let checkIsClosed;

export type AuthType =
  | "office365/individual"
  | "office365/organization"
  | "google/individual";

type Response = { auth: boolean; email?: string };

export default function useMailboxAuthPopup() {
  const { t } = useTranslation("authPopup");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * Mailbox (office and google) auth
   */
  async function openAuthPopup(
    type: AuthType,
    withConsent = false
  ): Promise<Response> {
    if (authWindow && !authWindow.closed) return null;
    // eslint-disable-next-line no-restricted-globals
    const currentUrl = location.origin;
    const fromMxHero = event => {
      return (
        currentUrl.startsWith(event.origin) && event.data.source === "mxhero"
      );
    };

    let windowSize =
      type === "google/individual"
        ? { width: 500, height: 680 }
        : { width: 450, height: 620 };

    // eslint-disable-next-line no-restricted-globals
    const redirectUrl = `${location.origin}/storage-auth-response`;
    const url = await getAuthUrl(redirectUrl, type, withConsent);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-use-before-define
      authWindow = window.open(url, "", popupConfig(windowSize));

      checkIsClosed = setInterval(() => {
        if (authWindow.closed) {
          clearInterval(checkIsClosed);
          authWindow = null;
          reject(new Error("CANCELED"));
        }
      }, 1000);

      function onMessage(event) {
        // Check component CloudStorageAuthResponse
        if (fromMxHero(event)) {
          const { success, errorCode, response, errorDetails } = event.data;
          window.removeEventListener("message", onMessage, false);

          clearInterval(checkIsClosed);
          if (authWindow?.close) authWindow.close();
          authWindow = null;

          if (success) {
            resolve({ auth: true, email: response.email });
          } else {
            const user = getUser();
            console.error("Error details: ", {
              errorCode,
              errorDetails,
              ...user,
            });

            Sentry.captureMessage("onMessage error", {
              level: "warning",
              user: getUser(),
              fingerprint: ["useMailboxAuthPopup"],
              extra: {
                type,
                withConsent,
                errorDetails,
                errorCode,
              },
            });

            if (
              type === "office365/individual" &&
              (errorCode || "").toLowerCase() === "consent_required"
            ) {
              enqueueSnackbar(t("office365consent.explain"), {
                action: snackbarId => (
                  <>
                    <Button
                      color="primary"
                      onClick={() => {
                        closeSnackbar(snackbarId);
                        try {
                          openAuthPopup("office365/individual", true)
                            .then(response => resolve(response))
                            .catch(error => reject(error));
                        } catch (error) {
                          reject(error);
                        }
                      }}
                    >
                      {t("office365consent.accept")}
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        closeSnackbar(snackbarId);
                        resolve({ auth: false });
                      }}
                    >
                      {t("office365consent.dismiss")}
                    </Button>
                  </>
                ),
                persist: true,
              });

              return;
            }
            if (
              type === "office365/individual" &&
              (errorCode || "").toLowerCase() === "access_denied"
            ) {
              // Reject the permissions in the popup
              enqueueSnackbar(t("office365consent.rejected"), {
                variant: "error",
              });
              resolve({ auth: false });
              return;
            }

            Sentry.captureMessage("Unhandled error in mailbox auth popup", {
              level: "fatal",
              user: getUser(),
              fingerprint: ["useMailboxAuthPopup"],
              extra: {
                type,
                withConsent,
                errorDetails,
                errorCode,
              },
            });

            reject(new Error(errorCode));
          }
        }
      }

      window.addEventListener("message", onMessage, false);
    });
  }

  const isClosedByUser = (error: unknown) => {
    return error instanceof Error && error.message === "CANCELED";
  };

  return { open: (type: AuthType) => openAuthPopup(type), isClosedByUser };
}

function popupConfig({ width, height }) {
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;
  return `width=${width},height=${height},status=false,titlebar=false,toolbar=false,menubar=false,top=${top},left=${left}`;
}

async function getAuthUrl(
  redirectUrl: string,
  type: AuthType,
  withConsent = false
) {
  let url = `/auth/mailbox/${type}/authorize?redirect_url=${redirectUrl}`;

  if (withConsent) {
    url = `${url}&consent=1`;
  }

  const response = await request<{ url: string }>("GET", url);

  return response.url;
}
