import { StorageSettings } from "@dashboard-v3/api";
import { useFetch } from "utils/api/useFetch";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

export default function useCheckBoxEnabled(mustBeChecked: boolean) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation("rules");
  const { data } = useFetch<StorageSettings>(
    mustBeChecked ? "/storages/settings" : null
  );

  if (mustBeChecked && data && !Boolean(data?.box?.enterpriseId)) {
    enqueueSnackbar(t("webhookBoxNotConfiguredError"), {
      action: () => (
        <Button
          color="inherit"
          href="https://support.mxhero.com/hc/en-us/articles/115002669183-Setting-up-a-BOX-Enterprise-or-Business-account"
          target="_blank"
        >
          {t("helpLinkLabel")}
        </Button>
      ),

      variant: "error",
    });

    history.push("/rules/list");
  }
}
