import useQuery from "utils/useQuery";
import { Redirect } from "react-router-dom";

export type AuthResponse = { source: "mxhero"; success: boolean };

export type AuthResponseSuccess = Omit<AuthResponse, "success"> & {
  success: true;
  response: Record<string, string>;
};

export type AuthResponseFail = Omit<AuthResponse, "success"> & {
  success: false;
  errorCode?: string;
  errorDetails?: string;
};

export default function CloudStorageAuthResponse() {
  const query = useQuery();
  const done = query.get("done");

  if (done === "1" && window.opener) {
    // eslint-disable-next-line no-use-before-define
    const response = convertQueryParamsToObject(query);
    const authResponse: AuthResponseSuccess = {
      source: "mxhero",
      success: true,
      response,
    };

    window.opener.postMessage(authResponse, "*");
    return <div />;
  }

  if (done === "0") {
    const authResponse: AuthResponseFail = {
      source: "mxhero",
      success: false,
      errorCode: query.get("code"),
      errorDetails: query.get("details"),
    };

    window.opener.postMessage(authResponse, "*");
    return <div />;
  }

  return <Redirect to="/" />;
}

function convertQueryParamsToObject(query: URLSearchParams) {
  return Array.from(query.entries()).reduce((collected, pair) => {
    const [key, value] = pair;
    if (key === "done") return collected;
    return { ...collected, [key]: value };
  }, {});
}
