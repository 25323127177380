/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import produce from "immer";
import { Trans, useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";
import { Alert } from "@material-ui/lab";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest, { getErrorCode } from "utils/apiRequestWithErrorCode";
import useVariables from "utils/useVariables";
import TestResults from "./PredicateTestResults";
import UploadEmailButton from "./UploadEmailButton";
import EmailDetailsBox from "./EmailDetailsBox";
import { TestingState } from "..";
import { TesterContainer, TestFooter, TestHeader } from "../Styled";
import { getEmailFieldLabel, getTestSourceValue } from "../helpers";

import {
  TestPredicateResponse,
  TestSources,
  VariableReqBody,
} from "@dashboard-v3/api";
import { LoadedEmail } from "../types";
import useTestSources from "../useTestSources";

interface Props {
  variable: VariableReqBody;
  variableTest: TestingState;
}

type FieldRows = { [x: string]: number };

export default function PredicateTester({ variable, variableTest }: Props) {
  const { t } = useTranslation(["variables", "platformVariables"]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [sources, setSources] = useState<TestSources>([]);
  const [loadedEmail, setLoadedEmail] = useState<LoadedEmail>(null);
  const [testResult, setTestResult] = useState<TestPredicateResponse>(null);
  const [wrongRegex, setWrongRegex] = useState<string>();
  const [maxFieldRows, setMaxFieldRows] = useState<FieldRows>({});
  const { platformVariables, userVariables } = useVariables();
  const variableSources = useTestSources(
    variable,
    userVariables,
    platformVariables
  );

  useEffect(() => {
    setTestResult(null);
    setSources(testSources =>
      variableSources.map((variableSource, i) => {
        const testSource = testSources[i];

        if (!loadedEmail) {
          return {
            ...variableSource,
            values:
              testSource && testSource.id === variableSource.id
                ? testSource.values
                : [],
          };
        }

        const emailSource = loadedEmail.sources.find(s => {
          return s.id === variableSource.id;
        });

        if (emailSource && !testSource?.values.length) return emailSource;

        return testSource || variableSource;
      })
    );

    setMaxFieldRows(initInputRows(loadedEmail));
  }, [variable, loadedEmail, userVariables]);

  const handleOnChange = (index: number) => e => {
    const { value } = e.target;
    setSources(prev =>
      produce(prev, sourcesDraft => {
        const { values } = sourcesDraft[index];
        sourcesDraft[index].values =
          values.length > 1 ? value.split("\n") : [value];
      })
    );
  };

  const handleError = (error: unknown) => {
    const errorCode = getErrorCode<string>(error);
    const match = errorCode.match(/WRONG_REGEX (?<test>.*)/);
    if (match && match.length > 1) {
      setWrongRegex(match[1]);
    } else {
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    }
  };

  const handleTestSubmit = async () => {
    const testBody = {
      predicate: variable.predicate,
      sources: sources,
    };
    setWrongRegex(null);
    setTestResult(null);
    setLoading(true);
    try {
      const testResult = await apiRequest<TestPredicateResponse>(
        "POST",
        "/variables/predicate-test",
        testBody
      );
      setTestResult(testResult);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputSize = (index: number) => e => {
    const { type } = e;
    const { id, values } = sources[index];
    setMaxFieldRows(prev =>
      produce(prev, draft => {
        if (type === "focus") {
          draft[id] = values.length > 1 ? values.length : null;
        } else {
          draft[id] = 6;
        }
      })
    );
  };

  const showUndoButton = (id: string, inputValues: string[]) => {
    if (loadedEmail) {
      const { sources } = loadedEmail;
      const match = sources.find(e => e.id === id);
      if (!match) return false;
      const originalValue = getTestSourceValue(match?.values || []);
      const currentValue = getTestSourceValue(inputValues);
      return originalValue !== currentValue;
    }
    return false;
  };

  const handleUndoValue = (id: string, index: number) => e => {
    e.preventDefault();
    const { sources } = loadedEmail;
    const { values: emailValues } = sources.find(e => e.id === id);
    const value = getTestSourceValue(emailValues);
    setSources(prev =>
      produce(prev, sourcesDraft => {
        sourcesDraft[index].values =
          emailValues.length > 1 ? value.split("\n") : [value];
      })
    );
  };

  return (
    <TesterContainer>
      <TestHeader>
        <Typography variant="subtitle2">{t("variableTester.title")}</Typography>
        <Typography variant="caption" color="textSecondary">
          {t("variableTester.subtitle")}
        </Typography>
      </TestHeader>
      {loadedEmail && <EmailDetailsBox email={loadedEmail} />}
      <Typography
        variant="subtitle2"
        style={{ alignSelf: "flex-start", fontSize: "16px" }}
      >
        {t("variableTester.fields")}
      </Typography>
      {sources.map(({ id, values }, i) => (
        <TextField
          key={id}
          variant="outlined"
          fullWidth
          multiline
          maxRows={maxFieldRows[id]}
          name={id}
          label={getEmailFieldLabel(id, t)}
          value={getTestSourceValue(values)}
          onChange={handleOnChange(i)}
          onFocus={handleInputSize(i)}
          onBlur={handleInputSize(i)}
          InputProps={{
            endAdornment: showUndoButton(id, values) && (
              <InputAdornment position="end">
                <Tooltip title={t("undoChanges")} aria-label="undo">
                  <IconButton onMouseDown={handleUndoValue(id, i)} size="small">
                    <UndoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      ))}
      {variableTest.disabled && (
        <FormHelperText error>
          {t("variableTester.disabledTest")}
        </FormHelperText>
      )}
      {wrongRegex && (
        <Box width="100%">
          <Alert severity="error">
            <Trans
              i18nKey="variableTester.wrongRegex"
              t={t}
              values={{ wrongRegex }}
            >
              The regex value "<b>{wrongRegex}</b>" is wrong
            </Trans>
          </Alert>
        </Box>
      )}
      <TestFooter>
        <ButtonWithLoading
          variant="contained"
          size="large"
          disableElevation
          onClick={handleTestSubmit}
          loading={loading}
          disabled={variableTest.disabled}
        >
          {t("variableTester.submitBtn")}
        </ButtonWithLoading>
        <UploadEmailButton
          onChange={(name, sources) => setLoadedEmail({ name, sources })}
          isReload={!!loadedEmail}
          disabled={variableTest.disabled}
        />
      </TestFooter>
      {testResult && (
        <TestResults
          testResult={testResult}
          defaultValue={variable.defaultValue}
        />
      )}
    </TesterContainer>
  );
}

const initInputRows = (email: LoadedEmail) => (rows: FieldRows) => {
  if (email) {
    const { sources } = email;
    return sources.reduce((acc, { id, values }) => {
      const isMultiline =
        values.length > 1 || id.includes("all") || id.includes("body");
      return { ...acc, [id]: isMultiline ? 6 : null };
    }, {});
  }

  return rows;
};
