import {
  Accordion,
  AccordionSummary,
  Typography,
  withStyles,
} from "@material-ui/core";
import styled from "styled-components";

export const AccordionContainer = styled(Accordion)`
  width: 100%;
  padding: 8px 5px;
`;

export const TextWithElipisis = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  cursor: pointer;
  max-width: 82%;
`;

export const SummaryContainer = withStyles({
  root: {
    padding: 0,
  },
  content: {
    margin: 0,
  },
})(AccordionSummary);

export const ButtonContainer = styled.div`
  justify-self: end;
  display: flex;
  gap: 8px;
`;
