import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { ListHeaderTitle } from "components/List";
import SectionHeader from "components/SectionHeader";
import { useTranslation } from "react-i18next";

type Props = {
  addBtn?: React.ReactElement;
  children: React.ReactElement;
  testId: string;
};

export default function TitleAndTable({ addBtn, children, testId }: Props) {
  const { t } = useTranslation("users");
  return (
    <>
      <SectionHeader title={t("userList")} sideBtn={addBtn} />
      <TableContainer component={Paper}>
        <Table data-testid={testId} size="small">
          <TableHead>
            <TableRow style={{ height: "48px" }}>
              <TableCell>
                <ListHeaderTitle variant="button">{t("name")}</ListHeaderTitle>
              </TableCell>
              <TableCell>
                <ListHeaderTitle variant="button">
                  {t("common:email")}
                </ListHeaderTitle>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
    </>
  );
}
