/* eslint-disable react-hooks/exhaustive-deps */
import { Direction, FromTo } from "@dashboard-v3/api";
import { useEffect, useState } from "react";
import {
  addKeys,
  replace,
  removeItem,
  extractKeys,
  ListWithKey,
} from "utils/listWithKey";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import { AllowedTypes } from "pages/Rules/FromToSelector/DirectionAutocomplete";
import FromToFilter from "pages/Rules/FromToSelector/FromToFilter";

type Props = {
  type: "exclude" | "filter";
  onChange: (changes: FromTo[]) => void;
  value?: FromTo[];
  allowedTypes?: AllowedTypes;
  errors: { [key: number]: string };
};

export default function ListFromTo({
  value,
  type,
  onChange,
  allowedTypes,
  errors,
}: Props) {
  const { t } = useTranslation("rules");
  const [list, setList] = useState<ListWithKey<FromTo>>([]);

  useEffect(() => {
    if (value) {
      setList(addKeys<FromTo>(value));
    } else {
      setList(initState());
      onChange(initFromTo());
    }
  }, []);

  const deleteItem = (key: string) => () => {
    const newList = removeItem(list, key);
    setList(newList);
    onChange(extractKeys(newList));
  };

  const addItem = () => {
    const newList = [...list, ...initState()];
    setList(newList);
    onChange(extractKeys(newList));
  };

  const updateItem = (key: string) => (changes: FromTo) => {
    const newList = replace(list, key, changes);
    setList(newList);
    onChange(extractKeys(newList));
    return newList;
  };

  return (
    <>
      {list.map((item, i) => {
        return (
          <Box key={item.key} mt={2}>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr auto"
              gridColumnGap="10px"
            >
              <Box justifySelf="center" alignSelf="center">
                <Typography>
                  {t(`targetFilter.emailsFrom`, {
                    type: t(`targetFilter.${type}`),
                  })}
                </Typography>
              </Box>

              <FromToFilter
                onChange={updateItem(item.key)}
                initialSelectedItem={item.value}
                allowedTypes={allowedTypes}
              />

              {isDeletable(list) && (
                <Box justifySelf="center" alignSelf="center">
                  <DeleteBtn onClick={deleteItem(item.key)} />
                </Box>
              )}
            </Box>
            {errors && (
              <FormHelperText style={{ marginTop: "10px" }} error>
                {t(errors[i])}
              </FormHelperText>
            )}
          </Box>
        );
      })}
      <Box mt={2}>
        <Button
          color="primary"
          disableElevation
          onClick={addItem}
          size="small"
          variant="contained"
        >
          {t("addAnotherRule")}
        </Button>
      </Box>
    </>
  );
}
const DeleteBtn = ({ onClick }) => {
  return (
    <IconButton
      edge="end"
      onClick={onClick}
      size="small"
      style={{ marginLeft: "-5px" }}
    >
      <DeleteIcon />
    </IconButton>
  );
};

const initFromTo = (): FromTo[] => [
  {
    fromDirection: { type: "" as Direction["type"] },
    toDirection: { type: "" as Direction["type"] },
  },
];

const initState = () => {
  return addKeys<FromTo>(initFromTo());
};

const isDeletable = (list: ListWithKey<FromTo>) => list.length > 1;
