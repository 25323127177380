import { CheckAccessResponse } from "@dashboard-v3/api";
import { Chip, Tooltip } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ChipRendererArgs } from "material-ui-chip-input";
import { useTranslation } from "react-i18next";

interface BalancedValuesProps extends ChipRendererArgs {
  accessErrors?: CheckAccessResponse["errors"];
}

export default function BalancedAccountsValues(props: BalancedValuesProps) {
  const { t } = useTranslation("rules");
  const { value, accessErrors, handleDelete } = props;
  const error = accessErrors?.find(a => a.account === value);
  const commonStyles: CSSProperties = {
    margin: "0 8px 8px 0",
    cursor: "pointer",
  };

  if (error) {
    return (
      <Tooltip
        title={t(`cloudStorage.asUser.error.${error.reason}`, {
          account: value,
        })}
      >
        <Chip
          color="secondary"
          label={value}
          onDelete={handleDelete}
          style={commonStyles}
        />
      </Tooltip>
    );
  }
  return <Chip label={value} onDelete={handleDelete} style={commonStyles} />;
}
