import { CopyPolicy, Storage } from "@dashboard-v3/api";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useFetch } from "utils/api/useFetch";
import useLocationTitle from "../StorageFilepath/useLocationTitle";

type Params = {
  location: Storage["location"];
};

export default function StorageFilepathDetails({ location }: Params) {
  const locationTitle = useLocationTitle(location);

  if (!location) return null;

  if (location.copyPolicy) {
    return <CopyPolicyDetails id={location.copyPolicy} />;
  }

  return <div style={{ wordBreak: "break-word" }}>{locationTitle}</div>;
}

function CopyPolicyDetails({ id }: { id: string }) {
  const { t } = useTranslation("rules");
  const { data, isLoading } = useFetch<CopyPolicy>(`/copy-policies/${id}`);

  if (isLoading) {
    return <Skeleton variant="text" />;
  }

  if (data) {
    return (
      <div>
        {t("copyPolicy")}: {data.name}
      </div>
    );
  }
}
